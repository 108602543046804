import { Button, IconButton, TablePagination, TextField, Theme, Tooltip } from '@material-ui/core';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import CancelIcon from '@material-ui/icons/Cancel';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash'
import {
  assignApplicationToProvider,
  clearSBApplicationFilter,
  createNewApplication,
  defermentApplicationAction,
  defermentApplicationActionEmail,
  deleteOutcomeFile,
  downloadAllApplicationFile,
  downloadOutComes,
  downloadQuestionFile,
  downloadVersionHistoryFile,
  duplicateActionByLead,
  duplicateActionByLeadWithFile,
  getAgentUserList,
  resetUserAgentList,
  getAllApplicationFileList,
  getAllProviderList,
  getAllSBTeamList,
  getApplicationFilters,
  getApplicationsProviders,
  getChildCounsellor,
  getProviderApplicationList,
  resetApplicationList,
  resetProviderApplicationListForFilter,
  setSBApplicationFilter,
  setSBApplicationFilterObj,
  submitOutcome,
  updateApplicationListValues,
  updateCounsllorCollapsExpand,
  getLockApplicationDetails,
  deleteApplication
} from '../../actions';
import SBButton from '../../components/base/SBButton';
import SBText from '../../components/base/SBText';
import SBDefermentDetailsDialog from '../../components/SBDefermentDetailsDialog';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { RootState } from '../../reducer';
import { getFormattedApplicationList } from '../../selector/applicationList';
import AppColor from '../../theme/AppColor';
import { encryptText } from '../../util';
import { hasOneOfPermissions, hasPermission, PERMISSION } from '../../util/rolePermissionsUtil';
import ApplicationFilter from '../SBProviderApplicationList/ApplicationFilter';
import AgentListModel from './AgentListModel';
import ApplicationTable from './ApplicationTable';
import DeferredApplicationActionDialog from './DeferredApplicationActionDialog';
import FileListModel from './FileListModel';
import LockApplicationActionDialog from './LockApplicationActionDialog';

type ApplicationTab = 'Leads' | 'Draft' | 'Current' | 'Closed' | 'Rejected' | 'Withdrawn';

const PAGE_SIZES = [10, 20, 50, 100];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F1F1F1',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    newAppBt: {
      width: 200,
      height: 50,
      borderRadius: 30,
      textTransform: 'none',
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        width: 230,
        fontSize: 14,
      },
    },
    tabBasic: {
      height: 40,
      width: 120,
      backgroundColor: 'white',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tabSelected: {
      height: 50,
      width: 120,
      backgroundColor: 'white',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cardContent: {
      display: 'flex',
      minHeight: '70vh',
      flexDirection: 'column',
      padding: 0,
    },
    nameCircle: {
      height: 78,
      width: 78,
      borderRadius: 40,
      backgroundColor: '#727487',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    circleText: { color: 'white', fontWeight: 'bold' },
    name: { color: 'black', fontWeight: 'bold' },
    appId: { color: '#898989', marginTop: 10 },
    headerTextParent: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 35,
    },
    cardHeader: {
      flex: 0.1,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 22,
    },
    cardStatus: {
      flex: 0.6,
      width: '100%',
      display: 'flex',
      backgroundColor: 'pink',
      flexDirection: 'row',
    },
    customWidth: {
      '& div': {
        // this is just an example, you can use vw, etc.
        width: '200px',
        fontFamily: 'Poppins',
        borderRadius: 0,
      },
    },
    input: {
      height: 40,
      border: 'none',
      backgroundColor: 'white',
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
    tabsParent: {
      height: 60,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    listContainer: {
      height: 'calc(100vh - 242px)',
      overflow: 'hidden',
    },
    withoutTopButton: {
      height: 'calc(100vh - 182px)',
    },
    searchSortParents: {
      alignItems: 'center',
      display: 'flex',
      [theme.breakpoints.up(1920)]: {
        height: 60,
        alignItems: 'unset',
      },
    },
    tabSubParents: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    searchbox: {
      border: 'none',
      '& .MuiOutlinedInput-root': {
        position: 'unset',
      },
      '& .MuiInputBase-input': {
        color: '#000000',
        fontFamily: 'Poppins',
        fontWeight: 400,
        letterSpacing: '0.75px',
        textTransform: 'none',
        fontSize: 14,
      },
      '& fieldset': {
        border: 'unset',
      },
      width: 600,
    },
    inherited: {
      '& .MuiFormControl-root': {
        position: 'unset',
      },
      height: 'fit-content',
    },
    tabDivider: { width: 10 },
    pagination: {
      backgroundColor: 'white',
      height: 50,
      marginRight: 20,
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: '#DDDDDD',
      borderStyle: 'solid',
      fontFamily: 'Poppins',
    },
    sort: {
      width: 260,
      borderWidth: 0,
      borderRadius: 5,
      display: 'flex',
      backgroundColor: 'white',
      height: 50,
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    filterButtonText: {
      fontWeight: 400,
      letterSpacing: '0.75px',
      textTransform: 'none',
      fontSize: 14,
    },
    tabText: {
      fontWeight: 500,
      fontSize: 14,
    },
    filterBtn: {
      width: 100,
      height: 40,
      marginLeft: 10,
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: 'white',
      borderColor: AppColor.appLightGrey,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
  })
);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

type PropsFromRedux = ConnectedProps<typeof connector>;
type SBApplicationListProps = PropsFromRedux & {};

const SBProviderApplicationList: React.FC<SBApplicationListProps> = ({
  getProviderApplicationList,
  resetApplicationList,
  appList,
  applicationListDetails,
  updateApplicationListValues,
  downloadOutComes,
  deleteOutcomeFile,
  submitOutcome,
  downloadAllApplicationFile,
  assignApplicationToProvider,
  getAllProviderList,
  providerList,
  user,
  getAllApplicationFileList,
  downloadQuestionFile,
  downloadVersionHistoryFile,
  resetProviderApplicationListForFilter,
  userAgentList,
  getAgentUserList,
  setSBApplicationFilterObj,
  clearSBApplicationFilter,
  applicationListParams,
  duplicateActionByLead,
  duplicateActionByLeadWithFile,
  createNewApplication,
  defermentApplicationAction,
  defermentApplicationActionEmail,
  provider,
  setSBApplicationFilter,
  getApplicationFilters,
  getAllSBTeamList,
  getChildCounsellor,
  updateCounsllorCollapsExpand,
  getApplicationsProviders,
  applicationProviderSuggestion,
  sbApplicationFilter,
  sbApplicationFilterList,
  getLockApplicationDetails,
  resetUserAgentList,
  deleteApplication,
  ...rest
}) => {
  const history = useHistory();
  const assignedList = ['Assigned', 'Unassigned', 'Clear All'];
  const classes = useStyles();
  const userPermissions: PERMISSION[] = useUserPermissions();
  const showLeadsTab: boolean = hasOneOfPermissions(
    [PERMISSION.applications_provider_view_sb_own, PERMISSION.applications_provider_view_sb_org],
    userPermissions
  );
  const showDraftTab: boolean = hasPermission(PERMISSION.view_draft_applications, userPermissions);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [tabString, setTabString] = useState<string>('Open');
  let tabs: ApplicationTab[] = [];
  showLeadsTab && tabs.push('Leads');
  showDraftTab && tabs.push('Draft');
  tabs = tabs.concat(['Current', 'Closed', 'Rejected', 'Withdrawn']);
  const [status, setStatus] = React.useState<ApplicationTab>('Current');
  const [tab, selectedTab] = useState(tabs.indexOf('Current'));
  const [lockPopup, showLockPopup] = React.useState(false);
  const [deferredPopup, showDeferredPopup] = React.useState(false);
  const [selectedLockApplication, setLockApplication] = React.useState<any>(undefined);
  const [selectedDeferredDetails, setDeferredApplication] = React.useState(undefined);

  const [deferredHistoryDetails, setDeferredHistoryDetails] = React.useState(undefined);
  const [deferredHistoryDialog, showDeferredHistoryDialog] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [questionPopupListData, setQuestionPopupListData] = React.useState(null);
  const [applicationIDForDownloadPopup, setApplicationIDForDownloadPopup] = React.useState(null);

  const [assigned, setAssigned] = React.useState(null);

  const [assignedTo, setAssignedTo] = React.useState<any>(null);

  
  const hasPerm = (perm: string) => {
    return hasPermission(perm, userPermissions);
  };

  const [filesListModel, setFileListModel] = React.useState(false);
  const [agentListModel, setAgentListModel] = React.useState(false);
 
  const getCurrentFilterCountAndDetails = () => {
    // console.log("sbApplicationFilter", sbApplicationFilter)
    let detailString = '';
    let counter = 0;
    let detailArray = [];
    const isProcessingOfficer =  hasOneOfPermissions(
      [
        PERMISSION.applications_provider_view_own,
        PERMISSION.applications_provider_view_org,
      ],
      userPermissions
    );
    for (var key in sbApplicationFilter) {
      if (sbApplicationFilter.hasOwnProperty(key) && !(isProcessingOfficer && key == 'provider')) {
        if (Array.isArray(sbApplicationFilter[key])) {
          let subString = undefined;
          if (sbApplicationFilter[key].length > 0) {
            counter++;
            for (let i = 0; i < sbApplicationFilter[key].length; i++) {
              subString = subString
                ? `${subString}, ${sbApplicationFilter[key][i].value}`
                : `${sbApplicationFilter[key][i].value}`;
            }
          }
          if (subString) {
            detailArray.push(
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 5,
                }}
              >
                <SBText text={`${getFormattedKey(key)}: `} style={{ fontSize: 12, fontWeight: 500 }} />
                <SBText text={subString} style={{ fontSize: 12, paddingLeft: 5 }} />
              </div>
            );
          }
        } else {
          if (sbApplicationFilter[key] && !(isProcessingOfficer && (key == 'provider' || key == 'country'))) {
            counter++;
            detailString = ` ${sbApplicationFilter[key].value}`;
            detailArray.push(
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: 5,
                }}
              >
                <SBText text={`${getFormattedKey(key)}:`} style={{ fontSize: 12, fontWeight: 500 }} />
                <SBText text={detailString} style={{ fontSize: 12, paddingLeft: 5 }} />
              </div>
            );
          }
        }
      }
    }

    if (assigned) {
      counter++;
      detailArray.push(
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
          <SBText text={`Assigned:`} style={{ fontSize: 12, fontWeight: 500 }} />
          <SBText text={'' + assigned} style={{ fontSize: 12, paddingLeft: 5 }} />
        </div>
      );
    }

    if (assignedTo) {
      // console.log("assignedTo ::", assignedTo)
      counter++;
      detailArray.push(
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
          <SBText text={`Assigned To:`} style={{ fontSize: 12, fontWeight: 500 }} />
          <SBText text={` ${assignedTo ? assignedTo.name : ''}`} style={{ fontSize: 12, paddingLeft: 5 }} />
        </div>
      );
    }
    return { count: counter, detail: detailArray };
  };

  const getFormattedKey = (key: string) => {
    if (key == 'applicationStatus') {
      return 'Status';
    } else if (key == 'subStatus') {
      return 'Sub Status';
    } else {
      return key.charAt(0).toUpperCase() + key.slice(1);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setApplicationIDForDownloadPopup(null);
    setQuestionPopupListData(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onFileListClick = async (event: any, application: any, addTimer: boolean = false) => {
    if (application && application.files && application.files.length > 0) {
      setQuestionPopupListData(application.files);
      // setAnchorEl(event.currentTarget);
      setApplicationIDForDownloadPopup(application.id);
      setFileListModel(true);
    }
  };

  const onCreateNewSBApplication = async (item: any) => {
    setAgentListModel(false);
    const result: any = await createNewApplication(item);

    if (result && result.id) {
      // history.push("/app/" + result.id);
      history.push('/app/' + encryptText(result.id) + '/group/-1/question/-1');
    }
  };

  useEffect(() => {
    loadApplicationList();

    return () => resetApplicationList();
  }, []);
  const loadApplicationList = async () => {
    await updateDefaultProviderAndCountry();
    // await updateApplicationListValues("status", status);
    await updateApplicationListValues('offset', 0);
    await updateApplicationListValues('limit', PAGE_SIZES[0]);
    // await getApplicationFilters();
    await getProviderApplicationList();
    if (hasPerm(PERMISSION.applications_provider_view_sb_org)) {
      // console.log("user >>>Provider List", providerList);
      await getAllSBTeamList();
    }

    //console.log("11111", applicationListDetails);
  };

  const updateDefaultProviderAndCountry = () => {
    if (user.user.provider && provider) {
      setSBApplicationFilter('provider', [{ id: provider.id, value: provider.name }]);
      setSBApplicationFilter('country', {
        id: provider.countryId,
        value: provider.country,
      });
    }
  };

  // console.log("user >>>Provider List", providerList);

  const onChangeSearchText = async (event: any) => {
    await updateApplicationListValues('searchText', event.target.value);
  };

  const onSearchClick = async () => {
    await resetProviderApplicationListForFilter();
    getProviderApplicationList();
  };

  const onApplyFilter = async (currentFilter: any, assigned: any, assignedTo: any) => {
    setSBApplicationFilterObj(currentFilter);
    if (assigned == 'Clear All') {
      await updateApplicationListValues('assigned', null);
      setAssigned(null);
    } else {
      await updateApplicationListValues('assigned', assigned);
      setAssigned(assigned);
    }
    await updateApplicationListValues('assignedTo', assignedTo ? assignedTo : null);
    setAssignedTo(assignedTo ? assignedTo : null);
    await resetProviderApplicationListForFilter();
    getProviderApplicationList();
  };

  const onClearFilter = async () => {
    await clearSBApplicationFilter();
    await updateApplicationListValues('assigned', null);
    await updateApplicationListValues('assignedTo', null);
    setAssigned(null);
    setAssignedTo(null);
    await resetProviderApplicationListForFilter();
    getProviderApplicationList();
    await updateDefaultProviderAndCountry();
  };

  const handlePageChange = async (event: unknown, newPage: number) => {
    await updateApplicationListValues('offset', newPage * applicationListDetails.pageSize);
    getProviderApplicationList();
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize: number = parseInt(event.target.value, 10);
    await updateApplicationListValues('limit', pageSize);
    await updateApplicationListValues('offset', 0);
    getProviderApplicationList();
  };

  const assignToMe = async (applicationId: any, userId: any) => {
    await assignApplicationToProvider(applicationId, userId);
  };

  const getTotalCountByTab = () => {
    if (tabs[tab] === 'Leads' || tabs[tab] === 'Draft') {
      return applicationListDetails.draftCount;
    }

    if (tabs[tab] === 'Current') {
      return applicationListDetails.openCount;
    }

    if (tabs[tab] === 'Closed') {
      return applicationListDetails.pastCount;
    }

    if (tabs[tab] === 'Rejected') {
      return applicationListDetails.RejectedCount;
    }

    if (tabs[tab] === 'Withdrawn') {
      return applicationListDetails.withDrawnCount;
    }
  };

  const onTabChange = async (index: any) => {
    if (index === tab) {
      return;
    }

    selectedTab(index);
    let tabsObj: string = '';

    if (tabs[index] === 'Leads' || tabs[index] === 'Draft') {
      tabsObj = 'Draft';
      setTabString('Draft');
    }

    if (tabs[index] === 'Current') {
      tabsObj = 'Open';
      setTabString('Open');
    }

    if (tabs[index] === 'Closed') {
      tabsObj = 'Closed';
      setTabString(tabsObj);
    }

    if (tabs[index] === 'Rejected') {
      tabsObj = 'Rejected';
      setTabString(tabsObj);
    }

    if (tabs[index] === 'Withdrawn') {
      tabsObj = 'Withdrawn';
      setTabString(tabsObj);
    }

    await resetApplicationList();
    await updateApplicationListValues('status', tabsObj);
    await updateApplicationListValues('offset', 0);
    await updateApplicationListValues('limit', 10);
    await clearSBApplicationFilter();
    getProviderApplicationList();
  };

  // console.log("providerList ::", providerList);

  //setUpdatedAppList(appList)
  //console.log("updated app list",UpdateAppList)

  const statusCount = (tab: any) => {
    // console.log("applicationListDetails ::", applicationListDetails);
    // console.log("tab ::", tab);
    let tabCount: any = '';
    if ((tab === 'Leads' || tab === 'Draft') && applicationListDetails) {
      return '' + applicationListDetails.draftCount;
    }
    if (tab === 'Current' && applicationListDetails) {
      return '' + applicationListDetails.openCount;
    }
    if (tab === 'Rejected' && applicationListDetails) {
      // console.log("RejectedCount", applicationListDetails.RejectedCount);
      return '' + applicationListDetails.RejectedCount;
    }
    if (tab === 'Withdrawn' && applicationListDetails) {
      return '' + applicationListDetails.withDrawnCount;
    }
    if (tab === 'Closed' && applicationListDetails) {
      return '' + applicationListDetails.pastCount;
    }

    return tabCount;
  };

  const onDownloadSingleFile = (question: any, applicationId:any) => {
    // console.log("question", question);
    setFileListModel(false);
    downloadQuestionFile(question.answer,applicationId);
    handleClose();
  };

  const onDownloadAllApplicationFile = () => {
    setFileListModel(false);
    downloadAllApplicationFile(applicationIDForDownloadPopup);
    handleClose();
  };

  const getAllFilesDetails = async (applicationid: any) => {
    const fileList = getAllApplicationFileList(applicationid);
  };

  const onShowLockUpDialog = async (application: any) => {
    if(application.lockedDetails) {
    setLockApplication(application);
    showLockPopup(true);
    } else {
      const result = await getLockApplicationDetails(application.id)
      setLockApplication(application);
      showLockPopup(true);
      // console.log("Result >>>>", result)
    }
  };

  const onShowDeferredDialog = (application: any, deferredDetails: any) => {
    const body: any = { application, deferredDetails };
    setDeferredApplication(body);
    showDeferredPopup(true);
  };

  const onShowDeferredHistory = (application: any, deferredDetails: any) => {
    // const body: any = { application, deferredDetails };
    setDeferredHistoryDetails(deferredDetails);
    showDeferredHistoryDialog(true);
  };

  const onSubmitLockApplicationAction = async (applicationId: number, action: string, comments: string, file: any) => {
    showLockPopup(false);
    if (file) {
      duplicateActionByLeadWithFile(applicationId, action, comments, file);
    } else {
      await duplicateActionByLead(applicationId, action, comments);
    }
  };

  const onSubmitDeferredApplicationAction = async (
    applicationId: number,
    deferredId: number,
    status: string,
    comments: string
  ) => {
    const body = {
      applicationId,
      deferredId,
      status,
      comments,
    };

    await defermentApplicationAction(body);
  };

  const callApplicationProvider = async (applicationId: any) => {
    return await getApplicationsProviders(applicationId);
  };

  const onSubmitForEmailApproval = async (applicationId: number, deferredId: number, email: string, name: string) => {
    const body = {
      applicationId,
      deferredId,
      email,
      name,
    };

    await defermentApplicationActionEmail(body);
  };

  const onDownloadHistory = (outcome: any) => {
    downloadOutComes(outcome);
  };

  const showAgentListModelForCreateApplication = async () => {
    if (!userAgentList || userAgentList.length == 0) {
      // await getAgentUserList();
      setAgentListModel(true);
    } else {
      setAgentListModel(true);
    }
  };

  const sortApplicationList = async ( order: string, orderBy: string,) => {
    updateApplicationListValues("orderBy", orderBy)
    updateApplicationListValues("order", order)
    updateApplicationListValues("offset", 0);
    updateApplicationListValues("limit", PAGE_SIZES[0]);
    getProviderApplicationList()
   } 

   const  onOpenFilter = ()=> {
    if(!sbApplicationFilterList) {
      getApplicationFilters()
    }
    setFilterOpen(true)
   }

  const filter = getCurrentFilterCountAndDetails();

  const getLockedUpdatedApplication = () => {
    const lockedApplication = _.find(appList, (obj:any)=> selectedLockApplication ? obj.id == selectedLockApplication.id : null)
    return lockedApplication
  } 

  const onSearchText = (text:string) => {
    // console.log("Text >>>>", text)
    if(text && text.length>3) {
    getAgentUserList(text)
    } else {
      resetUserAgentList()
      // console.log("No Data Found ")
    }
  }

  // @ts-ignore
  return (
    <div className={classes.root}>
      {hasPerm(PERMISSION.applications_create) && (
        <ButtonBase
          className={classes.newAppBt}
          style={{ marginBottom: 20 }}
          onClick={(event) =>
            hasPerm(PERMISSION.applications_provider_view_cph)
              ? onCreateNewSBApplication(user.user.id)
              : showAgentListModelForCreateApplication()
          }
        >
          <SBButton
            icon={false}
            text={'+ Create New Application'}
            className={classes.newAppBt}
            onClick={() => console.log()}
          />
        </ButtonBase>
      )}

      <div className={classes.tabsParent}>
        <TextField
          id="input-with-icon-textfield"
          variant="outlined"
          placeholder={'Search by Application Id, Passport & Applicant name'}
          className={classes.searchbox}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" onClick={() => onSearchClick()}>
                <SearchIcon />
              </InputAdornment>
            ),
            className: classes.input,
          }}
          value={applicationListParams.searchText}
          onChange={onChangeSearchText}
          onKeyPress={(ev) => {
            // console.log(`Pressed keyCode ${ev.key}`);
            if (ev.key === 'Enter') {
              // Do code here
              onSearchClick();
              ev.preventDefault();
            }
          }}
        />

        <div className={classes.searchSortParents}>
          {filter && filter.count > 0 ? (
            <div
              style={{
                backgroundColor: '#265FE4',
                height: 40,
                paddingLeft: 14,
                borderRadius: 5,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <SBText
                      text={`Filter${filter.count > 1 ? 's' : ''} `}
                      style={{
                        fontWeight: 600,
                        marginBottom: 10,
                        marginTop: 10,
                        textAlign: 'left',
                      }}
                    />
                    {filter.detail}
                  </React.Fragment>
                }
              >
                <div>
                  <SBText
                    text={`${filter.count} Filter${filter.count > 1 ? 's' : ''} Applied`}
                    style={{ color: 'white' }}
                  />
                </div>
              </HtmlTooltip>
              <Tooltip title={'Clear Filter'}>
                <IconButton onClick={() => onClearFilter()}>
                  <CancelIcon style={{ color: 'white' }} />
                </IconButton>
              </Tooltip>

              {/* #265FE4 */}
              {/* #5ACAFA */}
            </div>
          ) : null}

          <Button className={classes.filterBtn} onClick={() => onOpenFilter()}>
            <FilterListIcon />
            <SBText text={'Filter'} style={{ marginLeft: 10 }} />
          </Button>
        </div>
      </div>
      <div
        className={`${classes.listContainer} ${
          !hasPerm(PERMISSION.applications_create) ? classes.withoutTopButton : ''
        }`}
      >
        {appList.length === 0 ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <AnnouncementIcon style={{ fill: '#8F928E', height: 150, width: 150, opacity: 0.6 }} />
            <SBText
              text={`No ${tabs[tab].toLowerCase()} application exist`}
              style={{
                fontSize: 20,
                fontFamily: 'Poppins',
                fontWeight: '600',
                color: '#8F928E',
              }}
            />
          </div>
        ) : (
          <>
            <ApplicationTable
              data={appList}
              downloadOutComeFile={downloadOutComes}
              deleteOutcomeFile={deleteOutcomeFile}
              submitOutcome={submitOutcome}
              downloadAppFile={downloadAllApplicationFile}
              onAssignToMe={assignToMe}
              userPermissions={userPermissions}
              providerList={providerList}
              user={user}
              onShowFileList={(event: any, applicationId: any, timer: any) =>
                onFileListClick(event, applicationId, timer)
              }
              showLockApplication={(application: any) => onShowLockUpDialog(application)}
              showDeferredApplication={(application: any, obj: any) => onShowDeferredDialog(application, obj)}
              tab={tabs[tab]}
              downloadOutComeHistoryFile={onDownloadHistory}
              showDeferredApplicationHistory={(application: any, deferredHistoryDetails: any) =>
                onShowDeferredHistory(application, deferredHistoryDetails)
              }
              applicationProviderSuggestion={applicationProviderSuggestion}
              getApplicationsProviders={callApplicationProvider}
              onUpdateSort={(order:string, orderBy:string) => sortApplicationList(order, orderBy)}
              order={applicationListParams.order}
              orderBy={applicationListParams.orderBy}
              onDeleteApplication={(id) => deleteApplication(id)}
            />

            <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              component="div"
              count={applicationListDetails.totalRecords}
              rowsPerPage={applicationListDetails.pageSize}
              page={applicationListDetails.currentPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </div>

      <FileListModel
        open={filesListModel}
        onClose={() => setFileListModel(false)}
        onDownloadFile={(item, id) => onDownloadSingleFile(item, id)}
        onDownloadAll={() => onDownloadAllApplicationFile()}
        data={questionPopupListData}
        applicationId={applicationIDForDownloadPopup}
        onDownloadHistoryFile={(item) => {
          downloadVersionHistoryFile(item.id, item.fileName);
          setFileListModel(false);
        }}
      />
      {agentListModel ? (
        <AgentListModel
          open={agentListModel}
          onClose={() => setAgentListModel(false)}
          data={userAgentList}
          onSelectAgent={(item) => onCreateNewSBApplication(item)}
          onGetSubAgent={(companyId: number) => getChildCounsellor(companyId)}
          onCollapseExpand={(companyId: number) => updateCounsllorCollapsExpand(companyId)}
          isVNTUser={hasPerm(PERMISSION.applications_provider_view_global)}
          onSearchText={onSearchText}
        />
      ) : null}

      <ApplicationFilter
        filterOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        tabString={tabString}
        selectedAssign={applicationListParams.assigned}
        assignedTo={applicationListParams.assignedTo}
        onApplyFilter={onApplyFilter}
        onClearFilter={onClearFilter}
      />

      {lockPopup ? (
        <LockApplicationActionDialog
          open={lockPopup}
          onIntervention={() => console.log('Data')}
          onDecline={() => console.log('Data')}
          application={selectedLockApplication ? getLockedUpdatedApplication() : null}
          onSubmit={(applicationId: number, action: string, comments: string, file: any) =>
            onSubmitLockApplicationAction(applicationId, action, comments, file)
          }
          onClose={() => showLockPopup(false)}
        />
      ) : null}

      {deferredPopup ? (
        <DeferredApplicationActionDialog
          open={deferredPopup}
          application={selectedDeferredDetails}
          onSubmit={(applicationId: number, deferredId: number, status: string, comments: string) =>
            onSubmitDeferredApplicationAction(applicationId, deferredId, status, comments)
          }
          onSubmitForEmailApproval={(applicationId: number, deferredId: number, email: string, name: string) =>
            onSubmitForEmailApproval(applicationId, deferredId, email, name)
          }
          onClose={() => showDeferredPopup(false)}
        />
      ) : null}

      {deferredHistoryDialog ? (
        <SBDefermentDetailsDialog
          open={deferredHistoryDialog}
          onClose={() => {
            showDeferredHistoryDialog(false);
            setDeferredHistoryDetails(undefined);
          }}
          deferredDetailApplication={deferredHistoryDetails}
        />
      ) : null}
    </div>
  );
};

// @ts-ignore
const mapState = (state: RootState) => ({
  // @ts-ignore
  applicationListDetails: state.sbApplication.applicationListDetails,
  appList: getFormattedApplicationList(state),
  // @ts-ignore
  providerList: state.sbApplication.providerList,
  user: state.user.user,
  // @ts-ignore
  userAgentList: state.sbApplication.userAgentList,
  // @ts-ignore
  applicationListParams: state.sbApplication.applicationListParams,
  provider: state.user.provider,
  // @ts-ignore
  applicationProviderSuggestion: state.sbApplication.applicationProviderSuggestion,
  // @ts-ignore
  sbApplicationFilter: state.sbApplication.sbApplicationFilter,
  // @ts-ignore
  sbApplicationFilterList: state.sbApplication.sbApplicationFilterList
});

const mapDispatch = {
  submitOutcome,
  createNewApplication,
  getProviderApplicationList,
  resetApplicationList,
  updateApplicationListValues,
  downloadOutComes,
  deleteOutcomeFile,
  downloadAllApplicationFile,
  assignApplicationToProvider,
  getAllProviderList,
  getAllApplicationFileList,
  downloadQuestionFile,
  downloadVersionHistoryFile,
  resetProviderApplicationListForFilter,
  getAgentUserList,
  setSBApplicationFilterObj,
  clearSBApplicationFilter,
  duplicateActionByLead,
  duplicateActionByLeadWithFile,
  defermentApplicationAction,
  defermentApplicationActionEmail,
  setSBApplicationFilter,
  getApplicationFilters,
  getAllSBTeamList,
  getChildCounsellor,
  updateCounsllorCollapsExpand,
  getApplicationsProviders,
  getLockApplicationDetails,
  resetUserAgentList,
  deleteApplication
};

const connector = connect(mapState, mapDispatch);
export default connector(SBProviderApplicationList);