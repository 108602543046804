import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { LABELS } from "../../../../util/labels";
import { useResendAgentInvite } from "../../hooks/useResendInvite";
import { AgentTableViewItem } from "../../types/agent-table-view-item";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& button": {
        fontFamily: "Poppins",
        textTransform: "none",
        letterSpacing: 0.75,
        paddingLeft: 12,
        paddingRight: 12,
      },
      "& .MuiButton-contained": {
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
  })
);

interface ResendInviteConfirmationModalProps {
  resendConfirmOpen: boolean;
  handleClose: any;
  agent: AgentTableViewItem | null;
}

const ResendInviteConfirmationModal: React.FC<ResendInviteConfirmationModalProps> = ({
  resendConfirmOpen,
  handleClose,
  agent,
}) => {
  const classes = useStyles();
  const { resendAgentInvite } = useResendAgentInvite();

  const resendInvite = () => {
    if (agent) {
      resendAgentInvite(agent.id as number);
    }
    handleClose();
  };

  return (
    <Dialog
      className={classes.root}
      open={resendConfirmOpen}
      onClose={handleClose}
    >
      <DialogTitle>{LABELS.RESEND_INVITE}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {LABELS.RESENT_INVITE_CONFIRMATION}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text">
          Cancel
        </Button>
        <Button onClick={resendInvite} variant="contained">
          {LABELS.RESEND}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResendInviteConfirmationModal;
