import { RootState } from "../../../../reducer";

export const selectLeadsPaginatedData = (state: RootState) =>
  state.leads.paginatedData;

export const selectLeadsModalAPIStatus = (state: RootState) =>
  state.leads.modalAPIStatus;

export const selectedLeadDetails = (state: RootState) =>
  state.leads.selectedLeadDetails;

export const leadModalDropdownsData = (state: RootState) => 
  state.leads.modalDropdownsData;

export const leadStatusesDropdownsData = (state: RootState) => 
  state.leads.leadStatuses;

export const allLeadStatusesDropdownsData = (state: RootState) => 
  state.leads.allLeadStatuses;

export const getLeadStatusTimeline = (state: RootState) =>
  state.leads.selectedLeadStatusTimeline;

export const getDestinationCountrys = (state: RootState) =>
  state.leads.destinationCountrys;