import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';
import SBButton from '../base/SBButton';

type ErrorDialogProps = {
  className?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: () => void;
};
const LoginErrorDialog: React.FC<ErrorDialogProps> = ({ open, onSubmit }) => {
  return (
    <div>
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Session Timeout?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your session has been expired, Please login again to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SBButton icon={false} text={'Login'} onClick={onSubmit} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginErrorDialog;
