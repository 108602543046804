import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducer";
import { ActionType } from "../reducer/types";
export const SET_ERROR = "SET_ERROR";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const RESET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const RESET_ERROR = "RESET_ERROR";
export const SESSION_TIME_OUT_ERROR = "SESSION_TIME_OUT_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";
export const SHOW_ERROR = "SHOW_ERROR";
export const SHOW_SESSION_TIMEOUT_ERROR = "SHOW_SESSION_TIMEOUT_ERROR";
export const RESET_SESSION_TIMEOUT_ERROR = "RESET_SESSION_TIMEOUT_ERROR";
export const PARTIAL_DATA_FILLED_ERROR = "PARTIAL_DATA_FILLED_ERROR";
export const RESET_PARTIAL_DATA_FILLED_ERROR =
  "RESET_PARTIAL_DATA_FILLED_ERROR";
export const setErrors = (
  message: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: SET_ERROR, payload: message });
};

export const resetError = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  dispatch({ type: RESET_ERROR });
};

export const resetPartialFilledError = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  dispatch({ type: RESET_PARTIAL_DATA_FILLED_ERROR });
};

export const setLoginErrors = (
  message: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: SET_ERROR, payload: message });
};

export const resetLoginError = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  dispatch({ type: RESET_LOGIN_ERROR });
};
export const resetSessionError = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  dispatch({ type: RESET_SESSION_TIMEOUT_ERROR });
};
