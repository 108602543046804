import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Link,
  Tooltip,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import SBText from "../../../components/base/SBText";
import StatusChip from "../../../components/StatusChip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { tableStyles } from "../../../styles/table";
import { LABELS } from "../../../util/labels";
import { LPOTableItem, LPOTableActionItem, LPO_TABLE_ACTION } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(tableStyles(theme))
);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#4c4c4c",
  },
  tooltip: {
    backgroundColor: "#4c4c4c",
  },
}));

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

interface Props {
  lpoList: LPOTableItem[];
  onLPOActionClick: (lpo: LPOTableItem, action: LPO_TABLE_ACTION) => void;
  onViewLPO: (lpo: LPOTableItem) => void;
}

const LPOTable: React.FC<Props> = ({
  lpoList,
  onLPOActionClick,
  onViewLPO,
}) => {
  const classes = useStyles();

  const capitalizedCellClassName = `${classes.bodyText}  ${classes.capitalize}`;

  const handleViewClick = (lpo: LPOTableItem) => {
    onViewLPO(lpo);
  };

  const handleActionClick = (
    e: any,
    lpo: LPOTableItem,
    action: LPO_TABLE_ACTION
  ) => {
    e.stopPropagation();
    onLPOActionClick(lpo, action);
  };

  return (
    <>
      <div className={classes.mainLayout}>
        <div className={classes.tableParents}>
          <TableContainer component={Paper}>
            <Table className={classes.table} stickyHeader>
              <TableHead className={classes.TableRow}>
                <TableRow>
                  <TableCell align="left">
                    <SBText text={LABELS.NAME} className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text={LABELS.EMAIL} className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text={LABELS.PHONE} className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text={LABELS.CITY} className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text={LABELS.OCCUPATION} className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text={LABELS.STATUS} className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text={LABELS.ACTIONS} className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="center" className={classes.padding}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lpoList.map((item: LPOTableItem) => (
                  <TableRow
                    onClick={(e: any) => {}}
                    key={item.id}
                    style={{
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <TableCell component="th">
                      <SBText text={item.name} className={capitalizedCellClassName} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={item.email} className={classes.bodyText} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={item.phone} className={classes.bodyText} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={item.city} className={capitalizedCellClassName} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={item.occupation} className={capitalizedCellClassName} />
                    </TableCell>
                    <TableCell align="left">
                      <StatusChip item={item.statusViewDetails} className={classes.bodyText} />
                    </TableCell>
                    <TableCell align="left">
                      {item.actionLinks.map((actionItem: LPOTableActionItem, index: number) => (
                        <div key={index} style={{ display: 'inline-flex', flexDirection: 'row' }}>
                          {index>0  &&  <SBText text={'|'}   style={{ paddingRight: 5 }}/>}
                          <Link
                            key={actionItem.action}
                            style={{ paddingRight: 6 }}
                            component="button"
                            variant="body2"
                            onClick={(e: any) => handleActionClick(e, item, actionItem.action)}
                          >
                            {actionItem.label}
                          </Link>
                          
                          
                        </div>
                      ))}
                    </TableCell>
                    <TableCell align="left" className={classes.padding}>
                      <BootstrapTooltip title="Details">
                        <div onClick={(event) => handleViewClick(item)}>
                          <VisibilityIcon />
                        </div>
                      </BootstrapTooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default LPOTable;
