import { connect, ConnectedProps } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { RootState } from "../../reducer";
import { Theme } from "@material-ui/core";
import SBText from "../../components/base/SBText";
import { createStyles, makeStyles } from "@material-ui/styles";
import AppColor from "../../theme/AppColor";
import SBSimpleFileSelector from "../../components/base/SBSimpleFileSelector";
import SBFilterDropdown from "../../components/base/SBFilterDropdown";
import {
  getAllProviderList,
  uploadProviderPictureProfile,
  downloadUserProfile,
} from "../../actions";
import SBButton from "../../components/base/SBButton";
import _ from "lodash";

type PropsFromRedux = ConnectedProps<typeof connector>;
type CompanyProfileProps = PropsFromRedux & {
  classes?: string;
  className?: string;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      height: "calc(100% - 65px)",
      padding: 16,
    },
    mainCard: {
      height: "100%",
      width: "100%",
      backgroundColor: "white",
      borderRadius: 5,
      display: "flex",
      padding: 20,
      boxShadow:
        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      flex: 1,
      flexDirection: "column",
    },
    parent: {
      width: "40%",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "gray",
      borderRadius: 5,
      marginTop: 40,
      padding: 30,
    },
    button: { width: 100, alignSelf: "flex-end" },
  })
);
const CompanyProfile: React.FC<CompanyProfileProps> = ({
  getAllProviderList,
  providerList,
  uploadProviderPictureProfile,
  user,
  downloadUserProfile,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedFile, setFile] = useState<undefined | any>(undefined);
  // console.log("test ???", providerList);

  useEffect(() => {
    getAllProviderList();
  }, []);

  const getFileName = () => {
    if (selectedFile) {
      // @ts-ignore
      const fileName = selectedFile.name.split("#")[1];
      return fileName;
    } else {
      return "";
    }
  };

  const getSortedList = () => {
    const list = [];
    for (let i = 0; i < providerList.length; i++) {
      const obj = {
        id: providerList[i].id,
        value: providerList[i].name,
      };
      list.push(obj);
    }
    return list;
  };

  const getPreparedFileName = () => {
    const fileName = `company_profile_logo`;
    return fileName;
  };

  const onSubmit = async () => {
    if (!selectedFile) {
      alert("Please select picture");
      return;
    }
    const resposne: any = await uploadProviderPictureProfile(
      selectedFile,
      "-1"
    );
    if (resposne.status == "success") {
      setTimeout(function () {
        alert("Profile picture updated successfully");
        downloadUserProfile(user.user.id);
      }, 100);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.mainCard}>
        <SBText
          text={"Update Company Profile Picture"}
          style={{ fontSize: 20, fontWeight: "600" }}
        />
        <div className={classes.parent}>
          <div style={{ height: 30 }}></div>
          <SBSimpleFileSelector
            setUploadError={() => console.log("Error")}
            onFileSelected={(fileObj: any) => setFile(fileObj)}
            onDeleteFile={() => console.log("File Selected")}
            value={getFileName()}
            fileNameExtension={getPreparedFileName()}
            showDownload={false}
          />
          <div
            style={{
              justifyContent: "flex-end",
              marginTop: 30,
              display: "flex",
            }}
          >
            <SBButton
              text={"Submit"}
              icon={false}
              onClick={() => onSubmit()}
              className={classes.button}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  // @ts-ignore
  providerList: state.sbApplication.providerList,
  user: state.user.user,
});

const mapDispatch = {
  getAllProviderList,
  uploadProviderPictureProfile,
  downloadUserProfile,
};

const connector = connect(mapState, mapDispatch);
export default connector(CompanyProfile);
