import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/styles";
import {
  Grid,
  Theme,
  TextField,
  GridSpacing,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { RootState } from "../../reducer";
import { connect, ConnectedProps } from "react-redux";
import ApplicationCard from "./ApplicationCard";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SortBy from "./SortBy";
import SBButton from "../../components/base/SBButton";
import SBText from "../../components/base/SBText";
import FilterListIcon from "@material-ui/icons/FilterList";
import {
  createNewApplication,
  getApplicationList,
  resetApplicationList,
  updateApplicationListValues,
  clearSBApplicationFilter,
  setSBApplicationFilterObj,
  downloadRejectedAppProof,
  getApplicationFilters,
  getAllChildList,
} from "../../actions";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { getFormattedApplicationList } from "../../selector/applicationList";
import { encryptText } from "../../util";
import ApplicationFilter from "./ApplicationFilter";
import AppColor from "../../theme/AppColor";
import RejectedDetailModel from "../../components/RejectedDetailModel";
import {
  hasOneOfPermissions,
  hasPermission,
  PERMISSION,
} from "../../util/rolePermissionsUtil";
import { useUserPermissions } from "../../hooks/useUserPermissions";
import CancelIcon from "@material-ui/icons/Cancel";
//import { getCountByStatus } from "../../selector/getCountByStatus";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "calc(100vh - 64px)",
      backgroundColor: "#F1F1F1",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    newAppBt: {
      width: 200,
      height: 50,
      borderRadius: 30,
      textTransform: "none",
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        width: 230,
        fontSize: 14,
      },
    },
    tabBasic: {
      height: 40,
      minWidth: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      paddingLeft: 5,
      paddingRight: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    tabSelected: {
      height: 50,
      minWidth: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      paddingLeft: 5,
      paddingRight: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    cardContent: {
      display: "flex",
      minHeight: "70vh",
      flexDirection: "column",
      padding: 0,
    },
    mainLayout: {
      height: "100%",
      backgroundColor: "white",
      overflowY: "scroll",
      display: "flex",
      padding: theme.spacing(6),
    },
    nameCircle: {
      height: 78,
      width: 78,
      borderRadius: 40,
      backgroundColor: "#727487",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    circleText: { color: "white", fontWeight: "bold" },
    name: { color: "black", fontWeight: "bold" },
    appId: { color: "#898989", marginTop: 10 },
    headerTextParent: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 35,
    },
    cardHeader: {
      flex: 0.1,
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: 22,
    },
    cardStatus: {
      flex: 0.6,
      width: "100%",
      display: "flex",
      backgroundColor: "pink",
      flexDirection: "row",
    },
    customWidth: {
      "& div": {
        // this is just an example, you can use vw, etc.
        width: "200px",
        fontFamily: "Poppins",
        borderRadius: 0,
      },
    },
    input: {
      height: 40,
      border: "none",
      backgroundColor: "white",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
    searchbox: {
      border: "none",
      "& .MuiOutlinedInput-root": {
        position: "unset",
      },
      "& .MuiInputBase-input": {
        color: "#000000",
        fontFamily: "Poppins",
        fontWeight: 400,
        letterSpacing: "0.75px",
        textTransform: "none",
        fontSize: 14,
      },
      "& fieldset": {
        border: "unset",
      },
    },
    inherited: {
      "& .MuiFormControl-root": {
        position: "unset",
      },
    },
    tabsParent: {
      height: 60,
      display: "flex",
      marginTop: 10,
      justifyContent: "space-between",
      // backgroundColor: "pink",
    },
    searchSortParents: {
      height: 40,
      display: "flex",
      [theme.breakpoints.up(1920)]: {
        height: 60,
      },
    },
    tabSubParents: {
      display: "flex",
      alignItems: "flex-end",
    },
    tabDivider: { width: 10 },
    tabText: {
      fontWeight: 500,
      fontSize: 14,
    },
    pagination: {
      backgroundColor: "white",
      height: 50,
      marginRight: 20,
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 2,
      borderWidth: 1,
      borderColor: "#DDDDDD",
      borderStyle: "solid",
      fontFamily: "Poppins",
    },
    filterBtn: {
      width: 100,
      height: 40,
      marginLeft: 10,
      borderWidth: 1,
      borderStyle: "solid",
      backgroundColor: "white",
      borderColor: AppColor.appLightGrey,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
  })
);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SBApplicationListProps = PropsFromRedux & {};

const SBApplicationList: React.FC<SBApplicationListProps> = ({
  createNewApplication,
  getApplicationList,
  resetApplicationList,
  appList,
  applicationListDetails,
  applicationListParams,
  updateApplicationListValues,
  loading,
  clearSBApplicationFilter,
  setSBApplicationFilterObj,
  downloadRejectedAppProof,
  getApplicationFilters,
  getAllChildList,
  sbApplicationFilter,
  ...rest
}) => {
  const history = useHistory();
  const classes = useStyles();
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [tab, selectedTab] = useState<number>(0);
  const [tabString, setTabString] = useState<string>("Draft");
  const tabs = ["Draft", "Current", "Closed", "Rejected", "Withdrawn"];
  const spacingConst = parseInt(String((window.innerWidth * 6) / 1792));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [reasonDialog, showReasonDialog] = React.useState(false);
  const [reasonApp, setReasonApp] = React.useState<any | undefined>(undefined);
  const [assigned, setAssigned] = React.useState(null);

  const [assignedTo, setAssignedTo] = React.useState<any>(null);

  // const handleChangePage = async (
  //   event: React.MouseEvent<HTMLButtonElement> | null,
  //   newPage: number
  // ) => {
  //   await updateApplicationListValues("limit", rowsPerPage);
  //   await updateApplicationListValues(
  //     "offset",
  //     rowsPerPage * (newPage + 1) - rowsPerPage
  //   );
  //   getApplicationList();
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = async (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const newRowPerPage = parseInt(event.target.value, 10);

  //   await updateApplicationListValues("limit", newRowPerPage);
  //   await updateApplicationListValues(
  //     "offset",
  //     newRowPerPage * 1 - newRowPerPage
  //   );
  //   getApplicationList();

  //   // limit: newRowPerPage,
  //   //     offset: newRowPerPage * 1 - newRowPerPage,
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  useEffect(() => {
    // const body = {
    //   limit: 10,
    //   offset: 0,
    //   searchText: "",
    //   orderBy: null,
    //   status: "Draft",
    // };
    loadInitialData();
    return () => resetApplicationList();
  }, []);

  const loadInitialData = async () => {
    await getApplicationFilters();
    await getApplicationList();
    if (hasPerm(PERMISSION.applications_agent_view_org)) {
      await getAllChildList();
    }
  };

  const getCurrentFilterCountAndDetails = () => {
    let detailString = "";
    let counter = 0;
    let detailArray = [];
    for (var key in sbApplicationFilter) {
      if (sbApplicationFilter.hasOwnProperty(key)) {
        if (Array.isArray(sbApplicationFilter[key])) {
          let subString = undefined;
          if (sbApplicationFilter[key].length > 0) {
            counter++;
            for (let i = 0; i < sbApplicationFilter[key].length; i++) {
              subString = subString
                ? `${subString}, ${sbApplicationFilter[key][i].value}`
                : `${sbApplicationFilter[key][i].value}`;
            }
          }
          if (subString) {
            detailArray.push(
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 5,
                }}
              >
                <SBText
                  text={`${getFormattedKey(key)}: ${" "}`}
                  style={{ fontSize: 12, fontWeight: 500 }}
                />
                <SBText text={subString} style={{ fontSize: 12 }} />
              </div>
            );
          }
        } else {
          if (key != "counsellor" && sbApplicationFilter[key]) {
            counter++;
            detailString = `${sbApplicationFilter[key].value}`;
            detailArray.push(
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 5,
                }}
              >
                <SBText
                  text={`${getFormattedKey(key)}:${" "} `}
                  style={{ fontSize: 12, fontWeight: 500 }}
                />
                <SBText text={detailString} style={{ fontSize: 12 }} />
              </div>
            );
          } else {
            if (key == "counsellor" && sbApplicationFilter[key]) {
              counter++;
              detailString = `${sbApplicationFilter[key].name}`;
              detailArray.push(
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 5,
                  }}
                >
                  <SBText
                    text={`${getFormattedKey(key)}: `}
                    style={{ fontSize: 12, fontWeight: 500 }}
                  />
                  <SBText text={detailString} style={{ fontSize: 12 }} />
                </div>
              );
            }
          }
        }
      }
    }

    if (assigned) {
      counter++;
      detailArray.push(
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
          <SBText
            text={`Assigned : `}
            style={{ fontSize: 12, fontWeight: 500 }}
          />
          <SBText text={"" + assigned} style={{ fontSize: 12 }} />
        </div>
      );
    }

    if (assignedTo) {
      console.log("assignedTo ::", assignedTo);
      counter++;
      detailArray.push(
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
          <SBText
            text={`Assigned To : `}
            style={{ fontSize: 12, fontWeight: 500 }}
          />
          <SBText
            text={`${assignedTo ? assignedTo.name : ""}`}
            style={{ fontSize: 12 }}
          />
        </div>
      );
    }
    return { count: counter, detail: detailArray };
  };

  const getFormattedKey = (key: string) => {
    if (key == "applicationStatus") {
      return "Status";
    } else if (key == "subStatus") {
      return "Sub Status";
    } else {
      return key.charAt(0).toUpperCase() + key.slice(1);
    }
  };

  //console.log("Formatted Application List ", appList);
  const hasPerm = (perm: string) => {
    return hasPermission(perm, userPermissions);
  };

  const onCreateNewSBApplication = async () => {
    const result: any = await createNewApplication();
    if (result && result.id) {
      ///app/:id/group/:groupId/question/:questionId
      history.push("/app/" + encryptText(result.id) + "/group/-1/question/-1");
    }
  };

  const onChangeSearchText = async (event: any) => {
    await updateApplicationListValues("searchText", event.target.value);
  };

  const onSearchClick = async () => {
    getApplicationList();
  };

  const onTabChange = async (index: any) => {
    if (index === tab) {
      return;
    }
    //console.log(getApplicationList())
    selectedTab(index);
    let tabsObj: string = "";

    if (tabs[index] === "Draft") {
      tabsObj = "Draft";
    }

    if (tabs[index] === "Current") {
      tabsObj = "Open";
    }

    if (tabs[index] === "Closed") {
      tabsObj = "Closed";
    }

    if (tabs[index] === "Rejected") {
      tabsObj = "Rejected";
    }

    if (tabs[index] === "Withdrawn") {
      tabsObj = "Withdrawn";
    }
    setTabString(tabsObj);
    await clearSBApplicationFilter();
    await updateApplicationListValues("status", tabsObj);
    getApplicationList();
  };

  // const statusCount = (tab: any) => {
  //   // console.log("applicationListDetails ::", applicationListDetails);
  //   // console.log("tab ::", tab);
  //   let tabCount: any = "";
  //   if (tab === "Draft" && applicationListDetails) {
  //     return "" + applicationListDetails.draftCount;
  //   }
  //   if (tab === "Current" && applicationListDetails) {
  //     return "" + applicationListDetails.openCount;
  //   }
  //   if (tab === "Rejected" && applicationListDetails) {
  //     // console.log("RejectedCount", applicationListDetails.RejectedCount);
  //     return "" + applicationListDetails.RejectedCount;
  //   }
  //   if (tab === "Withdrawn" && applicationListDetails) {
  //     return "" + applicationListDetails.withDrawnCount;
  //   }
  //   if (tab === "Closed" && applicationListDetails) {
  //     return "" + applicationListDetails.pastCount;
  //   }

  //   return tabCount;
  // };

  const onApplyFilter = async (filter: any) => {
    await setSBApplicationFilterObj(filter);
    getApplicationList();
  };

  const onClearFilter = async () => {
    await clearSBApplicationFilter();
    getApplicationList();
  };

  const onShowReasonDetails = async (app: any) => {
    setReasonApp(app);
    showReasonDialog(true);
  };

  const filter = getCurrentFilterCountAndDetails();

  return (
    <div className={classes.root}>
      {hasPerm(PERMISSION.applications_create) && (
        <SBButton
          icon={false}
          text={"+ Create New Application"}
          className={classes.newAppBt}
          onClick={() => onCreateNewSBApplication()}
        />
      )}
      {/*<Button variant={"contained"} className={classes.newAppBt}></Button>*/}
      <div className={classes.tabsParent}>
        <div className={classes.searchSortParents}>
          <TextField
            id="input-with-icon-textfield"
            variant="outlined"
            placeholder={"Search Text"}
            //style={{border: 'none'}}
            className={classes.searchbox}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={() => onSearchClick()}>
                  <SearchIcon />
                </InputAdornment>
              ),
              className: classes.input,
            }}
            value={
              applicationListParams.searchText
                ? applicationListParams.searchText
                : ""
            }
            onChange={onChangeSearchText}
            onKeyPress={(ev) => {
              console.log(`Pressed keyCode ${ev.key}`);
              if (ev.key === "Enter") {
                // Do code here
                onSearchClick();
                ev.preventDefault();
              }
            }}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          {filter && filter.count > 0 ? (
            <div
              style={{
                backgroundColor: "#265FE4",
                height: 40,
                paddingLeft: 10,
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
              }}
            >
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <SBText
                      text={`${filter.count} Filter Applied`}
                      style={{
                        fontWeight: 600,
                        marginBottom: 10,
                        marginTop: 10,
                        textAlign: "center",
                      }}
                    />
                    {filter.detail}
                  </React.Fragment>
                }
              >
                <div>
                  <SBText
                    text={`${filter.count} Filter Applied`}
                    style={{ color: "white" }}
                  />
                </div>
              </HtmlTooltip>
              <Tooltip title={"Clear Filter"}>
                <IconButton onClick={() => onClearFilter()}>
                  <CancelIcon style={{ color: "white" }} />
                </IconButton>
              </Tooltip>

              {/* #265FE4 */}
              {/* #5ACAFA */}
            </div>
          ) : null}
          <Button
            className={classes.filterBtn}
            onClick={() => setFilterOpen(true)}
          >
            <FilterListIcon />
            <SBText text={"Filter"} style={{ marginLeft: 10 }} />
          </Button>
        </div>
      </div>

      <div className={classes.mainLayout}>
        {appList.length == 0 && !loading ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <AnnouncementIcon
              style={{ fill: "#8F928E", height: 150, width: 150, opacity: 0.6 }}
            />
            <SBText
              text={`No ${tabs[tab].toLowerCase()} application exist`}
              style={{
                fontSize: 20,
                fontFamily: "Poppins",
                fontWeight: "600",
                color: "#8F928E",
              }}
            />
          </div>
        ) : (
          <Grid container spacing={Number(spacingConst) as GridSpacing}>
            {appList.map((obj: any) => (
              <ApplicationCard
                data={obj}
                selectedTab={tabs[tab]}
                onShowReasonDetails={(app: any) => onShowReasonDetails(app)}
              />
            ))}
          </Grid>
        )}
      </div>

      <ApplicationFilter
        filterOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        tabString={tabString}
        onApplyFilter={onApplyFilter}
        onClearFilter={onClearFilter}
      />

      <RejectedDetailModel
        open={reasonDialog && reasonApp}
        application={reasonApp}
        onClose={() => {
          showReasonDialog(false);
          setReasonApp(undefined);
        }}
        onDownload={(fileName) => {
          downloadRejectedAppProof(fileName);
          showReasonDialog(false);
          setReasonApp(undefined);
        }}
      />
    </div>
  );
};

// export default SBApplicationList;

const mapState = (state: RootState) => ({
  // @ts-ignore
  applicationListDetails: state.sbApplication.applicationListDetails,
  appList: getFormattedApplicationList(state),
  // @ts-ignore
  applicationListParams: state.sbApplication.applicationListParams,
  loading: state.application.loading,
  // @ts-ignore
  sbApplicationFilter: state.sbApplication.sbApplicationFilter,
});

const mapDispatch = {
  createNewApplication,
  getApplicationList,
  resetApplicationList,
  updateApplicationListValues,
  clearSBApplicationFilter,
  setSBApplicationFilterObj,
  downloadRejectedAppProof,
  getApplicationFilters,
  getAllChildList,
};

const connector = connect(mapState, mapDispatch);
export default connector(SBApplicationList);
