import { SvgIcon } from "@material-ui/core";
import React from "react";

export function SponcerIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 57 57" fill="none">
      <g opacity="0.6">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M42 29C49.1797 29 55 23.1797 55 16C55 8.8203 49.1797 3 42 3C34.8203 3 29 8.8203 29 16C29 23.1797 34.8203 29 42 29ZM40.6957 23.3709C39.5762 23.2167 38.6325 22.8918 37.8647 22.396C36.7504 21.67 36.1288 20.5116 36 18.9208H39.9034C40.0129 19.4224 40.2738 19.8152 40.686 20.099C41.1047 20.3828 41.6425 20.5248 42.2995 20.5248C42.6924 20.5248 43.0145 20.4653 43.2657 20.3465C43.5233 20.2211 43.7134 20.0594 43.8357 19.8614C43.9581 19.6634 44.0193 19.4488 44.0193 19.2178C44.0193 18.7822 43.8744 18.4884 43.5845 18.3366C43.2947 18.1782 42.8535 18.0495 42.2609 17.9505L40.0773 17.5743C38.9887 17.396 38.1095 16.9538 37.4396 16.2475C36.7697 15.5347 36.4251 14.571 36.4058 13.3564C36.3929 12.3927 36.6506 11.571 37.1787 10.8911C37.7069 10.2046 38.4155 9.68317 39.3043 9.32673C39.7462 9.14759 40.2099 9.01264 40.6957 8.9219V7H43.8454V8.92829C44.3444 9.02729 44.805 9.17661 45.2271 9.37624C46.0709 9.77228 46.715 10.3135 47.1594 11C47.6103 11.6799 47.8132 12.4455 47.7681 13.297H43.9614C43.9227 12.8614 43.8068 12.495 43.6135 12.198C43.4203 11.901 42.9952 11.7525 42.3382 11.7525C41.971 11.7525 41.6651 11.7888 41.4203 11.8614C41.1755 11.9274 40.9791 12.0165 40.8309 12.1287C40.6828 12.2343 40.5765 12.3531 40.5121 12.4851C40.4541 12.6172 40.4251 12.7492 40.4251 12.8812C40.4251 13.2046 40.5282 13.462 40.7343 13.6535C40.9404 13.8383 41.3462 13.9967 41.9517 14.1287L44.1353 14.5842C45.4879 14.868 46.4702 15.4092 47.0821 16.2079C47.694 17.0066 48 18.0099 48 19.2178C48 20.0957 47.7681 20.8548 47.3043 21.495C46.847 22.1287 46.1932 22.6172 45.343 22.9604C44.8888 23.1437 44.3896 23.2781 43.8454 23.3635V25H40.6957V23.3709Z"
          fill="#3D405B"
        />
        <path
          d="M44.75 38.875H40C40 36.025 38.2188 33.46 35.5588 32.4625L20.9288 27H2V53.125H16.25V49.705L32.875 54.3125L51.875 48.375V46C51.875 42.0575 48.6925 38.875 44.75 38.875ZM6.75 48.375V31.75H11.5V48.375H6.75ZM32.8038 49.3488L16.25 44.765V31.75H20.0737L33.8963 36.9037C34.7038 37.2125 35.25 37.9963 35.25 38.875C35.25 38.875 30.5238 38.7562 29.7875 38.5187L24.135 36.6425L22.6387 41.155L28.2912 43.0312C29.5025 43.435 30.7612 43.6488 32.0437 43.6488H44.75C45.6763 43.6488 46.5075 44.195 46.8875 44.9787L32.8038 49.3488Z"
          fill="#3D405B"
        />
      </g>
    </SvgIcon>
  );
}
