import React, { useEffect, useRef } from "react";
import SBText from "../../../components/base/SBText";
import {
  TableBody,
  TableCell,
  TableContainer,
  Table,
  Modal,
  TableRow,
  Paper,
  TableHead,
  Tooltip,
  Typography,
  Link,
  Menu,
  MenuItem,
  Fade,
  IconButton,
  Button,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

import greenUp from "../assets/greenUp.png";
import redDown from "../assets/redDown.png";

import { connect, ConnectedProps } from "react-redux";

import { Theme } from "@material-ui/core";
import { RootState } from "../../../reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "90%",
    },
    tableParents: {
      display: "flex",
      flex: 1,
      // overflow: "scroll",
      height: "100%",
      overflow: "hidden",
    },
    tableMatrial: {
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.2)",
        borderRadius: 15,
      },
    },
    mainLayout: {
      display: "flex",
      flex: 1,
      backgroundColor: "white",
      padding: 16,
      // overflow: "scroll",
      height: "100%",
      overflow: "hidden",
    },
    tierBlockPlatinum: {
      borderRadius: "215px",
      background: "linear-gradient(178.18deg, #BFC5D2 -13.56%, #1B1C1D 158.3%)",
      width: "100%",
      padding: "4px 14px",
      fontSize: "15px",
      color: "#FFFFFF",
      textAlign: "center",
    },
    tierBlockGold: {
      borderRadius: "215px",
      background: "linear-gradient(178.18deg, #ECCC97 -13.56%, #694304 158.3%)",
      width: "100%",
      padding: "4px 14px",
      fontSize: "15px",
      color: "#FFFFFF",
      textAlign: "center",
    },
    parentCompanyName: {
      fontSize: "12px",
      fontWeight: 600,
    },
  })
);

// const columns: ColDef[] = [
//   { field: "agent", headerName: "Agent", width: 150 },
//   { field: "tier", headerName: "Tier", width: 150 },
//   {
//     field: "applications",
//     headerName: "Applications",
//     type: "number",
//     width: 150,
//   },
//   { field: "thisWeek", headerName: "This Week", width: 150 },
// ];

// const rows = [
//   {
//     id: 1,
//     tier: "Platinum",
//     agent: "T Ritika Singh",
//     applications: 35,
//     thisWeek: 12,
//   },
//   { id: 2, tier: "Gold", agent: "James", applications: 42, thisWeek: 17 },
//   {
//     id: 3,
//     tier: "Gold",
//     agent: "Uma Ram",
//     applications: 45,
//     thisWeek: 89,
//   },
//   { id: 4, tier: "Stark", agent: "Laxmi", applications: 16, thisWeek: 45 },
//   {
//     id: 6,
//     tier: "Platinum",
//     agent: "Anderson",
//     applications: 150,
//     thisWeek: 56,
//   },
//   { id: 7, tier: "Platinum", agent: "Ganesh", applications: 44, thisWeek: 78 },
//   { id: 8, tier: "Gold", agent: "Ashita", applications: 36, thisWeek: 34 },
//   { id: 9, tier: "Gold", agent: "Ashita", applications: 65, thisWeek: 89 },
// ];

type PropsFromRedux = ConnectedProps<typeof connector>;
type DashboardDefaultProps = PropsFromRedux & {
  data: [];
};

const AgentPerformanceTable: React.FC<DashboardDefaultProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ height: "90%", width: "100%" }}>
      <div className={classes.mainLayout}>
        <div className={classes.tableParents}>
          <TableContainer component={Paper} className={classes.tableMatrial}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <SBText text="Agent" />
                  </TableCell>

                  <TableCell align="center">
                    <SBText text="Tier" />
                  </TableCell>

                  <TableCell align="center">
                    <SBText text="Applications" />
                  </TableCell>

                  <TableCell align="center">
                    <SBText text="This Week" />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((element: any, index: number) => (
                  <TableRow>
                    <TableCell component="th">
                      <SBText
                        text={
                          element.companyName === ""
                            ? "Startblocks"
                            : element.companyName
                        }
                      />
                      <SBText
                        text={
                          element.companyName === ""
                            ? ""
                            : element.parentCompanyName
                        }
                        className={classes.parentCompanyName}
                      />
                    </TableCell>

                    <TableCell component="th">
                      <div
                        className={
                          index % 2 === 0
                            ? classes.tierBlockPlatinum
                            : classes.tierBlockGold
                        }
                      >
                        {index % 2 === 0 ? "Gold" : "Platinum"}
                      </div>
                    </TableCell>

                    <TableCell component="th" align={"center"}>
                      <SBText text={element.totalCount} />
                    </TableCell>

                    <TableCell component="th" align={"center"}>
                      <div>
                        {element.currentWeek - element.lastWeek}{" "}
                        <span style={{ marginLeft: "10%" }}>
                          {element.currentWeek - element.lastWeek !== 0 && (
                            <img
                              src={
                                element.currentWeek - element.lastWeek < 0
                                  ? redDown
                                  : greenUp
                              }
                            />
                          )}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(AgentPerformanceTable);
