import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
  post,
} from "../../../actions";
import { API_URL } from "../../../util/apiUrls";
import { LpoAction } from "../store/actions/action-types";
import { FetchLPOParams } from "../types";
import { getLPORequestQuery } from "../utils";

export const useGetKYCDetails = () => {
  const dispatch = useDispatch();

  const getKYCDetails = useCallback(
    async (lpoId: number) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(`${API_URL.LPO.KYCDETAIL(lpoId)}`,  dispatch);
        dispatch({ type: LpoAction.SET_KYC_DETAILS, payload: result });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    getKYCDetails,
  };
};
