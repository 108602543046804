import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionTypes,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
  post,
} from "../../../actions";
import { API_URL } from "../../../util/apiUrls";
import { get } from "lodash";

export const useResendAgentInvite = () => {
  const dispatch = useDispatch();

  const resendAgentInvite = useCallback(
    async (agentID: number) => {
      dispatch({ type: ON_LOADING_START });
      let response;
      try {
        response = await post(
          API_URL.AGENTS.RESEND_INVITE(agentID),
          {},
          dispatch
        );
        dispatch({
          type: ActionTypes.SET_RESEND_INVITE_RESPONSE,
          payload: { status: "success", response },
        });
      } catch (e) {
        const error = get(e, "response.data.message");
        dispatch({
          type: ActionTypes.SET_RESEND_INVITE_RESPONSE,
          payload: { status: "error", error, response },
        });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    resendAgentInvite,
  };
};
