import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { connect, ConnectedProps } from "react-redux";
import {
  Button,
  Card,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import { Pie } from "react-chartjs-2";
import Page from "../../components/Page";
import D3PieChart from "./D3PieChart/D3PieChart";
import D3BarGraphChart from "./D3BarGraphChart/D3BarGraphChart";
import D3LineChart from "./D3LineChart/D3LineChart";
import Legend from "./Legend/Legend";
import AgentPerformanceTable from "./AgentPerformanceTable/AgentPerformanceTable";

import gradients from "../../util/gradients";
import { RootState } from "../../reducer";

import { useHistory } from "react-router";
import image1 from "./assets/redMenu.png";
import image2 from "./assets/blueMenu.png";
import image3 from "./assets/greenMenu.png";
import image4 from "./assets/yellowMenu.png";
import SBText from "../../components/base/SBText";
import {
  getAgentPerformanceStatus,
  getDashboardFilter,
  getOverAllApplicationStatus,
  updateFilterValue,
} from "../../actions";
import {
  hasOneOfPermissions,
  PERMISSION,
} from "../../util/rolePermissionsUtil";
import _ from "lodash";

const country = ["India", "Nepal", "Pakistan", "China"];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      display: "flex",
      // alignItems: "center",
      // justifyContent: "center",
      flex: 1,
      height: "92vh",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.2)",
        borderRadius: 15,
      },
    },
    grid: {
      marginTop: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
    boxroot: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    details: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    label: {
      marginLeft: theme.spacing(1),
    },
    avatar: {
      backgroundImage: gradients.green,
      height: 48,
      width: 48,
    },
    locationChangeAvatar: {
      backgroundImage: gradients.red,
      height: 48,
      width: 48,
    },
    coeUpdateAvatar: {
      backgroundImage: gradients.orange,
      height: 48,
      width: 48,
    },
    studyPlanSuccessRoot: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    studyPlanSuccessDetails: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    studyPlanSuccessAvatar: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      height: 48,
      width: 48,
    },
    pos: {
      marginBottom: 12,
    },
    cardContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 140,
      width: "100%",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#ededed",
      },
    },
    cardImage: {
      height: 70,
      width: 70,
    },
    cardCount: {
      color: "#D27F65",
      textAlign: "left",
      fontWeight: "bold",
      fontSize: 30,
    },
    cardLabel: {
      fontSize: 12,
      color: "#4A4A4A",
      fontWeight: "normal",
      marginTop: 1,
    },
    cardTextParent: {
      marginLeft: 16,
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
    cardButton: { width: "100%" },
    cardParent: {
      borderRadius: 10,
      boxShadow: "rgba(0, 0, 0, 0.117647) -1px 2px 6px 4px",
    },
    labelTextStyle: {},
    cardHeaderText: {
      color: theme.palette.primary.dark,
      fontWeight: 700,
      fontSize: 30,
      marginTop: 300,
    },
    cardSubText: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
      fontSize: 16,
      marginTop: 7,
    },
    image: {
      position: "relative",
    },
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",

      height: 200,
      width: 250,
      backgroundColor: "pink",
      backgroundImage: image1,
      // backgroundPosition: "center 40%",
    },

    gridItem: {
      display: "flex",
      height: 140,
      width: 170,
      alignItems: "center",
      justifyContent: "center",
    },

    gridDiv: {
      position: "relative",
      display: "flex",
      // height: 140,
      width: 250,
    },
    outComeHeader: {
      position: "absolute",
      marginTop: 16,
      marginLeft: 16,
      fontWeight: 700,
      color: "white",
      fontSize: 16,
      maxWidth: 120,
    },
    outComeCount: {
      position: "absolute",
      marginTop: 90,
      marginLeft: 16,
      fontWeight: 500,
      color: "white",
      fontSize: 30,
    },
    filterButton: {
      height: 42,
      width: 200,
      border: "1px solid #ced4da",
      backgroundColor: "#F9F9F9",
      display: "flex",
      alignItems: "center",
      fontSize: 10,
      justifyContent: "center",
      marginLeft: 10,
      borderRadius: "10px",
    },
    filterButtonText: { fontSize: 12, fontWeight: 600 },
    gridForCards: {
      // alignItems: "center",
      // justifyContent: "center",
      // backgroundColor: "red",
      paddingLeft: "10%",
    },
    cardContainer: {
      marginTop: "15px",
      width: "100%",
      height: "600px",
      maxHeight: "600px",
      overflowY: "auto",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
    dashboardChartContainer: {
      padding: 0,
      marginTop: "1%",
      height: "100%",
      // maxHeight: "100vh",
      // overflowY: "auto",
      overflowX: "hidden",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.2)",
        borderRadius: 15,
      },
    },
    chartHeader: {
      fontWeight: 500,
      fontSize: "16px",
      color: "rgba(18, 18, 18, 0.5)",
    },
    chartCardContainer: {
      marginTop: "30px",
    },
    barChartLegends: {
      margin: "auto",
      width: "60%",
      textAlign: "center",
      marginTop: "10%",
    },
    legendBox: {
      width: "17px",
      height: "17px",
      backgroundColor: "red",
    },
    legendName: {
      fontWeight: 400,
      fontSize: "16px",
      color: "rgba(18, 18, 18, 0.5)",
    },
    pieChartActionsContainer: {
      marginTop: "3%",
      marginLeft: "3%",
    },
    sectionScroller: {
      margin: "auto",
      padding: "5%",
      height: "470px",
      maxHeight: "470px",
      overflowY: "auto",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.2)",
        borderRadius: 15,
      },
    },
  })
);

const state = {
  labels: ["January", "February", "March", "April", "May"],
  datasets: [
    {
      label: "Rainfall",
      backgroundColor: ["#B21F00", "#C9DE00", "#2FDE00", "#00A6B4", "#6800B4"],
      hoverBackgroundColor: [
        "#501800",
        "#4B5000",
        "#175000",
        "#003350",
        "#35014F",
      ],
      data: [65, 59, 80, 81, 56],
    },
  ],
};

type PropsFromRedux = ConnectedProps<typeof connector>;
type DashboardDefaultProps = PropsFromRedux & {
  classes?: string;
};
const DashboardDefault: React.FC<DashboardDefaultProps> = ({
  getDashboardFilter,
  getAgentPerformanceStatus,
  dashboardFilter,
  agentPerformanceStatus,
  updateFilterValue,
  selectedFilter,
  getOverAllApplicationStatus,
  dashboardData,
  user,
  userPermissions,
  provider,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [
    isDefaultFilterLoaded,
    setDefaultFilterLoaded,
  ] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const countryMenu = Boolean(anchorEl);

  const [
    anchorElProvider,
    setProviderAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const providerMenu = Boolean(anchorElProvider);

  const [anchorElTerms, setTermsAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const termsMenu = Boolean(anchorElTerms);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    // setMenuObj(obj);
  };

  const handleProviderClick = (event: React.MouseEvent<HTMLElement>) => {
    setProviderAnchorEl(event.currentTarget);
    // setMenuObj(obj);
  };

  const handleTermsClick = (event: React.MouseEvent<HTMLElement>) => {
    setTermsAnchorEl(event.currentTarget);
    // setMenuObj(obj);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setTermsAnchorEl(null);
    setProviderAnchorEl(null);
  };

  const onCountrySelected = async (event: any) => {
    // setAnchorEl(null);
    updateFilterValue("country", event);

    if (event != "All" && selectedFilter.country != event) {
      const selectedCountry = _.find(
        dashboardFilter.country,
        (obj: any) => obj.value == event
      );

      updateFilterValue("provider", "All");
      updateFilterValue("providerId", "-1");
      updateFilterValue("term", "All");
      await getDashboardFilter(selectedCountry.id);
    } else {
      if (event == "All") {
        await getDashboardFilter(-2);
      }
    }
    handleMenuClose();
  };

  const onProviderSelected = (event: any) => {
    updateFilterValue("provider", event.value);
    updateFilterValue("providerId", event.id);
    handleMenuClose();
  };

  const onTermsSelected = (event: any) => {
    updateFilterValue("term", event);
    handleMenuClose();
  };

  useEffect(() => {
    getFilters();
    if (
      hasOneOfPermissions(
        [
          PERMISSION.dashboard_provider_view_global,
          PERMISSION.dashboard_provider_view_org,
          PERMISSION.dashboard_provider_view_own,
          PERMISSION.dashboard_provider_view_cph,
          PERMISSION.dashboard_agent_view_own,
          PERMISSION.dashboard_agent_view_org,
          PERMISSION.dashboard_agent_view_main_agency,
        ],
        userPermissions
      )
    ) {
      getAgentPerformanceStatus();
    }
  }, []);

  useEffect(() => {
    if (isDefaultFilterLoaded) {
      getOverAllApplicationStatus();
    }
  }, [selectedFilter, isDefaultFilterLoaded]);

  const getFilters = async () => {
    const result: any = await getDashboardFilter(-1);

    if (
      result &&
      result.country &&
      hasPerm([
        PERMISSION.applications_agent_view_org,
        PERMISSION.applications_agent_view_own,
        PERMISSION.dashboard_provider_view_global,
        PERMISSION.dashboard_provider_view_cph,
        PERMISSION.dashboard_agent_view_main_agency,
      ])
    ) {
      const countryObj = _.find(
        result.country,
        (obj: any) => obj.value == "Australia"
      );
      if (countryObj) {
        await getDashboardFilter(countryObj.id);
        updateFilterValue("country", "Australia");
      }
      setDefaultFilterLoaded(true);
    } else {
      if (provider && provider.length > 0) {
        updateFilterValue("country", provider[0].country);
        updateFilterValue("provider", provider[0].name);
        updateFilterValue("providerId", provider[0].id);
        setDefaultFilterLoaded(true);
      }
    }
  };

  const getFormattedRegionData: any = (regionData: any) => {
    let color = [
      "linear-gradient(180deg, #3C5687 0%, #111E35 100%)",
      "linear-gradient(180deg, #D27F65 9.38%, #541603 100%)",
      "linear-gradient(180deg, #8AB59D 0%, #84A995 13.02%, #759583 26.56%, #345040 100%)",
      "linear-gradient(180deg, #ECCC97 0%, #976109 100%)",
      "linear-gradient(180deg, #0F4A98 0%, #88B1F9 0.01%, #06378B 95.31%)",
      "linear-gradient(180deg, #BDBEC7 0%, rgba(13, 13, 13, 0.76) 100%)",
    ];
    let tempData: any = [],
      i = 0;
    regionData.forEach((element: any) => {
      if (i === color.length) i = 0;
      let dataPointArray = [];
      for (let j = 0; j < element.weeks.length; j++) {
        dataPointArray.push([element.weeks[j], element.weekCount[j]]);
      }
      tempData.push({
        country: element.name,
        count: element.count,
        backgroundColor: color[i],
        dataPointArray: dataPointArray,
      });
      i++;
    });
    return tempData;
  };

  const hasPerm = (perm: PERMISSION[]) => {
    return hasOneOfPermissions(perm, userPermissions);
  };

  const getTotalDisplayCount = () => {
    let count = 0;
    if (
      dashboardData &&
      dashboardData.pieChart &&
      dashboardData.pieChart.length > 0
    ) {
      const openObj = _.find(
        dashboardData.pieChart,
        (obj: any) => obj.name == "Open"
      );

      if (openObj) {
        count = count + openObj.count;
      }

      const closedObj = _.find(
        dashboardData.pieChart,
        (obj: any) => obj.name == "Closed"
      );
      if (closedObj) {
        count = count + closedObj.count;
      }

      const rejectedObj = _.find(
        dashboardData.pieChart,
        (obj: any) => obj.name == "Rejected"
      );
      if (rejectedObj) {
        count = count + rejectedObj.count;
      }

      const withdrawnObj = _.find(
        dashboardData.pieChart,
        (obj: any) => obj.name == "Withdrawn"
      );
      if (withdrawnObj) {
        count = count + withdrawnObj.count;
      }
    }

    return count;
  };

  // console.log("dashboardFilter", dashboardFilter);

  return (
    <Page className={classes.root} title="Dashboard">
      {/*<SBText*/}
      {/*  style={{ textAlign: "center" }}*/}
      {/*  text={"Dashboard Launching Soon."}*/}
      {/*  className={classes.cardHeaderText}*/}
      {/*/>*/}

      <div
        style={{
          display: "flex",
          height: "100",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: 80,
        }}
      >
        <SBText text={"Filters"} />

        {hasPerm([
          PERMISSION.applications_agent_view_own,
          PERMISSION.applications_agent_view_org,
          PERMISSION.applications_provider_view_global,
          PERMISSION.dashboard_provider_view_cph,
          PERMISSION.dashboard_agent_view_main_agency,
        ]) ? (
          <>
            <Button className={classes.filterButton} onClick={handleMenuClick}>
              <SBText
                text={"Country : " + selectedFilter.country}
                className={classes.filterButtonText}
                noWrap={true}
              />
              <ExpandMoreIcon />
            </Button>
            <Button
              className={classes.filterButton}
              onClick={handleProviderClick}
            >
              <SBText
                text={"Provider : " + selectedFilter.provider}
                className={classes.filterButtonText}
                noWrap={true}
              />
              <ExpandMoreIcon />
            </Button>{" "}
          </>
        ) : null}

        <Button className={classes.filterButton} onClick={handleTermsClick}>
          <SBText
            text={"Term : " + selectedFilter.term}
            className={classes.filterButtonText}
            noWrap={true}
          />
          <ExpandMoreIcon />
        </Button>
      </div>

      <Grid container spacing={1} style={{ marginTop: 30 }}>
        <Grid item md={4} xs={6} xl={3} lg={3} className={classes.gridItem}>
          <div className={classes.gridDiv}>
            <SBText
              text={
                dashboardData && dashboardData.outcomes
                  ? dashboardData.outcomes[0].name
                  : "-"
              }
              className={classes.outComeHeader}
            />
            <SBText
              text={
                dashboardData && dashboardData.outcomes
                  ? dashboardData.outcomes[0].count
                  : "-"
              }
              className={classes.outComeCount}
            />
            <img src={image3} />
          </div>
        </Grid>

        <Grid item md={4} xs={6} xl={3} lg={3} className={classes.gridItem}>
          <div className={classes.gridDiv}>
            <SBText
              text={
                dashboardData && dashboardData.outcomes
                  ? dashboardData.outcomes[1].name
                  : "-"
              }
              className={classes.outComeHeader}
            />
            <SBText
              text={
                dashboardData && dashboardData.outcomes
                  ? dashboardData.outcomes[1].count
                  : "-"
              }
              className={classes.outComeCount}
            />
            <img src={image2} />
          </div>
        </Grid>

        <Grid item md={4} xs={6} xl={3} lg={3} className={classes.gridItem}>
          <div className={classes.gridDiv}>
            <SBText
              text={
                dashboardData && dashboardData.outcomes
                  ? dashboardData.outcomes[2].name
                  : "-"
              }
              className={classes.outComeHeader}
            />
            <SBText
              text={
                dashboardData && dashboardData.outcomes
                  ? dashboardData.outcomes[2].count
                  : "-"
              }
              className={classes.outComeCount}
            />
            <img src={image1} />
          </div>
        </Grid>

        <Grid item md={4} xs={6} xl={3} lg={3} className={classes.gridItem}>
          <div className={classes.gridDiv}>
            <SBText text={"Visa"} className={classes.outComeHeader} />
            <SBText
              text={dashboardData ? dashboardData.Visa : "-"}
              className={classes.outComeCount}
            />
            <img src={image4} />
          </div>
        </Grid>
      </Grid>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={countryMenu}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
      >
        {dashboardFilter && dashboardFilter.country ? (
          <>
            {dashboardFilter.country.map((obj: any, i: number) => (
              <MenuItem
                onClick={(event) => {
                  onCountrySelected(obj.value);
                }}
                style={{
                  padding: 0,
                }}
                value={obj.value}
              >
                <div
                  style={{
                    display: "flex",
                    height: 40,
                    alignItems: "center",
                    width: 200,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <SBText text={obj.value} />
                </div>
              </MenuItem>
            ))}
          </>
        ) : null}
      </Menu>

      <Menu
        id="fade-menu"
        anchorEl={anchorElProvider}
        keepMounted
        open={providerMenu}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={(event) => {
            onProviderSelected({ value: "All", id: -1 });
          }}
          style={{
            padding: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              height: 40,
              alignItems: "center",
              width: 200,
              justifyContent: "space-between",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <SBText text={"All"} />
          </div>
        </MenuItem>

        {dashboardFilter && dashboardFilter.providers ? (
          <>
            {dashboardFilter.providers.map((obj: any, i: number) => (
              <MenuItem
                onClick={(event) => {
                  onProviderSelected(obj);
                }}
                style={{
                  padding: 0,
                }}
                value={obj.value}
              >
                <div
                  style={{
                    display: "flex",
                    minHeight: 40,
                    alignItems: "center",
                    width: 200,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    textOverflow: "ellipsis",
                  }}
                >
                  <SBText
                    text={obj.value}
                    style={{ width: 190 }}
                    noWrap={true}
                  />
                </div>
              </MenuItem>
            ))}
          </>
        ) : null}
      </Menu>

      <Menu
        id="fade-menu"
        anchorEl={anchorElTerms}
        keepMounted
        open={termsMenu}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={(event) => {
            onTermsSelected("All");
          }}
          style={{
            padding: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              height: 40,
              alignItems: "center",
              width: 200,
              justifyContent: "space-between",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <SBText text={"All"} />
          </div>
        </MenuItem>

        {dashboardFilter && dashboardFilter.intakes ? (
          <>
            {dashboardFilter.intakes.map((obj: any, i: number) => (
              <MenuItem
                onClick={(event) => {
                  onTermsSelected(obj.value);
                }}
                style={{
                  padding: 0,
                }}
                value={obj.value}
              >
                <div
                  style={{
                    display: "flex",
                    height: 40,
                    alignItems: "center",
                    width: 200,
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <SBText text={obj.value} />
                </div>
              </MenuItem>
            ))}
          </>
        ) : null}
      </Menu>
      <div>
        <div style={{ margin: "auto", width: "90%" }}>
          <Grid container spacing={4}>
            <Grid
              item
              md={4}
              xs={12}
              xl={6}
              lg={6}
              className={classes.gridForCards}
            >
              <div className={classes.chartCardContainer}>
                <Typography className={classes.chartHeader}>
                  New/Closed Applications
                </Typography>
                <Card className={classes.cardContainer}>
                  <div className={classes.barChartLegends}>
                    <Grid container spacing={1}>
                      <Grid item md={4} xs={12} xl={6} lg={6}>
                        <Legend
                          legendName={"New Applications"}
                          legendColor={"#3D405B"}
                        />
                      </Grid>
                      <Grid item md={4} xs={12} xl={6} lg={6}>
                        <Legend
                          legendName={"Applications Closed"}
                          legendColor={"#D27F65"}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ padding: "5%", marginTop: "6%" }}>
                    {dashboardData?.barChart &&
                      dashboardData?.barChart.length > 0 && (
                        <D3BarGraphChart data={dashboardData.barChart} />
                      )}
                  </div>
                </Card>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              xl={6}
              lg={6}
              className={classes.gridForCards}
            >
              <div className={classes.chartCardContainer}>
                <Typography className={classes.chartHeader}>
                  Application Status
                </Typography>
                <Card className={classes.cardContainer}>
                  <div className={classes.pieChartActionsContainer}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#121212",
                      }}
                    >
                      Status Matrix
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          marginRight: "10px",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        Application Count:
                      </span>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#121212",
                        }}
                      >
                        {getTotalDisplayCount()}
                      </span>
                    </div>
                  </div>
                  <div style={{ marginLeft: "0%" }}>
                    {dashboardData?.pieChart && (
                      <D3PieChart data={dashboardData?.pieChart} />
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: "-15%",
                      width: "20%",
                      marginLeft: "3%",
                      marginBottom: "3%",
                    }}
                  >
                    <div>
                      <Legend legendName={"Open"} legendColor={"#ECCC97"} />
                    </div>
                    <Legend legendName={"Closed"} legendColor={"#8BB19C"} />
                    <Legend legendName={"Rejected"} legendColor={"#D27F65"} />
                    <Legend legendName={"Withdrawn"} legendColor={"#3D405B"} />
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>

          {hasPerm([
            PERMISSION.applications_provider_view_own,
            PERMISSION.dashboard_provider_view_global,
            PERMISSION.applications_provider_view_org,
            PERMISSION.applications_provider_view_cph,
            PERMISSION.applications_agent_view_main_agency,
          ]) ? (
            <Grid container spacing={4}>
              <Grid
                item
                md={4}
                xs={12}
                xl={8}
                lg={8}
                className={classes.gridForCards}
              >
                <div className={classes.chartCardContainer}>
                  <Typography className={classes.chartHeader}>
                    Agent Performance
                  </Typography>
                  <Card
                    className={classes.cardContainer}
                    style={{ padding: "2% 2%" }}
                  >
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Top Agents
                    </div>
                    {agentPerformanceStatus.length && (
                      <AgentPerformanceTable data={agentPerformanceStatus} />
                    )}
                  </Card>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
                xl={4}
                lg={4}
                className={classes.gridForCards}
              >
                <div className={classes.chartCardContainer}>
                  <Typography className={classes.chartHeader}>
                    Region
                  </Typography>
                  <Card
                    className={classes.cardContainer}
                    style={{ padding: "8%" }}
                  >
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                      }}
                    >
                      Historical Weekly Application Progression by Region
                    </div>
                    <div className={classes.sectionScroller}>
                      {dashboardData?.getRegionalStatus &&
                        getFormattedRegionData(
                          dashboardData?.getRegionalStatus
                        ).map((element: any) => (
                          <D3LineChart
                            backgroundColor={element.backgroundColor}
                            country={element.country}
                            count={element.count}
                            dataPointArray={element.dataPointArray}
                          />
                        ))}
                    </div>
                  </Card>
                </div>
              </Grid>
            </Grid>
          ) : null}
        </div>
      </div>
    </Page>
  );
};
const mapState = (state: RootState) => ({
  user: state.user.user,
  dashboardFilter: state.dashboard.dashboardFilter,
  selectedFilter: state.dashboard.selectedFilter,
  dashboardData: state.dashboard.dashboardData,
  agentPerformanceStatus: state.dashboard.agentPerformanceStatus,
  userPermissions: state.user.permissions,
  provider: state.user.provider,
});

const mapDispatch = {
  getDashboardFilter,
  getAgentPerformanceStatus,
  updateFilterValue,
  getOverAllApplicationStatus,
};

const connector = connect(mapState, mapDispatch);
export default connector(DashboardDefault);
