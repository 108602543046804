import { Theme } from "@material-ui/core";
import { CSSProperties, StyleRules } from "@material-ui/styles";
import AppColor from "../theme/AppColor";

export const formDialogStyle: (theme: Theme) => StyleRules = (
  theme: Theme
): StyleRules => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },
  sbDialog: {
    "& .MuiDialogTitle-root": {
      padding: 0,
      "& .MuiTypography-root": {
        minHeight: 70,
        width: "100%",
        backgroundColor: AppColor.appBlue,
        display: "flex",
        alignItems: "center",
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: "space-between",
        "& p": {
          fontSize: 16,
          color: AppColor.appWhite,
          fontWeight: 600,
          paddingLeft: 20,
        },
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "15px",
      marginBottom: "15px",
      borderTop: "1px solid #DDD",
      padding: 0,
      paddingTop: 20,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 5,
    },
  },
  formField: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "0px 14px",
    alignItems: "center",
  },
  Label: {
    width: "100%",
    paddingTop: "15px",
    paddingBottom: "5px",
  },
  textField: {
    width: "100%",
    borderRadius: "5px",
    "&$error": {
      color: "red",
    },
  },
  requiredFieldLabel: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.primary.light,
    "&::after": {
      content: "' *'",
      color: "red",
    },
  },
  multipleButtonsWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    "& .MuiButtonBase-root": {
      paddingRight: "12px",
      paddingLeft: "12px",
      marginLeft: "12px"
    },
  },
});
