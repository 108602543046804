
export enum Roles {
    Councillor = "Counsellor",
    AdmissionOfficers = "Admission Officer",
    AgencyOwner = "Agency Owner"
    // AdmissionOfficersLeader = "ADMISSION_TEAM_LEADER",
    // LPCAdministrator = "LPC_ADMIN",
    // AgentAdministrator = "AGENT_ADMIN",
  }
  

