import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AppColor from "../../theme/AppColor";
import SBText from "../../components/base/SBText";
import {
  colors,
  IconButton,
  List,
  ListItem,
  Collapse,
} from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";

import moment from "moment";
import Button from "@material-ui/core/Button";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { getApplicationIdInString } from "../../util";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import _ from "lodash";
import { ClosedCaptionOutlined, HighlightOffRounded } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SBTextField from "../../components/base/SBTextField";
import SBButton from "../../components/base/SBButton";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "40%",
    display: "flex",
    flexDirection: "column",
    height: "80%",
  },
  root: {
    width: "100%",
    paddingLeft: 0,
    overflowY: "scroll",

    backgroundColor: theme.palette.background.paper,
  },
  ul: {
    padding: 0,
  },
  companyText: {
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  agentText: {
    fontSize: 11,
    fontWeight: 500,
    color: AppColor.appDarkGrey,
    [theme.breakpoints.up(1920)]: {
      fontSize: 12,
    },
  },
  listParent: {
    minHeight: 70,
    width: "100%",
    backgroundColor: AppColor.appBlue,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: "space-between",
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",

    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,

    width: "84%",
  },
  childListItem: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",

    paddingLeft: 20,
    width: "84%",
  },
  listItemParent: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: AppColor.appLightGrey,
    "&:hover": {
      backgroundColor: colors.blueGrey[50],
    },
  },

  listItemParentForChild: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: AppColor.appLightGrey,
    paddingTop:8,
    paddingBottom:8,
    alignItems:'center'
  },

  
  listSection: {
    backgroundColor: "inherit",
    width: "100%",
  },
  ul1: {
    backgroundColor: "inherit",
    padding: 0,
  },
  selectAgentTxt: {
    fontSize: 16,
    color: AppColor.appWhite,
    fontWeight: 600,
    [theme.breakpoints.up(1920)]: {
      fontSize: 18,
    },
  },
  searchInputTxt: {
    fontSize: 12,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
}));

type AgentListModelProps = {
  className?: string;
  open: any;
  onClose: () => void;
  data: any;
  onSelectAgent: (obj: any) => void;
  onGetSubAgent: (companyId: number) => void;
  onCollapseExpand: (companyId: number) => void;
  isVNTUser:boolean;
  onSearchText: (searchText: any) => void
};

const AgentListModel: React.FC<AgentListModelProps> = ({
  children,
  onClose,
  className,
  open,
  data,
  onSelectAgent,
  onGetSubAgent,
  onCollapseExpand,
  isVNTUser,
  onSearchText,
  ...rest
}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [list, setList] = useState(data);
  const sectionHeader =isVNTUser?  [
    { label: "Channel Partner Head", key: "cphUser" },
    { label: "Agency List", key: "agencyUser" },
  ] : [
    
    { label: "Agency List", key: "agencyUser" },
  ];

  

  const handleClose = () => {
    // setOpen(false);
  };

  const getFileName = (fileName: string) => {
    // let filename = dataObject.answer.fileName;
    if (fileName.includes("#")) {
      fileName = fileName.split("#")[1];
    }
    return fileName;
  };

  useEffect(() => {
    // console.log("Use Effect in Model", data);
    const dataArray: any = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        dataArray.push(false);
      }
      setExpand(dataArray);
    }
  }, []);

  const onChangeText = (text: string) => {
    setSearchValue(text);
    onSearchText(text)

  };

  const getDataList = () => {
    if (data) {
      return data;
    } else {
      return { cphUser: [], agencyUser: [] };
    }
  };

  const onCloseModel = () => {
    onClose();
    setExpand([]);
  };

  
  const getTitle=(isCPHUser :boolean, index:number)=> {
      if(isCPHUser) {

        return 'Brand Manager'
      } else {

        return index == 0 ? "Agency Owner" : "Counsellor"

      }
  } 

  const getName = (item: any) => {
    if (item.companyDetails && item.companyDetails.companyName) {
      return item.companyDetails.companyName;
    } else {
      if(item.user) {
        if(item.user.firstName && item.user.lastName)  {
          return `${item.user.firstName} ${item.user.lastName}`
        } else {
          return `${item.user.name}`
        }
      }
      return "-"
    }
  }; 
  const getCounsellorAgencyChild = (item: any, index:number, isCPHUser:boolean) => {
    return (
      <div className={classes.listItemParentForChild}>
        <div
          className={classes.childListItem}
          onClick={() => {
            // onSelectAgent(item)
          }}
        >
          <SBText
            text={
              item.firstName && item.lastName
                ? item.firstName + " " + item.lastName
                : item.name
            }
            className={classes.companyText}
          />
          <SBText text={getTitle(isCPHUser,index)}
           className={classes.agentText} 
           />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "16%",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <SBButton
            text={"Assign"}
            icon={false}
            onClick={() =>  onSelectAgent(item.id)}
            style={{ width: 40, height: 30 }}
          />

          <IconButton
            style={{ height: 20, width: 20 }}
            onClick={() => {
                // if(item.child) {
                //   onCollapseExpand(item.companyDetails.id)
                // } else {
                //   onGetSubAgent(item.companyDetails.id)
                // }
              }}     
          >
            {item.childCount > 0 ? <NavigateNextIcon /> : null}
          </IconButton>
        </div>
      </div>
    );
  };

  const getAgencyListItem = (item: any) => {
    return (
      // <ul className={classes.ul}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div className={classes.listItemParent}>
          <div
            className={classes.listItem}
            onClick={() => {
              // onSelectAgent(item)
            }}
          >
            <SBText
              text={
                getName(item)
              }
              className={classes.companyText}
            />
            <SBText
              text={
                item.companyDetails && item.companyDetails.agentCode
                  ? item.companyDetails.agentCode
                  : "-"
              }
              className={classes.agentText}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "16%",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >

            <IconButton
              style={{ height: 20, width: 20 }}
              onClick={() => {
                if((item.childCount == 0 && item.child.length == 1) || (item.childCount > 0  &&item.child.length >1)  || item.isCPHUser) {
                  onCollapseExpand(item.companyDetails.agentCode)
                } else {
                  onGetSubAgent(item.companyDetails.id)
                }
              }}   
            >
              { item.expand ? <KeyboardArrowDownIcon/> : <NavigateNextIcon /> }
            </IconButton>
          </div>
        </div>

        {item.child ? (
          <Collapse in={item.expand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.child.map((user: any, index:number) => (
                <div
                  key={`item-${user.id}`}
                  style={{
                    width: "100%",
                    display: "flex",
                    paddingLeft:0,
                    paddingRight:0,
                    alignItems: "center",
                    backgroundColor:'#f9f9f9'
                  }}
                >
                  {getCounsellorAgencyChild(user, index, item.isCPHUser)}
                </div>
              ))}
            </List>
          </Collapse>
        ) : null}
      </div>
      // </ul>
    );
  };

  const getCPHListItem = (item: any) => {
    return (
      // <ul className={classes.ul}>
      <>
        <div className={classes.listItemParent}>
          <div
            className={classes.listItem}
            onClick={() => {
              // onSelectAgent(item)
            }}
          >
            <SBText
              text={
                item.firstName
                  ? item.firstName + " " + item.lastName
                  : item.name
              }
              className={classes.companyText}
            />
            <SBText
              text={item.cphCode ? item.cphCode : "-"}
              className={classes.agentText}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "16%",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <SBButton
              text={"Assign"}
              icon={false}
              onClick={() => onSelectAgent(item.id)}
              style={{ width: 40, height: 30 }}
            />

            <IconButton style={{ height: 20, width: 20 }}></IconButton>
          </div>
        </div>
      </>
      // </ul>
    );
  };

  // console.log("data", data);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.listParent}>
            <SBText
              text={"Select agent for your application"}
              className={classes.selectAgentTxt}
            />

            <HighlightOffRounded
              onClick={() => onClose()}
              style={{ marginLeft: 10, color: AppColor.appWhite }}
            />
          </div>

          <div
            style={{
              width: "100%",
              marginTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <SBTextField
              placeholder={"Search agent by name or agent code"}
              onChange={(event: any) => onChangeText(event.target.value)}
              value={searchValue}
              name="agent"
              className={classes.searchInputTxt}
              onDataChange={() => console.log()}
            />
          </div>
          {data ? (
            <List className={classes.root}>
                  <ul className={classes.ul}>
                    {getDataList().map((item: any) => (
                      <ListItem
                        style={{ width: "100%", display: "flex", padding: 0 }}
                      >
                        { getAgencyListItem(item)}
                      </ListItem>
                    ))}
                  </ul>
            </List>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
};

export default AgentListModel;

{
  /* {getDataList().map((item: any, index: number) => (
                <>
                  {item.user ? getAgencyListItem(item) : getCPHListItem(item)}
                </>
              ))} */
}
