import { Button, TextField, Theme } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  clearUserListFilter,
  createNewUser,
  dispatchUser,
  getAllAgencies,
  getAllProviders,
  getEntityList,
  getRoleList,
  getUser,
  getUserList,
  updateUser,
  updateUserListFilter,
} from "../../actions";
import SBButton from "../../components/base/SBButton";
import SBText from "../../components/base/SBText";
import { useUserPermissions } from "../../hooks/useUserPermissions";
import { RootState } from "../../reducer";
import { UserListFilter } from "../../reducer/types";
import AppColor from "../../theme/AppColor";
import { hasPermission, PERMISSION } from "../../util/rolePermissionsUtil";
import UserFilter from "./UserFilter";
import UserModel from "./UserModal";
import UserTable from "./UserTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#F1F1F1",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    newAppBt: {
      width: 130,
      height: 50,
      borderRadius: 30,
      textTransform: "none",
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        width: 130,
        fontSize: 14,
      },
    },
    tabBasic: {
      height: 40,
      width: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tabSelected: {
      height: 50,
      width: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cardContent: {
      display: "flex",
      minHeight: "70vh",
      flexDirection: "column",
      padding: 0,
    },
    nameCircle: {
      height: 78,
      width: 78,
      borderRadius: 40,
      backgroundColor: "#727487",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    circleText: { color: "white", fontWeight: "bold" },
    name: { color: "black", fontWeight: "bold" },
    appId: { color: "#898989", marginTop: 10 },
    headerTextParent: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 35,
    },
    cardHeader: {
      flex: 0.1,
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: 22,
    },
    cardStatus: {
      flex: 0.6,
      width: "100%",
      display: "flex",
      backgroundColor: "pink",
      flexDirection: "row",
    },
    customWidth: {
      "& div": {
        // this is just an example, you can use vw, etc.
        width: "200px",
        fontFamily: "Poppins",
        borderRadius: 0,
      },
    },
    input: {
      height: 40,
      border: "none",
      backgroundColor: "white",
      marginLeft: 10,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
    tabsParent: {
      height: 60,
      display: "flex",
      marginTop: 20,
      justifyContent: "space-between",
    },
    listContainer: {
      height: "calc(100vh - 242px)",
      [theme.breakpoints.up(1920)]: {
        height: "calc(100vh - 242px)",
      },
      overflow: "hidden",
    },
    searchSortParents: {
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.up(1920)]: {
        height: 60,
        alignItems: "unset",
      },
    },
    tabSubParents: {
      display: "flex",
      alignItems: "flex-end",
    },
    searchbox: {
      border: "none",
      "& .MuiOutlinedInput-root": {
        position: "unset",
      },
      "& .MuiInputBase-input": {
        color: "#000000",
        fontFamily: "Poppins",
        fontWeight: 400,
        letterSpacing: "0.75px",
        textTransform: "none",
        fontSize: 14,
      },
      "& fieldset": {
        border: "unset",
      },
    },
    filterBtn: {
      width: 100,
      height: 40,
      marginLeft: 10,
      borderWidth: 1,
      borderStyle: "solid",
      backgroundColor: "white",
      borderColor: AppColor.appLightGrey,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
    inherited: {
      "& .MuiFormControl-root": {
        position: "unset",
      },
      height: "fit-content",
    },
    tabDivider: { width: 10 },
  })
);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SBApplicationListProps = PropsFromRedux & {};

const UserList: React.FC<SBApplicationListProps> = ({
  userListDetails,
  userListFilter,
  updateUserListFilter,
  clearUserListFilter,
  getUserList,
  createNewUser,
  updateUser,
  loggedInUser,
  providers,
  getAllProviders,
  agencies,
  getAllAgencies,
  entityListDetails,
  getEntityList,
  roleListDetails,
  getRoleList,
  selectedUserDetails,
  dispatchUser,
  getUser,
  ...rest
}) => {
  const classes = useStyles();
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [newPage, setNewPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [openModal, setOpenModel] = React.useState(false);

  useEffect(() => {
    loadAllAgencies();
    loadAllProviders();
    loadRoleList();
    return () => {};
  }, []);

  useEffect(() => {
    //Initial user load + every change of filter
    reloadUserList();
  }, [userListFilter]);

  const onClose = (): void => {
    dispatchUser(undefined);
    setOpenModel(false);
  };

  const hasPerm = (perm: string) => {
    return hasPermission(perm, userPermissions);
  };

  const reloadUserList = async () => {
    setPage(0);
    await getUserList({
      offset: 0,
      limit: rowsPerPage,
      searchText: searchText,
      filter: userListFilter,
    });
  };

  const loadAllProviders = async () => {
    await getAllProviders();
  };

  const loadAllAgencies = async () => {
    await getAllAgencies();
  };

  const loadRoleList = async () => {
    await getRoleList({ offset: 0, limit: 50, searchText: "" });
  };

  const onChangeSearchText = async (event: any) => {
    setSearchText(event.target.value);
  };

  const onSearchClick = async () => {
    await reloadUserList();
  };

  const onApplyFilter = async (filter: UserListFilter) => {
    await updateUserListFilter(filter);
  };

  const onClearFilter = async () => {
    await clearUserListFilter();
  };

  const handleChangePage = async (
    // event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    await getUserList({
      offset: rowsPerPage * (newPage + 1),
      limit: rowsPerPage,
      searchText: searchText,
      filter: userListFilter,
    });
  };

  const onModalClick = (item: any) => {
    getUser(item.id);
    setOpenModel(true);
  };
  const onCreateOrUpdate = async (user: any) => {
    if (user && !user.name) {
      user.name = `${user.firstName} ${user.lastName}`;
    }
    const result: any = user.id
      ? await updateUser(user)
      : await createNewUser(user);
    setOpenModel(false);
    dispatchUser(undefined);
    setPage(0);
    setNewPage(0);
  };

  // @ts-ignore
  return (
    <div className={classes.root}>
      {hasPerm(PERMISSION.user_create) && (
        <SBButton
          icon={false}
          text={"+ New User"}
          className={classes.newAppBt}
          onClick={() => {
            dispatchUser(undefined);
            setOpenModel(true);
          }}
        />
      )}
      <div className={classes.tabsParent}>
        {/* tabs for user,role,entity */}
        {rest.children}

        <div className={classes.searchSortParents}>
          <div className={classes.inherited}>
            <TextField
              id="input-with-icon-textfield"
              variant="outlined"
              placeholder={"Search Text"}
              className={classes.searchbox}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={onSearchClick}>
                    <SearchIcon />
                  </InputAdornment>
                ),
                className: classes.input,
              }}
              onChange={onChangeSearchText}
              onKeyPress={(ev) => {
                // console.log(`Pressed keyCode ${ev.key}`);
                if (ev.key === "Enter") {
                  // Do code here
                  onSearchClick();
                  ev.preventDefault();
                }
              }}
            />
            {hasPerm(PERMISSION.user_view_global) && (
              <Button
                className={classes.filterBtn}
                onClick={() => setFilterOpen(true)}
              >
                <FilterListIcon />
                <SBText text={"Filter"} style={{ marginLeft: 10 }} />
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={classes.listContainer}>
        {userListDetails?.count === 0 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <AnnouncementIcon
              style={{ fill: "#8F928E", height: 150, width: 150, opacity: 0.6 }}
            />
            <SBText
              text={`No users exist`}
              style={{
                fontSize: 20,
                fontFamily: "Poppins",
                fontWeight: "600",
                color: "#8F928E",
              }}
            />
          </div>
        ) : (
          <UserTable
            handlePageChange={handleChangePage}
            data={userListDetails?.rows || []}
            userPermissions={userPermissions}
            newPage={newPage}
            setNewPage={(value) => setNewPage(value)}
            onModelClick={onModalClick}
          />
        )}
      </div>
      <UserModel
        open={openModal}
        onClose={onClose}
        data={selectedUserDetails}
        onSave={onCreateOrUpdate}
        agencies={agencies || []}
        providers={providers || []}
        loggedInUser={loggedInUser}
        userPermissions={userPermissions}
        roles={roleListDetails?.rows || []}
      />

      <UserFilter
        filterOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApplyFilter={onApplyFilter}
        onClearFilter={onClearFilter}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  userListDetails: state.users.userListDetails,
  userListFilter: state.users.userListFilter,
  agencies: state.entities.agencies,
  loggedInUser: state.user?.user?.user,
  providers: state.providers.all,
  entityListDetails: state.entities.entityListDetails,
  roleListDetails: state.roles.roleListDetails,
  selectedUserDetails: state.users.selectedUserDetails,
});

const mapDispatch = {
  getUserList,
  createNewUser,
  updateUser,
  getEntityList,
  getAllAgencies,
  getAllProviders,
  getRoleList,
  dispatchUser,
  updateUserListFilter,
  clearUserListFilter,
  getUser,
};

const connector = connect(mapState, mapDispatch);
export default connector(UserList);
