import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducer";
import { ActionType } from "../reducer/types";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";
import { get, post } from "./common";
import config from "../config";
export const SET_DASHBOARD_FILTER = "SET_DASHBOARD_FILTER";
export const CHANGE_DASHBOARD_FILTER = "CHANGE_DASHBOARD_FILTER";
export const SET_DASHBOARD_DETAILS_DATA = "SET_DASHBOARD_DETAILS_DATA";
export const SET_AGENT_PERFORMANCE_STATUS = "SET_AGENT_PERFORMANCE_STATUS";

export const getDashboardFilter = (
  id: number
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });
      const { provider } = getState().user;
      // console.log("provider", provider);
      let countryId;
      if (provider && provider.length>0) {
        countryId = provider[0].countryId;
      } else {
        countryId = id;
      }

      const result = await get(
        `${config.BASE_URL}/application/dashFilter/${countryId}`,
        null,
        dispatch
      );
      // console.log("Filter  List", result);
      await dispatch({
        type: SET_DASHBOARD_FILTER,
        payload: result,
      });

      //console.log("Application Details :::", result);
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    }
  );
};

export const getAgentPerformanceStatus = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch, getState) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });

      const result = await get(
        `${config.BASE_URL}/application/agentPerformanceStatus`,
        null,
        dispatch
      );
      // console.log("Agent Performance Status", result);
      await dispatch({
        type: SET_AGENT_PERFORMANCE_STATUS,
        payload: result,
      });

      //console.log("Application Details :::", result);
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    }
  );
};

export const getOverAllApplicationStatus = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch, getState) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      // @ts-ignore
      const { selectedFilter } = getState().dashboard;

      // console.log("Dashboard  Filter:", selectedFilter);
      dispatch({ type: ON_LOADING_START });

      const result = await post(
        `${config.BASE_URL}/application/status`,
        selectedFilter,
        dispatch
      );
      // console.log("Dashboard Detaail Result:", result);
      dispatch({ type: SET_DASHBOARD_DETAILS_DATA, payload: result });
      resolve(result);

      dispatch({ type: ON_LOADING_FINISHED });
    }
  );
};

export const updateFilterValue = (
  key: string,
  value: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: CHANGE_DASHBOARD_FILTER,
    payload: { key, value },
  });
};
