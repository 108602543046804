import React from "react";
import ReportsView from "./ReportsView";


const Reports = () => {
  return (
    <ReportsView />
  );
};

export default Reports;
