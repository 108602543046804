import { SvgIcon } from "@material-ui/core";
import React from "react";

export function AcceptanceIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 57 57" fill="none">
      <g opacity="0.6">
        <path
          d="M45 9.75H35.0725C34.075 6.995 31.4625 5 28.375 5C25.2875 5 22.675 6.995 21.6775 9.75H11.75C9.1375 9.75 7 11.8875 7 14.5V47.75C7 50.3625 9.1375 52.5 11.75 52.5H45C47.6125 52.5 49.75 50.3625 49.75 47.75V14.5C49.75 11.8875 47.6125 9.75 45 9.75ZM28.375 9.15625C29.3487 9.15625 30.1562 9.96375 30.1562 10.9375C30.1562 11.9113 29.3487 12.7188 28.375 12.7188C27.4013 12.7188 26.5938 11.9113 26.5938 10.9375C26.5938 9.96375 27.4013 9.15625 28.375 9.15625ZM45 47.75H11.75V14.5H45V47.75Z"
          fill="#3D405B"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38.4448 21.8516L41.3049 24.648L25.2082 41.1105L16.4448 32.148L19.3049 29.3516L25.2082 35.389L38.4448 21.8516Z"
          fill="#3D405B"
        />
      </g>
    </SvgIcon>
  );
}
