import { Grid, Paper, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import SBText from '../../../components/base/SBText';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ReportsDetails from '../ReportDetails';
import { REPORT_LIST } from '../constants';
import { hasOneOfPermissions, PERMISSION } from '../../../util/rolePermissionsUtil';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import { ReportItem } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#E5E5E5',
      padding: theme.spacing(2),
      height: 'calc(100% - 64px)',
      overflow: 'auto',
    },
    paper: {
      height: '100%',
      borderRadius: 0,
    },
    listItem: {
      padding: '14px 18px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 6,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#F7F7F7',
      },
    },
    listItemSelected: {
      backgroundColor: '#EFEFEF',
    },
  })
);

const ReportsView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userPermissions: PERMISSION[] = useUserPermissions();

  const [reportList, setReportList] = useState<ReportItem[]>([]);
  const [selectedReport, setSelectedReport] = useState<ReportItem>();

  useEffect(() => {
    const isProcessingTeamUser = hasOneOfPermissions(
      [
        PERMISSION.applications_provider_view_sb_org,
        PERMISSION.applications_provider_view_global,
        PERMISSION.applications_provider_view_org,
        PERMISSION.applications_provider_view_cph,
      ],
      userPermissions
    );

    const list: any = [];
    REPORT_LIST.forEach((r: any) => {
      if (['POBySubStatusReport', 'CPHBySubStatusReport'].includes(r.type)) {
        isProcessingTeamUser && list.push(r);
      } else {
        list.push(r);
      }
    });
    setReportList(list);
  }, []);

  const onItemClick = (report: ReportItem) => {
    setSelectedReport(report);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} style={{ height: 'calc(100% + 16px)' }}>
        <Grid item xs={4} style={{ height: '100%' }}>
          <Paper className={classes.paper} style={{ padding: 16 }}>
            {reportList.map((report: ReportItem, index: number) => (
              <div key={report.type}>
                <div
                  className={`${classes.listItem} ${
                    selectedReport?.type === report.type ? classes.listItemSelected : ''
                  }`}
                  onClick={() => onItemClick(report)}
                >
                  <DescriptionOutlinedIcon style={{ color: 'gray', marginRight: 12, fontSize: 40 }} />
                  <SBText style={{ fontSize: 16 }} text={report.name} />
                </div>
              </div>
            ))}
          </Paper>
        </Grid>
        <Grid item xs={8} style={{ height: '100%' }}>
          <Paper className={classes.paper}>
            <ReportsDetails report={selectedReport} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default ReportsView;
