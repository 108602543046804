import React, { useEffect, useRef } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { TextField, Theme, Typography, IconButton } from "@material-ui/core";
import { useHistory } from "react-router";
import avtar from "./assets/avatar_sample.png";
import InputBase from "@material-ui/core/InputBase";
import send from "./assets/send.png";
import SBText from "../../../components/base/SBText";
import clsx from "clsx";
import moment from "moment";
import _ from "lodash";
// import SendIcon from "@material-ui/icons/Send";
import SendIcon from "@material-ui/icons/Telegram";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "91%",
      borderWidth: 1,
      borderStyle: "solid",
      marginTop: 10,
      backgroundColor:'white',
      width:'100%'
    },
    messageParent: {
      padding: 6,
      flex: 1,
      overflow: "auto",
      overflowX: "hidden",
      maxHeight: 200,
      minHeight: 200,
      "&::-webkit-scrollbar": {
        width: "0.8em",
        backgroundColor: "#F1F1F1",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D6D3D3",
      },
      "&::-webkit-scrollbar-button:start:decrement": {
        backgroundColor: "#D6D3D3",
        // backgroundImage: `url(${arrow})`,
        backgroundRepeat: "no-repeat",
        // paddingTop: 5,
        // display: "flex",
      },
      "&::-webkit-scrollbar-button:end:increment": {
        backgroundColor: "#D6D3D3",
      },
    },
    sendMessageBox: {
      height: 50,
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: "#3E4E6C",
      alignItems: "center",
      display: "flex",
    },

    label: {
      color: "white",
      marginLeft: 10,
      fontSize: 12,
    },
    row: {
      display: "flex",
      alignItems: "center",
      marginTop: 10,
    },
    green: {
      height: 18,
      width: 18,
      backgroundColor: "#8BB19C",
      borderRadius: 10,
    },
    yellow: {
      height: 18,
      width: 18,
      backgroundColor: "#ECCC97",
      borderRadius: 10,
    },
    red: {
      height: 18,
      width: 18,
      backgroundColor: "#D27F65",
      borderRadius: 10,
    },
    margin: {
      margin: theme.spacing(1),
      width: "83%",
      display: "flex",
    },
    inputStyle: {
      fontFamily: "Poppins",
      fontSize: 12,
      letterSpacing: 0.75,
    },
    sendButton: {
      height: 40,
      width: 40,
    },

    fromParent: {
      padding: 5,
      minHeight: 30,
      backgroundColor: "#B3E5F0",
      minWidth: "90%",
      maxWidth: "90%",
      marginLeft: 5,
      borderTopRightRadius: 7,
      borderTopLeftRadius: 7,
      borderBottomRightRadius: 7,
    },
    toParent: {
      padding: 5,
      backgroundColor: "#BBDECB",
      minWidth: "75%",
      maxWidth: "75%",
      borderTopRightRadius: 7,
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 7,
      marginRight: 5,
    },
    img: {
      height: 30,
      width: 30,
    },
    messageText: {
      fontSize: 12,
      color: theme.palette.common.black,
    },
    nameCircle: {
      height: 30,
      width: 30,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 15,
      backgroundColor: "#D27F65",
      display: "flex",
    },
    userName: { padding: 10, fontSize: 18, color: "white" },

    leftParent: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      marginTop: 5,
    },
    rightParent: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      flexDirection: "row-reverse",
      marginTop: 5,
    },
    rightTimeParent: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },

    messageSubParent: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    timeText: {
      fontSize: 7,
      display: "flex",
      alignSelf: "flexEnd",
      textAlign: "end",
      color: "#A2A1A1",
    },
    rightUserName: {
      fontSize: 8,
      marginBottom: -4,
      marginRight: 40,
    },
    leftUserName: {
      fontSize: 8,
      marginLeft: 40,
      marginBottom: -4,
    },
    rightUsernameParent: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },

    timeParent: { display: "flex", alignItems: "flex-end" },
  })
);

type BlockHintsProps = {
  comments: any;
  onTextChange: (message: string) => void;
  value: string;
  onSendMessage: () => void;
  user: any;
};

const MessageChat: React.FC<BlockHintsProps> = ({
  comments,
  onTextChange,
  onSendMessage,
  value,
  user,
  ...rest
}) => {
  const classes = useStyles();
  const messageEl = useRef(null);

  useEffect(() => {
    if (messageEl && messageEl.current) {
      // @ts-ignore
      messageEl.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, []);

  const getTime = (date: any) => {
    // console.log("date::::",date)
    const now = moment();
    const expiration = moment(date);
    const diff = now.diff(expiration);
    const diffDuration = moment.duration(diff);
    //console.log("diffDuration.days()", diffDuration.days());
    //console.log("diffDuration.hours(),", diffDuration.hours());
    //console.log("diffDuration.minutes(),", diffDuration.minutes());
    //return new Date(date).toTimeString()
    try {
      let current_date: any = new Date();
      let created_date: any = new Date(date);
      let diffInMilliSeconds = Math.abs(current_date - created_date) / 1000;
      let output: any;
      const days = Math.floor(diffInMilliSeconds / 86400);
      diffInMilliSeconds -= days * 86400;
      const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
      diffInMilliSeconds -= hours * 3600;
      const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
      if (days === 0) {
        if (hours === 0) {
          output = `${minutes}m ago`;
        } else {
          output = `${hours}h ago`;
        }
      } else if (days > 7) {
        output = `few d ago`;
      } else {
        output = `${days}d ago`;
      }

      return output;
    } catch (error) {
      console.log(error);
      return NaN;
    }
  };


let messageList = []
if(comments) {
  messageList =  _.sortBy(comments, [function(o) { return o.id; }]); 
} 



  return (
    <div className={classes.root}>
      <div className={classes.messageParent} ref={messageEl}>
        {messageList.map((messageObj: any) => (
          <div>
            <div
              style={{
                display: "flex",
                paddingBottom: 0,
                flexDirection:
                  messageObj.createdUser.id == user.id ? "row" : "row-reverse",
              }}
            >
              {messageObj.createdUser.id == user.id ? (
                <div className={classes.leftParent}>
                  <div style={{ width: "100%" }}>
                    <SBText
                      text={messageObj.createdUser.name}
                      className={classes.leftUserName}
                    />

                    <div className={classes.messageSubParent}>
                      <div
                        style={{
                          display: "flex",
                          width: "68%",
                        }}
                      >
                        <div className={classes.nameCircle}>
                          <SBText
                            text={
                              messageObj.createdUser.name
                                ? messageObj.createdUser.name
                                    .substring(0, 1)
                                    .toUpperCase()
                                : "-"
                            }
                            className={classes.userName}
                          />
                        </div>
                        <div className={classes.fromParent}>
                          <SBText
                            text={messageObj.comment}
                            className={classes.messageText}
                          />
                        </div>
                      </div>
                      <div className={classes.timeParent}>
                        <SBText
                          text={getTime(messageObj.createdAt)}
                          className={classes.timeText}
                        />
                      </div>
                    </div>
                    {/*<div style={{ width: "75%" }}>*/}
                    {/*  <SBText*/}
                    {/*    text={moment(messageObj.createdAt).fromNow()}*/}
                    {/*    style={{ width: 50, fontSize: 8, marginRight: 5 }}*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                </div>
              ) : (
                <div className={classes.rightParent}>
                  <div style={{ width: "100%" }}>
                    <div className={classes.rightUsernameParent}>
                      <SBText
                        text={messageObj.createdUser.name}
                        className={classes.rightUserName}
                      />
                    </div>

                    <div className={classes.messageSubParent}>
                      <div className={classes.timeParent}>
                        <SBText
                          text={getTime(messageObj.createdAt)}
                          className={classes.timeText}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          width: "68%",
                        }}
                      >
                        <div className={classes.toParent}>
                          <SBText
                            text={messageObj.comment}
                            className={classes.messageText}
                          />
                        </div>
                        <div className={classes.nameCircle}>
                          <SBText
                            text={
                              messageObj.createdUser.name
                                ? messageObj.createdUser.name
                                    .substring(0, 1)
                                    .toUpperCase()
                                : "-"
                            }
                            className={classes.userName}
                          />
                        </div>
                      </div>
                    </div>
                    {/*<div style={{ width: "75%" }}>*/}
                    {/*  <SBText*/}
                    {/*    text={moment(messageObj.createdAt).fromNow()}*/}
                    {/*    style={{ width: 50, fontSize: 8, marginRight: 5 }}*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={classes.sendMessageBox}>
        <InputBase
          placeholder={"New Message"}
          className={classes.margin}
          inputProps={{
            className: clsx(classes.inputStyle),
          }}
          onChange={(event: any) => onTextChange(event.target.value)}
          value={value}
          onKeyPress={(ev) => {
            // console.log(`Pressed keyCode ${ev.key}`);
            if (ev.key === "Enter") {
              onSendMessage();
              ev.preventDefault();
            }
          }}
        />

        <IconButton
          aria-label="delete"
          className={classes.sendButton}
          onClick={() => onSendMessage()}
        >
          <SendIcon fontSize="small" />
        </IconButton>

        {/*<img*/}
        {/*  src={send}*/}
        {/*  style={{ height: 24 }}*/}
        {/*  onClick={() => onSendMessage()}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default MessageChat;

{
  /*<div*/
}
{
  /*  style={{*/
}
{
  /*    height: 50,*/
}
{
  /*    width: 50,*/
}
{
  /*    paddingTop: 5,*/
}
{
  /*    marginRight: 5,*/
}
{
  /*    marginTop: 6,*/
}
{
  /*    alignItems: "center",*/
}
{
  /*    justifyContent: "center",*/
}
{
  /*    borderRadius: 30,*/
}
{
  /*    backgroundColor: "gray",*/
}
{
  /*  }}*/
}
{
  /*>*/
}
{
  /*  <SBText*/
}
{
  /*    text={*/
}
{
  /*      messageObj.createdUser.name*/
}
{
  /*        ? messageObj.createdUser.name*/
}
{
  /*            .substring(0, 1)*/
}
{
  /*            .toUpperCase()*/
}
{
  /*        : "-"*/
}
{
  /*    }*/
}
{
  /*    style={{ padding: 10, fontSize: 30, color: "white" }}*/
}
{
  /*  />*/
}
{
  /*</div>*/
}
{
  /*<div style={{ width: "75%" }}>*/
}
{
  /*  <SBText*/
}
{
  /*    text={messageObj.createdUser.name}*/
}
{
  /*    style={{ fontSize: 8 }}*/
}
{
  /*  />*/
}
{
  /*  <div className={classes.toParent}>*/
}
{
  /*    <SBText*/
}
{
  /*      text={messageObj.comment}*/
}
{
  /*      className={classes.messageText}*/
}
{
  /*    />*/
}
{
  /*  </div>*/
}
{
  /*  <div style={{ width: "75%" }}>*/
}
{
  /*    <SBText*/
}
{
  /*      text={moment(messageObj.createdAt).fromNow()}*/
}
{
  /*      style={{ width: 50, fontSize: 8, marginRight: 5 }}*/
}
{
  /*    />*/
}
{
  /*  </div>*/
}
{
  /*</div>*/
}
