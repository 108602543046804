import React, { useState } from 'react';
import { makeStyles, createStyles, withStyles } from '@material-ui/styles';
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton,
  Typography,
  Collapse,
} from '@material-ui/core';
import SBButton from '../../../components/base/SBButton';
import AppColor from '../../../theme/AppColor';
import SBText from '../../../components/base/SBText';
import clsx from 'clsx';
import { getApplicationIdInString } from '../../../util';
import moment from 'moment';
import { CloseOutlined } from '@material-ui/icons';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { hasPermission, PERMISSION } from '../../../util/rolePermissionsUtil';
import { useUserPermissions } from '../../../hooks/useUserPermissions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    input: {
      borderRadius: 5,
      height: 45,
      marginBottom: 2,
      backgroundColor: '#FCFBFB',
      '&:hover': {
        borderColor: '#727487',
      },
      '&$focused': {
        // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: '#727487',
      },
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    cancelButton: {
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: '#FAFAFA',
      borderColor: AppColor.appBlue,
      borderWidth: 1,
      borderStyle: 'solid',
      color: AppColor.appBlue,
      '&:hover': {
        borderColor: '#727487',
        backgroundColor: '#FCFBFB',
      },
      height: 35,
    },
    requestButton: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    paper: { width: '450px', minHeight: 150 },
    checkButtonText: {
      marginLeft: 6,
      fontSize: 16,
      fontWeight: 600,
      color: AppColor.appBlue,
    },
    uncheckButton: { opacity: 0.5, height: 30, width: 30 },
    checkButton: { height: 30, width: 30 },
    checkedText: { color: 'black' },
    arrow: {
      '&:before': {
        border: '0px solid #E6E8ED',
      },
      color: AppColor.appBlue,
    },
    tooltip: {
      backgroundColor: AppColor.appBlue,
      border: '0px solid #E6E8ED',
      color: 'white',
      width: 120,
      textAlign: 'center',
    },
    textAreaWidth: {
      border: '1px solid black',
      borderRadius: '5px',
      fontFamily: 'Poppins',
    },
    label: {
      textAlign: 'left',
      color: theme.palette.primary.light,
      display: 'flex',
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      marginTop: 5,
    },
    infoCommentsRow: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      marginTop: 5,
    },
    infoLabel: { fontSize: 12, minWidth: 170 },
    infoValue: { fontSize: 12, fontWeight: 600 },
    dialogHeaderText: {
      fontFamily: 'poppins',
      letterSpacing: 0.15,
      fontWeight: 600,
      color: AppColor.appWhite,
    },
    headerParent: {
      fontFamily: 'poppins',
      letterSpacing: 0.15,
      fontWeight: 600,
      color: AppColor.appWhite,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      // height: 50,
    },
    outcomeHistoryParent: {
      minHeight: 30,
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 5,
      paddingRight: 5,
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottomColor: AppColor.appLightGrey,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
    },
  })
);

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
type DeferredApplicationActionDialogProps = {
  className?: string;
  open: boolean;
  application: any;
  onSubmit: (applicationId: number, deferredId: number, status: string, comments: string) => void;
  onSubmitForEmailApproval: (applicationId: number, deferredId: number, email: string, name: string) => void;
  onClose: () => void;
};
const DeferredApplicationActionDialog: React.FC<DeferredApplicationActionDialogProps> = ({
  open,
  application,
  // setOpen,
  onSubmit,
  onSubmitForEmailApproval,
  onClose,
}) => {
  const getLatestDeferredObject = () => {};

  const classes = useStyles();
  const [collapseHistory, setCollapseHistory] = useState(false);
  const [email, setEmail] = useState('');
  const [confrmEmail, setConfirmEmail] = useState('');
  const [comment, setComment] = useState('');
  const [name, setName] = useState('');

  const [emailtError, showEmailError] = useState('');
  const [confirmEmailError, showConfirmEmailError] = useState('');
  const [commentError, showCommentError] = useState('');
  const [nameErrorr, setNameError] = useState('');
  const userPermissions: PERMISSION[] = useUserPermissions();

  const handleClose = () => {
    setEmail('');
    setConfirmEmail('');
    setComment('');
    showEmailError('');
    showConfirmEmailError('');
    showCommentError('');
    onClose();
    // setOpen(false);
    // onSubmit();
  };

  const validateEmail = (inputText: string) => {
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailFormat)) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmitAction = () => {
    if (!name) {
      setNameError('Please enter name of approver');
      return;
    }

    if (!email) {
      showEmailError('Please enter email address');
      return;
    }

    if (!validateEmail(email)) {
      showEmailError('Please enter valid email address');
      return;
    }

    if (!confrmEmail) {
      showEmailError('');
      showConfirmEmailError('Please enter confirm email address');
      return;
    }

    if (email != confrmEmail) {
      showEmailError('');
      showConfirmEmailError('Email and confirm email address should be same');
      return;
    }

    // onSubmit(application.application.id, comment, status);

    onSubmitForEmailApproval(application.application.id, application.deferredDetails.id, email, name);

    handleClose();
  };

  const onSubmitForApprove = (status: string) => {
    if (!comment) {
      showCommentError('Please enter your comments');
      return;
    }

    // applicationId: number,
    //     deferredId: number,
    //     status: string,
    //     comments: string
    onSubmit(application.application.id, application.deferredDetails.id, status, comment);
    handleClose();
  };

  const formattedDate = (date: Date) => {
    //console.log("Date", moment(date).format("YYYY-MM-DD"));
    return moment(date).format('YYYY-MM-DD');
  };

  const handleKeyboardEvent = (event: any) => {
    event.preventDefault();
  };

  const getHistoryFileName = (fileName: string) => {
    // let filename = dataObject.answer.fileName;
    if (fileName.includes('#')) {
      fileName = fileName.split('#')[1];
    }
    return fileName;
  };

  const getOutComeHistoryObj = (formdata: any) => {
    return (
      <>
        <Button
          style={{ paddingLeft: 0, paddingBottom: 2, marginTop: 10 }}
          onClick={() => setCollapseHistory(!collapseHistory)}
          disableRipple={true}
        >
          <SBText className={classes.label} text="Outcome History" />
          {!collapseHistory ? (
            <KeyboardArrowRightIcon fontSize={'small'} />
          ) : (
            <KeyboardArrowDownIcon fontSize={'small'} />
          )}
        </Button>

        <Collapse in={collapseHistory}>
          <div
            style={{
              maxHeight: 100,
              marginBottom: 10,
              overflowY: 'scroll',
              borderWidth: 1,
              borderColor: AppColor.appLightGrey,
              borderStyle: 'solid',
              width: '78%',
              borderRadius: 5,
            }}
          >
            {/*applicationId: 28*/}
            {/*comment: "213123123123"*/}
            {/*createdAt: "2021-07-12T19:19:57.923Z"*/}
            {/*createdBy: 5*/}
            {/*createdUser: {id: 5, name: "AdminLeadUser", email: "rahulnakate7@gmail.com", roleId: null, email1: null, …}*/}
            {/*fileName: "outcome_1626117590440#pigen_bird.png"*/}
            {/*id: 34*/}
            {/*isActive: true*/}
            {/*isDeleted: true*/}
            {/*key: "https://s3.ap-southeast-2.amazonaws.com/pace.dev/outcome_1626117590440%23pigen_bird.png"*/}
            {/*outcome: "ConditionalOffer"*/}
            {/*referenceNumber: "213123"*/}
            {/*updatedAt: "2021-07-12T19:42:49.572Z"*/}
            {/*updatedBy: 5*/}

            {formdata.outcomeHistory.map((obj: any, index: number) => (
              <div className={classes.outcomeHistoryParent}>
                <SBText text={getHistoryFileName(obj.fileName)} style={{ fontSize: 12, width: '30%' }} />
                <SBText text={moment(obj.updatedAt).format('DD-MM-YYYY')} style={{ fontSize: 12 }} />
                <IconButton size={'small'} onClick={() => console.log('On Download')}>
                  <CloudDownloadIcon fontSize={'small'} />
                </IconButton>
              </div>
            ))}
          </div>
        </Collapse>
      </>
    );
  };

  const getInfoComponent = () => {
    return (
      <>
        <div className={classes.infoRow}>
          <SBText text={'Visa Lodged:'} className={classes.infoLabel} />
          <SBText
            text={
              application && application.deferredDetails && application.deferredDetails.visaLodgmentStatus
                ? application.deferredDetails.visaLodgmentStatus
                : 'No'
            }
            className={classes.infoValue}
          />
        </div>
        {application && application.deferredDetails && application.deferredDetails.visaLodgmentDate ? (
          <>
            <div className={classes.infoRow}>
              <SBText text={'Visa Lodgment Date:'} className={classes.infoLabel} />
              <SBText
                text={formattedDate(application.deferredDetails.visaLodgmentDate)}
                className={classes.infoValue}
              />
            </div>
          </>
        ) : null}

        <div className={classes.infoRow}>
          <SBText text={'Previous Term:'} className={classes.infoLabel} />
          <SBText text={application.deferredDetails.previousTerm} className={classes.infoValue} />
        </div>
        <div className={classes.infoRow}>
          <SBText text={'New Term:'} className={classes.infoLabel} />
          <SBText text={application.deferredDetails.nextTerm} className={classes.infoValue} />
        </div>

        <div className={classes.infoCommentsRow}>
          <SBText text={'Reason:'} className={classes.infoLabel} />
          <SBText text={application.deferredDetails.comments} className={classes.infoValue} />
        </div>
      </>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
        style={{
          minHeight: hasPermission(PERMISSION.applications_defer_approve_reject, userPermissions) ? 300 : 150,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            backgroundColor: AppColor.appBlue,
          }}
        >
          <div className={classes.headerParent}>
            <Typography className={classes.dialogHeaderText}>
              {`${getApplicationIdInString(application ? application.application.id : -1)} Deferement Request`}
            </Typography>

            <IconButton size={'small'} onClick={() => handleClose()}>
              <CloseOutlined fontSize={'small'} style={{ color: AppColor.appWhite }} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          {application && application.deferredDetails ? getInfoComponent() : null}

          {application &&
          application.deferredDetails.visaLodgmentStatus &&
          application.deferredDetails.visaLodgmentStatus == 'Yes' &&
          hasPermission(PERMISSION.applications_defer_approve_reject, userPermissions) ? (
            <>
              <div style={{ height: 90 }}>
                <SBText text={'Enter name of approver'} className={classes.label} style={{ marginTop: 20 }} />
                <TextField
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    className: clsx(classes.input),
                  }}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />

                {emailtError ? (
                  <SBText
                    text={emailtError}
                    style={{
                      fontSize: 12,
                      color: 'red',
                    }}
                  />
                ) : null}
              </div>

              <div style={{ height: 90 }}>
                <SBText
                  text={"Please enter approving officer's email address "}
                  className={classes.label}
                  style={{ marginTop: 10 }}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    className: clsx(classes.input),
                  }}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  onCut={handleKeyboardEvent}
                  onCopy={handleKeyboardEvent}
                  onPaste={handleKeyboardEvent}
                />

                {emailtError ? (
                  <SBText
                    text={emailtError}
                    style={{
                      fontSize: 12,
                      color: 'red',
                    }}
                  />
                ) : null}
              </div>

              <div style={{ height: 90 }}>
                <SBText text={'Confirm email address '} className={classes.label} />
                <TextField
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    className: clsx(classes.input),
                  }}
                  value={confrmEmail}
                  onChange={(event) => setConfirmEmail(event.target.value)}
                  onCut={handleKeyboardEvent}
                  onCopy={handleKeyboardEvent}
                  onPaste={handleKeyboardEvent}
                />

                {confirmEmailError ? (
                  <SBText
                    text={confirmEmailError}
                    style={{
                      fontSize: 12,
                      color: 'red',
                    }}
                  />
                ) : null}
              </div>
            </>
          ) : hasPermission(PERMISSION.applications_defer_approve_reject, userPermissions) ? (
            <div style={{ height: 150, marginTop: 10 }}>
              <SBText text={'Comments*'} className={classes.label} />
              <TextField
                className={classes.textAreaWidth}
                id="outlined-multiline-static"
                variant="outlined"
                onChange={(event) => setComment(event.target.value)}
                value={comment}
                name="refNum"
                multiline
                rows={4}
                fullWidth={true}
              />

              {commentError ? (
                <SBText
                  text={commentError}
                  style={{
                    fontSize: 12,
                    color: 'red',
                  }}
                />
              ) : null}
            </div>
          ) : null}
        </DialogContent>

        {application &&
        application.deferredDetails.visaLodgmentStatus &&
        application.deferredDetails.visaLodgmentStatus == 'Yes' &&
        hasPermission(PERMISSION.applications_defer_approve_reject, userPermissions) ? (
          <DialogActions>
            <SBButton icon={false} text={'Cancel'} onClick={() => handleClose()} className={classes.cancelButton} />
            <SBButton
              icon={false}
              text={'Send For Approval'}
              onClick={() => onSubmitAction()}
              className={classes.requestButton}
            />
          </DialogActions>
        ) : hasPermission(PERMISSION.applications_defer_approve_reject, userPermissions) ? (
          <DialogActions>
            <SBButton
              icon={false}
              text={'Reject'}
              onClick={() => onSubmitForApprove('Reject')}
              className={classes.cancelButton}
            />
            <SBButton
              icon={false}
              text={'Approve'}
              onClick={() => onSubmitForApprove('Approve')}
              className={classes.requestButton}
            />
          </DialogActions>
        ) : null}
      </Dialog>
    </div>
  );
};

export default DeferredApplicationActionDialog;
