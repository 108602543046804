import React, { useCallback, useState } from "react";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionBody: {
      width: "100%",
    },
    dropArea: {
      border: "1px solid gray",
      borderRadius: "15px",
      borderStyle: "dashed",
      padding: "15px 0px",
      width: "60%",
      margin: "auto",
    },
    dropAreaText: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      color: "#8D8F97",
      width: "100%",
      textAlign: "center",
    },
    clickStyle: {
      color: "#D27F65",
      opacity: "0.5",
      textDecoration: "underline",
    },
  })
);

interface FileDropZoneProps {
  onFileDrop: any;
}

const FileDropZone: React.FC<FileDropZoneProps> = ({ onFileDrop }) => {
  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles.length) {
      onFileDrop(acceptedFiles);
    } else {
      onFileDrop(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
    accept: ".pdf",
  });

  return (
    <div className={classes.sectionBody}>
      <div className={classes.dropArea} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={classes.dropAreaText}>
          <span className={classes.clickStyle}>Click</span> to browse or <br />
          drag and drop your files
        </div>
      </div>
    </div>
  );
};

export default FileDropZone;
