import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import SBButton from "../../../components/base/SBButton";
import { LABELS } from "../../../util/labels";
import SBSearch from "../../../components/base/SBSearch";
import LeadsModal from "../LeadsModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      position: "relative"
    },
    searchSortParents: {
      width: "100%",
    },
    addBtn: {
      height: 40,
      borderRadius: 30,
      textTransform: "none",
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        fontSize: 14,
      },
      padding: "5px 20px",
    },
    btnWrapper: {
      [theme.breakpoints.down("xs")]: {
        position: "absolute",
        right: "-8px",
        top: "-54px"
      }
    }
  })
);

type ToolBarPros = {
  handleOnSearch: (searchText: string) => void;
};
const ToolBar: React.FC<ToolBarPros> = ({ handleOnSearch }) => {
  const classes = useStyles();

  const [isLeadsModalOpen, setLeadsModalOpen] = useState<boolean>(false);

  const handleAddBtnClick = () => {
    setLeadsModalOpen(true);
  };

  const handleLeadModalClose = () => {
    setLeadsModalOpen(false);
  };
  return (
    <div className={classes.toolbar}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={8} className={classes.btnWrapper}>
          <div>
            <SBButton
              icon={false}
              text={LABELS.NEW_LEAD}
              className={classes.addBtn}
              onClick={handleAddBtnClick}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className={classes.searchSortParents}>
            <SBSearch
              onSearch={handleOnSearch}
              placeHolder={LABELS.SEARCH_BY_APPLICANT_NAME}
            />
          </div>
        </Grid>
      </Grid>
      <LeadsModal
        open={isLeadsModalOpen}
        onClose={handleLeadModalClose}
      ></LeadsModal>
    </div>
  );
};

export default ToolBar;
