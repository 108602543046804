import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import { Theme, MenuItem, Select, InputBase, Chip } from "@material-ui/core";
import SBText from "../SBText";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 5,
      height: 45,
      backgroundColor: "#FCFBFB",
      textAlign: "left",
      overflow: "hidden",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    },
    placeHolder: { textAlign: "left" },
    dropDown: {
      height: 45,
      overflow: "hidden",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      textAlign: "left",
      borderColor: "#3E4059",
      backgroundColor: "#FCFBFB",
      "&:focus": {
        borderColor: "#3E4059",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      textAlign: "left",
      color: "#736F6F",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 1,
      fontSize: 12,
      padding: 1,
    },
    error: {
      border: "1px solid #e53935 !important",
    },
    helperError: {
      marginLeft: 14,
      marginRight: 14,
    },
  })
);

type SBFilterMultiselectDropdownProps = {
  className?: string;
  [x: string]: any;
  value: any;
  onDataChange: (obj: any) => void;
};
const SBFilterMultiselectDropdown: React.FC<SBFilterMultiselectDropdownProps> = ({
  className,
  value,
  onDataChange,
  ...rest
}) => {
  const SbInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "label + &": {},
      },
      input: {
        borderRadius: 5,
        backgroundColor: "#FCFBFB",
        border: "1px solid #3E4059",
        minHeight: 30,
        textAlign: "left",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        // Use the system font instead of the default Roboto font.
        fontFamily: "Poppins",
        "&:focus": {
          borderRadius: 5,
          borderColor: "#3E4059",
          // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        letterSpacing: 0.75,
      },
    })
  )(InputBase);

  const classes = { ...useStyles(), ...rest.userStyles?.() };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles() {
    return {
      fontWeight: 400,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    };
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { options } = event.target as HTMLSelectElement;
    // console.log("options", options);

    const valuesArray: any = event.target.value;
    // console.log("options", valuesArray);
    const objectArray = [];
    for (let i = 0; i < valuesArray.length; i++) {
      let object = undefined;

      object = _.find(rest.options, function (o) {
        return o.value == valuesArray[i];
      });

      objectArray.push(object);
    }

    // console.log("objectArray", objectArray);

    onDataChange(objectArray);
  };

  const optionMap = new Map<any, any>();
  rest.options.map((option: any) => {
    optionMap.set(option.value, option.value);
  });

  const selected = _.map(value, (group) => {
    return group.value;
  });

  // console.log("rest.options", rest.options);
  // console.log("optionMap", optionMap);

  return (
    <div className={className}>
      {rest.labelText ? (
        <SBText
          text={rest.labelText}
          className={classes.label}
          color={"#736F6F"}
        />
      ) : null}
      <Select
        labelId="demo-mutiple-name-label"
        id="demo-mutiple-chip"
        placeholder={rest.placeholder}
        multiple
        // value={personName}
        value={selected}
        onChange={handleChange}
        input={<SbInput />}
        MenuProps={MenuProps}
        fullWidth={true}
        {...(rest.error ? { classes: { select: classes.error } } : {})}
        renderValue={(selected) => {
          // console.log("MultiSelect", selected);
          return (
            <div className={classes.chips}>
              {(selected as string[]).map((obj) => (
                <Chip
                  key={obj}
                  label={optionMap.get(obj as any)}
                  className={classes.chip}
                />
              ))}
            </div>
          );
        }}
      >
        {rest.options.map((state: any) => (
          <MenuItem key={state.value} value={state.value} style={getStyles()}>
            {state.value}
          </MenuItem>
        ))}
      </Select>
      {rest.error && (
        <FormHelperText classes={{ error: classes.helperError }} error>
          {rest.helperText}
        </FormHelperText>
      )}
    </div>
  );
};

export default SBFilterMultiselectDropdown;
