export const ActionTypes = {
  SET_AGENT_LIST: "SET_AGENT_LIST",
  SET_LEGACY_AGENT_LIST: "SET_LEGACY_AGENT_LIST",
  SET_REFREE_DEATILS: "SET_REFREE_DEATILS",
  SET_SELECTED_AGENT_DETAILS: "SET_SELECTED_AGENT_DETAILS",
  SET_REFREE_APPROVAL_DEATILS: "SET_REFREE_APPROVAL_DEATILS",
  SET_SEND_CONTRACT_DETAILS: "SET_SEND_CONTRACT_DETAILS",
  SET_RESEND_INVITE_RESPONSE: "SET_RESEND_INVITE_RESPONSE",
  SET_ONBOARD_AGENT_DETAILS: "SET_ONBOARD_AGENT_DETAILS",
  SET_AGENT_CONTRACT_DETAILS: "SET_AGENT_CONTRACT_DETAILS",
  SET_AGENT_CONTRACT_FILE: "SET_AGENT_CONTRACT_FILE",
  SET_AGENT_APPLICATION_FORM_FILE: "SET_AGENT_APPLICATION_FORM_FILE",
  SET_REFFERENCE_CHECK_ATTACH_FILE: "SET_REFFERENCE_CHECK_ATTACH_FILE",
  SET_SUPPORTING_DOCUMENT: "SET_SUPPORTING_DOCUMENT",
  SET_SUBMITTED_DOCUMENT: "SET_SUBMITTED_DOCUMENT",
  SET_ONBOARD_REQUEST_AGENT_LIST: "SET_ONBOARD_REQUEST_AGENT_LIST",
 SET_FILTERED_STATUSES: "SET_FILTERED_STATUSES",
 SET_ALL_AGENT_STATUSES: "SET_ALL_AGENT_STATUSES"
};
