import { isDate } from "lodash";
import { ActionType } from "../../../../reducer/types";
import { ActionTypes } from "../actions/agent";

interface AgentState {
  agents: any;
  totalAgentCount: number;
}

const initialState: AgentState = {
  agents: [],
  totalAgentCount: 0,
};

const legacyAgentReducer = (
  state = initialState,
  action: ActionType
): AgentState => {
  switch (action.type) {
    case ActionTypes.SET_LEGACY_AGENT_LIST: {
      return {
        ...state,
        agents: action.payload.rows,
        totalAgentCount: action.payload.count,
      };
    }
    default:
      return state;
  }
};

export default legacyAgentReducer;
