import * as actionTypes from "../actions";
import { extractPermissions } from "../util/rolePermissionsUtil";
import { ActionType } from "./types";

const initialState: any = {
  isUserLoggedIn: false,
  username: null,
  name: null,
  user: null,
  permissions: [],
  loginError: null,
  sessionError: false,
  isForgotPasswordOn: false,
  companyProfilePicture: null,
  provider: null,
};

const userReducers = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESSFULLY: {
      const user = action.payload;
      return {
        ...state,
        isUserLoggedIn: true,
        user,
        permissions: extractPermissions(user),
      };
    }
    case actionTypes.SET_PROVIDER_DETAILS: {
      return {
        ...state,
        provider: action.payload,
      };
    }
    case actionTypes.SET_LOGIN_ERROR: {
      const result: any = action.payload;
      return {
        ...state,
        loginError: result,
      };
    }
    case actionTypes.RESET_LOGIN_ERROR: {
      return {
        ...state,
        loginError: null,
      };
    }
    case actionTypes.SHOW_SESSION_TIMEOUT_ERROR: {
      return {
        ...state,
        sessionError: true,
      };
    }
    case actionTypes.RESET_SESSION_TIMEOUT_ERROR: {
      return {
        ...state,
        sessionError: false,
      };
    }
    case actionTypes.SET_FORGOT_PASSWORD_VIEW: {
      return {
        ...state,
        isForgotPasswordOn: action.payload,
      };
    }
    case actionTypes.SET_COMPANY_PIC: {
      return {
        ...state,
        companyProfilePicture: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        isUserLoggedIn: false,
        username: null,
        name: null,
        user: null,
        loginError: null,
        sessionError: false,
        companyProfilePicture: null,
        provider: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducers;
