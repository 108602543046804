// @ts-nocheck
import { createSelector } from 'reselect';
import _ from 'lodash';
import { RootState } from '../reducer';
import { QuestionType } from '../actions/util/QuestionType';

const sbApplication = (state: RootState) => state.sbApplication.sbApplication;
const currentApplicationDetails = (state: RootState) => state.sbApplication.currentApplicationDetails;

export const getFormattedQuestion = createSelector(
  sbApplication,
  currentApplicationDetails,
  (sbApplication, currentApplicationDetails) => {
    // console.log("***applicationLocalAnswer ::1",applicationLocalAnswer)
    // console.log("***currentApplicationDetails ::2",currentApplicationDetails)
    const questionList = sbApplication.activeGroup ? sbApplication[sbApplication.activeGroup].questions : [];
    if (sbApplication.activeGroup) {
      const mainQuestionArray: any = [];
      const statusUpdatedQuestion: any = [];
      for (let i = 0; i < questionList.length; i++) {
        if (questionList[i].dependencies && questionList[i].dependencies.length > 0) {
          const dependency = questionList[i].dependencies[0].dependency;

          const orderList = getDependnciesOrder(dependency);
          const requiredAnswer = getAllAnswer(currentApplicationDetails.allAnswers, orderList);

          if (!_.isEmpty(requiredAnswer)) {
            const status = verifyDependenciesAndGetStatus(questionList[i], requiredAnswer);
            if (questionList[i].status != status) {
              statusUpdatedQuestion.push({ id: questionList[i].id, status });
            }
            questionList[i].status = status;
          } else {
            if (questionList[i].status != 'DISABLED') {
              statusUpdatedQuestion.push({ id: questionList[i].id, status: 'DISABLED' });
            }
            questionList[i].status = 'DISABLED';
          }
        }

        if (questionList[i].questionList && questionList[i].questionList.length > 0) {
          for (let j = 0; j < questionList[i].questionList.length; j++) {
            if (
              questionList[i].questionList[j].dependencies &&
              questionList[i].questionList[j].dependencies.length > 0
            ) {
              const dependency = questionList[i].questionList[j].dependencies[0].dependency;
              const orderList = getDependnciesOrder(dependency);
              const requiredAnswer = getAllAnswer(currentApplicationDetails.allAnswers, orderList);
              if (!_.isEmpty(requiredAnswer)) {
                const status = verifyDependenciesAndGetStatus(questionList[i].questionList[j], requiredAnswer, true);

                if (questionList[i].questionList[j].status != status) {
                  statusUpdatedQuestion.push({ id: questionList[i].questionList[j].id, status });
                }
                questionList[i].questionList[j].status = status;
              } else {
                if (questionList[i].questionList[j].status != 'DISABLED') {
                  statusUpdatedQuestion.push({ id: questionList[i].questionList[j].id, status: 'DISABLED' });
                }
                questionList[i].questionList[j].status = 'DISABLED';
              }
            }
          }
        }
        if (questionList[i].status && questionList[i].status != 'HIDE') {
          mainQuestionArray.push(questionList[i]);
        }
      }
      // console.log("*** main Question Array", mainQuestionArray)
      return { questionList: mainQuestionArray, statusUpdate: statusUpdatedQuestion };
      // }
    } else {
      return { questionList, statusUpdate: [] };
    }
  }
);

const getDependnciesOrder = (input) => {
  let regex = /[0-9]+#/g;
  let list = input.match(regex);
  let uniqueChars = [...new Set(list)];
  return uniqueChars;
};

const getAllAnswer = (allAnswer, dependentQuestionArray) => {
  const requiredAnswer = {};
  for (let i = 0; i < dependentQuestionArray.length; i++) {
    const value = dependentQuestionArray[i].split('#')[0];
    const answerObject = _.find(allAnswer, (answer) => answer.order == value);
    if (answerObject) {
      requiredAnswer[answerObject.order] = answerObject.finalAnswer.includes('#*%20$#')
        ? answerObject.finalAnswer.split('#*%20$#')
        : answerObject.finalAnswer;
    }
  }
  return requiredAnswer;
};

const matchExact = (r, str) => {
  var match = str.match(r);
  return match && str === match[0];
};

const verifyDependenciesAndGetStatus = (dependentQuestion: any, requiredAnswer, isChildQuestion: boolean = false) => {
  const dependentObject = dependentQuestion;
  const dependency = dependentObject.dependencies[0].dependency;

  const dependencyTree = getDependencyTree(dependency);
  const orExpressions = dependencyTree.orExpression;
  let ORingSatisfied: any = null;

  for (let i = 0; i < orExpressions.length; i++) {
    const expression = orExpressions[i];
    let ANDSatisfied: any = null;
    const andingExpressions = expression.split('AND');
    const andCondition: any = [];
    // console.log('andingExpressions >>>', andingExpressions);

    for (let j = 0; j < andingExpressions.length; j++) {
      const andPart = andingExpressions[j].trim();
      const orderAnswer = andPart.split('#');
      const questionOrder = orderAnswer[0].trim();
      const questionAnswer = orderAnswer[1] ? orderAnswer[1].trim() : '';
      let answers = [];
      const answerObject = requiredAnswer.hasOwnProperty(questionOrder) ? requiredAnswer[questionOrder] : undefined;
      if (answerObject) {
        if (!Array.isArray(answerObject)) {
          answers.push(answerObject);
        } else {
          answers = [...answers, ...answerObject];
        }
      }
      if (answers.length > 0) {
        const ansArray = _.filter(answers, (answer) => {
          return answer.trim() == questionAnswer.trim();
        });
        const flagAnswer = ansArray.length > 0;
        andCondition.push(flagAnswer);
      }
    }
    if (andingExpressions.length == andCondition.length) {
      if (
        _.filter(andCondition, (ans) => {
          return !ans;
        }).length == 0
      ) {
        ANDSatisfied = true;
      } else {
        ANDSatisfied = false;
      }
    } else {
      if (
        _.filter(andCondition, (ans) => {
          return !ans;
        }).length > 0
      ) {
        ANDSatisfied = false;
      }
    }

    if (ANDSatisfied == true) {
      ORingSatisfied = true;
      break;
    } else if (ANDSatisfied == false) {
      ORingSatisfied = false;
    }
  }

  if (ORingSatisfied == false) {
    const status = dependencyTree.action.toUpperCase() == 'HIDE' ? 'SHOW' : 'HIDE';
    //its parent is answeredQuestion, disable it
    if (isChildQuestion) {
      // updateQuestionMap[status == 'HIDE' ? 'DISABLED' : 'SHOW'].push(questionObject.id);
      return status == 'HIDE' ? 'DISABLED' : 'SHOW';
    } else {
      // updateQuestionMap[status].push(questionObject.id);
      return status;
    }
  } else if (ORingSatisfied == true) {
    const status = dependencyTree.action.toUpperCase() == 'HIDE' ? 'HIDE' : 'SHOW';
    if (isChildQuestion) {
      // updateQuestionMap[status == 'HIDE' ? 'DISABLED' : 'SHOW'].push(questionObject.id);
      return status == 'HIDE' ? 'DISABLED' : 'SHOW';
    } else {
      // updateQuestionMap[status].push(questionObject.id);
      return status;
    }
  } else if (ORingSatisfied == null) {
    // updateQuestionMap['DISABLED'].push(questionObject.id);
    return 'DISABLED';
  }
  // }
};

const getDependencyTree = (dependency) => {
  const str = dependency;
  const action = str.substring(str.lastIndexOf('#') + 1);
  // console.log(action)
  const expression = str.substring(0, str.lastIndexOf('#'));
  /* console.log(expression); */
  const splits = expression.split('OR');
  const result: any = {
    action,
  };
  /* console.log(splits); */
  const orExpression = [];
  for (let i = 0; i < splits.length; i++) {
    let split = splits[i];
    split = split.split('(').join('');
    split = split.split(')').join('');
    orExpression.push(split.trim());
  }
  result.orExpression = orExpression;
  return result;
};

const isQuestionNeedToAdd = (questionObj: any, allQuestionsArray: any, isSubQuestion: boolean) => {
  let addQuestionCall = false;

  if (questionObj.dependencies[0].dependency && questionObj.dependencies[0].dependency.startsWith('(')) {
    return addQuestionCall;
  }

  const dependenciesSet = questionObj.dependencies[0].dependency.split('#');
  //console.log("dependenciesSet", dependenciesSet);

  const orderID = dependenciesSet[0];
  const answer = dependenciesSet[1];
  const hideShow = dependenciesSet[2];
  const objectByOrder = _.find(allQuestionsArray, (obj: any) => obj.order == orderID);

  if (objectByOrder.answer || objectByOrder.localAnswer) {
    const currentAnswer = getQuestionAnswersByQuestionType(objectByOrder);
    if (currentAnswer == answer) {
      if (hideShow === 'Show') {
        addQuestionCall = true;
        // mainQuestionArray.push(questionList[i]);
      }

      if (hideShow == 'Hide') {
        addQuestionCall = false;
      }
    }
  } else {
    if (isSubQuestion) {
      addQuestionCall = false;
    } else {
      addQuestionCall = true;
    }

    // mainQuestionArray.push(questionList[i]);
  }

  return addQuestionCall;
};

const isSubQuestionNeedToAdd = (questionObj: any, allQuestionsArray: any, isSubQuestion: boolean) => {
  let addQuestionCall = false;

  if (questionObj.dependencies[0].dependency.startsWith('(')) {
  } else {
    if (!questionObj.dependencies[0].dependency.startsWith('(')) {
      if (questionObj.dependencies[0].dependency == 'Hide') {
        return false;
      }

      const dependenciesSet = questionObj.dependencies[0].dependency.split('#');
      //console.log("dependenciesSet", dependenciesSet);
      const orderID = dependenciesSet[0];
      const answer = dependenciesSet[1];
      const hideShow = dependenciesSet[2];
      const objectByOrder = _.find(allQuestionsArray, (obj: any) => obj.order == orderID);

      if (objectByOrder.answer || objectByOrder.localAnswer) {
        const currentAnswer = getQuestionAnswersByQuestionType(objectByOrder);

        if (currentAnswer == answer) {
          if (hideShow === 'Show') {
            addQuestionCall = true;
            // mainQuestionArray.push(questionList[i]);
          }

          if (hideShow == 'Hide') {
            addQuestionCall = false;
          }
        } else {
          if (hideShow == 'Show') {
            addQuestionCall = false;
          } else {
            addQuestionCall = true;
          }
        }
      } else {
        if (isSubQuestion) {
          addQuestionCall = false;
        } else {
          addQuestionCall = true;
        }

        // mainQuestionArray.push(questionList[i]);
      }
    } else {
      addQuestionCall = true;
    }
  }

  return addQuestionCall;
};

const getSubQuestionVerified = (questionObj: any, allQuestionsArray: any, hideQuestions: any, showQuestions: any) => {
  for (let j = 0; j < questionObj.questionList.length; j++) {
    if (questionObj.questionList[j].dependencies[0].dependency) {
      const addQuestionsInMainArray = isSubQuestionNeedToAdd(questionObj.questionList[j], allQuestionsArray, true);
      questionObj.questionList[j].disable = !addQuestionsInMainArray;

      // hide show question logic
      if (questionObj.questionList[j].disable) {
        hideQuestions.push(questionObj.questionList[j].id);
      } else {
        if (questionObj.questionList[j].hidden) {
          showQuestions.push(questionObj.questionList[j].id);
        }
      }
    } else {
      questionObj.questionList[j].disable = false;
      // hide show question logic
      if (questionObj.questionList[j].hidden) {
        showQuestions.push(questionObj.questionList[j].id);
      }
    }
  }
  return questionObj;
};

const getAllQuestionArray = (sbApplication: any, groups: any) => {
  let questionArray: any[] = [];
  for (let i = 0; i < groups.length; i++) {
    if (groups[i].name in sbApplication) {
      questionArray = questionArray.concat(sbApplication[groups[i].name].questions);

      for (let j = 0; j < sbApplication[groups[i].name].questions.length; j++) {
        if (
          sbApplication[groups[i].name].questions[j].questionList &&
          sbApplication[groups[i].name].questions[j].questionList.length > 0
        ) {
          questionArray = questionArray.concat(sbApplication[groups[i].name].questions[j].questionList);
        }
      }
    }
  }
  //console.log("Final Question Array ::", questionArray);
  return questionArray;
};

const getQuestionAnswersByQuestionType = (answeredQuestion: any) => {
  if (answeredQuestion.type == QuestionType.DropDown) {
    let optionId: any = null;
    if (answeredQuestion.localAnswer) {
      optionId = answeredQuestion.localAnswer;
    } else {
      optionId = answeredQuestion.answer.optionId;
    }
    const optionObj = _.find(answeredQuestion.options, (obj: any) => obj.id == optionId);
    //console.log("optionObj", optionObj);
    return optionObj.value.trim();
  } else if (answeredQuestion.type == QuestionType.TextField) {
    let finalAnswer: any = null;
    if (answeredQuestion.localAnswer) {
      finalAnswer = answeredQuestion.localAnswer;
    } else {
      finalAnswer = answeredQuestion.answer.answer;
    }
    return finalAnswer;
  } else if (answeredQuestion.type == QuestionType.DatePicker) {
  } else if (answeredQuestion.type == QuestionType.MultiSelect) {
  } else {
  }
  //console.log("answeredQuestion", answeredQuestion);
};
