import * as actionTypes from "../actions";
import { ActionType } from "./types";
import _ from "lodash";

const initialState = {
  roleListDetails: undefined,
  selectedRoleDetails: undefined,
};

const roleMgmntReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ROLES_SUCCESS: {
      return {
        ...state,
        roleListDetails: action.payload,
      };
    }
    case actionTypes.FETCH_ROLE_SUCCESS: {
      return {
        ...state,
        selectedRoleDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default roleMgmntReducer;
