import {
  Grid,
  TablePagination,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import NoDataMessage from "../../../components/NoDataMessage";
import { tableViewHeaderStyle, tableViewRootStyle } from "../../../styles";
import { encryptText } from "../../../util";
import { LABELS } from "../../../util/labels";
import { DEFAULT_FETCH_PARAMS, PAGE_SIZES, DEFAULT_TLLEADFETCH_PARAMS } from "../constants";
import {
  useFetchLeadsPaginatedData,
  useFetchLeadsPopupDropdownsData,
  useFetchTlLeadsPaginatedData,
  useFetchLeadStatusesDropdownsData,
  useFetchAllLeadStatusesDropdownsData,
  useDownloadLeadsData,
  useFetchAllCoutrys,
} from "../hooks";
import LeadsModal from "../LeadsModal";
import TlLeadsModal from "../TlLeadModel";
import LeadsTable from "../LeadsTable";
import TlLeadsTable from "../TlLeadsTable";
import { submitToSBAction } from "../store/actions";
import { getLead, getLeadStatusTimeLine, updateLeadStatus } from "../store/actions/get-lead-action";
import {
  selectedLeadDetails,
  getLeadStatusTimeline,
  selectLeadsModalAPIStatus,
  selectLeadsPaginatedData,
} from "../store/selectors";
import ToolBar from "../ToolBar";
import { useUserPermissions } from "../../../hooks/useUserPermissions";
import { hasPermission, PERMISSION } from "../../../util/rolePermissionsUtil";
import {
  LeadPaginatedData,
  FetchLeadsParams,
  LeadTableItem,
  LEAD_TABLE_ACTION,
  LeadModalAPIStatus,
  Lead,
  LeadStatusDetails,
  LEAD_STATUS,
  FetchTlLeadsParams,
} from "../types";
import { mapLeadDataToTableViewItem } from "../utils";
import LeadsFilter from "../LeadsFilter";
import TlToolBar from "../TlToolBar";
import LeadStatusTimeline from "./LeadStatusTimeline";
import LeadStatusUpdatePopup from "./LeadStatusUpdatePopup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...tableViewRootStyle(theme),
    },
    legendWrapper: {
      display: "flex",
      flexWrap: "wrap",
      color: "#666",
      fontSize: 12,
      paddingLeft: 10,
      marginTop: 20,
      "& .legend": {
        display: "flex",
        alignItems: "center",
        flex: "1 1 50%",
        marginBottom: 8,
        "& .icon": {
          width: 8,
          height: 8,
          marginRight: 8,
          borderRadius: 10,
        },
      },
    },
    header: {
      ...tableViewHeaderStyle,
    },
  })
);

const LeadsView = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { fetchDropdownData } = useFetchLeadsPopupDropdownsData();
  const { fetchLeadStatusesDropdownData } = useFetchLeadStatusesDropdownsData();
  const { fetchAllLeadStatusesDropdownData } = useFetchAllLeadStatusesDropdownsData();
  const { getCountryDetails } = useFetchAllCoutrys();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const { fetchData } = useFetchLeadsPaginatedData();
  const { fetchTlData } = useFetchTlLeadsPaginatedData();
  const { downloadLeads } = useDownloadLeadsData();
  const tableData: LeadPaginatedData = useSelector(selectLeadsPaginatedData);
  const modalApiStatus: LeadModalAPIStatus = useSelector(selectLeadsModalAPIStatus);
  const selectedLeadDetail: Lead = useSelector(selectedLeadDetails);
  const selectedLeadStatusTimeline: any = useSelector(getLeadStatusTimeline);
  const [isLeadsModalOpen, setLeadsModalOpen] = useState<boolean>(false);
  const [isTlLeadsModalOpen, setTlLeadsModalOpen] = useState<boolean>(false);
  const [leadList, setLeadList] = useState<LeadTableItem[]>([]);
  const [fetchLeadParams, setFetchLeadParams] = useState<FetchLeadsParams>(DEFAULT_FETCH_PARAMS);
  const [fetchTLLeadParams, setFetchTLLeadParams] = useState<FetchTlLeadsParams>(DEFAULT_TLLEADFETCH_PARAMS);
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [leadStatusTimelinePopup, setLeadStatusTimelinePopup] = React.useState(false);
  const [applicantName, setApplicantName] = React.useState("");
  const [leadStatus, setLeadstatus] = React.useState("");
  const [leadStatusLabel, setLeadstatusLabel] = React.useState("");
  const [leadId, setLeadId] = React.useState<number>(-1);
  const [confirmLeadStatusUpdatePopup, setConfirmLeadStatusUpdatePopup] = React.useState(false);

  const history = useHistory();

  const hasPerm = (perm: string) => {
    return hasPermission(perm, userPermissions);
  };
  let UserType = "";
  if (hasPerm(PERMISSION.ta_view)) {
    UserType = "TL";
  } else if (hasPerm(PERMISSION.ta_leads_view)) {
    UserType = "TA";
  }
  useEffect(() => {
    if (UserType === "TA") {
      fetchDropdownData();
    }
  }, []);
  useEffect(() => {
    if (UserType === "TL") {
      fetchLeadStatusesDropdownData();
      fetchAllLeadStatusesDropdownData();
      getCountryDetails();
    }
  }, []);

  useEffect(() => {
    if (modalApiStatus.status === "success") {
      fetchData(fetchLeadParams);
    }
  }, [modalApiStatus]);

  useEffect(() => {
    if (UserType === "TA") {
      fetchData(fetchLeadParams);
    }
  }, [fetchLeadParams]);

  useEffect(() => {
    if (UserType === "TL") {
      fetchTlData(fetchTLLeadParams);
      // fetchLeadStatusesDropdownData();
    }
  }, [fetchTLLeadParams]);

  useEffect(() => {
    setLeadList(tableData.data.map(mapLeadDataToTableViewItem));
  }, [tableData]);

  const handleOnSearch = (searchBy: string) => {
    setFetchLeadParams({
      ...fetchLeadParams,
      offset: 0,
      search: searchBy,
    });
  };
  const handleOnTlSearch = (searchBy: string) => {
    setFetchTLLeadParams({
      ...fetchTLLeadParams,
      offset: 0,
      taName: searchBy,
    });
  };
  const handleChangePage = (newPage: number) => {
    setFetchLeadParams({
      ...fetchLeadParams,
      offset: newPage * tableData.pageSize,
    });
  };

  const loadNextPageOnScroll = () => {
    if (leadList && leadList.length < tableData.totalRecords) {
      const newPage = tableData.currentPage + 1;
      handleChangePage(newPage);
    }
  };
  const onApplyFilter = async (filter: any) => {
    setFetchTLLeadParams(filter);
  };
  const onClearFilter = async () => {
    setFetchTLLeadParams(DEFAULT_TLLEADFETCH_PARAMS);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
    ) => {
    const pageSize: number = parseInt(event.target.value, 10);
    if (UserType === "TA") {
      setFetchLeadParams({
        ...fetchLeadParams,
        limit: pageSize,
        offset: 0,
      });
    }
    if (UserType === "TL") {
      setFetchTLLeadParams({
        ...fetchTLLeadParams,
        limit: pageSize,
        offset: 0,
      });
    }
  };

  const onViewLead = (lead: LeadTableItem) => {
    dispatch(getLead(lead.id as number));
    setLeadsModalOpen(true);
  };
  const onTlViewLead = (lead: LeadTableItem) => {
    dispatch(getLead(lead.id as number));
    setTlLeadsModalOpen(true);
  };
  const handleLeadModalClose = () => {
    setLeadsModalOpen(false);
  };
  const handleTlLeadModalClose = () => {
    setTlLeadsModalOpen(false);
  };
  const handleDownloadLeads = () => {
    downloadLeads(fetchTLLeadParams);
  };

  const confrimUpdateLeadStatus = async (comment: string) => {
    await dispatch(updateLeadStatus(leadId, leadStatus, comment));
    setConfirmLeadStatusUpdatePopup(false);
  };

  const updateLeadStatusById = (lead: LeadTableItem, status: string, label: string) => {
    setLeadstatusLabel(label);
    setLeadstatus(status);
    setLeadId(lead.id as number);
    setApplicantName(lead.name);
    setConfirmLeadStatusUpdatePopup(true);
  };

  const onLeadActionClick = (lead: LeadTableItem, action: LEAD_TABLE_ACTION) => {
    switch (action) {
      case "SUBMIT_TO_SB":
        dispatch(submitToSBAction(lead));
        break;
      case "VIEW_APPLICATION":
        history.push(
          "/app/" + 
        encryptText(lead.convertedApplicationId) + 
        "/group/-1/question/-1"
        );
        break;
    }
  };
  const handleLeadStatusTimeline = (lead: LeadTableItem) => {
    dispatch(getLeadStatusTimeLine(lead.id as number));
    setApplicantName(lead.name);
    setLeadStatusTimelinePopup(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>{LABELS.LEADS_HEADER}</div>
      {UserType === "TA" && <ToolBar handleOnSearch={handleOnSearch} />}
      {UserType === "TL" && (
        <TlToolBar
          handleOnSearch={handleOnTlSearch}
          setFilterOpen={setFilterOpen}
          downloadLeads={handleDownloadLeads}
        />
      )}
      {leadList.length <= 0 ? (
        <NoDataMessage message={LABELS.NO_LEADS} />
      ) : (
        <>
          {UserType === "TA" && (
            <LeadsTable
              handleScrollEnd={loadNextPageOnScroll}
              leadList={leadList}
              onLeadActionClick={onLeadActionClick}
              onViewLead={onViewLead}
            />
          )}
          {UserType === "TL" && (
            <TlLeadsTable
              leadList={leadList}
              updateLeadStatusById={updateLeadStatusById}
              onTlViewLead={onTlViewLead}
              handleLeadStatusTimeline={handleLeadStatusTimeline}
            />
          )}

          {!isMobileView && (
            <TablePagination
              rowsPerPageOptions={isMobileView ? [] : PAGE_SIZES}
              component="div"
              count={tableData.totalRecords}
              rowsPerPage={tableData.pageSize}
              page={tableData.currentPage}
              onPageChange={(e, newPage) => {
                handleChangePage(newPage);
              }}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {isMobileView && (
            <div className={classes.legendWrapper}>
              {Object.keys(LeadStatusDetails).map((status) => {
                return (
                  status != "qualified" && (
                    <div 
                    className="legend"
                     key={LeadStatusDetails[status as LEAD_STATUS].label}
                     >
                      <div
                        className="icon"
                        style={{
                          backgroundColor: 
                          LeadStatusDetails[status as LEAD_STATUS].color,
                        }}
                      ></div>
                      <div>
                        {LeadStatusDetails[status as LEAD_STATUS].label}
                        </div>
                    </div>
                  )
                );
              })}
            </div>
          )}
          <LeadsModal 
          open={isLeadsModalOpen} 
          onClose={handleLeadModalClose} 
          leadData={selectedLeadDetail}
           />
          <TlLeadsModal 
          open={isTlLeadsModalOpen} 
          onClose={handleTlLeadModalClose}
          leadData={selectedLeadDetail} />       
          {UserType === "TL" && (
            <LeadStatusTimeline
              open={leadStatusTimelinePopup}
              onClose={() => setLeadStatusTimelinePopup(false)}
              ApplicantName={applicantName}
              data={selectedLeadStatusTimeline}
            />
          )}
          {confirmLeadStatusUpdatePopup ? (
            <LeadStatusUpdatePopup
              open={confirmLeadStatusUpdatePopup}
              onClose={() => setConfirmLeadStatusUpdatePopup(false)}
              ApplicantName={applicantName}
              leadstatus={leadStatusLabel}
              confrimUpdateLeadStatus={confrimUpdateLeadStatus}
            />
          ) : null}
        </>
      )}
         {UserType === "TL" && (
            <LeadsFilter
              filterOpen={filterOpen}
              onClose={() => setFilterOpen(false)}
              onApplyFilter={onApplyFilter}
              onClearFilter={onClearFilter}
              fetchTLLeadParams={fetchTLLeadParams}
              setFetchTLLeadParams={setFetchTLLeadParams}
            />
          )}
    </div>
  );
};

export default LeadsView;
