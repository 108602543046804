import { useLayoutEffect, useState } from 'react';
import { Base64 } from 'js-base64';
import SimpleCrypto from 'simple-crypto-js';
import { UserRoles } from '../constants/UserRoles';
import { extractRolesPermission, hasOneOfPermissions, PERMISSION } from './rolePermissionsUtil';
const simpleCrypto = new SimpleCrypto('SB67$YW');

export const numberToPhone = (number) => {
  if (number) {
    return number.replace(/\D+/g, '').replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, '$1$2$3'); //mask numbers (xxx) xxx-xxxx
  } else {
    return '';
  }
};

export const hasError = (response) => {
  return response.error && !response.error.success;
};

export const convertToPhoneFormat = (number) => {
  if (number) {
    return ('' + number).replace(/\D+/g, '').replace(/([0-9]{1,3})([0-9]{3})([0-9]{4}$)/gi, '($1) $2-$3'); //mask numbers (xxx) xxx-xxxx
  } else {
    return '';
  }
};

//Email validation
export const validateEmail = (inputText) => {
  const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (inputText.match(mailFormat)) {
    return true;
  } else {
    return false;
  }
};

//Card Number Format
export const formatCardNumber = (value) => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, '');

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) => [$1, $2, $3, $4].filter((group) => !!group).join(' '));
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

export const getApplicationIdInString = (value) => {
  const stringFormat = 'SB0000000';
  const newId = stringFormat.substr(0, stringFormat.length - String(value).length) + value;
  return newId;
};

export const getNameByRole = (role) => {
  if (role === UserRoles.Councillor) {
    return 'Counsellor';
  }

  if (role === UserRoles.AdmissionOfficers) {
    return 'Admission Officer';
  }

  if (role === UserRoles.AdmissionOfficersLeader) {
    return 'Admission Team Leaders';
  }
};

export const isProvidersWithSameCountry = (providers) => {
  let isSameCountry = false;
  if (providers && providers.length > 0) {
    let s = new Set(providers.map((p) => p.countryId));
    isSameCountry = s.size == 1;
  }
  return isSameCountry;
};

export const encryptText = (value) => {
  // const encodedString = new Buffer(value).toString("base64");
  // console.log('encodedString :::', Base64.encodeURI(value));
  return Base64.encodeURL(value);
  // return value;
};

export const decryptText = (value) => {
  return Base64.decode(value);
  // return value;
};

export const getApplicationGroupList = () => {
  return [
    'PreQuestions',
    'Registration',
    'Academic',
    'Work Exp',
    'Marriage',
    'English',
    'Student Info',
    'Sponsorship',
    'Sponsor',
    'Sponsor-1',
    'Sponsor-2',
    'Completion',
    'Acceptance',
    'Deferred',
    'UKKAcadmic',
    'UKKSponsor',
    'UKAcadmic',
    'UKSponsor',
    'CANAcadmic',
    'CANSponsor',
    'Offer Letter',
    'Sponsorship (CAS) Block',
    'Visa',
    'Additional Documents',
  ];
};

export const toGetRequestURLParam = (paramJson) => {
  let urlParams = '';
  if (paramJson) {
    urlParams = '?';
    urlParams += Object.entries(paramJson)
      .map((e) => e.join('='))
      .join('&');
  }
  return urlParams;
};
