import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Button, IconButton, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontFamily: 'Poppins',
      backgroundColor: 'rgb(239, 239, 239)',
      textTransform: 'none',
      letterSpacing: 0.75,
      '&:hover .MuiButton-label $span': {
        maxWidth: '100vh',
      },
      '&.MuiButton-contained:hover': {
        boxShadow: 'none',
        backgroundColor: '#e0e0e0',
      },
      '&:hover .MuiButton-root': {
        boxShadow: 'none',
        backgroundColor: 'rgb(239, 239, 239)',
      },
      display: 'flex',
      flexDirection: 'row',
      minWidth: 25,
      boxShadow: 'none',
    },
    buttonText: {
      maxWidth: 0,
      opacity: 1,
      transition: 'max-width 2s ease',
      WebkitTransition: 'max-width 2s ease',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    buttonIcon: {
      font: 'small-caption',
    },
  })
);

type SBRevealButtonProps = {
  className?: string;
  text: JSX.Element;
  icon: JSX.Element;
  onClick?: () => void;
  [x: string]: any;
};

const SBRevealButton: React.FC<SBRevealButtonProps> = ({ className, text, icon, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <Button className={clsx(classes.button, className)} variant={'contained'} onClick={onClick} {...rest}>
      <span className={clsx(classes.buttonText, className)}>{text}</span>
      <span className={clsx(classes.buttonIcon, className)}>{icon}</span>
    </Button>
  );
};

export default SBRevealButton;
