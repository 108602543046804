import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import _ from "lodash";

import {
  Theme,
  MenuItem,
  Select,
  InputBase,
  Chip,
  FormHelperText,
  ListSubheader,
} from "@material-ui/core";
import SBText from "../SBText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 5,
      height: 45,
      backgroundColor: "#FCFBFB",
      textAlign: "left",
      overflow: "hidden",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    },
    placeHolder: { textAlign: "left" },
    dropDown: {
      height: 45,
      overflow: "hidden",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      textAlign: "left",
      borderColor: "#3E4059",
      backgroundColor: "#FCFBFB",
      "&:focus": {
        borderColor: "#3E4059",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      textAlign: "left",
      color: "#736F6F",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    chip: {
      margin: 1,
      fontSize: 12,
      padding: 1,
      maxWidth: "calc(89% - 34px)",
    },
    menuItemRoot: {
      whiteSpace: "unset",
    },
  })
);

type SBMultiSelectDropdownProps = {
  className?: string;
  [x: string]: any;
  value: any;
  questionObj?: any;
  disabled?: boolean;
  groupBy?: string;
};
const SBMultiSelectDropdown: React.FC<SBMultiSelectDropdownProps> = ({
  className,
  value,
  questionObj,
  disabled,
  groupBy,
  ...rest
}) => {
  const SbInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "label + &": {},
      },
      input: {
        borderRadius: 5,
        backgroundColor: "#FCFBFB",
        border: "1px solid #3E4059",
        minHeight: 30,
        textAlign: "left",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        // Use the system font instead of the default Roboto font.
        fontFamily: "Poppins",
        "&:focus": {
          borderRadius: 5,
          borderColor: "#3E4059",
          // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        letterSpacing: 0.75,
      },
    })
  )(InputBase);

  const classes = { ...useStyles(), ...(rest.styles?.() || {}) };
  const [personName, setPersonName] = React.useState<string[]>([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name: string, personName: string[]) {
    return {
      fontWeight: personName.indexOf(name) === -1 ? 400 : 500,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    };
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as string[]);
    const valuesArray: any = event.target.value;
    const objectArray = [];
    for (let i = 0; i < valuesArray.length; i++) {
      if (valuesArray[i]) {
        const object = _.find(rest.options, function (o) {
          return o.value == valuesArray[i];
        });
        objectArray.push(object);
      }
    }
    console.log("objectArray", objectArray);

    rest.onDataChange(objectArray, questionObj);
  };

  // console.log("rest.options!!!!!", rest.options);
  // console.log("rest.value!!!!!", value);

  const getGroupedOptions = () => {
    if (groupBy) {
      const groupedOptions = _.groupBy(
        rest.options,
        (option: any) => option[groupBy]
      );
      const options: any[] = [];
      _.keys(groupedOptions).forEach((group) => {
        options.push(
          <ListSubheader
            style={{
              backgroundColor: "#EEEEEE",
              height: "30px",
              lineHeight: "30px",
            }}
          >
            {group}
          </ListSubheader>
        );
        groupedOptions[group].forEach((option) =>
          options.push(getOption(option))
        );
      });
      return options;
    } else {
      return rest.options.map((option: any) => getOption(option));
    }
  };

  const getOption = (option: any) => {
    return (
      <MenuItem
        key={option.value}
        value={option.value}
        classes={{ root: classes.menuItemRoot }}
        style={getStyles(option.value, personName)}
      >
        {option.value}
      </MenuItem>
    );
  };

  return (
    <>
      {rest.labelText ? (
        <SBText
          text={rest.labelText}
          className={classes.label}
          color={"#736F6F"}
        />
      ) : null}

      <Select
        labelId="demo-mutiple-name-label"
        id="demo-mutiple-chip"
        multiple
        // value={personName}
        value={value}
        onChange={handleChange}
        input={<SbInput />}
        MenuProps={MenuProps}
        fullWidth={true}
        renderValue={(selected) => {
          return (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          );
        }}
        disabled={
          (questionObj &&
            questionObj.status &&
            questionObj.status === "DISABLED") ||
          disabled 
            ? true
            : false
        }
        {...(rest.error
          ? { classes: { select: classes.error, root: className } }
          : { root: className })}
      >
        {getGroupedOptions()}
      </Select>
      {rest.error && (
        <FormHelperText classes={{ error: classes.helperError }} error>
          {rest.helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default SBMultiSelectDropdown;
