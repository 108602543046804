import { useCallback } from 'react';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from '../../../actions';
import { API_URL } from '../../../util/apiUrls';

export const useDownloadTaContract = () => {
  const dispatch = useDispatch();

  const downloadTaContract = useCallback(
    async (id: number, type: string, fileName:string) => {
      dispatch({ type: ON_LOADING_START });
      const url = `${API_URL.LPO.DOWNLOAD_CONTRACT(id)}`.replace('#', '%*%');
      try {
        const result = await get(
          url,
        //   @ts-ignore
          {
            responseType: 'blob',
          },
          dispatch
        );
        fileDownload(result, `${fileName}${type}`);
        // const blobUrl = URL.createObjectURL(result);
        // console.log('blobUrl ??????', blobUrl);
        // window.open(blobUrl);
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    downloadTaContract,
  };
};
