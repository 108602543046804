import React, { useCallback, useState } from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionBody: {
      width: '100%',
    },
    dropArea: {
      width: '90%',
      marginTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      height: 200,
      border: 'dashed 2px #d7d7d7',
      borderRadius: 25,
      margin: '35px 0px 0px 20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dropAreaText: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 400,
      color: '#8D8F97',
      width: '100%',
      textAlign: 'center',
    },
    clickStyle: {
      color: '#5C5F74',
      opacity: '0.5',
      fontWeight: 600,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  })
);

interface FileDropZoneProps {
  onFileDrop: any;
}

const FileDropZone: React.FC<FileDropZoneProps> = ({ onFileDrop }) => {
  const classes = useStyles();

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles.length) {
      onFileDrop(acceptedFiles);
    } else {
      onFileDrop(acceptedFiles);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
    accept: '.pdf',
  });

  return (
    <div className={classes.sectionBody}>
      <div className={classes.dropArea} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={classes.dropAreaText}>
          <span className={classes.clickStyle}>Click</span> to browse or <br />
          drag and drop your files
        </div>
      </div>
    </div>
  );
};

export default FileDropZone;
