import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory, useLocation } from "react-router";
import Grid from '@material-ui/core/Grid';
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  TextField,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import SBText from "../../../../components/base/SBText";
// import SBButton from "../../../components/base/SBButton";
import cloud from "./assets/cloud.png";
import deleteIcon from "./assets/delete.png";
import download from "./assets/download.png";
import { encryptText } from "../../../../util";
//import SBFileSelector from "../../../components/base/SBFileSelector";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 600,
      backgroundColor: theme.palette.background.paper,
      //border: '2px solid #000',
      boxShadow: theme.shadows[5],
      // padding: "0px 32px 24px",
    },
    textAreaWidth: {
      width: "78%",
      border: "1px solid black",
      borderRadius: "5px",
    },
    Lable: {
      // width: 440,
      paddingTop: "15px",
      paddingBottom: "5px",
    },
    fileUpload: {
      height: "100px !important",
      border: "1px solid black !important",
    },
    lableFont: {
      fontSize: "14px",
    },
    headerColor: {
      color: "white",
      fontSize: "14px",
    },
    headerText: {
      backgroundColor: "#5C5F74",
      color: "white",
      // marginRight: "-32px",
      // marginLeft: "-32px",
      padding: "10px 25px 10px",
    },
    saveBtnDiv: {
      alignContent: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: 30,
      marginLeft: 250,
    },
    saveBtn: {
      height: 40,
      borderRadius: 30,
      width: 93,
      backgroundColor: "#5C5F74",
      color: "white",
      fontSize: "12px",
    },
    statement: {
      color: "black",
      display:
        "inline-block",
      fontSize: 13,
      [theme.breakpoints.up(1440)]: {
        fontSize: 14,
      },
    }
  })
);

//let uploadedFile=null;

export default function SimpleModal(SimpleModalProps: any) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  //const [comment, setComment] = useState("hello");

  //console.log("Outcome object>>>>>",SimpleModalProps);
  let { outcomeQuestionsByType, outcomeType, appId } = SimpleModalProps;

  //console.log("appId:::::::", appId)
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  // filter the array
  let QuestionArray = [];
  for (let row of outcomeQuestionsByType) {
    if (!row.answer) {
      QuestionArray.push(row);
    }
  }

  function gotoApplication(notificationObject: any) {
    console.log("gotoapplication::::::", notificationObject);
    if (location.pathname.startsWith("/app/")) {
      history.replace(
        "/app/" +
          encryptText(notificationObject.applicationId) +
          "/group/" +
          encryptText(notificationObject.groupId) +
          "/question/" +
          encryptText(notificationObject.questionId)
      );
    } else {
      history.push(
        "/app/" +
          encryptText(notificationObject.applicationId) +
          "/group/" +
          encryptText(notificationObject.groupId) +
          "/question/" +
          encryptText(notificationObject.questionId)
      );
    }
  }

  let blanckLength = QuestionArray.length;
  let totalLength = outcomeQuestionsByType.length;

  let percentage = ((totalLength - blanckLength) / totalLength) * 100;

  //console.log("QuestionArray:::", QuestionArray)
  let text = `CheckList: ${outcomeType} Letter`;
  return (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.headerText}>
        <SBText
          style={{ color: "white", display: "inline-block" }}
          text={text}
        />
        <div
          style={{ display: "inline-block", float: "right", cursor: "pointer" }}
          onClick={() => SimpleModalProps.handleclose()}
        >
          <CloseIcon></CloseIcon>
        </div>
      </div>
     
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // paddingLeft: "25px",
        // paddingRight: "25px"
      }}>
         <Grid container>
        <Grid item xs={9}>
          <div
            id="simple-modal-description"
            style={{ padding: "10px 0px 10px", paddingLeft:25, display: "inline-block", width: 440 }}
          >
            <SBText
              className={classes.statement}
              text={`You have answered ${totalLength - blanckLength
                }/${totalLength} questions required to request a ${outcomeType} letter. To achieve a ${outcomeType}, Please Complete all the required questions, Here's a checklist of pending items.`}
            />

            {/*{`You have answered ${*/}
            {/*  totalLength - blanckLength*/}
            {/*}/${totalLength} questions required to request a ${outcomeType} letter. To achieve a ${outcomeType}, Please Complete all the required solutions, Here's a checklist of pending items.`}*/}
          </div>
        </Grid>
        <Grid item xs={3} style={{
              display: "flex",
            }} 
            justify='center'>
          <div
            style={{
              width: 110,
              display: "inline-block",
              padding: "10px 0px 10px",
            }}
          >
            <CircularProgressbar
              value={Math.round(percentage)}
              text={`${totalLength - blanckLength}/${totalLength}`}
            />
          </div>
        </Grid>
      </Grid>
       
      </div>
      <div style={{ height: "250px", paddingLeft: 9, paddingRight: 9 }}>
        <TableContainer style={{ overflowY: "auto", height: "100%" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Block</TableCell>
                <TableCell align="left">Questions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {QuestionArray.map((row: any) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.group.name}
                  </TableCell>
                  <TableCell align="left">
                    {row.question}{" "}
                    <ArrowForwardIcon
                      style={{ verticalAlign: "middle", width: "5%" }}
                      onClick={() =>
                        gotoApplication({
                          applicationId: appId,
                          groupId: row.group.id,
                          questionId: row.parentId ? row.parentId : row.id,
                        })
                      }
                    ></ArrowForwardIcon>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
