import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import SBText from "../../../components/base/SBText";
import { RootState } from "../../../reducer";
import { connect, ConnectedProps } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: "column",
      paddingBottom: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      flex: 1,
    },
    label: {
      marginLeft: 10,
    },
    row: {
      display: "flex",
      alignItems: "center",
      marginTop: 10,
    },
    green: {
      height: 18,
      width: 18,
      backgroundColor: "#8BB19C",
      borderRadius: 10,
    },
    yellow: {
      height: 18,
      width: 18,
      backgroundColor: "#ECCC97",
      borderRadius: 10,
    },
    red: {
      height: 18,
      width: 18,
      backgroundColor: "#D27F65",
      borderRadius: 10,
    },
    infoText: {
      textAlign: "center",
      fontWeight: 700,
      color: "#ECCC97",
      fontSize: 16,
    },

    helpText: {
      color: theme.palette.primary.contrastText,
      fontSize: 12,
      marginLeft: 10,
    },
  })
);

// type BlockHintsProps = {};

type PropsFromRedux = ConnectedProps<typeof connector>;
type BlockHintsProps = PropsFromRedux & {};

const BlockHints: React.FC<BlockHintsProps> = ({ sbApplication, ...rest }) => {
  const classes = useStyles();
  return (
    <>
      {sbApplication.initialStateCompleted ? (
        <div
          style={{
            // position: "absolute",
            bottom: "0",
            left: "0",
            padding: 15,
            width: '100%',
            paddingBottom: 30,
          }}
        >
          <div className={classes.row}>
            <div className={classes.green} />
            <SBText text={"Completed"} className={classes.helpText} />
          </div>

          <div className={classes.row}>
            <div className={classes.yellow} />
            <SBText text={"Partially Complete"} className={classes.helpText} />
          </div>

          <div className={classes.row}>
            <div className={classes.red} />
            <SBText text={"Not Started"} className={classes.helpText} />
          </div>
        </div>
      ) : (
        <div style={{ paddingRight: 10, paddingLeft: 10 }}>
          <SBText
            text={"Answer the questions first to open up blocks"}
            className={classes.infoText}
          />
        </div>
      )}
    </>
  );
};

// export default BlockHints;

const mapState = (state: RootState) => ({
  // @ts-ignore
  sbApplication: state.sbApplication.sbApplication,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(BlockHints);
