import React from 'react';

interface ImageViewerProps {
  src: string;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ src }) => {
  return (
    <div style={{ overflow: 'auto', width: '100%', maxHeight: '700px' }}>
      <img src={src} style={{ width: '100%' }} />
      //{' '}
    </div>
  );
};

export default ImageViewer;
