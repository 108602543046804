import { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useCallback, useState } from "react";
import SBButton from "../../../../components/base/SBButton";
import { useFetchAgentData } from "../../hooks/useFetchAgentData";
import AddAgentModal from "../AddAgentModal";
import { FormCloseOptions } from "../AddAgentModal/create-agent-form.types";
import Filter from "./Filter";
import Search from "./Search";
import FIlterSideBar from "./Filter/FIlterSideBar/FIlterSideBar";
import { filter } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.dark,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    header: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "36px",
      letterSpacing: 1,
      textAlign: "left",
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    searchSortParents: {
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.up(1920)]: {
        height: 60,
        alignItems: "unset",
      },
    },
    addAgentBtn: {
      height: 35,
      borderRadius: 30,
      textTransform: "none",
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        fontSize: 14,
      },
      padding: "5px 10px",
    },
  })
);

type ToolBarPros = {
  handleOnSearchOrFilter: (searchText: string, filterArray: string) => void;
};
// there is search-bar component in components but it looks like it's out of date
// move this to components after confirmation with team
const ToolBar: React.FC<ToolBarPros> = ({ handleOnSearchOrFilter }) => {
  const [isAddAgentDialogVisible, setAddAgentDialogVisible] = useState(false);
  const [isFilterSideBarVissible, setIsFIlterSideBarVissible] = useState(false);
  const [searchTextState, setSearchTextState] = useState("");
  const [filterArray, setFilterArray] = useState([]);
  const classes = useStyles();
  const { fetchData } = useFetchAgentData();

  const handleAddAgentBtnClick = useCallback(() => {
    setAddAgentDialogVisible(true);
  }, [setAddAgentDialogVisible]);

  const handleAgentModalClose = useCallback(
    (options: FormCloseOptions) => {
      setAddAgentDialogVisible(false);
      if (options.sucessful) {
        //fetchData("", "", 0, 5);
      }
    },
    [setAddAgentDialogVisible, fetchData]
  );

  const handleOnSearch = (searchText: string) => {
    //fetchData(searchText, filterArray, "", "");
    setSearchTextState(searchText);
    handleOnSearchOrFilter(searchText, filterArray ? filterArray.toString() : "");
  };

  const handleFilterButtonClick = () => {
    setIsFIlterSideBarVissible(true);
  };

  const handleApplyFilters = (filterArray: any) => {
    //fetchData(searchTextState, filterArray.toString());
    setFilterArray(filterArray);
    handleOnSearchOrFilter(searchTextState, filterArray.toString());
    setIsFIlterSideBarVissible(false);
  };

  const handleClearFilter = () => {
    handleOnSearchOrFilter(searchTextState, "");
    setIsFIlterSideBarVissible(false);
  };

  return (
    <div className={classes.toolbar}>
      <div>
        <SBButton
          icon={false}
          text={"+ Add Agent"}
          className={classes.addAgentBtn}
          onClick={handleAddAgentBtnClick}
        />
      </div>
      <div className={classes.searchSortParents}>
        <Search onSearch={handleOnSearch} />
        <Filter onFilterButtonClick={handleFilterButtonClick} />
        {isFilterSideBarVissible && (
          <FIlterSideBar
            onFilterSideBarClose={setIsFIlterSideBarVissible}
            onApplyFilters={handleApplyFilters}
            onClearFilters={handleClearFilter}
          />
        )}
      </div>

      <AddAgentModal
        open={isAddAgentDialogVisible}
        onClose={handleAgentModalClose}
      />
    </div>
  );
};

export default ToolBar;
