export const LABELS = {
  SEARCH_TEXT: "Search Text",
  APPLY_FILTER: "Apply filter",
  CLEAR_FILTER: "Clear filter",
  ASSIGN: "Assign",
  ASSIGN_TO_ME: "Assign to Me",
  ENTITY: "Entity",
  SELECT_ENTITY: "Select Entity",
  ENTITY_TYPE: "Entity Type",
  SELECT_ENTITY_TYPE: "Select Entity Type",
  AGENCY: "Agency",
  SELECT_AGENCY: "Select Agency",
  PARENT_AGENCY: "Parent Agency",
  AGENCIES: "Agencies",
  NEW_AGENCY: "+ New Agency",
  CREATE_AGENCY: "Create Agency",
  UPDATE_AGENCY: "Update Agency",
  CREATE_AGENT: "Add New Agent",
  UPDATE_AGENT: "Update Agent",
  VIEW_AGENT: "View Agent",
  AGENCY_NAME: "Agency Name",
  USER_NAME: "User Name",
  CLOSE: "Close",
  SAVE: "Save",
  INVITE: "Invite",
  VIEW_DETAILS: "View Details",
  USERS: "Users",
  CREATE_USER: "Create User",
  UPDATE_USER: "Update User",
  ROLES: "Roles",
  SELECT_ROLES: "Select Roles",
  CREATE_ROLE: "Create Role",
  UPDATE_ROLE: "Update Role",
  PROVIDER: "Provider",
  SELECT_PROVIDER: "Select Provider",
  PROVIDERS: "Providers",
  NAME: "Name",
  SUB_TITLE: "Sub Title",
  REGION: "Region",
  COUNTRY: "Country",
  CITY: "City",
  NO_PROVIDERS: "No Providers found.",
  USER_FILTER_ENTITY_GROUP: "By Entity Type/ Entity",
  UNAUTHORIZED_PAGE: 
  "You don't have permission to view this page, Please contact your administrator.",
  LPO_HEADER: "Trusted Advisors",
  NEW_LPO: "+ New TA",
  INVITE_LPO: "Invite TA",
  CREATE_LPO: "Create TA",
  UPDATE_LPO: "Update TA",
  VIEW_LPO: "View TA",
  SEARCH_BY_NAME: "Search by name",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  HIGHER_EDUCATION: "Higher Education",
  OTHER_HIGHER_EDUCATION: "Other Higher Education",
  BIRTH_DATE: "Birth Date",
  REASON_TO_BACOME_TA : "Reason To become TA",

  COMPANY_MAIL: "Company mail",
  ADDRESS_LINE1: "Address Line1",
  ADDRESS_LINE2: "Address Line2",
  ADDRESS_CITY: "City",
  ADDRESS_STATE: "State",
  ADDRESS_COUNTRY: "Country",
  ADDRESS_PINCODE: "Pincode",

  ACCEPTED_TERMS_CONDITION : "Accepted Terms and Condition",
  DOC_ID_PROOF_FRONT : "ID Proof(Front)",
  DOC_ID_PROOF_BACK : "ID Proof(Back)",
  DOC_ADDRESS_PROOF : "Address Proof",
  DOC_BANK_DETAILS : "Bank Details",

  VERIFICATION_EMAIL : "Email Verification",
  VERIFICATION_OTP : "Mobile Verification",
  VERIFICATION_KYC : "KYC Verification",


  EMAIL: "Email",
  PHONE: "Phone",
  OCCUPATION: "Occupation",
  STATUS: "Status",
  ACTIONS: "Actions",
  ACTION: "Action",
  NO_LPOS: "No TAs found.",
  LEADS_HEADER: "Leads",
  LEAD_DETAILS: "Lead Details",
  NEW_LEAD: "+ New Lead",
  VIEW_LEAD: "View Lead",
  CREATE_LEAD: "Create Lead",
  UPDATE_LEAD: "Update Lead",
  NO_LEADS: "No Leads found.",
  APPLICANT_NAME: "Applicant Name",
  APPLICANT_FIRST_NAME: "Applicant First Name",
  APPLICANT_LAST_NAME: "Applicant Last Name",
  PHONE_NUMBER: "Phone Number",
  APPLICANT_CITY: "Applicant City",
  TOTAL_LEADS: "Total Leads",
  LEADS: "Leads",
  APPLICATION: "Application",
  APPLICATIONS_CONVERTED: "Applications Converted",
  COMPLETED_APPLICATIONS: "Applications Completed",
  COMPLETION_PERCENTAGE: "Completion Percentage",
  BAR_CHART_TITLE: "Leads/Application Converted",
  PIE_CHART_TITLE: "Converted Application Status",
  STATUS_MATRIX: "Status Matrix",
  APPLICATION_COUNT: "Application Count",
  OPEN: "Open",
  DRAFT: "Draft",
  CLOSED: "Closed",
  REJECTED: "Rejected",
  WITHDRAWN: "Withdrawn",
  OK: "Ok",
  CANCEL: "Cancel",
  APPLICANT_COUNTRY: "Applicant Country",
  PREFERRED_COUNTRY: "Preferred Country",
  PREFERRED_COURSE: "Preferred Course",
  ADDITIONAL_COMMENT: "Additional Comment",
  SEARCH_BY_APPLICANT_NAME: "Search by Applicant name",
  SELECT_PREFERRED_COUNTRYENTITY: "Select Preferred Country",
  SELECT_PREFERRED_COURSE: "Select Preferred Course",
  SAVE_AS_DRAFT: "Save as Draft",
  SUBMIT: "Submit",
  SUBMIT_TO_SB: "Submit to SB",
  RESEND: "Resend",
  RESEND_INVITE: "Resend Invite",
  RESENT_INVITE_CONFIRMATION:
    "An invitation has already been sent to this agent. Do you want to resend the invitation?",
  NO_EDIT_PERMISSION: "You don't have permission to edit this application",
  ACCOUNT_ACTIVE_PLACEHOLDER: "Account status",
  TRUSTED_ADVISOR: "Trusted Advisor",
  TA_NAME:"TA Name",
  TA_EMAIL:"TA Email",
  ORIGIN_OF_LEAD:"Origin of lead",
  DESTINATION_OF_LEAD:"Destination of lead"
};
