import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import SBButton from "../base/SBButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
  })
);

type ErrorDialogProps = {
  className?: string;
  open: boolean;
  onSubmit: () => void;
  message: string;
  header: string;
};
const CommenMessageDialog: React.FC<ErrorDialogProps> = ({
  open,
  message,
  header,
  // setOpen,
  onSubmit,
}) => {
  const handleSubmit = () => {
    // setOpen(false);
    onSubmit();
  };

  const handleClose = () => {
    // setOpen(false);
    onSubmit();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SBButton icon={false} text={"OK"} onClick={handleSubmit} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommenMessageDialog;
