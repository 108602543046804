import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducer";
import { ActionType } from "../reducer/types";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";
import { get } from "./common";
import config from "../config";
import { RESET_APPLICATION_LIST } from "./sbApplication";
export const SET_VERIFICATION_EMAIL = "SET_VERIFICATION_EMAIL";
export const RESET_VERIFICATION_EMAIL = "SET_VERIFICATION_EMAIL";
export const SET_EMAIL_VERIFICATION_DETAILS = "SET_EMAIL_VERIFICATION_DETAILS";
export const RESET_EMAIL_VERIFICATION_DETAILS =
  "RESET_EMAIL_VERIFICATION_DETAILS";

export const getApplicationVerificationMails = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });
      return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
          dispatch({ type: ON_LOADING_START });
          //Call API
          const result = await get(
            `${config.BASE_URL}/verification`,
            null,
            dispatch
          );
         // console.log("Verification Application result", result);
          resolve(result);
          dispatch({ type: SET_VERIFICATION_EMAIL, payload: result });
          dispatch({ type: ON_LOADING_FINISHED });
        }
      );
    }
  );
};

export const getVerificationMailDetails = (
  id: number
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });
      return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
          dispatch({ type: ON_LOADING_START });
          //Call API
          const result = await get(
            `${config.BASE_URL}/verification/inbox/${id}`
          );
         // console.log("Verification Application Deatil result", result);
          resolve(result);
          dispatch({ type: SET_EMAIL_VERIFICATION_DETAILS, payload: result });
          dispatch({ type: ON_LOADING_FINISHED });
        }
      );
    }
  );
};

export const resetApplicationVerificationMailList = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  dispatch({
    type: RESET_VERIFICATION_EMAIL,
  });
};

export const resetApplicationMailDetails = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  dispatch({
    type: RESET_EMAIL_VERIFICATION_DETAILS,
  });
};
