import React, { useState } from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import _ from "lodash";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SBButton from "../base/SBButton";
import SBText from "../base/SBText";
import clsx from "clsx";
import SBFilterDropdown from "../base/SBFilterDropdown";
import moment from "moment";
import SBDate from "../base/SBDate";
import { getApplicationIdInString } from "../../util";
import AppColor from "../../theme/AppColor";
import App from "../../App";
import rightArrow from "./assets/right_arrow.png";
import { CloseOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    cancelButton: {
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: "#FAFAFA",
      borderColor: AppColor.appBlue,
      borderWidth: 1,
      borderStyle: "solid",
      color: AppColor.appBlue,
    },
    requestButton: {
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 16,
      marginBottom: 10,
    },
    paper: { width: "450px", minHeight: 300 },
    checkButtonText: {
      marginLeft: 6,
      fontSize: 16,
      fontWeight: 600,
      color: AppColor.appBlue,
    },
    uncheckButton: { opacity: 0.5, height: 30, width: 30 },
    checkButton: { height: 30, width: 30 },
    checkedText: { color: "black" },
    arrow: {
      "&:before": {
        border: "0px solid #E6E8ED",
      },
      color: AppColor.appBlue,
    },
    tooltip: {
      backgroundColor: AppColor.appBlue,
      border: "0px solid #E6E8ED",
      color: "white",
      width: 120,
      textAlign: "center",
    },
    textAreaWidth: {
      border: "1px solid black",
      borderRadius: "5px",
      fontFamily: "Poppins",
    },
    label: {
      textAlign: "left",
      color: theme.palette.primary.light,
      display: "flex",
    },
    alertTitle: {
      fontFamily: "poppins",
      letterSpacing: 0.15,
      fontWeight: 600,
      backgroundColor: AppColor.appBlue,
    },
    mainTitle: {
      fontSize: 16,
      color: "white",
      fontWeight: 600,
    },
    subTitle: {
      fontSize: 12,
      color: "white",
    },
    dialogContentParent: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
    headerParent: {
      fontFamily: "poppins",
      letterSpacing: 0.15,
      fontWeight: 600,
      color: AppColor.appWhite,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      // height: 50,
    },
  })
);

type SBDefermentDetailsDialogProps = {
  className?: string;
  open: boolean;
  onClose: () => void;
  sbApplication?: any;
  deferredDetailApplication: any;
  dataObj?: any;
};
const SBDefermentDetailsDialog: React.FC<SBDefermentDetailsDialogProps> = ({
  open,
  sbApplication,
  deferredDetailApplication,
  // setOpen,
  onClose,
  dataObj,
}) => {
  const reasonList = [
    { id: 1, value: "Yes" },
    { id: 2, value: "No" },
  ];

  console.log("dataObj", deferredDetailApplication);

  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const formattedDate = (date: Date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const renderRow = () => {
    const deferredArray = _.sortBy(
      deferredDetailApplication.deferred,
      (dateObj) => {
        return dateObj.id;
      }
    ).reverse();

    const deferredComponentArray: any = [];

    for (let i = 0; i < deferredArray.length; i++) {
      const object = deferredArray[i];
      let statusColor = "";
      let statusText = "";
      // nextTerm: "Jul-22"
      // outcome: ""
      // previousTerm: "Mar-22"

      if (i == 0) {
        deferredComponentArray.push(
          <SBText
            text={"Current Status"}
            style={{ fontSize: 12, fontWeight: 600, marginBottom: 8 }}
          />
        );
      }

      if (i == 1) {
        deferredComponentArray.push(
          <SBText
            text={"History"}
            style={{
              fontSize: 12,
              fontWeight: 600,
              marginBottom: 8,
              marginTop: 8,
            }}
          />
        );
      }

      if (object.approvedStatus && object.approvedStatus == "Approve") {
        statusColor = AppColor.appGreen;
        statusText = "Approved";
      } else if (object.approvedStatus && object.approvedStatus == "Reject") {
        statusColor = AppColor.appRed;
        statusText = "Rejected";
      } else {
        statusColor = AppColor.appDarkGrey;
        statusText = "In Progress";
      }

      deferredComponentArray.push(
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SBText
              text={"Status : "}
              style={{ fontSize: 10, fontWeight: 600, marginRight: 5 }}
            />
            <SBText
              text={statusText}
              style={{
                fontSize: 10,
                fontWeight: 600,
                color: statusColor,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <SBText
              text={"Terms : "}
              style={{ fontSize: 10, fontWeight: 600, marginRight: 5 }}
            />
            <SBText
              text={object.previousTerm}
              style={{
                fontSize: 10,
                fontWeight: 600,
                color: AppColor.appLightGrey,
              }}
            />
            <img src={rightArrow} style={{ height: 16, marginTop: 2 }} />
            <SBText
              text={object.nextTerm}
              style={{
                fontSize: 10,
                fontWeight: 600,
              }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SBText
              text={"Reason : "}
              style={{ fontSize: 10, fontWeight: 600, marginRight: 5 }}
            />
            <SBText
              text={object.comments}
              style={{
                fontSize: 10,
                fontWeight: 600,
                color: AppColor.appLightGrey,
              }}
            />
          </div>

          <Divider />
          {/*{i != 0 ? <Divider /> : null}*/}
        </>
      );
    }

    return deferredComponentArray;
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.alertTitle}>
          <div className={classes.headerParent}>
            <SBText className={classes.mainTitle} text={"Deferment Details"} />

            <IconButton size={"small"} onClick={() => handleClose()}>
              <CloseOutlined
                fontSize={"small"}
                style={{ color: AppColor.appWhite }}
              />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContentParent}>{renderRow()}</div>
        </DialogContent>
        <DialogActions>
          <SBButton
            icon={false}
            text={"OK"}
            onClick={() => handleClose()}
            className={classes.requestButton}
          />
          {/*<SBButton icon={false} text={"OK"} onClick={handleSubmit} />*/}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SBDefermentDetailsDialog;
