export default {
  appGreen: "#8BB19C",
  appRed: "#D27F65",
  appBlue: "#5C5F74",
  appDarkGrey: "#736F6F",
  appGray: "#EFEFEF",
  textGray: "#898C9C",
  appLightGrey: "#B0B0B0",
  appBlack: "#000000",
  appWhite: "#ffffff",
  appLink: "rgba(93,145,213,1)",
  menuTextColor: "rgba(89,109,121,1)",
};
