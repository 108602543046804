import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from "../../../actions";
import { API_URL } from "../../../util/apiUrls";
import { LpoAction } from "../store/actions/action-types";
import { FetchLPOParams } from "../types";
import { getLPORequestQuery } from "../utils";

export const useFetchLPOPaginatedData = () => {
  const dispatch = useDispatch();

  const fetchData = useCallback(
    async (params: FetchLPOParams) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(
          `${API_URL.LPO.LIST}${getLPORequestQuery(params)}`,
          dispatch
        );
        
        dispatch({ type: LpoAction.SET_LPO_PAGINATED_DATA, payload: result });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    fetchData,
  };
};
