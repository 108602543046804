import { Button, Dialog, DialogActions, DialogContent, TablePagination, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import NoDataMessage from '../../../components/NoDataMessage';
import { RootState } from '../../../reducer';
import { tableViewHeaderStyle, tableViewRootStyle } from '../../../styles';
import { LABELS } from '../../../util/labels';
import { DEFAULT_FETCH_PARAMS, PAGE_SIZES } from '../constants';
import { useDownloadTaDoc } from '../hooks/useDownloadTaDoc';
import { useUploadSignedContract } from '../hooks/useUploadSignedContract';
import { useDownloadTaContract } from '../hooks/useDownloadTaContract';
import { useFetchLPOPaginatedData } from '../hooks/useFetchLPOPaginatedData';
import { useGetKYCDetails } from '../hooks/useGetKYCDetails';
import { useInviteLPO } from '../hooks/useInviteLPO';
import { useRejectLPO } from '../hooks/useRejectLPO';
import LPOModal from '../LPOModal';
import LPOTable from '../LPOTable';
import { getLPO, onboardLPOAction } from '../store/actions';
import { selectedLPODetails, selectLPOModalAPIStatus, selectLPOPaginatedData } from '../store/selectors';
import TARejectionDialog from '../TARejectionDialog';
import ToolBar from '../ToolBar';
import { LPOPaginatedData, FetchLPOParams, LPOTableItem, LPO_TABLE_ACTION, LPOModalAPIStatus, LPO } from '../types';
import { mapLPODataToTableViewItem } from '../utils';
import UploadDocumentPopup from './UploadDocumentPopup';
import async from 'react-select/async';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...tableViewRootStyle(theme),
    },
    header: {
      ...tableViewHeaderStyle,
    },
  })
);
type confirmationConfig =
  | {
      action: LPO_TABLE_ACTION;
      title: string;
      message: string;
      lpoItem: LPOTableItem;
    }
  | undefined;

type ConfirmationDialogRawProps = {
  confirmConfig: confirmationConfig;
  open: boolean;
  onClose: (proceedFurther: boolean, confirmConfig: confirmationConfig) => void;
};
const ConfirmationDialogRaw = (props: ConfirmationDialogRawProps) => {
  const { open, onClose, confirmConfig } = props;

  const handleCancel = () => {
    onClose(false, confirmConfig);
  };

  const handleOk = () => {
    onClose(true, confirmConfig);
  };
  return (
    <Dialog open={open}>
      <DialogContent>{confirmConfig?.message}</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {LABELS.CANCEL}
        </Button>
        <Button onClick={handleOk} color="primary">
          {LABELS.OK}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const LPOView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fetchData } = useFetchLPOPaginatedData();
  const { rejectLPO } = useRejectLPO();
  const { inviteLPO } = useInviteLPO();
  const { downloadTaFile } = useDownloadTaDoc();
  const { downloadTaContract } = useDownloadTaContract();
  const { postUploadSignedContract } = useUploadSignedContract();
  const { getKYCDetails } = useGetKYCDetails();
  const tableData: LPOPaginatedData = useSelector(selectLPOPaginatedData);
  const selectedLPODetail: LPO = useSelector(selectedLPODetails);
  const modalApiStatus: LPOModalAPIStatus = useSelector(selectLPOModalAPIStatus);
  const [isLPOModalOpen, setLPOModalOpen] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmConfig, setConfirmConfig] = useState<confirmationConfig>();
  const [lpoList, setLpoList] = useState<LPOTableItem[]>([]);
  const [fetchLPOParams, setFetchLPOParams] = useState<FetchLPOParams>(DEFAULT_FETCH_PARAMS);
  const [rejectioDialog, showRejectionDialog] = useState<boolean>(false);
  const [opanUploadDocumentPopup, setOpenUploadDocumentPopup] = React.useState(false);
  const [taName, setTaName] = useState<string>('');

  useEffect(() => {
    if (modalApiStatus.status === 'success') {
      fetchData(fetchLPOParams);
    }
  }, [modalApiStatus]);

  useEffect(() => {
    fetchData(fetchLPOParams);
  }, [fetchLPOParams]);

  useEffect(() => {
    setLpoList(tableData.data.map(mapLPODataToTableViewItem));
  }, [tableData]);

  const handleOnSearch = (searchBy: string) => {
    setFetchLPOParams({
      ...fetchLPOParams,
      offset: 0,
      search: searchBy,
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFetchLPOParams({
      ...fetchLPOParams,
      offset: newPage * tableData.pageSize,
    });
  };

  const handleRejectionSubmit = (reason: string) => {
    showRejectionDialog(false);
    const lpoId = confirmConfig?.lpoItem.id as number;
    rejectLPO(lpoId, fetchLPOParams, reason);
  };

  const uploadDocumnet =async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    await postUploadSignedContract(confirmConfig?.lpoItem.id as number, formData);
    setOpenUploadDocumentPopup(false);
  };

  const downloadContract = (taId: number) => {
    downloadTaContract(taId, '.pdf', `Contract-${confirmConfig?.lpoItem.firstName}-${confirmConfig?.lpoItem.lastName}`);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize: number = parseInt(event.target.value, 10);
    setFetchLPOParams({
      ...fetchLPOParams,
      limit: pageSize,
      offset: 0,
    });
  };

  const onLPOActionClick = (lpo: LPOTableItem, action: LPO_TABLE_ACTION) => {
    console.log('action',action);
    
    switch (action) {
      case 'ONBOARD':
        setConfirmConfig({
          action,
          lpoItem: lpo,
          title: 'Onboard',
          message: `Do you want to onboard ${lpo.firstName} ${lpo.lastName} as an LPO?`,
        });
        setConfirmOpen(true);
        break;
      case 'REJECT':
        setConfirmConfig({
          action,
          lpoItem: lpo,
          title: 'Reject',
          message: `Are you sure, You want to reject ${lpo.firstName} ${lpo.lastName}'s TA application?`,
        });
        setConfirmOpen(true);

        break;
      case 'APPROVE':
        setConfirmConfig({
          action,
          lpoItem: lpo,
          title: 'Approve',
          message: `Do you want to approve ${lpo.firstName} ${lpo.lastName} as a Trusted Adviser?`,
        });
        setOpenUploadDocumentPopup(true);
        break;
      case 'RESEND_INVITE':
      case 'INVITE':
        setConfirmConfig({
          action,
          lpoItem: lpo,
          title: 'Approve',
          message: `Do you want to Resend invite to  ${lpo.firstName} ${lpo.lastName} as a Trusted Adviser?`,
        });
        setConfirmOpen(true);
        break;
    }
  };

  const onViewLPO = (lpo: LPOTableItem) => {
    dispatch(getLPO(lpo.id as number));
    // dispatch(getKYCDetails(lpo.id as number));
    //
    // const result = getKYCDetails(lpo.id)
    setLPOModalOpen(true);
  };

  const handleLPOModalClose = () => {
    setLPOModalOpen(false);
  };

  const downloadFile = (id: number, type: string, fileName: string) => {
    downloadTaFile(id, type, fileName);
  };

  const handleConfirmClose = (proceedFurther: boolean, confirmConfig: confirmationConfig) => {
    setConfirmOpen(false);
    if (proceedFurther) {
      const lpoId = confirmConfig?.lpoItem.id as number;
      switch (confirmConfig?.action) {
        case 'APPROVE':
          dispatch(onboardLPOAction(lpoId, fetchLPOParams));
          break;
        case 'REJECT':
          // rejectLPO(lpoId, fetchLPOParams);
          showRejectionDialog(true);
          break;
        case 'RESEND_INVITE':
        case 'INVITE':
          inviteLPO(lpoId, fetchLPOParams);
          break;
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>{LABELS.LPO_HEADER}</div>
      <ToolBar handleOnSearch={handleOnSearch} />
      {lpoList.length <= 0 ? (
        <NoDataMessage message={LABELS.NO_LPOS} />
      ) : (
        <>
          <LPOTable lpoList={lpoList} onLPOActionClick={onLPOActionClick} onViewLPO={onViewLPO} />

          <TablePagination
            rowsPerPageOptions={PAGE_SIZES}
            component="div"
            count={tableData.totalRecords}
            rowsPerPage={tableData.pageSize}
            page={tableData.currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ConfirmationDialogRaw open={confirmOpen} confirmConfig={confirmConfig} onClose={handleConfirmClose} />
          <LPOModal
            open={isLPOModalOpen}
            onClose={handleLPOModalClose}
            lpoData={selectedLPODetail}
            onViewFile={(id: number, type: string, fileName: string) => downloadFile(id, type, fileName)}
          ></LPOModal>

          <TARejectionDialog
            open={rejectioDialog}
            onSubmit={(text) => handleRejectionSubmit(text)}
            message={''}
            header={'Enter Rejection Reason'}
            onClose={() => showRejectionDialog(false)}
          />
          {opanUploadDocumentPopup ? (
            <UploadDocumentPopup
              open={opanUploadDocumentPopup}
              onClose={() => setOpenUploadDocumentPopup(false)}
              ApplicantName={`${confirmConfig?.lpoItem.firstName} ${confirmConfig?.lpoItem.lastName}`}
              uploadDocumnet={uploadDocumnet}
              downloadContract={downloadContract}
            />
          ) : null}
        </>
      )}
    </div>
  );
};

export default LPOView;
