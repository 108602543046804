// @ts-ignore-file
// @ts-nocheck
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { IconButton, Menu, MenuItem, Theme, Button } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../reducer';
import {
  deferApplicationByPOVnt,
  duplicateAction,
  getAllStatusList,
  getApplicationDetails,
  getApplicationStatus,
  getCountryDetails,
  getQuestionByGroup,
  getQuestionsbyOutCome,
  getTermsAsPerCountry,
  resetSBApplication,
  setCountryProviderDetails,
  setQuestionCounter,
  setShowHideQuestions,
  showLockApplicationDialogBox,
  submitQuestionAnswer,
  updateApplicationStatus,
  updateApplicationSubStatus,
  updateCountryDetailsOfApplication,
  updateCountryProviderChange,
  lockBlocks,
  getApplicationRejectionReason,
  updatePreQuestionAnswer,
  deleteCurrentApplication,
  changeApplicationCourse,
  syncGroupStatus,
  syncQuestionStatus,
  setUpdatedQuestionList,
  setQuestionStatusUpdate,
  setFilteredQuestion,
  resetSelectedCountryChangeDetails,
  getfilesCount,
} from '../../actions';
import Page from '../../components/Page';
import { useHistory, useParams } from 'react-router';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ProgressStepper from './ProgressStepper';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import './newapp.css';

import MainCard from './cardsList/MainCard';
import _ from 'lodash';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RestorePageIcon from '@material-ui/icons/Restore';
import backArrow from './LeftBlockPanel/assets/backArrow.png';
import nextArrow from './LeftBlockPanel/assets/nextArrow.png';
import LeftBlockPanel from './LeftBlockPanel/index';
import RightBlockPanel from './RightBlockPanel';
import StatusGraph from './StatusGraph';
import SBText from '../../components/base/SBText';
import { getFormattedQuestion } from '../../selector/questions';
import SBDefermentDetailsDialog from '../../components/SBDefermentDetailsDialog';
import { decryptText, getApplicationIdInString } from '../../util';
import AppColor from '../../theme/AppColor';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { theme } from '../../theme';
import ApplicationStatusReasonModel from './ApplicationStatusReasonModel';
import CommenErrorDialog from '../../components/CommenErrorDialog';
import LockApplicationDialog from './LockApplicationDialog';
import DuplicateApplicationDialog from './DuplicateApplicationDialog';
import PreQuestionDetailsModel from './PreQuestionDetailsModel';
import ChangeCountryDialog from './ChangeCountryDialog';
import { hasOneOfPermissions, hasPermission, PERMISSION } from '../../util/rolePermissionsUtil';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import ChangeBasicDetailDialog from './ChangeBasicDetailDialog';
import { PAGE_ROUTE } from '../../util/pageRoutes';
import { GroupStatus, getGroupStatusByQuestionAnswer } from './SBApplicationUtils';
import DeferedApplicationDialog from './DeferedApplicationDialog';
import { SBTooltip } from '../../components/base/SBTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F1F1F1',
      // display: "flex",
      // flexDirection: "row",
      // overflowX: "hidden",
      // width: "100%",
      width: '100%',
      display: 'flex',
      minHeight: 'calc(100% - 64px)',
      overflowY: 'hidden',
      overflowX: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
    },
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    filterButton: {
      display: 'flex',
    },
    filterIcon: {
      marginRight: theme.spacing(1),
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    appIDParent: {
      height: 25,
      backgroundColor: '#FCEFCD',
      minWidth: 100,
      borderRadius: 7,
      borderColor: '#C2B78A',
      borderWidth: 1,
      borderStyle: 'solid',
      paddingLeft: 10,
      paddingRight: 10,
      justifyContent: 'center',
      alignItems: 'center',
    },
    appIdSuperParent: {
      minWidth: 300,
      height: 30,
      justifyContent: 'center',
      display: 'flex',
      flex: 0,
    },
    componentParent: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(3),
      textAlign: 'left',
    },
    cardRoot: {
      // minHeight:250,
      minWidth: '45vw',
      marginBottom: 30,
      marginTop: 30,
      height: 218,
      // '&:last-child': {
      //     paddingBottom: 3,
      // },
    },
    leftPanel: {
      height: 'calc(100vh - 64px)',
      overflow: 'scroll',
      width: '180px',
      padding: 10,
      [theme.breakpoints.up(1366)]: {
        padding: 15,
        width: '200px',
      },
      [theme.breakpoints.up(1440)]: {
        padding: 15,
        width: '312px',
      },
      backgroundColor: '#5C5F74',
      // height: 'fit-content'
    },
    buttonStyle: {
      height: 30,
      backgroundColor: '#BDBEC7',
      textTransform: 'none',
      marginRight: 10,
      color: AppColor.appBlue,
      borderWidth: 1,
      borderColor: AppColor.appBlue,
      borderStyle: 'solid',
      '&:hover': {
        backgroundColor: '#BDBEC7',
      },
    },
    buttonDisabledStyle: {
      height: 30,
      backgroundColor: '#E0E0E0',
      textTransform: 'none',
      marginRight: 10,
      color: AppColor.appBlue,
    },
    leftDOpenPanel: {
      width: '19%',
      height: '94vh',
      minWidth: 160,
      // [theme.breakpoints.up("lg")]: {
      //   minWidth: 200,
      // },
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      // justifyContent: "space-between",
      flexDirection: 'column',
      overflowY: 'scroll',
    },
    rightPanel: {
      height: 'calc(100vh - 64px)',
      overflow: 'scroll',
      width: '250px',
      [theme.breakpoints.up(1440)]: {
        width: '286px',
      },
      backgroundColor: 'white',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      paddingTop: 23,
      paddingBottom: 23,
    },
    rightDOpenPanel: {
      width: '19%',
      height: '94vh',
      backgroundColor: 'white',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },

    middlePanel: {
      width: 'calc(100% - 180px - 250px - 73px)',
      left: 'calc(180px + 73px)',
      paddingLeft: 15,
      paddingRight: 15,
      position: 'fixed',
      height: 'calc(100% - 64px)',
      [theme.breakpoints.up(1366)]: {
        width: 'calc(100% - 200px - 250px - 73px)',
        left: 'calc(200px + 73px)',
        paddingLeft: 20,
        paddingRight: 20,
      },
      [theme.breakpoints.up(1440)]: {
        width: 'calc(100% - 286px - 312px - 73px)',
        left: 'calc(312px + 73px)',
        paddingLeft: 25,
        paddingRight: 25,
      },
      [theme.breakpoints.up(1920)]: {
        paddingLeft: 41,
        paddingRight: 41,
      },
    },
    middleDOpenPanel: {
      display: 'flex',
      flexDirection: 'column',
      // width: "62%",
      flex: 1,
      height: '94vh',
      backgroundColor: '#F1F1F1',
    },

    ca: {
      // height: 'calc(50vh - 50px)',
      marginTop: 20,
      '& .carousel': {
        '& .thumbs-wrapper': {
          marginTop: 0,
        },
        height: 'inherit',
        '& .slider-wrapper': {
          height: 'inherit',
          '& .slider': {
            height: 'inherit',
            '& li': {
              height: 'inherit',
            },
          },
        },
      },
      '& li.slide': {
        backgroundColor: '#F1F1F1',
        '& div > div': {
          borderLeftColor: '#F1F1F1',
          borderRightColor: '#F1F1F1',
        },
      },
      '& li.slide.selected': {
        '& div > div': {
          borderColor: '#727487',
        },
      },
    },
    nextBackButton: {
      height: 50,
      borderRadius: 25,
      width: 50,
      backgroundColor: ' #3E4059',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: '#81839c',
      },
    },
    nextBackBtParent: {
      height: '100%',
      width: '12%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    middleTopPanel: {
      paddingTop: 32,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    timerParent: {
      width: '30%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      // backgroundColor: "red",
      paddingLeft: 30,
      paddingRight: 30,
    },
    studentName: {
      fontSize: '16px',
      [theme.breakpoints.up(1920)]: {
        fontSize: '18px',
      },
      fontWeight: 700,
      color: AppColor.appBlack,
      marginBottom: 10,
    },
    applicationInfo: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: 15,
      // marginBottom: "1.8rem",
    },
    applicationInfoText: {
      fontSize: '12px',
      [theme.breakpoints.up(1440)]: {
        fontSize: '13px',
      },
      [theme.breakpoints.up(1920)]: {
        fontSize: '14px',
      },
      marginRight: 10,
    },
    applicationInfoValue: {
      fontSize: '12px',
      [theme.breakpoints.up(1440)]: {
        fontSize: '13px',
      },
      [theme.breakpoints.up(1920)]: {
        fontSize: '14px',
      },
      wordBreak: 'break-word',
    },
    applicationDetailsParent: {
      display: 'flex',
      padding: '13px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      flexDirection: 'column',
      justifyContent: 'center',
      backgroundColor: '#BDBEC7',
      width: '100%',
    },
    startApplicationBt: {
      height: 50,
      width: 200,
      borderRadius: 25,
    },
    stepperContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 32,
      paddingTop: 10,
      paddingBottom: 10,
      width: '100%',
      height: 'inherit',
    },
    formControl: {
      display: '-webkit-inline-box',
      minWidth: 120,
    },
    overlayApplicationInfo: {
      // overflowY: 'scroll',
      // maxHeight: 200
    },
    fileCount: {
      backgroundColor: 'rgb(210, 127, 101)',
      borderRadius: 10,
      height: 18,
      width: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 3,
    },
    viewFilesBtn: {
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#BDBEC7',
      borderBottomLeftRadius: 5,
      fontFamily: 'Poppins',
      borderTop: '#5c5f74 1px solid',
      cursor: 'pointer',
      width: '100%',
      '&:hover': {
        backgroundColor: '#BDBEC7BB',
      },
      '@media ( max-width: 1439px)': {
        width: '100%',
        borderRadius: 0,
        borderTop: '#5c5f74 1px solid',
      },
    },
    viewFilesContainer: {
      display: 'flex',
      '@media ( max-width: 1439px )': {
        display: 'block',
      },
    },
    deferredDParent: {
      height: 20,
      width: 20,
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dText: {
      fontSize: 12,
      textAlign: 'center',
      color: AppColor.appWhite,
      fontWeight: 700,
      marginLeft: 1,
      marginTop: -1,
    },
    verifyBtn: {
      padding: '12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#BDBEC7',
      borderBottomRightRadius: 5,
      fontFamily: 'Poppins',
      borderTop: '#5c5f74 1px solid',
      borderLeft: '#5c5f74 1px solid',
      cursor: 'pointer',
      width: '100%',
      '&:hover': {
        backgroundColor: '#BDBEC7BB',
      },
      '@media ( max-width: 1439px)': {
        width: '100%',
        borderBottomLeftRadius: 5,
        borderTop: '#5c5f74 1px solid',
      },
    },
    applicationFilesText: {
      fontSize: '12px',
      // [theme.breakpoints.up(1440)]: {
      //   fontSize: '13px',
      // },
      [theme.breakpoints.up(1920)]: {
        fontSize: '14px',
      },
    },
    applicationFilest: {
      fontSize: 12,
      // lineHeight:'16px',
      // [theme.breakpoints.up(1440)]: {
      //   fontSize: '13px',
      // },
      [theme.breakpoints.up(1920)]: {
        fontSize: 12,
      },
    },
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type NewApplicationProps = PropsFromRedux & {
  classes?: string;
};

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
interface RouteParams {
  id: string;
}

const NewApplication: React.FC<NewApplicationProps> = ({
  sbApplication,
  currentApplicationDetails,
  counter,
  drawerOpen,
  user,
  lastQuestion,
  submitQuestionAnswer,
  getQuestionByGroup,
  setQuestionCounter,
  getApplicationDetails,
  resetSBApplication,
  blocks,
  // questionList,
  questionDetailList,
  setShowHideQuestions,
  getQuestionsbyOutCome,
  outcomeQuestionsByType,
  getApplicationStatus,
  applicationStatus,
  updateApplicationStatus,
  updateApplicationSubStatus,
  showLockApplicationDialog,
  showLockApplicationDialogBox,
  duplicateAction,
  deferApplicationByPOVnt,
  applicationStatusList,
  getAllStatusList,
  getCountryDetails,
  updateCountryProviderChange,
  countryList,
  selectedCountryChangesDetails,
  setCountryProviderDetails,
  getTermsAsPerCountry,
  termsList,
  updateCountryDetailsOfApplication,
  lockBlocks,
  getApplicationRejectionReason,
  rejectionReason,
  updatePreQuestionAnswer,
  deleteCurrentApplication,
  changeApplicationCourse,
  setUpdatedQuestionList,
  applicationLocalAnswer,
  syncQuestionStatus,
  setFilteredQuestion,
  resetSelectedCountryChangeDetails,
  getfilesCount,
  filesAndVericount,
  // questions,
}) => {
  const classes = useStyles();
  const { id, groupId, questionId } = useParams<RouteParams>();
  const history = useHistory();
  const ref = useRef(null);
  const [height1, setHeight] = useState(-1);
  const [width1, setWidth] = useState(-1);
  const [moveQuestion, setMoveQuestion] = useState(undefined);
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSubStatus, setAnchorElSubStatus] = React.useState(null);
  const [reasonWindow, showReasonWindow] = React.useState(false);
  const [selectedStatus, setSelectedReason] = React.useState(false);
  const [preQuestionModel, showPreQuestionModel] = React.useState(false);
  const [updateQuestionIndex, setUpdatedQuestionIndex] = React.useState(-1);
  const [moveToNext, setMoveToNext] = React.useState(false);
  const [preQuestionMoveNext, setPreQuestionMoveNext] = React.useState(false);
  const [preQuestionStepperChange, setPreQuestionStepperChange] = React.useState(false);
  const [hideDeleteInduplicateDialog, setHideDeleteInDuplicateDialog] = React.useState(false);
  const [changeCountryProviderDialog, showChangeCountryProviderDialog] = React.useState(false);

  const [deferredHistoryDetails, setDeferredHistoryDetails] = React.useState(undefined);
  const [deferredHistoryDialog, showDeferredHistoryDialog] = React.useState(false);

  const [basicDetailsForChange, setBasicDetailsForChange] = React.useState(undefined);

  const [basicDetailDialog, showBasicDetailDialog] = React.useState(false);

  const [deferAppDialog, showDeferAppDialog] = React.useState<boolean>(false);
  const [errorDialogMessage, setErrorDialogMessage] = React.useState('');
  const [errorDialog, showErrorDialog] = React.useState(false);
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [isCanDefer, SetDeferredDisplay] = useState<boolena>(true);
  // const questionList = questionDetailList.questionList
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSunStatusClick = (event) => {
    setAnchorElSubStatus(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSubStatus(null);
  };

  useEffect(() => {
    if (groupId != -1 && questionId != -1) {
      setApplicationToSpecificQuestion(id, groupId, questionId);
    } else {
      resetSBApplication();
      const appId = decryptText(id);
      getApplicationDetails(appId);
      getAllStatusList(appId);
      getApplicationStatus();
      getfilesCount(appId);
    }
    return () => {
      //Reset current application
      resetSBApplication();
    };
  }, [id, groupId, questionId]);

  useEffect(() => {
    if (questionDetailList.questionList && questionDetailList.questionList.length > 0) {
      if (moveQuestion) {
        const index = _.findIndex(questionDetailList.questionList, (obj) => obj.id == moveQuestion);

        if (index != -1) {
          setTimeout(() => {
            setQuestionCounter(index);
            setMoveQuestion(undefined);
          }, 2000);
        }
        setMoveQuestion(undefined);
      }

      if (questionDetailList.statusUpdate && questionDetailList.statusUpdate.length > 0) {
        syncQuestionStatus(questionDetailList.statusUpdate);
      }
      setFilteredQuestion(questionDetailList.questionList);
    }
  }, [questionDetailList]);

  useEffect(() => {
    SetDeferredDisplay(isDeferredDisplay());
  }, [currentApplicationDetails]);

  const setApplicationToSpecificQuestion = async (appId, groupId, questionId) => {
    resetSBApplication();
    if (questionId != -1) {
      const directQuestionId = decryptText(questionId);
      setMoveQuestion(directQuestionId);
    }
    const directAppId = decryptText(appId);
    const directGroupId = decryptText(groupId);
    await getApplicationDetails(directAppId, directGroupId);
    await getAllStatusList(directAppId);
  };

  const updateCurrentSlide = (index: any) => {
    // if (slide !== index) {
    //    setCurrentSlide(index)
    // }
  };

  // console.log("current application :", currentApplicationDetails)
  // console.log("SB application :", sbApplication)

  const showCountryChangeDialog = async (obj: any) => {
    await setCountryProviderDetails(obj.selectedDetails);
    if (countryList && countryList.length > 0) {
      showPreQuestionModel(false);
      showChangeCountryProviderDialog(true);
    } else {
      await getCountryDetails();
      await getTermsAsPerCountry(obj.selectedDetails.country);
      showPreQuestionModel(false);
      showChangeCountryProviderDialog(true);
    }
  };

  const showBasicDetailChangeDialog = async (obj: any) => {
    showPreQuestionModel(false);
    setBasicDetailsForChange(obj);
    showBasicDetailDialog(true);
  };

  const openFilesTab = () => {
    const filesURL = `/files/${id}/${sbApplication.activeGroup}`;
    window.open(filesURL, '_blank');
  };
  const openVarificationTab = () => {
    const filesURL = `/inbox/${id}`;
    window.open(filesURL, '_blank');
  };

  useEffect(() => {
    if (updateQuestionIndex != -1 && !preQuestionStepperChange) {
      const updatedIndex =
        updateQuestionIndex >= questionDetailList.questionList.length
          ? questionDetailList.questionList.length - 1
          : updateQuestionIndex;
      setQuestionCounter(updatedIndex);
      setUpdatedQuestionIndex(-1);
    } else if (
      preQuestionStepperChange &&
      sbApplication.activeGroup === 'PreQuestions' &&
      !sbApplication.initialStateCompleted
    ) {
      const updatedIndex =
        updateQuestionIndex >= questionDetailList.questionList.length
          ? questionDetailList.questionList.length - 1
          : updateQuestionIndex;
      setQuestionCounter(updatedIndex);
      setUpdatedQuestionIndex(-1);
      setPreQuestionStepperChange(false);
    }
  }, [questionDetailList.questionList, updateQuestionIndex]);

  useEffect(() => {
    if (
      preQuestionMoveNext &&
      questionDetailList.questionList &&
      counter < questionDetailList.questionList.length - 1 &&
      sbApplication.activeGroup === 'PreQuestions' &&
      !sbApplication.initialStateCompleted
    ) {
      setQuestionCounter(counter + 1);
      setPreQuestionMoveNext(false);
    }
  }, [preQuestionMoveNext]);

  useEffect(() => {
    if (moveToNext) {
      // @ts-ignore
      if (counter < questionDetailList.questionList.length - 1) {
        setQuestionCounter(counter + 1);
      } else {
        //console.log("Move to next Block");
        if (sbApplication.activeGroupIndex < blocks.length - 1) {
          getQuestionByGroup(blocks[sbApplication.activeGroupIndex + 1].id, true);
        } else {
          // console.log("Block Completed");
        }
      }
      setMoveToNext(false);
    }
  }, [moveToNext]);

  const onStepperClick = async (index: number) => {
    if (!_.isEmpty(applicationLocalAnswer)) {
      submitQuestionAnswer();
      if (sbApplication.activeGroup === 'PreQuestions' && !sbApplication.initialStateCompleted) {
        setPreQuestionStepperChange(true);
      }
      setUpdatedQuestionIndex(index);
    } else {
      setQuestionCounter(index);
    }
  };

  const onNextClick = async (event: any) => {
    // event.preventDefault();

    if (sbApplication.activeGroup === 'PreQuestions' && !sbApplication.initialStateCompleted) {
      if (!_.isEmpty(applicationLocalAnswer)) {
        submitQuestionAnswer();
      }
      setPreQuestionMoveNext(true);
      // @ts-ignore
    } else {
      if (!_.isEmpty(applicationLocalAnswer)) {
        submitQuestionAnswer();
        setMoveToNext(true);
      } else {
        // @ts-ignore
        if (counter < questionDetailList.questionList.length - 1) {
          setQuestionCounter(counter + 1);
        } else {
          //console.log("Move to next Block");
          if (sbApplication.activeGroupIndex < blocks.length - 1) {
            getQuestionByGroup(blocks[sbApplication.activeGroupIndex + 1].id, true);
          } else {
            console.log('Block Completed');
          }
        }
      }
    }
  };

  const onPreviousClick = (event: any) => {
    event.preventDefault();
    if (counter > 0) {
      setQuestionCounter(counter - 1);
    } else {
      if (sbApplication.activeGroup != 'PreQuestions') {
        if (sbApplication.activeGroupIndex > 0) {
          getQuestionByGroup(blocks[sbApplication.activeGroupIndex - 1].id, true);
        }
      }
    }
    if (!_.isEmpty(applicationLocalAnswer)) {
      submitQuestionAnswer();
    }
  };

  const onBlockClick = async (block: any) => {
    //console.log("block:", block);
    if (sbApplication.activeGroup == block.name) {
      return;
    }
    if (!_.isEmpty(applicationLocalAnswer)) {
      submitQuestionAnswer(questionDetailList.questionList);
    }
    await getQuestionByGroup(block.id, true);
  };

  // @ts-ignore-start
  const getCards = () => {
    //console.log('questionDetailList', questionDetailList);
    const itemList = [];
    // @ts-ignore
    // console.log("questionList:", questionList);
    for (let i = 0; i < questionDetailList.questionList.length; i++) {
      if (
        questionDetailList.questionList[i].questionList &&
        questionDetailList.questionList[i].questionList.length > 0
      ) {
        questionDetailList.questionList[i].questionList = _.sortBy(questionDetailList.questionList[i].questionList, [
          function (o) {
            return o.questionOrder;
          },
        ]);
      }

      itemList.push(
        <MainCard height={height1} width={width1} parentDataObject={questionDetailList.questionList[i]} index={i + 1} />
      );
    }
    return itemList;
  };
  // @ts-ignore-end

  // console.log("*** sbApplicatin", sbApplication);
  // console.log("*** currentApplicationDetails", currentApplicationDetails);
  // console.log("*** questions >>>>>", questionList)

  const getStudentName = () => {
    let studentName = '';
    if (sbApplication['PreQuestions']) {
      const parentQuestion = _.find(
        sbApplication['PreQuestions'].questions,
        (questionObj: any) =>
          questionObj.question == "Select applicant's title" || questionObj.question == 'Select your title'
      );

      if (parentQuestion) {
        const nameList = _.filter(
          parentQuestion.questionList,
          (questionObj: any) => questionObj.key == 'Q_FIRSTNAME' || questionObj.key == 'Q_LASTNAME'
        );

        const firstNameObj = _.find(nameList, (questionObj: any) => questionObj.key == 'Q_FIRSTNAME');
        const lastNameObj = _.find(nameList, (questionObj: any) => questionObj.key == 'Q_LASTNAME');

        if (firstNameObj && firstNameObj.answer && firstNameObj.answer.answer) {
          studentName = firstNameObj.answer.answer;
        }
        if (lastNameObj && lastNameObj.answer && lastNameObj.answer.answer) {
          if (studentName) {
            studentName = studentName + ' ' + lastNameObj.answer.answer;
          } else {
            studentName = lastNameObj.answer.answer;
          }
        }
      } else {
        const studentNameQuestion = _.find(
          sbApplication['PreQuestions'].questions,
          (questionObj: any) => questionObj.order == 1
        );

        if (studentNameQuestion && studentNameQuestion.answer && studentNameQuestion.answer.answer) {
          studentName = studentNameQuestion.answer.answer;
        }
      }
    }

    return studentName;
  };

  // const onDropDownChange = () => {
  //   console.log("selected");
  // };
  const getConditionalOfferQuestions = () => {
    //console.log("called",outcomeType);
    getQuestionsbyOutCome(currentApplicationDetails.id, 'Conditional Offer');
  };
  const getUnConditionalOfferQuestions = () => {
    //console.log("called",outcomeType);
    getQuestionsbyOutCome(currentApplicationDetails.id, 'Unconditional Offer');
  };
  const getCOEOfferQuestions = () => {
    //console.log("called",outcomeType);
    getQuestionsbyOutCome(currentApplicationDetails.id, 'COE');
  };

  const getOutcomeQuestion = (type) => {
    getQuestionsbyOutCome(currentApplicationDetails.id, type);
  };

  const handleChange = async (event, reason = null, other = null) => {
    // console.log("Event ::::", event);
    await updateApplicationStatus(currentApplicationDetails.id, event, reason, other);
    // const appId = decryptText(currentApplicationDetails.id);
    // getApplicationDetails(currentApplicationDetails.id);
  };

  const handleSubStatusChange = async (status) => {
    //console.log('status', status);
    await updateApplicationSubStatus(currentApplicationDetails.id, status);
    // const appId = decryptText(currentApplicationDetails.id);
    // getApplicationDetails(currentApplicationDetails.id);
  };

  const getApplicationAgent = () => {
    if (currentApplicationDetails.assignedByProvider) {
      // if (currentApplicationDetails.assignedByProvider.parentCompanyDetails) {
      //   return currentApplicationDetails.assignedByProvider.parentCompanyDetails
      //     .companyName;
      // }

      if (currentApplicationDetails.assignedByProvider.companyDetails) {
        return currentApplicationDetails.assignedByProvider.companyDetails.companyName;
      } else {
        if (currentApplicationDetails.assignedByProvider.companyDetails) {
          return currentApplicationDetails.assignedByProvider.companyDetails.name;
        } else {
          return currentApplicationDetails.assignedByProvider.firstName &&
            currentApplicationDetails.assignedByProvider.lastName
            ? currentApplicationDetails.assignedByProvider.firstName +
                ' ' +
                currentApplicationDetails.assignedByProvider.lastName
            : currentApplicationDetails.assignedByProvider.name;
        }
      }
    } else {
      if (currentApplicationDetails.createdUser.parentCompanyDetails) {
        return currentApplicationDetails.createdUser.parentCompanyDetails.companyName;
      }

      if (currentApplicationDetails.createdUser.companyDetails) {
        return currentApplicationDetails.createdUser.companyDetails.companyName;
      } else {
        return currentApplicationDetails.createdUser.name;
      }
    }
  };

  const getCPHCode = () => {
    //console.log('currentApplicationDetails >>>', currentApplicationDetails);
    if (currentApplicationDetails.assignedByProvider) {
      if (currentApplicationDetails.assignedByProvider.companyDetails) {
        return getDestinationCountryAndCPH(currentApplicationDetails.assignedByProvider);
      } else if (currentApplicationDetails.assignedByProvider.cphCode) {
        return `${currentApplicationDetails.assignedByProvider.name}(${currentApplicationDetails.assignedByProvider.cphCode})`;
      } else {
        return '';
      }
    } else {
      if (currentApplicationDetails.createdUser.companyDetails) {
        return getDestinationCountryAndCPH(currentApplicationDetails.createdUser);
      } else if (currentApplicationDetails.createdUser.cphCode) {
        return `${currentApplicationDetails.createdUser.name}(${currentApplicationDetails.createdUser.cphCode})`;
      } else {
        return '';
      }
    }
  };

  const getDestinationCountryAndCPH = (userDetails: any) => {
    if (currentApplicationDetails?.keyAnswers) {
      const destCountry = _.find(currentApplicationDetails?.keyAnswers, (answer: any) => answer.key == 'Q_DESTCOUNTRY');
      if (destCountry) {
        if (userDetails?.companyDetails?.userCph && userDetails?.companyDetails?.userCph.length > 0) {
          const cphDetails = _.find(userDetails.companyDetails.userCph, (cphObj: any) =>
            _.find(cphObj.details.country, (obj: any) => obj == destCountry.value)
          );
          if (cphDetails) {
            return `${cphDetails.details.name}(${cphDetails.details.cphCode})`;
          }
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const duplicateApplicationActions = async (action: string) => {
    showLockApplicationDialogBox(false);
    await duplicateAction(action);
  };

  const updateApplicationCountry = async () => {
    showChangeCountryProviderDialog(false);

    const result = await updateCountryDetailsOfApplication(selectedCountryChangesDetails, currentApplicationDetails.id);

    if (!result.success && result.message) {
      setHideDeleteInDuplicateDialog(true);
      showLockApplicationDialogBox(true);
      // setErrorDialogMessage(result.message);
      // showErrorDialog(true);
    }
    resetSelectedCountryChangeDetails();
  };

  const updateBasicPrequestionsAnswers = async (obj: any) => {
    await updatePreQuestionAnswer(obj, currentApplicationDetails.id);
  };

  const onLockUnlockClick = async (block: any) => {
    // console.log("Lock unlock Block", block)
    const body = {
      groupId: block.id,
      lock: !block.isLocked,
      lockType: 'All',
      applicationId: currentApplicationDetails.id,
    };
    lockBlocks(body, currentApplicationDetails.id);
  };

  const hasPerm = (perm: PERMISSION[]) => {
    // console.log("userPermissions", userPermissions);
    return hasOneOfPermissions(perm, userPermissions);
  };

  const handleRejectionReasons = async (status) => {
    const result = await getApplicationRejectionReason('Australia', status.code);
    setSelectedReason(status.code);
    showReasonWindow(true);
  };

  const getInstituteName = () => {
    let instituteName = null;
    const institute = _.find(currentApplicationDetails.keyAnswers, (obj: any) => obj.key == 'Q_PROVIDER');
    if (institute) {
      instituteName = institute.value;
    }
    return instituteName;
  };

  const getIntake = () => {
    let intake = null;
    const isPO_VNT = hasPerm([
      PERMISSION.applications_provider_view_org,
      PERMISSION.applications_provider_view_own,
      PERMISSION.applications_provider_view_global,
      PERMISSION.applications_provider_view_sb_org,
      PERMISSION.applications_provider_view_sb_own,
    ]);
    const intakeobject = _.find(currentApplicationDetails.keyAnswers, (obj: any) => obj.key == 'Q_INTAKE');
    if (intakeobject) {
      intake = intakeobject.value;
    }
    if (isPO_VNT && !isCanDefer) {
      intake = 'Deferment In-Progress';
    }
    return intake;
  };

  const onDeferredApplication = async (
    deferredTo: string,
    status: string,
    comments: string,
    date: string,
    applicationId: number
  ) => {
    const body = {
      deferredTo,
      status,
      comments,
      applicationId,
      date,
    };

    await deferApplicationByPOVnt(body);
  };

  const isDeferredDisplay = () => {
    if (currentApplicationDetails) {
      if (!currentApplicationDetails.deferred || currentApplicationDetails.deferred.length == 0) {
        return true;
      } else {
        let deferredList = _.cloneDeep(currentApplicationDetails.deferred);
        deferredList = _.sortBy(deferredList, function (dateObj) {
          return new Date(dateObj.createdAt);
        }).reverse();
        if (!deferredList[0].approvedStatus) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };

  const showDeferredApplicationHistory = (deferredDetails: any) => {
    // const body: any = { application, deferredDetails };
    setDeferredHistoryDetails(deferredDetails);
    showDeferredHistoryDialog(true);
  };

  const onDeleteDuplicateApplication = async () => {
    showLockApplicationDialogBox(false);
    const deleteApplicationResult = await deleteCurrentApplication(currentApplicationDetails.id);
    if (deleteApplicationResult.success) {
      if (!hasPerm([PERMISSION.applications_agent_view_org]) && !hasPerm([PERMISSION.applications_agent_view_own])) {
        history.replace(PAGE_ROUTE.APP_LIST_PROVIDER);
      } else {
        history.replace(PAGE_ROUTE.APP_LIST_AGENCY);
      }
    }
  };

  const onChangeCourseDialog = async () => {
    if (hideDeleteInduplicateDialog) {
      showChangeCountryProviderDialog(true);
      showLockApplicationDialogBox(false);
      setHideDeleteInDuplicateDialog(false);
    } else {
      showLockApplicationDialogBox(false);
      const applicationId = currentApplicationDetails?.id;
      const groupId = sbApplication?.PreQuestions?.id;
      let questionId = undefined;
      if (applicationId && groupId) {
        const question = _.find(sbApplication.PreQuestions.questions, (obj: any) => obj.key == 'Q_COURSE');
        questionId = question.id;
      }
      const body = {
        applicationId,
        groupId,
        questionId,
      };
      const result = await changeApplicationCourse(body);
    }
  };

  //C2B78A
  if (sbApplication.activeGroup && currentApplicationDetails) {
    const APPLICATION_STATUS = applicationStatusList ? applicationStatusList : { status: [], subStatusList: [] };
    const CPH_CODE = getCPHCode();

    const getSubStatusLabelByCode = (code) => {
      const labelObj = _.find(APPLICATION_STATUS.subStatusList, (obj: any) => obj.code == code);
      if (labelObj) {
        return labelObj.name;
      } else {
        return '';
      }
    };

    const getStatusLabelByCode = (code) => {
      const labelObj = _.find(APPLICATION_STATUS.status, (obj: any) => obj.code == code);
      if (labelObj) {
        return labelObj.name;
      } else {
        return '';
      }
    };

    // @ts-ignore
    return (
      <Page className={classes.root} title="Application">
        <div className={classes.leftPanel}>
          <div className={classes.applicationInfo}>
            {sbApplication ? (
              <>
                <div className={classes.applicationDetailsParent}>
                  <SBText text={getStudentName()} className={classes.studentName} />
                  <div className={classes.overlayApplicationInfo}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <SBText text={'ID: '} className={classes.applicationInfoText} style={{ fontWeight: 700 }} />
                      <SBText
                        text={getApplicationIdInString(currentApplicationDetails.id)}
                        className={classes.applicationInfoText}
                      />

                      {currentApplicationDetails.status.status != 'Draft' ? (
                        <IconButton style={{ marginLeft: -16 }} onClick={() => showPreQuestionModel(true)}>
                          <RestorePageIcon fontSize={'small'} />
                        </IconButton>
                      ) : null}
                    </div>

                    {currentApplicationDetails.status.status != 'Draft' &&
                    APPLICATION_STATUS.status &&
                    APPLICATION_STATUS.status.length > 0 &&
                    hasPerm([PERMISSION.update_application_status]) ? (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <SBText
                          text={`Status:  `}
                          className={classes.applicationInfoText}
                          style={{ fontWeight: 700 }}
                        />

                        <div
                          style={{
                            display: 'flex',
                          }}
                          onClick={handleClick}
                        >
                          <SBText
                            text={getStatusLabelByCode(currentApplicationDetails.status.status)}
                            className={classes.applicationInfoValue}
                          />
                          <ExpandMoreIcon />
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          marginTop: 5,
                          flexDirection: 'column',
                        }}
                      >
                        <SBText text={'Status: '} className={classes.applicationInfoText} style={{ fontWeight: 700 }} />

                        <SBText
                          text={currentApplicationDetails.status.status}
                          className={classes.applicationInfoText}
                          // style={{  marginLeft: 5 }}
                        />
                      </div>
                    )}
                    {(user && !hasPerm([PERMISSION.update_application_subStatus])) ||
                    currentApplicationDetails.status.status == 'Draft' ? (
                      <>
                        <SBText
                          text={'Sub Status: '}
                          className={classes.applicationInfoText}
                          style={{ fontWeight: 700 }}
                        />

                        <SBText
                          text={
                            currentApplicationDetails.status.subStatus
                              ? getSubStatusLabelByCode(currentApplicationDetails.status.subStatus)
                              : '-'
                          }
                          className={classes.applicationInfoValue}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: 5,
                        }}
                      >
                        <SBText
                          text={`Sub Status:  `}
                          className={classes.applicationInfoText}
                          style={{ fontWeight: 700 }}
                        />

                        <div
                          style={{
                            display: 'flex',
                          }}
                          onClick={handleSunStatusClick}
                        >
                          <SBText
                            text={
                              currentApplicationDetails.status.subStatus
                                ? getSubStatusLabelByCode(currentApplicationDetails.status.subStatus)
                                : '-'
                            }
                            className={classes.applicationInfoValue}
                          />
                          <ExpandMoreIcon />
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <SBText
                        text={`Institution:  `}
                        className={classes.applicationInfoText}
                        style={{ fontWeight: 700 }}
                      />

                      <SBText
                        text={getInstituteName()}
                        className={classes.applicationInfoText}
                        // style={{  marginLeft: 5 }}
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <SBText text={`Intake:  `} className={classes.applicationInfoText} style={{ fontWeight: 700 }} />
                      {hasPerm([
                        PERMISSION.applications_provider_view_org,
                        PERMISSION.applications_provider_view_own,
                        PERMISSION.applications_provider_view_global,
                        PERMISSION.applications_provider_view_sb_org,
                        PERMISSION.applications_provider_view_sb_own,
                      ]) ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {currentApplicationDetails &&
                          currentApplicationDetails.deferred &&
                          currentApplicationDetails.deferred.length > 0 ? (
                            <>
                              <div
                                className={classes.deferredDParent}
                                style={{ backgroundColor: '#8bb19c', cursor: 'pointer' }}
                                onClick={() => showDeferredApplicationHistory(currentApplicationDetails)}
                              >
                                <SBText text={'D'} className={classes.dText} />
                              </div>
                              <div style={{ width: 5 }}></div>
                            </>
                          ) : null}
                          <Button
                            variant="contained"
                            color="primary"
                            className={isCanDefer ? classes.buttonStyle : classes.buttonDisabledStyle}
                            disabled={!isCanDefer}
                            style={{ width: '100%' }}
                            onClick={() => (isCanDefer ? showDeferAppDialog(true) : null)}
                          >
                            {getIntake()}
                          </Button>
                        </div>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.buttonDisabledStyle}
                          disabled={true}
                          onClick={() => null}
                        >
                          {getIntake()}
                        </Button>
                      )}
                    </div>

                    {currentApplicationDetails.assignedUser ? (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <SBText
                          text={'Admission Officer: '}
                          className={classes.applicationInfoText}
                          style={{ fontWeight: 700, marginTop: 5 }}
                        />
                        <SBText
                          text={currentApplicationDetails.assignedUser.name}
                          className={classes.applicationInfoText}
                        />
                      </div>
                    ) : null}

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <SBText
                        text={currentApplicationDetails.leadId ? 'TA: ' : 'Agent: '}
                        className={classes.applicationInfoText}
                        style={{ fontWeight: 700, marginTop: 5 }}
                      />
                      <SBText
                        text={getApplicationAgent()}
                        className={classes.applicationInfoText}
                        style={{ marginTop: 5 }}
                      />
                    </div>

                    {CPH_CODE ? (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <SBText
                          text={'CPH'}
                          className={classes.applicationInfoText}
                          style={{ fontWeight: 700, marginTop: 5 }}
                        />
                        <SBText text={CPH_CODE} className={classes.applicationInfoText} style={{ marginTop: 5 }} />
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {hasPermission(PERMISSION.dms, userPermissions) && currentApplicationDetails.status.status != 'Draft' ? (
            <div className={classes.viewFilesContainer}>
              <SBTooltip
                title={
                  filesAndVericount.fileCount > 0 ? `${filesAndVericount.fileCount} missing files` : `No missing files`
                }
              >
                <div className={classes.viewFilesBtn} onClick={openFilesTab}>
                  <FileCopyOutlinedIcon style={{ marginRight: 5, fontSize: 20 }} />
                  <SBText text={'Files'} className={classes.applicationFilesText} />
                  {filesAndVericount.fileCount && filesAndVericount.fileCount > 0 ? (
                    <span className={classes.fileCount}>
                      <SBText
                        text={
                          filesAndVericount.fileCount > 9
                            ? filesAndVericount.fileCount > 99
                              ? '99+'
                              : filesAndVericount.fileCount
                            : '0' + filesAndVericount.fileCount
                        }
                        className={classes.applicationFilest}
                      />
                    </span>
                  ) : null}
                </div>
              </SBTooltip>
              <SBTooltip
                title={
                  currentApplicationDetails.pendingVerifications && currentApplicationDetails.pendingVerifications > 0
                    ? `${currentApplicationDetails.pendingVerifications} Verifications Pending`
                    : `No verifications pending`
                }
              >
                <div className={classes.verifyBtn} onClick={openVarificationTab}>
                  <VerifiedUserOutlinedIcon style={{ marginRight: 5, fontSize: 20 }} />
                  <SBText text={'Verifications'} className={classes.applicationFilesText} />
                  {currentApplicationDetails.pendingVerifications &&
                  currentApplicationDetails.pendingVerifications > 0 ? (
                    <span className={classes.fileCount}>
                      <SBText
                        text={
                          currentApplicationDetails.pendingVerifications > 9
                            ? currentApplicationDetails.pendingVerifications > 99
                              ? '99+'
                              : currentApplicationDetails.pendingVerifications
                            : '0' + currentApplicationDetails.pendingVerifications
                        }
                        className={classes.applicationFilest}
                      />
                    </span>
                  ) : null}
                </div>
              </SBTooltip>
            </div>
          ) : (
            <div style={{ height: 1 }}></div>
          )}

          <LeftBlockPanel
            onBlockClick={(block: any) => onBlockClick(block)}
            onLockUnlockClick={(block: any) => onLockUnlockClick(block)}
          />
          <DuplicateApplicationDialog
            open={showLockApplicationDialog}
            onDeleteApplication={() => onDeleteDuplicateApplication()}
            onChangeCourseApplication={() => onChangeCourseDialog()}
            questions={sbApplication && sbApplication.PreQuestions ? sbApplication.PreQuestions.questions : []}
            hideDelete={hideDeleteInduplicateDialog}
          />
          <CommenErrorDialog
            open={errorDialog}
            onSubmit={() => showErrorDialog(false)}
            message={errorDialogMessage}
            header={'Error'}
          />

          {preQuestionModel ? (
            <PreQuestionDetailsModel
              open={preQuestionModel}
              onCancel={() => showPreQuestionModel(false)}
              onChange={(details: any) => showCountryChangeDialog(details)}
              onChangeOther={(details: any) => showBasicDetailChangeDialog(details)}
              data={sbApplication && sbApplication.PreQuestions ? sbApplication.PreQuestions : null}
              isChangeAvailable={hasPerm([
                PERMISSION.applications_provider_view_org,
                PERMISSION.applications_provider_view_own,
                PERMISSION.applications_provider_view_global,
              ])}
              application={currentApplicationDetails}
            />
          ) : null}

          <ChangeCountryDialog
            open={changeCountryProviderDialog}
            onSubmit={() => updateApplicationCountry()}
            onClose={() => {
              showChangeCountryProviderDialog(false);
              resetSelectedCountryChangeDetails();
            }}
            currentApplicationDetails={null}
            countryList={countryList}
            dataObj={selectedCountryChangesDetails}
            termsList={termsList}
            onValueChange={(key: string, value: string) => {
              if (key == 'country') {
                getTermsAsPerCountry(value);
              }
              updateCountryProviderChange(key, value);
            }}
          />

          <ChangeBasicDetailDialog
            open={basicDetailDialog}
            onSubmit={(obj: any) => updateBasicPrequestionsAnswers(obj)}
            onClose={() => {
              //console.log('Check Close');
              showBasicDetailDialog(false);
            }}
            data={basicDetailsForChange}
          />
        </div>

        <div className={classes.middlePanel}>
          <div className={classes.middleTopPanel}>
            <StatusGraph
              initialState={sbApplication.initialStateCompleted}
              outcomes={currentApplicationDetails.outcomes}
              outcomeMaster={currentApplicationDetails.outcomesMasterList}
              createdAt={currentApplicationDetails.createdAt}
              appId={currentApplicationDetails.id}
              getConditionalOfferQuestions={getConditionalOfferQuestions}
              getUnConditionalOfferQuestions={getUnConditionalOfferQuestions}
              getCOEOfferQuestions={getCOEOfferQuestions}
              getOutcomeQuestion={getOutcomeQuestion}
              outcomeQuestionsByType={outcomeQuestionsByType}
            />
            <div className={classes.stepperContainer}>
              <div className={classes.nextBackBtParent}>
                <div className={classes.nextBackButton} onMouseDown={onPreviousClick}>
                  <img src={backArrow} style={{ marginRight: 5 }} />
                </div>
              </div>
              <ProgressStepper
                dataObject={questionDetailList.questionList}
                currentIndex={counter + 1}
                onStepperClick={(index: number) => onStepperClick(index)}
                applicationLocalAnswerd={applicationLocalAnswer}
              />
              <div className={classes.nextBackBtParent}>
                <div className={classes.nextBackButton} onMouseDown={onNextClick}>
                  <img src={nextArrow} style={{ marginLeft: 5 }} />
                </div>
              </div>
            </div>
          </div>

          <Carousel
            showIndicators={false}
            showArrows={false}
            selectedItem={counter}
            onChange={updateCurrentSlide}
            className={classes.ca}
            labels={{ item: '' }}
            showStatus={false}
          >
            {getCards()}
          </Carousel>

          {currentApplicationDetails.lockStatus ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255,255,255,0.5)',
                position: 'absolute',
                display: 'flex',
                top: 0,
                bottom: 0,
                left: 0,
              }}
            ></div>
          ) : null}
        </div>

        <div className={drawerOpen ? classes.rightDOpenPanel : classes.rightPanel}>
          <RightBlockPanel questionList={questionDetailList.questionList} />
        </div>

        {APPLICATION_STATUS && APPLICATION_STATUS.status ? (
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {APPLICATION_STATUS.status.map((status) => (
              <MenuItem
                onClick={() => {
                  handleClose();
                  //console.log('Status ', status);
                  if (
                    status.code == 'Rejected' ||
                    status.code == 'Closed' ||
                    status.code == 'Withdrawn' ||
                    status.code == 'Withheld'
                  ) {
                    handleRejectionReasons(status);
                  } else {
                    handleChange(status.code);
                  }
                  //
                }}
                key={`option-${status.code}`}
                value={`${status.code}`}
                selected={status.code === currentApplicationDetails.status.status ? 'selected' : ''}
              >
                {status.name}
              </MenuItem>
            ))}
          </Menu>
        ) : null}

        {APPLICATION_STATUS && APPLICATION_STATUS.status ? (
          <Menu
            id="simple-menu"
            anchorEl={anchorElSubStatus}
            keepMounted
            open={Boolean(anchorElSubStatus)}
            onClose={handleClose}
          >
            {APPLICATION_STATUS.subStatusList.map((status, index) => (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleSubStatusChange(status.code);
                }}
                key={`option-${status.name}`}
                value={`${status.name}`}
                selected={
                  status.code == currentApplicationDetails.status.subStatus
                  // status.name === currentApplicationDetails.status.subStatus
                }
              >
                {status.name}
              </MenuItem>
            ))}
          </Menu>
        ) : null}

        <ApplicationStatusReasonModel
          open={reasonWindow}
          onClose={() => showReasonWindow(false)}
          onSetReason={(text, other) => {
            //console.log(text);
            showReasonWindow(false);
            handleChange(selectedStatus, text, other);
          }}
          reason={'Reason'}
          data={rejectionReason ? rejectionReason['Australia'] : []}
        />

        {deferAppDialog ? (
          <DeferedApplicationDialog
            open={deferAppDialog}
            onSubmit={(deferredTo: string, reason: string, remarks: string, date: string, applicationId: number) =>
              onDeferredApplication(deferredTo, reason, remarks, date, applicationId)
            }
            onClose={() => showDeferAppDialog(false)}
            sbApplication={null}
            currentApplicationDetails={currentApplicationDetails}
          />
        ) : null}

        {deferredHistoryDialog ? (
          <SBDefermentDetailsDialog
            open={deferredHistoryDialog}
            onClose={() => {
              showDeferredHistoryDialog(false);
              setDeferredHistoryDetails(undefined);
            }}
            deferredDetailApplication={deferredHistoryDetails}
          />
        ) : null}
      </Page>
    );
  } else {
    return null;
  }
};

const mapState = (state: RootState) => ({
  sbApplication: state.sbApplication.sbApplication,
  currentApplicationDetails: state.sbApplication.currentApplicationDetails,
  lastQuestion: state.sbApplication.lastQuestion,
  drawerOpen: state.application.drawerOpen,
  counter: state.sbApplication.counter,
  user: state.user.user,
  blocks: state.sbApplication.blocks,
  questionDetailList: getFormattedQuestion(state),
  outcomeQuestionsByType: state.sbApplication.outcomeQuestionsByType,
  applicationStatus: state.sbApplication.applicationStatus,
  showLockApplicationDialog: state.sbApplication.showLockApplicationDialog,
  applicationStatusList: state.sbApplication.applicationStatusList,
  countryList: state.sbApplication.countryList,
  selectedCountryChangesDetails: state.sbApplication.selectedCountryChangesDetails,
  termsList: state.sbApplication.termsList,
  rejectionReason: state.sbApplication.rejectionReason,
  applicationLocalAnswer: state.sbApplication.applicationLocalAnswer,
  filesAndVericount: state.sbApplication.filesAndVericount,
});
const mapDispatch = {
  submitQuestionAnswer,
  getQuestionByGroup,
  setQuestionCounter,
  getApplicationDetails,
  resetSBApplication,
  setShowHideQuestions,
  getQuestionsbyOutCome,
  getApplicationStatus,
  updateApplicationStatus,
  updateApplicationSubStatus,
  showLockApplicationDialogBox,
  duplicateAction,
  deferApplicationByPOVnt,
  getAllStatusList,
  getCountryDetails,
  updateCountryProviderChange,
  setCountryProviderDetails,
  getTermsAsPerCountry,
  updateCountryDetailsOfApplication,
  lockBlocks,
  getApplicationRejectionReason,
  updatePreQuestionAnswer,
  deleteCurrentApplication,
  changeApplicationCourse,
  syncGroupStatus,
  syncQuestionStatus,
  setUpdatedQuestionList,
  setQuestionStatusUpdate,
  setFilteredQuestion,
  resetSelectedCountryChangeDetails,
  getfilesCount,
};

const connector = connect(mapState, mapDispatch);
export default connector(NewApplication);
