import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import applicationReducer from './aplication';
import bulkUploadReducer from './bulkUpload';
import sbApplicationReducer from './sbApplication';
import inboxReducers from './inbox';
import userReducers from './user';
import dashboardReducer from './dashboard';
import rolemanagmentReducer from './rolemanagement';
import entitiesReducer from './entities';
import providerReducer from './providers';
import usersReducer from './users';
import fileViewerReducer from './fileViewer';
import lpcReducer from './lpc';
import agentReducer from '../views/Agent/store/reducer';
import refreeReducer from '../views/Agent/store/reducer/refreeDetails';
import RefereeApproveDisapproveReducer from '../views/Agent/store/reducer/refereeApproveDisapprove';
import sendContractReducer from '../views/Agent/store/reducer/sendContract';
import onboardAgentReducer from '../views/Agent/store/reducer/onboardAgent';
import agentContractDetailsReducer from '../views/Agent/store/reducer/agentContractDetails';
import agentContractAttachedFileReducer from '../views/Agent/store/reducer/agentContractAttachedFile';
import agentApplicationFormFileReducer from '../views/Agent/store/reducer/agentApplicationFormAttachedFile';
import refreeCheckAttachFileReducer from '../views/Agent/store/reducer/refreeCheckAttachedFile';
import downloadSupportingDocumentsReducer from '../views/Agent/store/reducer/supportingDocuments';
import downloadSubmittedDocumentsReducer from '../views/Agent/store/reducer/submittedDocuments';
import lpoReducer from '../views/LPO/store/reducer';
import leadsReducer from '../views/Leads/store/reducer';
import legacyAgentReducer from '../views/Agent/store/reducer/legacyAgentData';
import onboardRequestAgentReducer from '../views/Agent/store/reducer/onboardRequestAgentReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  application: applicationReducer,
  bulkUpload: bulkUploadReducer,
  sbApplication: sbApplicationReducer,
  user: userReducers,
  inbox: inboxReducers,
  dashboard: dashboardReducer,
  roles: rolemanagmentReducer,
  entities: entitiesReducer,
  providers: providerReducer,
  users: usersReducer,
  lpc: lpcReducer,
  agent: agentReducer,
  lpo: lpoReducer,
  leads: leadsReducer,
  refreeReducer,
  RefereeApproveDisapproveReducer,
  sendContractReducer,
  onboardAgentReducer,
  agentContractDetailsReducer,
  agentContractAttachedFileReducer,
  agentApplicationFormFileReducer,
  refreeCheckAttachFileReducer,
  downloadSupportingDocumentsReducer,
  downloadSubmittedDocumentsReducer,
  legacyAgentReducer,
  onboardRequestAgentReducer,
  fileViewerReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
