import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AppColor from "../../theme/AppColor";
import SBText from "../../components/base/SBText";
import { List } from "@material-ui/core";
import moment from "moment";
import Button from "@material-ui/core/Button";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { getApplicationIdInString } from "../../util";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "40%",
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
    minWidth: 700,
    minHeight: 300,
    maxHeight: 600,
  },
  root: {
    width: "100%",
    paddingLeft: 0,
    overflowY: "scroll",
    // backgroundColor: theme.palette.background.paper,
  },
  ul: {
    padding: 0,
  },
}));

type FileListModelProps = {
  className?: string;
  open: any;
  onClose: () => void;
  onDownloadFile: (obj: any, id:any) => void;
  onDownloadAll: (type: any) => void;
  data: any;
  applicationId: any;
  onDownloadHistoryFile: (obj: any) => void;
};

const FileListModel: React.FC<FileListModelProps> = ({
  children,
  onClose,
  className,
  onDownloadFile,
  open,
  onDownloadAll,
  data,
  applicationId,
  onDownloadHistoryFile,
  ...rest
}) => {
  const classes = useStyles();
  const [expand, setExpand] = useState([]);

  const handleClose = () => {
    // setOpen(false);
  };

  const getFileName = (fileName: string) => {
    // let filename = dataObject.answer.fileName;
    if (fileName.includes("#")) {
      fileName = fileName.split("#")[1];
    }
    return fileName;
  };

  useEffect(() => {
    // console.log("Use Effect in Model", data);
    const dataArray: any = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        dataArray.push(false);
      }
      setExpand(dataArray);
    }
  }, []);

  const onCloseModel = () => {
    onClose();
    setExpand([]);
  };

  // console.log("data", data);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div
            style={{
              minHeight: 70,
              width: "100%",
              backgroundColor: AppColor.appBlue,
              display: "flex",
              alignItems: "center",
              paddingLeft: 16,
              paddingRight: 16,
              justifyContent: "space-between",
            }}
          >
            <SBText
              text={
                "Application File List-" +
                getApplicationIdInString(applicationId)
              }
              style={{
                fontSize: 20,
                color: AppColor.appWhite,
                fontWeight: 700,
              }}
            />

            <Button
              href="#text-buttons"
              color="primary"
              style={{
                fontSize: 16,
                textTransform: "none",
                textDecorationLine: "underline",
                color: AppColor.appWhite,
              }}
              onClick={onDownloadAll}
            >
              Download All
              <CloudDownloadOutlinedIcon
                style={{ marginLeft: 10, color: AppColor.appWhite }}
              />
            </Button>
          </div>

          {data && data.length > 0 ? (
            <List className={classes.root}>
              {data.map((question: any, index: number) => (
                <ul className={classes.ul}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderBottomWidth: 1,
                      borderBottomStyle: "solid",
                      borderBottomColor: AppColor.appLightGrey,
                    }}
                  >
                    <Accordion
                      defaultExpanded={false}
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        boxShadow: "none",
                      }}
                      expanded={expand[index]}
                      onChange={() => null}
                    >
                      <AccordionSummary
                        aria-controls="panel1c-content"
                        id="panel1c-header"
                        expandIcon={null}
                        style={{
                          width: "100%",
                          padding: 0,
                          boxShadow: "none",
                          minHeight: 80,
                        }}
                        onClick={(event) => event.stopPropagation()}
                      >
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: 10,
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                maxWidth: "70%",
                              }}
                            >
                              <SBText
                                text={question.group + ":" + String(" ")}
                                style={{
                                  fontSize: 15,
                                  lineHeight: 1.2,
                                  fontWeight: 600,
                                }}
                              />
                              <SBText
                                text={question.question}
                                style={{
                                  fontSize: 15,
                                  lineHeight: 1.2,
                                  maxWidth: "70%",
                                  marginLeft: 5,
                                }}
                              />
                            </div>

                            <SBText
                              text={moment(question.answer.updatedAt).format(
                                "DD MMM YYYY HH:mm"
                              )}
                              style={{
                                fontSize: 13,
                                lineHeight: 1.2,
                                maxWidth: "30%",
                                color: AppColor.appLightGrey,
                              }}
                            />
                          </div>

                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <SBText
                              text={getFileName(question.answer.fileName)}
                              style={{
                                fontSize: 13,
                                color: AppColor.appLightGrey,
                                maxWidth: "70%",
                              }}
                            />

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Button
                                href="#text-buttons"
                                color="primary"
                                style={{
                                  fontSize: 13,
                                  textTransform: "none",
                                  textDecorationLine: "underline",
                                }}
                                onClick={() => onDownloadFile(question, applicationId)}
                              >
                                Download
                              </Button>

                              <Button
                                href="#text-buttons"
                                color="primary"
                                style={{
                                  fontSize: 13,
                                  textTransform: "none",
                                  textDecorationLine: "underline",
                                  height: 10,
                                }}
                                disabled={data[index].oldFiles == 0}
                                onClick={() => {
                                  if (data[index].oldFiles.length > 0) {
                                    const tempExpand: any = _.cloneDeep(expand);
                                    tempExpand[index] = !tempExpand[index];
                                    setExpand(tempExpand);
                                  }
                                }}
                              >
                                Version History
                              </Button>
                            </div>
                          </div>

                          {/*<div style={{ width: "100%" }}>*/}
                          {/*  <Button*/}
                          {/*    href="#text-buttons"*/}
                          {/*    color="primary"*/}
                          {/*    style={{*/}
                          {/*      fontSize: 13,*/}
                          {/*      textTransform: "none",*/}
                          {/*      textDecorationLine: "underline",*/}
                          {/*      height: 10,*/}
                          {/*    }}*/}
                          {/*    disabled={data[index].oldFiles == 0}*/}
                          {/*    onClick={() => {*/}
                          {/*      if (data[index].oldFiles.length > 0) {*/}
                          {/*        const tempExpand: any = _.cloneDeep(expand);*/}
                          {/*        tempExpand[index] = !tempExpand[index];*/}
                          {/*        setExpand(tempExpand);*/}
                          {/*      }*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    Version History*/}
                          {/*  </Button>*/}
                          {/*</div>*/}
                        </div>
                      </AccordionSummary>

                      <AccordionDetails style={{ padding: 0 }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#F7F5F5",
                          }}
                        >
                          {data[index].oldFiles.map(
                            (file: any, index1: number) => (
                              <div
                                style={{
                                  height: 50,
                                  width: "100%",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  display: "flex",
                                  borderBottomWidth:
                                    index1 == file.length - 1 ? 0 : 0.3,
                                  borderBottomStyle: "solid",
                                  borderBottomColor:
                                    index1 == file.length - 1
                                      ? AppColor.appWhite
                                      : AppColor.appLightGrey,
                                }}
                              >
                                <SBText
                                  text={getFileName(file.fileName)}
                                  style={{
                                    fontSize: 12,
                                    marginLeft: 10,
                                    width: "33%",
                                  }}
                                />

                                <SBText
                                  text={moment(file.createdAt).format(
                                    "DD MMM YYYY HH:mm"
                                  )}
                                  style={{
                                    fontSize: 12,
                                    color: "#A4A3A6",
                                    width: "33%",
                                    textAlign: "center",
                                  }}
                                />
                                <div
                                  style={{
                                    width: "33%",
                                    display: "flex",
                                    alignItems: "flex-end",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Button
                                    href="#text-buttons"
                                    color="primary"
                                    style={{
                                      fontSize: 13,
                                      textTransform: "none",
                                      textDecorationLine: "underline",
                                      marginRight: 10,
                                    }}
                                    onClick={() => onDownloadHistoryFile(file)}
                                  >
                                    Download
                                  </Button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </ul>
              ))}
            </List>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
};

export default FileListModel;
