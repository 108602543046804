import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Drawer, Button, Divider } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../reducer";
import { updateUserListFilter, clearUserListFilter } from "../../../actions";
import SBButton from "../../../components/base/SBButton";
import CloseIcon from "@material-ui/icons/Close";
import AppColor from "../../../theme/AppColor";
import SBText from "../../../components/base/SBText";
import SBDropdown from "../../../components/base/SBDropdown";
import { LABELS } from "../../../util/labels";
import { ENTITY_TYPE, ENTITY_TYPE_LIST } from "../../../util/constants";
import { UserListFilter } from "../../../reducer/types";

type PropsFromRedux = ConnectedProps<typeof connector>;
type SBApplicationListProps = PropsFromRedux & {
  filterOpen: boolean;
  onClose: Function;
  onApplyFilter: Function;
  onClearFilter: Function;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: { marginTop: theme.spacing(2) },
    drawerRoot: {
      width: 360,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    filterButtonParent: {
      display: "flex",
      flexDirection: "column",
      padding: 20,
      justifyContent: "space-between",
    },
    clearButton: {
      backgroundColor: "white",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: AppColor.appBlue,
      "&:hover": {
        backgroundColor: "#fafafa",
      },
      color: AppColor.appBlue,
    },
    headerText: {
      fontSize: 16,
      fontWeight: 600,
      marginTop: 30,
      marginBottom: 5,
      color: AppColor.appBlue,
    },
    filterButtonText: {
      fontWeight: 400,
      letterSpacing: "0.75px",
      textTransform: "none",
      fontSize: 14,
    },
    sort: {
      // width: 260,
      borderRadius: 5,
      display: "flex",
      height: 45,
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#FCFBFB",
      border: "1px solid #3E4059",
    },
    label: {
      textAlign: "left",
      color: "#736F6F",
      marginTop: theme.spacing(2),
    },
  })
);
const UserFilter: React.FC<SBApplicationListProps> = ({
  filterOpen,
  onClose,
  onApplyFilter,
  onClearFilter,
  filterData,
  agencies,
  providers,
}) => {
  const classes = useStyles();
  const [currentFilter, setCurrentFilter] = React.useState<UserListFilter>(
    filterData
  );

  useEffect(() => {
    setCurrentFilter(filterData);
  }, [filterData]);

  const onEntityTypeChange = (entityType: ENTITY_TYPE) => {
    setCurrentFilter({
      ...currentFilter,
      entityType,
      provider: null,
      companyId: null,
    });
  };

  const onProviderChange = (providerId: number) => {
    setCurrentFilter({
      ...currentFilter,
      provider: providerId || null,
      companyId: null,
    });
  };

  const onAgencyChange = (agencyId: number) => {
    setCurrentFilter({
      ...currentFilter,
      provider: null,
      companyId: agencyId || null,
    });
  };

  const onClearChangeFilter = async () => {
    onClose();
    onClearFilter();
  };

  const onApplyChangeFilter = async () => {
    onApplyFilter(currentFilter);
    onClose();
  };

  const getAgencies = (): any[] => {
    return agencies.map((agency: any) => {
      return { id: agency.id, value: agency.companyName || "-" };
    });
  };

  const getProviders = (): any[] => {
    return providers.map((provider: any) => {
      return { id: provider.id, value: provider.name || "-" };
    });
  };

  let entityDropdown = (
    <SBDropdown
      placeholder={LABELS.SELECT_ENTITY}
      options={[]}
      labelText={LABELS.ENTITY}
      value={""}
    />
  );

  if (currentFilter.entityType === ENTITY_TYPE.AGENCY) {
    entityDropdown = (
      <SBDropdown
        placeholder={LABELS.SELECT_AGENCY}
        options={getAgencies()}
        labelText={LABELS.AGENCY}
        onDataChange={onAgencyChange}
        showNoneOption={true}
        value={currentFilter?.companyId || ""}
      />
    );
  } else if (currentFilter.entityType === ENTITY_TYPE.PROVIDER) {
    entityDropdown = (
      <SBDropdown
        placeholder={LABELS.SELECT_PROVIDER}
        options={getProviders()}
        labelText={LABELS.PROVIDER}
        onDataChange={onProviderChange}
        showNoneOption={true}
        value={currentFilter?.provider || ""}
      />
    );
  } else if (currentFilter.entityType === ENTITY_TYPE.STARTBLOCKS) {
    entityDropdown = <></>;
  }

  return (
    <Drawer anchor={"right"} open={filterOpen} onClose={() => onClose()}>
      <div className={classes.drawerRoot}>
        <div style={{ paddingTop: 20 }}>
          <Button onClick={() => onClose()}>
            <CloseIcon /> Close
          </Button>

          <div style={{ padding: 20 }}>
            <SBText
              text={LABELS.USER_FILTER_ENTITY_GROUP}
              className={classes.headerText}
            />
            <Divider />

            <div style={{ paddingTop: 10 }}>
              <SBDropdown
                placeholder={LABELS.SELECT_ENTITY_TYPE}
                options={ENTITY_TYPE_LIST}
                labelText={LABELS.ENTITY_TYPE}
                onDataChange={onEntityTypeChange}
                value={currentFilter?.entityType || ""}
              />
            </div>

            <div style={{ paddingTop: 10 }}>{entityDropdown}</div>
          </div>
        </div>

        <div className={classes.filterButtonParent}>
          <SBButton
            text={LABELS.APPLY_FILTER}
            icon={false}
            onClick={() => onApplyChangeFilter()}
          />
          <div style={{ height: 20 }}></div>
          <SBButton
            text={LABELS.CLEAR_FILTER}
            icon={false}
            onClick={() => onClearChangeFilter()}
            className={classes.clearButton}
          />
        </div>
      </div>
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  filterData: state.users.userListFilter,
  agencies: state.entities.agencies,
  providers: state.providers.all,
});

const mapDispatch = {
  updateUserListFilter,
  clearUserListFilter,
};

const connector = connect(mapState, mapDispatch);
export default connector(UserFilter);
