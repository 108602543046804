import {
  Button,
  Fade,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { InfoOutlined, OpenInNew, PanoramaFishEyeOutlined } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LockIcon from '@material-ui/icons/Lock';
import MenuIcon from '@material-ui/icons/Menu';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import _, { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import SBText from '../../../components/base/SBText';
import { SBTooltip } from '../../../components/base/SBTooltip';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import AppColor from '../../../theme/AppColor';
import { encryptText, getApplicationIdInString } from '../../../util';
import { LABELS } from '../../../util/labels';
import { hasPermission, PERMISSION } from '../../../util/rolePermissionsUtil';
import SimpleModal from '../FileUploadModal';
import StatusAndEventsDrawer from '../statusAndEventsDrawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paddingHeader: { backgroundColor: 'pink' },
    paddingButtonParent: { backgroundColor: 'pink' },
    paddingButton: {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      flexDirection: 'row',
      borderBottomWidth: 0,
      alignItems: 'center',
      alignSelf: 'center',
    },
    viewBt: {
      height: 35,
      borderRadius: 30,
      width: 80,
      fontSize: '11px',
    },
    greenBt: {
      height: 35,
      borderRadius: 30,
      width: 120,
      backgroundColor: '#8BB19C',
      color: 'white',
      fontSize: '11px',
    },
    outcomeContainer: {
      display: 'flex',
    },
    outcomeButton: {
      display: 'flex',
      justifyContent: 'space-between',
      color: '#D27F65',
      textTransform: 'none',
    },
    outcomeSatisfied: {
      color: '#8BB19C',
    },
    OfferBt: {
      height: 35,
      borderRadius: 30,
      width: 80,
      backgroundColor: '#eccb98',
      color: 'white',
      fontSize: '11px',
    },

    COEBt: {
      height: 35,
      borderRadius: 30,
      width: 80,
      backgroundColor: '#d27e65',
      color: 'white',
      fontSize: '11px',
    },
    TableRow: {
      backgroundColor: '#efefef',
      fontWeight: 500,
    },
    TableBodyRow: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    HeaderPadding: {
      fontWeight: 500,
      fontSize: '13px',
    },
    bodyText: {
      fontSize: '13px',
    },
    tableSubtitleText: {
      color: '#727487',
      fontSize: 12,
      fontWeight: 500,
    },
    tableParents: {
      display: 'flex',
      flex: 1,
      height: '100%',
      overflow: 'hidden',
    },
    customChip: {
      background: '#727487',
      color: 'white',
      padding: '6px',
      borderRadius: '4px',
      textAlign: 'center',
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 'bold',
    },
    mainLayout: {
      display: 'flex',
      flex: 1,
      backgroundColor: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.117647) 2px 4px 6px 4px',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#D3D3D3',
      height: 'calc(100% - 50px)',
      overflow: 'hidden',
    },
    outcomeMenuParent: {
      display: 'flex',
      height: 30,
      alignItems: 'center',
      width: 160,
      justifyContent: 'space-between',
    },
    outcomeMenuStatus: {
      height: 16,
      width: 16,
      borderRadius: 8,
    },
    actionMenuParent: {
      display: 'flex',
      height: 30,
      alignItems: 'center',
      width: 160,
      textDecoration: 'none',
    },
    agentWrapper: {
      display: 'inline-flex',
    },
    actionMenuText: {
      fontSize: 12,
      marginLeft: 16,
      color: AppColor.menuTextColor,
      fontWeight: 500,
    },
    outcomeMenuText: {
      fontSize: 12,
      color: AppColor.menuTextColor,
      fontWeight: 500,
    },
    outcomeMenuEmailText: {
      fontSize: 10,
      color: AppColor.appLightGrey,
      fontWeight: 500,
    },
    deferredDParent: {
      height: 20,
      width: 20,
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    leadLabel: {
      height: 20,
      width: 25,
      borderRadius: 10,
      marginRight: theme.spacing(0.5),
      justifyContent: 'center',
    },
    dText: {
      fontSize: 12,
      textAlign: 'center',
      color: AppColor.appWhite,
      fontWeight: 700,
      marginLeft: 1,
      marginTop: -1,
    },
    sortableHeader: {
      fontWeight: 500,
      fontSize: '13px',
      color: theme.palette.common.black,
      fontFamily: 'Poppins',
      textTransform: 'none',
      letterSpacing: 0.75,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
);

type Order = 'asc' | 'desc';
type ApplicationTableProps = {
  data: any;
  submitOutcome: any;
  downloadOutComeFile: any;
  deleteOutcomeFile: any;
  downloadAppFile: any;
  onAssignToMe: any;
  applicationProviderSuggestion: any;
  providerList: any;
  user: any;
  userPermissions: PERMISSION[];
  onShowFileList: (event: any, applicationId: any, timer: any) => void;
  showLockApplication: (application: any) => void;
  showDeferredApplication: (application: any, obj: any) => void;
  showDeferredApplicationHistory: (application: any, obj: any) => void;
  tab: string;
  downloadOutComeHistoryFile: (outcome: any) => void;
  getApplicationsProviders: (applicationId: any) => void;
  order: Order;
  orderBy: string;
  onUpdateSort: (order: string, orderBy: string) => void;
  onDeleteApplication: (id: string) => void;
};
// export default function ApplicationTable() {

const ApplicationTable: React.FC<ApplicationTableProps> = ({
  data,
  submitOutcome,
  downloadOutComeFile,
  deleteOutcomeFile,
  downloadAppFile,
  onAssignToMe,
  providerList,
  user,
  userPermissions,
  onShowFileList,
  showLockApplication,
  tab,
  showDeferredApplication,
  downloadOutComeHistoryFile,
  showDeferredApplicationHistory,
  applicationProviderSuggestion,
  getApplicationsProviders,
  order,
  orderBy,
  onUpdateSort,
  onDeleteApplication,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDrawerFor, setOpenDrawerFor] = React.useState<number | null>(null);
  const [appId, setappId] = React.useState('');
  const [outcome, setOutcome] = React.useState('');
  const [outcomeName, setOutcomeName] = useState('');
  const [outcomeIds, setOutcomeIds] = useState({});
  const [outcomeObj, setOutcomeObj] = useState([]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1440));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [menuObj, setMenuObj] = useState<any>(null);
  const [menuOutcomeMasterObj, setMenuOutcomeMasterObj] = useState<any>(null);
  const [outcomeHistory, setOutComeHistory] = useState<any>([]);
  const [providerUserList, setProviderUserList] = useState<any>(undefined);
  const [providerMenuVisible, setProviderMenuVisible] = useState<boolean>(false);
  const [deleteApplication, setDeleteApplication] = useState<any>({
    showDeleteApplication: false,
    deleteApplicationId: '',
  });

  const [providerAnchorEl, setProviderAnchorEl] = React.useState<null | HTMLElement>(null);
  useEffect(() => {
    if (providerList && providerAnchorEl) {
      setProviderMenuVisible(true);
    }
  }, [providerUserList]);

  const handleProviderMenuClick = async (event: React.MouseEvent<HTMLElement>, obj: any) => {
    event.preventDefault();

    if (applicationProviderSuggestion && applicationProviderSuggestion.hasOwnProperty(obj.id)) {
      setProviderAnchorEl(event.currentTarget);
      setProviderUserList(applicationProviderSuggestion[obj.id]);
      setMenuObj(obj);
      setMenuOutcomeMasterObj(getOutcomeMasterArray(obj));
    } else {
      showProviderMenu(event, obj);
    }
  };

  const showProviderMenu = async (event: React.MouseEvent<HTMLElement>, obj: any) => {
    setProviderAnchorEl(event.currentTarget);
    const result = await getApplicationsProviders(obj.id);
    setProviderUserList(result);
    setMenuObj(obj);
    setMenuOutcomeMasterObj(getOutcomeMasterArray(obj));
  };

  const handleProviderMenuClose = () => {
    setProviderAnchorEl(null);
    setProviderUserList(undefined);
    setProviderMenuVisible(false);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, obj: any) => {
    setAnchorEl(event.currentTarget);
    setMenuObj(obj);
    setMenuOutcomeMasterObj(getOutcomeMasterArray(obj));
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const hasPerm = (perm: string) => {
    return hasPermission(perm, userPermissions);
  };

  const getApplicationAgent = (obj: any) => {
    if (obj.assignedByProvider) {
      if (obj.assignedByProvider.parentCompanyDetails) {
        return obj.assignedByProvider.parentCompanyDetails.companyName;
      }

      if (obj.assignedByProvider.companyDetails) {
        return obj.assignedByProvider.companyDetails.companyName;
      } else {
        return obj.assignedByProvider.name;
      }
    } else if (obj.createdUser && obj.createdUser.entityType == 'AGENCY') {
      if (obj.createdUser.parentCompanyDetails) {
        return obj.createdUser.parentCompanyDetails.companyName;
      }

      if (obj.createdUser.companyDetails) {
        return obj.createdUser.companyDetails.companyName;
      } else {
        return obj.createdUser.name;
      }
    } else {
      return '-';
    }
  };

  const getApplicationCreatedAgent = (obj: any) => {
    if (obj.createdUser) {
      if (obj.createdUser.parentCompanyDetails) {
        return obj.createdUser.parentCompanyDetails.companyName;
      }

      if (obj.createdUser.companyDetails) {
        return obj.createdUser.companyDetails.companyName;
      } else {
        return obj.createdUser.name;
      }
    } else if (obj.assignedByProvider) {
      if (obj.assignedByProvider.parentCompanyDetails) {
        return obj.assignedByProvider.parentCompanyDetails.companyName;
      }

      if (obj.assignedByProvider.companyDetails) {
        return obj.assignedByProvider.companyDetails.companyName;
      } else {
        return obj.assignedByProvider.name;
      }
    } else {
      return '-';
    }
  };

  const handleOpen = (
    applicationId: any,
    outcome: any,
    outcomeName: any,
    outcomeObjs: any,
    applicantId: any,
    studentId: any
  ) => {
    if (!hasPermission(PERMISSION.update_application_outcomes, userPermissions)) {
      return;
    }
    setappId(applicationId);
    setOutcome(outcome);
    setOutcomeName(outcomeName);
    setOutcomeIds({ applicantId, studentId });
    //console.log("outcome::"+outcome+"....outcomeName:::"+outcomeName+"...outcomeObjs:::"+outcomeObjs)
    let index = checkIfValueExist(outcomeObjs, outcome);
    if (index >= 0) {
      setOutcomeObj(outcomeObjs[index]);
    } else {
      setOutcomeObj([]);
    }

    const outcomeHistory = _.filter(outcomeObjs, (object) => object.outcome === outcome && object.isDeleted);
    setOutComeHistory(outcomeHistory);

    setAnchorEl(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOutcomeObj([]);
  };

  const handleInfoClose = async () => {
    setOpenDrawerFor(null);
  };

  const handleInfoOpen = async (applicationId: any) => {
    setOpenDrawerFor(applicationId);
  };

  const canEditApplication = (application: any) => {
    let canEdit = true;
    if (hasPerm(PERMISSION.applications_provider_view_cph)) {
      const loggedInUserId = user.user.id;
      canEdit =
        loggedInUserId == get(application, 'createdUser.id') ||
        loggedInUserId == get(application, 'assignedByProvider.id');
    }
    return canEdit;
  };

  // const viewApplication = (application: any) => {
  //   if (!canEditApplication(application)) {
  //     return;
  //   }
  //   const applicationUrl = '/app/' + encryptText(application.id) + '/group/-1/question/-1';
  //   history.push(applicationUrl);
  // };

  const checkIfValueExist = (obj: any, value: any) => {
    let itemIndex = -1;

    if (!obj) {
      return itemIndex;
    }

    obj.map((item: any, index: any) => {
      if (item.outcome === value && !item.isDeleted) {
        itemIndex = index;
      }
    });

    // console.log(itemIndex);
    return itemIndex;
  };

  const submitApplicationOutComes = async (
    file: any,
    applicationId: any,
    outcome: any,
    comment: any,
    refNum: any,
    id: any,
    applicantId: any,
    studentId: any
  ) => {
    if (!file) {
      setOutcomeObj([]);
    }

    const result = await submitOutcome(file, applicationId, outcome, comment, refNum, id, applicantId, studentId);
    if (result) {
      setOpen(false);
    }
  };

  const getDateFormat = (date: any) => {
    try {
      return `${moment(date).fromNow()}`;
    } catch (error) {
      console.log(error);
      return '';
    }
  };

  const getAdmissionTeamLeadAssignButtons = (application: any) => {
    let assignLink;
    const assignedUser: any = application.assignedUser;
    if (hasPerm(PERMISSION.applications_assignAdmissionTeam)) {
      assignLink = (
        <Link href="#" onClick={(event: any) => handleProviderMenuClick(event, application)}>
          {assignedUser ? assignedUser.name : LABELS.ASSIGN}
        </Link>
      );
    } else {
      if (assignedUser) {
        assignLink = <Typography>{assignedUser.name}</Typography>;
      } else if (hasPerm(PERMISSION.applications_assignToMe)) {
        assignLink = (
          <Link href="#" onClick={() => onAssignToMe(application.id, user.user.id)} style={{ color: AppColor.appLink }}>
            {LABELS.ASSIGN_TO_ME}
          </Link>
        );
      }
    }

    if (assignLink) {
      assignLink = (
        <>
          <AccountCircleIcon />
          <Typography
            style={{
              fontFamily: 'Poppins',
              fontSize: 12,
              textAlign: 'center',
              fontWeight: 500,
            }}
          >
            {assignLink}
          </Typography>
        </>
      );
    }
    return assignLink;
  };

  const getApplicationIdComponent = (obj: any) => {
    if (obj.lockStatus && tab == 'Current' && (!obj.deferred || obj.deferred.length == 0)) {
      return (
        <Tooltip title={'Locked Application'}>
          <Button onClick={() => showLockApplication(obj)} style={{ marginLeft: -16 }}>
            <LockIcon fontSize={'small'} />
            <div style={{ width: 5 }}></div>
            <SBText text={getApplicationIdInString(obj.appId)} className={classes.bodyText} />
          </Button>
        </Tooltip>
      );
    } else if (tab == 'Current' && obj.deferred && obj.deferred.length > 0) {
      const array = _.sortBy(obj.deferred, (dateObj) => {
        return new Date(dateObj.createdAt);
      }).reverse();

      const latestObj: any = array[0];

      let backgroundStatusColor = '';
      let toolTipMessage = '';
      let showHistory = false;

      if (!latestObj.approvedStatus) {
        if (latestObj.secondApproverEmail) {
          backgroundStatusColor = AppColor.appLightGrey;
          toolTipMessage = 'Deferment In Progress for ' + latestObj.nextTerm;
          showHistory = true;
        } else {
          backgroundStatusColor = AppColor.appBlue;
          toolTipMessage = 'Deferment Request for ' + latestObj.nextTerm;
          showHistory = false;
        }
      } else {
        if (latestObj.approvedStatus == 'Approve') {
          backgroundStatusColor = AppColor.appGreen;
          toolTipMessage = 'Deferment Request Approved For ' + latestObj.nextTerm;
          showHistory = true;
        } else {
          backgroundStatusColor = AppColor.appRed;
          toolTipMessage = 'Deferment Request Rejected For ' + latestObj.nextTerm;
          showHistory = true;
        }
      }

      if (hasPerm(PERMISSION.applications_defer)) {
        return (
          <Tooltip title={toolTipMessage}>
            <Button
              onClick={() =>
                showHistory ? showDeferredApplicationHistory(obj, obj) : showDeferredApplication(obj, latestObj)
              }
              style={{ marginLeft: -16 }}
            >
              <div className={classes.deferredDParent} style={{ backgroundColor: backgroundStatusColor }}>
                <SBText text={'D'} className={classes.dText} />
              </div>
              <div style={{ width: 5 }}></div>
              <SBText text={getApplicationIdInString(obj.appId)} className={classes.bodyText} />
            </Button>
          </Tooltip>
        );
      } else {
        return <SBText text={getApplicationIdInString(obj.appId)} className={classes.bodyText} />;
      }
    } else if (obj.lockStatus && tab == 'Current' && obj.deferred && obj.deferred.length > 0) {
      return <SBText text={getApplicationIdInString(obj.appId)} className={classes.bodyText} />;
    } else {
      return <SBText text={getApplicationIdInString(obj.appId)} className={classes.bodyText} />;
    }
  };

  const getOutcomeMasterArray = (application: any) => {
    let outcomes = application.outcomesMasterList;
    outcomes = _.sortBy(outcomes, (obj) => obj.outcomeSequence);
    return outcomes;
  };

  const getProviderName = (obj: any) => {
    if (obj.email == user.user.email) {
      return 'self';
    } else {
      if (obj.firstName) {
        return `${obj.firstName} ${obj.lastName}`;
      } else {
        return obj.name;
      }
    }
  };

  const isAllQuestionsAnswered = (questionList: any) => {
    let isAllQuestionAnswered = true;
    for (let i = 0; i < questionList.length; i++) {
      if (!questionList[i].answer) {
        isAllQuestionAnswered = false;
        break;
      }
    }
    return isAllQuestionAnswered;
  };

  const isOutComeGained = (groupList: any, code: any) => {
    let isAllQuestionAnswered = true;
    return isAllQuestionAnswered;
  };

  const onSortList = (key: string) => {
    let tempOrder = 'asc';
    if (orderBy == key) {
      tempOrder = order == 'asc' ? 'desc' : 'asc';
    }
    onUpdateSort(tempOrder, key);
  };

  return (
    <div className={classes.mainLayout}>
      <div className={classes.tableParents}>
        <TableContainer component={Paper}>
          <Table className={classes.table} stickyHeader>
            <TableHead className={classes.TableRow}>
              <TableRow>
                <TableCell align="left">
                  {/* <SBText text="Application" className={classes.HeaderPadding} /> */}

                  <TableSortLabel
                    active={orderBy == 'id'}
                    direction={orderBy == 'id' ? order : 'asc'}
                    onClick={() => onSortList('id')}
                    className={classes.sortableHeader}
                  >
                    {'Application'}
                    {orderBy == 'id' ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell align="left">
                  <SBText text="Applicant" className={classes.HeaderPadding} />
                </TableCell>
                <TableCell align="left">
                  <SBText text={tab == 'Leads' ? 'TA' : 'Agent'} className={classes.HeaderPadding} />
                </TableCell>
                <TableCell align="left">
                  <SBText text={tab == 'Leads' ? 'TA' : 'Created By'} className={classes.HeaderPadding} />
                </TableCell>
                <TableCell align="left">
                  {/* <SBText text="Created On" className={classes.HeaderPadding} /> */}

                  <TableSortLabel
                    active={orderBy == 'createdAt'}
                    direction={orderBy == 'createdAt' ? order : 'asc'}
                    onClick={() => onSortList('createdAt')}
                    className={classes.sortableHeader}
                  >
                    {'Created On'}
                    {orderBy == 'createdAt' ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">
                  {/* <SBText text="Last Updated" className={classes.HeaderPadding} /> */}

                  <TableSortLabel
                    active={orderBy == 'updatedAt'}
                    direction={orderBy == 'updatedAt' ? order : 'asc'}
                    onClick={() => onSortList('updatedAt')}
                    className={classes.sortableHeader}
                  >
                    {'Last Updated'}
                    {orderBy == 'updatedAt' ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                {tab !== 'Draft' && (
                  <TableCell align="center">
                    <SBText text={`Assigned`} className={classes.HeaderPadding} />
                  </TableCell>
                )}
                <TableCell align="left">
                  <SBText text={`Status`} className={classes.HeaderPadding} />
                </TableCell>
                {matches && (
                  <TableCell align="center">
                    <SBText text="Outcomes" className={classes.HeaderPadding} />
                  </TableCell>
                )}
                <TableCell align="center">
                  <SBText text={`Actions`} className={classes.HeaderPadding} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((obj: any) => {
                const outcomeMaster: any[] = getOutcomeMasterArray(obj);
                return (
                  <TableRow
                    key={obj.appId}
                    style={{
                      alignItems: 'center',
                    }}
                  >
                    <TableCell component="th">
                      <div style={{ display: 'flex', alignItems: 'center' }}>{getApplicationIdComponent(obj)}</div>
                      <SBText
                        text={obj.answers?.passport ? obj.answers?.passport : '-'}
                        className={classes.tableSubtitleText}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={obj.answers?.name || '-'} className={classes.bodyText} />
                      <SBText text={obj.answers?.course || '-'} className={classes.tableSubtitleText} />
                    </TableCell>
                    <TableCell align="left">
                      <div className={classes.agentWrapper}>
                        {obj.leadId && tab !== 'Leads' ? (
                          <SBTooltip title="TA">
                            <div className={classes.leadLabel} style={{ backgroundColor: AppColor.appRed }}>
                              <SBText text={'L'} className={classes.dText} />
                            </div>
                          </SBTooltip>
                        ) : null}
                        <SBText text={getApplicationAgent(obj)} className={classes.bodyText} />
                      </div>
                    </TableCell>

                    <TableCell align="left">
                      <div className={classes.agentWrapper}>
                        {obj.leadId && tab !== 'Leads' ? (
                          <SBTooltip title="TA">
                            <div className={classes.leadLabel} style={{ backgroundColor: AppColor.appRed }}>
                              <SBText text={'L'} className={classes.dText} />
                            </div>
                          </SBTooltip>
                        ) : null}
                        <SBText text={getApplicationCreatedAgent(obj)} className={classes.bodyText} />
                      </div>
                    </TableCell>

                    <TableCell align="left">
                      <SBText text={moment(obj.createdAt).format('DD MMM YYYY')} className={classes.bodyText} />
                      <SBText text={getDateFormat(obj.createdAt)} className={classes.tableSubtitleText} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={moment(obj.updatedAt).format('DD MMM YYYY HH:mm')} className={classes.bodyText} />
                      <SBText text={getDateFormat(obj.updatedAt)} className={classes.tableSubtitleText} />
                    </TableCell>
                    <TableCell align="left">
                      {obj.status !== 'Draft' && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          {getAdmissionTeamLeadAssignButtons(obj)}
                        </div>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <SBText text={obj?.status} className={classes.tableSubtitleText} />
                      <div className={classes.customChip}>{obj?.subStatus || '--'}</div>
                    </TableCell>

                    {matches ? (
                      <>
                        <TableCell align="left" style={{ maxWidth: 130 }}>
                          <div className={classes.outcomeContainer}>
                            {outcomeMaster.map((outcome) => {
                              const satisfied = checkIfValueExist(obj.outcomes, outcome.code) >= 0;
                              return (
                                <Tooltip title={outcome.outcomeLabel} key={outcome.code}>
                                  <IconButton
                                    className={clsx(classes.outcomeButton, satisfied && classes.outcomeSatisfied)}
                                    onClick={() =>
                                      handleOpen(
                                        obj.id,
                                        outcome.code,
                                        outcome.outcomeLabel,
                                        obj.outcomes,
                                        obj.applicantId,
                                        obj.studentId
                                      )
                                    }
                                  >
                                    {satisfied ? (
                                      <CheckCircleOutlineIcon fontSize="small" />
                                    ) : (
                                      <PanoramaFishEyeOutlined fontSize="small" />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              );
                            })}
                          </div>
                        </TableCell>

                        <TableCell align="center">
                          <div className={classes.actionsContainer}>
                            <Tooltip title="Recent Activity">
                              <IconButton onClick={(event) => handleInfoOpen(obj.id)}>
                                <InfoOutlined />
                              </IconButton>
                            </Tooltip>

                            {hasPermission(PERMISSION.dms, userPermissions) && (
                              <Tooltip title="View Files">
                                <IconButton
                                  disabled={obj.status === 'Draft'}
                                  href={`/files/${encryptText(obj.id)}/0`}
                                  target="__blank"
                                >
                                  <FileCopyIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title={canEditApplication(obj) ? 'Details' : LABELS.NO_EDIT_PERMISSION}>
                              <IconButton
                                disabled={!canEditApplication(obj)}
                                href={`/app/${encryptText(obj.id)}/group/-1/question/-1`}
                                target="__blank"
                              >
                                <OpenInNew />
                              </IconButton>
                            </Tooltip>
                            {hasPermission(PERMISSION.delete_draft_application, userPermissions) && (
                              <Tooltip
                                title={canEditApplication(obj) ? 'Delete Application' : LABELS.NO_EDIT_PERMISSION}
                              >
                                <IconButton
                                  onClick={() => {
                                    setDeleteApplication({ showDeleteApplication: true, deleteApplicationId: obj.id });
                                  }}
                                  disabled={obj.status !== 'Draft'}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      </>
                    ) : (
                      <TableCell align="left">
                        <IconButton onClick={(event) => handleMenuClick(event, obj)}>
                          <MenuIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SimpleModal
          appId={appId}
          outComeObj={outcomeObj}
          outcome={outcome}
          ids={outcomeIds}
          downloadOutComeFile={downloadOutComeFile}
          deleteOutcomeFile={deleteOutcomeFile}
          outcomeName={outcomeName}
          submitOutcome={submitApplicationOutComes}
          onClose={() => setOpen(false)}
          outcomeHistory={outcomeHistory}
          downloadOutComeHistoryFile={downloadOutComeHistoryFile}
        />
      </Modal>

      <StatusAndEventsDrawer applicationId={openDrawerFor} onClose={handleInfoClose} />

      {deleteApplication.showDeleteApplication && deleteApplication.deleteApplicationId && (
        <ConfirmationDialog
          open={deleteApplication.showDeleteApplication}
          onConfirm={() => {
            onDeleteApplication(deleteApplication.deleteApplicationId);
            setDeleteApplication({ showDeleteApplication: false, deleteApplicationId: '' });
          }}
          onCancel={() => setDeleteApplication({ showDeleteApplication: false, deleteApplicationId: '' })}
          message={'Are you sure, you want to delete application'}
          header={'Delete Application'}
        />
      )}

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={openMenu}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
      >
        {menuOutcomeMasterObj ? (
          <>
            {menuOutcomeMasterObj[0] && (
              <MenuItem
                onClick={() =>
                  handleOpen(
                    menuObj.id,
                    menuOutcomeMasterObj[0].code,
                    menuOutcomeMasterObj[0].outcomeLabel,
                    menuObj.outcomes,
                    menuObj.applicantId,
                    menuObj.studentId
                  )
                }
              >
                <div className={classes.outcomeMenuParent}>
                  <SBText text={menuOutcomeMasterObj[0].outcomeLabel} className={classes.outcomeMenuText} />
                  <div
                    className={classes.outcomeMenuStatus}
                    style={{
                      backgroundColor: isOutComeGained(menuObj.group, menuOutcomeMasterObj[0].code)
                        ? AppColor.appGreen
                        : AppColor.appRed,
                    }}
                  >
                    {checkIfValueExist(
                      menuObj && menuObj.outcomes ? menuObj.outcomes : null,
                      menuOutcomeMasterObj[0].code
                    ) >= 0 ? (
                      <CheckCircleOutlineIcon style={{ height: 16, width: 16, color: 'white' }} />
                    ) : null}
                  </div>
                </div>
              </MenuItem>
            )}
            {menuOutcomeMasterObj[1] && (
              <MenuItem
                onClick={() =>
                  handleOpen(
                    menuObj.id,
                    menuOutcomeMasterObj[1].code,
                    menuOutcomeMasterObj[1].outcomeLabel,
                    menuObj.outcomes,
                    menuObj.applicantId,
                    menuObj.studentId
                  )
                }
              >
                <div className={classes.outcomeMenuParent}>
                  <SBText text={menuOutcomeMasterObj[1].outcomeLabel} className={classes.outcomeMenuText} />
                  <div
                    className={classes.outcomeMenuStatus}
                    style={{
                      backgroundColor: isOutComeGained(menuObj.group, menuOutcomeMasterObj[1].code)
                        ? AppColor.appGreen
                        : AppColor.appRed,
                    }}
                  >
                    {checkIfValueExist(
                      menuObj && menuObj.outcomes ? menuObj.outcomes : null,
                      menuOutcomeMasterObj[1].code
                    ) >= 0 ? (
                      <CheckCircleOutlineIcon style={{ height: 16, width: 16, color: 'white' }} />
                    ) : null}
                  </div>
                </div>
              </MenuItem>
            )}
            {menuOutcomeMasterObj[2] && (
              <MenuItem
                onClick={() =>
                  handleOpen(
                    menuObj.id,
                    menuOutcomeMasterObj[2].code,
                    menuOutcomeMasterObj[2].outcomeLabel,
                    menuObj.outcomes,
                    menuObj.applicantId,
                    menuObj.studentId
                  )
                }
              >
                <div className={classes.outcomeMenuParent}>
                  <SBText text={menuOutcomeMasterObj[2].outcomeLabel} className={classes.outcomeMenuText} />
                  <div
                    className={classes.outcomeMenuStatus}
                    style={{
                      backgroundColor: isOutComeGained(menuObj.group, menuOutcomeMasterObj[2].code)
                        ? AppColor.appGreen
                        : AppColor.appRed,
                    }}
                  >
                    {checkIfValueExist(
                      menuObj && menuObj.outcomes ? menuObj.outcomes : null,
                      menuOutcomeMasterObj[2].code
                    ) >= 0 ? (
                      <CheckCircleOutlineIcon style={{ height: 16, width: 16, color: 'white' }} />
                    ) : null}
                  </div>
                </div>
              </MenuItem>
            )}{' '}
          </>
        ) : null}

        <MenuItem
          onClick={() => {
            handleInfoOpen(menuObj.id);
            setAnchorEl(null);
          }}
        >
          <div className={classes.actionMenuParent}>
            <InfoOutlined style={{ color: AppColor.menuTextColor }} />
            <SBText text={'Recent Activity'} className={classes.actionMenuText} />
          </div>
        </MenuItem>
        {hasPerm(PERMISSION.dms_edit) && menuObj?.status !== 'Draft' && (
          <MenuItem onClick={() => setAnchorEl(null)}>
            <a className={classes.actionMenuParent} href={`/files/${encryptText(menuObj?.id)}/0`} target="__blank">
              <FileCopyIcon style={{ color: AppColor.menuTextColor }} />
              <SBText text={'View Files'} className={classes.actionMenuText} />
            </a>
          </MenuItem>
        )}

        {hasPerm(PERMISSION.applications_edit) && canEditApplication(menuObj) && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            <a
              className={classes.actionMenuParent}
              title={canEditApplication(menuObj) ? '' : LABELS.NO_EDIT_PERMISSION}
              href={'/app/' + encryptText(menuObj?.id) + '/group/-1/question/-1'}
              target="__blank"
            >
              <VisibilityIcon style={{ color: AppColor.menuTextColor }} />
              <SBText text={'Details'} className={classes.actionMenuText} />
            </a>
          </MenuItem>
        )}

        {hasPerm(PERMISSION.delete_draft_application) && menuObj?.status === 'Draft' && (
          <MenuItem
            onClick={() => {
              setDeleteApplication({ showDeleteApplication: true, deleteApplicationId: menuObj.id });
              setAnchorEl(null);
            }}
          >
            <div className={classes.actionMenuParent}>
              <DeleteIcon style={{ color: AppColor.menuTextColor }} />
              <SBText text={'Delete Application'} className={classes.actionMenuText} />
            </div>
          </MenuItem>
        )}
      </Menu>

      <Menu
        id="fade-menu"
        anchorEl={providerAnchorEl}
        keepMounted
        open={providerMenuVisible}
        onClose={handleProviderMenuClose}
        TransitionComponent={Fade}
      >
        {providerUserList &&
          providerUserList.map((obj: any) => (
            <MenuItem
              onClick={() => {
                onAssignToMe(menuObj.id, obj.id);
                handleProviderMenuClose();
              }}
              style={{
                // paddingBottom: 0,
                // paddingTop: 0,
                padding: 0,
                // paddingRight: 0,
              }}
            >
              <div
                style={{
                  borderBottomColor: AppColor.appLightGrey,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 1,
                  display: 'flex',
                  height: 40,
                  alignItems: 'center',
                  width: 300,
                  justifyContent: 'space-between',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SBText text={getProviderName(obj)} className={classes.outcomeMenuText} />
                  <SBText text={`(${obj.email})`} className={classes.outcomeMenuEmailText} />
                </div>

                {menuObj && menuObj.assignedUser && menuObj.assignedUser.email === obj.email ? (
                  <CheckCircleOutlineIcon style={{ color: AppColor.appGreen }} />
                ) : null}
              </div>
            </MenuItem>
          ))}

        {menuObj && menuObj.assignedUser ? (
          <MenuItem
            onClick={() => {
              onAssignToMe(menuObj.id, '-1');
              handleProviderMenuClose();
            }}
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              padding: 0,
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <div
              style={{
                borderBottomColor: AppColor.appLightGrey,
                borderBottomStyle: 'solid',
                borderBottomWidth: 1,
                display: 'flex',
                height: 40,
                alignItems: 'center',
                width: 300,
                justifyContent: 'space-between',
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <SBText text={'Unassign'} className={classes.outcomeMenuText} style={{ color: AppColor.appRed }} />
            </div>
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default ApplicationTable;
