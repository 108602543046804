import React from "react";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type DialogProps = {
  className?: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  hideCancelButton?: boolean;
  message: string;
  header: string;
};
const ConfirmationDialog: React.FC<DialogProps> = ({
  open,
  onConfirm,
  onCancel,
  header,
  message,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  hideCancelButton = false,
}) => {
  
  const handleSubmit = () => {
    onConfirm();
  };

  const handleClose = () => {
    onCancel();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!hideCancelButton && (
            <Button onClick={handleClose} color="primary">
              {cancelButtonText}
            </Button>
          )}
          <Button onClick={handleSubmit} color="primary">
            {confirmButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
