import React from "react";
import LeadsView from "./LeadsView";


const Leads = () => {
  return (
    <LeadsView></LeadsView>
  );
};

export default Leads;
