import { LPO_STATUS } from '../types';

export * from './get-lpo-table-actions';
export * from './lpo-data-mapping';
export * from './get-lpo-request-query';
export * from './lpo-validator';

export const statusEditable = (status: string) => {
  return status === LPO_STATUS.ADDED || status === LPO_STATUS.ENQUIRED || status === LPO_STATUS.INVITED;
};
