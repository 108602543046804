import { string } from "prop-types";
import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../config";
import { RootState } from "../reducer";
import { ActionType } from "../reducer/types";
import { get, patch, post, put } from "./common";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";

export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const UPDATE_ROLES_SEARCH_PARAMS = 'UPDATE_ROLES_SEARCH_PARAMS';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';

interface RoleSearchParams {
    limit: number;
    offset: number;
    searchText: string;
}
export const getRoleList = (roleSearchParams: RoleSearchParams): ThunkAction<
    void,
    RootState,
    unknown,
    ActionType
> => async (dispatch, getState) => {
    return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
            dispatch({ type: ON_LOADING_START });
            dispatch({ type: ON_LOADING_START });
            const result = await get(
                `${config.BASE_URL}/user/role/all`,
                dispatch
            );
            await dispatch({
                type: FETCH_ROLES_SUCCESS,
                payload: result,
            });
            resolve(result);
            dispatch({ type: ON_LOADING_FINISHED });
        }
    );
};

interface RoleParams {
    name: string;
    key: string;
    description?: string;
    permissions: any[];
    [x: string]: any;
}

export const createNewRole = (roleParams: RoleParams
): ThunkAction<void, RootState, unknown, ActionType> => async (
    dispatch,
    getState
) => {
        return new Promise(
            async (resolve: (x: any) => void, reject: (x: any) => void) => {
                dispatch({ type: ON_LOADING_START });

                const result = await post(
                    `${config.BASE_URL}/user/role`,
                    roleParams,
                    dispatch
                );
                dispatch(getRoleList({ offset: 0, searchText: '', limit: 10 }))
                resolve(result);
                dispatch({ type: ON_LOADING_FINISHED });
            }
        );
};

export const getRole = (id: number
): ThunkAction<void, RootState, unknown, ActionType> => async (
    dispatch,
    getState
) => {
        return new Promise(
            async (resolve: (x: any) => void, reject: (x: any) => void) => {
                dispatch({ type: ON_LOADING_START });

                const result = await get(
                    `${config.BASE_URL}/user/role/${id}`,
                    dispatch
                );
                resolve(result);
                await dispatchRole(result)(dispatch);
                dispatch({ type: ON_LOADING_FINISHED });
            }
        );
};

export const dispatchRole = (result: any) => async (dispatch: Dispatch) => {
    dispatch({
        type: FETCH_ROLE_SUCCESS,
        payload: result,
    });
};

export const updateRole = (roleParams: RoleParams
): ThunkAction<void, RootState, unknown, ActionType> => async (
    dispatch,
    getState
) => {
    const {...rest } = roleParams;
        return new Promise(
            async (resolve: (x: any) => void, reject: (x: any) => void) => {
                dispatch({ type: ON_LOADING_START });

                const result = await put(
                    `${config.BASE_URL}/user/role/${rest.id}`,
                    roleParams,
                    dispatch
                );
                dispatch(getRoleList({ offset: 0, searchText: '', limit: 10 }))
                resolve(result);
                dispatch({ type: ON_LOADING_FINISHED });
            }
        );
    };

export const getPermissionList = (): ThunkAction<
    void,
    RootState,
    unknown,
    ActionType
> => async (dispatch, getState) => {
    return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
            dispatch({ type: ON_LOADING_START });
            const result = await get(
                `${config.BASE_URL}/user/permission/all`,
                dispatch
            );
            await dispatch({
                type: FETCH_PERMISSIONS_SUCCESS,
                payload: result,
            });
            resolve(result);
            dispatch({ type: ON_LOADING_FINISHED });
        }
    );
};

