import { TablePagination, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import SBButton from '../../../components/base/SBButton';
import SBText from '../../../components/base/SBText';
import SBTextField from '../../../components/base/SBTextField';
import { HistoryPaginatedData, HistoryItem, ReportFilterType, ReportItem, ReportTab, FetchDataParams } from '../types';
import HistoryTable from './history';
import moment from 'moment';
import ReportFilter from './filters';
import { DEFAULT_FETCH_PARAMS, DEFAULT_PAGINATED_DATA, DEFAULT_REPORT_FILTER, PAGE_SIZES } from '../constants';
import { useGenerateReport } from '../hooks/useGenerateReport';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { useLoadReportHistory } from '../hooks/useLoadReportHistory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '28px 36px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      //   padding: theme.spacing(2),
      flex: '0 0 54px',
      padding: theme.spacing(2),
      margin: theme.spacing(-2),
      marginBottom: 0,
      // backgroundColor: "#CCC",
      '& .MuiTypography-body1': { fontSize: 24, fontWeight: 600 },
    },
    emptyText: {
      flex: 1,
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiTypography-body1': { color: 'grey', fontSize: 18 },
    },
    tabsParent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 55px)',
    },
    tabSubParents: {
      display: 'flex',
      alignItems: 'flex-end',
      paddingTop: 8,
      position: 'relative',
    },
    tabBasic: {
      height: 50,
      width: 143,
      backgroundColor: '#F9F9F9',
      borderRadius: '8px 8px 0px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      color: '#9D9D9D',
      border: '1px solid #E6E6E6',
      borderBottomColor: 'transparent',
      position: 'relative',
      fontSize: 18,
      letterSpacing: 0.5,
      fontWeight: 400,

      '&:hover': {
        color: '#7D7D7D',
        backgroundColor: '#F1F1F1',
      },
    },
    tabSelected: {
      height: 51,
      fontWeight: 500,
      backgroundColor: 'white',
      color: '#121212',
      bottom: -1,
      '&:hover': {
        color: '#121212',
        backgroundColor: 'white',
      },
    },
    tabBody: {
      overflow: 'auto',
      backgroundColor: 'white',
      padding: '8px 16px 0 16px',
      border: '1px solid #E6E6E6',
      borderRadius: '0 8px 8px 8px',
    },
    generateContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginLeft: '45%',
      backgroundColor: '#FCFBFB',
      borderRadius: 6,
      border: '1px solid rgba(0,0,0,0.15)',
      padding: 8,

      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
    btn: {
      padding: '10px 20px',
      float: 'right',
      marginBottom: 2,
      width: '94%',
    },
  })
);

type ReportsDetailsProps = {
  report: ReportItem | undefined;
};

const reportTabs: ReportTab[] = ['Generate', 'History'];
const ReportsDetails = ({ report }: ReportsDetailsProps) => {
  const classes = useStyles();

  const { generateReport } = useGenerateReport();
  const { loadReportHistory } = useLoadReportHistory();

  const [reportName, setReportName] = useState<string>('');
  const [activeTab, setActiveTab] = useState<ReportTab>('Generate');
  const [tableData, setTableData] = useState<HistoryPaginatedData>(DEFAULT_PAGINATED_DATA);
  const [appliedFilter, setAppliedFilter] = useState<ReportFilterType>(DEFAULT_REPORT_FILTER);
  const [generateConfirmOpen, setGenerateConfirmOpen] = useState<boolean>(false);
  const [fetchDataParams, setFetchDataParams] = useState<FetchDataParams>(DEFAULT_FETCH_PARAMS);

  useEffect(() => {
    setActiveTab('Generate');
    setFetchDataParams(DEFAULT_FETCH_PARAMS);
    setReportName(`${report?.name}-${moment(new Date()).format('MMM YY')}`);
    loadHistory(report?.type || '');
  }, [report]);

  useEffect(() => {
    activeTab === 'History' && loadHistory(report?.type || '');
  }, [activeTab, fetchDataParams]);

  const loadHistory = async (reportType: string) => {
    const history = await loadReportHistory(reportType, fetchDataParams);
    setTableData(history);
  };

  const handleGenerateReport = async () => {
    setGenerateConfirmOpen(true);
    await generateReport(report?.type || 'StatusSummary', reportName, appliedFilter);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFetchDataParams({
      ...fetchDataParams,
      offset: newPage * tableData.pageSize,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize: number = parseInt(event.target.value, 10);
    setFetchDataParams({
      ...fetchDataParams,
      limit: pageSize,
      offset: 0,
    });
  };

  return (
    <div className={classes.root}>
      {report ? (
        <>
          <div className={classes.header}>
            <SBText text={report.name} />
          </div>

          <div className={classes.tabsParent}>
            <div className={classes.tabSubParents}>
              {reportTabs.map((tab: ReportTab) => (
                <>
                  <div
                    key={tab}
                    className={`${classes.tabBasic} ${activeTab == tab ? classes.tabSelected : ''}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </div>
                </>
              ))}
            </div>

            <div className={classes.tabBody}>
              {activeTab == 'Generate' && (
                <div style={{ padding: '16px 16px' }}>
                  <ReportFilter
                    report={report}
                    onFilterChange={(filterValue: ReportFilterType) => {
                      setAppliedFilter(filterValue);
                    }}
                  />
                  <div className={classes.generateContainer}>
                    <div style={{ flex: 1 }}>
                      <SBTextField value={reportName} onDataChange={(value) => setReportName(value)}></SBTextField>
                    </div>

                    <div style={{ flex: '0 0 150px' }}>
                      <SBButton icon={false} text="Generate" className={classes.btn} onClick={handleGenerateReport} />
                    </div>
                  </div>
                </div>
              )}

              {activeTab == 'History' && (
                <>
                  <HistoryTable historyList={tableData.data} />

                  <TablePagination
                    rowsPerPageOptions={PAGE_SIZES}
                    component="div"
                    count={tableData.totalRecords}
                    rowsPerPage={tableData.pageSize}
                    page={tableData.currentPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={classes.emptyText}>
          <SBText text={'Select a report from the list'} />
        </div>
      )}

      <ConfirmationDialog
        open={generateConfirmOpen}
        onConfirm={() => {
          setGenerateConfirmOpen(false);
        }}
        onCancel={() => {}}
        hideCancelButton={true}
        message={`You will be notified once your report (${reportName}) is generated, You can also check the 'History' tab for previous reports.`}
        header={`Generating Report`}
        confirmButtonText="OK"
      />
    </div>
  );
};

export default ReportsDetails;
