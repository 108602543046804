import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { RootState } from "../../../reducer";
import SBText from "../../../components/base/SBText";
import _ from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BlockHints from "./BlockHints";
import Block from "./block";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "2rem",
      // marginBottom: 150,
    },
    blockWrapper: {
      display: "flex",
      justifyContent: "center",
      marginBottom: 15,
    },
    blocksContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    imageTitle: {
      position: "relative",
      fontWeight: 500,
    },
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type LeftBlockPanelProps = PropsFromRedux & {
  onBlockClick: (block: any) => void;
  onLockUnlockClick: (block: any) => void;
};

const LeftBlockPanel: React.FC<LeftBlockPanelProps> = ({
  sbApplication,
  blocks,
  onBlockClick,
  onLockUnlockClick,
  currentApplicationDetails,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up(1440));



  const onBlockPress = (block: any) => {
    if (
      !sbApplication.initialStateCompleted ||
      currentApplicationDetails.lockStatus
    ) {
      return;
    } else {
      onBlockClick(block);
    }
  };

  const getUpdatedBlockName = (name: string, lineHeight: number) => {
    if (name == "Deferred") {
      const intakeObj = _.find(
        currentApplicationDetails.keyAnswers,
        (obj: any) => obj.key == "Q_INTAKE"
      );

      return (
        <div style={{ flexDirection: "column", display: "flex" }}>
          <SBText
            text={name}
            className={classes.imageTitle}
            style={{ fontSize: 14, lineHeight: lineHeight }}
          />
          {intakeObj ? (
            <SBText
              text={intakeObj.value}
              className={classes.imageTitle}
              style={{ fontSize: 14, lineHeight: lineHeight, marginTop: 2 }}
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <SBText
          text={name}
          className={classes.imageTitle}
          style={{ fontSize: 14, lineHeight: lineHeight }}
        />
      );
    }
  };

  const getBlockUI = () => {
    const blockItems: any = [];
    blocks.forEach((block: any, index: number) => {
      let spanWidth = !matches || (index + 1) % 3 == 0 ? "100%" : "50%";

      //Show Last & Single block full width
      if (index % 3 == 0 && index + 1 == blocks.length) {
        spanWidth = "100%";
      }
      block &&
        blockItems.push(
          <div className={classes.blockWrapper} style={{ width: spanWidth }}>
            <Block
              blockData={block}
              sbApplication={sbApplication}
              currentApplicationDetails={currentApplicationDetails}
              onBlockClick={() => {
                onBlockPress(block);
              }}
              onLockUnlockClick={() => {
                onLockUnlockClick(block);
              }}
            >
              {getUpdatedBlockName(block.name, 1)}
            </Block>
          </div>
        );
    });
    return blockItems;
  };

  return (
    <div className={classes.root}>
      {blocks && <div className={classes.blocksContainer}>{getBlockUI()}</div>}
      <BlockHints />
    </div>
  );
};

const mapState = (state: RootState) => ({
  // @ts-ignore
  sbApplication: state.sbApplication.sbApplication,
  // @ts-ignore
  currentApplicationDetails: state.sbApplication.currentApplicationDetails,
  // @ts-ignore
  blocks: state.sbApplication.blocks,
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(LeftBlockPanel);
