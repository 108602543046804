import { useCallback } from 'react';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from '../../../actions';
import { API_URL } from '../../../util/apiUrls';

export const useDownloadTaDoc = () => {
  const dispatch = useDispatch();

  const downloadTaFile = useCallback(
    async (id: number, type: string, fileName:string) => {
      dispatch({ type: ON_LOADING_START });
      const url = `${API_URL.LPO.DOWNLOAD_FILE(id, type)}`.replace('#', '%*%');
      try {
        const result = await get(
          url,
        //   @ts-ignore
          {
            responseType: 'blob',
          },
          dispatch
        );
        fileDownload(result, fileName);
        // const blobUrl = URL.createObjectURL(result);
        // console.log('blobUrl ??????', blobUrl);
        // window.open(blobUrl);
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    downloadTaFile,
  };
};


const b64toBlob = (b64Data:any, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }