import { SvgIcon } from "@material-ui/core";
import React from "react";

export function AddDocIcon(props: any) {
  return (
    <SvgIcon {...props}  stroke="gray" viewBox="0 0 49 53" fill="none" color="#8b8c9b">
    <path d="M5.375 47.875C4.06875 47.875 2.95012 47.4103 2.01912 46.4809C1.08971 45.5499 0.625 44.4313 0.625 43.125V9.875C0.625 8.56875 1.08971 7.45012 2.01912 6.51912C2.95012 5.58971 4.06875 5.125 5.375 5.125H15.35C15.8646 3.7 16.7259 2.55208 17.934 1.68125C19.1405 0.810417 20.4958 0.375 22 0.375C23.5042 0.375 24.8603 0.810417 26.0684 1.68125C27.2749 2.55208 28.1354 3.7 28.65 5.125H38.625C39.9313 5.125 41.0499 5.58971 41.9809 6.51912C42.9103 7.45012 43.375 8.56875 43.375 9.875V25.7875C42.6229 25.4312 41.851 25.1241 41.0594 24.866C40.2677 24.6095 39.4562 24.4219 38.625 24.3031V9.875H5.375V43.125H19.7437C19.8625 43.9958 20.0509 44.8271 20.309 45.6187C20.5655 46.4104 20.8719 47.1625 21.2281 47.875H5.375ZM5.375 43.125V9.875V24.3031V24.125V43.125ZM10.125 38.375H19.8031C19.9219 37.5438 20.1095 36.7323 20.366 35.9406C20.6241 35.149 20.9115 34.3771 21.2281 33.625H10.125V38.375ZM10.125 28.875H24.6125C25.8792 27.6875 27.2939 26.6979 28.8566 25.9062C30.421 25.1146 32.0938 24.5802 33.875 24.3031V24.125H10.125V28.875ZM10.125 19.375H33.875V14.625H10.125V19.375ZM22 8.09375C22.5146 8.09375 22.9405 7.92513 23.2778 7.58788C23.6134 7.25221 23.7812 6.82708 23.7812 6.3125C23.7812 5.79792 23.6134 5.372 23.2778 5.03475C22.9405 4.69908 22.5146 4.53125 22 4.53125C21.4854 4.53125 21.0603 4.69908 20.7246 5.03475C20.3874 5.372 20.2188 5.79792 20.2188 6.3125C20.2188 6.82708 20.3874 7.25221 20.7246 7.58788C21.0603 7.92513 21.4854 8.09375 22 8.09375ZM36.25 52.625C32.9646 52.625 30.1645 51.4676 27.8496 49.1528C25.5332 46.8363 24.375 44.0354 24.375 40.75C24.375 37.4646 25.5332 34.6637 27.8496 32.3472C30.1645 30.0324 32.9646 28.875 36.25 28.875C39.5354 28.875 42.3363 30.0324 44.6528 32.3472C46.9676 34.6637 48.125 37.4646 48.125 40.75C48.125 44.0354 46.9676 46.8363 44.6528 49.1528C42.3363 51.4676 39.5354 52.625 36.25 52.625ZM35.0625 47.875H37.4375V41.9375H43.375V39.5625H37.4375V33.625H35.0625V39.5625H29.125V41.9375H35.0625V47.875Z"/>
    </SvgIcon>
  );
}



