import * as actionTypes from "../actions";
import { ActionType } from "./types";
import _ from "lodash";

const initialState = {
  loading: false,
  internalLoading: false,
  fileUploading: false,
  fileUploadingInfo: undefined,
  errorMessage: undefined,
  displayErrorView: false,
  drawerOpen: false,
  partialFilledError: null,
  notifications: [],
  totalNotificationCount: 0,
  commentNotification: 0,
  reminderNotification: 0,
  notificationTrayOpened: false,
  appPermissionDetails: {},
  applicationDetailLoading: false,
  applicationQuestionLoading: false
};

const applicationReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.ON_LOADING_START: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.ON_LOADING_FINISHED: {
      return {
        ...state,
        loading: false,
        // fileUploading: false,
      };
    }

    case actionTypes.ON_INTERNAL_LOADING_START: {
      return {
        ...state,
        internalLoading: true,
      };
    }

    case actionTypes.ON_INTERNAl_LOADING_FINISHED: {
      return {
        ...state,
        internalLoading: false,
        // fileUploading: false,
      };
    }


    case actionTypes.ON_FILE_UPLOADING_START: {
      return {
        ...state,
        fileUploading: true,
      };
    }

    case actionTypes.ON_FILE_UPLOADING_FINISHED: {
      return {
        ...state,
        fileUploading: false,
        fileUploadingInfo: undefined,
      };
    }

    case actionTypes.PARTIAL_DATA_FILLED_ERROR: {
      return {
        ...state,
        partialFilledError: action.payload,
      };
    }
    case actionTypes.RESET_PARTIAL_DATA_FILLED_ERROR: {
      return {
        ...state,
        partialFilledError: null,
      };
    }

    case actionTypes.ON_FILE_UPLOADING_PROGRESS: {
      return {
        ...state,
        fileUploadingInfo: action.payload,
      };
    }

    case actionTypes.SET_ERROR: {
      return {
        ...state,
        errorMessage: action.payload,
        displayErrorView: true,
      };
    }

    case actionTypes.RESET_ERROR: {
      return {
        ...state,
        errorMessage: undefined,
        displayErrorView: false,
      };
    }
    case actionTypes.SHOW_HIDE_DRAWER: {
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
        // fileUploading: false,
      };
    }
    case actionTypes.ADD_NOTIFICATION_EVENT: {
      // const tempNotification: any = _.cloneDeep(state.notifications);
      // tempNotification.push(action.payload);
      // let totalUnreadCount = 0;
      // let commentsUnreadCount = 0;
      // let reminderUnreadCount = 0;
      // for (let i = 0; i < tempNotification.length; i++) {
      //   if (!tempNotification[i].read) {
      //     totalUnreadCount++;
      //     if (tempNotification[i].notificationType === "CN") {
      //       commentsUnreadCount++;
      //     } else {
      //       reminderUnreadCount++;
      //     }
      //   }
      // }

      let totalUnreadCount = 0;
      let commentsUnreadCount = 0;
      let reminderUnreadCount = 0;
      for (let i = 0; i < action.payload.length; i++) {
        if (!action.payload[i].read) {
          totalUnreadCount++;
          if (action.payload[i].notificationType === "CN") {
            commentsUnreadCount++;
          } else {
            reminderUnreadCount++;
          }
        }
      }
      return {
        ...state,
        notifications: action.payload,
        notificationTrayOpened: false,
        totalNotificationCount: totalUnreadCount,
        commentNotification: commentsUnreadCount,
        reminderNotification: reminderUnreadCount,
      };
    }
    case actionTypes.SET_INITIAL_NOTIFICATION_LIST: {
      let totalUnreadCount = 0;
      let commentsUnreadCount = 0;
      let reminderUnreadCount = 0;
      for (let i = 0; i < action.payload.length; i++) {
        if (!action.payload[i].read) {
          totalUnreadCount++;
          if (action.payload[i].notificationType === "CN") {
            commentsUnreadCount++;
          } else {
            reminderUnreadCount++;
          }
        }
      }

      return {
        ...state,
        notifications: action.payload,
        totalNotificationCount: totalUnreadCount,
        commentNotification: commentsUnreadCount,
        reminderNotification: reminderUnreadCount,
      };
    }

    case actionTypes.SET_MARK_AS_READ: {
      const tempNotification: any = _.cloneDeep(state.notifications);
      for (let i = 0; i < action.payload; i++) {
        const id = action.payload[i];
        const index = _.findIndex(tempNotification, (obj: any) => obj.id == id);
        if (index != -1) {
          tempNotification[index].read = true;
        }
      }

      // console.log("tempNotification !!!!!!!!!!!!!!!!", tempNotification);

      let totalUnreadCount = 0;
      let commentsUnreadCount = 0;
      let reminderUnreadCount = 0;
      for (let i = 0; i < tempNotification.length; i++) {
        if (!tempNotification[i].read) {
          totalUnreadCount++;
          if (tempNotification[i].notificationType === "CN") {
            commentsUnreadCount++;
          } else {
            reminderUnreadCount++;
          }
        }
      }

      return {
        ...state,
        notifications: tempNotification,
        totalNotificationCount: totalUnreadCount,
        commentNotification: commentsUnreadCount,
        reminderNotification: reminderUnreadCount,
        // fileUploading: false,
      };
    }
    case actionTypes.SET_MARK_AS_CLOSE: {
      const tempNotification: any = _.cloneDeep(state.notifications);
      for (let i = 0; i < action.payload.length; i++) {
        const id = action.payload[i];
        const index = _.findIndex(tempNotification, (obj: any) => obj.id == id);
        if (index != -1) {
          tempNotification.splice(index, 1);
        }
      }
      let totalUnreadCount = 0;
      let commentsUnreadCount = 0;
      let reminderUnreadCount = 0;
      for (let i = 0; i < tempNotification.length; i++) {
        if (!tempNotification[i].read) {
          totalUnreadCount++;
          if (tempNotification[i].notificationType === "CN") {
            commentsUnreadCount++;
          } else {
            reminderUnreadCount++;
          }
        }
      }
      return {
        ...state,
        notifications: tempNotification,
        totalNotificationCount: totalUnreadCount,
        commentNotification: commentsUnreadCount,
        reminderNotification: reminderUnreadCount,
      };
    }

    case actionTypes.CLEAR_NOTIFICATION_EVENT: {
      return {
        ...state,
        notifications: [],
      };
    }
    case actionTypes.SET_NOTIFICATION_TRAY_OPENED: {
      return {
        ...state,
        notificationTrayOpened: true,
      };
    }
    case actionTypes.FETCH_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        appPermissionDetails: action.payload,
      };
    }
    case actionTypes.APPLICATION_DETAIL_LOADING: {
      return {
        ...state,
        applicationDetailLoading: true,
      };
    }
    case actionTypes.APPLICATION_DETAIL_LOADING_COMPLETED: {
      return {
        ...state,
        applicationDetailLoading: false,
      };
    }
    case actionTypes.APPLICATION_QUESTION_LOADING: {
      return {
        ...state,
        applicationQuestionLoading: true,
      };
    }
    case actionTypes.APPLICATION_QUESTION_LOADING_COMPLETED: {
      return {
        ...state,
        applicationQuestionLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationReducer;
