import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.common.black,
      fontFamily: "Poppins",
      textTransform: "none",
      fontWeight: 400,
      letterSpacing: 0.75,
    },
  })
);

type SBButtonProps = {
  className?: string;
  text: string;
  [x: string]: any;
};

const SBText: React.FC<SBButtonProps> = ({ className, text, ...rest }) => {
  // console.log('SBText............');
  const classes = useStyles();
  return (
    <Typography className={clsx(classes.text, className)} {...rest}>
      {text}
    </Typography>
  );
};

export default SBText;
