import * as actionTypes from "../actions";
import { ActionType } from "./types";
import _ from "lodash";

const initialState = {
  dashboardFilter: {
    countries: [],
    providers: [],
    term: [],
  },
  agentPerformanceStatus: {
    userId: 0,
    companyName: "",
    parentCompanyName: "",
    userName: "",
    totalCount: 0,
    currentWeek: 0,
    lastWeek: 0,
  },
  selectedFilter: {
    isFilter: true,
    term: "All",
    provider: "All",
    country: "All",
    providerId: -1
  },
  dashboardData: undefined,
};

const dashboardReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SET_DASHBOARD_FILTER: {
      return {
        ...state,
        dashboardFilter: action.payload,
      };
    }
    case actionTypes.SET_AGENT_PERFORMANCE_STATUS: {
      return {
        ...state,
        agentPerformanceStatus: action.payload,
      };
    }
    case actionTypes.CHANGE_DASHBOARD_FILTER: {
      const filter: any = _.cloneDeep(state.selectedFilter);
      filter[action.payload.key] = action.payload.value;
      return {
        ...state,
        selectedFilter: filter,
      };
    }
    case actionTypes.SET_DASHBOARD_DETAILS_DATA: {
      return {
        ...state,
        dashboardData: action.payload,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        selectedFilter: {
          isFilter: true,
          term: "All",
          provider: "All",
          country: "All",
          providerId: -1
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default dashboardReducer;
