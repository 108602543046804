import { ActionTypes } from "../actions/agent";
import { ActionType } from "../../../../reducer/types";

interface RefreeState {
  contractSend: [];
}

const initialState: RefreeState = {
  contractSend: [],
};

const sendContractReducer = (
  state = initialState,
  action: ActionType
): RefreeState => {
  switch (action.type) {
    case ActionTypes.SET_SEND_CONTRACT_DETAILS: {
      return {
        ...state,
        contractSend: action.payload,
      };
    }
    default:
      return state;
  }
};

export default sendContractReducer;
