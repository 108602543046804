import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

export const tableViewHeaderStyle: CSSProperties = {
  fontSize: 24,
  fontWeight: 700,
  lineHeight: '36px',
  letterSpacing: 1,
  textAlign: 'left'
};

export const tableViewRootStyle: (theme: Theme) => CSSProperties = (theme: Theme) => ({
  backgroundColor: theme.palette.secondary.dark,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
});
