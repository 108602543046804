import { ThunkAction } from "redux-thunk";
import { LeadAction } from ".";
import {
  get,
  post,
  put,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../../actions";
import { RootState } from "../../../../reducer";
import { ActionType } from "../../../../reducer/types";
import { API_URL } from "../../../../util/apiUrls";
import { Lead, LeadModalAPIStatus, LEAD_STATUS } from "../../types";
import { get as _get } from "lodash";

export const saveLeadAction = (
  lead: Lead,
  isDraft: boolean
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ON_LOADING_START });

  let apiStatus: LeadModalAPIStatus;

  try {
    lead.status = isDraft ? LEAD_STATUS.DRAFT : LEAD_STATUS.SUBMITTED_TO_SB;
    //Create Lead
    const result: Lead = lead.id
      ? await put(API_URL.LEADS.BY_ID(lead.id), lead, dispatch)
      : await post(API_URL.LEADS.CREATE, lead, dispatch);

    apiStatus = {
      status: "success",
      errors: new Map(),
    };

    if (result.status === LEAD_STATUS.QUALIFIED) {
      await post(API_URL.LEADS.SUBMIT_TO_SB, result, dispatch);
    }
  } catch (e) {
    const errrosMap = new Map<string, string>();
    const errors = _get(e, "response.data.message");
    if (errors && Array.isArray(errors)) {
      errors.forEach((error: any) => {
        errrosMap.set(error.field, error.message);
      });
    }
    apiStatus = {
      status: "error",
      errors: errrosMap,
    };
  }

  dispatch({
    type: LeadAction.SET_LEADS_MODAL_API_STATUS,
    payload: apiStatus,
  });
  dispatch({ type: ON_LOADING_FINISHED });
};

//TODO: Remove from this file after adding "Submit to SB" from poupup
export const submitToSBAction = (
  lead: Lead
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ON_LOADING_START });
  await post(API_URL.LEADS.SUBMIT_TO_SB, lead, dispatch);
  dispatch({ type: ON_LOADING_FINISHED });
};
