import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { makeStyles, createStyles } from '@material-ui/styles';
import { colors, Theme } from '@material-ui/core';
import moment from 'moment';
import deleteIcon from './assets/file_delete_icon.png';
import downloadIcon from './assets/file_download_icon.png';
import SBText from '../SBText';
import CommenErrorDialog from '../../CommenErrorDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: 'flex', flexDirection: 'row' },
    dropZone: {
      border: `1px solid #727487`,
      // paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(2),
      // paddingLeft: theme.spacing(2),

      outline: 'none',
      display: 'flex',
      borderRadius: 5,
      height: 45,
      backgroundColor: '#FCFBFB',
      flex: 1,
      width: 'calc(100% - 90px)',
      [theme.breakpoints.up(1366)]: {
        width: 'calc(100% - 110px)',
      },
      // flexWrap: "wrap",
      alignItems: 'center',
      '&:hover': {
        backgroundColor: colors.grey[50],
        opacity: 0.5,
        cursor: 'pointer',
      },
    },
    one: {
      border: `1px solid #727487`,
      // paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(2),
      // paddingLeft: theme.spacing(2),

      outline: 'none',
      display: 'flex',
      borderRadius: 5,
      height: 100,
      backgroundColor: '#FCFBFB',
      flex: 1,

      // flexWrap: "wrap",
      alignItems: 'center',
      '&:hover': {
        backgroundColor: colors.grey[50],
        opacity: 0.5,
        cursor: 'pointer',
      },
    },
    disbleDropZone: {
      opacity: 0.3,
    },
    dragActive: {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      width: '85%',
    },
    image: {
      width: 40,
      height: 40,
    },
    info: {
      // marginTop: theme.spacing(0),
      fontSize: 14,
      letterSpacing: 0.75,
    },
    fileUpload: {
      height: '100px !important',
      border: '1px solid black !important',
    },
    list: {
      maxHeight: 320,
    },
    label: {
      textAlign: 'left',
      color: theme.palette.primary.light,
      display: 'flex',
    },
    actions: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    buttonParent: {
      height: 45,
      minWidth: 50,
      // [theme.breakpoints.up(1366)]: {
      //   width: 110,
      // },
      display: 'flex',
      alignItems: 'center',
      // justifyContent: "space-between",
      // paddingLeft: 10,
    },
    deleteButton: {
      height: 40,
      width: 40,
      [theme.breakpoints.up(1366)]: {
        height: 45,
        width: 45,
        marginLeft: 10,
      },
      display: 'flex',
      backgroundColor: '#D27F65',
      borderRadius: 5,
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 5,
    },
    downloadButton: {
      height: 40,
      width: 40,
      marginLeft: 10,
      [theme.breakpoints.up(1366)]: {
        height: 45,
        width: 45,
        marginLeft: 10,
      },
      display: 'flex',
      backgroundColor: '#DEDEDE',
      borderRadius: 5,
      alignItems: 'center',
      justifyContent: 'center',
    },

    downloadDisableButton: {
      height: 40,
      width: 40,
      marginLeft: 5,
      [theme.breakpoints.up(1366)]: {
        height: 45,
        width: 45,
        marginLeft: 10,
      },
      display: 'flex',
      backgroundColor: '#DEDEDE',
      borderRadius: 5,
      alignItems: 'center',
      opacity: 0.5,
      justifyContent: 'center',
    },

    buttonImage: { height: 20, objectFit: 'contain' },
    buttonDisableImage: { height: 20, objectFit: 'contain', opacity: 0.5 },
    placeHolderParent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 10,
      flex: 1,
      // width: "-moz-min-content",
      width: '100%',
    },
    placeHolderText: {
      color: '#596D79',
      fontSize: 12,
      [theme.breakpoints.up(1366)]: {
        fontSize: 13,
      },
      [theme.breakpoints.up(1440)]: {
        fontSize: 14,
      },
    },
    placeHolderBrowsText: {
      color: '#3576CB',
      fontSize: 12,
      [theme.breakpoints.up(1366)]: {
        fontSize: 13,
      },
      [theme.breakpoints.up(1440)]: {
        fontSize: 14,
      },
    },
    fileName: {
      color: '#000000',
      fontSize: 14,
      textAlign: 'left',
      // maxWidth: 300,
      whiteSpace: 'nowrap',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
    },
  })
);
type SBSimpleFileSelectorProps = {
  className?: string;
  // onUploadFile: (file: any) => void;
  // onRemoveFile?: () => void;
  // uploadError?: string;
  setUploadError: (error: string) => void;
  // fileLoadPercentage: number;
  onFileSelected: (file: any) => void;
  isUploading?: boolean;
  multiselect?: boolean;
  title?: string;
  disable?: boolean;
  onDeleteFile: () => void;
  // size: string;
  // questionObj?: any;
  value: string;
  // onDownloadFile: () => void;
  [x: string]: any;
  // parentForm?: any;
  fileNameExtension: string;
  // outcomeObj?: any;
  // applicationId?: any;
  showDownload: boolean;
};
const SBSimpleFileSelector: React.FC<SBSimpleFileSelectorProps> = ({
  // fileLoadPercentage,
  setUploadError,
  onFileSelected,
  // uploadError,
  className,
  // onUploadFile,
  isUploading,
  // onRemoveFile,
  multiselect,
  title,
  disable,
  onDeleteFile,
  // size,
  // questionObj,
  // onDownloadFile,
  value,
  // parentForm,
  fileNameExtension,
  // outcomeObj,
  // applicationId,
  showDownload,
  ...rest
}) => {
  const classes = { ...useStyles(), ...(rest.styles?.() || {}) };
  // const [files, setFiles] = useState<any>([]);
  const [errorDialog, setErrorDialog] = useState<any>(false);
  const [downloadFileStatus, setDownloadFileStatus] = useState(false);
  // const [FileObj, setFileObj] = useState(
  //   questionObj ? questionObj : outcomeObj
  // );

  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.lenght > 0 && acceptedFiles[0].name.includes('#')) {
        setErrorDialog(true);
        return;
      }

      let renamedAcceptedFiles = [];
      renamedAcceptedFiles = acceptedFiles.map(
        (file: any) =>
          new File([file], `${fileNameExtension}_${new Date().getTime()}#${file.name}`, {
            type: file.type,
          })
      );
      //console.log("Files >>>>>", acceptedFiles);

      // onUploadFile(renamedAcceptedFiles);
      onFileSelected(renamedAcceptedFiles[0]);
      // onFileAdded(acceptedFiles);
      setUploadError('');
    },
    [setUploadError]
  );

  const onFileAdded = (files: any) => {};

  // const handleRemoveAll = () => {
  //   setFiles([]);
  //   if (onRemoveFile) {
  //     onRemoveFile();
  //   }
  // };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: rest.accept ? rest.accept : '*',
  });

  const onDeletePress = () => {
    // const fileList = _.cloneDeep(files);
    // fileList.splice(0, 1);
    // setFiles(fileList);
    onDeleteFile();
  };

  return (
    <>
      {rest.labelText ? <SBText text={rest.labelText} className={clsx(classes.label, rest.labelStyle)} /> : null}
      <div
        {...rest}
        className={clsx(
          classes.root,
          // className,
          disable ? classes.disbleDropZone : null
        )}
      >
        <div
          className={clsx({
            [rest.largeBox ? classes.one : classes.dropZone]: true,

            [classes.dragActive]: isDragActive,
          })}
          {...getRootProps()}
        >
          <input
            {...getInputProps({
              disabled: disable,
            })}
          />
          <div className={classes.placeHolderParent}>
            {!value ? (
              <>
                <SBText text={'Drop files here or'} className={classes.placeHolderText} />
                <div style={{ width: 5 }} />
                <SBText text={'browse'} className={classes.placeHolderBrowsText} />
              </>
            ) : (
              <SBText text={value} className={classes.fileName} />
            )}
          </div>
        </div>
        <div className={classes.buttonParent}>
          <div className={classes.deleteButton} onClick={() => onDeletePress()}>
            <img src={deleteIcon} className={classes.buttonImage} />
          </div>

          {showDownload ? (
            <div
              className={classes.downloadButton}
              // onClick={() => onDownloadFile(FileObj)}
            >
              <img src={downloadIcon} className={classes.buttonImage} />
            </div>
          ) : null}
        </div>
      </div>
      <CommenErrorDialog
        open={errorDialog}
        onSubmit={() => setErrorDialog(false)}
        message={'File name is not valid, File name should not contain "#" character'}
        header={'Error'}
      />
    </>
  );
};

export default SBSimpleFileSelector;
