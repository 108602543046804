import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  Paper,
  TableHead,
  Tooltip,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router";
import SBText from "../../../components/base/SBText";
import { encryptText } from "../../../util";
import { hasPermission, PERMISSION } from "../../../util/rolePermissionsUtil";
import { LABELS } from "../../../util/labels";
import { ENTITY_TYPE_NAMES } from "../../../util/constants";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  padding: {},
  paddingHeader: { backgroundColor: "pink" },
  paddingButtonParent: { backgroundColor: "pink" },
  paddingButton: {
    paddingLeft: 0,
    paddingRight: 0,
    // backgroundColor: "pink",
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 0,
    alignItems: "center",
    // backgroundColor: "pink",
    alignSelf: "center",
  },
  TableRow: {
    backgroundColor: "#efefef",
    fontWeight: 500,
  },
  TableBodyRow: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  HeaderPadding: {
    fontWeight: 500,
    fontSize: "13px",
  },
  bodyText: {
    fontSize: "13px",
  },
  tableParents: {
    display: "flex",
    flex: 1,
    // overflow: "scroll",
    height: "100%",
    overflow: 'hidden'
  },

  mainLayout: {
    display: "flex",
    flex: 1,
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.117647) 2px 4px 6px 4px",
    padding: 16,
    // overflow: "scroll",
    height: "100%",
    overflow: 'hidden'
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#4c4c4c",
  },
  tooltip: {
    backgroundColor: "#4c4c4c",
  },
}));

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

type RoleTableProps = {
  data: any;
  handlePageChange: any;
  newPage: number;
  userPermissions: PERMISSION[];
  setNewPage: (value: number) => void;
  onItemClick: (item: any) => void;
};
// export default function RoleTable() {

const RoleTable: React.FC<RoleTableProps> = ({
  data,
  handlePageChange,
  newPage,
  userPermissions,
  setNewPage,
  onItemClick,
  ...rest
}) => {
  const classes = useStyles();
  const ref = useRef<number>();
  const handleScroll = (e: any) => {
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      ref.current = newPage;
      let pageNumber;
      if (data.length) {
        pageNumber = ref.current + 1;
      } else {
        pageNumber = ref.current;
      }
      handlePageChange(pageNumber);
      setNewPage(pageNumber);
    }
  };

  const getPermissionStr = (role: any): string => {
    const permissions = role.permissions || {};
    return permissions.map((permission: any) => permission.name).join(', ')
  }

  return (
    <div className={classes.mainLayout}>
      <div className={classes.tableParents}>
        <TableContainer
          onScroll={handleScroll}
          component={Paper}
        >
          <Table className={classes.table} stickyHeader>
            <TableHead className={classes.TableRow}>
              <TableRow>
                <TableCell align="left">
                  <SBText
                    text="Role Name"
                    className={classes.HeaderPadding}
                  />
                </TableCell>
                <TableCell align="left" className={classes.padding}>
                  <SBText text="Role Description" className={classes.HeaderPadding} />
                </TableCell>
                <TableCell align="left" className={classes.padding}>
                  <SBText text={LABELS.ENTITY_TYPE} className={classes.HeaderPadding} />
                </TableCell>
                <TableCell align="left" className={classes.padding}>
                  <SBText text="Role Permissions" className={classes.HeaderPadding} />
                </TableCell>
               <TableCell align="center" className={classes.padding}>
                  
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((role: any) => (
                <TableRow
                  key={role.id}
                  style={{
                    alignItems: "center",
                  }}
                >
                  <TableCell component="th">
                    <SBText
                      text={role.name}
                      className={classes.bodyText}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.padding}>
                    <SBText
                      text={role.description}
                      className={classes.bodyText}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.padding}>
                    <SBText
                      text={ENTITY_TYPE_NAMES[role.entityType] || ""}
                      className={classes.bodyText}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.padding}>
                  
                    <SBText
                      text={getPermissionStr(role)}
                      className={classes.bodyText}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.padding}>
                    { hasPermission(PERMISSION.user_roles_edit, userPermissions) && 
                      <BootstrapTooltip title="Details">
                        <div onClick={(event) => onItemClick(role)}>
                          <VisibilityIcon />
                        </div>
                      </BootstrapTooltip>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default RoleTable;
