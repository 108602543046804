import { ThunkAction } from "redux-thunk";
import {
  post,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../../actions";
import { RootState } from "../../../../reducer";
import { ActionType } from "../../../../reducer/types";
import { API_URL } from "../../../../util/apiUrls";
import { LpoAction } from "./action-types";

export const getLPODashboardData = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch, getState) => {
  dispatch({ type: ON_LOADING_START });

  const result = await post(API_URL.LPO.DASHBOARD, dispatch);

  await dispatch({
    type: LpoAction.SET_LPO_DASHBOARD_DETAILS_DATA,
    payload: result,
  });
  dispatch({ type: ON_LOADING_FINISHED });
};
