import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/styles";
import {
  Grid,
  Theme,
  TextField,
  GridSpacing,
  TablePagination,
  Drawer,
  Button,
  Divider,
  Menu,
  Fade,
  MenuItem,
  
} from "@material-ui/core";
import _ from "lodash";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../reducer";
import { getFormattedApplicationList } from "../../../selector/applicationList";
import {
  getApplicationFilters,
  setSBApplicationFilter,
  getApplicationList,
  clearSBApplicationFilter,
  getCourseListByProviderId
} from "../../../actions";
import SBButton from "../../../components/base/SBButton";
import CloseIcon from "@material-ui/icons/Close";
import AppColor from "../../../theme/AppColor";
import SBFilterMultiselectDropdown from "../../../components/base/SBFilterMultiselectDropdown";
import SBText from "../../../components/base/SBText";
import {
  hasOneOfPermissions,
  hasPermission,
  PERMISSION,
} from "../../../util/rolePermissionsUtil";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useUserPermissions } from "../../../hooks/useUserPermissions";
type PropsFromRedux = ConnectedProps<typeof connector>;
type SBApplicationListProps = PropsFromRedux & {
  filterOpen: boolean;
  onClose: Function;
  tabString: string;
  onApplyFilter: Function;
  onClearFilter: Function;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: { marginTop: 20 },
    drawerRoot: {
      width: 360,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    filterButtonParent: {
      display: "flex",
      flexDirection: "column",
      padding: 20,
      justifyContent: "space-between",
    },
    clearButton: {
      backgroundColor: "white",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: AppColor.appBlue,
      "&:hover": {
        backgroundColor: "#fafafa",
      },
      color: AppColor.appBlue,
    },
    headerText: {
      fontSize: 14,
      fontWeight: 600,
      marginTop: 30,
      marginBottom: 5,
      color: AppColor.appBlue,
      [theme.breakpoints.up(1920)]: {
        fontSize: 16,
      },
    },
    label: {
      textAlign: "left",
      color: "#736F6F",
      marginTop: theme.spacing(2),
    },
    filterButtonText: {
      fontWeight: 400,
      letterSpacing: "0.75px",
      textTransform: "none",
      fontSize: 14,
    },
    sort: {
      // width: 260,
      borderRadius: 5,
      display: "flex",
      height: 45,
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: "#FCFBFB",
      border: "1px solid #3E4059",
    },
  })
);
const ApplicationFilter: React.FC<SBApplicationListProps> = ({
  filterOpen,
  onClose,
  getApplicationFilters,
  sbApplicationFilterList,
  sbApplicationFilter,
  tabString,
  onApplyFilter,
  onClearFilter,
  setSBApplicationFilter,
  childAgentList,
  getCourseListByProviderId,
  user,
}) => {
  const classes = useStyles();
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [currentFilter, setCurrentFilter] = React.useState(sbApplicationFilter);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userAncherEL, setUserAnchorEl] = React.useState(null);
  const [countryAnchorEl, setcountryAnchorEL] = React.useState(null);
  const [buttonWidth, setButtonWidth] = React.useState<number | undefined>(
    undefined
  );
  // useEffect(() => {
  //   if (!sbApplicationFilterList) {
  //     getApplicationFilters();
  //   }
  // }, []);

  useEffect(() => {
    setCurrentFilter(sbApplicationFilter);
  }, [sbApplicationFilter]);

  const onSetApplicationFilter = (key: string, value: any) => {
    const tempFilter = _.cloneDeep(currentFilter);
    tempFilter[key] = value;
    setCurrentFilter(tempFilter);
    if(key == "provider") {
      if(tempFilter.provider && tempFilter.provider.length>0) {
        const providerId: number[] = _.map(tempFilter.provider, (obj: any) => obj.id);
        getCourseListByProviderId(providerId)
      }
    }
  };

  const getValues = (key: string) => {
    const valueArray: any = [];
    // if (currentFilter) {
    //   for (let i = 0; i < currentFilter[key].length; i++) {
    //     valueArray.push(currentFilter[key][i].value);
    //   }
    // }
    return valueArray;
  };

  // console.log("sbApplicationFilter", sbApplicationFilter);

  const getApplicationStatus = (statusList: any) => {
    let selectedStatusArray = [];
    if (tabString == "Open") {
      selectedStatusArray = _.filter(statusList, (obj: any) => {
        return (
          obj.value == "Open" ||
          obj.value == "UnderReviewPartner" ||
          obj.value == "UnderReviewUni"
        );
      });
    } else {
      selectedStatusArray = _.filter(statusList, (obj: any) => {
        return obj.value == tabString;
      });
    }
    // console.log("selectedStatusArray", selectedStatusArray);
    return selectedStatusArray;
  };

  const onClearChangeFilter = async () => {
    onClose();
    onClearFilter();
  };

  const onChangeFilterApply = () => {
    onApplyFilter(currentFilter);
    onClose();
  };

  const hasPerm = (perm: PERMISSION[]) => {
    return hasOneOfPermissions(perm, userPermissions);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCountryClick = (event: any) => {
    setcountryAnchorEL(event.currentTarget);
  };

  const onSelectedAssign = async (obj: any) => {
    const tempFilter = _.cloneDeep(currentFilter);
    tempFilter["counsellor"] = obj;
    setCurrentFilter(tempFilter);
    setcountryAnchorEL(null);
    setAnchorEl(null);
  };

  const onCountrySelected = async (obj: any) => {
    const tempFilter = _.cloneDeep(currentFilter);
    tempFilter["country"] = obj;
    tempFilter.provider = []
    tempFilter.outcomes = []
    tempFilter.subStatus = []
    tempFilter.intakes = []
    // tempFilter.applicationStatus = []
    setCurrentFilter(tempFilter);
    await getApplicationFilters(obj.id);
    setcountryAnchorEL(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setUserAnchorEl(null);
    setcountryAnchorEL(null);
  };

  return (
    <Drawer anchor={"right"} open={filterOpen} onClose={() => onClose()}>
      <div className={classes.drawerRoot}>
        <div style={{ paddingTop: 20 }}>
          <Button onClick={() => onClose()}>
            <CloseIcon /> Close
          </Button>

          <div style={{ paddingLeft: 20, paddingRight:20 }}>

          <SBText text={"By Status"} className={classes.headerText} />
            <Divider />
            <SBFilterMultiselectDropdown
              value={currentFilter.applicationStatus}
              options={
                sbApplicationFilterList &&
                sbApplicationFilterList.applicationStatus
                  ? sbApplicationFilterList.applicationStatus
                  : []
              }
              labelText={"Status"}
              className={classes.dropdown}
              onDataChange={(value: any) =>
                onSetApplicationFilter("applicationStatus", value)
              }
            />


            {hasPerm([PERMISSION.applications_agent_view_org]) ? (
              <>
                <SBText
                  text={"By Assigned/Unassigned Status"}
                  className={classes.headerText}
                />
                <Divider />

                <SBText
                  text={"Counsellor"}
                  className={classes.label}
                  color={"#736F6F"}
                />
                <Button
                  className={classes.sort}
                  onClick={handleClick}
                  fullWidth={true}
                  ref={(el: any) => {
                    if (!el) return;
                    // make sure we only update chart dimensions on certain conditions (otherwise will trigger infinite re-renders)
                    setButtonWidth(
                      Math.round(el.getBoundingClientRect().width)
                    );
                  }}
                >
                  <SBText
                    text={
                      currentFilter && currentFilter.counsellor
                        ? currentFilter.counsellor.name
                        : ""
                    }
                    className={classes.filterButtonText}
                  />
                  <ExpandMoreIcon />
                </Button>
              </>
            ) : null}

            {hasPerm([
              PERMISSION.applications_agent_view_org,
              PERMISSION.applications_agent_view_own,
            ]) ? (
              <>
                <SBText
                  text={"By Country/Provider/Term"}
                  className={classes.headerText}
                />

                <>
                  <SBText
                    text={"Country"}
                    className={classes.label}
                    color={"#736F6F"}
                  />
                  <Button
                    className={classes.sort}
                    onClick={handleCountryClick}
                    fullWidth={true}
                  >
                    <SBText
                      text={
                        currentFilter && currentFilter.country
                          ? currentFilter.country.value
                          : ""
                      }
                      className={classes.filterButtonText}
                    />
                    <ExpandMoreIcon />
                  </Button>

                  <SBFilterMultiselectDropdown
                    value={currentFilter.provider}
                    options={
                      sbApplicationFilterList &&
                      currentFilter?.country &&
                      sbApplicationFilterList.providers
                        ? sbApplicationFilterList.providers
                        : []
                    }
                    labelText={"Providers"}
                    className={classes.dropdown}
                    onDataChange={(value: any) =>
                      onSetApplicationFilter("provider", value)
                    }
                  />

                  <SBFilterMultiselectDropdown
                  value={currentFilter.course}
                  options={
                    sbApplicationFilterList && sbApplicationFilterList.course && currentFilter?.country && currentFilter?.provider
                      ? sbApplicationFilterList.course
                      : []
                  }
                  labelText={'Course'}
                  className={classes.dropdown}
                  onDataChange={(value: any) => onSetApplicationFilter('course', value)}
                />
                </>

                <SBFilterMultiselectDropdown
                  value={currentFilter.intakes}
                  options={
                    sbApplicationFilterList &&
                    currentFilter?.country &&
                    sbApplicationFilterList.intakes
                      ? sbApplicationFilterList.intakes
                      : []
                  }
                  labelText={"Term"}
                  className={classes.dropdown}
                  onDataChange={(value: any) =>
                    onSetApplicationFilter("intakes", value)
                  }
                />
              </>
            ) : null}

            <SBText
              text={"By Application/Outcome Status"}
              className={classes.headerText}
            />
            <Divider />

            <SBFilterMultiselectDropdown
              value={currentFilter.subStatus}
              options={
                sbApplicationFilterList &&
                sbApplicationFilterList.applicationSubStatus &&
                currentFilter?.country
                  ? sbApplicationFilterList.applicationSubStatus
                  : []
              }
              labelText={"Sub Status"}
              className={classes.dropdown}
              onDataChange={(value: any) =>
                onSetApplicationFilter("subStatus", value)
              }
            />

            <SBFilterMultiselectDropdown
              value={currentFilter.outcomes}
              options={
                sbApplicationFilterList &&
                currentFilter?.country &&
                sbApplicationFilterList.outcomes
                  ? sbApplicationFilterList.outcomes
                  : []
              }
              labelText={"Outcome"}
              className={classes.dropdown}
              onDataChange={(value: any) =>
                onSetApplicationFilter("outcomes", value)
              }
            />
          </div>
        </div>

        <div className={classes.filterButtonParent}>
          <SBButton
            text={"Apply filter"}
            icon={false}
            onClick={() => onChangeFilterApply()}
          />
          <div style={{ height: 20 }}></div>
          <SBButton
            text={"Clear filter"}
            icon={false}
            onClick={() => onClearChangeFilter()}
            className={classes.clearButton}
          />
        </div>
      </div>

      {sbApplicationFilterList ? (
        <Menu
          id="fade-menu"
          anchorEl={countryAnchorEl}
          keepMounted
          open={countryAnchorEl ? true : false}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
        >
          {sbApplicationFilterList.country.map((obj: any, i: number) => (
            <MenuItem
              onClick={(event) => {
                onCountrySelected(obj);
              }}
              style={{
                padding: 0,
              }}
              value={obj.value}
            >
              <div
                style={{
                  display: "flex",
                  height: 40,
                  alignItems: "center",
                  width: 260,
                  justifyContent: "space-between",
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <SBText text={obj.value} />
              </div>
            </MenuItem>
          ))}
        </Menu>
      ) : null}

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl ? true : false}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            width: buttonWidth ? buttonWidth : 260,
            marginTop: 10,
            maxHeight: 500,
          },
        }}
      >
        <MenuItem
          onClick={(event) => {
            // onSelectedAssign(name);
            onSelectedAssign(user.user);
          }}
          style={{
            padding: 0,
          }}
          value={user.user.name}
        >
          <div
            style={{
              display: "flex",
              height: 50,
              justifyContent: "center",
              width: buttonWidth ? buttonWidth : 260,
              paddingLeft: 10,
              paddingRight: 10,
              flexDirection: "column",
            }}
          >
            <SBText text={"Self"} style={{ color: AppColor.appBlue }} />
            <SBText
              text={user.user.email}
              style={{ fontSize: 10, marginTop: -5, color: "#736F6F" }}
            />
          </div>
        </MenuItem>

        {childAgentList.map((userObj: any, i: number) => (
          <MenuItem
            onClick={(event) => {
              // onSelectedAssign(name);
              onSelectedAssign(userObj);
            }}
            style={{
              padding: 0,
            }}
            value={userObj.name}
          >
            <div
              style={{
                display: "flex",
                height: 50,
                justifyContent: "center",
                width: buttonWidth ? buttonWidth : 260,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: "column",
              }}
            >
              <SBText text={userObj.name} style={{ color: AppColor.appBlue }} />
              <SBText
                text={userObj.email}
                style={{ fontSize: 10, marginTop: -5, color: "#736F6F" }}
              />
            </div>
          </MenuItem>
        ))}
      </Menu>

      {/*{list(anchor)}*/}
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  // @ts-ignore
  sbApplicationFilterList: state.sbApplication.sbApplicationFilterList,
  // @ts-ignore
  sbApplicationFilter: state.sbApplication.sbApplicationFilter,
  // @ts-ignore
  childAgentList: state.sbApplication.childAgentList,
  user: state.user.user,
});

const mapDispatch = {
  getApplicationFilters,
  setSBApplicationFilter,
  getApplicationList,
  clearSBApplicationFilter,
  getCourseListByProviderId
};

const connector = connect(mapState, mapDispatch);
export default connector(ApplicationFilter);
