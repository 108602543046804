import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Grid } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import TabPanel from "./TabPanel/TabPanel";

import { useFetchRefreeDetails } from "../../hooks/useFetchRefreeDetails";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      position: "absolute",
      width: 596,
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      width: "100%",
      backgroundColor: "#5C5F74",
      padding: "9px 28px",
      color: "white",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
    },
    body: {
      backgroundColor: "white",
      padding: "20px 40px",
    },
    icon: {
      fontSize: "16px",
      cursor: "pointer",
      fontWeight: 500,
    },
  })
);

interface ReferenceCheckModalProps {
  agentId: number;
  onModalCloseClick: any;
}

const ReferenceCheckModal: React.FC<ReferenceCheckModalProps> = ({
  agentId,
  onModalCloseClick,
}) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const { fetchRefreeDetails } = useFetchRefreeDetails();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const { refreeReducer } = useSelector((state: any) => state);

  useEffect(() => {
    fetchRefreeDetails(agentId);
  }, [fetchRefreeDetails]);

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const body = (
    <div className={classes.paper}>
      <div className={classes.header}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12} xl={6} lg={6}>
            Reference Check
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            xl={6}
            lg={6}
            style={{ textAlign: "right" }}
          >
            <CloseIcon
              className={classes.icon}
              onClick={() => onModalCloseClick()}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.body}>
        <AppBar position="static" color="default" style={{ boxShadow: "none" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {refreeReducer?.refree?.length >= 1 && (
              <Tab
                label="Referee 1"
                icon={
                  refreeReducer.refree && refreeReducer.refree[0] ? (
                    refreeReducer.refree[0].isApproved == null ? (
                      ""
                    ) : refreeReducer.refree[0].isApproved == true ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                      <CancelIcon style={{ color: "red" }} />
                    )
                  ) : (
                    ""
                  )
                }
                {...a11yProps(0)}
              />
            )}
            {refreeReducer?.refree?.length >= 2 && (
              <Tab
                label="Referee 2"
                icon={
                  refreeReducer.refree && refreeReducer.refree[1] ? (
                    refreeReducer.refree[1].isApproved == null ? (
                      ""
                    ) : refreeReducer.refree[1].isApproved == true ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                      <CancelIcon style={{ color: "red" }} />
                    )
                  ) : (
                    ""
                  )
                }
                {...a11yProps(1)}
              />
            )}
            {refreeReducer?.refree?.length >= 3 && (
              <Tab
                label="Referee 3"
                icon={
                  refreeReducer.refree && refreeReducer.refree[1] ? (
                    refreeReducer.refree[2].isApproved == null ? (
                      ""
                    ) : refreeReducer.refree[2].isApproved == true ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                      <CancelIcon style={{ color: "red" }} />
                    )
                  ) : (
                    ""
                  )
                }
                {...a11yProps(2)}
              />
            )}
          </Tabs>
        </AppBar>

        {value == 0 && (
          <TabPanel
            agentId={agentId}
            refreeId={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].id
                  : ""
                : ""
            }
            name={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].name
                  : ""
                : ""
            }
            institute={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].educationInstitution
                  : ""
                : ""
            }
            jobTitle={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].jobTitle
                  : ""
                : ""
            }
            country={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].country
                  : ""
                : ""
            }
            workEmail={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].email
                  : ""
                : ""
            }
            phone={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].phone
                  : ""
                : ""
            }
            yearsOfAgreement={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].agreementYears
                  : ""
                : ""
            }
            isApproved={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].isApproved
                  : ""
                : ""
            }
            approvedBy={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].approvedBy
                  : ""
                : ""
            }
            attachmentName={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].attachmentName
                  : ""
                : ""
            }
            approvalComment={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].approvalComment
                  : ""
                : ""
            }
            fileKey={
              refreeReducer.refree
                ? refreeReducer.refree[0]
                  ? refreeReducer.refree[0].attachmentKey
                  : ""
                : ""
            }
            approveDisapproveTimeStamp={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].updatedAt
                  : ""
                : ""
            }
          />
        )}

        {value == 1 && (
          <TabPanel
            agentId={agentId}
            refreeId={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].id
                  : ""
                : ""
            }
            name={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].name
                  : ""
                : ""
            }
            institute={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].educationInstitution
                  : ""
                : ""
            }
            jobTitle={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].jobTitle
                  : ""
                : ""
            }
            country={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].country
                  : ""
                : ""
            }
            workEmail={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].email
                  : ""
                : ""
            }
            phone={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].phone
                  : ""
                : ""
            }
            yearsOfAgreement={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].agreementYears
                  : ""
                : ""
            }
            isApproved={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].isApproved
                  : ""
                : ""
            }
            approvedBy={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].approvedBy
                  : ""
                : ""
            }
            attachmentName={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].attachmentName
                  : ""
                : ""
            }
            approvalComment={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].approvalComment
                  : ""
                : ""
            }
            fileKey={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].attachmentKey
                  : ""
                : ""
            }
            approveDisapproveTimeStamp={
              refreeReducer.refree
                ? refreeReducer.refree[1]
                  ? refreeReducer.refree[1].updatedAt
                  : ""
                : ""
            }
          />
        )}

        {value == 2 && (
          <TabPanel
            agentId={agentId}
            refreeId={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].id
                  : ""
                : ""
            }
            name={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].name
                  : ""
                : ""
            }
            institute={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].educationInstitution
                  : ""
                : ""
            }
            jobTitle={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].jobTitle
                  : ""
                : ""
            }
            country={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].country
                  : ""
                : ""
            }
            workEmail={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].email
                  : ""
                : ""
            }
            phone={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].phone
                  : ""
                : ""
            }
            yearsOfAgreement={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].agreementYears
                  : ""
                : ""
            }
            isApproved={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].isApproved
                  : ""
                : ""
            }
            approvedBy={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].approvedBy
                  : ""
                : ""
            }
            attachmentName={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].attachmentName
                  : ""
                : ""
            }
            approvalComment={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].approvalComment
                  : ""
                : ""
            }
            fileKey={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].attachmentKey
                  : ""
                : ""
            }
            approveDisapproveTimeStamp={
              refreeReducer.refree
                ? refreeReducer.refree[2]
                  ? refreeReducer.refree[2].updatedAt
                  : ""
                : ""
            }
          />
        )}
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        className={classes.modal}
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default ReferenceCheckModal;
