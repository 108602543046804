import { FetchLeadsParams,FetchTlLeadsParams } from "../types";

export const getLeadsRequestQuery = (fetchLeadsParams: FetchLeadsParams) => {
  const { limit, offset, search } = fetchLeadsParams;
  return `?limit=${limit}&offset=${offset}&search=${search}`;
};
export const getTlLeadsRequestQuery = (fetchLeadsParams: FetchTlLeadsParams) => {
  const { limit, offset,taName,taEmail,originOfLead,destinationOfLead,status } = fetchLeadsParams;
  return `?offset=${offset}&limit=${limit}&taName=${taName}&taEmail=${taEmail}&originOfLead=${originOfLead}&destinationOfLead=${destinationOfLead}&status=${status}`;
};
export const getDownloadLeadsRequestQuery = (fetchLeadsParams: FetchTlLeadsParams) => {
  const { taName,taEmail,originOfLead,destinationOfLead,status } = fetchLeadsParams;
  return `?taName=${taName}&taEmail=${taEmail}&originOfLead=${originOfLead}&destinationOfLead=${destinationOfLead}&status=${status}`;
};
