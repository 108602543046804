import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from "../../../actions";
import { API_URL } from "../../../util/apiUrls";
import { LeadAction } from "../store/actions/action-types";

export const useFetchAllCoutrys = () => {
  const dispatch = useDispatch();

  const getCountryDetails = useCallback(
    async () => {
      
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(`${API_URL.LEADS.ALL_COUNTRYS}/country/getAllCountry`, {}, dispatch);

        dispatch({
          type: LeadAction.SET_DESTINATION_COUNTRYS,
          payload: result,
        });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    getCountryDetails,
  };
};
