import { StatusViewItem } from "../../../components/StatusChip/status-type";

export enum LEAD_STATUS {
  DRAFT = 'draft',
  QUALIFIED = 'qualified',
  SUBMITTED_TO_SB = 'submittedToSB',
  OPEN = 'Open',
  CLOSED = 'Closed',
  REJECTED = 'Rejected',
  WITHDRAWN = 'Withdrawn',
  DOCUMENT_RECEIVED='DocumentReceived',
  SENT_FOR_ASSESSMENT='SentForAssessment',
  PENDING_DOCS='PendingDocs',
  APPLICATION_SUBMITTED='ApplicationSubmitted',
  OFFER_LETTER_RECEIVED='OfferLetterReceived',
  PAYMENT_MADE='PaymentMade',
  VISA_RECEIVED='VisaReceived',
}

export type LeadStatusViewItem = StatusViewItem & {};

export type LeadStatusDetailsType = {
  [key in LEAD_STATUS]: LeadStatusViewItem;
};

export const LeadStatusDetails: any = {
  [LEAD_STATUS.DRAFT]: {
    label: "Draft",
    color: "#7B8997",
  },
  [LEAD_STATUS.QUALIFIED]: {
    label: "Qualified for Application",
    color: "#3D405B",
  },
  [LEAD_STATUS.SUBMITTED_TO_SB]: {
    label: "Submitted to SB",
    color: "#3E405B",
  },
  [LEAD_STATUS.OPEN]: {
    label: "Application Open",
    color: "#ECCC97",
  },
  [LEAD_STATUS.CLOSED]: {
    label: "Application Closed",
    color: "#8BB19C",
  },
  [LEAD_STATUS.REJECTED]: {
    label: "Application Rejected",
    color: "#CF4E0B",
  },
  [LEAD_STATUS.WITHDRAWN]: {
    label: "Application Withdrawn",
    color: "#FF6632",
  },
  [LEAD_STATUS.DOCUMENT_RECEIVED]: {
    label: "Application submitted",
    color: "#FF6632",
  },
  [LEAD_STATUS.SENT_FOR_ASSESSMENT]: {
    label: "Sent for assessment",
    color: "#FF6632",
  },
  [LEAD_STATUS.PENDING_DOCS]: {
    label: "Pending Docs",
    color: "#FF6632",
  },
  [LEAD_STATUS.APPLICATION_SUBMITTED]: {
    label: "Application submitted",
    color: "#FF6632",
  },
  [LEAD_STATUS.OFFER_LETTER_RECEIVED]: {
    label: "Offer letter received",
    color: "#FF6632",
  },
  [LEAD_STATUS.PAYMENT_MADE]: {
    label: "Payment made",
    color: "#FF6632",
  },
  [LEAD_STATUS.VISA_RECEIVED]: {
    label: "Visa received",
    color: "#FF6632",
  }
};
