import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { IconButton, Theme, Tooltip, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import fileGreen from "./assets/file_green.png";
import gteGreen from "./assets/gte_green.png";
import coeOrange from "./assets/coe_orange.png";
import SBText from "../../components/base/SBText";
import { useWindowSize } from "../../util";

import moment from "moment";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    circle: {
      width: "40px",
      height: "40px",
      display: "flex",
      alignItems: "center",
      borderRadius: "25px",
      justifyContent: "center",
      backgroundColor: "#EBEBEB",
    },
    mainHeader: {
      fontSize: 18,
      fontWeight: "bold",
      color: "#8BB19C",
    },
    mainHeaderOrange: {
      fontSize: 18,
      fontWeight: "bold",
      color: "#D8856C",
    },

    infoText: {
      color: "#B0B0B0",
      marginTop: 2,
      fontSize: 12,
    },
    textParent: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 10,
      justifyContent: "center",
    },
    circleRow: {
      flexDirection: "row",
      display: "flex",
      width: "100%",
    },
    dividerParent: {},
    dividerSubParent: {
      width: "40px",
      height: 30,
      display: "flex",
      paddingTop: "10px",
      paddingBottom: "10px",
      justifyContent: "center",
      [theme.breakpoints.up(1920)]: {
        // height: 30,
      },
    },
    divider: {
      width: 2,
      backgroundColor: "rgba(33, 33, 33, 0.18)",
    },
    labelGreenText: {
      fontWeight: 500,
      fontSize: 13,
      color: "#5F7468",
      lineHeight: 1.2,
    },

    labelDisabledText: {
      fontWeight: 500,
      fontSize: 13,
      color: "#5F7468",
      lineHeight: 1.2,
      opacity: 0.5,
    },

    labelGreyText: {
      color: "#B0B0B0",
      fontWeight: 500,
      fontSize: 10,
      lineHeight: "20px",
    },

    disableImage: {
      filter: "sepia(200%) saturate(0%) brightness(100%) hue-rotate(180deg)",
      opacity: 0.5,
      width: 25,
    },

    image: {
      width: 25,
    },
  })
);

type ApplicationStatusStepperProps = {
  unConditionalOfferLetter: any;
  conditional: any;
  COE: any;
  onDownloadOutcome: Function;
  outcomeMaster: any;
  data: any;
};
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#4c4c4c",
  },
  tooltip: {
    backgroundColor: "#4c4c4c",
  },
}));
function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const ApplicationStatusStepper: React.FC<ApplicationStatusStepperProps> = ({
  unConditionalOfferLetter,
  conditional,
  COE,
  onDownloadOutcome,
  outcomeMaster,
  data,
  ...rest
}) => {
  // const history = useHistory();
  const classes = useStyles();
  // const [width, height] = useWindowSize();

  // console.log("outcomeMaster", outcomeMaster);

  // const outcomes = data.outcomes;
  //
  // const unConditionalOfferLetter = _.find(
  //   outcomes,
  //   (obj: any) => obj.outcome == "UnConditionalOffer"
  // );
  //
  // // if (unConditionalOfferLetter) {
  // //   unConditionalOfferLetter.updatedAt == "2021-01-01T18:47:00.663Z";
  // // }
  //
  // const GTE = _.find(
  //   outcomes,
  //   (obj: any) => obj.outcome == "RequestForPayment"
  // );
  //
  // const COE = _.find(outcomes, (obj: any) => obj.outcome == "IssueOfCOE");

  const isOutcomeAvailable = (code: any) => {
    const available = _.find(
      data.outcomes,
      (obj: any) => obj.outcome == code && !obj.isDeleted
    );
    if (available) {
      return true;
    } else {
      return false;
    }
  };

  const getOutcome = (code: any) => {
    const available = _.find(
      data.outcomes,
      (obj: any) => obj.outcome == code && !obj.isDeleted
    );
    if (available) {
      return available;
    } else {
      return undefined;
    }
  };

  const getFromNow = (dateObj: string) => {
    try {
      let current_date: any = new Date();
      let created_date: any = new Date(dateObj);
      let diffInMilliSeconds = Math.abs(current_date - created_date) / 1000;

      const days = Math.floor(diffInMilliSeconds / 86400);
      diffInMilliSeconds -= days * 86400;
      const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
      diffInMilliSeconds -= hours * 3600;
      const minutes = Math.floor(diffInMilliSeconds / 60) % 60;

      let output: any;
      if (days === 0 && hours === 0) {
        output = `${minutes} ${minutes > 1 ? "Mins" : "Min"}`;
      }

      if (days === 0 && hours > 0) {
        output = `${hours}  ${hours > 1 ? "Hours" : "Hour"} ${minutes} ${
          minutes > 1 ? "Mins" : "Min"
        }`;
      }

      if (days > 0 && hours === 0) {
        output = `${days}  ${days > 1 ? "Days" : "Day"}`;
      }

      if (days > 0 && hours > 0) {
        output = `${days} ${days > 1 ? "Days" : "Day"} ${hours} ${
          hours > 1 ? "Hours" : "Hour"
        }`;
      }

      return output;
    } catch (error) {
      console.log(error);
      return NaN;
    }
  };

  console.log(
    outcomeMaster.length > 0 ? outcomeMaster[2].outcomeLabel : "Outcome Pending"
  );

  const outcome0 =
    outcomeMaster.length > 0 ? getOutcome(outcomeMaster[0].code) : undefined;
  const outcome1 =
    outcomeMaster.length > 0 ? getOutcome(outcomeMaster[1].code) : undefined;
  const outcome2 =
    outcomeMaster.length > 0 ? getOutcome(outcomeMaster[2].code) : undefined;

  return (
    <div className={classes.root}>
      <div className={classes.circleRow}>
        <div className={classes.circle}>
          {outcome0 ? (
            <BootstrapTooltip
              title={"Download " + outcomeMaster[0].outcomeLabel}
            >
              <IconButton onClick={() => onDownloadOutcome(outcome1)}>
                <img src={fileGreen} className={classes.image} />
              </IconButton>
            </BootstrapTooltip>
          ) : (
            <img src={fileGreen} className={classes.disableImage} />
          )}
        </div>
        <div className={classes.textParent}>
          <SBText
            text={
              outcomeMaster.length > 0
                ? outcomeMaster[0].outcomeLabel
                : "Outcome Pending"
            }
            className={
              outcome0 ? classes.labelGreenText : classes.labelDisabledText
            }
          />

          {outcome0 ? (
            <SBText
              text={`${moment(outcome0.updatedAt).format(
                "DD MMM YYYY"
              )} | ${getFromNow(conditional.updatedAt)}`}
              className={classes.labelGreyText}
            />
          ) : null}
        </div>
      </div>

      <div className={classes.dividerParent}>
        <div className={classes.dividerSubParent}>
          <div className={classes.divider}></div>
        </div>
      </div>

      <div className={classes.circleRow}>
        <div className={classes.circle}>
          {outcome1 ? (
            <BootstrapTooltip
              title={"Download " + outcomeMaster[1].outcomeLabel}
            >
              <IconButton
                onClick={() => onDownloadOutcome(unConditionalOfferLetter)}
              >
                <img src={gteGreen} className={classes.image} />
              </IconButton>
            </BootstrapTooltip>
          ) : (
            <img src={gteGreen} className={classes.disableImage} />
          )}
        </div>
        <div className={classes.textParent}>
          <SBText
            text={
              outcomeMaster.length
                ? outcomeMaster[1].outcomeLabel
                : "Outcome Pending"
            }
            className={
              outcome1 ? classes.labelGreenText : classes.labelDisabledText
            }
          />
          {outcome1 ? (
            <SBText
              text={`${moment(outcome1.updatedAt).format(
                "DD MMM YYYY HH:mm"
              )} | ${getFromNow(outcome1.updatedAt)}`}
              className={classes.labelGreyText}
            />
          ) : null}
        </div>
      </div>

      <div className={classes.dividerParent}>
        <div className={classes.dividerSubParent}>
          <div className={classes.divider}></div>
        </div>
      </div>

      <div className={classes.circleRow}>
        <div className={classes.circle}>
          {outcome2 ? (
            <BootstrapTooltip
              title={"Download " + outcomeMaster[2].outcomeLabel}
            >
              <IconButton onClick={() => onDownloadOutcome(COE)}>
                <img src={coeOrange} className={classes.image} />
              </IconButton>
            </BootstrapTooltip>
          ) : (
            <img src={coeOrange} className={classes.disableImage} />
          )}
        </div>
        <div className={classes.textParent}>
          <SBText
            text={
              outcomeMaster.length
                ? outcomeMaster[2].outcomeLabel
                : "Outcome Pending"
            }
            className={
              outcome2 ? classes.labelGreenText : classes.labelDisabledText
            }
          />
          {outcome2 ? (
            <SBText
              text={`${moment(outcome2.updatedAt).format(
                "DD MMM YYYY HH:mm"
              )} | ${getFromNow(outcome2.updatedAt)}`}
              className={classes.labelGreyText}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ApplicationStatusStepper;
