import { colors } from "@material-ui/core";

const white = "#FFFFFF";

export default {
  primary: {
    contrastText: white,
    dark: "#5C5F74",
    main: "#727487",
    light: "#727487",
  },
  secondary: {
    contrastText: white,
    dark: "#F1F1F1",
    main: "#b1aeae",
    light: "#F3F3F5",
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  link: colors.blue[800],
  icon: colors.blueGrey[600],
  common: {
    white: "#ffffff",
    black: "#000000",
    linkBlue: "#2463FF",
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  divider: colors.grey[200],
};
