import { Dialog, DialogContent, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import animationData from './assets/loaderv2.json';
import Lottie from 'lottie-react';

type DialogProps = {
  className?: string;
  open: boolean;
};

const LoadingDialog: React.FC<DialogProps> = ({ open }) => {
  return (
    <Dialog
      aria-labelledby='customized-dialog-title'
      open={open}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0.3)' } }}
    >
      <DialogContent>
        <Lottie
          autoPlay={true}
          animationData={animationData}
          loop={true}
          style={{ height: 150, width: 150 }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
