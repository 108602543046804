import { TextField, TextFieldProps, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useState } from 'react';
import SBText from '../SBText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 5,
      height: 45,
      marginBottom: 2,
      backgroundColor: '#FCFBFB',
      '&:hover': {
        borderColor: '#727487',
      },
      '&$focused': {
        // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: '#727487',
      },
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    inputDisabled: {
      opacity: 0.3,
      backgroundColor: "#F0F0F0"
    },
    label: {
      textAlign: 'left',
      color: theme.palette.primary.light,
    },
  })
);

type SBTextFieldProps = {
  className?: string;
  [x: string]: any;
  value: string;
  onDataChange?: (value: string, questionObj: any) => void;
};
const SBTextField: React.FC<SBTextFieldProps & TextFieldProps> = ({ className, value, onDataChange, ...rest }) => {
  // console.log('1',{...useStyles()})
  // console.log('2',{...styles()})
  const classes = { ...useStyles(), ...(rest.styles?.() || {}) };
  const [countryCodeLength, setCountryCodeLength] = useState(15);

  // console.log("rest.questionObj", rest.questionObj);

  const [text, setText] = useState('');
  const handleChange = (event: any) => {
    event.persist();
    if (rest.questionObj) {
      if (rest.questionObj.length) {
        const number = parseInt(rest.questionObj.length);
        if (number < event.target.value.length) {
          return;
        }
      }

      if (
        event.target.value &&
        rest.questionObj.validation &&
        rest.questionObj.validation === 'Phone' &&
        !validPhoneNumber(event.target.value)
      ) {
        return;
      }

      if (
        event.target.value &&
        rest.questionObj.validation &&
        rest.questionObj.validation === 'Number' &&
        !validateNumber(event.target.value)
      ) {
        return;
      }
    }
    // @ts-ignore
    onDataChange(event.target.value);
  };

  const validateNumber = (number: string) => {
    const re = /^(0|[1-9]\d*)$/;
    return re.test(number);
  };

  const validPhoneNumber = (number: string) => {
    const re = /^(0|[1-9]\d*)(\.\d+)?$/;
    return re.test(number);
  };

  const handleOnChange = (event: any, data: any) => {
    // const number = parseInt(rest.questionObj.length);
    // if (number < event.target.value.length) {
    //   return;
    // }

    const dialCodeLength: number = data.dialCode ? data.dialCode.length + 1 : 0;
    const eventLength = event.length;
    const enteredPhoneNumber = event.substr(dialCodeLength, eventLength);
    if (rest.questionObj && rest.questionObj.length) {
      // console.log(
      //   "rest.questionObj.length + dialCodeLength",
      //   rest.questionObj.length + dialCodeLength
      // );
      setCountryCodeLength(parseInt(rest.questionObj.length) + dialCodeLength);
    }
    // console.log("1231231", enteredPhoneNumber);

    if (enteredPhoneNumber) {
      // @ts-ignore
      onDataChange(event);
    } else {
      // @ts-ignore
      onDataChange('');
    }
  };

  // @ts-ignore
  return (
    <form autoComplete="off">
      {rest.labelText ? <SBText text={rest.labelText} className={clsx(classes.label, rest.labeStyle)} /> : null}

      {rest.questionObj && rest.questionObj.validation && rest.questionObj.validation == 'Phone' ? (
        <MuiPhoneNumber
          defaultCountry={'in'}
          onChange={handleOnChange}
          fullWidth
          variant="outlined"
          InputProps={{
            className: clsx(classes.input, className),
          }}
          inputProps={{ maxlength: countryCodeLength }}
          autoFormat={false}
          countryCodeEditable={false}
          disableAreaCodes={true}
          value={value}
        />
      ) : (
        <TextField
          variant="outlined"
          fullWidth
          InputProps={{
            className: clsx(classes.input, className, {[classes.inputDisabled]: rest.disabled}),
          }}
          value={value}
          onChange={(event) => handleChange(event)}
          {...rest}
        />
      )}
    </form>
  );
};

export default SBTextField;
