import React, { useState, useEffect, Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { RootState } from "../../reducer";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import RoleList from "../RoleList";
import EntityList from "../EntityList";
import UserList from "../UserList";
import ProviderList from "../ProviderList";
import { getPermissionList } from "../../actions";
import { LABELS } from "../../util/labels";
import { hasPermission, PERMISSION } from "../../util/rolePermissionsUtil";
import { useUserPermissions } from "../../hooks/useUserPermissions";

const ADMIN_TABS: any = {
  AGENCY: "agencies",
  PROVIDER: "providers",
  USER: "user",
  ROLE: "roles",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#F1F1F1",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    newAppBt: {
      width: 130,
      height: 50,
      borderRadius: 30,
      textTransform: "none",
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        width: 130,
        fontSize: 14,
      },
    },
    tabsParent: {
      height: 60,
      display: "flex",
      marginTop: 20,
      justifyContent: "space-between",
    },
    tabSubParents: {
      display: "flex",
      alignItems: "flex-end",
    },
    tabBasic: {
      height: 40,
      width: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tabSelected: {
      height: 50,
      width: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tabText: {
      fontWeight: 500,
      fontSize: 14,
    },
    tabDivider: { width: 10 },
  })
);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SBApplicationListProps = PropsFromRedux & {};

const Admin: React.FC<SBApplicationListProps> = ({ ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    dispatch(getPermissionList());
  }, []);

  const hasPerm = (perm: string) => {
    return hasPermission(perm, userPermissions);
  };

  const onTabChange = async (index: any) => {
    if (index === selectedTab) {
      return;
    }
    setSelectedTab(index);
  };

  const tabsConfig: { id: string; label: string }[] = [];
  if (hasPerm(PERMISSION.user_agencies_view)) {
    tabsConfig.push({ id: ADMIN_TABS.AGENCY, label: LABELS.AGENCIES });
  }
  if (hasPerm(PERMISSION.user_providers_view)) {
    tabsConfig.push({ id: ADMIN_TABS.PROVIDER, label: LABELS.PROVIDERS });
  }
  if (hasPerm(PERMISSION.user_view)) {
    tabsConfig.push({ id: ADMIN_TABS.USER, label: LABELS.USERS });
  }
  if (hasPerm(PERMISSION.user_roles_view)) {
    tabsConfig.push({ id: ADMIN_TABS.ROLE, label: LABELS.ROLES });
  }

  let tabs;
  if (tabsConfig.length > 0) {
    !selectedTab && onTabChange(tabsConfig[0].id);
    tabs = tabsConfig.map((tab: any) => (
      <Fragment key={tab.id}>
        <div
          className={
            selectedTab == tab.id ? classes.tabSelected : classes.tabBasic
          }
          onClick={() => onTabChange(tab.id)}
        >
          <Typography variant={"h5"} className={classes.tabText}>
            {tab.label}
          </Typography>
        </div>
        <div className={classes.tabDivider} />
      </Fragment>
    ));
  }

  return (
    <>
      {selectedTab === ADMIN_TABS.ROLE && (
        <RoleList>
          <div className={classes.tabSubParents}>{tabs}</div>
        </RoleList>
      )}
      {selectedTab === ADMIN_TABS.USER && (
        <UserList>
          <div className={classes.tabSubParents}>{tabs}</div>
        </UserList>
      )}
      {selectedTab === ADMIN_TABS.AGENCY && (
        <EntityList>
          <div className={classes.tabSubParents}>{tabs}</div>
        </EntityList>
      )}
      {selectedTab === ADMIN_TABS.PROVIDER && (
        <ProviderList>
          <div className={classes.tabSubParents}>{tabs}</div>
        </ProviderList>
      )}
    </>
  );
};

// @ts-ignore
const mapState = (state: RootState) => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(Admin);
