import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppColor from '../../../theme/AppColor';
import SBText from '../../../components/base/SBText';
import {  Divider } from '@material-ui/core';
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import moment from 'moment';
import { LeadStatusDetails } from '../types';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    width: '32%',
    display: 'flex',
    flexDirection: 'column',
    height: '70%',
    position: 'relative',
    borderRadius: 4,
  },
  createdByText: {
    fontSize: 14,
    fontWeight: 600,
    color: '#393b50',
    [theme.breakpoints.up(1920)]: {
      fontSize: 12,
    },
  },
  listParent: {
    minHeight: 70,
    width: '100%',
    backgroundColor: AppColor.appBlue,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 16,
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  selectAgentTxt: {
    fontSize: 25,
    color: AppColor.appWhite,
    fontWeight: 400,
    [theme.breakpoints.up(1920)]: {
      fontSize: 22,
    },
  },
  createdAtText: {
    fontSize: 10,
    color: '#63667C',
  },
  Updatedby: {
    fontSize: 10,
    color: '#63667C',
    marginLeft:5
  },
  labelText: {
    color: AppColor.appDarkGrey,
    fontSize: 17,
    margin: '20px 0px 5px 30px',
    fontWeight: 500,
  },
  commentText: {
    fontSize: 13,
    color: AppColor.appBlack,
    fontWeight: 500,

  },
}));

type LeadStatusTimelineProps = {
  open: any;
  onClose: () => void;
  ApplicantName: string;
  data: any;
};

const LeadStatusTimeline: React.FC<LeadStatusTimelineProps> = ({
  onClose,
  open,
  ApplicantName,
  data,
  ...rest
}) => {
  const classes = useStyles();
  const onCloseModel = () => {
    onClose();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.listParent}>
            <SBText text="Status Timeline" className={classes.selectAgentTxt} />
            <CloseSharpIcon
              onClick={() => onCloseModel()}
              fontSize="large"
              style={{ marginLeft: 10, color: AppColor.appWhite, cursor: 'pointer' }}
            />
          </div>
          <div style={{ minHeight: 60, overflowY: 'scroll' }}>
            <SBText text={`Applicant: ${ApplicantName}`} className={classes.labelText} />
            {data.map((item: any) => (
              <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ paddingTop: 15 }}>
                    <SBText text={LeadStatusDetails[item.status].label} className={classes.createdByText} />
                  </div>
                  <div style={{ display: 'flex', paddingBottom: 2 }}>
                    <SBText
                      text={item.createdAt ? `${moment(item.createdAt).format('DD MMM YYYY HH:mm')} |` : '- |'}
                      className={classes.createdAtText}
                    />
                    <SBText text={`Updated by ${item.createdByUser.name}`} className={classes.Updatedby} />
                  </div>
                </div>
                <div>
                  <SBText text={item.comment} className={classes.commentText} />
                  <Divider style={{ marginTop: 15 }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default LeadStatusTimeline;
