/* eslint-disable react/no-multi-comp */
// @ts-nocheck
import React, { useEffect, useState, useRef } from 'react';
import { Divider, IconButton, Theme } from '@material-ui/core';
import { RootState } from '../../../reducer';
import { connect, ConnectedProps } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid, Link, Checkbox } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router';
import 'react-portal-hint/dist/default.css';
import SBText from '../../../components/base/SBText';
import verifieldSmall from '../assets/icons8-ok-50.png';
import pending from '../assets/pendingSmall.png';
import SBTextField from '../../../components/base/SBTextField';
import SBSimpleFileSelector from '../../../components/base/SBSimpleFileSelector';
import SBFilterDropdown from '../../../components/base/SBFilterDropdown';
import SBDate from '../../../components/base/SBDate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SBButton from '../../../components/base/SBButton';
import { getTAUsreDetails, updateTADetails, sendOTP, verifyOTP, uploadTaContract } from '../../../actions';
import ErrorIcon from '@material-ui/icons/Error';
import LoadingDialog from '../../../components/LoadingDialog';
import termsAndConditionPDF from '../assets/TermsAndCondition.pdf';
import AppColor from '../../../theme/AppColor';
import UploadDocumentPopup from './UploadDocumentPopup';
import async from 'react-select/async';
// import logo from "./asts.png";
interface RouteParams {
  id: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      width: '100%',
      backgroundColor: '#F1F1F1',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingBottom: 20,
    },
    appBar: {
      // transition: theme.transitions.create(["width", "margin"], {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      backgroundColor: 'white',
    },
    logoImage: {
      width: '100px',
    },
    verifiedImage: {
      width: '30px',
      height: '30px',
      marginRight: 10,
    },
    inProgressImage: {
      width: '50px',
      height: '50px',
      marginRight: 10,
    },

    medialParent: {
      width: '100%',
      backgroundColor: 'white',
      borderRadius: 5,
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: '80vh',
    },

    headerParent: {
      width: '60%',
      [theme.breakpoints.only('sm')]: {
        width: '90%',
      },
      [theme.breakpoints.only('xs')]: {
        width: '100%',
        paddingLeft: 16,
      },
      height: 70,
      marginTop: 65,
      display: 'flex',
      alignItems: 'center',
    },
    headerText: {
      color: 'white',
      fontFamily: 'Poppins',
      textTransform: 'none',
      fontWeight: 600,
    },
    inputHeader: {
      color: '#5C5F74',
      fontFamily: 'Poppins',
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 14,
      letterSpacing: 0.2,
      lineHeight: 2,
    },

    welcomeHeader: {
      color: '#5C5F74',
      fontFamily: 'Poppins',
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 18,
      letterSpacing: 0.2,
      lineHeight: 2,
    },

    subHeaderParent: {
      height: 40,
      backgroundColor: '#5C5F74',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 16,
      justifyContent: 'space-between',
    },
    kycHeaderparent: {
      height: 40,
      backgroundColor: '#5C5F74',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 16,
      marginTop: 30,
      justifyContent: 'space-between',
    },
    textArea: {
      height: 140,
      maxLength: 25,
      textAlign: 'start',
    },
    otpButton: {
      width: '100%',
      height: 45,
    },
    submitButton: {
      width: 200,
      height: 40,
    },
    otpTextField: {
      width: '100%',
    },
    headerIconParent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    verifiedText: {
      color: '#68AC5F',
      fontFamily: 'Poppins',
      textTransform: 'none',
      fontWeight: 800,
      fontSize: 20,
    },

    addressHeader: {
      color: '#7A8080',
      fontFamily: 'Poppins',
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 12,
      marginLeft: 16,
      marginTop: 16,
    },

    bottomLayout: {
      width: '100%',
      height: 70,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      boxShadow: ' 0 -5px 4px -5px #333',
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      paddingLeft: 16,
      paddingRight: 16,
      zIndex: 100,
    },
    linkText: {
      fontFamily: 'Poppins',
      fontSize: 16,
      fontWeight: 600,
    },
    mainParent: {
      width: '60%',
      height: '86vh',
      marginTop: 100,
      [theme.breakpoints.only('sm')]: {
        width: '90%',
      },
      [theme.breakpoints.only('xs')]: {
        width: '100%',
        paddingLeft: 16,
      },
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: 5,
    },
    errorHeader: {
      fontFamily: 'Poppins',
      fontSize: 18,
      fontWeight: 500,
      marginLeft: 10,
      color: '#FF5349',
    },

    verificationProgressHeader: {
      fontFamily: 'Poppins',
      fontSize: 24,
      fontWeight: 600,
      marginLeft: 10,
      marginTop: 30,
      color: '#5D5F72',
    },
    errorMessage: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 500,
      color: '#2A547E',
      marginTop: 6,
    },
    inProgressMessage: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 500,
      color: '#2A547E',
      marginTop: 10,
      textAlign: 'center',
    },
    errorParent: {
      height: 100,
      width: '40%',
      backgroundColor: 'white',
      marginTop: 50,
      display: 'flex',
      borderRadius: 5,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      boxShadow: '0px 0px 2px 2px #d3d3d3',
    },
    InProgressParent: {
      paddingTop: 30,
      paddingBottom: 30,
      width: '40%',
      backgroundColor: 'white',
      marginTop: 50,
      display: 'flex',
      borderRadius: 5,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      boxShadow: '0px 0px 2px 2px #d3d3d3',
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    dropdown: {
      marginTop: 23,
    },
    clearButton: {
      backgroundColor: 'white',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: AppColor.appBlue,
      '&:hover': {
        backgroundColor: '#fafafa',
      },
      color: AppColor.appBlue,
      width: '100%',
      height: 45,
      padding: '0 20px',
    },
    downloadButton: {
      width: '35%',
      height: 45,
    },
    printnSIgnLbl: { fontSize: 24, color: AppColor.appBlue, fontWeight: 600 },
    printInstr: {
      color: '#5C5F74',
      fontFamily: 'Poppins',
      textTransform: 'none',
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: 0.2,
    },
    agreContener: {
      display: 'flex',
      margin: 'auto',
      flexDirection: 'column',
      textAlign: 'center',
      gap: 50,
      width: '75%',
      marginTop: 20,
    },
    agreBtnContainer: { display: 'flex', justifyContent: 'center', gap: 20 },
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type KYCVerificationFormProps = PropsFromRedux & {
  classes?: string;
};

const KYCVerificationForm: React.FC<KYCVerificationFormProps> = ({
  getTAUsreDetails,
  updateTADetails,
  loading,
  sendOTP,
  verifyOTP,
  uploadTaContract,
}) => {
  const { id } = useParams<RouteParams>();
  const classes = useStyles();
  const [basicProfile, showBasicProfile] = useState(false);
  const [kycView, showKycView] = useState(true);
  const [mobileVerificationView, showMobileVerification] = useState(true);
  const [AgreementViwe, showAgreementView] = useState(true);
  const [opanUploadDocumentPopup, setOpenUploadDocumentPopup] = React.useState(false);

  const [addess, setAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  });
  const [reason, setReason] = useState('');
  const [otp, setOTP] = useState('');
  const reference: any = useRef(null);
  const [timer, setTimer] = useState('00:00');
  const [firstOTOSend, setFirstOTPSend] = useState(false);
  const [timerOnOff, setTimerOnOff] = useState(false);
  const [mobileVerified, setMobileVerified] = useState(false);
  const [uploadContract, setUploadContract] = useState(false);
  const tempOTP = '111111';
  const [wronOTPError, setWrongOTPError] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [education, setEducation] = useState('');
  const [otherEducation, setOtherEducation] = useState('');
  const [termsAndCodition, setTermsAndCondition] = useState(false);
  const [idProofFileFront, setIdProofFileFront] = useState<undefined | any>(undefined);
  const [submitButtonEnable, setSubmitButtonEnabled] = useState(false);
  const [idProofFileBack, setIdProofFileback] = useState<undefined | any>(undefined);
  const [addresProofFile, setAddressProofFile] = useState<undefined | any>(undefined);
  const [bankProofFile, setBankProofFile] = useState<undefined | any>(undefined);
  const [isEmailVerifationComplete, setEmailVerificationComplete] = useState(false);
  const [currentUserDetails, setCurrentUserDetails] = useState(undefined);
  const [serverError, setServerError] = useState(undefined);
  const [kycVerification, setKeyverification] = useState(false);
  const [scrollToBottonView, setScrollToBottom] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (scrollToBottonView) {
      scrollToBottom();
      setScrollToBottom(false);
    }
  }, [scrollToBottonView]);

  const getFileName = (selectedFile: any) => {
    if (selectedFile) {
      // @ts-ignore
      const fileName = selectedFile.name.split('#')[1];
      return fileName;
    } else {
      return '';
    }
  };

  const getTimeRemaining = (e: any) => {
    const total = Date.parse(e) - Date.parse(new Date().toString());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    // const hours = Math.floor((total / 1000 * 60 * 60) % 24);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e: any) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the begining of the variable
      setTimer((minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds));
    } else {
      setTimerOnOff(false);
      setWrongOTPError('');
      setOTP('');
      if (reference.current) {
        clearInterval(reference.current);
      }
    }
  };

  const clearTimer = (e: any) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer('01:59');

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (reference.current) {
      clearInterval(reference.current);
    }
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    reference.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 120);
    return deadline;
  };

  const onClickReset = async () => {
    setFirstOTPSend(true);
    setTimerOnOff(true);

    const body = { id: currentUserDetails.userDetails.id };
    const result = await sendOTP(body);
    if (result.success) {
      clearTimer(getDeadTime());
    }
  };

  const onVerifyOTP = async () => {
    if (otp && otp.trim()) {
      const result = await verifyOTP({ id: currentUserDetails.userDetails.id, otp });

      if (result.success) {
        setMobileVerified(true);
        setTimer('00:00');
        if (reference.current) {
          clearInterval(reference.current);
        }
      } else {
        if (result && result.message) {
          setWrongOTPError(result.message);
        }
      }
    }
  };

  const onAddressChange = (text: string, key: string) => {
    setAddress({ ...addess, [key]: text });
  };

  const uploadDoc = () => {
    setOpenUploadDocumentPopup(true);
  };

  const uploadDocumnet = async (file: any) => {
    await uploadTaContract(file, currentUserDetails.userDetails.id);
    setOpenUploadDocumentPopup(false);
    setUploadContract(true);
  };

  const getPreparedFileName = (type: string, id: any) => {
    return `ta_${type}_${id}`;
  };

  const getEducationDropDownValue = () => {
    return [
      { id: 1, value: 'High School' },
      { id: 2, value: 'Diploma' },
      { id: 3, value: 'Graduate' },
      { id: 4, value: 'Post-Graduate' },
      { id: 5, value: 'Other' },
    ];
  };

  const onDropDownChange = (value: any) => {
    setEducation(value);
    setOtherEducation('');
    if (value == 'Other') {
      setScrollToBottom(true);
    }
  };

  const isAllFileUploaded = () => {
    if (idProofFileFront && addresProofFile && bankProofFile) {
      return true;
    } else {
      return false;
    }
  };

  const isAllAddressFieldAdded = () => {
    if (
      addess &&
      addess.line1.trim() &&
      addess.city.trim() &&
      addess.country.trim() &&
      addess.state.trim() &&
      addess.pincode.trim()
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isEducationAndReasonEntered = () => {
    if (reason && reason.trim() && education && termsAndCodition) {
      if (education == 'Other') {
        if (otherEducation) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isEducationAndReasonEntered() && isAllAddressFieldAdded() && isAllFileUploaded() && mobileVerified) {
      setSubmitButtonEnabled(true);
    } else {
      setSubmitButtonEnabled(false);
    }
  }, [isEducationAndReasonEntered, isAllAddressFieldAdded, isAllFileUploaded, mobileVerified]);

  const basicProfileView = () => {
    return (
      <>
        <div className={classes.subHeaderParent}>
          <div className={classes.headerIconParent}>
            <Tooltip title="Basic Details Completed" placement="bottom">
              <img src={verifieldSmall} className={classes.verifiedImage} />
            </Tooltip>
            <SBText text="Basic Details" className={classes.headerText} />
          </div>
          <IconButton onClick={() => showBasicProfile(!basicProfile)}>
            {!basicProfile ? (
              <ExpandMoreIcon style={{ color: 'white' }} fontSize={'large'} />
            ) : (
              <ExpandLessIcon style={{ color: 'white' }} fontSize={'large'} />
            )}
          </IconButton>
        </div>

        <Collapse in={basicProfile} timeout="auto" unmountOnExit>
          <Grid container spacing={2} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 30 }}>
            <Grid item lg={6} sm={12} xs={12}>
              <SBTextField
                value={currentUserDetails.userDetails.firstName}
                labelText={'First Name'}
                labeStyle={classes.inputHeader}
                disabled={true}
              ></SBTextField>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <SBTextField
                value={currentUserDetails.userDetails.lastName}
                labelText={'Last Name'}
                labeStyle={classes.inputHeader}
                disabled={true}
              ></SBTextField>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <SBTextField
                value={currentUserDetails.userDetails.email}
                labelText={'Email'}
                labeStyle={classes.inputHeader}
                disabled={true}
              ></SBTextField>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <SBTextField
                value={currentUserDetails.userDetails.city}
                labelText={'City'}
                labeStyle={classes.inputHeader}
                disabled={true}
              ></SBTextField>
            </Grid>
            {/* <Grid item lg={6} sm={12} xs={12}>
              <SBTextField
                value={""}
                labelText={"Occupation"}
                labeStyle={classes.inputHeader}
              ></SBTextField>
            </Grid> */}
            {/* <Grid item lg={6} sm={12} xs={12}>
              <SBTextField
                value={""}
                labelText={"Where did you hear about us?"}
                labeStyle={classes.inputHeader}
              ></SBTextField>
            </Grid> */}
            <Grid item lg={6} sm={12} xs={12}>
              <SBTextField
                value={currentUserDetails.userDetails.phone}
                labelText={'Mobile'}
                labeStyle={classes.inputHeader}
                disabled={true}
              ></SBTextField>
            </Grid>
          </Grid>
        </Collapse>
      </>
    );
  };

  const mobileVerificatioView = () => {
    return (
      <>
        <div className={classes.kycHeaderparent}>
          <div className={classes.headerIconParent}>
            <Tooltip title="Please verify your mobile number" placement="bottom">
              {mobileVerified ? (
                <img src={verifieldSmall} className={classes.verifiedImage} />
              ) : (
                <img src={pending} className={classes.verifiedImage} />
              )}
            </Tooltip>
            <SBText text="Mobile Number Verification" className={classes.headerText} />
          </div>

          <IconButton onClick={() => showMobileVerification(!mobileVerificationView)}>
            {!mobileVerificationView ? (
              <ExpandMoreIcon style={{ color: 'white' }} fontSize={'large'} />
            ) : (
              <ExpandLessIcon style={{ color: 'white' }} fontSize={'large'} />
            )}
          </IconButton>
        </div>
        <Collapse in={mobileVerificationView} timeout="auto" unmountOnExit>
          <Grid container spacing={2} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 30 }}>
            <Grid item lg={6} sm={12} xs={12}>
              <SBTextField
                value={currentUserDetails.userDetails.phone}
                labeStyle={classes.inputHeader}
                disabled={true}
              ></SBTextField>
            </Grid>

            {!mobileVerified ? (
              <Grid item lg={6} sm={12} xs={12}>
                <SBButton
                  text={firstOTOSend && !timerOnOff ? 'Resend OTP' : 'Send OTP'}
                  onClick={() => onClickReset()}
                  icon={false}
                  className={classes.otpButton}
                  disabled={timerOnOff}
                />
              </Grid>
            ) : (
              <Grid
                item
                lg={6}
                sm={12}
                xs={12}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={verifieldSmall} className={classes.verifiedImage} />
                <SBText text="Verified" className={classes.verifiedText} />
              </Grid>
            )}

            {timer != '00:00' ? (
              <>
                <Grid item lg={6} sm={12} xs={12}>
                  <SBTextField
                    value={otp}
                    labelText={`Enter OTP in ${timer}`}
                    labeStyle={classes.inputHeader}
                    className={classes.otpTextField}
                    onDataChange={(text) => {
                      if (!text || (text && text.length < 7)) {
                        setOTP(text);
                        setWrongOTPError('');
                      }
                    }}
                    error={wronOTPError}
                    helperText={wronOTPError}
                  ></SBTextField>
                </Grid>

                <Grid
                  item
                  lg={6}
                  sm={12}
                  xs={12}
                  style={{
                    alignItems: 'flex-end',
                    display: 'flex',
                    paddingBottom: 10,
                  }}
                >
                  <SBButton
                    text={'Verify'}
                    onClick={() => onVerifyOTP()}
                    icon={false}
                    className={classes.otpButton}
                    disabled={!(otp && otp.length == 6)}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Collapse>
      </>
    );
  };

  const kycVerificationView = () => {
    return (
      <>
        <div className={classes.kycHeaderparent}>
          <div className={classes.headerIconParent}>
            <img src={pending} className={classes.verifiedImage} />
            <SBText text="KYC and Verification Details" className={classes.headerText} />
          </div>

          <IconButton onClick={() => showKycView(!kycView)}>
            {!kycView ? (
              <ExpandMoreIcon style={{ color: 'white' }} fontSize={'large'} />
            ) : (
              <ExpandLessIcon style={{ color: 'white' }} fontSize={'large'} />
            )}
          </IconButton>
        </div>

        <Collapse in={kycView} timeout="auto" unmountOnExit>
          <>
            <SBText text="Required Documents" className={classes.addressHeader} />
            <Divider style={{ marginLeft: 16, marginRight: 16 }} />

            <Grid container spacing={2} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
              <Grid item lg={6} sm={12} xs={12}>
                <SBSimpleFileSelector
                  onFileSelected={(fileObj: any) => setIdProofFileFront(fileObj)}
                  onDeleteFile={() => setIdProofFileFront(undefined)}
                  value={getFileName(idProofFileFront)}
                  setUploadError={() => console.log('setUploadError ')}
                  onDownloadFile={() => console.log('onDownloadFile ')}
                  fileNameExtension={getPreparedFileName('idProof', 2)}
                  labelText={'ID Proof(Front)*'}
                  showDownload={false}
                  labelStyle={classes.inputHeader}
                  accept={'image/jpeg,image/png, .pdf'}
                />
              </Grid>

              <Grid item lg={6} sm={12} xs={12}>
                <SBSimpleFileSelector
                  onFileSelected={(fileObj: any) => setIdProofFileback(fileObj)}
                  onDeleteFile={() => setIdProofFileback(undefined)}
                  value={getFileName(idProofFileBack)}
                  setUploadError={() => console.log('setUploadError ')}
                  onDownloadFile={() => console.log('onDownloadFile ')}
                  fileNameExtension={getPreparedFileName('idProof', 2)}
                  labelText={'ID Proof(Back)'}
                  showDownload={false}
                  labelStyle={classes.inputHeader}
                  accept={'image/jpeg,image/png, .pdf'}
                />
              </Grid>

              <Grid item lg={6} sm={12} xs={12}>
                <SBSimpleFileSelector
                  onFileSelected={(fileObj: any) => setBankProofFile(fileObj)}
                  onDeleteFile={() => setBankProofFile(undefined)}
                  value={getFileName(bankProofFile)}
                  setUploadError={() => console.log('setUploadError ')}
                  onDownloadFile={() => console.log('onDownloadFile ')}
                  fileNameExtension={getPreparedFileName('bankProof', 2)}
                  labelText={'Bank Details (Cancelled Cheque to be uploaded)*'}
                  showDownload={false}
                  labelStyle={classes.inputHeader}
                  accept={'image/jpeg,image/png, .pdf'}
                />
              </Grid>

              <Grid item lg={6} sm={12} xs={12}>
                <SBSimpleFileSelector
                  onFileSelected={(fileObj: any) => setAddressProofFile(fileObj)}
                  onDeleteFile={() => setAddressProofFile(undefined)}
                  value={getFileName(addresProofFile)}
                  setUploadError={() => console.log('setUploadError ')}
                  onDownloadFile={() => console.log('onDownloadFile ')}
                  fileNameExtension={getPreparedFileName('addressProof', 2)}
                  labelText={'Address Proof*'}
                  showDownload={false}
                  labelStyle={classes.inputHeader}
                  accept={'image/jpeg,image/png, .pdf'}
                />
              </Grid>
            </Grid>

            <SBText text="Current Address Details" className={classes.addressHeader} />
            <Divider style={{ marginLeft: 16, marginRight: 16 }} />
            <Grid container spacing={2} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
              <Grid item lg={6} sm={12} xs={12}>
                <SBTextField
                  value={addess.line1}
                  labelText={'Line 1*'}
                  labeStyle={classes.inputHeader}
                  onDataChange={(text) => onAddressChange(text, 'line1')}
                  inputProps={{ maxlength: 70 }}
                ></SBTextField>
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <SBTextField
                  value={addess.line2}
                  labelText={'Line 2'}
                  labeStyle={classes.inputHeader}
                  onDataChange={(text) => onAddressChange(text, 'line2')}
                  inputProps={{ maxlength: 70 }}
                ></SBTextField>
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <SBTextField
                  value={addess.city}
                  labelText={'City*'}
                  labeStyle={classes.inputHeader}
                  onDataChange={(text) => onAddressChange(text, 'city')}
                  inputProps={{ maxlength: 20 }}
                ></SBTextField>
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <SBTextField
                  value={addess.state}
                  labelText={'State*'}
                  labeStyle={classes.inputHeader}
                  onDataChange={(text) => onAddressChange(text, 'state')}
                  inputProps={{ maxlength: 20 }}
                ></SBTextField>
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <SBTextField
                  value={addess.country}
                  labelText={'Country*'}
                  labeStyle={classes.inputHeader}
                  onDataChange={(text) => onAddressChange(text, 'country')}
                  inputProps={{ maxlength: 20 }}
                ></SBTextField>
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <SBTextField
                  value={addess.pincode}
                  labelText={'Pincode*'}
                  labeStyle={classes.inputHeader}
                  onDataChange={(text) => onAddressChange(text, 'pincode')}
                  inputProps={{ maxlength: 6 }}
                  type="number"
                ></SBTextField>
              </Grid>
            </Grid>

            <SBText text="Other Info" className={classes.addressHeader} />
            <Divider style={{ marginLeft: 16, marginRight: 16 }} />

            <Grid container spacing={2} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16 }}>
              <Grid item lg={6} sm={12} xs={12}>
                <SBDate
                  value={birthDate}
                  disabled={false}
                  labelText={'Birth Date*'}
                  labelStyle={classes.inputHeader}
                  onDataChange={(date: string, obj: any) => setBirthDate(date)}
                />

                <SBFilterDropdown
                  data={getEducationDropDownValue()}
                  value={education}
                  labelText={'Higher Education*'}
                  onChange={(value: any) => onDropDownChange(value)}
                  labeStyle={classes.inputHeader}
                  className={classes.dropdown}
                />
              </Grid>
              {/* <Grid item lg={6} sm={12} xs={12}>
                <SBFilterDropdown
                  data={getEducationDropDownValue()}
                  value={education}
                  labelText={"Higher Education*"}
                  onChange={(value: any) => onDropDownChange(value)}
                  labeStyle={classes.inputHeader}
                />
              </Grid> */}

              <Grid item lg={6} sm={12} xs={12}>
                <SBTextField
                  value={reason}
                  labelText={'Top 3 reasons why he/she is interested to become an TA*'}
                  labeStyle={classes.inputHeader}
                  rows={5}
                  className={classes.textArea}
                  multiline={true}
                  onDataChange={(text) => {
                    if (reason && reason.length > 400) {
                      return;
                    } else {
                      setReason(text);
                    }
                  }}
                ></SBTextField>
              </Grid>

              <Grid item lg={6} sm={12} xs={12}>
                {education && education == 'Other' ? (
                  <SBTextField
                    value={otherEducation}
                    labelText={'Other*'}
                    labeStyle={classes.inputHeader}
                    onDataChange={(text) => setOtherEducation(text)}
                  ></SBTextField>
                ) : null}
              </Grid>
              <div ref={messagesEndRef} />
            </Grid>
          </>
        </Collapse>
      </>
    );
  };

  const startblocksAggrement = () => {
    return (
      <>
        <div className={classes.subHeaderParent}>
          <div className={classes.headerIconParent}>
            <Tooltip title="Startblocks Agreements" placement="bottom">
              {AgreementViwe ? (
                <img src={verifieldSmall} className={classes.verifiedImage} />
              ) : (
                <img src={pending} className={classes.verifiedImage} />
              )}
            </Tooltip>
            <SBText text="Startblocks Agreements" className={classes.headerText} />
          </div>
          <IconButton onClick={() => showAgreementView(!AgreementViwe)}>
            {!AgreementViwe ? (
              <ExpandMoreIcon style={{ color: 'white' }} fontSize={'large'} />
            ) : (
              <ExpandLessIcon style={{ color: 'white' }} fontSize={'large'} />
            )}
          </IconButton>
        </div>

        <Collapse in={AgreementViwe} timeout="auto" unmountOnExit>
          <Grid container spacing={2} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 30 }}>
            <Grid item lg={12} sm={12} xs={12}>
              <div className={classes.agreContener}>
                <SBText text="Print and Sign Application" className={classes.printnSIgnLbl} />
                <SBText
                  text={`Please print and sign the document. Upon signing, please scan and upload the document by pressing on the "Upload Document" button bellow`}
                  className={classes.printInstr}
                />
                <div className={classes.agreBtnContainer}>
                  <a
                    href={termsAndConditionPDF}
                    style={{ textDecoration: 'none' }}
                    download="MyExampleDoc"
                    target="_blank"
                  >
                    <SBButton
                      text={'Download Document'}
                      // onClick={() => onClickReset()}
                      icon={false}
                      className={classes.clearButton}
                      // disabled={timerOnOff}
                    />
                  </a>
                  <SBButton
                    text={'Upload Document'}
                    onClick={() => uploadDoc()}
                    icon={false}
                    className={classes.downloadButton}
                    // disabled={timerOnOff}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Collapse>
      </>
    );
  };

  const handleTermsAndCondition = () => {
    setTermsAndCondition(!termsAndCodition);
  };

  useEffect(() => {
    if (id) {
      getIDVerification();
    } else {
      setServerError({ message: 'Detail not found' });
    }
  }, []);

  const getIDVerification = async () => {
    const user: any = await getTAUsreDetails(id);
    if (user.success) {
      setCurrentUserDetails(user);
      setEmailVerificationComplete(true);
      if (user.verificationDetails && user.verificationDetails.otpVerification == 'completed') {
        setMobileVerified(true);
      }
      if (user.verificationDetails && user.verificationDetails.kycVerification == 'inProgress') {
        setKeyverification(true);
      }
    } else {
      setServerError(user);
    }
  };

  const onSubmitClick = async () => {
    const body = {
      higherEducation: education,
      otherHigherEducation: otherEducation.trim(),
      birthDate: birthDate,
      reasonToBecomeTA: reason.trim(),
      line1: addess.line1,
      line2: addess.line2,
      city: addess.city,
      state: addess.state,
      country: addess.country,
      pincode: addess.pincode,
      termsCondition: termsAndCodition,
      taId: currentUserDetails.userDetails.id,
    };
    const fileArray = [
      { key: 'doc_id_proof_front', file: idProofFileFront },
      { key: 'doc_address_proof', file: addresProofFile },
      { key: 'doc_bank_details', file: bankProofFile },
    ];
    if (idProofFileBack) {
      fileArray.push({ key: 'doc_id_proof_back', file: idProofFileBack });
    }
    const result = await updateTADetails(fileArray, currentUserDetails.userDetails.id, body);
    if (result.success) {
      setCurrentUserDetails({
        ...currentUserDetails,
        verificationDetails: result.verificationDetails,
      });
      if (result.verificationDetails && result.verificationDetails.otpVerification == 'completed') {
        setMobileVerified(true);
      }
      if (result.verificationDetails && result.verificationDetails.kycVerification == 'inProgress') {
        setKeyverification(true);
      }
    }
    // console.log("Final result >>>>", result);
  };

  if (id && isEmailVerifationComplete && currentUserDetails) {
    if (!kycVerification) {
      return (
        <div className={classes.root}>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <img src={logo} alt="startblocks logo" className={classes.logoImage} />

              <SBText
                text={`Welcome ${currentUserDetails?.userDetails?.firstName}`}
                className={classes.welcomeHeader}
              />
            </Toolbar>
          </AppBar>
          <div className={classes.mainParent}>
            <div className={classes.medialParent}>
              {basicProfileView()}
              {mobileVerificatioView()}
              {kycVerificationView()}
              {startblocksAggrement()}
            </div>

            <div className={classes.bottomLayout}>
              <div>
                <Checkbox
                  checked={termsAndCodition}
                  onChange={handleTermsAndCondition}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  style={{
                    transform: 'scale(1.3)',
                  }}
                  color={'default'}
                />
                <Link href={termsAndConditionPDF} onClick={() => {}} className={classes.linkText} target="_blank">
                  Accept Terms and Condition
                </Link>
              </div>
              <SBButton
                text={'Submit'}
                onClick={() => onSubmitClick()}
                icon={false}
                className={classes.submitButton}
                disabled={!submitButtonEnable}
              />
            </div>
          </div>
          <LoadingDialog open={loading} />
          {opanUploadDocumentPopup ? (
            <UploadDocumentPopup
              open={opanUploadDocumentPopup}
              onClose={() => setOpenUploadDocumentPopup(false)}
              ApplicantName={'applicantName'}
              leadstatus={'leadStatusLabel'}
              uploadDocumnet={uploadDocumnet}
            />
          ) : null}
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <div className={classes.InProgressParent}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: 40,
                paddingRight: 40,
              }}
            >
              <img src={pending} className={classes.inProgressImage} />
              <SBText text={'Identity Verification is in progress '} className={classes.verificationProgressHeader} />

              <p className={classes.inProgressMessage}>
                We will notify you via email message as soon as your account will be verified
              </p>

              <p className={classes.errorMessage} style={{ marginTop: 30 }}>
                Please contact{' '}
                <a href="mailto:support@startblocks.com?subject=Need Support For TA!">support@startblocks.com</a> for
                more details.
              </p>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className={classes.root}>
        {!loading ? (
          <div className={classes.errorParent}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <ErrorIcon fontSize="large" />
              <SBText
                text={serverError && serverError.message ? serverError.message : 'Something went wrong'}
                className={classes.errorHeader}
              />
            </div>
            <p className={classes.errorMessage}>
              Please contact{' '}
              <a href="mailto:support@startblocks.com?subject=Need Support For TA!">support@startblocks.com</a> for more
              details.
            </p>
          </div>
        ) : null}
      </div>
    );
  }
};

const mapState = (state: RootState) => ({
  loading: state.application.loading,
});
const mapDispatch = {
  getTAUsreDetails,
  updateTADetails,
  sendOTP,
  verifyOTP,
  uploadTaContract,
};

const connector = connect(mapState, mapDispatch);
export default connector(KYCVerificationForm);
