import { StatusViewItem } from "../../../components/StatusChip/status-type";

export enum LPO_STATUS {
  ENQUIRED = "enquired",
  ADDED = "added",
  REJECTED = "rejected",
  INVITED = "invited",
  ACTIVE = "active",
  RECEIVED = 'received',
  APPROVED = 'approved',
}

export type LPOStatusViewItem = StatusViewItem & {};

export type LPOStatusDetailsType = {
  [key in LPO_STATUS]: LPOStatusViewItem;
};

export const LPOStatusDetails: LPOStatusDetailsType = {
  [LPO_STATUS.ADDED]: {
    label: "Added",
    color: "#3D405B",
  },
  [LPO_STATUS.ENQUIRED]: {
    label: "Enquired",
    color: "#7B8997",
  },
  [LPO_STATUS.INVITED]: {
    label: "Invited",
    color: "#FF6632",
  },
  [LPO_STATUS.REJECTED]: {
    label: "Rejected",
    color: "#CF4E0B",
  },
  [LPO_STATUS.ACTIVE]: {
    label: "Active",
    color: "#8BB19C",
  },

  [LPO_STATUS.RECEIVED]: {
    label: "Received",
    color: "#a3a5d4",
  },

  [LPO_STATUS.APPROVED]: {
    label: "Approved",
    color: "#8BB19C",
  },
};
