import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { TextField, IconButton, Button, Collapse } from "@material-ui/core";
import SBText from "../../../components/base/SBText";
import SBButton from "../../../components/base/SBButton";
import SBFileSelector from "../../../components/base/SBFileSelector";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AppColor from "../../../theme/AppColor";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import moment from "moment";
import { getApplicationIdInString } from "../../../util";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 550,
      backgroundColor: theme.palette.background.paper,
      //border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: "0px 24px 24px",
    },
    textAreaWidth: {
      width: "83%",
      border: "1px solid black",
      borderRadius: "5px",
    },

    idTextField: {
      border: "1px solid black",
      borderRadius: "5px",
    },
    Lable: {
      width: "80%",
      paddingTop: "15px",
      paddingBottom: "5px",
    },
    fileUpload: {
      height: "100px !important",
      border: "1px solid black !important",
    },
    lableFont: {
      fontSize: "14px",
      color: theme.palette.primary.light,
    },
    headerColor: {
      color: "white",
      fontSize: "14px",
    },
    headerText: {
      backgroundColor: "#5C5F74",
      color: "white",
      marginRight: "-24px",
      marginLeft: "-24px",
      paddingLeft: 10,
      paddingRight: 10,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    saveBtnDiv: {
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: 30,
      display: "flex",
    },
    saveBtn: {
      height: 40,
      borderRadius: 30,
      width: 93,
      backgroundColor: "#5C5F74",
      color: "white",
      fontSize: "12px",
    },
    outcomeHistoryParent: {
      minHeight: 30,
      display: "flex",
      flexDirection: "row",
      paddingLeft: 5,
      paddingRight: 5,
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomColor: AppColor.appLightGrey,
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
    },
  })
);

//let uploadedFile=null;

export default function SimpleModal(SimpleModalProps: any) {
  const classes = useStyles();
  const [collapseHistory, setCollapseHistory] = useState(false);

  const [formdata, setFormData] = useState<any>({
    uploadedFile: undefined,
    localFileName: "",
    comment: SimpleModalProps.outComeObj
      ? SimpleModalProps.outComeObj.comment
      : "",
    refNum: SimpleModalProps.outComeObj &&  SimpleModalProps.outComeObj.referenceNumber ?  SimpleModalProps.outComeObj.referenceNumber : "",
    outcome: SimpleModalProps.outcome,
    appId: SimpleModalProps.appId,
    outcomeName: SimpleModalProps.outcomeName,
    outcomeObj: SimpleModalProps.outComeObj,
    outcomeHistory: SimpleModalProps.outcomeHistory,
    id:  SimpleModalProps.outComeObj &&  SimpleModalProps.outComeObj.id ?  SimpleModalProps.outComeObj.id : null,
    applicantId:  SimpleModalProps.ids &&  SimpleModalProps.ids.applicantId ?  SimpleModalProps.ids.applicantId : "",
    studentId: SimpleModalProps.ids &&  SimpleModalProps.ids.studentId ?  SimpleModalProps.ids.studentId : "",
  });

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const onFileSelected = (file: any, questionObj: any) => {
    //body.file=file;
    // setOutComeObject("comment56",file);
    setFormData({ ...formdata, ["uploadedFile"]: file });
    //setFormData({ ...formdata, ["localFileName"]: file.name });
  };

  const deleteFile = (obj: any) => {
    let result = SimpleModalProps.deleteOutcomeFile(obj);
    setFormData({
      ...formdata,
      ["uploadedFile"]: undefined,
      ["comment"]: "",
      ["refNum"]: "",
      ["outcomeObj"]: null,
    });
  };
  const handleChange = (event: any) => {
    // event.persist();
    const value = event.target.value;
    const name = event.target.name;
    setFormData({ ...formdata, [name]: value });
  };

  const getFileName = (dataObject: any) => {
    let filename =
      formdata.uploadedFile && formdata.uploadedFile.name
        ? formdata.uploadedFile.name
        : formdata.outcomeObj && formdata.outcomeObj.fileName
        ? formdata.outcomeObj.fileName
        : "";

    if (filename.includes("#")) {
      filename = filename.split("#")[1];
    }
    return filename;
  };

  const getHistoryFileName = (fileName: string) => {
    // let filename = dataObject.answer.fileName;
    if (fileName.includes("#")) {
      fileName = fileName.split("#")[1];
    }
    return fileName;
  };

  return (
    <div
      style={{
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width:"40%",
        // overflowY: "auto",
      }}
      className={classes.paper}
    >
      <div className={classes.headerText}>
        <SBText className={classes.headerColor} text={formdata.outcomeName + " Letter for Application "+ getApplicationIdInString(formdata.appId)} />
        <IconButton
          onClick={() => {
            setFormData(null);
            SimpleModalProps.onClose();
          }}
        >
          <HighlightOffIcon style={{ color: "white" }} />
        </IconButton>
      </div>
      <p id="simple-modal-description">
        <form noValidate autoComplete="off">
          {/* <div>
            <div className={classes.Lable}> <SBText className={classes.lableFont} text="File Upload"/></div>
            <TextField className={classes.textAreaWidth} id="outlined" multiline rows={4} variant="outlined" />
            <img src={deleteIcon} style={{ width: 20, margin:"10px" }} alt="icon"/>
            <img src={download} style={{ width: 20, margin:"10px" }} alt="downloadIcon"/>
        </div> */}
          <div
            style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
          >
            <SBFileSelector
              onUploadFile={() => console.log("File Upload")}
              setUploadError={() => console.log("File Upload")}
              fileLoadPercentage={13}
              onFileSelected={onFileSelected}
              onDeleteFile={deleteFile}
              onDownloadFile={SimpleModalProps.downloadOutComeFile}
              parentForm={"outcome"}
              labelText="File Upload"
              size="box"
              largeBox={true}
              outcomeObj={formdata.outcomeObj}
              value={getFileName(formdata)}
            />
          </div>

          <div>
            <div className={classes.Lable}>
              <SBText className={classes.lableFont} text="Comment" />
            </div>
            <TextField
              className={classes.textAreaWidth}
              id="outlined-multiline"
              multiline
              rows={4}
              variant="outlined"
              onChange={handleChange}
              value={formdata.comment ? formdata.comment : ""}
              name="comment"
            />
          </div>
          <div>
            <div className={classes.Lable}>
              <SBText className={classes.lableFont} text="Reference Number" />
            </div>
            <TextField
              className={classes.textAreaWidth}
              id="outlined-multiline-static"
              variant="outlined"
              onChange={handleChange}
              value={formdata.refNum}
              name="refNum"
            />
          </div>


        <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
          <div style={{display:'flex', flex:1, flexDirection:'column'}}>
            <div className={classes.Lable}>
              <SBText className={classes.lableFont} text="Applicant Id" />
            </div>
            <TextField
              className={classes.idTextField}
              id="outlined-multiline-static"
              variant="outlined"
              onChange={handleChange}
              value={formdata.applicantId}
              name="applicantId"
            />
          </div>
          <div style={{width:24}}/>

          <div style={{display:'flex', flex:1, flexDirection:'column'}}>
            <div className={classes.Lable}>
              <SBText className={classes.lableFont} text="Student Id" />
            </div>
            <TextField
              className={classes.idTextField}
              id="outlined-multiline-static"
              variant="outlined"
              onChange={handleChange}
              value={formdata.studentId}
              name="studentId"
            />
          </div>
          </div>

          <div className={classes.saveBtnDiv}>
            <SBButton
              text={"Save"}
              icon={false}
              className={classes.saveBtn}
              onClick={() =>
                SimpleModalProps.submitOutcome(
                  formdata.uploadedFile,
                  formdata.appId,
                  formdata.outcome,
                  formdata.comment ? formdata.comment : "",
                  formdata.refNum,
                  formdata.id,
                  formdata.applicantId,
                  formdata.studentId
                )
              }
            />
          </div>

          {formdata.outcomeHistory && formdata.outcomeHistory.length > 0 ? (
            <>
              <Button
                style={{ paddingLeft: 0, paddingBottom: 2, marginTop: 10 }}
                onClick={() => setCollapseHistory(!collapseHistory)}
                disableRipple={true}
              >
                <SBText className={classes.lableFont} text="Document History" />
                {!collapseHistory ? (
                  <KeyboardArrowRightIcon fontSize={"small"} />
                ) : (
                  <KeyboardArrowDownIcon fontSize={"small"} />
                )}
              </Button>

              <Collapse in={collapseHistory}>
                <div
                  style={{
                    maxHeight: 100,
                    marginBottom: 10,
                    overflowY: "scroll",
                    borderWidth: 1,
                    borderColor: AppColor.appLightGrey,
                    borderStyle: "solid",
                    width: "78%",
                    borderRadius: 5,
                  }}
                >
                  {formdata.outcomeHistory.map((obj: any, index: number) => (
                    <div className={classes.outcomeHistoryParent}>
                      <SBText
                        text={getHistoryFileName(obj.fileName)}
                        style={{ fontSize: 12, width: "30%" }}
                      />
                      <SBText
                        text={moment(obj.updatedAt).format("DD-MM-YYYY")}
                        style={{ fontSize: 12 }}
                      />
                      <IconButton
                        size={"small"}
                        onClick={() =>
                          SimpleModalProps.downloadOutComeHistoryFile(obj)
                        }
                      >
                        <CloudDownloadIcon fontSize={"small"} />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </Collapse>
            </>
          ) : null}
        </form>
      </p>
    </div>
  );
}
