import { getLeadsTableActions } from ".";
import { LABELS } from "../../../util/labels";
import { Lead, LeadStatusDetails, LeadTableItem, LEAD_STATUS } from "../types";
import _ from "lodash";

export const mapLeadDataToTableViewItem = (lead: Lead): LeadTableItem => {
  const applicationStatus = _.get(lead, 'convertedApplication.status.status');
  return {
    ...lead,
    name: `${lead.applicantFirstName} ${lead.applicantLastName}`,
    statusViewDetails:
    applicationStatus && applicationStatus != "Draft"
        ? 
        LeadStatusDetails[applicationStatus as LEAD_STATUS]
        : LeadStatusDetails[lead.status],
    actionLinks: getLeadsTableActions(lead.status),
  };
};
