import { ThunkAction } from "redux-thunk";
import {
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
  put,
} from "../../../../actions";
import { RootState } from "../../../../reducer";
import { ActionType } from "../../../../reducer/types";
import { API_URL } from "../../../../util/apiUrls";
import { LeadAction } from "./action-types";

export const getLead = (
  id: number
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ON_LOADING_START });

  const result = await get(API_URL.LEADS.BY_ID(id), dispatch);

  await dispatch({
    type: LeadAction.SET_SELECTED_LEAD_DETAILS,
    payload: result,
  });
  dispatch({ type: ON_LOADING_FINISHED });
};
export const getLeadStatusTimeLine = (
  id: number
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ON_LOADING_START });

  const result = await get(API_URL.LEADS.BY_ID_STATUSE_TIMELINE(id), dispatch);

  await dispatch({
    type: LeadAction.SET_LEAD_STATUSE_TIMELINE,
    payload: result,
  });
  dispatch({ type: ON_LOADING_FINISHED });
};
export const updateLeadStatus = (
  id: number,status:string,comment:string
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ON_LOADING_START });

  const result= await put(API_URL.LEADS.UPDATE_LEAD_STATUS(id),{status,comment}, dispatch);

  await dispatch({
    type: LeadAction.UPDATE_LEAD_STATUS,
    payload: result,
  });
  dispatch({ type: ON_LOADING_FINISHED });
};
