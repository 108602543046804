import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import StatusChip from "../../../../components/StatusChip";

import { useFetchAgentContractData } from "../../hooks/useFetchAgentContractDeatils";
import { useFetchContractFile } from "../../hooks/useFetchContractAttachedFile";
import { useFetchApplicationFormFile } from "../../hooks/useFetchApplicationFormAttachedFile";
import { useFetchSupportingDocuments } from "../../hooks/useFetchSupportingDocuments";
import { useFetchSubmittedDocuments } from "../../hooks/useFetchSubmittedDocuments";

const useStyles = makeStyles({
  mainContainer: {
    width: 350,
    padding: "20px",
  },
  crossButtonContainer: {
    textAlign: "right",
  },
  fullList: {
    width: "auto",
  },
  header: {
    marginTop: "30px",
  },
  companyName: {
    color: "#3E4059",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 700,
    marginBottom: "20px",
  },
  name: {
    color: "#3E4059",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "20px",
  },
  downloadButton: {
    color: "#3E4059",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    "&:hover": {
      cursor: "pointer",
      color: "#a5a5ad",
    },
  },
});

interface SideBarProps {
  agentsData: any;
  currentAgentId: any;
  onSideBarClose: any;
}

const SideBar: React.FC<SideBarProps> = ({
  agentsData,
  currentAgentId,
  onSideBarClose,
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [statusViewDetails, setStatusViewDetails] = useState({
    label: "",
    color: "",
  });
  const [receivedContractFileKey, setReceivedContractFileKey] = useState("");
  const [agentStatusCode, setAgentStatusCode] = useState(0);
  const [agentId, setAgentId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [
    isDownLoadSupportingDocumentClicked,
    setIsDownLoadSupportingDocumentClicked,
  ] = useState("");
  const [isSaveFormButtonClicked, setIsSaveFormButtonClicked] = useState(false);
  const [
    isContractSaveButtonClicked,
    setIsContractSaveButtonClicked,
  ] = useState(false);

  const {
    agentContractDetailsReducer,
    agentContractAttachedFileReducer,
    agentApplicationFormFileReducer,
    downloadSupportingDocumentsReducer,
  } = useSelector((state: any) => state);

  const { fetchAgentContractData } = useFetchAgentContractData();
  const { fetchContractFile } = useFetchContractFile();
  const { fetchFetchApplicationFormFile } = useFetchApplicationFormFile();
  const { fetchSupportingDocuments } = useFetchSupportingDocuments();
  const { fetchSubmittedDocuments } = useFetchSubmittedDocuments();

  useEffect(() => {
    const filteredData = agentsData.filter(
      (data: any) => data.id === currentAgentId
    );
    if (filteredData.length) {
      setName(
        filteredData[0].firstKeyPerson?.firstName +
        " " +
        filteredData[0].firstKeyPerson?.lastName
      );
      setStatusViewDetails(filteredData[0].statusViewDetails);
      setAgentStatusCode(filteredData[0].status);
      setAgentId(filteredData[0].id);
      setCompanyName(filteredData[0].companyName);
      if (filteredData[0].status >= 10) {
        fetchAgentContractData(filteredData[0].id);
      }
    }
  }, []);

  useEffect(() => {
    if (agentId !== 0) {
      fetchSubmittedDocuments(agentId);
    }
  }, [agentId]);

  useEffect(() => {
    if (agentContractDetailsReducer.agentContract.receivedContractFileKey) {
      setReceivedContractFileKey(
        agentContractDetailsReducer.agentContract.receivedContractFileKey
      );
    }
  }, [agentContractDetailsReducer]);

  useEffect(() => {
    if (
      agentContractAttachedFileReducer.agentContractFile.length !== 0 &&
      isContractSaveButtonClicked
    ) {
      setIsContractSaveButtonClicked(false);
      const url = window.URL.createObjectURL(
        new Blob([agentContractAttachedFileReducer.agentContractFile], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${receivedContractFileKey}.pdf`);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }
  }, [agentContractAttachedFileReducer]);

  useEffect(() => {
    if (
      downloadSupportingDocumentsReducer.supportingDocument.length !== 0 &&
      isDownLoadSupportingDocumentClicked !== ""
    ) {
      const url = window.URL.createObjectURL(
        new Blob([downloadSupportingDocumentsReducer.supportingDocument], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${isDownLoadSupportingDocumentClicked}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }
  }, [downloadSupportingDocumentsReducer]);

  useEffect(() => {
    if (
      agentApplicationFormFileReducer.agentApplicationFormFile.length !== 0 &&
      isSaveFormButtonClicked
    ) {
      setIsSaveFormButtonClicked(false);
      const url = window.URL.createObjectURL(
        new Blob([agentApplicationFormFileReducer.agentApplicationFormFile], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Application Form ${agentId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }
  }, [agentApplicationFormFileReducer]);

  const handleDownloadSignedContractClick = () => {
    fetchContractFile(receivedContractFileKey);
    setIsContractSaveButtonClicked(true);
  };

  const handleDownloadApplicationFormClick = () => {
    fetchFetchApplicationFormFile(agentId);
    setIsSaveFormButtonClicked(true);
  };

  const handleDownloadCompanyCertificateClick = () => {
    fetchSupportingDocuments(agentId, "COMPANY_REGISTRATION_CERT");
    setIsDownLoadSupportingDocumentClicked("COMPANY_REGISTRATION_CERT");
  };

  const handleDownloadCompanyProfileClick = () => {
    fetchSupportingDocuments(agentId, "COMPANY_PROFILE");
    setIsDownLoadSupportingDocumentClicked("COMPANY_PROFILE");
  };

  const handleDownloadProfessionalAccreditationClick = () => {
    fetchSupportingDocuments(agentId, "PROFESSIONAL_ACCREDITATION");
    setIsDownLoadSupportingDocumentClicked("PROFESSIONAL_ACCREDITATION");
  };

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor="right"
          open={true}
          onClose={() => onSideBarClose(false)}
        >
          <div className={classes.mainContainer}>
            <div className={classes.header}>
              <div className={classes.crossButtonContainer}>
                <div>
                  <CloseIcon
                    onClick={() => onSideBarClose(false)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              <Grid container spacing={1}>
                <Grid item md={4} xs={12} xl={11} lg={11}>
                  <div className={classes.companyName}>{companyName}</div>
                  <div className={classes.name}>{name}</div>
                </Grid>
                <Grid item md={4} xs={12} xl={1} lg={1}></Grid>
              </Grid>
              <div>
                <StatusChip item={statusViewDetails} />
              </div>
              <div style={{ marginTop: "20px" }}>
                {receivedContractFileKey && agentStatusCode >= 10 && (
                  <div
                    className={classes.downloadButton}
                    onClick={handleDownloadSignedContractClick}
                  >
                    <Grid container spacing={1}>
                      <Grid item md={4} xs={12} xl={10} lg={10}>
                        Download Signed Contract
                      </Grid>
                      <Grid
                        item
                        md={4}
                        xs={12}
                        xl={2}
                        lg={2}
                        style={{ textAlign: "right" }}
                      >
                        <CloudDownloadIcon style={{ fontSize: "18px" }} />
                      </Grid>
                    </Grid>
                  </div>
                )}
                {agentStatusCode >= 3 && (
                  <>
                    <div
                      className={classes.downloadButton}
                      onClick={handleDownloadApplicationFormClick}
                    >
                      <Grid container spacing={1}>
                        <Grid item md={4} xs={12} xl={10} lg={10}>
                          Download Application Form
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={12}
                          xl={2}
                          lg={2}
                          style={{ textAlign: "right" }}
                        >
                          <CloudDownloadIcon style={{ fontSize: "18px" }} />
                        </Grid>
                      </Grid>
                    </div>
                    <div
                      className={classes.downloadButton}
                      onClick={handleDownloadCompanyCertificateClick}
                    >
                      <Grid container spacing={1}>
                        <Grid item md={4} xs={12} xl={10} lg={10}>
                          Company Certificate
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={12}
                          xl={2}
                          lg={2}
                          style={{ textAlign: "right" }}
                        >
                          <CloudDownloadIcon style={{ fontSize: "18px" }} />
                        </Grid>
                      </Grid>
                    </div>
                    <div
                      className={classes.downloadButton}
                      onClick={handleDownloadCompanyProfileClick}
                    >
                      <Grid container spacing={1}>
                        <Grid item md={4} xs={12} xl={10} lg={10}>
                          Company Profile
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={12}
                          xl={2}
                          lg={2}
                          style={{ textAlign: "right" }}
                        >
                          <CloudDownloadIcon style={{ fontSize: "18px" }} />
                        </Grid>
                      </Grid>
                    </div>
                    {/* <div
                      className={classes.downloadButton}
                      onClick={handleDownloadProfessionalAccreditationClick}
                    >
                      <Grid container spacing={1}>
                        <Grid item md={4} xs={12} xl={10} lg={10}>
                          Professional Accreditation
                        </Grid>
                        <Grid
                          item
                          md={4}
                          xs={12}
                          xl={2}
                          lg={2}
                          style={{ textAlign: "right" }}
                        >
                          <CloudDownloadIcon style={{ fontSize: "18px" }} />
                        </Grid>
                      </Grid>
                    </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default SideBar;
