import React, { useEffect, useState } from "react";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import pdfIcon from "../../ReferenceCheckModal/UploadFileModal/PDF.png";
import FilesDropzone from "./FileDropZone";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginTop: "20px",
      padding: "0 10%",
    },
    sectionHeading: {
      color: "#5C5F74",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: "15px",
    },
    pdfIconImage: {
      width: "16px",
      height: "16px",
    },
    fileName: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      color: "#727487",
      width: "80%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    fileSize: {
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: 500,
      color: "#727487",
      padding: "3px",
      textAlign: "center",
      border: "1px solid #727487",
    },
    fileInfoContainer: {
      width: "100%",
    },
  })
);

interface AttachContractProps {
  getContractAttachment: any;
  getAnnexureAttachment: any;
  contractAttachedFile: any;
  removeAnnexureAttachment: (index: number) => void;
}

const AttachContract: React.FC<AttachContractProps> = ({
  getContractAttachment,
  getAnnexureAttachment,
  contractAttachedFile,
  removeAnnexureAttachment,
}) => {
  const classes = useStyles();

  const [contractDroppedFileInfo, setContractDroppedFileInfo] = useState({
    fileName: contractAttachedFile.name || "",
    isError: contractAttachedFile ? true : false,
    file: contractAttachedFile || [],
    size: contractAttachedFile.size || 0,
  });

  const [annexureDroppedFileInfo, setAnnexureDroppedFileInfo] = useState<any>(
    []
  );
  const handleContractFileDrop = (file: any) => {
    if (file.length) {
      setContractDroppedFileInfo((prevState) => ({
        ...prevState,
        fileName: file[0].name,
        isError: false,
        file: file[0],
        size: file[0].size,
      }));
      getContractAttachment(file[0]);
    } else {
      setContractDroppedFileInfo((prevState) => ({
        ...prevState,
        fileName: "",
        isError: true,
        file: [],
        size: 0,
      }));
    }
  };

  const handleAnnexureFileDrop = (file: any) => {
    if (file.length) {
      setAnnexureDroppedFileInfo((prevState: any) => [
        ...prevState,
        {
          fileName: file[0].name,
          isError: false,
          file: file[0],
          size: file[0].size,
        },
      ]);
      getAnnexureAttachment(file[0]);
    }
  };

  const handleContractFileDelete = () => {
    setContractDroppedFileInfo((prevState) => ({
      ...prevState,
      fileName: "",
      isError: false,
      file: [],
      size: 0,
    }));
    getContractAttachment([]);
  };

  const handleAnnexureFileDelete = (index: number) => {
    const currentState = [...annexureDroppedFileInfo];
    currentState.splice(index, 1);
    setAnnexureDroppedFileInfo(currentState);
    removeAnnexureAttachment(index);
  };

  return (
    <>
      <div className={classes.section}>
        <div className={classes.sectionHeading}>Attach Contract</div>
        {!contractDroppedFileInfo.fileName ? (
          <FilesDropzone onFileDrop={handleContractFileDrop} />
        ) : (
          <div className={classes.fileInfoContainer}>
            <div>
              <Grid container spacing={1}>
                <Grid item md={4} xs={12} xl={2} lg={1}>
                  <img src={pdfIcon} className={classes.pdfIconImage} />
                </Grid>
                <Grid item md={4} xs={12} xl={8} lg={8}>
                  <div className={classes.fileName}>
                    {contractDroppedFileInfo.fileName}
                  </div>
                </Grid>
                <Grid item md={4} xs={12} xl={2} lg={2}>
                  <div className={classes.fileSize}>
                    {Math.trunc(contractDroppedFileInfo.size / 1024) + "KB"}
                  </div>
                </Grid>
                <Grid
                  item
                  md={4}
                  xs={12}
                  xl={1}
                  lg={1}
                  style={{ textAlign: "right" }}
                >
                  <DeleteOutlinedIcon
                    onClick={handleContractFileDelete}
                    style={{ cursor: "pointer" }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>

      <div className={classes.section}>
        <div className={classes.sectionHeading}>Attach Annexure</div>
        {annexureDroppedFileInfo && annexureDroppedFileInfo.length > 0 && (
          <div
            className={classes.fileInfoContainer}
            style={{ marginBottom: 15 }}
          >
            {annexureDroppedFileInfo.map((fileObj: any, index: number) => {
              return (
                <div key={index}>
                  <Grid container spacing={1}>
                    <Grid item md={4} xs={12} xl={2} lg={1}>
                      <img src={pdfIcon} className={classes.pdfIconImage} />
                    </Grid>
                    <Grid item md={4} xs={12} xl={8} lg={8}>
                      <div className={classes.fileName}>{fileObj.fileName}</div>
                    </Grid>
                    <Grid item md={4} xs={12} xl={2} lg={2}>
                      <div className={classes.fileSize}>
                        {Math.trunc(fileObj.size / 1024) + "KB"}
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={12}
                      xl={1}
                      lg={1}
                      style={{ textAlign: "right" }}
                    >
                      <DeleteOutlinedIcon
                        onClick={() => handleAnnexureFileDelete(index)}
                        style={{ cursor: "pointer" }}
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </div>
        )}
        {!annexureDroppedFileInfo ||
          (annexureDroppedFileInfo.length < 2 && (
            <FilesDropzone onFileDrop={handleAnnexureFileDrop} />
          ))}
      </div>
    </>
  );
};

export default AttachContract;
