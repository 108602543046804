import { RootState } from "../../../../reducer";

export const selectLPOPaginatedData = (state: RootState) =>
  state.lpo.paginatedData;

export const selectLPOModalAPIStatus = (state: RootState) =>
  state.lpo.modalAPIStatus;

export const selectedLPODetails = (state: RootState) =>
  state.lpo.selectedLPODetails;

export const lpoDashboardData = (state: RootState) =>
  state.lpo.lpoDashboardData;
