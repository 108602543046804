/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import io from 'socket.io-client';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import logout from './images/logout.png';
import AssessmentIcon from '@material-ui/icons/Assessment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useSnackbar } from 'notistack';
import { Badge, colors, Drawer, Hidden, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { RootState } from '../../../reducer';
import {
  addNotificationEvents,
  clearAllNotification,
  deferApplication,
  downloadUserProfile,
  getAllNotifications,
  markAsClose,
  markAsRead,
  setLogout,
  setNotificationTrayOpened,
  toggleAppDrawer,
} from '../../../actions';
import { connect, ConnectedProps } from 'react-redux';
import SBText from '../../base/SBText';
import HomeIcon from '@material-ui/icons/Home';
import SchoolIcon from '@material-ui/icons/School';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MailIcon from '@material-ui/icons/Mail';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import NotificationPopover from './NotificationPopover';
import { getFormattedNotificationList } from '../../../selector/notification';
import { encryptText } from '../../../util';
import config from '../../../config';
import { NAV_MENU } from '../../../util/constants';
import { hasPermission, PERMISSION } from '../../../util/rolePermissionsUtil';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { PAGE_ROUTE } from '../../../util/pageRoutes';
import { useUserPermissions } from '../../../hooks/useUserPermissions';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    mobileDrawer: {
      width: 256,
    },
    desktopDrawer: {
      width: 256,
      top: 68,
      height: 'calc(100% - 68px)',
    },
    navigation: {
      overflow: 'auto',
      padding: theme.spacing(0, 2, 2, 2),
      flexGrow: 1,
    },
    profile: {
      padding: theme.spacing(2),
      paddingLeft: 0,
      display: 'flex',
      alignItems: 'center',
    },
    badge: {
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
    badgeDot: {
      height: 9,
      minWidth: 9,
    },
    onlineBadge: {
      backgroundColor: colors.green[600],
    },
    awayBadge: {
      backgroundColor: colors.orange[600],
    },
    busyBadge: {
      backgroundColor: colors.red[600],
    },
    offlineBadge: {
      backgroundColor: colors.grey[300],
    },
    avatar: {
      cursor: 'pointer',
      width: 40,
      height: 40,
    },
    details: {
      marginLeft: theme.spacing(2),
    },
    moreButton: {
      marginLeft: 'auto',
      color: colors.blueGrey[200],
    },

    appBar: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: 'white',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        width: `calc(100% - 0px)`,
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    companylogo: {
      height: 110,
      [theme.breakpoints.down('xs')]: {
        height: 75,
        width: 140,
        margin: '0 20px',
      },
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      height: 130,
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },

    topMenuParent: {
      display: 'flex',
      justifyContent: 'center',
      height: 60,
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      height: '100vh',
      width: 'calc(100% - 73px)',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      backgroundColor: '#F1F1F1',
    },
    notificationsButton: {
      marginLeft: theme.spacing(1),
    },
    notificationsBadge: {
      backgroundColor: colors.orange[600],
    },
    profileButton: {
      marginLeft: theme.spacing(1),
    },
    profileIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

type MenuItem = { text: string; url: string };

type PropsFromRedux = ConnectedProps<typeof connector>;
type NavBarProps = PropsFromRedux & {
  openMobile: boolean | undefined;
  onMobileClose: () => void;
  className?: string;
  handleStatusToggle?: () => void;
  session?: any;
  children: any;
};

const NavBar: React.FC<NavBarProps> = ({
  openMobile,
  onMobileClose,
  className,
  handleStatusToggle,
  session,
  children,
  toggleAppDrawer,
  drawerOpen,
  user,
  setLogout,
  addNotificationEvents,
  clearAllNotification,
  notifications,
  getAllNotifications,
  markAsRead,
  markAsClose,
  totalNotificationCount,
  commentNotification,
  reminderNotification,
  notificationTrayOpened,
  setNotificationTrayOpened,
  currentApplicationDetails,
  sbApplication,
  deferApplication,
  companyProfilePicture,
  downloadUserProfile,
  fileUploadSnakbar,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [status, setStatus] = useState('online');
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deferVisible, setDeferVisible] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = React.useState<null | HTMLElement>(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const open = Boolean(anchorEl);
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    let socket: any;
    if (user) {
      socket = io.connect(config.BASE_URL, { transports: ['websocket'] });
      socket.on(user.user.email, (data: any) => {
        addNotificationEvents(data);
      });

      if (user && user.user.companyProfileUrl && !companyProfilePicture) {
        downloadUserProfile(user.user.id);
      }

      if (hasPerm(PERMISSION.notifications)) {
        getAllNotifications();
      }
    }

    return () => {
      if (socket) {
        socket.close();
        clearAllNotification();
      }
    };
  }, []);

  const action = (snackbarId: any) => (
    <>
      <button
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
        style={{ backgroundColor: 'transparent', border: 'none', color: 'white', fontSize: 12 }}
      >
        Dismiss
      </button>
    </>
  );

  useEffect(() => {
    if (fileUploadSnakbar) {
      if (fileUploadSnakbar.success) {
        enqueueSnackbar(
          <div>
            <strong style={{ fontWeight: 800 }}>File upload successful.</strong> <br />
            <strong style={{ fontWeight: 600 }}>{fileUploadSnakbar.groupName}</strong>
            {` -> ${fileUploadSnakbar.question}`}
          </div>,
          { variant: 'success', autoHideDuration: 3000 }
        );
      } else {
        enqueueSnackbar(
          <div>
            <strong>File upload failed.</strong> <br />
            {`${fileUploadSnakbar.groupName} -> ${fileUploadSnakbar.question}`}
          </div>,
          { variant: 'error', action: action, persist: true }
        );
      }
    }
  }, [fileUploadSnakbar]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onLogoutClick = () => {
    setAnchorEl(null);
    history.push('/login');
    setLogout();
  };

  const handleDrawerOpen = () => {
    toggleAppDrawer();
  };

  const handleDrawerClose = () => {
    toggleAppDrawer();
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    const currentPage = location.pathname === PAGE_ROUTE.HOME ? '/' : location.pathname;
    const menuIndex = getMenuItems().findIndex((menuItem: MenuItem) => menuItem.url === currentPage);
    setSelectedIndex(menuIndex);
    setDeferVisible(menuIndex === -1);
  }, [location.pathname]);

  const hasPerm = (perm: string) => {
    return hasPermission(perm, userPermissions);
  };

  const handleNotificationClick = (event: any) => {
    setPopoverAnchor(event.currentTarget);
    setNotificationTrayOpened();
  };

  const onNotificationListItemClick = (notificationObject: any) => {
    if (notificationObject?.category != 'REPORT') {
      setPopoverAnchor(null);
      if (
        (notificationObject.headerComments.includes('Status update') &&
          notificationObject.headerComments.includes('More Information Required')) ||
        notificationObject.headerComments.includes('Required documents are missing on application')
      ) {
        if (location.pathname.startsWith('/files/')) {
          history.replace('/files/' + encryptText(notificationObject.applicationId) + '/Registration');
        } else {
          history.push('/files/' + encryptText(notificationObject.applicationId) + '/Registration');
        }
      } else {
        if (location.pathname.startsWith('/app/')) {
          history.replace(
            '/app/' +
              encryptText(notificationObject.applicationId) +
              '/group/' +
              encryptText(notificationObject.groupId) +
              '/question/' +
              encryptText(notificationObject.questionId)
          );
        } else {
          history.push(
            '/app/' +
              encryptText(notificationObject.applicationId) +
              '/group/' +
              encryptText(notificationObject.groupId) +
              '/question/' +
              encryptText(notificationObject.questionId)
          );
        }
      }
    }

    if (!notificationObject.read) {
      const body = [];
      body.push(notificationObject.id);
      markAsRead(body);
    }
  };

  const onNotificationCloseItemClick = (notificationObject: any) => {
    const body = [];
    body.push(notificationObject.id);
    markAsClose(body);
  };

  const onCloseAllNotification = (notificationObject: any) => {
    markAsClose(notificationObject);
  };

  const getMaterialImageIcon = (menu: MenuItem, index: number) => {
    const commonStyle = {
      fill: selectedIndex == index ? '#FFFFFF' : '#5C5F72',
    };
    let icon;
    switch (menu.text) {
      case NAV_MENU.APPLICATIONS:
      case NAV_MENU.LEADS:
        icon = <SchoolIcon style={commonStyle} />;
        break;
      case NAV_MENU.VERIFICATION_INBOX:
        icon = <MailIcon style={commonStyle} />;
        break;
      case NAV_MENU.DMS:
        icon = <FileCopyIcon style={commonStyle} />;
        break;
      case NAV_MENU.AGENTS:
        icon = <AccountCircleIcon style={commonStyle} />;
        break;
      case NAV_MENU.REPORTS:
        icon = <AssessmentIcon style={commonStyle} />;
        break;

      case NAV_MENU.LPO:
      case NAV_MENU.USER_MANAGMENT:
        icon = <PeopleAltIcon style={commonStyle} />;
        break;
      case NAV_MENU.DASHBOARD:
      default:
        icon = <HomeIcon style={commonStyle} />;
    }
    return icon;
  };

  const onItemClick = (menu: MenuItem, index: number) => {
    if (selectedIndex != index) {
      setSelectedIndex(index);
      navigateTo(menu.url);
      if (isMobileView) {
        handleDrawerClose();
      }
    }
  };

  const navigateTo = (url: string) => {
    url && history.push(url);
  };

  const getMenuItems = () => {
    const menu: MenuItem[] = [];
    if (hasPerm(PERMISSION.dashboard)) {
      // TODO: Reenable after dashboard is fixed
      // menu.push({
      //   text: NAV_MENU.DASHBOARD,
      //   url: PAGE_ROUTE.DASHBOARD,
      // });
    }

    if (hasPerm(PERMISSION.applications_agent_view)) {
      // TODO: Enable when agents are allowed to view application details
      menu.push({
        text: NAV_MENU.APPLICATIONS,
        url: PAGE_ROUTE.APP_LIST_AGENCY,
      });
    } else if (hasPerm(PERMISSION.applications_provider_view)) {
      // provider view
      menu.push({
        text: NAV_MENU.APPLICATIONS,
        url: PAGE_ROUTE.APP_LIST_PROVIDER,
      });
    }

    if (hasPerm(PERMISSION.verifications)) {
      menu.push({
        text: NAV_MENU.VERIFICATION_INBOX,
        url: PAGE_ROUTE.INBOX,
      });
    }

    if (hasPerm(PERMISSION.dms)) {
      menu.push({
        text: NAV_MENU.DMS,
        url: PAGE_ROUTE.DMS,
      });
    }

    if (hasPerm(PERMISSION.reports)) {
      menu.push({
        text: NAV_MENU.REPORTS,
        url: PAGE_ROUTE.REPORTS,
      });
    }
    // FIXME: Tmp hide this company profile menu
    // if (hasPerm(PERMISSION.common)) {
    //   menu.push({
    //     text: NAV_MENU.COMPANY_PROFILE,
    //     url: PAGE_ROUTE.COMPANY,
    //   });
    // }

    if (hasPerm(PERMISSION.ta_view)) {
      menu.push({
        text: NAV_MENU.LPO,
        url: PAGE_ROUTE.TA,
      });
    }

    if (hasPerm(PERMISSION.ta_leads_view) || hasPerm(PERMISSION.ta_view)) {
      menu.push({
        text: NAV_MENU.LEADS,
        url: PAGE_ROUTE.LEADS,
      });
    }

    if (hasPerm(PERMISSION.agentManagement)) {
      menu.push({
        text: NAV_MENU.AGENTS,
        url: PAGE_ROUTE.AGENTS,
      });
    }

    if (hasPerm(PERMISSION.user)) {
      menu.push({
        text: NAV_MENU.USER_MANAGMENT,
        url: PAGE_ROUTE.USERS,
      });
    }

    //Select first available tab on home/empty route
    if (menu.length > 0 && (location.pathname === PAGE_ROUTE.HOME || location.pathname === '/')) {
      onItemClick(menu[0], 0);
    }

    return menu;
  };

  const renderDraweContent = () => {
    return (
      <>
        <div className={classes.topMenuParent}>
          <IconButton onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}>
            {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <List>
            {getMenuItems().map((menu, index) => (
              <ListItem button key={menu.text} onClick={() => onItemClick(menu, index)}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: !drawerOpen ? 'center' : undefined,
                    paddingLeft: drawerOpen ? 20 : undefined,
                    backgroundColor: selectedIndex === index ? '#3D405B' : 'transparent',
                    width: '100%',
                    height: 40,
                    borderRadius: 25,
                  }}
                >
                  {getMaterialImageIcon(menu, index)}
                  {drawerOpen ? (
                    <SBText
                      text={menu.text}
                      style={{
                        fontSize: 14,
                        color: selectedIndex == index ? 'white' : '#3D405B',
                        marginLeft: '7%',
                      }}
                    />
                  ) : null}
                </div>
              </ListItem>
            ))}
          </List>

          <div className={classes.toolbar}>
            <img
              src="/images/logo_large.png"
              className={classes.companylogo}
              onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}
            />

            {!drawerOpen ? (
              <div
                style={{
                  position: 'absolute',
                  width: '100%',
                  backgroundColor: 'white',
                  height: 70,
                  bottom: 0,
                }}
              ></div>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {!drawerOpen && isMobileView ? (
            <IconButton onClick={drawerOpen ? handleDrawerClose : handleDrawerOpen}>
              {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>
          ) : null}

          {companyProfilePicture ? (
            <img src={`data:image/png;base64,${companyProfilePicture}`} style={{ height: 40, marginLeft: 70 }} />
          ) : (
            <div />
          )}

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Hidden>
              {hasPerm(PERMISSION.notifications) && (
                <IconButton className={classes.notificationsButton} color="inherit" onClick={handleNotificationClick}>
                  <Badge
                    badgeContent={totalNotificationCount}
                    classes={{ badge: classes.notificationsBadge }}
                    invisible={notificationTrayOpened || totalNotificationCount == 0}
                  >
                    <NotificationsIcon color={'secondary'} />
                  </Badge>
                </IconButton>
              )}
              <div>
                <IconButton className={classes.notificationsButton} color="inherit" onClick={handleMenu}>
                  <Badge badgeContent={3} classes={{ badge: classes.notificationsBadge }} variant="dot">
                    <PersonOutlineOutlinedIcon color={'secondary'} />
                  </Badge>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    style={{
                      fontSize: 16,
                      fontFamily: 'Poppins',
                      letterSpacing: 0.75,
                      fontWeight: 600,
                      height: 40,
                      color: '#5C5F74',
                    }}
                  >
                    {'Welcome ' + user.user.name}
                  </MenuItem>

                  <MenuItem
                    onClick={onLogoutClick}
                    style={{
                      fontSize: 16,
                      fontFamily: 'Poppins',
                      letterSpacing: 0.75,
                      fontWeight: 600,
                      // color: "red",
                    }}
                  >
                    <img
                      src={logout}
                      style={{
                        height: 30,
                        objectFit: 'contain',
                        marginRight: 10,
                      }}
                    />
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            </Hidden>
          </div>
        </Toolbar>
      </AppBar>

      {isMobileView ? (
        <Drawer anchor={'left'} open={drawerOpen} onClose={handleDrawerClose}>
          {renderDraweContent()}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            }),
          }}
        >
          {renderDraweContent()}
        </Drawer>
      )}

      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>

      <NotificationPopover
        notifications={notifications}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        onListItemClick={onNotificationListItemClick}
        onCloseNotificationItem={onNotificationCloseItemClick}
        reminderNotification={reminderNotification}
        commentNotification={commentNotification}
        onCloseAll={onCloseAllNotification}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  drawerOpen: state.application.drawerOpen,
  user: state.user.user,
  notifications: getFormattedNotificationList(state),
  totalNotificationCount: state.application.totalNotificationCount,
  commentNotification: state.application.commentNotification,
  reminderNotification: state.application.reminderNotification,
  notificationTrayOpened: state.application.notificationTrayOpened,
  // @ts-ignore
  sbApplication: state.sbApplication.sbApplication,
  // @ts-ignore
  currentApplicationDetails: state.sbApplication.currentApplicationDetails,
  companyProfilePicture: state.user.companyProfilePicture,
  fileUploadSnakbar: state.sbApplication.fileUploadSnakbar,
});

const mapDispatch = {
  toggleAppDrawer,
  setLogout,
  addNotificationEvents,
  clearAllNotification,
  getAllNotifications,
  markAsRead,
  markAsClose,
  setNotificationTrayOpened,
  deferApplication,
  downloadUserProfile,
};

const connector = connect(mapState, mapDispatch);
export default connector(NavBar);
