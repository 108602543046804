import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionTypes,
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../actions";
import config from "../../../config";

export const useFetchLegacyAgent = () => {
  const dispatch = useDispatch();

  const fetchLegacyData = useCallback(
    async (offset, limit) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(
          `${config.BASE_URL}/agent/legacy?offset=${offset}&limit=${limit}`,
          dispatch
        );
        dispatch({ type: ActionTypes.SET_LEGACY_AGENT_LIST, payload: result });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    fetchLegacyData,
  };
};
