// @ts-nocheck
import thunk, { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { get, post, put, postWithHeader, autoVerificationPost, remove, uploadFileS3, getWithNoAuth } from './common';
import {
  ON_INTERNAL_LOADING_START,
  ON_INTERNAl_LOADING_FINISHED,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from './sessionActions';
import config from '../config';
import { QuestionType } from './util/QuestionType';
import fileDownload from 'js-file-download';
import { getApplicationIdInString, isProvidersWithSameCountry, toGetRequestURLParam } from '../util';
import { UserRoles } from '../constants/UserRoles';
import { getRoleByPermissions, hasOneOfPermissions, extractPermissions, PERMISSION } from '../util/rolePermissionsUtil';
import { Roles } from '../constants/Roles';
import { API_URL } from '../util/apiUrls';
import { getGroupStatusByQuestionAnswer, GroupStatus } from '../views/SBApplication/SBApplicationUtils';
import _ from 'lodash';
import { VIEW_FILE } from './fileViewer';
import { SET_ERROR } from './appError';

export const UPDATE_SB_APPLICATION_VALUE = 'UPDATE_SB_APPLICATION_VALUE';
export const ADD_FILES_IN_SB_APPLICATION = 'ADD_FILES_IN_SB_APPLICATION';
export const UPDATE_SB_ACADEMIC_APPLICATION_VALUE = 'UPDATE_SB_ACADEMIC_APPLICATION_VALUE';
export const SHOW_HIDE_DRAWER = 'SHOW_HIDE_DRAWER';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_BLOCK_STATUSES = 'GET_BLOCK_STATUSES';

export const GET_THE_OUTCOME_BY_TYPE = 'GET_THE_OUTCOME_BY_TYPE';

export const OPEN_EMAIL_TEMPLATE_IN_MODEL = 'OPEN_EMAIL_TEMPLATE_IN_MODEL';

export const UPDATE_FUND_INFO = 'UPDATE_FUND_INFO';
export const SET_BLOCKS = 'SET_BLOCKS';

export const SET_QUESTIONS_BY_GROUP = 'SET_QUESTIONS_BY_GROUP';
export const UPDATE_QUESTION_SET_WITH_ANSWER = 'UPDATE_QUESTION_SET_WITH_ANSWER';

export const SET_LAST_QUESTION = 'SET_LAST_QUESTION';
export const START_APPLICATION = 'START_APPLICATION';
export const RESET_SB_APPLICATION = 'RESET_SB_APPLICATION';
export const SET_QUESTION_COUNTER = 'SET_QUESTION_COUNTER';
export const UPDATE_BLOCK_STATUS = 'UPDATE_BLOCK_STATUS';
export const SET_CURRENT_ACTIVITY_LOGS = 'SET_CURRENT_ACTIVITY_LOGS';
export const SET_CURRENT_COMMENTS = 'SET_CURRENT_COMMENTS';
export const DELETE_FILE_LOCALLY = 'DELETE_FILE_LOCALLY';

export const SET_APPLICATION_LIST = 'SET_APPLICATION_LIST';
export const SET_PROVIDER_APPLICATION_LIST = 'SET_PROVIDER_APPLICATION_LIST';
export const RESET_APPLICATION_LIST = 'RESET_APPLICATION_LIST';
export const SET_SHOW_HIDE_QUESTIONS = 'SET_SHOW_HIDE_QUESTIONS';
export const UPDATE_APPLICATION_SEARCH_PARAMS = 'UPDATE_APPLICATION_SEARCH_PARAMS';

export const UPDATE_OUT_COMES_IN_PROVIDER_APPLICATION_LIST = 'UPDATE_OUT_COMES_IN_PROVIDER_APPLICATION_LIST';
export const GET_APPLICATION_STATUS_MASTER = 'GET_APPLICATION_STATUS_MASTER';
export const ADD_NEW_REPEAT_QUESTION = 'ADD_NEW_REPEAT_QUESTION';
export const SET_TAB_INDEX = 'SET_TAB_INDEX';
export const RESET_TAB_INDEX = 'RESET_TAB_INDEX';
export const REMOVE_INVALID_ANSWER_FROM_LAST_QUESTION = 'REMOVE_INVALID_ANSWER_FROM_LAST_QUESTION';

export const ADD_NOTIFICATION_EVENT = 'ADD_NOTIFICATION_EVENT';
export const CLEAR_NOTIFICATION_EVENT = 'CLEAR_NOTIFICATION_EVENT';
export const UPDATE_PROVIDER_LIST = 'UPDATE_PROVIDER_LIST';
export const RESET_APPLICATION_LIST_FOR_FILTER = 'RESET_APPLICATION_LIST_FOR_FILTER';

export const USER_AGENT_LIST = 'USER_AGENT_LIST';

export const SET_DMS_APPLICATION_LIST = 'SET_DMS_APPLICATION_LIST';

export const SET_DMS_APPLICATION_DETAILS = 'SET_DMS_APPLICATION_DETAILS';
export const SET_DMS_QUESTION = 'SET_DMS_QUESTION';
export const SET_SB_APP_FILTER_LIST = 'SET_SB_APP_FILTER_LIST';
export const UPDATE_SB_FILTER_VALUE = 'UPDATE_SB_FILTER_VALUE';

export const CLEAR_SB_FILTER_VALUE = 'CLEAR_SB_FILTER_VALUE';
export const SET_SB_FILTER_OBJECT = 'SET_SB_FILTER_OBJECT';
export const SHOW_LOCK_APPLICATION_DIALOG = 'SHOW_LOCK_APPLICATION_DIALOG';
export const LOCK_APPLICATION = 'LOCK_APPLICATION';

export const DEFER_APPLICATION_SUCCESS = 'DEFER_APPLICATION_SUCCESS';
export const DEFER_APPLICATION_SUCCESS_BY_POVNT = 'DEFER_APPLICATION_SUCCESS_BY_POVNT';

export const SET_APPLICATION_STATUS_LIST = 'SET_APPLICATION_STATUS_LIST';
export const SET_QUESTION_REVIEW_HISTORY = 'SET_QUESTION_REVIEW_HISTORY';
export const SET_APPLICATION_SUB_STATUS_LIST = 'SET_APPLICATION_SUB_STATUS_LIST';
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST';
export const UPDATE_COUNTRY_PROVIDER = 'UPDATE_COUNTRY_PROVIDER';
export const SET_COUNTRY_DETAIL_UPDATE = 'SET_COUNTRY_DETAIL_UPDATE';
export const SET_TERMS_LIST_AS_PER_COUNTRY = 'SET_TERMS_LIST_AS_PER_COUNTRY';
export const RESET_DMS_APPLICATION_DETAIL = 'RESET_DMS_APPLICATION_DETAIL';
export const SET_APPLICATION_PROVIDER_LIST = 'SET_APPLICATION_PROVIDER_LIST';
export const SET_LOCK_UNLOCK_QUESTION_STATUS = 'SET_LOCK_UNLOCK_QUESTION_STATUS';
export const SET_LOCK_UNLOCK_DMS_FILE_STATUS = 'SET_LOCK_UNLOCK_DMS_FILE_STATUS';
export const UPDATE_BLOCK_LOCK_UNLOCK_STATUS = 'UPDATE_BLOCK_LOCK_UNLOCK_STATUS';
export const RESET_CART_COUNTER = 'RESET_CART_COUNTER';

export const SET_REJECTION_REASON = 'SET_REJECTION_REASON';
export const UPDATE_OUTCOME_REFERENCE_COMMENTS = 'UPDATE_OUTCOME_REFERENCE_COMMENTS';
export const ADD_LOCK_DETAIL_IN_APPLICATION = 'ADD_LOCK_DETAIL_IN_APPLICATION';
export const RESET_USER_AGENT_LIST = 'RESET_USER_AGENT_LIST';
export const UPDATE_APPLICATION_SUB_STATUS_AND_STATUS = 'UPDATE_APPLICATION_SUB_STATUS_AND_STATUS';
export const RESEET_DELETED_APPLICATION = 'RESEET_DELETED_APPLICATION';
export const UPDATE_CURRENT_APPLICATION_LOCK_STATAUS = 'UPDATE_CURRENT_APPLICATION_LOCK_STATAUS';
export const UPDATE_SB_FILTER_LIST = 'UPDATE_SB_FILTER_LIST';
export const RESET_LAST_QUESTION_UPDATE = 'RESET_LAST_QUESTION_UPDATE';
export const UPDATE_APPLICATION_ANSWER = 'UPDATE_APPLICATION_ANSWER';
export const UPDATE_DMS_FILE_UPLOAD_ANSWER_STATUS = 'UPDATE_DMS_FILE_UPLOAD_ANSWER_STATUS';
export const UPDATE_CURRENT_APPLICATION_DETAIL_ANSWER = 'UPDATE_CURRENT_APPLICATION_DETAIL_ANSWER';
export const UPDATE_LOCAL_GROUP_STATUS = 'UPDATE_LOCAL_GROUP_STATUS';
export const SET_CURRENT_APPLICATION_FILTERED_QUESTION_LIST = 'SET_CURRENT_APPLICATION_FILTERED_QUESTION_LIST';
export const SET_LOCAL_QUESTION_ANSWER = 'SET_LOCAL_QUESTION_ANSWER';
export const SET_QUESTION_STATUS_UPDATE = 'SET_QUESTION_STATUS_UPDATE';
export const RESET_QUESTION_STATUS_UPDATE = 'RESET_QUESTION_STATUS_UPDATE';
export const SET_FILTERED_QUESTION = 'SET_FILTERED_QUESTION';
export const RESET_SELECTED_COUNTRY_CHANGE_DETAILS = 'RESET_SELECTED_COUNTRY_CHANGE_DETAILS';
export const REVIEW_DOCUMENT_STATUS = 'REVIEW_DOCUMENT_STATUS';
export const SET_MANDATORY_FLAG = 'SET_MANDATORY_FLAG';
export const SET_FILES_AND_VERICOUNT = 'SET_FILES_AND_VERICOUNT';
export const SET_FILE_UPLOAD_PROGRESS = 'SET_FILE_UPLOAD_PROGRESS';
export const SET_FILE_UPLOAD_COMPLETE_PROGRESS = 'SET_FILE_UPLOAD_COMPLETE_PROGRESS';
export const SHOW_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR = 'SHOW_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR';
export const RESET_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR = 'RESET_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR';
export const DELETE_APPLICATIONS = 'DELETE_APPLICATIONS';
export const ADD_NEW_DOCUMENTS = 'ADD_NEW_DOCUMENTS';
export const MISSING_DOCUMENT_EMAIL_SUCCESS = 'MISSING_DOCUMENT_EMAIL_SUCCESS';
export const GET_PROCESSING_OFFICER_BY_EMAIL = 'GET_PROCESSING_OFFICER_BY_EMAIL';
export const ADD_PROCESSING_OFFICER_EMAIL='ADD_PROCESSING_OFFICER_EMAIL';
export const DELETE_PROCESSING_OFFICER_EMAIL = 'DELETE_PROCESSING_OFFICER_EMAIL';
export const ADD_NEW_EVENT = 'ADD_NEW_EVENT';
export const ADD_MISSING_DOCUMENT_COMMENTS = 'ADD_MISSING_DOCUMENT_COMMENTS';
export const APPLICATION_DETAIL_LOADING = 'APPLICATION_DETAIL_LOADING';
export const APPLICATION_DETAIL_LOADING_COMPLETED = 'APPLICATION_DETAIL_LOADING_COMPLETED';

export const APPLICATION_QUESTION_LOADING = 'APPLICATION_QUESTION_LOADING';
export const APPLICATION_QUESTION_LOADING_COMPLETED = 'APPLICATION_QUESTION_LOADING_COMPLETED';

export const getBlocks = (id: number): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    // console.log("getApplicationById ::::", id);
    dispatch({ type: ON_LOADING_START });
    return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });
      //Call API
      const result = await get(`${config.BASE_URL}/question/groups`);
      //console.log("get Block result::::", result);
      resolve(result);
      dispatch({ type: SET_BLOCKS, payload: result });
      dispatch({ type: ON_LOADING_FINISHED });
    });
  });
};

export const getApplicationStatus = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
  
    const { applicationDetailLoading } = getState().application;
    dispatch({ type: ON_LOADING_START });
    const result = await get(`${config.BASE_URL}/application/application-status-master`);
    dispatch({
      type: GET_APPLICATION_STATUS_MASTER,
      payload: result,
    });
    resolve(result);
    
    if (!applicationDetailLoading) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const getAllStatusList = (applicationId): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {

  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const { applicationDetailLoading } = getState().application;
    dispatch({ type: ON_LOADING_START });
    const result = await get(`${config.BASE_URL}/application/statusList/${applicationId}`);
    // console.log("****List ::::" + applicationId, result);
    dispatch({
      type: SET_APPLICATION_STATUS_LIST,
      payload: result,
    });
    resolve(result);
    
    if (!applicationDetailLoading) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const getSubStatusList = (applicationId): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getstate
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await get(`${config.BASE_URL}/application/subStatus/${applicationId}`);
    // console.log("****List1111 ::::" + applicationId, result);
    dispatch({
      type: SET_APPLICATION_SUB_STATUS_LIST,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const updateApplicationStatus = (
  applicationId: any,
  status: any,
  reason: any = null,
  other: any = null
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getstate) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await post(
      `${config.BASE_URL}/application/update-status`,
      { applicationId, status, reason, other },
      dispatch
    );
    resolve(result);
    if (result && result.status == 'success') {
      dispatch({ type: UPDATE_APPLICATION_SUB_STATUS_AND_STATUS, payload: { status, key: 'status' } });
    }
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const updateApplicationSubStatus = (
  applicationId: any,
  subStatus: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getstate) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await post(
      `${config.BASE_URL}/application/update-sub-status`,
      { applicationId, subStatus },
      dispatch
    );

    if (result && result.subStatusId) {
      dispatch({
        type: UPDATE_APPLICATION_SUB_STATUS_AND_STATUS,
        payload: { subStatus, subStatusId: result.subStatusId, key: 'sub_status' },
      });
    }

    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getQuestionsbyOutCome = (
  appId: any,
  outcomeType: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getstate) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/application/questions/${appId}/${outcomeType}`);
    // get pre question group id

    //console.log("outcome by type>>>>>>",result)
    dispatch({
      type: GET_THE_OUTCOME_BY_TYPE,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};
export const createNewApplication = (
  assignUserId: any = null
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    console.log('assignUserId ::: InCreate Application', assignUserId);
    dispatch({ type: ON_LOADING_START });

    const result = await post(`${config.BASE_URL}/application`, { assignUserId }, dispatch);
    // get pre question group id
    result.groups = _.sortBy(result.groups, ['id']);
    const preQuestionGroupId = result.groups[0].id;
    //console.log("createNewApplication Result  ", result);
    //console.log("Prequestion Group id", preQuestionGroupId);
    // await sleep(30000);
    await dispatch(getQuestionByGroup(preQuestionGroupId, true, true));
    dispatch({
      type: SET_BLOCKS,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getApplicationDetails = (
  id: any,
  directGroupId: any = -1,
  changeCourse: boolean = false,
  isPreQuestionUpdate: boolean = false
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    dispatch({ type: APPLICATION_DETAIL_LOADING });
    const result = await get(`${config.BASE_URL}/application/summary/${id}`, null, dispatch);
    console.log('get Application Details !!!!  ', result);
    result.groups = _.sortBy(result.groups, ['id']);
    let groupID = null;
    if (directGroupId != -1) {
      groupID = directGroupId;
      // console.log("111");
      // dispatch(getAllStatusList(id));
    } else {
      if (result.status.status === 'Draft') {
        // console.log("222");
        groupID = result.groups[0].id;
      } else {
        // console.log("333");
        // dispatch(getAllStatusList(id));
        groupID = result.groups[1].id;
      }
    }
    await dispatch({
      type: SET_BLOCKS,
      payload: result,
    });
    await dispatch(getQuestionByGroup(groupID, true, true, false, changeCourse));
    if (isPreQuestionUpdate) {
      await dispatch(getQuestionByGroup(result.groups[0].id, false, false, false, changeCourse));
    }

    //console.log("Application Details :::", result);
    resolve(result);
    // dispatch({ type: ON_LOADING_FINISHED });
    dispatch({ type: APPLICATION_DETAIL_LOADING_COMPLETED });
  });
};

export const getAgentUserList = (searchText: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/user/users/udetails/${searchText}`, null, dispatch);
    // console.log("Get User Agent List", result);
    await dispatch({
      type: USER_AGENT_LIST,
      payload: result,
    });

    //console.log("Application Details :::", result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getfilesCount = (appid: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    // dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/application/filesAndVeriCount/${appid}`, null, dispatch);
    // console.log("Get User Agent List", result);
    await dispatch({
      type: SET_FILES_AND_VERICOUNT,
      payload: result,
    });

    //console.log("Application Details :::", result);
    resolve(result);
      // dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getQuestionByGroup = (
  id: number,
  isChangeCurrentGroup: boolean,
  isCreateNewApplication: boolean = false,
  isFromLockUnlock: boolean = false,
  isFromChangeCourse: boolean = false
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    dispatch({type: APPLICATION_QUESTION_LOADING})

    const { applicationDetailLoading } = getState().application;
    const result = await get(`${config.BASE_URL}/application/questions/${id}`);

    let questionList = _.cloneDeep(result.questions);
    result.questions = await reArrangeQuestion(questionList, dispatch, true);
    //console.log("result.questions Reaaranged", result.questions);
    await dispatch({
      type: SET_QUESTIONS_BY_GROUP,
      payload: { result, isChangeCurrentGroup, isCreateNewApplication },
    });

    if (isFromLockUnlock) {
      dispatch({
        type: UPDATE_BLOCK_LOCK_UNLOCK_STATUS,
        payload: result,
      });
    } else {
      if (isFromChangeCourse) {
        dispatch(setQuestionCounter(7));
      } else {
        await dispatch({
          type: RESET_CART_COUNTER,
        });
      }
    }

    if (result.name != 'PreQuestions' && !('PreQuestions' in getState().sbApplication.sbApplication)) {
      // @ts-ignore
      const currentApps = getState().sbApplication.currentApplicationDetails;
      const perQuestionsGroup = _.find(currentApps.groups, (obj: any) => obj.name === 'PreQuestions');
      await dispatch(getQuestionByGroup(perQuestionsGroup.id, false));

      //console.log("current Appp ", currentApps);
      //console.log("perQuestionsGroup", perQuestionsGroup);
    }
    resolve(result);
    // if (!applicationDetailLoading) {
    dispatch({ type: ON_LOADING_FINISHED });
    dispatch({type: APPLICATION_QUESTION_LOADING_COMPLETED})
    // }
  });
};

export const getActivityLogsByQuestion = (id: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_INTERNAL_LOADING_START });

    const result = await get(`${config.BASE_URL}/activity-log/question/${id}`);
    // console.log("result By Activity Log", result);
    dispatch({
      type: SET_CURRENT_ACTIVITY_LOGS,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_INTERNAl_LOADING_FINISHED });
  });
};

export const setTabIndex = (
  key: any,
  value: any,
  update: boolean
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: SET_TAB_INDEX,
    payload: { key, value, update },
  });
};

const reArrangeQuestion = (question: any, dispatch: any = undefined, update: boolean = false) => {
  //console.log("question >>>", question);
  for (var i = question.length - 1; i >= 0; i--) {
    // Auction.auctions[i].seconds--;

    if (question[i].repeat) {
      question[i].tabs = [];
    }

    if (question[i].parentId) {
      const parentIndex = _.findIndex(question, (object: any) => object.id == question[i].parentId);

      if (parentIndex != -1) {
        if (!question[parentIndex].questionList) {
          question[parentIndex].questionList = [];
        }
        question[parentIndex].questionList.push(question[i]);
      }
      question.splice(i, 1);
    }
  }

  const mainQuestionObj = _.filter(question, function (o) {
    return !o.tabParentQuestionId;
  });
  const tabChild = _.filter(question, function (o) {
    return o.tabParentQuestionId;
  });

  for (var i = 0; i < tabChild.length; i++) {
    // Auction.auctions[i].seconds--;
    const parentIndex = _.findIndex(mainQuestionObj, (object: any) => object.id == tabChild[i].tabParentQuestionId);

    if (parentIndex != -1) {
      if (!mainQuestionObj[parentIndex].tabs) {
        mainQuestionObj[parentIndex].tabs = [];
      }

      if (update) {
        dispatch(setTabIndex(mainQuestionObj[parentIndex].id, 0, true));
      }

      mainQuestionObj[parentIndex].tabs.push(tabChild[i]);
    }
  }
  question = mainQuestionObj;

  for (var i = question.length - 1; i >= 0; i--) {
    if (question[i].questionList && question[i].questionList.length > 0) {
      // console.log("question[i].questionList", question[i].questionList[0]);
      question[i].questionList = _.sortBy(question[i].questionList, ['order']);
    }
    if (question[i].tabs && question[i].tabs.length > 0) {
      for (var j = 0; j < question[i].tabs.length; j++) {
        question[i].tabs[j].questionList = _.sortBy(question[i].tabs[j].questionList, [
          (o) => {
            return o.order;
          },
        ]);
      }
    }
  }
  if (question && question.length > 0 && question[0].questionOrder) {
    question = _.sortBy(question, (obj) => parseFloat(obj.questionOrder));
  } else {
    question = _.sortBy(question, ['order']);
  }
  return question;
};

/////application/2/answer/116

const getAnswerBody = (localAnswer: any) => {
  let body: any = {
    values: [],
  };
  const files = [];

  for (let key in localAnswer) {
    let answerObject = localAnswer[key];
    let answerdPrepareObj = {};
    if (
      answerObject.type === QuestionType.DropDown ||
      answerObject.type === QuestionType.TextField ||
      answerObject.type === QuestionType.DatePicker
    ) {
      answerdPrepareObj = {
        questionId: key,
        value: {
          id: answerObject.answer,
          label: answerObject.answer,
        },
      };
    }

    if (answerObject.type === QuestionType.MultiSelect) {
      answerdPrepareObj.questionId = key;
      const multiselectArray = [];
      for (let j = 0; j < answerObject.answer.length; j++) {
        const answerObj = {
          id: answerObject.answer[j].id,
          label: answerObject.answer[j].value,
        };
        multiselectArray.push(answerObj);
      }
      answerdPrepareObj.values = multiselectArray;
    }

    if (!_.isEmpty(answerdPrepareObj)) {
      body.values.push(answerdPrepareObj);
    }

    if (answerObject.type === QuestionType.FileUpload) {
      const body = {
        file: answerObject.answer,
        applicationId: answerObject.applicationId,
        questionId: key,
        question: answerObject.question,
      };
      files.push(body);
    }
  }
  return { body, files };
};

export const setFilteredQuestion = (filteredQuestion: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: SET_FILTERED_QUESTION, payload: { filteredQuestion } });
};

export const submitQuestionAnswer = (
  filesOnly: boolean = false
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const {
      applicationLocalAnswer,
      currentApplicationDetails,
      fileUploadProgress,
      sbApplication,
      networkStatus,
    } = getState().sbApplication;
    if (!networkStatus) {
      dispatch({
        type: SET_ERROR,
        payload: 'Please check your internet connectivity',
      });
      reject('Error');
      return;
    }

    const { body, files } = await getAnswerBody(applicationLocalAnswer);

   
    let result = undefined;
    if (!filesOnly && body.values.length > 0) {
      if (sbApplication.activeGroup === "PreQuestions") {
        dispatch({ type: ON_LOADING_START });
      }
      result = await post(`${config.BASE_URL}/application/${currentApplicationDetails.id}/bulk-answer`, body, dispatch);
    }

    if (result) {
      // dispatch({ type: UPDATE_APPLICATION_ANSWER, payload: result ? result : [] });
      dispatch({
        type: UPDATE_APPLICATION_ANSWER,
        payload: { result: result ? result : [], groupName: sbApplication.activeGroup },
      });
      dispatch({ type: RESET_LAST_QUESTION_UPDATE });
      dispatch(updateLocalGroupStatus());
    } else {
      dispatch({ type: ON_LOADING_FINISHED });
    }

    const onUploadProgress = (progress: any, questionId: any, question: any, group: any) => {
      dispatch({ type: SET_FILE_UPLOAD_PROGRESS, payload: { questionId, progress, question, group } });
    };

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (!fileUploadProgress.hasOwnProperty(files[i].questionId)) {
          dispatch({ type: SET_FILE_UPLOAD_PROGRESS, payload: { questionId: files[i].questionId, progress: -1 } });
          uploadQuestionFiles(
            files[i].file,
            files[i].applicationId,
            files[i].questionId,
            dispatch,
            onUploadProgress,
            sbApplication.activeGroup,
            files[i].question ? files[i].question : ''
          );
        }
      }
    }
    resolve(result);
  });
};

export const updateLocalAnswerInCurrentApplicationAllAnswer = (
  questionId: any,
  answer: any,
  order: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  dispatch({ type: UPDATE_CURRENT_APPLICATION_DETAIL_ANSWER, payload: { questionId, answer, order } });
};

const updateLocalGroupStatus = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  const { filteredQuestionDetail, sbApplication, currentApplicationDetails, blocks } = getState().sbApplication;
  if (currentApplicationDetails) {
    const status = getGroupStatusByQuestionAnswer(filteredQuestionDetail.questionList);
    const isPrequestinGroup = sbApplication.activeGroup == 'PreQuestions';
    let lastStatus = '';
    if (isPrequestinGroup) {
      lastStatus = sbApplication[sbApplication.activeGroup].status.status;
    } else {
      const activegroup = _.find(blocks, (obj) => obj.name == sbApplication.activeGroup);
      if (activegroup) {
        lastStatus = activegroup.status.status;
      }
    }
    let questionStatus = [];
    if (lastStatus != status) {
      dispatch({
        type: UPDATE_LOCAL_GROUP_STATUS,
        payload: { groupId: sbApplication[sbApplication.activeGroup].id, status, isPrequestinGroup },
      });
      let nextGroupId = null;
      if (isPrequestinGroup && status == GroupStatus.Complete) {
        nextGroupId = blocks[0].id;
      }
      const body = {
        groupId: sbApplication[sbApplication.activeGroup].id,
        groupName: sbApplication.activeGroup,
        applicationId: currentApplicationDetails.id,
        status: status,
        questionStatus,
        nextGroupId,
      };
      dispatch(syncGroupStatus(body));
    } else {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  }
};

export const updatePreQuestionAnswer = (
  answerObject: any,
  applicationId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    let result = undefined;
    if (answerObject.values.length > 0) {
      result = await post(`${config.BASE_URL}/application/${applicationId}/bulk-answer`, answerObject, dispatch);
    }
    // result.questions = await reArrangeQuestion(result.questions);
    dispatch({
      type: UPDATE_QUESTION_SET_WITH_ANSWER,
      payload: result,
    });
    dispatch({ type: UPDATE_APPLICATION_ANSWER, payload: { result: result ? result : [], groupName: 'PreQuestions' } });

    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const postComments = (body: any, questionId: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await post(`${config.BASE_URL}/comments/${questionId}`, body, dispatch);
    await dispatch(getQuestionsComments(questionId));
    //console.log("Post Comments Result:", result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getQuestionsComments = (id: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_INTERNAL_LOADING_START });

    const result = await get(`${config.BASE_URL}/comments/${id}`);
    //console.log("Question Comments !!!!  ", result);
    dispatch({
      type: SET_CURRENT_COMMENTS,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const startApplication = (lockStatus = false): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  const blocks = getState().sbApplication.blocks;
  await dispatch(getQuestionByGroup(blocks[0].id, true));
  await dispatch(setQuestionCounter(0));

  dispatch({ type: START_APPLICATION, payload: lockStatus });
};

export const setDMSQuestion = (question): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: SET_DMS_QUESTION, payload: question });
};

export const resetSBApplication = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: RESET_SB_APPLICATION });
};

export const resetProviderApplicationListForFilter = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: RESET_APPLICATION_LIST_FOR_FILTER });
};

export const setQuestionCounter = (counter: number): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  dispatch({ type: SET_QUESTION_COUNTER, payload: counter });
};

export const setLastQuestion = (
  group: string,
  answer: any,
  index: number,
  applicationId: number,
  questionObj: any,
  tabIndex: number = -1
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: SET_LAST_QUESTION,
    payload: {
      group,
      answer,
      index,
      applicationId,
      questionObj,
      tabIndex,
    },
  });
};

export const setLocalQuestionAnswer = (
  questionId: any,
  answer: any,
  type: string,
  applicationId: any,
  question: any = ''
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: SET_LOCAL_QUESTION_ANSWER,
    payload: {
      questionId,
      answer,
      type,
      applicationId,
      question,
    },
  });
};

export const removeInvalidAnswerFromLastQuestion = (
  questionId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: REMOVE_INVALID_ANSWER_FROM_LAST_QUESTION,
    payload: questionId,
  });
};

export const setShowHideQuestions = (
  hiddenQuestions: any,
  shownQuestions: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: SET_SHOW_HIDE_QUESTIONS,
    payload: { hiddenQuestions, shownQuestions },
  });
};

export const updateBlockStatus = (
  name: string,
  status: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: UPDATE_BLOCK_STATUS,
    payload: { name, status },
  });
};
export const submitOutcome = (
  file: any,
  applicationId: any,
  outcome: any,
  comment: any,
  refNumber: any,
  id: any,
  applicantId: any,
  studentId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });
      let result = { id: applicationId, blank: true };
      if (file) {
        result = await submitOutcome2(file, applicationId, outcome, comment, refNumber, applicantId, studentId);
        dispatch({ type: ON_LOADING_FINISHED });
      } else if (!file && id) {
        dispatch(updateOutcomeRefAndComments(id, comment, refNumber, applicationId, applicantId, studentId));
      } else {
        dispatch({ type: ON_LOADING_FINISHED });
      }
      dispatch({
        type: UPDATE_OUT_COMES_IN_PROVIDER_APPLICATION_LIST,
        payload: result,
      });
      resolve(result);
    } catch (error) {
      //console.log("Error In Upload::", error);
      dispatch({ type: ON_LOADING_FINISHED });
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

export const getEvents = (applicationId: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });

      const result = await get(`${config.BASE_URL}/application/getevents/${applicationId}`);

      // console.log(result);
      dispatch({
        type: GET_EVENTS,
        payload: result,
      });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
      //console.log("Error In Upload::", error);
      dispatch({ type: ON_LOADING_FINISHED });
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

export const getAppBlockStatus = (id: number): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ON_LOADING_START });
  const result = await get(API_URL.APPLICATION.BLOCK_STATUS(id), dispatch);
  await dispatch({
    type: GET_BLOCK_STATUSES,
    payload: result,
  });
  dispatch({ type: ON_LOADING_FINISHED });
};

export const submitOutcome2 = (
  file: any,
  applicationId: any,
  outcome: any,
  comment: any,
  refNumber: any,
  applicantId: any,
  studentId: any
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('applicationId', '' + applicationId);
    formData.append('outcome', outcome);
    formData.append('comment', comment);
    formData.append('referenceNumber', refNumber);
    formData.append('applicantId', applicantId);
    formData.append('studentId', studentId);
    // console.log("appid" + applicationId);
    //console.log("file" + file);
    try {
      const response = await postWithHeader(`${config.BASE_URL}/application/upload-outcome`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          //console.log("loaded", loaded);
          //console.log("total", total);
          const percent = Math.floor((loaded * 100) / total);
        },
      });
      //console.log("file upload response",response);
      resolve(response);
    } catch (error) {
      //console.log("Error In Upload::", error);
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

export const uploadQuestionFileFromDMS = (
  file: any,
  activeGroup: string = '',
  question: string = ''
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    //console.log("file" + file);
    // dispatch({ type: ON_LOADING_START });
    const { dmsQuestion, dmsCurrentAppDetails } = getState().sbApplication;

    console.log('dmsCurrentAppDetails :::', dmsCurrentAppDetails);
    console.log('dmsQuestion :::', dmsQuestion);
    const onUploadProgress = (progress: any, questionId: any, question: any, group: any) => {
      dispatch({ type: SET_FILE_UPLOAD_PROGRESS, payload: { questionId, progress, question, group } });
    };

    try {
      const result = await uploadQuestionFiles(
        file,
        dmsCurrentAppDetails.id,
        dmsQuestion.id,
        dispatch,
        onUploadProgress,
        activeGroup,
        question
      );
      dispatch({ type: ON_LOADING_FINISHED });
      resolve(result);
    } catch (error) {
      //console.log("Error In Upload::", error);
      // dispatch({ type: ON_LOADING_FINISHED });
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

export const uploadQuestionFiles = (
  file: any,
  applicationId: any,
  questionId: any,
  dispatch: any,
  progressCallBack: any = null,
  activeGroup: string = '',
  question: string = ''
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const fileUploadResult = await getSignedURL(file.name, 'putObject', applicationId);

    const onUploadFileProgress = (percentage) => {
      if (progressCallBack) {
        progressCallBack(percentage, questionId, question, activeGroup);
      }
    };

    try {
      const result = await uploadFileS3(fileUploadResult, file, null, null, onUploadFileProgress);

      if (result && result.status && result.status === 200) {
        const body = {
          questionId,
          applicationId,
          fileName: file.name,
        };

        try {
          const response = await postWithHeader(
            `${config.BASE_URL}/application/submitFileAnswer`,
            body,
            null,
            dispatch
          );
          if (response) {
            // dispatch({ type: UPDATE_APPLICATION_ANSWER, payload: response ? response : [] });
            dispatch({
              type: UPDATE_APPLICATION_ANSWER,
              payload: { result: response ? response : [], groupName: activeGroup },
            });
            // dispatch({ type: SET_FILE_UPLOAD_COMPLETE_PROGRESS, payload: { questionId } });
            dispatch({
              type: UPDATE_DMS_FILE_UPLOAD_ANSWER_STATUS,
              payload: { questionId, groupName: activeGroup, answer: response },
            });
            dispatch({ type: RESET_LAST_QUESTION_UPDATE });
            dispatch({
              type: SHOW_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR,
              payload: { questionId, groupName: activeGroup, success: true, error: false },
            });
            dispatch(updateLocalGroupStatus());
          }
          resolve(response);
        } catch (error) {
          dispatch({ type: ON_LOADING_FINISHED });
          dispatch({
            type: SHOW_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR,
            payload: { questionId, groupName: activeGroup, success: false, error: true },
          });
          if (error && error.response) {
            reject(error.response.data);
          }
        }
      } else {
        const error = new Error('Failed file upload', file.name);
        dispatch({
          type: SHOW_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR,
          payload: { questionId, groupName: activeGroup, success: false, error: true },
        });
        reject(error);
      }
    } catch (error) {
      dispatch({ type: ON_LOADING_FINISHED });
      dispatch({
        type: SHOW_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR,
        payload: { questionId, groupName: activeGroup, success: false, error: true },
      });
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

export const deleteQuestionFile = (
  applicationId: any,
  answerId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });
      const result = await remove(`${config.BASE_URL}/application/${applicationId}/delete-file/${answerId}`, {});
      result.questions = await reArrangeQuestion(result.questions);
      //console.log("Delete Question File Result !!!!!", result);
      resolve(result);
      dispatch({
        type: UPDATE_QUESTION_SET_WITH_ANSWER,
        payload: result,
      });
      await dispatch(updateBlockStatus(result.name, result.status));
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
      console.log('Error In Upload::', error);
      dispatch({ type: ON_LOADING_FINISHED });
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

export const deleteFileLocally = (questionObj: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({
      type: DELETE_FILE_LOCALLY,
      payload: questionObj,
    });
  });
};

export const resetUserAgentList = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({
      type: RESET_USER_AGENT_LIST,
    });
  });
};

export const viewQuestionFile = (
  fileAnswer: any,
  applicationId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  const fileName: string = fileAnswer.fileName;
  const signedURL = await getSignedURL(fileName, 'getObject', applicationId);
  dispatch({ type: VIEW_FILE, payload: { fileName, fileUrl: signedURL } });
};

export const downloadQuestionFile = (
  fileAnswer: any,
  applicationId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    console.log('fileAnswer :::', fileAnswer);
    const signedURL = await getSignedURL(fileAnswer.fileName, 'getObject', applicationId);

    console.log('signedURL For :' + fileAnswer.fileName, signedURL);
    try {
      const result = await getWithNoAuth(
        signedURL,
        {
          responseType: 'blob',
        },
        null,
        dispatch
      );
      fileDownload(result, fileAnswer.fileName);
      dispatch({ type: ON_LOADING_FINISHED });
      resolve(result);
    } catch (error) {
      dispatch({ type: ON_LOADING_FINISHED });
      reject(error);
    }
  });
};

export const downloadVersionHistoryFile = (
  id: any,
  fileName: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const signedURL = await getSignedURL(fileName, 'getObject', id);
    console.log('signedURL For :' + fileName, signedURL);
    try {
      const result = await getWithNoAuth(
        signedURL,
        {
          responseType: 'blob',
        },
        null,
        dispatch
      );
      fileDownload(result, fileName);
      dispatch({ type: ON_LOADING_FINISHED });
      resolve(result);
    } catch (error) {
      dispatch({ type: ON_LOADING_FINISHED });
    }

    //console.log("File Object ::::", fileAnswer);
    // const result = await get(
    //   `${config.BASE_URL}/application/downloadChild/${id}`,
    //   {
    //     responseType: 'blob',
    //   },
    //   dispatch
    // );
    // fileDownload(result, fileName);
  });
};

export const downloadOutComes = (outcomes: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await get(
      `${config.BASE_URL}/application/download-outcome/${outcomes.id}`,
      {
        responseType: 'blob',
      },
      dispatch
    );
    fileDownload(result, outcomes.fileName);
    dispatch({ type: ON_LOADING_FINISHED });
    resolve(result);
  });
};
export const deleteOutcomeFile = (outcomes: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });
      const result = await remove(`${config.BASE_URL}/application/outcome/delete-outcome/${outcomes.id}`, {});

      dispatch({
        type: UPDATE_OUT_COMES_IN_PROVIDER_APPLICATION_LIST,
        payload: result,
      });
      resolve(result);
      // dispatch({
      //   type: UPDATE_QUESTION_SET_WITH_ANSWER,
      //   payload: result,
      // });
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
      console.log('Error In Upload::', error);
      dispatch({ type: ON_LOADING_FINISHED });
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

export const getApplicationList = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    // @ts-ignore
    const applicationParamBody = getState().sbApplication.applicationListParams;

    const userObj = getState().user.user;

    const role = getRoleByPermissions(userObj);
    if (role == Roles.Councillor || role == Roles.AgencyOwner) {
      applicationParamBody.limit = 1000;
    }
    applicationParamBody.filter = getState().sbApplication.sbApplicationFilter;

    dispatch({ type: ON_LOADING_START });

    const result = await post(`${config.BASE_URL}/application/fetch-applications`, applicationParamBody, dispatch);
    // console.log("Application List:", result);
    dispatch({ type: SET_APPLICATION_LIST, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getProviderApplicationList = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const user = getState().user.user.user;
    const providers = user.providers;

    const applicationParamBody = getState().sbApplication.applicationListParams;
    if (applicationParamBody.searchText != null) {
      applicationParamBody.searchText = applicationParamBody.searchText.trim();
    }
    applicationParamBody.filter = getState().sbApplication.sbApplicationFilter;
    const permissions: any = extractPermissions({ user });
    const isSBProcessingTeam = hasOneOfPermissions(
      [PERMISSION.applications_provider_view_sb_org, PERMISSION.applications_provider_view_sb_own],
      permissions
    );

    if (!isSBProcessingTeam && isProvidersWithSameCountry(providers)) {
      for (const providerObj of providers) {
        applicationParamBody.filter['provider'].push({
          id: providerObj.id,
          value: providerObj.name,
        });
      }
      applicationParamBody.filter['country'] = {
        id: providers[0].countryId,
        value: providers[0].country,
      };
    }

    if (applicationParamBody.assignedTo) {
      applicationParamBody.assignedTo = applicationParamBody.assignedTo.id;
    }
    // console.log("Application Params :::", applicationParamBody);
    dispatch({ type: ON_LOADING_START });
    const result = await post(`${config.BASE_URL}/application/fetch-applications`, applicationParamBody, dispatch);
    // console.log("Application List:", result);
    dispatch({ type: SET_PROVIDER_APPLICATION_LIST, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const resetApplicationList = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: RESET_APPLICATION_LIST,
  });
};

export const updateApplicationListValues = (
  key: any,
  value: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: UPDATE_APPLICATION_SEARCH_PARAMS,
    payload: { key, value },
  });
};

export const updateSBValues = (
  key: string,
  value: any,
  index: number
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: UPDATE_SB_APPLICATION_VALUE,
    payload: { key, value, index },
  });
};

export const addFileInSbApplication = (
  file: any,
  category: string,
  moduleKey: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: ADD_FILES_IN_SB_APPLICATION,
    payload: { file, category, moduleKey },
  });
};

export const updateAcademicInfo = (
  question: string,
  key: string,
  value: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: UPDATE_SB_ACADEMIC_APPLICATION_VALUE,
    payload: { question, key, value },
  });
};

export const toggleAppDrawer = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: SHOW_HIDE_DRAWER });
};

export const getEmailTemplate = (
  questionId: any,
  eventId: any,
  ids: any = []
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });

      const result = await post(`${config.BASE_URL}/verification/${questionId}/${eventId}`, ids, dispatch);
      dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const getMissingDocumentTemplate = (
  applicationId: number,
  missingDocumentOverallComment: any,
  missingDocumentAdditionalComments: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });
      const body = {
        missingDocumentOverallComment,
        missingDocumentAdditionalComments,
      };
      const result = await post(
        `${config.BASE_URL}/application/agentNotificationEmailTemplate/${applicationId}`,
        body,
        dispatch
      );
      dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const sendMissingDocumentEmail = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });

      const result = await post(`${config.BASE_URL}/application/sendMissingDocumentNotification`, body, dispatch);
      dispatch({
        type: MISSING_DOCUMENT_EMAIL_SUCCESS,
        payload: { isLoading: true, type: 'success' },
      });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const getProcessingOfficerByAppId = (
  applicationId: number
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });

      const result = await get(
        `${config.BASE_URL}/application/getPrcessingOfficerByApplicationId?applicationId=${applicationId}`,
        {},
        dispatch
      );

      dispatch({ type: GET_PROCESSING_OFFICER_BY_EMAIL, payload: result });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const addProcessingOfficer = (
  email: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });

      dispatch({ type: ADD_PROCESSING_OFFICER_EMAIL, payload: email });
      resolve(email);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const deleteProcessingOfficer = (
  email: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });

      dispatch({ type: DELETE_PROCESSING_OFFICER_EMAIL, payload: email });
      resolve(email);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const getDeferredEmailTemplate = (
  applicationId: any,
  deferredId: any,
  pending: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });

      ///verificationViewHtml/:applicationId/:deferredId/:pending"
      const result = await get(
        `${config.BASE_URL}/application/verificationViewHtml/${applicationId}/${deferredId}/${pending}`,
        {},
        dispatch
      );
      // dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const okToProceed = (id: any): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await post(`${config.BASE_URL}/verification/proceedVerification/${id}/-1`, {}, dispatch);
    //console.log("Ok to proceed:", result);

    result.questions = await reArrangeQuestion(result.questions);
    dispatch({
      type: UPDATE_QUESTION_SET_WITH_ANSWER,
      payload: result,
    });

    // dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const okToProceedWithMultipleEmail = (allIds: any): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await post(`${config.BASE_URL}/verification/proceedVerificationEmail`, allIds, dispatch);
    //console.log("Ok to proceed:", result);

    result.questions = await reArrangeQuestion(result.questions);
    dispatch({
      type: UPDATE_QUESTION_SET_WITH_ANSWER,
      payload: result,
    });

    // dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};



export const cancelVerification = (questionId: any): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await post(`${config.BASE_URL}/verification/cancelVerificationRequest/req/${questionId}`, {}, dispatch);
    //console.log("Ok to proceed:", result);
    result.questions = await reArrangeQuestion(result.questions);
    dispatch({
      type: UPDATE_QUESTION_SET_WITH_ANSWER,
      payload: result,
    });
    // dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const addMoreQuestion = (
  questionId: number,
  applicationID: number
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    // console.log("getApplicationById ::::", id);
    dispatch({ type: ON_LOADING_START });
    return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });
      const result = await get(
        `${config.BASE_URL}/application/addMore/applicationId/${applicationID}/questionId/${questionId}`
      );

      let questionList = _.cloneDeep(result.questions);
      result.questions = await reArrangeQuestion(questionList);
      await dispatch({
        type: ADD_NEW_REPEAT_QUESTION,
        payload: result,
      });

      const tabQuestion = _.find(result.questions, (obj) => obj.id === questionId);
      if (tabQuestion) {
        dispatch(setTabIndex(questionId, tabQuestion.tabs.length, true));
      }
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    });
  });
};

export const deleteTab = (tabParentId: any, tabIndex: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });
      const result = await remove(
        `${config.BASE_URL}/application/deleteTab/tabId/${tabParentId}/tabIndex/${tabIndex}`,
        {}
      );
      let questionList = _.cloneDeep(result.questions);
      result.questions = await reArrangeQuestion(questionList);

      await dispatch(setTabIndex(tabParentId, 0, true));

      dispatch({
        type: ADD_NEW_REPEAT_QUESTION,
        payload: result,
      });

      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
      console.log('Error In Upload::', error);
      dispatch({ type: ON_LOADING_FINISHED });
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

//
export const getAcademicVerification = (questionId: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });

      const result = await autoVerificationPost(
        `${config.BASE_URL}/verification/academic/questionId/${questionId}`,
        {},
        dispatch
      );
      // console.log("email Template:", result);
      // dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
      resolve(result);

      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const updateAcademicVerification = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });
      const result = await post(
        `${config.BASE_URL}/verification/update/auto/questionId/${body.questionId}`,
        body,
        dispatch
      );
      console.log('Updated Verification Status:', result);
      result.questions = await reArrangeQuestion(result.questions);
      dispatch({
        type: UPDATE_QUESTION_SET_WITH_ANSWER,
        payload: result,
      });
      // dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
      resolve(result);

      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const getAcademicVerificationWithCaptcha = (
  body: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });
      const result = await autoVerificationPost(`${config.BASE_URL}/verification/captcha`, body, dispatch);
      console.log('email Template:', result);
      // dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
      resolve(result);

      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const addNotificationEvents = (notification: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    // dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/notification/getAll`, null, dispatch);

    dispatch({
      type: ADD_NOTIFICATION_EVENT,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const downloadAllApplicationFile = (
  applicationId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    //console.log("File Object ::::", fileAnswer);
    const result = await get(`${config.BASE_URL}/application/all-file/${applicationId}`, {
      responseType: 'blob',
    });

    const fileName = `application-${getApplicationIdInString(applicationId)}.zip`;

    fileDownload(result, fileName);
    dispatch({ type: ON_LOADING_FINISHED });
    resolve(result);
  });
};

export const downloadAllApplicationFileByGroupId = (
  applicationId: any,
  groupId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    //console.log("File Object ::::", fileAnswer);
    const result = await get(`${config.BASE_URL}/application/all-file/${applicationId}/${groupId}`, {
      responseType: 'blob',
    });

    const fileName = `application-${getApplicationIdInString(applicationId)}.zip`;

    fileDownload(result, fileName);
    dispatch({ type: ON_LOADING_FINISHED });
    resolve(result);
  });
};

export const getAllApplicationFileList = (
  applicationId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    //console.log("File Object ::::", fileAnswer);
    const result = await get(`${config.BASE_URL}/application/getAllFiles/${applicationId}`);
    // console.log("All Files Result :::", result);
    dispatch({ type: ON_LOADING_FINISHED });
    resolve(result);
  });
};

export const clearAllNotification = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: CLEAR_NOTIFICATION_EVENT,
  });
};

export const assignApplicationToProvider = (
  applicationId: any,
  userId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/application/assignToMe/${applicationId}/${userId}`, null, dispatch);

    dispatch({
      type: UPDATE_PROVIDER_LIST,
      payload: result,
    });
    // console.log("result:::", result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getDMSApplicationList = (
  params: any = null,
  showProgress: boolean = true
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    if (showProgress) {
      dispatch({ type: ON_LOADING_START });
    }

    const result = await get(
      `${config.BASE_URL}/application/dmsAppList${toGetRequestURLParam(params)}`,
      null,
      dispatch
    );
    // console.log("Application List", result);
    await dispatch({
      type: SET_DMS_APPLICATION_LIST,
      payload: result,
    });

    //console.log("Application Details :::", result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getALlDMSApplicationFiles = (id): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/application/dmsFiles/` + id, null, dispatch)
    await dispatch({
      type: SET_DMS_APPLICATION_DETAILS,
      payload: result,
    });

    //console.log("Application Details :::", result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getApplicationFilters = (id: string = '-1'): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const user = getState().user.user.user;
    const providers = user.providers;
    const permissions: any = extractPermissions({ user });
    const isSBProcessingTeam = hasOneOfPermissions(
      [PERMISSION.applications_provider_view_sb_org, PERMISSION.applications_provider_view_sb_own],
      permissions
    );
    const countryId = !isSBProcessingTeam && isProvidersWithSameCountry(providers) ? providers[0].countryId : id;

    dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/application/appFilter/${countryId}`, null, dispatch);
    console.log('Filter List', result);
    dispatch({
      type: SET_SB_APP_FILTER_LIST,
      payload: result,
    });
    //console.log("Application Details :::", result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};
export const setSBApplicationFilter = (
  key: string,
  value: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: UPDATE_SB_FILTER_VALUE,
    payload: { key, value },
  });
};

export const clearSBApplicationFilter = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: CLEAR_SB_FILTER_VALUE,
  });
};

export const setSBApplicationFilterObj = (obj: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  dispatch({
    type: SET_SB_FILTER_OBJECT,
    payload: obj,
  });
};

export const showLockApplicationDialogBox = (value): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  dispatch({
    type: SHOW_LOCK_APPLICATION_DIALOG,
    payload: value,
  });
};

export const duplicateAction = (action: string): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const { currentApplicationDetails } = getState().sbApplication;
    const group = _.find(currentApplicationDetails.groups, (obj) => obj.name == 'PreQuestions');
    console.log('currentApplicationDetails in action', currentApplicationDetails);

    const result = await post(
      `${config.BASE_URL}/application/duplicateAction`,
      {
        applicationId: currentApplicationDetails.id,
        action,
        groupId: group.id,
      },
      dispatch
    );
    // dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const duplicateActionByLead = (
  applicationId: number,
  action: string,
  comments: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await post(
      `${config.BASE_URL}/application/duplicateActionByLead`,
      {
        applicationId: applicationId,
        action,
        comments,
      },
      dispatch
    );
    // dispatch({ type: OPEN_EMAIL_TEMPLATE_IN_MODEL, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const duplicateActionByLeadWithFile = (
  applicationId: number,
  action: string,
  comments: string,
  file: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('applicationId', '' + applicationId);
    formData.append('action', action);
    formData.append('comments', comments);
    // console.log("appid" + applicationId);
    //console.log("file" + file);
    try {
      const response = await postWithHeader(`${config.BASE_URL}/application/duplicateActionByLeadWithFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          //console.log("loaded", loaded);
          //console.log("total", total);
          const percent = Math.floor((loaded * 100) / total);
        },
      });
      dispatch({ type: ON_LOADING_FINISHED });
      //console.log("file upload response",response);
      resolve(response);
    } catch (error) {
      dispatch({ type: ON_LOADING_FINISHED });
      //console.log("Error In Upload::", error);
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

export const downloadRejectedAppProof = (fileName: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    console.log('File Object ::::', fileName);
    const result = await get(
      `${config.BASE_URL}/application/downloadRejectedProof/${encodeURIComponent(fileName)}`,
      {
        responseType: 'blob',
      },
      dispatch
    );
    fileDownload(result, fileName);
    dispatch({ type: ON_LOADING_FINISHED });
    resolve(result);
  });
};

export const deferApplication = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await post(`${config.BASE_URL}/application/deferApplication`, body, dispatch);
    console.log('Ok to proceed:', result);

    dispatch({ type: DEFER_APPLICATION_SUCCESS, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const deferApplicationByPOVnt = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await post(`${config.BASE_URL}/application/deferApplicationAndApprove`, body, dispatch);
    //console.log('Ok to proceed:', result);

    dispatch({ type: DEFER_APPLICATION_SUCCESS_BY_POVNT, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const defermentApplicationAction = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    // console.log("Body ::::", body);
    dispatch({ type: ON_LOADING_START });
    const result = await post(`${config.BASE_URL}/application/deferApplicationAction`, body, dispatch);
    dispatch({ type: DEFER_APPLICATION_SUCCESS, payload: result });
    // console.log("Ok to proceed:", result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const defermentApplicationActionEmail = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    // console.log("Body ::::", body);
    dispatch({ type: ON_LOADING_START });
    const result = await post(`${config.BASE_URL}/application/deferApplicationApprove`, body, dispatch);
    dispatch({ type: DEFER_APPLICATION_SUCCESS, payload: result });
    // console.log("Ok to proceed:", result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getCountryDetails = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });

      ///verificationViewHtml/:applicationId/:deferredId/:pending"
      const result = await get(`${config.BASE_URL}/country/getAllCountry`, {}, dispatch);
      dispatch({ type: SET_COUNTRY_LIST, payload: result });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const getTermsAsPerCountry = (countryName: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    try {
      dispatch({ type: ON_LOADING_START });
      ///verificationViewHtml/:applicationId/:deferredId/:pending"
      const result = await get(`${config.BASE_URL}/country/getTerms/${countryName}`, {}, dispatch);
      dispatch({ type: SET_TERMS_LIST_AS_PER_COUNTRY, payload: result });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (e) {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  });
};

export const updateCountryProviderChange = (
  key: string,
  value: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: UPDATE_COUNTRY_PROVIDER,
    payload: { key, value },
  });
};

export const setCountryProviderDetails = (obj: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  dispatch({
    type: SET_COUNTRY_DETAIL_UPDATE,
    payload: obj,
  });
};

export const resetSelectedCountryChangeDetails = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  dispatch({
    type: RESET_SELECTED_COUNTRY_CHANGE_DETAILS,
  });
};

export const updateCountryDetailsOfApplication = (
  body: any,
  applicationId: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    body['applicationId'] = applicationId;
    dispatch({ type: ON_LOADING_START });
    const result = await post(`${config.BASE_URL}/application/updateDetails`, body, dispatch);
    if (result.success) {
   
      dispatch(getApplicationDetails(applicationId,-1,false,true));
      dispatch(getAllStatusList(applicationId));
    }
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const resetDMSApplicationList = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: RESET_DMS_APPLICATION_DETAIL,
  });
};

export const getApplicationsProviders = (
  applicationId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/application/fetch-providers/${applicationId}`, null, dispatch);
    console.log('Provider Result >>>>', result);
    dispatch({
      type: SET_APPLICATION_PROVIDER_LIST,
      payload: { applicationId, userList: result },
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const lockUnlockQuestions = (
  body: any,
  groupId: any,
  tabParent: any = 0
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getstate) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const blocks = getstate().sbApplication.blocks;
    const result = await post(`${config.BASE_URL}/application/lockQuestions`, body, dispatch);
    if (result && result.success) {
      await dispatch({
        type: SET_LOCK_UNLOCK_QUESTION_STATUS,
        payload: { body, tabParent },
      });
    }

    const index = _.findIndex(blocks, (obj: any) => obj.id == groupId);
    if (!body.lock) {
      if (index != -1 && blocks[index].isLocked) {
        const groupUpdateBody = {
          groupId: blocks[index].id,
          lock: false,
          lockType: 'Single',
        };
        await dispatch(lockBlocks(groupUpdateBody));
      }
    } else {
      const questionList = getstate().sbApplication.sbApplication[blocks[index].name].questions;
      const unlockedQuestionList = _.filter(questionList, (obj) => obj.status == 'SHOW' && !obj.isLocked);
      if (unlockedQuestionList.length == 0) {
        const groupUpdateBody = {
          groupId: blocks[index].id,
          lock: true,
          lockType: 'Single',
        };
        await dispatch(lockBlocks(groupUpdateBody));
      }

      // console.log("Result >>>", result);
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    }

    // console.log('Result >>>', result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const lockUnlockDMSFileQuestions = (
  body: any,
  activeGroup: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getstate) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await post(`${config.BASE_URL}/application/lockDmsQuestions`, body, dispatch);
    console.log('Result >>>', result);
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
    if (result && result.success) {
      dispatch({
        type: SET_LOCK_UNLOCK_DMS_FILE_STATUS,
        payload: { body, activeGroup },
      });
    }
  });
};

export const lockBlocks = (body: any, applicationId: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getstate
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await post(`${config.BASE_URL}/application/lockBlocks`, body, dispatch);
    console.log('Result >>>', result);

    resolve(result);

    if (result && result.success) {
      dispatch(getQuestionByGroup(body.groupId, true, false, true));
    }
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getApplicationRejectionReason = (
  country: any,
  status: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    // dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/country/getAppRejReason/${country}/${status}`);
    //console.log("Question Comments !!!!  ", result);
    dispatch({
      type: SET_REJECTION_REASON,
      payload: { country, reason: result },
    });
    resolve(result);
    // dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const updateOutcomeRefAndComments = (
  id: any,
  comment: any,
  refNumber: any,
  applicationID: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const applicationParamBody = { refNumber, comment, id };
    const result = await post(`${config.BASE_URL}/application/updateOutcomeRefNumber`, applicationParamBody, dispatch);
    if (result.length == 1) {
      dispatch({
        type: UPDATE_OUTCOME_REFERENCE_COMMENTS,
        payload: { id, comment, refNumber, applicationID },
      });
    }
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getSignedURL = (key: string, type: string, applicationId: any) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const body = { key, type, applicationId };
    const result = await post(`${config.BASE_URL}/application/signedURL`, body, null);
    resolve(result);
  });
};

export const getLockApplicationDetails = (
  applicationId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await get(`${config.BASE_URL}/application/getLockDetails/${applicationId}`, null, dispatch);
    dispatch({
      type: ADD_LOCK_DETAIL_IN_APPLICATION,
      payload: { applicationId, result },
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const deleteCurrentApplication = (
  applicationID: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const applicationParamBody = { passcode: '$12$45%89#890@@ABC', ids: [applicationID] };
    const result = await post(`${config.BASE_URL}/application/deleteApplicationsById`, applicationParamBody, dispatch);
    if (result.length == 1) {
      dispatch({
        type: RESEET_DELETED_APPLICATION,
        payload: applicationID,
      });
    }
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const changeApplicationCourse = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await post(`${config.BASE_URL}/application/changeCourse`, body, dispatch);
    if (result.success) {
      dispatch(getApplicationDetails(body.applicationId, -1, true));
    }
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getCourseListByProviderId = (providerId: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await get(`${config.BASE_URL}/country/getCourseByProvider/${providerId}`, null, dispatch);
    dispatch({
      type: UPDATE_SB_FILTER_LIST,
      payload: { key: 'course', result },
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const syncGroupStatus = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const result = await post(`${config.BASE_URL}/application/syncGroupStatus`, body, dispatch);

    if (result && result.applicationDetails) {
      result.applicationDetails.groups = _.sortBy(result.applicationDetails.groups, ['id']);
      dispatch({
        type: SET_BLOCKS,
        payload: result?.applicationDetails,
      });
    }

    if (result && result.nextGroup) {
      result.nextGroup.questions = await reArrangeQuestion(result.nextGroup.questions);
      dispatch({
        type: SET_QUESTIONS_BY_GROUP,
        payload: { result: result.nextGroup, isChangeCurrentGroup: true, isCreateNewApplication: false },
      });
    }

    if (result && result.applicationDetails && result.nextGroup) {
      dispatch({ type: RESET_CART_COUNTER });
    }

    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const syncQuestionStatus = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    const result = await post(`${config.BASE_URL}/application/syncQuestionStatus`, body, dispatch);
    resolve(result);
    dispatch({ type: RESET_QUESTION_STATUS_UPDATE });
  });
};

export const setQuestionStatusUpdate = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: SET_QUESTION_STATUS_UPDATE, payload: body });
  });
};

export const setUpdatedQuestionList = (questionList): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: SET_CURRENT_APPLICATION_FILTERED_QUESTION_LIST, payload: questionList });
};
export const toggleMandatoryFlag = (
  body: any,
  groupId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await put(
      `${config.BASE_URL}/application/${body.applicationId}/questions/${body.applicationQuestionId}/toggleMandatoryFlag`,
      body,
      dispatch
    );
    dispatch({ type: SET_MANDATORY_FLAG, payload: { questionId: body.applicationQuestionId, groupId } });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};
export const reviewDocument = (body: any, groupId: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await post(
      `${config.BASE_URL}/application/questions/${body.applicationQuestionId}/reviews`,
      body,
      dispatch
    );
    if (result.success) {
      dispatch({
        type: REVIEW_DOCUMENT_STATUS,
        payload: { questionId: body.applicationQuestionId, groupId, reviewStatus: body.reviewStatus },
      });
    }
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getReviewHistroy = (applicationQuestionId): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getstate
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await get(`${config.BASE_URL}/application/questionsReviews/${applicationQuestionId}`);
    dispatch({
      type: SET_QUESTION_REVIEW_HISTORY,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const clearReviewHistory = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: SET_QUESTION_REVIEW_HISTORY, payload: [] });
  });
};

export const deleteApplication = (
  id: any,
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const body = {
      ids:[id],
      passcode:"$12$45%89#890@@ABC"
    }
    let result = undefined;
    if (id) {
      result = await post(`${config.BASE_URL}/application/deleteApplications`, body, dispatch);
    } 
    dispatch({
      type: DELETE_APPLICATIONS,
      payload: id,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};


export const addDocuments = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await post(
      `${config.BASE_URL}/application/addDocuments`,
      body,
      dispatch
    );
    if (result.success) {
      dispatch({
        type: ADD_NEW_DOCUMENTS,
        payload: result.data,
      });
    }
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};


export const uploadVerificationEvent = (
  type: string,
  eventId: any,
  comments: string,
  file: any,
  applicationId: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('eventId', eventId);
    formData.append('comments', comments);
    formData.append('type', type);
    formData.append('applicationId', applicationId);
   
    try {
      const response = await postWithHeader(`${config.BASE_URL}/verification/verificationEvent`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          //console.log("loaded", loaded);
          //console.log("total", total);
          const percent = Math.floor((loaded * 100) / total);
        },
      });
      dispatch({ type: ON_LOADING_FINISHED });
      dispatch({ type: ADD_NEW_EVENT, payload: {applicationId,eventId, result : response } });
      //console.log("file upload response",response);
      resolve(response);
    } catch (error) {
      dispatch({ type: ON_LOADING_FINISHED });
      //console.log("Error In Upload::", error);
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};
