import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { PieArcDatum } from 'd3-shape';
import { Types } from './types';

import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducer';
import './D3PieChart.css';

type PropsFromRedux = ConnectedProps<typeof connector>;
type D3PieChartProps = PropsFromRedux & {
  data: [];
  showDraft?: boolean;
};

const D3PieChart: React.FC<D3PieChartProps> = ({ data, showDraft = false }) => {
  const getFormattedData = () => {
    let tempData = [];

    let closedData: any = data.filter((element: any) => element.name === 'Closed');
    tempData.push({
      name: 'Closed',
      count: closedData[0]?.count,
      //size: closedData[0]?.count + 100,
      size: getCalculatedRadius('Closed', data),
      color: '#8BB19C',
    });

    let rejectedData: any = data.filter((element: any) => element.name === 'Rejected');
    tempData.push({
      name: 'Rejected',
      count: rejectedData[0]?.count,
      //size: rejectedData[0]?.count + 100,
      size: getCalculatedRadius('Rejected', data),
      color: '#D27F65',
    });

    let openData: any = data.filter((element: any) => element.name === 'Open');
    tempData.push({
      name: 'Open',
      count: openData[0]?.count,
      //size: openData[0]?.count + 100,
      size: getCalculatedRadius('Open', data),
      color: '#ECCC97',
    });

    let withdrawnData: any = data.filter((element: any) => element.name === 'Withdrawn');
    tempData.push({
      name: 'Withdrawn',
      count: withdrawnData[0]?.count,
      //size: withdrawnData[0]?.count + 100,
      size: getCalculatedRadius('Withdrawn', data),
      color: '#3D405B',
    });
    if (showDraft) {
      let draftData: any = data.filter((element: any) => element.name === 'Draft');
      tempData.push({
        name: 'Draft',
        count: draftData[0]?.count,
        size: getCalculatedRadius('Draft', data),
        color: '#7B8997',
      });
    }
    tempData.sort((a, b) => b.count - a.count);
    return tempData;
  };

  const getCalculatedRadius = (name: string, array: []) => {
    let tempData = array
      .filter(
        (element: any) =>
          element.name === 'Closed' ||
          element.name === 'Rejected' ||
          element.name === 'Open' ||
          element.name === 'Draft' ||
          element.name === 'Withdrawn'
      )
      .sort((a: any, b: any) => b.count - a.count);

    const baseRadius = 150;
    const radiusMultiplier = 10;
    return baseRadius + tempData.findIndex((x: any) => x.name === name) * radiusMultiplier;
  };

  interface Data {
    name: string;
    count: number;
    size: number;
    color: string;
  }

  // var dataset: Data[] = [
  //   { name: "Closed", count: 60, size: 185, color: "#d27f65" },
  //   { name: "Rejected", count: 10, size: 155, color: "#eccc97" },
  //   { name: "Open", count: 30, size: 135, color: "#8bb19c" },
  //   { name: "Withdrawn", count: 40, size: 155, color: "#3d405b" },
  // ];

  var dataset: Data[] = getFormattedData();

  let svgMain, arc, pie, path;

  const inititaeSvg = () => {
    d3.selectAll('.svgContainer svg').remove();
    svgMain = d3
      .select(divRef.current)
      .append('svg')
      .attr('width', 500)
      .attr('height', 500)
      .append('g')
      .attr('transform', 'translate(' + 500 / 2 + ',' + 500 / 2 + ')');

    arc = d3
      .arc<PieArcDatum<Types.Data>>()
      .innerRadius(0)
      .outerRadius(function (d) {
        return 0 + d.data.size;
      });

    pie = d3
      .pie<Data>()
      .value(function (d) {
        return d.count;
      })
      .sort(null);

    path = svgMain
      .selectAll('path')
      .data(pie(dataset))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d, i) {
        return d.data.color;
      })
      .on('mouseover', function (event, d) {
        d3.select('#tooltip')
          .style('left', event.clientX + 'px')
          .style('top', event.clientY + 'px')
          .style('opacity', 1)
          .select('#value')
          .text(d.data.count);
      })
      .on('mouseout', function () {
        d3.select('#tooltip').style('opacity', 0);
      });
  };

  useEffect(() => {
    inititaeSvg();
  });

  const divRef = useRef(null);
  return (
    <>
      <div id="tooltip" className="hidden">
        <p>
          <span id="value"></span>
        </p>
      </div>
      <div className="svgContainer" ref={divRef}></div>
    </>
  );
};

const mapState = (state: RootState) => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(D3PieChart);
