import * as actionTypes from '../actions';
import { ActionType } from './types';
import _, { result } from 'lodash';
import { getApplicationGroupList } from '../util';
import { filter } from 'lodash';

const DEFAULT_DMS_ALL_LIST_DATA = {
  currentPage: 0,
  data: [],
  pageSize: 10,
  totalPages: 0,
  totalRecords: 0,
};

const initialState = {
  sbApplication: {
    activeGroup: null,
    activeGroupIndex: -1,
    initialStateCompleted: false,
  },
  currentApplicationFilterQuestion: [],
  applicationStatus: [],
  blocks: [],
  currentApplicationDetails: null,
  lastQuestion: [],
  events: [],
  blockStatuses: [],
  counter: 0,
  activityLogs: [],
  comments: [],
  hiddenQuestions: [],
  shownQuestions: [],
  applicationListDetails: undefined,
  applicationListParams: {
    limit: 10,
    offset: 0,
    searchText: '',
    orderBy: 'updatedAt',
    order: 'asc',
    status: '',
    assigned: null,
    assignedTo: null,
  },
  emailtemplate: [{ subject: '', content: '' }],
  alertSuccess: { isLoading: false, type: null },
  missingDocumentEmailTemplate: { subject: '', content: '' },
  processingOfficerEmails: [],
  missingDocumentAdditionalComments: [],
  missingDocumentOverallComment: null,
  tabIndex: [],
  outcomeQuestionsByType: [],
  providerList: [],
  childAgentList: [],
  userAgentList: undefined,
  dmsApplicationList: { ...DEFAULT_DMS_ALL_LIST_DATA },
  dmsCurrentAppDetails: undefined,
  dmsQuestion: undefined,
  sbApplicationFilter: {
    applicationStatus: [],
    subStatus: [],
    outcomes: [],
    provider: [],
    intakes: [],
    country: undefined,
    counsellor: undefined,
    agencies: [],
    course: [],
  },

  sbApplicationFilterList: undefined,
  showLockApplicationDialog: false,
  applicationStatusList: [],
  applicationSubStatusList: [],
  countryList: [],
  selectedCountryChangesDetails: {
    country: undefined,
    city: undefined,
    provider: undefined,
    category: undefined,
    course: undefined,
    terms: undefined,
  },
  termsList: [],
  applicationProviderSuggestion: {},
  rejectionReason: undefined,
  applicationLocalAnswer: {},
  questionStatusUpdate: {},
  filteredQuestionDetail: { activeGroup: '', questionList: [] },
  reviewHistory: [],
  reviewdocumentstatus: false,
  filesAndVericount: {},
  fileUploadProgress: {},
  fileUploadSnakbar: undefined,
  networkStatus: true,
};

const sbApplicationReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SET_NETWORK_STATUS: {
      return {
        ...state,
        networkStatus: action.payload,
      };
    }
    case actionTypes.SET_BLOCKS: {
      let blocksArray = _.cloneDeep(action.payload.groups);
      blocksArray.splice(0, 1);
      for (let i = 0; i < blocksArray.length; i++) {
        blocksArray[i].index = i;
      }

      const groupName = getApplicationGroupList();
      const sortedArray = [];
      for (let i = 0; i < groupName.length; i++) {
        const newObj: any = _.find(blocksArray, (obj: any) => obj.name == groupName[i]);
        if (newObj) {
          sortedArray.push(newObj);
        }
      }

      return {
        ...state,
        blocks: sortedArray,
        currentApplicationDetails: action.payload,
      };
    }

    case actionTypes.SET_LOCAL_QUESTION_ANSWER: {
      const tempLocalAnswer: any = _.cloneDeep(state.applicationLocalAnswer);
      if (action.payload.questionId in tempLocalAnswer) {
        tempLocalAnswer[action.payload.questionId].answer = action.payload.answer;
      } else {
        tempLocalAnswer[action.payload.questionId] = {
          answer: action.payload.answer,
          type: action.payload.type,
          applicationId: action.payload.applicationId,
          question: action.payload.question,
        };
      }
      return {
        ...state,
        applicationLocalAnswer: tempLocalAnswer,
      };
    }

    case actionTypes.SET_FILTERED_QUESTION: {
      const tempFilteredQuestion: any = _.cloneDeep(state.filteredQuestionDetail);
      tempFilteredQuestion.activeGroup = state.sbApplication.activeGroup;
      tempFilteredQuestion.questionList = action.payload.filteredQuestion;
      return {
        ...state,
        filteredQuestionDetail: tempFilteredQuestion,
      };
    }
    case actionTypes.SET_FILE_UPLOAD_PROGRESS: {
      const tempFileUploadProgress: any = _.cloneDeep(state.fileUploadProgress);
      if (
        !tempFileUploadProgress[action.payload.questionId] ||
        (tempFileUploadProgress[action.payload.questionId].progress != action.payload.progress &&
          tempFileUploadProgress[action.payload.questionId].progress < action.payload.progress)
      ) {
        tempFileUploadProgress[action.payload.questionId] = {
          progress: action.payload.progress,
          question: action.payload.question,
          group: action.payload.group,
        };
      }
      return {
        ...state,
        fileUploadProgress: tempFileUploadProgress,
      };
    }
    case actionTypes.SET_FILE_UPLOAD_COMPLETE_PROGRESS: {
      const tempFileUploadProgress: any = _.cloneDeep(state.fileUploadProgress);
      if (tempFileUploadProgress[action.payload.questionId]) {
        delete tempFileUploadProgress[action.payload.questionId];
      }
      return {
        ...state,
        fileUploadProgress: tempFileUploadProgress,
      };
    }

    case actionTypes.SET_QUESTION_STATUS_UPDATE: {
      const statusUpdate: any = _.cloneDeep(state.questionStatusUpdate);
      for (let i = 0; i < action.payload.length; i++) {
        const obj = action.payload[i];
        statusUpdate[obj.id] = obj.status;
      }
      return {
        ...state,
        questionStatusUpdate: statusUpdate,
      };
    }

    case actionTypes.RESET_QUESTION_STATUS_UPDATE: {
      return {
        ...state,
        questionStatusUpdate: {},
      };
    }

    case actionTypes.UPDATE_LOCAL_GROUP_STATUS: {
      const tempSbApplication: any = _.cloneDeep(state.sbApplication);
      const tempBlocks: any = _.cloneDeep(state.blocks);
      tempSbApplication[tempSbApplication.activeGroup].status.status = action.payload.status;
      if (!action.payload.isPrequestinGroup) {
        const index = _.findIndex(tempBlocks, (blockObj: any) => blockObj.id == action.payload.groupId);
        tempSbApplication[tempSbApplication.activeGroup].status.status = action.payload.status;
        if (index != -1) {
          tempBlocks[index].status.status = action.payload.status;
        }
      }
      return {
        ...state,
        blocks: tempBlocks,
        sbApplication: tempSbApplication,
      };
    }
    case actionTypes.SET_CURRENT_APPLICATION_FILTERED_QUESTION_LIST: {
      return {
        ...state,
        currentApplicationFilterQuestion: action.payload,
      };
    }

    case actionTypes.UPDATE_BLOCK_LOCK_UNLOCK_STATUS: {
      const tempBlocks: any = _.cloneDeep(state.blocks);
      const tempCurrentApp: any = _.cloneDeep(state.currentApplicationDetails);
      const index = _.findIndex(tempBlocks, (obj: any) => obj.name == action.payload.name);
      const groupIndex = _.findIndex(tempCurrentApp.groups, (obj: any) => obj.name == action.payload.name);
      if (index != -1) {
        tempBlocks[index].isLocked = action.payload.isLocked;
      }

      if (groupIndex != -1) {
        tempCurrentApp.groups[groupIndex].isLocked = action.payload.isLocked;
      }

      return {
        ...state,
        blocks: tempBlocks,
        currentApplicationDetails: tempCurrentApp,
      };
    }

    case actionTypes.UPDATE_APPLICATION_ANSWER: {
      const tempCurrentApp: any = _.cloneDeep(state.currentApplicationDetails);
      const sbApplicationTemp: any = _.cloneDeep(state.sbApplication);
      const sbTempApplicationLocalAnswer: any = _.cloneDeep(state.applicationLocalAnswer);
      if (tempCurrentApp) {
        const currentAnswers = tempCurrentApp.allAnswers;
        let applicationQuestions =
          sbApplicationTemp[action.payload.groupName ? action.payload.groupName : sbApplicationTemp.activeGroup]
            .questions;
        const newAnswers = action.payload.result;
        for (let i = 0; i < newAnswers.length; i++) {
          let currentIndex = _.findIndex(currentAnswers, { id: newAnswers[i].questionId });
          let questinIndex = _.findIndex(applicationQuestions, {
            id: newAnswers[i].parentQuestionId ? Number(newAnswers[i].parentQuestionId) : newAnswers[i].questionId,
          });
          if (questinIndex != -1) {
            if (newAnswers[i].parentQuestionId) {
              const childQuestionIdex = _.findIndex(applicationQuestions[questinIndex].questionList, {
                id: newAnswers[i].questionId,
              });
              if (childQuestionIdex != -1) {
                if (newAnswers[i].id) {
                  applicationQuestions[questinIndex].questionList[childQuestionIdex].answer = newAnswers[i];
                  applicationQuestions[questinIndex].questionList[childQuestionIdex].localAnswer = null;
                  delete sbTempApplicationLocalAnswer[newAnswers[i].questionId];
                  if (applicationQuestions[questinIndex].questionList[childQuestionIdex].key) {
                    const index = _.findIndex(
                      tempCurrentApp.keyAnswers,
                      (obj: any) => obj.key == applicationQuestions[questinIndex].questionList[childQuestionIdex].key
                    );
                    if (index !== -1) {
                      tempCurrentApp.keyAnswers[index].value = newAnswers[i].answerValue;
                    } else {
                      const tempKeyAnswer = {
                        applicationId: tempCurrentApp.id,
                        key: applicationQuestions[questinIndex].questionList[childQuestionIdex].key,
                        value: newAnswers[i].answerValue,
                      };
                      tempCurrentApp.keyAnswers.push(tempKeyAnswer);
                    }
                  }
                } else {
                  applicationQuestions[questinIndex].questionList[childQuestionIdex].answer = null;
                }
              }
            } else {
              if (newAnswers[i].id) {
                applicationQuestions[questinIndex].answer = newAnswers[i];
                delete sbTempApplicationLocalAnswer[newAnswers[i].questionId];
                if (
                  applicationQuestions[questinIndex].externalAnswer &&
                  applicationQuestions[questinIndex].answerType
                ) {
                  applicationQuestions = updateExternalCountryLevelAnswers(
                    applicationQuestions[questinIndex].answerType,
                    applicationQuestions
                  );
                }
                applicationQuestions[questinIndex].localAnswer = null;
                if (applicationQuestions[questinIndex].key) {
                  const index = _.findIndex(
                    tempCurrentApp.keyAnswers,
                    (obj: any) => obj.key == applicationQuestions[questinIndex].key
                  );
                  if (index !== -1) {
                    tempCurrentApp.keyAnswers[index].value = newAnswers[i].answerValue;
                  } else {
                    const tempKeyAnswer = {
                      applicationId: tempCurrentApp.id,
                      key: applicationQuestions[questinIndex].key,
                      value: newAnswers[i].answerValue,
                    };
                    tempCurrentApp.keyAnswers.push(tempKeyAnswer);
                  }
                }
              } else {
                applicationQuestions[questinIndex].answer = null;
              }
            }
          } else if (newAnswers[i].parentQuestionId && newAnswers[i].tabIndex > 0) {
            const tabsQuestion = _.filter(applicationQuestions, (obj: any) => obj.tabs && obj.tabs.length > 0);
            for (let j = 0; j < tabsQuestion.length; j++) {
              const tabsChildQuestionIndex = _.findIndex(
                tabsQuestion[j].tabs,
                (obj: any) => newAnswers[i].parentQuestionId == obj.id
              );
              if (tabsChildQuestionIndex != -1) {
                const finalQuestionIndex = _.findIndex(
                  tabsQuestion[j].tabs[tabsChildQuestionIndex].questionList,
                  (obj: any) => newAnswers[i].questionId == obj.id
                );
                const mainQuestionIndex = _.findIndex(applicationQuestions, (obj: any) => obj.id == tabsQuestion[j].id);
                applicationQuestions[mainQuestionIndex].tabs[tabsChildQuestionIndex].questionList[
                  finalQuestionIndex
                ].answer = newAnswers[i];
                applicationQuestions[mainQuestionIndex].tabs[tabsChildQuestionIndex].questionList[
                  finalQuestionIndex
                ].localAnswer = null;
                delete sbTempApplicationLocalAnswer[newAnswers[i].questionId];
              }
            }
          }
          if (currentIndex != -1) {
            if (newAnswers[i].id) {
              currentAnswers.splice(currentIndex, 1, {
                id: newAnswers[i].questionId,
                order: newAnswers[i].order,
                finalAnswer: newAnswers[i].answerValue,
              });
            } else {
              currentAnswers.splice(currentIndex, 1);
            }
          } else {
            if (newAnswers[i].id) {
              currentAnswers.push({
                id: newAnswers[i].questionId,
                order: newAnswers[i].order,
                finalAnswer: newAnswers[i].answerValue,
              });
            }
          }
          if (newAnswers[i].optionsUpdate) {
            for (let key in newAnswers[i].optionsUpdate) {
              let questinIndex = _.findIndex(applicationQuestions, { id: Number(key) });
              applicationQuestions[questinIndex].options = newAnswers[i].optionsUpdate[key];
            }
          }
        }
        tempCurrentApp.allAnswers = currentAnswers;
        sbApplicationTemp[action.payload.groupName].questions = applicationQuestions;
      }
      return {
        ...state,
        currentApplicationDetails: tempCurrentApp,
        sbApplication: sbApplicationTemp,
        applicationLocalAnswer: sbTempApplicationLocalAnswer,
      };
    }
    case actionTypes.UPDATE_CURRENT_APPLICATION_DETAIL_ANSWER: {
      const tempCurrentApp: any = _.cloneDeep(state.currentApplicationDetails);
      const allAnswers = tempCurrentApp.allAnswers;
      const index = _.findIndex(allAnswers, (obj: any) => obj.id == action.payload.questionId);
      if (index != -1) {
        allAnswers[index].finalAnswer = action.payload.answer;
      } else {
        allAnswers.push({
          id: action.payload.questionId,
          order: action.payload.order,
          finalAnswer: action.payload.answer,
        });
      }
      tempCurrentApp.allAnswers = allAnswers;
      return {
        ...state,
        currentApplicationDetails: tempCurrentApp,
      };
    }

    case actionTypes.UPDATE_APPLICATION_SUB_STATUS_AND_STATUS: {
      const tempCurrentApp: any = _.cloneDeep(state.currentApplicationDetails);
      if (action.payload.key == 'sub_status') {
        tempCurrentApp.status.subStatus = action.payload.subStatus;
        tempCurrentApp.status.subStatusId = action.payload.subStatusId;
      } else {
        tempCurrentApp.status.status = action.payload.status;
      }
      return {
        ...state,
        currentApplicationDetails: tempCurrentApp,
      };
    }

    case actionTypes.UPDATE_CURRENT_APPLICATION_LOCK_STATAUS: {
      const tempCurrentApp: any = _.cloneDeep(state.currentApplicationDetails);
      tempCurrentApp.lockStatus = action.payload;
      return {
        ...state,
        currentApplicationDetails: tempCurrentApp,
      };
    }

    case actionTypes.SET_QUESTIONS_BY_GROUP: {
      const sbApplicationTemp: any = _.cloneDeep(state.sbApplication);
      sbApplicationTemp[action.payload.result.name] = action.payload.result;
      if (action.payload.result.name !== 'PreQuestions' && action.payload.isChangeCurrentGroup) {
        sbApplicationTemp.initialStateCompleted = true;
        const blockIndex = _.findIndex(state.blocks, (block: any) => block.name == action.payload.result.name);
        sbApplicationTemp.activeGroup = action.payload.result.name;
        sbApplicationTemp.activeGroupIndex = blockIndex;
      }

      if (action.payload.isCreateNewApplication) {
        const blockIndex = _.findIndex(state.blocks, (block: any) => block.name == action.payload.result.name);
        sbApplicationTemp.activeGroup = action.payload.result.name;
        sbApplicationTemp.activeGroupIndex = blockIndex;
      }
      return {
        ...state,
        sbApplication: sbApplicationTemp,
      };
    }

    case actionTypes.RESET_CART_COUNTER: {
      return {
        ...state,
        counter: 0,
      };
    }

    case actionTypes.UPDATE_QUESTION_SET_WITH_ANSWER: {
      const sbApplicationTemp: any = _.cloneDeep(state.sbApplication);
      sbApplicationTemp[action.payload.name] = action.payload;
      return {
        ...state,
        sbApplication: sbApplicationTemp,
        lastQuestion: [],
      };
    }
    case actionTypes.DELETE_APPLICATIONS: {
      const applicationList: any = _.cloneDeep(state.applicationListDetails);
      const index = _.findIndex(applicationList.rows, (obj: any) => obj.id == action.payload);
      if (index != -1) {
        applicationList.rows.splice(index, 1);
      }
      return {
        ...state,
        applicationListDetails: applicationList,
      };
    }
    case actionTypes.RESET_LAST_QUESTION_UPDATE: {
      return {
        ...state,
        lastQuestion: [],
      };
    }
    case actionTypes.ADD_NEW_REPEAT_QUESTION: {
      const sbApplicationTemp: any = _.cloneDeep(state.sbApplication);
      sbApplicationTemp[action.payload.name] = action.payload;
      return {
        ...state,
        sbApplication: sbApplicationTemp,
        lastQuestion: [],
      };
    }
    case actionTypes.SET_REJECTION_REASON: {
      const rejectionReasonTemp: any = state.rejectionReason ? _.cloneDeep(state.rejectionReason) : {};
      rejectionReasonTemp[action.payload.country] = action.payload.reason;
      return {
        ...state,
        rejectionReason: rejectionReasonTemp,
      };
    }
    case actionTypes.GET_EVENTS: {
      return {
        ...state,
        events: action.payload,
      };
    }
    case actionTypes.GET_BLOCK_STATUSES: {
      return {
        ...state,
        blockStatuses: action.payload,
      };
    }

    case actionTypes.GET_THE_OUTCOME_BY_TYPE: {
      return {
        ...state,
        outcomeQuestionsByType: action.payload,
      };
    }

    case actionTypes.SET_COUNTRY_LIST: {
      return {
        ...state,
        countryList: action.payload,
      };
    }

    case actionTypes.SET_TERMS_LIST_AS_PER_COUNTRY: {
      return {
        ...state,
        termsList: action.payload,
      };
    }

    case actionTypes.SET_COUNTRY_DETAIL_UPDATE: {
      return {
        ...state,
        selectedCountryChangesDetails: action.payload,
      };
    }

    case actionTypes.RESET_SELECTED_COUNTRY_CHANGE_DETAILS: {
      return {
        ...state,
        selectedCountryChangesDetails: {
          country: undefined,
          city: undefined,
          provider: undefined,
          category: undefined,
          course: undefined,
          terms: undefined,
        },
      };
    }

    case actionTypes.UPDATE_COUNTRY_PROVIDER: {
      const tempObj: any = _.cloneDeep(state.selectedCountryChangesDetails);
      tempObj[action.payload.key] = action.payload.value;

      // country: undefined,
      //     city: undefined,
      //     provider: undefined,
      //     category: undefined,
      //     course: undefined,
      //     terms: undefined,

      if (action.payload.key == 'country') {
        tempObj.city = undefined;
        tempObj.provider = undefined;
        tempObj.category = undefined;
        tempObj.course = undefined;
        tempObj.terms = undefined;
      }

      if (action.payload.key == 'city') {
        tempObj.provider = undefined;
        tempObj.category = undefined;
        tempObj.course = undefined;
      }

      if (action.payload.key == 'provider') {
        tempObj.category = undefined;
        tempObj.course = undefined;
      }

      if (action.payload.key == 'provider') {
        tempObj.course = undefined;
      }

      return {
        ...state,
        selectedCountryChangesDetails: tempObj,
      };
    }

    case actionTypes.SET_LAST_QUESTION: {
      const sbApplicationTemp: any = _.cloneDeep(state.sbApplication);
      if (action.payload.questionObj.tabParentQuestionId > 0) {
        const questionIndex = _.findIndex(
          sbApplicationTemp[action.payload.group].questions,
          (obj: any) => action.payload.questionObj.tabParentQuestionId == obj.id
        );
        //  sbApplicationTemp[action.payload.group].questions[questionIndex].tabs[action.payload.tabIndex].questionList[]
        const tabQuestion =
          sbApplicationTemp[action.payload.group].questions[questionIndex].tabs[action.payload.tabIndex];

        if (tabQuestion.id == action.payload.questionObj.id) {
          sbApplicationTemp[action.payload.group].questions[questionIndex].tabs[action.payload.tabIndex].localAnswer =
            action.payload.answer;
        } else {
          const tabChildQuestionList =
            sbApplicationTemp[action.payload.group].questions[questionIndex].tabs[action.payload.tabIndex].questionList;

          const childQuestionIndex = _.findIndex(
            tabChildQuestionList,
            (obj: any) => action.payload.questionObj.id == obj.id
          );

          sbApplicationTemp[action.payload.group].questions[questionIndex].tabs[action.payload.tabIndex].questionList[
            childQuestionIndex
          ].localAnswer = action.payload.answer;
        }
      } else {
        if (!action.payload.questionObj.parentId) {
          const questionIndex = _.findIndex(
            sbApplicationTemp[action.payload.group].questions,
            (obj: any) => action.payload.questionObj.id == obj.id
          );
          sbApplicationTemp[action.payload.group].questions[questionIndex].localAnswer = action.payload.answer;
        } else {
          const parentQuestionIndex = _.findIndex(
            sbApplicationTemp[action.payload.group].questions,
            (object: any) => object.id == action.payload.questionObj.parentId
          );

          const parentQuestion = sbApplicationTemp[action.payload.group].questions[parentQuestionIndex];
          const childQuestion = action.payload.questionObj;
          const childQuestionIndex = _.findIndex(parentQuestion.questionList, (obj: any) => obj.id == childQuestion.id);

          if (childQuestionIndex != -1) {
            sbApplicationTemp[action.payload.group].questions[parentQuestionIndex].questionList[
              childQuestionIndex
            ].localAnswer = action.payload.answer;
          }
        }
      }

      let tempLastQuestionArray: any = _.cloneDeep(state.lastQuestion);

      const existIndex = _.findIndex(
        tempLastQuestionArray,
        (obj: any) => obj.questionObj.id == action.payload.questionObj.id
      );
      if (existIndex != -1) {
        tempLastQuestionArray[existIndex] = action.payload;
      } else {
        tempLastQuestionArray.push(action.payload);
      }

      return {
        ...state,
        sbApplication: sbApplicationTemp,
        lastQuestion: tempLastQuestionArray,
      };
    }
    case actionTypes.REMOVE_INVALID_ANSWER_FROM_LAST_QUESTION: {
      let tempLastQuestionArray: any = _.cloneDeep(state.lastQuestion);

      const existIndex = _.findIndex(tempLastQuestionArray, (obj: any) => obj.questionObj.id == action.payload);
      if (existIndex != -1) {
        tempLastQuestionArray.splice(existIndex, 1);
      }

      return {
        ...state,
        lastQuestion: tempLastQuestionArray,
      };
    }

    case actionTypes.START_APPLICATION: {
      const sbApplicationTemp: any = _.cloneDeep(state.sbApplication);
      sbApplicationTemp.initialStateCompleted = true;
      const currentApplication: any = _.cloneDeep(state.currentApplicationDetails);
      currentApplication.status.status = 'Open';
      currentApplication.lockStatus = action.payload ? action.payload : false;
      return {
        ...state,
        sbApplication: sbApplicationTemp,
        currentApplicationDetails: currentApplication,
      };
    }

    case actionTypes.GET_APPLICATION_STATUS_MASTER: {
      return {
        ...state,
        applicationStatus: action.payload,
      };
    }
    case actionTypes.SET_APPLICATION_STATUS_LIST: {
      return {
        ...state,
        applicationStatusList: action.payload,
      };
    }
    case actionTypes.SET_APPLICATION_SUB_STATUS_LIST: {
      return {
        ...state,
        applicationSubStatusList: action.payload,
      };
    }

    case actionTypes.SET_TAB_INDEX: {
      const tempTabIndex: any = _.cloneDeep(state.tabIndex);
      const index = _.findIndex(tempTabIndex, (obj: any) => action.payload.key in obj);
      if (index == -1) {
        tempTabIndex.push({ [action.payload.key]: action.payload.value });
      } else {
        if (action.payload.update) {
          tempTabIndex[index][action.payload.key] = action.payload.value;
        }
      }

      return {
        ...state,
        tabIndex: tempTabIndex,
      };
    }
    case actionTypes.RESET_TAB_INDEX: {
      return {
        ...state,
        tabIndex: [],
      };
    }
    case actionTypes.SET_QUESTION_COUNTER: {
      return {
        ...state,
        counter: action.payload,
      };
    }

    case actionTypes.UPDATE_BLOCK_STATUS: {
      const blocks: any = _.cloneDeep(state.blocks);
      const indexToUpdate = _.findIndex(blocks, (block: any) => block.name == action.payload.name);
      blocks[indexToUpdate].status = action.payload.status;
      return {
        ...state,
        blocks: blocks,
      };
    }
    case actionTypes.SET_CURRENT_ACTIVITY_LOGS: {
      return {
        ...state,
        activityLogs: action.payload,
      };
    }

    case actionTypes.SET_CURRENT_COMMENTS: {
      return {
        ...state,
        comments: action.payload,
      };
    }

    case actionTypes.OPEN_EMAIL_TEMPLATE_IN_MODEL: {
      return {
        ...state,
        emailtemplate: action.payload,
      };
    }

    case actionTypes.DELETE_FILE_LOCALLY: {
      const questionObj = action.payload;
      const temmpLocalAnswer: any = _.cloneDeep(state.applicationLocalAnswer);
      delete temmpLocalAnswer[questionObj.id];
      // const group = _.find(state.blocks, (object: any) => object.id == questionObj.groupId);
      // if (questionObj.parentId) {
      //   const parentQuestionIndex = _.findIndex(
      //     sbApplicationTemp[group.name].questions,
      //     (object: any) => object.id == questionObj.parentId
      //   );
      //   const childQuestionIndex = _.findIndex(
      //     sbApplicationTemp[group.name].questions[parentQuestionIndex].questionList,
      //     (object: any) => object.id == questionObj.id
      //   );
      //   delete sbApplicationTemp[group.name].questions[parentQuestionIndex].questionList[childQuestionIndex]
      //     .localAnswer;
      // } else {
      //   const questionIndex = _.findIndex(
      //     sbApplicationTemp[group.name].questions,
      //     (object: any) => object.id == questionObj.id
      //   );
      //   delete sbApplicationTemp[group.name].questions[questionIndex].localAnswer;
      // }

      // const lastQuestionArray = state.lastQuestion;
      // const lastQuestionIndex = _.findIndex(
      //   lastQuestionArray,
      //   (object: any) => object.questionObj.id == questionObj.id
      // );
      // lastQuestionArray.splice(lastQuestionIndex, 1);

      return {
        ...state,
        applicationLocalAnswer: temmpLocalAnswer,
      };
    }
    case actionTypes.SET_PROVIDER_APPLICATION_LIST: {
      return {
        ...state,
        applicationListDetails: { ...action.payload },
      };
    }

    case actionTypes.UPDATE_PROVIDER_LIST: {
      const applicationList: any = _.cloneDeep(state.applicationListDetails);
      const index = _.findIndex(applicationList.rows, (obj: any) => obj.id == action.payload.id);

      if (index != -1) {
        applicationList.rows[index] = action.payload;
      }
      return {
        ...state,
        applicationListDetails: applicationList,
      };
    }

    case actionTypes.SET_APPLICATION_LIST: {
      return {
        ...state,
        applicationListDetails: action.payload,
      };
    }

    case actionTypes.ADD_LOCK_DETAIL_IN_APPLICATION: {
      const applicationList: any = _.cloneDeep(state.applicationListDetails);
      const index = _.findIndex(applicationList.rows, (obj: any) => obj.id == action.payload.applicationId);
      if (index != -1) {
        applicationList.rows[index].lockedDetails = action.payload.result;
      }
      return {
        ...state,
        applicationListDetails: applicationList,
      };
    }

    case actionTypes.SET_APPLICATION_PROVIDER_LIST: {
      const temp: any = _.clone(state.applicationProviderSuggestion);
      temp[action.payload.applicationId] = action.payload.userList;
      return {
        ...state,
        applicationProviderSuggestion: temp,
      };
    }

    case actionTypes.RESET_APPLICATION_LIST: {
      return {
        ...state,
        applicationListDetails: undefined,
        applicationProviderSuggestion: {},
        applicationListParams: {
          limit: 10,
          offset: 0,
          searchText: '',
          status: 'Draft',
          assigned: null,
          orderBy: 'updatedAt',
          order: 'asc',
        },
      };
    }
    case actionTypes.RESET_APPLICATION_LIST_FOR_FILTER: {
      const params: any = _.cloneDeep(state.applicationListParams);
      params.limit = 10;
      params.offset = 0;
      params.orderBy = 'updatedAt';
      params.order = 'asc';

      return {
        ...state,
        applicationListDetails: undefined,
        applicationListParams: params,
      };
    }

    case actionTypes.SET_SHOW_HIDE_QUESTIONS: {
      return {
        ...state,
        hiddenQuestions: action.payload.hiddenQuestions,
        shownQuestions: action.payload.shownQuestions,
      };
    }
    case actionTypes.UPDATE_APPLICATION_SEARCH_PARAMS: {
      const params: any = _.cloneDeep(state.applicationListParams);
      params[action.payload.key] = action.payload.value;
      return {
        ...state,
        applicationListParams: params,
      };
    }

    case actionTypes.UPDATE_OUT_COMES_IN_PROVIDER_APPLICATION_LIST: {
      const appListDetail: any = _.cloneDeep(state.applicationListDetails);
      const indexOfApplication = _.findIndex(appListDetail.rows, (obj: any) => obj.id == action.payload.id);
      if (!action.payload.blank) {
        appListDetail.rows[indexOfApplication] = action.payload;
      }

      return {
        ...state,
        applicationListDetails: appListDetail,
      };
    }

    case actionTypes.SET_PROVIDER_LIST: {
      return {
        ...state,
        providerList: action.payload,
      };
    }
    case actionTypes.SET_CHILD_AGENT_LIST: {
      return {
        ...state,
        childAgentList: action.payload,
      };
    }

    case actionTypes.USER_AGENT_LIST: {
      return {
        ...state,
        userAgentList: action.payload,
      };
    }

    case actionTypes.RESET_USER_AGENT_LIST: {
      return {
        ...state,
        userAgentList: undefined,
      };
    }
    case actionTypes.SET_COUNSELLOR: {
      const tempUserAgentList: any = _.clone(state.userAgentList);
      const index = _.findIndex(
        tempUserAgentList,
        (obj: any) => obj.companyDetails.id && action.payload.companyId == obj.companyDetails.id
      );
      if (index != -1) {
        tempUserAgentList[index].child = tempUserAgentList[index].child.concat(action.payload.result);
        tempUserAgentList[index]['expand'] = true;
      }
      return {
        ...state,
        userAgentList: tempUserAgentList,
      };
    }

    case actionTypes.COUNSELLOR_EXPAND_COLLAPSE: {
      const tempUserAgentList: any = _.clone(state.userAgentList);
      const index = _.findIndex(tempUserAgentList, (obj: any) => action.payload == obj.companyDetails.agentCode);
      if (index != -1) {
        tempUserAgentList[index]['expand'] = tempUserAgentList[index].expand ? !tempUserAgentList[index].expand : true;
      }
      return {
        ...state,
        userAgentList: tempUserAgentList,
      };
    }

    case actionTypes.UPDATE_OUTCOME_REFERENCE_COMMENTS: {
      const tempApplicationList: any = _.clone(state.applicationListDetails);
      const index = _.findIndex(tempApplicationList.rows, (obj: any) => action.payload.applicationID == obj.id);
      if (index != -1) {
        const outcomeIndex = _.findIndex(
          tempApplicationList.rows[index].outcomes,
          (obj: any) => action.payload.id == obj.id
        );

        if (outcomeIndex != -1) {
          tempApplicationList.rows[index].outcomes[outcomeIndex].referenceNumber = action.payload.refNumber;
          tempApplicationList.rows[index].outcomes[outcomeIndex].comment = action.payload.comment;
        }
      }
      return {
        ...state,
        applicationListDetails: tempApplicationList,
      };
    }

    case actionTypes.SET_DMS_APPLICATION_LIST: {
      return {
        ...state,
        dmsApplicationList: action.payload,
      };
    }
    case actionTypes.SET_DMS_APPLICATION_DETAILS: {
      return {
        ...state,
        dmsCurrentAppDetails: action.payload,
      };
    }
    case actionTypes.SET_DMS_QUESTION: {
      return {
        ...state,
        dmsQuestion: action.payload,
      };
    }
    case actionTypes.SET_SB_APP_FILTER_LIST: {
      return {
        ...state,
        sbApplicationFilterList: action.payload,
      };
    }
    case actionTypes.ADD_NEW_DOCUMENTS: {
      const tenpApplication: any = _.cloneDeep(state.dmsCurrentAppDetails);
      const groupIndex = _.findIndex(tenpApplication.groups, (groups: any) => groups.id === action.payload.groupId);
      if (groupIndex != -1) {
        tenpApplication.groups[groupIndex].questions.push(action.payload);
      }
      return {
        ...state,
        dmsCurrentAppDetails: tenpApplication,
      };
    }
    case actionTypes.UPDATE_SB_FILTER_LIST: {
      const tempFilter: any = _.cloneDeep(state.sbApplicationFilterList);
      tempFilter[action.payload.key] = action.payload.result;
      return {
        ...state,
        sbApplicationFilterList: tempFilter,
      };
    }
    case actionTypes.UPDATE_SB_FILTER_VALUE: {
      const filter: any = _.cloneDeep(state.sbApplicationFilter);
      filter[action.payload.key] = action.payload.value;
      return {
        ...state,
        sbApplicationFilter: filter,
      };
    }
    case actionTypes.SET_LOCK_UNLOCK_QUESTION_STATUS: {
      const tenpApplication: any = _.cloneDeep(state.sbApplication);

      const activeGroup = state.sbApplication.activeGroup;

      if (activeGroup) {
        if (action.payload.tabParent == 0) {
          const index = _.findIndex(
            tenpApplication[activeGroup].questions,
            (obj: any) => obj.id == action.payload.body.questionId
          );
          tenpApplication[activeGroup].questions[index].isLocked = action.payload.body.lock;
          if ((action.payload.body.lockType = 'All')) {
            // questionList
            if (tenpApplication[activeGroup].questions[index].questionList) {
              for (let i = 0; i < tenpApplication[activeGroup].questions[index].questionList.length; i++) {
                tenpApplication[activeGroup].questions[index].questionList[i].isLocked = action.payload.body.lock;
              }
            }

            if (
              tenpApplication[activeGroup].questions[index].tabs &&
              tenpApplication[activeGroup].questions[index].tabs.length > 0
            ) {
              for (let j = 0; j < tenpApplication[activeGroup].questions[index].tabs.length; j++) {
                tenpApplication[activeGroup].questions[index].tabs[j].isLocked = action.payload.body.lock;
              }
            }
          }
        } else {
          const index = _.findIndex(
            tenpApplication[activeGroup].questions,
            (obj: any) => obj.id == action.payload.tabParent
          );
          const mainQuestionIndex = _.findIndex(
            tenpApplication[activeGroup].questions[index].tabs,
            (obj: any) => obj.id == action.payload.body.questionId
          );
          tenpApplication[activeGroup].questions[index].tabs[mainQuestionIndex].isLocked = action.payload.body.lock;
          if (tenpApplication[activeGroup].questions[index].tabs[mainQuestionIndex].questionList) {
            for (let i = 0; i < tenpApplication[activeGroup].questions[index].tabs[mainQuestionIndex].length; i++) {
              tenpApplication[activeGroup].questions[index].questionList[i].isLocked = action.payload.body.lock;
            }
          }
        }
      }

      return {
        ...state,
        sbApplication: tenpApplication,
      };
    }
    case actionTypes.SET_LOCK_UNLOCK_DMS_FILE_STATUS: {
      const tenpApplication: any = _.cloneDeep(state.dmsCurrentAppDetails);
      const body = action.payload.body;
      const activeGroup = action.payload.activeGroup;
      const activeGroupIndex = _.findIndex(tenpApplication.groups, (obj: any) => obj.name == activeGroup);
      if (body.lockType == 'All') {
        tenpApplication.groups[activeGroupIndex].isDMSLocked = body.lock;
        for (let i = 0; i < tenpApplication.groups[activeGroupIndex].questions.length; i++) {
          tenpApplication.groups[activeGroupIndex].questions[i].isLocked = body.lock;
        }
      } else {
        const questionIndex = _.findIndex(
          tenpApplication.groups[activeGroupIndex].questions,
          (obj: any) => obj.id == body.questionId[0]
        );
        tenpApplication.groups[activeGroupIndex].questions[questionIndex].isLocked = body.lock;
      }
      return {
        ...state,
        dmsCurrentAppDetails: tenpApplication,
      };
    }
    case actionTypes.CLEAR_SB_FILTER_VALUE: {
      return {
        ...state,
        sbApplicationFilter: {
          applicationStatus: [],
          subStatus: [],
          outcomes: [],
          provider: [],
          intakes: [],
          country: undefined,
          counsellor: undefined,
          agencies: [],
          course: [],
        },
      };
    }

    case actionTypes.SET_SB_FILTER_OBJECT: {
      return {
        ...state,
        sbApplicationFilter: action.payload,
      };
    }
    case actionTypes.SHOW_LOCK_APPLICATION_DIALOG: {
      return {
        ...state,
        showLockApplicationDialog: action.payload,
      };
    }
    case actionTypes.DEFER_APPLICATION_SUCCESS: {
      const tempListDetails: any = _.cloneDeep(state.applicationListDetails);
      const index = _.findIndex(tempListDetails.rows, (obj: any) => obj.id == action.payload.id);
      if (index != -1) {
        tempListDetails.rows[index] = action.payload;
      }
      return {
        ...state,
        applicationListDetails: tempListDetails,
        // showLockApplicationDialog: action.payload,
      };
    }
    case actionTypes.DEFER_APPLICATION_SUCCESS_BY_POVNT: {
      const appDetails: any = _.cloneDeep(state.currentApplicationDetails);
      appDetails.deferred = action.payload.deferred;
      appDetails.keyAnswers = action.payload.keyAnswers;
      let blocksArray = _.cloneDeep(action.payload.groups);
      blocksArray.splice(0, 1);
      for (let i = 0; i < blocksArray.length; i++) {
        blocksArray[i].index = i;
      }

      const groupName = getApplicationGroupList();
      const sortedArray = [];
      for (let i = 0; i < groupName.length; i++) {
        const newObj: any = _.find(blocksArray, (obj: any) => obj.name == groupName[i]);
        if (newObj) {
          sortedArray.push(newObj);
        }
      }
      return {
        ...state,
        currentApplicationDetails: appDetails,
        blocks: sortedArray,
        // showLockApplicationDialog: action.payload,
      };
    }

    case actionTypes.LOGOUT: {
      return {
        ...state,
        dmsCurrentAppDetails: undefined,
        sbApplicationFilter: {
          applicationStatus: [],
          subStatus: [],
          outcomes: [],
          provider: [],
          intakes: [],
          country: undefined,
          counsellor: undefined,
        },
      };
    }
    case actionTypes.RESET_DMS_APPLICATION_DETAIL: {
      return {
        ...state,
        dmsApplicationList: { ...DEFAULT_DMS_ALL_LIST_DATA },
        dmsCurrentAppDetails: undefined,
      };
    }

    case actionTypes.RESET_SB_APPLICATION: {
      return {
        ...state,
        sbApplication: {
          activeGroup: null,
          initialStateCompleted: false,
          activeGroupIndex: -1,
        },
        filteredQuestionDetail: { activeGroup: '', questionList: [] },
        blocks: [],
        currentApplicationDetails: null,
        lastQuestion: [],
        counter: 0,
        activityLogs: [],
        comments: [],
        hiddenQuestions: [],
        shownQuestions: [],
        tabIndex: [],
        showLockApplicationDialog: false,
        applicationStatusList: undefined,
        countryList: [],
        selectedCountryChangesDetails: {
          country: undefined,
          city: undefined,
          provider: undefined,
          category: undefined,
          course: undefined,
          terms: undefined,
        },
        termsList: [],
      };
    }
    case actionTypes.SET_QUESTION_REVIEW_HISTORY: {
      return {
        ...state,
        reviewHistory: action.payload,
      };
    }
    case actionTypes.REVIEW_DOCUMENT_STATUS: {
      const tempObj: any = _.cloneDeep(state.dmsCurrentAppDetails);
      const groupIndex = _.findIndex(tempObj.groups, (group: any) => group.id === action.payload.groupId);
      if (groupIndex != -1) {
        const questionIndex = _.findIndex(
          tempObj.groups[groupIndex].questions,
          (question: any) => question.id == action.payload.questionId
        );
        if (questionIndex != -1) {
          tempObj.groups[groupIndex].questions[questionIndex].reviewStatus = action.payload.reviewStatus;
        }
      }
      return {
        ...state,
        dmsCurrentAppDetails: tempObj,
        reviewdocumentstatus: !state.reviewdocumentstatus,
      };
    }
    case actionTypes.UPDATE_DMS_FILE_UPLOAD_ANSWER_STATUS: {
      const tempObj: any = _.cloneDeep(state.dmsCurrentAppDetails);
      if (tempObj) {
        const groupIndex = _.findIndex(tempObj.groups, (group: any) => group.name === action.payload.groupName);
        if (groupIndex != -1) {
          const questionIndex = _.findIndex(
            tempObj.groups[groupIndex].questions,
            (question: any) => question.id == action.payload.questionId
          );
          if (questionIndex != -1) {
            const answerIndex = _.findIndex(
              action.payload.answer,
              (answer: any) => answer.questionId === action.payload.questionId
            );
            tempObj.groups[groupIndex].questions[questionIndex].answer = action.payload.answer[answerIndex];
          }
        }
      }
      return {
        ...state,
        dmsCurrentAppDetails: tempObj,
      };
    }
    case actionTypes.SHOW_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR: {
      const tempfileUploadProgress: any = {};
      let tempprogress: any = _.cloneDeep(state.fileUploadProgress);
      if (tempprogress[action.payload.questionId]) {
        tempfileUploadProgress['question'] = tempprogress[action.payload.questionId].question;
        tempfileUploadProgress['groupName'] = tempprogress[action.payload.questionId].group;
        tempfileUploadProgress['success'] = action.payload.success;
        tempfileUploadProgress['error'] = action.payload.error;
        delete tempprogress[action.payload.questionId];
      }
      return {
        ...state,
        fileUploadSnakbar: tempfileUploadProgress,
        fileUploadProgress: tempprogress,
      };
    }
    case actionTypes.RESET_FILE_UPLOAD_SUCCESS_FAIL_SNAKBAR: {
      return {
        ...state,
        fileUploadSnakbar: undefined,
      };
    }
    case actionTypes.SET_MANDATORY_FLAG: {
      const tempObj: any = _.cloneDeep(state.dmsCurrentAppDetails);
      const tempDMSListObj: any = _.cloneDeep(state.dmsApplicationList);
      const groupIndex = _.findIndex(tempObj.groups, (group: any) => group.id === action.payload.groupId);
      if (groupIndex != -1) {
        const questionIndex = _.findIndex(
          tempObj.groups[groupIndex].questions,
          (question: any) => question.id == action.payload.questionId
        );
        if (questionIndex != -1) {
          tempObj.groups[groupIndex].questions[questionIndex].mandatory = !tempObj.groups[groupIndex].questions[
            questionIndex
          ].mandatory;
        }

        const appListindex = _.findIndex(tempDMSListObj.data, (applicationobj: any) => applicationobj.id == tempObj.id);
        if (appListindex !== -1) {
          if (tempObj.groups[groupIndex].questions[questionIndex].mandatory) {
            tempDMSListObj.data[appListindex].totalMandatoryFiles =
              tempDMSListObj.data[appListindex].totalMandatoryFiles + 1;
          } else {
            tempDMSListObj.data[appListindex].totalMandatoryFiles =
              tempDMSListObj.data[appListindex].totalMandatoryFiles - 1;
          }
        }
      }
      return {
        ...state,
        dmsCurrentAppDetails: tempObj,
        dmsApplicationList: tempDMSListObj,
      };
    }
    case actionTypes.SET_FILES_AND_VERICOUNT: {
      return {
        ...state,
        filesAndVericount: action.payload,
      };
    }

    case actionTypes.MISSING_DOCUMENT_EMAIL_SUCCESS: {
      const result = action.payload;
      return {
        ...state,
        missingDocumentOverallComment: null,
        missingDocumentAdditionalComments: [],
        processingOfficerEmails:[],
        alertSuccess: result,
      };
    }

    case actionTypes.GET_PROCESSING_OFFICER_BY_EMAIL: {
      let tempLocalEmails: any = _.cloneDeep(state.processingOfficerEmails);
      const result = action.payload;
      if (result.count) {
        for (let i = 0; i < result.rows.length; i++) {
          const value = result.rows[i];
          if (
            value.assignedUserDetail &&
            value.assignedUserDetail?.email &&
            !tempLocalEmails.includes(value.assignedUserDetail?.email)
          ) {
            tempLocalEmails.push(value.assignedUserDetail?.email);
          }
        }
      }

      return {
        ...state,
        processingOfficerEmails: tempLocalEmails,
      };
    }

    case actionTypes.ADD_PROCESSING_OFFICER_EMAIL: {
      const tempLocalEmail: any = _.cloneDeep(state.processingOfficerEmails);
      const email: any = action.payload;
      tempLocalEmail.push(email);
      return {
        ...state,
        processingOfficerEmails: tempLocalEmail,
      };
    }

    case actionTypes.ADD_MISSING_DOCUMENT_COMMENTS: {
      const tempAdditionalComments: any = _.cloneDeep(state.missingDocumentAdditionalComments);
      let overallComment: any = _.cloneDeep(state.missingDocumentOverallComment);
      let availableComments: any = [];
      const com: any = action.payload;
      if (com.id === 'comment') {
        overallComment = com.value;
      } else {
        availableComments =
          tempAdditionalComments.length > 0 ? tempAdditionalComments.filter((x: any) => x.id === com.id) : [];
        if (availableComments.length > 0) {
          tempAdditionalComments.forEach((item: any) => {
            if (item.id === com.id) item.value = com.value;
          });
        } else {
          tempAdditionalComments.push(com);
        }
      }
      return {
        ...state,
        missingDocumentOverallComment: overallComment,
        missingDocumentAdditionalComments: tempAdditionalComments,
      };
    }

    case actionTypes.DELETE_PROCESSING_OFFICER_EMAIL: {
      let tempLocalEmail: any = _.cloneDeep(state.processingOfficerEmails);
      const email: any = action.payload;
      tempLocalEmail = tempLocalEmail.filter((x: any) => x != email);
      return {
        ...state,
        processingOfficerEmails: tempLocalEmail,
      };
    }

    default: {
      return state;
    }
  }
};

const updateExternalCountryLevelAnswers = (type: any, applicationQuestions: any) => {
  const cityIndex = _.findIndex(applicationQuestions, (obj: any) => obj.answerType == 'CITY');
  const providerIndex = _.findIndex(applicationQuestions, (obj: any) => obj.answerType == 'PROVIDER');
  const categoryIndex = _.findIndex(applicationQuestions, (obj: any) => obj.answerType == 'COURSE_CATEGORY');
  const courseIndex = _.findIndex(applicationQuestions, (obj: any) => obj.answerType == 'COURSE');

  switch (type) {
    case 'COUNTRY':
      applicationQuestions[cityIndex].answer = null;
      applicationQuestions[cityIndex].options = [];
      applicationQuestions[providerIndex].answer = null;
      applicationQuestions[providerIndex].options = [];
      applicationQuestions[categoryIndex].answer = null;
      applicationQuestions[categoryIndex].options = [];
      applicationQuestions[courseIndex].answer = null;
      applicationQuestions[courseIndex].options = [];
      break;
    case 'CITY':
      applicationQuestions[providerIndex].answer = null;
      applicationQuestions[providerIndex].options = [];
      applicationQuestions[categoryIndex].answer = null;
      applicationQuestions[categoryIndex].options = [];
      applicationQuestions[courseIndex].answer = null;
      applicationQuestions[courseIndex].options = [];
      break;
    case 'PROVIDER':
      applicationQuestions[categoryIndex].answer = null;
      applicationQuestions[categoryIndex].options = [];
      applicationQuestions[courseIndex].answer = null;
      applicationQuestions[courseIndex].options = [];
      break;
    case 'COURSE_CATEGORY':
      applicationQuestions[courseIndex].answer = null;
      applicationQuestions[courseIndex].options = [];
      break;
    default:
      break;
  }
  return applicationQuestions;
  //       [COUNTRY, CITY, PROVIDER,COURSE_CATEGORY, COURSE]
};

export default sbApplicationReducer;