import React from 'react';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import SBText from '../base/SBText';
import SBButton from '../base/SBButton';
import close from './assets/close_model.png';
import { style } from 'd3';

// import logo from "./assets/atmc_logo.gif";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      // border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      width: '50%',
      minWidth: 500,
      maxHeight: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerParent: {
      height: 100,
      width: '100%',
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      justifyContent: 'space-between',
    },
    headerText: {
      color: theme.palette.primary.contrastText,
      fontSize: 24,
      fontWeight: 500,
    },
    body: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
    },
    subjectText: {
      fontSize: 14,
      fontWeight: 700,
      marginTop: 16,
    },
    bodyText: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    rowText: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    tableView: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#000000',
    },
    tableRow: {
      borderBottomColor: '#000000',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      height: 50,
      display: 'flex',
    },
    tableTextRow: {
      display: 'flex',
      flex: 0.6,
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableButtonRow: {
      display: 'flex',
      flex: 0.4,
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableSmallDivider: { width: 1, backgroundColor: '#000000' },
    approveBt: {
      backgroundColor: '#8BB19C',
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    disApproveBt: {
      backgroundColor: '#8BB19C',
      height: 38,
      borderRadius: 20,
      flex: 1,
    },

    greyBt: {
      backgroundColor: '#979797',
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    okTOProceedBt: {
      backgroundColor: '#8BB19C',
      height: 38,
      borderRadius: 20,
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
      maxWidth: 150,
    },
    middleDivider: {
      width: theme.spacing(2),
    },

    tabButtonUnselectd: {
      height: 38,
      borderRadius: 5,
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: '#D1D1D1',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      color: 'grey',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: '#D1D1D1',
      },
    },

    tabButtonSelectd: {
      height: 38,
      borderRadius: 5,
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: '#8BB19C',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      color: 'white',
      fontWeight: 600,
      '&:hover': {
        backgroundColor: '#8BB19C',
      },
    },

    htmlParent: {
      paddingLeft: 16,
      paddingRight: 16,
      borderWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
      marginTop: 20,
    },
  })
);

type SBEmailWithTabsTemplatesProps = {
  className?: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  showButton: boolean;
  value?: any;
};
const SBEmailWithTabsTemplates: React.FC<SBEmailWithTabsTemplatesProps> = ({
  open,
  onConfirm,
  onCancel,
  showButton,
  value,
}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const okToProceed = () => {
    onConfirm();
  };

  const handleClose = () => {
    onCancel();
  };

  const getBody = (value: any) => {
    return <div dangerouslySetInnerHTML={{ __html: value[selectedIndex].content }}></div>;
    // return null;
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.headerParent}>
            <SBText text={'Verification email'} className={classes.headerText} />

            <img src={close} onClick={onCancel} />
          </div>

          <div className={classes.body}>
            <div style={{ display: 'flex', maxHeight: 100 }}>
              {value.map((item: any, i: number) => (
                <div style={{ marginLeft: i !== 0 ? 10 : 0 }}>
                  <SBButton
                    text={item.emailId}
                    className={i === selectedIndex ? classes.tabButtonSelectd : classes.tabButtonUnselectd}
                    onClick={() => setSelectedIndex(i)}
                  />
                </div>
              ))}
            </div>

            <div className={classes.htmlParent}>
              <SBText
                text={value && value[selectedIndex].subject ? `SUBJECT: ${value[selectedIndex].subject}` : 'SUBJECT:""'}
                className={classes.subjectText}
              />
              {value && value.length > 0 ? getBody(value) : ''}
            </div>
          </div>

          {showButton ? (
            <div
              style={{
                textAlign: 'center',
                paddingBottom: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <SBButton
                icon={false}
                text={'OK to Proceed'}
                onClick={() => okToProceed()}
                className={classes.okTOProceedBt}
              />
            </div>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
};

export default SBEmailWithTabsTemplates;
