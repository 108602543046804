import { Agent } from "../types";
import { AgentTableViewItem } from "../types/agent-table-view-item";
import { getAgentStatusViewDetails } from "./agent-status";

export const mapAgentDataToTableViewItem = (agent: Agent): AgentTableViewItem  => {
  return {
    ...agent,
    firstKeyPerson: agent.keyPersons[0],
    statusViewDetails: getAgentStatusViewDetails(agent.status)
  };
};
