import { LoggedInData, LOGGED_IN_USER_DATA_COOKIE, REMEMBER_ME_USERNAME } from './constant';

export const saveLoggedInData = (data: LoggedInData) => {
  localStorage.setItem(LOGGED_IN_USER_DATA_COOKIE, JSON.stringify(data));
};

export const getLoggedInData = () => {
  const dataString = localStorage.getItem(LOGGED_IN_USER_DATA_COOKIE);
  if (dataString) {
    return JSON.parse(dataString) as LoggedInData;
  }
  return null;
};

export const getAccessToken = async () => {
  const data = await getLoggedInData();
  if (data) {
    return data.access_token;
  }

  return null;
};

export const removeLoggedInData = () => {
  const rememberMeUsername = localStorage.getItem(REMEMBER_ME_USERNAME);
  localStorage.clear();
  if (rememberMeUsername) {
    localStorage.setItem(REMEMBER_ME_USERNAME, rememberMeUsername);
  }
};

export const gotoLogin = () => {
  window.location.href = '/login';
};

export const getPassKey = () => {
  return 'e4160b4a9197c0ea84d777a3fcd36f030b35d946';
};
