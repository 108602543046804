import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  IconButton,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import SBText from '../../../components/base/SBText';
import { tableStyles } from '../../../styles/table';
import { LABELS } from '../../../util/labels';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { HistoryItem } from '../types';
import { DATE_TIME_FORMAT, SERVER_DATE_FORMAT } from '../constants';
import moment from 'moment';
import { useDownloadReport } from '../hooks/useDownloadReport';

const useStyles = makeStyles((theme: Theme) => createStyles(tableStyles(theme)));

interface Props {
  historyList: HistoryItem[];
}

const HistoryTable: React.FC<Props> = ({ historyList }) => {
  const classes = useStyles();
  const { downloadReport } = useDownloadReport();

  const capitalizedCellClassName = `${classes.bodyText}  ${classes.capitalize}`;

  const handleDownloadClick = (e: any, historyItem: HistoryItem) => {
    e.stopPropagation();
    downloadReport(historyItem);
  };

  return (
    <div className={`${classes.tableParents} ${classes.tableWithPagination}`} style={{ padding: '16px 8px 2px' }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} stickyHeader style={{ boxShadow: 'inset 0px 0px 1px 0px #AAA' }}>
          <TableHead className={classes.TableRow}>
            <TableRow>
              <TableCell align="left">
                <SBText text={LABELS.NAME} className={classes.HeaderPadding} />
              </TableCell>
              <TableCell align="left">
                <SBText text={'Date'} className={classes.HeaderPadding} />
              </TableCell>
              <TableCell align="left">
                <SBText text={LABELS.ACTIONS} className={classes.HeaderPadding} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {historyList.map((item: HistoryItem) => (
              <TableRow
                onClick={(e: any) => {}}
                key={item.id}
                style={{
                  alignItems: 'center',
                }}
              >
                <TableCell component="th">
                  <SBText text={item.name} className={capitalizedCellClassName} />
                </TableCell>
                <TableCell align="left">
                  <SBText text={moment(item.createdAt).format(DATE_TIME_FORMAT)} className={classes.bodyText} />
                </TableCell>
                <TableCell align="left">
                  {item.fileName ? (
                    <IconButton style={{ padding: 0 }} onClick={(e: any) => handleDownloadClick(e, item)}>
                      <CloudDownloadIcon style={{ color: '#606378' }} />
                    </IconButton>
                  ) : (
                    <SBText
                      style={{ color: 'gray', fontSize: 12 }}
                      text={
                        moment().diff(moment(item.createdAt), 'days') >= 1 ? 'Error generating report' : 'Generating...'
                      }
                      className={classes.bodyText}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HistoryTable;
