export type LPO_TABLE_ACTION = "REJECT" | "INVITE" | "ONBOARD" | "APPROVE" |  "RESEND_INVITE";

export interface LPOTableActionItem {
  label: string;
  action: LPO_TABLE_ACTION;
}

export type LPOTableActionLink = {
  [key in LPO_TABLE_ACTION]: LPOTableActionItem;
};

export const LPO_ACTION_LINKS: LPOTableActionLink = {
  ONBOARD: {
    label: "Onboard",
    action: "ONBOARD",
  },
  APPROVE: {
    label: "Approve",
    action: "APPROVE",
  },
  INVITE: {
    label: "Invite",
    action: "INVITE",
  },
  REJECT: {
    label: "Reject",
    action: "REJECT",
  },
  RESEND_INVITE: {
    label: "Resend Invite",
    action: "RESEND_INVITE",
  },
};
