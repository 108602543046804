import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { MenuItem, Theme, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import downArrow from "./assets/down_arrow.png";
import Menu from "@material-ui/core/Menu";
import clsx from "clsx";
import SBText from "../../components/base/SBText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    sort: {
      height: 50,
      width: 200,
      backgroundColor: "white",
      //borderRadius: 2,
      //borderWidth: 1,
      //borderColor: "#DDDDDD",
      //borderStyle: "solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 10,
      paddingRight: 10,
      fontFamily: "Poppins",
    },
    menuItem: { fontFamily: "Poppins", letterSpacing: 0.75 },
  })
);

type SortByProps = {
  className?: string;
  dropDownWidth?: number;
  value: string;
  onItemClick: (text: string) => void;
};

const SortBy: React.FC<SortByProps> = ({
  className,
  dropDownWidth,
  value,
  onItemClick,
  ...rest
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (item: string) => {
    setAnchorEl(null);
    onItemClick(item);
  };

  return (
    <div className={clsx(classes.root)}>
      <div
        onClick={(event) => handleClick(event)}
        className={clsx(classes.sort, className)}
      >
        <SBText text={value ? value : "Sort By"} />

        <img src={downArrow} style={{ height: 10 }} />
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={false}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: dropDownWidth ? dropDownWidth : 200,
            maxWidth: 300,
            fontFamily: "Poppins",
            borderRadius: 0,
            letterSpacing: 0.75,
          },
        }}
        // className={classes.customWidth}
      >
        <MenuItem
          onClick={() => onMenuItemClick("Date")}
          className={classes.menuItem}
        >
          Date
        </MenuItem>
        <MenuItem
          onClick={() => onMenuItemClick("Student Name")}
          className={classes.menuItem}
        >
          Student Name
        </MenuItem>
        <MenuItem
          onClick={() => onMenuItemClick("Application ID")}
          className={classes.menuItem}
        >
          Application ID
        </MenuItem>
        <MenuItem
          onClick={() => onMenuItemClick("Pending verification")}
          className={classes.menuItem}
        >
          Pending verification
        </MenuItem>
        <MenuItem
          onClick={() => onMenuItemClick("")}
          className={classes.menuItem}
        >
          Clear All
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SortBy;
