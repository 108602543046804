import { ActionTypes } from "../actions/agent";
import { ActionType } from "../../../../reducer/types";

interface RefreeAttachFileState {
  supportingDocument: [];
}

const initialState: RefreeAttachFileState = {
  supportingDocument: [],
};

const downloadSupportingDocumentsReducer = (
  state = initialState,
  action: ActionType
): RefreeAttachFileState => {
  switch (action.type) {
    case ActionTypes.SET_SUPPORTING_DOCUMENT: {
      return {
        ...state,
        supportingDocument: action.payload,
      };
    }
    default:
      return state;
  }
};

export default downloadSupportingDocumentsReducer;
