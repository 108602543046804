import React, { useState, useCallback } from "react";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { makeStyles, createStyles,withStyles } from "@material-ui/styles";
import { colors, Theme } from "@material-ui/core";
import { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import deleteIcon from "./assets/file_delete_icon.png";
import downloadIcon from "./assets/file_download_icon.png";
import SBText from "../SBText";
import CommenErrorDialog from "../../CommenErrorDialog";
import ConfirmationDialog from "../../ConfirmationDialog";
import LinearProgress from '@material-ui/core/LinearProgress';
import { RootState } from "../../../reducer";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { display: "flex", flexDirection: "row" },
    dropZone: {
      border: `1px solid #727487`,
      // paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(2),
      // paddingLeft: theme.spacing(2),

      outline: "none",
      display: "flex",
      borderRadius: 5,
      height: 45,
      backgroundColor: "#FCFBFB",
      flex: 1,
      width: "calc(100% - 90px)",
      [theme.breakpoints.up(1366)]: {
        width: "calc(100% - 110px)",
      },
      // flexWrap: "wrap",
      alignItems: "center",
      "&:hover": {
        backgroundColor: colors.grey[50],
        opacity: 0.5,
        cursor: "pointer",
      },
      flexDirection:'column'
    },
    one: {
      border: `1px solid #727487`,
      // paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(2),
      // paddingLeft: theme.spacing(2),

      outline: "none",
      display: "flex",
      borderRadius: 5,
      height: 100,
      backgroundColor: "#FCFBFB",
      flex: 1,

      // flexWrap: "wrap",
      alignItems: "center",
      "&:hover": {
        backgroundColor: colors.grey[50],
        opacity: 0.5,
        cursor: "pointer",
      },
    },
    disbleDropZone: {
      opacity: 0.3,
    },
    dragActive: {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      width: "85%",
    },
    image: {
      width: 40,
      height: 40,
    },
    info: {
      // marginTop: theme.spacing(0),
      fontSize: 14,
      letterSpacing: 0.75,
    },
    fileUpload: {
      height: "100px !important",
      border: "1px solid black !important",
    },
    list: {
      maxHeight: 320,
    },
    label: {
      textAlign: "left",
      color: theme.palette.primary.light,
      display: "flex",
    },
    actions: {
      marginTop: theme.spacing(2),
      display: "flex",
      justifyContent: "flex-end",
      "& > * + *": {
        marginLeft: theme.spacing(2),
      },
    },
    buttonParent: {
      height: 45,
      width: 100,
      [theme.breakpoints.up(1366)]: {
        width: 110,
      },
      display: "flex",
      alignItems: "center",
      // justifyContent: "space-between",
      // paddingLeft: 10,
    },
    deleteButton: {
      height: 40,
      width: 40,
      [theme.breakpoints.up(1366)]: {
        height: 45,
        width: 45,
        marginLeft: 10,
      },
      display: "flex",
      backgroundColor: "#D27F65DD",
      borderRadius: 5,
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 5,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#D27F65",
      },
    },
    deleteDisableButton: {
      opacity: 0.6,
      cursor: "auto",
      "&:hover": {
        backgroundColor: "#D27F65DD",
      },
    },
    downloadButton: {
      height: 40,
      width: 40,
      marginLeft: 10,
      [theme.breakpoints.up(1366)]: {
        height: 45,
        width: 45,
        marginLeft: 10,
      },
      display: "flex",
      cursor: "pointer",
      backgroundColor: "#DEDEDE",
      borderRadius: 5,
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        backgroundColor: "#CCCCCC",
      },
    },

    downloadDisableButton: {
      height: 40,
      width: 40,
      marginLeft: 5,
      [theme.breakpoints.up(1366)]: {
        height: 45,
        width: 45,
        marginLeft: 10,
      },
      display: "flex",
      backgroundColor: "#DEDEDE",
      borderRadius: 5,
      alignItems: "center",
      opacity: 0.5,
      justifyContent: "center",
    },

    buttonImage: { height: 20, objectFit: "contain" },
    buttonDisableImage: { height: 20, objectFit: "contain", opacity: 0.5 },
    placeHolderParent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: 10,
      flex: 1,
      // width: "-moz-min-content",
      width: "100%",
    },
    placeHolderText: {
      color: "#596D79",
      fontSize: 12,
      [theme.breakpoints.up(1366)]: {
        fontSize: 13,
      },
      [theme.breakpoints.up(1440)]: {
        fontSize: 14,
      },
    },
    placeHolderBrowsText: {
      color: "#3576CB",
      fontSize: 12,
      [theme.breakpoints.up(1366)]: {
        fontSize: 13,
      },
      [theme.breakpoints.up(1440)]: {
        fontSize: 14,
      },
    },
    fileName: {
      color: "#000000",
      fontSize: 14,
      textAlign: "left",
      // maxWidth: 300,
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "block",
    },
  })
);

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTopRightRadius: 3,
      borderBottomRightRadius:3,
      height:6
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#8BB19C',
    },
  }),
)(LinearProgress);

const BorderunDeterminedLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTopRightRadius: 3,
      borderBottomRightRadius:3,
      height:6
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#bdbfbe',
    },
  }),
)(LinearProgress);

type PropsFromRedux = ConnectedProps<typeof connector>;

type SBFileSelectorProps = PropsFromRedux & {
  className?: string;
  onUploadFile: (file: any) => void;
  onRemoveFile?: () => void;
  uploadError?: string;
  setUploadError: (error: string) => void;
  fileLoadPercentage: number;
  onFileSelected: (file: any, questionObj: any) => void;
  isUploading?: boolean;
  multiselect?: boolean;
  title?: string;
  disable?: boolean;
  onDeleteFile: (questionObj: any) => void;
  size: string;
  questionObj?: any;
  value: string;
  onDownloadFile: (questionObj: any) => void;
  [x: string]: any;
  parentForm?: any;
  outcomeObj?: any;
  applicationId?: any;
  disabled?: boolean;
  // uploadProgress?: any,
};
const SBFileSelector: React.FC<SBFileSelectorProps> = ({
  fileLoadPercentage,
  setUploadError,
  onFileSelected,
  uploadError,
  className,
  onUploadFile,
  isUploading,
  onRemoveFile,
  multiselect,
  title,
  disable,
  onDeleteFile,
  size,
  questionObj,
  onDownloadFile,
  value,
  parentForm,
  outcomeObj,
  applicationId,
  disabled,
  // uploadProgress,
  fileUploadProgress,
  ...rest
}) => {
  const classes = { ...useStyles(), ...(rest.styles?.() || {}) };
  const [files, setFiles] = useState<any>([]);
  const [errorDialog, setErrorDialog] = useState<any>(false);
  const [downloadFileStatus, setDownloadFileStatus] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [lockError, SetLockError] = useState<boolean>(false);
  // const [FileObj, setFileObj] = useState(
  //   questionObj ? questionObj : outcomeObj
  // );

  // console.log("***11", fileUploadProgress)
  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles[0].name.includes("#")) {
        setErrorDialog(true);
        return;
      }

      if (multiselect) {
        setFiles((prevFiles: any) => prevFiles.concat(acceptedFiles));
      } else {
        setFiles((prevFiles: any) => [].concat(acceptedFiles));
      }

      let renamedAcceptedFiles = [];
      if (questionObj && applicationId) {
        console.log("acceptedFiles", acceptedFiles);

        renamedAcceptedFiles = acceptedFiles.map(
          (file: any) =>
            new File(
              [file],
              `dir_${applicationId}_${new Date().getTime()}#${file.name}`,
              {
                type: file.type,
              }
            )
        );
      } else {
        renamedAcceptedFiles = acceptedFiles.map(
          (file: any) =>
            new File([file], `outcome_${new Date().getTime()}#${file.name}`, {
              type: file.type,
            })
        );
      }

      console.log("Files >>>>>disabled", disabled);

      if (!disabled || disabled != true) {
        onUploadFile(renamedAcceptedFiles);
        onFileSelected(renamedAcceptedFiles[0], questionObj);
        // onFileAdded(acceptedFiles);
        setUploadError("");
      }
    },
    [setUploadError]
  );

  const onFileAdded = (files: any) => {};

  // const handleRemoveAll = () => {
  //   setFiles([]);
  //   if (onRemoveFile) {
  //     onRemoveFile();
  //   }
  // };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
  });

  const onDeletePress = () => {
    // const fileList = _.cloneDeep(files);
    // fileList.splice(0, 1);
    // setFiles(fileList);

    if(questionObj && fileUploadProgress.hasOwnProperty(questionObj.id)) {
      return
    }
    if(questionObj && questionObj.isLocked)  {
      SetLockError(true)
      return
    }
    if (value) {
      setDeleteConfirmOpen(true);
    }
  };

  const FileObj = questionObj ? questionObj : outcomeObj;
  // console.log("*** File", fileUploadProgress[questionObj.id])

  return (
    <>
      {rest.labelText ? <SBText text={rest.labelText} className={classes.label} /> : null}
      <div
        {...rest}
        className={clsx(
          classes.root,
          // className,
          (questionObj && questionObj.status && questionObj.status === 'DISABLED') || disabled
            ? classes.disbleDropZone
            : null
        )}
      >
        <div
          className={clsx({
            [rest.largeBox ? classes.one : classes.dropZone]: true,

            [classes.dragActive]: isDragActive,
          })}
          {...getRootProps()}
        >
          <input
            {...getInputProps({
              disabled:
                (questionObj && questionObj.status && questionObj.status === 'DISABLED') ||
                (questionObj && fileUploadProgress.hasOwnProperty(questionObj.id)) ||
                disabled ||
                (questionObj && questionObj.isLocked)
                  ? true
                  : false,
            })}
          />
          <div className={classes.placeHolderParent}>
            {!value ? (
              <>
                <SBText text={'Drop files here or'} className={classes.placeHolderText} />
                <div style={{ width: 5 }} />
                <SBText text={'browse'} className={classes.placeHolderBrowsText} />
              </>
            ) : (
              <SBText text={value} className={classes.fileName} />
            )}
          </div>

          {questionObj && fileUploadProgress.hasOwnProperty(questionObj.id) && (
            <div style={{ width: '100%' }}>
              {fileUploadProgress[questionObj.id].progress <= 0 ? (
                <BorderunDeterminedLinearProgress
                  color="secondary"
                  variant="indeterminate"
                />
              ) : (
                <BorderLinearProgress color="secondary" value={fileUploadProgress[questionObj.id].progress} variant="determinate" />
              )}
            </div>
          )}
        </div>
        <div className={classes.buttonParent}>
          <div
            className={`${classes.deleteButton} ${
              !(questionObj && fileUploadProgress.hasOwnProperty(questionObj.id)) && value
                ? ''
                : classes.deleteDisableButton
            }`}
            onClick={() => onDeletePress()}
          >
            <img src={deleteIcon} className={classes.buttonImage} />
          </div>

          <div
            className={
              !(questionObj && fileUploadProgress.hasOwnProperty(questionObj.id)) &&
              ((questionObj && questionObj.answer) || (FileObj && FileObj.fileName))
                ? classes.downloadButton
                : classes.downloadDisableButton
            }
            onClick={() => {
              if (
                !(questionObj && fileUploadProgress.hasOwnProperty(questionObj.id)) &&
                ((questionObj && questionObj.answer) || (FileObj && FileObj.fileName))
              ) {
                onDownloadFile(FileObj);
              }
            }}
          >
            <img
              src={downloadIcon}
              className={questionObj && questionObj.answer ? classes.buttonImage : classes.buttonDisableImage}
            />
          </div>
        </div>
      </div>
      <CommenErrorDialog
        open={errorDialog}
        onSubmit={() => setErrorDialog(false)}
        message={'File name is not valid, File name should not contain "#" character'}
        header={'Error'}
      />

      <ConfirmationDialog
        open={deleteConfirmOpen}
        onConfirm={() => {
          setDeleteConfirmOpen(false);
          onDeleteFile(questionObj ? questionObj : outcomeObj);
        }}
        onCancel={() => setDeleteConfirmOpen(false)}
        message={`Are you sure you want to delete "${value}"?`}
        header={'Delete File'}
        confirmButtonText="Yes"
        cancelButtonText="No"
      />

      <CommenErrorDialog
        open={lockError}
        onSubmit={() => SetLockError(false)}
        message={'Question is locked by admin, You can not delete this file'}
        header={'Error'}
      />
    </>
  );
};
const mapState = (state: RootState) => ({
  fileUploadProgress: state.sbApplication.fileUploadProgress
})

const mapDispatch = {}

// export default SBFileSelector;
const connector = connect(mapState, mapDispatch);
export default connector(SBFileSelector);