import React from 'react'
import { Route, Switch } from 'react-router';
import AgentTableView from './AgentTableView';

const AgentView = () => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path={""}
          component={AgentTableView}
        >
        </Route>
      </Switch>
    </div>
  );
}

export default AgentView;
