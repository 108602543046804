import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../config";
import { RootState } from "../reducer";
import { ActionType } from "../reducer/types";
import { hasError } from "../util";
import { API_URL } from "../util/apiUrls";
import { SET_ERROR } from "./appError";
import { get, post, put } from "./common";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";

export const FETCH_ALL_AGENCIES_SUCCESS = "FETCH_ALL_AGENCIES_SUCCESS";
export const FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS';
export const APPEND_ENTITIES = 'APPEND_ENTITIES';

export const UPDATE_ENTITIES_SEARCH_PARAMS = 'UPDATE_ENTITIES_SEARCH_PARAMS';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';
export const FETCH_ENTITY_SUCCESS = 'FETCH_ENTITY_SUCCESS';

interface EntitySearchParams {
    limit: number;
    offset: number;
    searchText: string;
}
export const getEntityList = (entirySearchParams: EntitySearchParams): ThunkAction<
    void,
    RootState,
    unknown,
    ActionType
> => async (dispatch, getState) => {
    const { offset } = entirySearchParams;
    return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
            dispatch({ type: ON_LOADING_START });
            const result = await post(
                API_URL.ENTITY.LIST,
                entirySearchParams,
                dispatch
            );
            await dispatch({
                type: offset > 0 ? APPEND_ENTITIES : FETCH_ENTITIES_SUCCESS,
                payload: result,
            });
            resolve(result);
            dispatch({ type: ON_LOADING_FINISHED });
        }
    );
};

export const getAllAgencies= (): ThunkAction<
    void,
    RootState,
    unknown,
    ActionType
> => async (dispatch, getState) => {
    return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
            dispatch({ type: ON_LOADING_START });
            const result = await post(
                API_URL.ENTITY.LIST,
                {},
                dispatch
            );
            await dispatch({
                type: FETCH_ALL_AGENCIES_SUCCESS,
                payload: result,
            });
            resolve(result);
            dispatch({ type: ON_LOADING_FINISHED });
        }
    );
};

interface EntityParams {
    name: string;
    key: string;
    description?: string;
    permissions: any[];
    [x: string]: any;
}

export const createNewEntity = (roleParams: EntityParams
): ThunkAction<void, RootState, unknown, ActionType> => async (
    dispatch,
    getState
) => {
        return new Promise(
            async (resolve: (x: any) => void, reject: (x: any) => void) => {
                dispatch({ type: ON_LOADING_START });

                const result = await post(
                    API_URL.ENTITY.BASE,
                    roleParams,
                    dispatch
                );
                if(hasError(result)) {
                    dispatch({ type: SET_ERROR, payload: result.error?.message });
                } else {
                    dispatch(getEntityList({ offset: 0, searchText: '', limit: 10 }));
                    dispatch(getAllAgencies());
                }
                resolve(result);
                dispatch({ type: ON_LOADING_FINISHED });
            }
        );
};

export const updateEntity = (roleParams: EntityParams
): ThunkAction<void, RootState, unknown, ActionType> => async (
    dispatch,
    getState
) => {
        const { ...rest } = roleParams;

        return new Promise(
            async (resolve: (x: any) => void, reject: (x: any) => void) => {
                dispatch({ type: ON_LOADING_START });

                const result = await put(
                    API_URL.ENTITY.BY_ID(rest.id),
                    roleParams,
                    dispatch
                );
                if(hasError(result)) {
                    dispatch({ type: SET_ERROR, payload: result.error?.message });
                } else {
                    dispatch(getEntityList({ offset: 0, searchText: '', limit: 10 }));
                    dispatch(getAllAgencies());
                }
                resolve(result);
                dispatch({ type: ON_LOADING_FINISHED });
            }
        );
    };

export const getEntity = (id: number
): ThunkAction<void, RootState, unknown, ActionType> => async (
    dispatch,
    getState
) => {
        return new Promise(
            async (resolve: (x: any) => void, reject: (x: any) => void) => {
                dispatch({ type: ON_LOADING_START });

                const result = await get(
                    API_URL.ENTITY.BY_ID(id),
                    dispatch
                );
                resolve(result);
                await dispatchEntity(result)(dispatch);
                dispatch({ type: ON_LOADING_FINISHED });
            }
        );
    };

export const dispatchEntity = (result: any) => async (dispatch: Dispatch) => {
    dispatch({
        type: FETCH_ENTITY_SUCCESS,
        payload: result,
    });
};