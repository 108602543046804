import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from "../../../actions";
import { API_URL } from "../../../util/apiUrls";

export const useLoadReportFilterMaster = () => {
  const dispatch = useDispatch();

  const loadReportFilterMaster = useCallback(
    async (filterField: string, filterValue: string) => {
      return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
          dispatch({ type: ON_LOADING_START });
          try {
            const result = await get(
              API_URL.REPORTS.FILTERS(filterField, filterValue),
              dispatch
            );
            resolve(result);
          } finally {
            dispatch({ type: ON_LOADING_FINISHED });
          }
        }
      );
    },
    [dispatch]
  );

  return {
    loadReportFilterMaster,
  };
};
