import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";

import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import UploadFileModal from "../UploadFileModal/UploadFileModal";

import { usePostApproveDisapproveRefereeCheck } from "../../../hooks/usePostApproveDisapproveRefereeCheck";
import { useFetchRefreeDetails } from "../../../hooks/useFetchRefreeDetails";
import { useFetchRefferenceCheckAttachFile } from "../../../hooks/useFetchRefferenceCheckAttachedFile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      padding: "20px 20px",
    },
    attachedFile: {
      color: "#8B849B",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 400,
      width: "80%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    fileName: {
      width: "80%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    section: {
      marginTop: "20px",
    },
    sectionHeader: {
      color: "#8B849B",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
    sectionData: {
      color: "#5C5F74",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 700,
      marginTop: "5px",
    },
    textbox: {
      width: "100%",
      borderRadius: "70px",
    },
    error: {
      textTransform: "none",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      color: "red",
    },
    buttonContainer: {
      border: "1px solid #D27F65",
    },
    label: {
      textTransform: "none",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      color: "#D27F65",
    },
    filledButton: {
      backgroundColor: "#D27F65",
      color: "white",
      float: "right",
    },
    refCheckApprovalInfo: {
      color: "#8B849B",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
    refCheckApprovalComment: {
      color: "#5C5F74",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 700,
      marginTop: "5px",
    },
    attachedFileName: {
      display: "inline-block",
      textDecoration: "underline",
      cursor: "pointer",
      // maxWidth: "40%",
      // whiteSpace: "nowrap",
      // textOverflow: "ellipsis",
      // overflow: "hidden",
    },
    approvedBox: {
      margin: "auto",
      color: "#a8e0b7",
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 700,
      padding: "10px",
      textAlign: "center",
      width: "40%",
    },
    dispprovedBox: {
      margin: "auto",
      color: "#fc8d8d",
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 700,
      padding: "10px",
      textAlign: "center",
      width: "40%",
    },
    approveAgainButton: {
      color: "#8B849B",
      textDecoration: "underline",
      fontFamily: "Poppins",
      fontSize: "15px",
      fontWeight: 400,
      margin: "auto",
      cursor: "pointer",
      width: "40%",
      textAlign: "center",
    },
  })
);

interface TabPanelProps {
  agentId: number;
  refreeId: number;
  name: string;
  institute: string;
  jobTitle: string;
  country: string;
  workEmail: string;
  phone: string;
  yearsOfAgreement: number;
  isApproved: boolean;
  approvedBy: string;
  attachmentName: string;
  approvalComment: string;
  fileKey: string;
  approveDisapproveTimeStamp: string;
}

const TabPanel: React.FC<TabPanelProps> = ({
  agentId,
  refreeId,
  name,
  institute,
  jobTitle,
  country,
  workEmail,
  phone,
  yearsOfAgreement,
  isApproved,
  approvedBy,
  attachmentName,
  approvalComment,
  fileKey,
  approveDisapproveTimeStamp,
}) => {
  const classes = useStyles();
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(
    false
  );
  const [
    isDownloadAttachedFileButtonClicked,
    setIsDownloadAttachedFileButtonClicked,
  ] = useState(false);
  const [textBoxValue, setTextBoxValue] = useState("");
  const [error, setError] = useState("");
  const [attachedFileInfo, setAttachedFileInfo] = useState({
    fileName: "",
    isError: false,
    file: [],
    size: 0,
  });
  const [isApproveAgain, setIsApproveAgain] = useState(false);

  const {
    RefereeApproveDisapproveReducer,
    refreeCheckAttachFileReducer,
  } = useSelector((state: any) => state);

  let approvalDate = new Date(approveDisapproveTimeStamp);

  const { postRefereeCheckApproval } = usePostApproveDisapproveRefereeCheck();
  const { fetchRefreeDetails } = useFetchRefreeDetails();
  const {
    fetchRefferenceCheckAttachFile,
  } = useFetchRefferenceCheckAttachFile();

  const setUploadedFileInState = (file: any) => {
    setAttachedFileInfo((prevState) => ({
      ...prevState,
      fileName: file.fileName,
      isError: false,
      file: file.file,
      size: file.size,
    }));
    setIsFileUploadModalVisible(false);
  };

  const handleClearAttachedFile = () => {
    setAttachedFileInfo((prevState) => ({
      ...prevState,
      fileName: "",
      isError: false,
      file: [],
      size: 0,
    }));
  };

  const handleApproveClick = () => {
    if (textBoxValue === "") {
      setError("Comment is needed");
    } else {
      if (
        attachedFileInfo.fileName === "" ||
        attachedFileInfo.fileName === undefined
      ) {
        setError("Please attach the screenshot");
      } else {
        setError("");
        const formData = new FormData();
        formData.append("file", attachedFileInfo.file as any);
        formData.append("isApproved", true as any);
        formData.append("comment", textBoxValue as any);
        postRefereeCheckApproval(refreeId, formData);
      }
    }
  };

  const handleDisapproveClick = () => {
    if (textBoxValue === "") {
      setError("Comment is needed");
    } else {
      if (
        attachedFileInfo.fileName === "" ||
        attachedFileInfo.fileName === undefined
      ) {
        setError("Please attach the screenshot");
      } else {
        setError("");
        const formData = new FormData();
        formData.append("file", attachedFileInfo.file as any);
        formData.append("isApproved", false as any);
        formData.append("comment", textBoxValue as any);
        postRefereeCheckApproval(refreeId, formData);
      }
    }
  };

  useEffect(() => {
    if (RefereeApproveDisapproveReducer.refreeApproval)
      fetchRefreeDetails(agentId);
  }, [RefereeApproveDisapproveReducer]);

  const handleTextBoxOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTextBoxValue(event.target.value);
  };

  useEffect(() => {
    if (
      refreeCheckAttachFileReducer.refreeCheckAttachFile &&
      refreeCheckAttachFileReducer.refreeCheckAttachFile.length !== 0 &&
      isDownloadAttachedFileButtonClicked
    ) {
      setIsDownloadAttachedFileButtonClicked(false);
      const url = window.URL.createObjectURL(
        new Blob([refreeCheckAttachFileReducer.refreeCheckAttachFile], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileKey}`);
      document.body.appendChild(link);
      link.click();
      link?.parentNode?.removeChild(link);
    }
  }, [refreeCheckAttachFileReducer]);

  const handleFileDownload = () => {
    fetchRefferenceCheckAttachFile(fileKey);
    setIsDownloadAttachedFileButtonClicked(true);
  };

  return (
    <div className={classes.mainContainer}>
      {isFileUploadModalVisible && (
        <UploadFileModal
          setUploadFileModalVisibility={setIsFileUploadModalVisible}
          fileUpload={setUploadedFileInState}
        />
      )}
      <div className={classes.section}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12} xl={6} lg={6}>
            <div className={classes.sectionHeader}>Name</div>
            <div className={classes.sectionData}>{name}</div>
          </Grid>
          <Grid item md={4} xs={12} xl={6} lg={6}>
            <div className={classes.sectionHeader}>Education Institute</div>
            <div className={classes.sectionData}>{institute}</div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12} xl={6} lg={6}>
            <div className={classes.sectionHeader}>Job Title</div>
            <div className={classes.sectionData}>{jobTitle}</div>
          </Grid>
          <Grid item md={4} xs={12} xl={6} lg={6}>
            <div className={classes.sectionHeader}>Country</div>
            <div className={classes.sectionData}>{country}</div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12} xl={6} lg={6}>
            <div className={classes.sectionHeader}>Work Email</div>
            <div className={classes.sectionData}>{workEmail}</div>
          </Grid>
          <Grid item md={4} xs={12} xl={6} lg={6}>
            <div className={classes.sectionHeader}>Phone</div>
            <div className={classes.sectionData}>{phone}</div>
          </Grid>
        </Grid>
      </div>
      {isApproved === null || isApproveAgain ? (
        <div className={classes.section}>
          <Grid container spacing={1}>
            <Grid item md={4} xs={11} xl={11} lg={11}>
              <TextField
                id="outlined-full-width"
                value={textBoxValue}
                onChange={handleTextBoxOnChange}
                placeholder="Reference check comments (mandatory)"
                className={classes.textbox}
                fullWidth
                margin="normal"
                multiline
                rows={2}
                InputProps={{
                  style: {
                    borderRadius: "10px",
                  },
                }}
                inputProps={{
                  maxLength: 1000,
                }}
                variant="outlined"
                error={false}
                helperText={""}
              />
            </Grid>
            <Grid item md={4} xs={12} xl={1} lg={1}>
              {attachedFileInfo.fileName == "" ? (
                <AttachFileIcon
                  style={{ marginTop: "60px", cursor: "pointer" }}
                  onClick={() => setIsFileUploadModalVisible(true)}
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          {attachedFileInfo.fileName !== "" && (
            <div className={classes.attachedFile}>
              <CancelIcon
                style={{ fontSize: "16px", color: "red", cursor: "pointer" }}
                onClick={handleClearAttachedFile}
              />
              <AttachFileIcon style={{ fontSize: "16px" }} />{" "}
              <span className={classes.fileName}>
                {attachedFileInfo.fileName}{" "}
              </span>
            </div>
          )}
          <div className={classes.error}>{error}</div>
        </div>
      ) : (
        <div className={classes.section}>
          <div className={classes.refCheckApprovalInfo}>
            Ref check {isApproved == true ? "approved" : "disapproved"} |
            {approvedBy} | {approvalDate.toLocaleDateString()},{" "}
            {approvalDate.toLocaleTimeString()} |
            <AttachFileIcon style={{ fontSize: "14px" }} />
            <span
              className={classes.attachedFileName}
              onClick={handleFileDownload}
            >
              {attachmentName}
            </span>
          </div>
          <div className={classes.refCheckApprovalComment}>
            {approvalComment}
          </div>
        </div>
      )}
      {isApproved === null || isApproveAgain ? (
        <div className={classes.section}>
          <Grid container spacing={1}>
            <Grid
              item
              md={4}
              xs={12}
              xl={6}
              lg={6}
              style={{ textAlign: "right" }}
            >
              <Button
                variant="contained"
                className={classes.filledButton}
                onClick={handleApproveClick}
              >
                Approve
              </Button>
            </Grid>
            <Grid item md={4} xs={12} xl={6} lg={6}>
              <Button
                variant="outlined"
                classes={{
                  root: classes.buttonContainer,
                  label: classes.label,
                }}
                onClick={handleDisapproveClick}
              >
                Disapprove
              </Button>
            </Grid>
          </Grid>
        </div>
      ) : isApproved == true ? (
        <div className={classes.section}>
          <div className={classes.approvedBox}>Approved</div>
        </div>
      ) : isApproved == false ? (
        <div className={classes.section}>
          <div className={classes.dispprovedBox}>Disapproved</div>
          <div
            onClick={() => setIsApproveAgain(true)}
            className={classes.approveAgainButton}
          >
            Approve Again?
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TabPanel;
