import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Button, Theme, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
      fontFamily: 'Poppins',
      textTransform: 'none',
      letterSpacing: 0.75,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      display: 'flex',
      flexDirection: 'row',

      paddingLeft: 2,
      paddingRight: 2,
    },
  })
);

type SBButtonProps = {
  className?: string;
  text: string;
  icon?: boolean;
  onClick?: () => void;
  [x: string]: any;
};

const SBButton: React.FC<SBButtonProps> = ({ className, text, icon, onClick, ...rest }) => {
  const classes = useStyles();
  return (
    <Button className={clsx(classes.button, className)} variant={'contained'} onClick={onClick} {...rest}>
      {icon && <CheckCircleOutlineIcon fontSize="small" style={{ marginRight: 4 }}></CheckCircleOutlineIcon>}
      {text}
    </Button>
  );
};

export default SBButton;
