import { get, isString, isArray } from 'lodash';

export const getServerErrorMessages = (e: any): string[] => {
  if (!e) {
    return [''];
  }
  const response = e.response;
  if (response && response.status === 500) {
    return [response.statusText || 'Internal Server Error'];
  }
  const errorMessages = get(response, 'data.message');
  if (errorMessages) {
    if (isArray(errorMessages)) {
      return errorMessages.map((errorMessageItem: any) => {
        if (isString(errorMessageItem)) {
          return errorMessageItem;
        }
        return errorMessageItem.message;
      })
    }
    if (isString(errorMessages)) {
      return [errorMessages];
    }
  }
  return [];
}
