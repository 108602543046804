import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import clsx from "clsx";
import SBText from "../../components/base/SBText";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 10,
    },
    blockFont: {
      fontSize: "14px",
      fontFamily: "Poppins",
    },
    container: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "5px 0",
    },
    content: {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      //paddingLeft: theme.spacing(2),
      fontWeight: 500,
      fontSize: 22,
      flex: 1,
    },
  })
);

type StatusProps = {
  data: any;
};

const TableBlock: React.FC<StatusProps> = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root)}>
      {data?.groups?.map((group: any) =>
        group.status && group.status.status && group.name != "PreQuestions" ? (
          <div className={classes.container}>
            <span className={classes.content}>
              <SBText text={group.name} className={classes.blockFont} />
            </span>
            <div
              style={{
                height: 10,
                flex: 2,
                backgroundColor: "#e0e0de",
                borderRadius: 50,
              }}
            >
              <div
                style={{
                  height: 10,
                  width: "100%",
                  backgroundColor:
                    group.status &&
                    group.status.status &&
                    group.status.status == "PartiallyComplete"
                      ? "#ECCC97"
                      : group.status.status == "NotStarted"
                      ? "#D27F65"
                      : "#8BB19C",
                  borderRadius: "inherit",
                }}
              ></div>
            </div>
          </div>
        ) : null
      )}
    </div>
  );
};

export default TableBlock;
