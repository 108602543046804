import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Button, Drawer, Theme } from '@material-ui/core';
import _ from 'lodash';

import { getAppBlockStatus, getEvents } from '../../../actions';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducer';
import CloseIcon from '@material-ui/icons/Close';
import AppColor from '../../../theme/AppColor';
import { hasOneOfPermissions, PERMISSION } from '../../../util/rolePermissionsUtil';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import EventModal from '../EventModal';
import { getApplicationIdInString } from '../../../util';
import TableBlock from '../TableBlock';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerRoot: {
      width: 500,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      background: '#FAFAFA',
      padding: 10,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: 18,
      padding: '10px 0 10px 10px',
    },
    closeBtn: {
      minWidth: 40,
    },
    tabsParent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 55px)',
    },
    tabSubParents: {
      display: 'flex',
      alignItems: 'flex-end',
      paddingTop: 8,
      position: 'relative',
    },
    tabBasic: {
      height: 45,
      width: 143,
      backgroundColor: '#F9F9F9',
      borderRadius: '8px 8px 0px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      color: '#9D9D9D',
      border: '1px solid #E6E6E6',
      borderBottomColor: 'transparent',
      position: 'relative',
      fontSize: 16,
      letterSpacing: 0.5,
      fontWeight: 400,

      '&:hover': {
        color: '#7D7D7D',
        backgroundColor: '#F1F1F1',
      },
    },
    tabSelected: {
      height: 46,
      fontWeight: 500,
      backgroundColor: 'white',
      color: '#121212',
      bottom: -1,
      '&:hover': {
        color: '#121212',
        backgroundColor: 'white',
      },
    },
    tabBody: {
      backgroundColor: 'white',
      height: 'calc(100% - 50px)',
      padding: '8px 16px 0 16px',
      border: '1px solid #E6E6E6',
      borderRadius: '0 8px 8px 8px',
      overflow: 'auto',
    },
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type StatusAndEventsDrawerProps = PropsFromRedux & {
  applicationId: number | null;
  onClose: Function;
  blockStatuses: any;
  getAppBlockStatus: any;
  getEvents: any;
  events: any;
};

const infoTabs: string[] = ['events', 'statuses'];
const tabNames: any = {
  events: 'Recent Activity',
  statuses: 'Block Status',
};

const StatusAndEventsDrawer: React.FC<StatusAndEventsDrawerProps> = ({
  applicationId,
  onClose,
  getEvents,
  events,
  blockStatuses,
  getAppBlockStatus,
}) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState<string>('events');
  const userPermissions: PERMISSION[] = useUserPermissions();

  const hasPerm = (perm: PERMISSION[]) => {
    return hasOneOfPermissions(perm, userPermissions);
  };

  useEffect(() => {
    if (applicationId) {
      setActiveTab('events');
      getEvents(applicationId);
      getAppBlockStatus(applicationId);
    }
  }, [applicationId]);

  return (
    <Drawer anchor={'right'} open={applicationId != null} onClose={() => onClose()}>
      <div className={classes.drawerRoot}>
        <div className={classes.header}>
          {getApplicationIdInString(applicationId)}
          <Button onClick={() => onClose()} className={classes.closeBtn}>
            <CloseIcon />
          </Button>
        </div>

        <div className={classes.tabsParent}>
          <div className={classes.tabSubParents}>
            {infoTabs.map((tab: string) => (
              <div
                key={tab}
                className={`${classes.tabBasic} ${activeTab == tab ? classes.tabSelected : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tabNames[tab]}
              </div>
            ))}
          </div>

          <div className={classes.tabBody}>
            {activeTab == 'events' && <EventModal events={events} />}

            {activeTab == 'statuses' && <TableBlock data={blockStatuses} />}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  // @ts-ignore
  events: state.sbApplication.events,
   // @ts-ignore
  blockStatuses: state.sbApplication.blockStatuses,
});

const mapDispatch = {
  getAppBlockStatus,
  getEvents,
};

const connector = connect(mapState, mapDispatch);
export default connector(StatusAndEventsDrawer);
