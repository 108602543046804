import { FetchLPOParams, LPOModalAPIStatus } from "../types";

export const PAGE_SIZES: number[] = [5, 10, 20, 50];
export const DEFAULT_PAGE_SIZE: number = PAGE_SIZES[3];
export const FIRST_PAGE: number = 0;
export const DEFAULT_FETCH_PARAMS: FetchLPOParams = {
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
  search: "",
};

export const LPO_API_IDEAL_STATUS: LPOModalAPIStatus = {
  status: "ideal",
  errors: new Map(),
};
