import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Grid, Theme, Typography } from "@material-ui/core";

import { RootState } from "../../../reducer";

type PropsFromRedux = ConnectedProps<typeof connector>;
type LegendProps = PropsFromRedux & {
  legendName: string;
  legendColor: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    legendBox: {
      width: "17px",
      height: "17px",
    },
    legendName: {
      fontWeight: 400,
      fontSize: "16px",
      color: "rgba(18, 18, 18, 0.5)",
    },
  })
);

const Legend: React.FC<LegendProps> = ({ legendName, legendColor }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12} xl={2} lg={2}>
          <div
            className={classes.legendBox}
            style={{ backgroundColor: legendColor }}
          ></div>
        </Grid>
        <Grid item md={4} xs={12} xl={10} lg={10} style={{ textAlign: "left" }}>
          <Typography className={classes.legendName}> {legendName}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

const mapState = (state: RootState) => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(Legend);
