import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  Paper,
  TableHead,
  Tooltip,
  Chip,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router";
import SBText from "../../../components/base/SBText";
import { LABELS } from "../../../util/labels";
import { hasPermission, PERMISSION } from "../../../util/rolePermissionsUtil";
import { ENTITY_TYPE_NAMES } from "../../../util/constants";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  padding: {},
  paddingHeader: { backgroundColor: "pink" },
  paddingButtonParent: { backgroundColor: "pink" },
  paddingButton: {
    paddingLeft: 0,
    paddingRight: 0,
    // backgroundColor: "pink",
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 0,
    alignItems: "center",
    // backgroundColor: "pink",
    alignSelf: "center",
  },
  TableRow: {
    backgroundColor: "#efefef",
    fontWeight: 500,
  },
  TableBodyRow: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  HeaderPadding: {
    fontWeight: 500,
    fontSize: "13px",
  },
  bodyText: {
    fontSize: "13px",
  },
  tableParents: {
    display: "flex",
    flex: 1,
    // overflow: "scroll",
    height: "100%",
    overflow: "hidden",
  },

  mainLayout: {
    display: "flex",
    flex: 1,
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.117647) 2px 4px 6px 4px",
    padding: 16,
    // overflow: "scroll",
    height: "100%",
    overflow: "hidden",
  },
  tableRowContent: {
    "@media screen and ( min-height: 768px )": {
      height: 81,
      alignItems: "center",
    },
  },
  chip: {
    textTransform: "none",
    fontWeight: 400,
    letterSpacing: 0.75,
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#4c4c4c",
  },
  tooltip: {
    backgroundColor: "#4c4c4c",
  },
}));

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

type UserTableProps = {
  data: any;
  handlePageChange: any;
  userPermissions: PERMISSION[];
  newPage: number;
  setNewPage: (value: number) => void;
  onModelClick: (item: any) => void;
};

const UserTable: React.FC<UserTableProps> = ({
  data,
  handlePageChange,
  userPermissions,
  newPage,
  setNewPage,
  onModelClick,
  ...rest
}) => {
  const classes = useStyles();

  const handleClick = (item: any) => {
    onModelClick(item);
  };

  // const [page, setPage] = React.useState(newPage);
  const ref = useRef<number>();
  const handleScroll = (e: any) => {
    let element = e.target;
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
      element.clientHeight
    ) {
      ref.current = newPage;
      let pageNumber;
      if (data.length) {
        pageNumber = ref.current + 1;
      } else {
        pageNumber = ref.current;
      }
      handlePageChange(pageNumber);
      setNewPage(pageNumber);
    }
  };

  return (
    <div className={classes.mainLayout}>
      <div className={classes.tableParents}>
        <TableContainer onScroll={handleScroll} component={Paper}>
          <Table className={classes.table} stickyHeader>
            <TableHead className={classes.TableRow}>
              <TableRow>
                <TableCell align="left">
                  <SBText
                    text={LABELS.USER_NAME}
                    className={classes.HeaderPadding}
                  />
                </TableCell>
                <TableCell align="left">
                  <SBText
                    text={LABELS.ENTITY_TYPE}
                    className={classes.HeaderPadding}
                  />
                </TableCell>
                <TableCell align="left" className={classes.padding}>
                  <SBText text="Email" className={classes.HeaderPadding} />
                </TableCell>
                <TableCell align="left">
                  <SBText
                    text={LABELS.ROLES}
                    className={classes.HeaderPadding}
                  />
                </TableCell>
                <TableCell align="center" className={classes.padding}>
                  Active
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.padding}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((obj: any) => (
                <TableRow key={obj.id} className={classes.tableRowContent}>
                  <TableCell component="th">
                    <SBText text={obj.name} className={classes.bodyText} />
                  </TableCell>
                  <TableCell component="th">
                    <SBText
                      text={ENTITY_TYPE_NAMES[obj.entityType] || ""}
                      className={classes.bodyText}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.padding}>
                    <SBText text={obj?.email} className={classes.bodyText} />
                  </TableCell>
                  <TableCell align="left" className={classes.padding}>
                    <SBText
                      text={
                        obj?.roles
                          ? obj?.roles
                              ?.map((role: any) => role && role.name)
                              .toString()
                          : ""
                      }
                      className={classes.bodyText}
                    />
                  </TableCell>
                  <TableCell align="center" className={classes.padding}>
                    <Chip
                      className={classes.chip}
                      label={obj?.isActive ? "Active" : "Disabled"}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.padding}>
                    {hasPermission(PERMISSION.user_edit, userPermissions) && (
                      <BootstrapTooltip title="Details">
                        <div onClick={(event) => handleClick(obj)}>
                          <VisibilityIcon />
                        </div>
                      </BootstrapTooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default UserTable;
