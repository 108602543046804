import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionTypes,
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../actions";
import { API_URL } from "../../../util/apiUrls";

export const useFetchAgentDetails = () => {
  const dispatch = useDispatch();

  const fetchAgentDetails = useCallback(
    async (id) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(API_URL.AGENTS.BY_ID(id), dispatch);
        dispatch({
          type: ActionTypes.SET_SELECTED_AGENT_DETAILS,
          payload: result,
        });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    fetchAgentDetails,
  };
};
