import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
  TextField,
  Theme,
} from "@material-ui/core";
import { HighlightOffRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useCallback, useEffect, useState } from "react";
import SBButton from "../../../../components/base/SBButton";
import SBText from "../../../../components/base/SBText";
import AppColor from "../../../../theme/AppColor";
import { getServerErrorMessages } from "../../../../util/handleServerError";
import { AgentFormData, FormCloseOptions } from "./create-agent-form.types";
import { createAgent } from "./create-agent.service";
import { convertErrorMessages } from "./util";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "../../../../actions";
import { useDispatch } from "react-redux";
import { identity } from "lodash";
import { AgentStatus } from "../../types";
import { LABELS } from "../../../../util/labels";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "75%",
    display: "flex",
    flexDirection: "column",
    height: "80%",
  },
  root: {
    width: "100%",
    paddingLeft: 0,
    overflowY: "scroll",
  },
  header: {
    minHeight: 70,
    width: "100%",
    backgroundColor: AppColor.appBlue,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: "space-between",
  },
  titleTxt: {
    fontSize: 16,
    color: AppColor.appWhite,
    fontWeight: 600,
    [theme.breakpoints.up(1920)]: {
      fontSize: 18,
    },
    paddingLeft: 20,
  },
  formParent: {
    width: "100%",
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    flex: "1 1 0px",
    minHeight: "0px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    maxHeight: "100%",
    overflowY: "auto",
  },
  fieldsContainer: {
    overflowY: "auto",
    flex: "1 1 0px",
    minHeight: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  keyPersonFieldsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderTop: "1px solid #D9D9D9",
    marginTop: 15,
  },
  row: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  formField: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "0px 14px",
    alignItems: "center",
    width: "50%",
  },
  Label: {
    width: "100%",
    paddingTop: "15px",
    paddingBottom: "5px",
  },
  labelFont: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.primary.light,
  },
  textField: {
    width: "100%",
    borderRadius: "5px",
    "&$error": {
      color: "red",
    },
    error: {},
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
    marginBottom: "15px",
  },
  formLevelErrorMsgContainer: {
    marginBottom: 10,
  },
  requiredField: {
    "&::after": {
      content: "' *'",
      color: "red",
    },
  },
}));

interface AddAgentModalProps {
  className?: string;
  open: any;
  onClose: (options: FormCloseOptions) => void;
  companyName?: string;
  companyEmail?: string;
  companyPhone?: string;
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  userAgencyId?:string;
selectedAgentDetail?: any;

}

const AddAgentModal: React.FC<AddAgentModalProps> = ({
  open,
  onClose,
  companyName,
  companyEmail,
  companyPhone,
  firstName,
  lastName,
  jobTitle,
  userAgencyId,
 selectedAgentDetail,
}) => {
const defaultFormData = {
companyName: selectedAgentDetail
? selectedAgentDetail.companyName
: companyName,
companyEmail: selectedAgentDetail
? selectedAgentDetail.companyEmail
: companyEmail,
companyPhone: selectedAgentDetail
? selectedAgentDetail.companyPhone
: companyPhone,
keyPersonFirstName: selectedAgentDetail
? selectedAgentDetail.keyPersons[0].firstName
: firstName,
keyPersonLastName: selectedAgentDetail
? selectedAgentDetail.keyPersons[0].lastName
: lastName,
keyPersonJobTitle: selectedAgentDetail
? selectedAgentDetail.keyPersons[0].jobTitle
: "",
userAgencyId: userAgencyId?userAgencyId: "-1"
};
  const classes = useStyles();
  const [errorTextInEmail, setErrorTextInEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);

  const [errorTextInCompanyName, setErrorTextInCompanyName] = useState("");
  const [isCompanyNameInvalid, setIsCompanyNameInvalid] = useState(false);

  const [errorTextInCompanyPhone, setErrorTextInCompanyPhone] = useState("");
  const [isCompanyPhoneInvalid, setIsCompanyPhoneInvalid] = useState(false);

  const [isFirstNameInvalid, setIsFirstNameInvalid] = useState(false);
  const [errorTextInFirstName, setErrorTextInFirstName] = useState("");

  const [isLastNameInvalid, setIsLastNameInvalid] = useState(false);
  const [errorTextInLastName, setErrorTextInLastName] = useState("");

  const [isJobTitleInvalid, setIsJobTitleInvalid] = useState(false);
  const [errorTextInJobTitle, setErrorTextInJobTitle] = useState("");

  const [formData, setFormData] = useState<any>(defaultFormData);
  const [formLevelErrorMessage, setFormLevelErrorMessage] = useState<string>(
    ""
  );
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData(defaultFormData);
    setFormLevelErrorMessage("");
  }, [open, setFormData]);

  useEffect(() => {
    if (selectedAgentDetail) {
      setFormData(defaultFormData);
      setFormDisabled(selectedAgentDetail.statuses[0].statusId !=
        AgentStatus.invited);
    }
  }, [selectedAgentDetail]);

  const handleChange = useCallback(
    ({ target }) => {
      const { name, type } = target;
      setFormData((formDataVal: AgentFormData) => {
        let value = target.value;
        if (type === "checkbox") {
          value = target.checked;
        }
        return { ...formDataVal, [name]: value };
      });
    },
    [setFormData]
  );

  const handlePhoneChange = useCallback(
    (val: any) => {
      setFormData((formDataVal: AgentFormData) => ({
        ...formDataVal,
        companyPhone: val,
      }));
    },  
    [setFormData]
  );

  const handleInviteBtnClick = useCallback(async () => {
    if (formDisabled) {
      onClose({ sucessful: false });
      return;
    }
    setErrorTextInEmail("");
    setIsEmailInvalid(false);
    setErrorTextInCompanyName("");
    setIsCompanyNameInvalid(false);
    setErrorTextInCompanyPhone("");
    setIsCompanyPhoneInvalid(false);
    setErrorTextInFirstName("");
    setIsFirstNameInvalid(false);
    setErrorTextInLastName("");
    setIsLastNameInvalid(false);
    setErrorTextInJobTitle("");
    setIsJobTitleInvalid(false);

    try {
      if (selectedAgentDetail) {
        formData.id = selectedAgentDetail.id;
        formData.keyPersonId = selectedAgentDetail.keyPersons[0].id;
      }
      dispatch({ type: ON_LOADING_START });
      await createAgent(formData, selectedAgentDetail);
      onClose({ sucessful: true });
    } catch (e) {
      const errorMessages = getServerErrorMessages(e);
      setFormLevelErrorMessage(
        convertErrorMessages(errorMessages).slice(0, 5).join(", ")
      );
      setError(errorMessages);
    } finally {
      dispatch({ type: ON_LOADING_FINISHED });
    }
  }, [formData, onClose, setFormLevelErrorMessage, dispatch]);

  const setError = (errorMessages: any) => {
    console.log(errorMessages);
    errorMessages.forEach((element: any) => {
      let errorElement = element.substr(0, element.indexOf(" "));
      let errorMessage = element.substr(element.indexOf(" "), element.length);
      switch (errorElement) {
        case "companyEmail":
          setErrorTextInEmail("Company email " + errorMessage);
          setIsEmailInvalid(true);
          break;
        case "companyName":
          setErrorTextInCompanyName("Company name " + errorMessage);
          setIsCompanyNameInvalid(true);
          break;
        case "companyPhone":
          setErrorTextInCompanyPhone("Company phone " + errorMessage);
          setIsCompanyPhoneInvalid(true);
          break;
        case "firstKeyPerson.firstName":
          setErrorTextInFirstName("First name " + errorMessage);
          setIsFirstNameInvalid(true);
          break;
        case "firstKeyPerson.lastName":
          setErrorTextInLastName("Last name " + errorMessage);
          setIsLastNameInvalid(true);
          break;
        case "firstKeyPerson.jobTitle":
          setErrorTextInJobTitle("Job title " + errorMessage);
          setIsJobTitleInvalid(true);
          break;
        default:
          console.log("default");
      }
    });
  };

  const requiredFieldLabelClassName = clsx(
    classes.requiredField,
    classes.labelFont
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <SBText
              text={
                selectedAgentDetail
                  ? !formDisabled
                    ? LABELS.UPDATE_AGENT
                    : LABELS.VIEW_AGENT
                  : LABELS.CREATE_AGENT
              }
              className={classes.titleTxt}
            />

            <IconButton
              aria-label="close"
              onClick={() => onClose({ sucessful: false })}
            >
              <HighlightOffRounded style={{ color: AppColor.appWhite }} />
            </IconButton>
          </div>

          <div className={classes.formParent}>
            <form autoComplete="off" className={classes.form}>
              <div className={classes.fieldsContainer}>
                <div className={classes.formLevelErrorMsgContainer}>
                  {formLevelErrorMessage && (
                    <div style={{ display: "none", alignItems: "center" }}>
                      <ErrorOutlineIcon
                        style={{
                          margin: "0px 10px",
                          color: "red",
                          fontSize: 28,
                        }}
                      />
                      <SBText
                        text={formLevelErrorMessage}
                        style={{
                          color: AppColor.appRed,
                          fontWeight: "600",
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className={classes.row}>
                  <div className={classes.formField} style={{ width: "100%" }}>
                    <div className={classes.Label}>
                      <SBText
                        className={requiredFieldLabelClassName}
                        text="Company Name"
                      />
                    </div>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      onChange={handleChange}
                      disabled={formDisabled}
                      value={formData.companyName}
                      name="companyName"
                      error={isCompanyNameInvalid}
                      helperText={errorTextInCompanyName}
                    />
                  </div>
                </div>

                <div className={classes.row}>
                  <div className={classes.formField}>
                    <div className={classes.Label}>
                      <SBText
                        className={requiredFieldLabelClassName}
                        text="Company Email"
                      />
                    </div>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      onChange={handleChange}
                      value={formData.companyEmail}
                      name="companyEmail"
                      disabled={formDisabled}
                      error={isEmailInvalid}
                      helperText={errorTextInEmail}
                    />
                  </div>
                  <div className={classes.formField}>
                    <div className={classes.Label}>
                      <SBText
                        className={requiredFieldLabelClassName}
                        text="Company Phone"
                      />
                    </div>
                    <MuiPhoneNumber
                      defaultCountry={"in"}
                      fullWidth
                      onChange={handlePhoneChange}
                      variant="outlined"
                      autoFormat={false}
                      countryCodeEditable={false}
                      disabled={formDisabled}
                      disableAreaCodes={true}
                      value={formData.companyPhone}
                      error={isCompanyPhoneInvalid}
                      helperText={errorTextInCompanyPhone}
                    />
                  </div>
                </div>

                <div className={classes.keyPersonFieldsContainer}>
                  <div className={classes.Label}>
                    <SBText
                      className={classes.labelFont}
                      style={{ paddingLeft: 14 }}
                      text="Key Staff"
                    />
                  </div>

                  <div className={classes.row}>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText
                          className={requiredFieldLabelClassName}
                          text="First Name"
                        />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.keyPersonFirstName}
                        name="keyPersonFirstName"
                        disabled={formDisabled}
                        error={isFirstNameInvalid}
                        helperText={errorTextInFirstName}
                      />
                    </div>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText
                          className={requiredFieldLabelClassName}
                          text="Last Name"
                        />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChange}
                        disabled={formDisabled}
                        value={formData.keyPersonLastName}
                        name="keyPersonLastName"
                        error={isLastNameInvalid}
                        helperText={errorTextInLastName}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText
                          className={requiredFieldLabelClassName}
                          text="Job Title"
                        />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChange}
                        value={formData.keyPersonJobTitle}
                        name="keyPersonJobTitle"
                        disabled={formDisabled}
                        error={isJobTitleInvalid}
                        helperText={errorTextInJobTitle}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.btnContainer}>
                <SBButton
                  icon={false}
                  text={
                    selectedAgentDetail
                      ? !formDisabled
                        ? LABELS.SAVE
                        : LABELS.CLOSE
                      : LABELS.INVITE
                  }
                  color="primary"
                  onClick={handleInviteBtnClick}
                />
              </div>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AddAgentModal;
