import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import SBButton from "../../../components/base/SBButton";
import SBTextField from "../../../components/base/SBTextField";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    textField: {
      height: 200,
    },
    textArea: {
      height: 140,
      maxLength: 25,
      textAlign: "start",
    },

    button: {
      width: 100,
    },
  })
);

type TARejectionDialog = {
  className?: string;
  open: boolean;
  onSubmit: (text: string) => void;
  onClose: () => void;
  message: string;
  header: string;
};
const TARejectionDialog: React.FC<TARejectionDialog> = ({
  open,
  message,
  header,
  // setOpen,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const [reason, setReason] = useState("");

  const handleSubmit = () => {
    // setOpen(false);
    if (reason && reason.trim()) {
      onSubmit(reason);
    }
  };

  const handleClose = () => {
    // setOpen(false);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <SBTextField
              value={reason}
              rows={5}
              className={classes.textArea}
              multiline={true}
              onDataChange={(text) => {
                if (reason && reason.length > 400) {
                  return;
                } else {
                  setReason(text);
                }
              }}
            ></SBTextField>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
        <SBButton icon={false} text={"Cancel"} onClick={handleClose} className={classes.button}/>
              <div style={{width:10}}/>
          <SBButton icon={false} text={"Submit"} onClick={handleSubmit} className={classes.button}/>
          <div style={{width:8}}/>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TARejectionDialog;
