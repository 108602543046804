import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducer";
import { ActionType } from "../reducer/types";
import {
  get,
  post,
  postWithHeader,
  autoVerificationPost,
  remove,
} from "./common";
import config from "../config";
import _ from "lodash";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";
import { ADD_NOTIFICATION_EVENT } from "./sbApplication";

export const SET_INITIAL_NOTIFICATION_LIST = "SET_INITIAL_NOTIFICATION_LIST";
export const SET_MARK_AS_READ = "SET_MARK_AS_READ";
export const SET_MARK_AS_CLOSE = "SET_MARK_AS_CLOSE";
export const SET_NOTIFICATION_TRAY_OPENED = "SET_NOTIFICATION_TRAY_OPENED";

export const getAllNotifications = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch, getState) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });
      const { applicationDetailLoading } = getState().application;
      const result = await get(
        `${config.BASE_URL}/notification/getAll`,
        null,
        dispatch
      );
      dispatch({
        type: SET_INITIAL_NOTIFICATION_LIST,
        payload: result,
      });

      // console.log("Notification Result :::", result);
      resolve(result);
      if (!applicationDetailLoading) {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    }
  );
};

export const markAsRead = (
  body: any
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });

      try {
        const result = await post(
          `${config.BASE_URL}/notification/markAsRead`,
          body,
          dispatch
        );
        dispatch({
          type: SET_MARK_AS_READ,
          payload: body,
        });
        console.log(
          "Notification Result @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@:::",
          result
        );
        resolve(result);
        dispatch({ type: ON_LOADING_FINISHED });
      } catch (e) {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    }
  );
};

export const markAsClose = (
  body: any
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });

      try {
        const result = await post(
          `${config.BASE_URL}/notification/markAsClose`,
          body,
          dispatch
        );
        dispatch({
          type: SET_MARK_AS_CLOSE,
          payload: body,
        });
        // console.log("Notification Result :::", result);
        resolve(result);
        dispatch({ type: ON_LOADING_FINISHED });
      } catch (e) {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    }
  );
};

export const setNotificationTrayOpened = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  dispatch({
    type: SET_NOTIFICATION_TRAY_OPENED,
  });
};
