import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Select,
  MenuItem,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import SBText from '../../../components/base/SBText';
import { leadTableStyles } from '../../../styles/leadtable';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
import { LABELS } from '../../../util/labels';
import { LeadStatusDetails, LeadTableItem } from '../types';
import { SBTooltip } from '../../../components/base/SBTooltip';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DoneIcon from '@material-ui/icons/Done';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useSelector } from 'react-redux';
import { leadStatusesDropdownsData } from '../store/selectors';
const useStyles = makeStyles((theme: Theme) => createStyles(leadTableStyles(theme)));
interface Props {
  leadList: LeadTableItem[];
  updateLeadStatusById: (lead: LeadTableItem, status: string, label: string) => void;
  onTlViewLead: (lead: LeadTableItem) => void;
  handleLeadStatusTimeline: (lead: LeadTableItem) => void;
}

const TlLeadsTable: React.FC<Props> = ({ leadList, updateLeadStatusById, onTlViewLead, handleLeadStatusTimeline }) => {
  const classes = useStyles();
  const capitalizedCellClassName = `${classes.bodyText}  ${classes.capitalize}`;
  const lead_Statuses = useSelector(leadStatusesDropdownsData);

  const handleChange = (e: any, lead: LeadTableItem) => {
    updateLeadStatusById(lead, e.target.value, LeadStatusDetails[e.target.value].label);
  };

  const handleViewClick = (lead: LeadTableItem) => {
    onTlViewLead(lead);
  };

  const handleLeadStatus = (lead: LeadTableItem) => {
    handleLeadStatusTimeline(lead);
  };

  return (
    <>
      <div className={classes.mainLayout}>
        <div className={classes.tableParents}>
          <TableContainer component={Paper}>
            <Table className={classes.table} stickyHeader>
              <TableHead className={classes.TableRow}>
                {
                  <TableRow>
                    <TableCell align="left">
                      <SBText text={LABELS.APPLICANT_NAME} className={classes.HeaderPadding} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={LABELS.CITY} className={classes.HeaderPadding} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={LABELS.COUNTRY} className={classes.HeaderPadding} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={LABELS.PREFERRED_COUNTRY} className={classes.HeaderPadding} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={LABELS.TRUSTED_ADVISOR} className={classes.HeaderPadding} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={LABELS.STATUS} className={classes.HeaderPadding} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={LABELS.ACTION} className={classes.HeaderPadding} />
                    </TableCell>
                  </TableRow>
                }
              </TableHead>
              <TableBody>
                {leadList.map((item: LeadTableItem) => {
                  return (
                    <TableRow
                      onClick={(e: any) => {}}
                      key={item.id}
                      style={{
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell component="th">
                        <SBText text={item.name} className={capitalizedCellClassName} />
                      </TableCell>
                      <TableCell align="left">
                        <SBText text={item.applicantCity} className={capitalizedCellClassName} />
                      </TableCell>
                      <TableCell align="left">
                        <SBText text={item.applicantCountry} className={capitalizedCellClassName} />
                      </TableCell>
                      <TableCell align="left">
                        <SBText text={item.preferredCountry} className={capitalizedCellClassName} />
                      </TableCell>
                      <TableCell align="left">
                        <SBText text={item.createdByUser.name} className={capitalizedCellClassName} />
                      </TableCell>
                      <TableCell align="left">
                       
                        <Select
                          //   className="MuiOutlinedInput-notchedOutline"
                          disableUnderline
                          style={{ minWidth: 250, padding: '15px 0px 15px 0px' }}
                          labelId="label"
                          id="select"
                          value={item.status}
                          onChange={(e) => handleChange(e, item)}
                          IconComponent={KeyboardArrowDownIcon}
                          native={false}
                          renderValue={(selected: any) => {
                            if (selected.length === 0) {
                              return <em>Placeholder</em>;
                            }
                            // const labelnValue = lead_Statuses.find((x: any) => x.status === selected);
                            // This will return a comma-separated list of the values.
                            return (
                              <SBText text={LeadStatusDetails[selected].label} style={{ color: '#0d67c9', fontWeight: 500, fontSize: 13 }} />
                            );
                          }}
                        >
                          {lead_Statuses.map((i: any) => {
                            return (
                              <MenuItem
                                style={{ padding: 10, justifyContent: 'space-between' }}
                                key={i.status}
                                value={i.status}
                              >
                                <SBText
                                  text={i.label}
                                  style={{
                                    color: i.status === item.status ? '#0d67c9' : '#7a7575',
                                    fontWeight: 500,
                                    fontSize: 13,
                                  }}
                                />
                                {i.status === item.status && (
                                  <ListItemIcon>
                                    <DoneIcon style={{ color: '#0d67c9' }} />
                                  </ListItemIcon>
                                )}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </TableCell>

                      <TableCell align="left" className={classes.padding}>
                        <div className={classes.rowActions}>
                          <div className={classes.inlineAction} onClick={(event) => handleLeadStatus(item)}>
                            <InfoIcon />
                          </div>
                          <SBTooltip title="Details">
                            <div className={classes.inlineAction} onClick={(event) => handleViewClick(item)}>
                              <VisibilityIcon />
                            </div>
                          </SBTooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default TlLeadsTable;
