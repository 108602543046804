import { colors, MenuItem, Select } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { HighlightOffRounded } from '@material-ui/icons';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import SBButton from '../../components/base/SBButton';
import SBDropdown from '../../components/base/SBDropdown';
import SBMultiSelectDropdown from '../../components/base/SBMultiSelectDropdown';
import SBMultiSelectWithGroup from '../../components/base/SBMultiSelectWithGroup';
import SBText from '../../components/base/SBText';
import SBTextField from '../../components/base/SBTextField';
import AppColor from '../../theme/AppColor';
import { ACCOUNT_ACTIVE_LIST, EMAIL_REGEX, ENTITY_TYPE, ENTITY_TYPE_LIST } from '../../util/constants';
import { LABELS } from '../../util/labels';
import { hasOneOfRoles, hasPermission, PERMISSION, USER_ROLE } from '../../util/rolePermissionsUtil';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1920)]: {
      maxHeight: '60%',
    },
    maxHeight: '80%',
  },
  root: {
    width: '100%',
    paddingLeft: 0,
    overflowY: 'scroll',
    // backgroundColor: theme.palette.background.paper,
  },
  ul: {
    padding: 0,
  },
  companyText: {
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  agentText: {
    fontSize: 11,
    fontWeight: 500,
    color: AppColor.appLightGrey,
    [theme.breakpoints.up(1920)]: {
      fontSize: 12,
    },
  },
  listParent: {
    minHeight: 70,
    width: '100%',
    backgroundColor: AppColor.appBlue,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
  },
  formContainer: {
    padding: 16,
    overflowY: 'auto',
    height: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: AppColor.appLightGrey,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.blueGrey[50],
    },
  },
  selectAgentTxt: {
    fontSize: 16,
    color: AppColor.appWhite,
    fontWeight: 600,
    [theme.breakpoints.up(1920)]: {
      fontSize: 18,
    },
  },
  searchInputTxt: {
    fontSize: 12,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  inputValue: {
    fontSize: '12px !important',
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px !important',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px !important',
    },
  },
  newAppBt: {
    width: 130,
    height: 50,
    borderRadius: 30,
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.up(1920)]: {
      width: 130,
      fontSize: 14,
    },
  },
  closeBtn: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #cac8c8',
    marginLeft: 15,
  },
  label: {
    textAlign: 'left',
    color: '#000000',
    marginBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px',
    },
    fontSize: '12px',
  },
}));

type UserModalProps = {
  className?: string;
  open: any;
  onClose: () => void;
  data: any;
  onSave: (obj: any) => void;
  loggedInUser: any;
  userPermissions: PERMISSION[];
  agencies: any[];
  providers: any[];
  roles: any[];
};

const SBTextFieldClasses = makeStyles((theme) => ({
  label: {
    textAlign: 'left',
    color: '#000000',
    marginBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px',
    },
    fontSize: '12px',
  },
}));
const SBMultiSelectClasses = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  chip: {
    margin: 3,
    fontSize: 12,
    padding: 1,
    maxWidth: '100%',
  },
  label: {
    textAlign: 'left',
    color: '#000000',
    marginBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px',
    },
    fontSize: '12px',
  },
}));

const UserModal: React.FC<UserModalProps> = ({
  children,
  onClose,
  className,
  open,
  data,
  onSave,
  agencies,
  providers,
  loggedInUser,
  userPermissions,
  roles,
  ...rest
}) => {
  const classes = useStyles();

  const [item, setItem] = useState<any>({});
  const [errors, setErrors] = useState<Map<string, string>>(new Map());
  const [createGlobalUser, setCreateGlobalUser] = useState<boolean>(true);
  const [showProvidersForSB, setShowProvidersForSB] = useState<boolean>(false);

  useEffect(() => {
    let prefilledData = setCreateUserMode();
    if (data) {
      const { id, firstName, lastName, contact, email, entity, role, roleIds, roles, providerMapping, isActive } = data;
      let { entityType, companyId, providers } = data;
      // console.log("providers!!!!!", providers);
      if (!entityType) {
        entityType = providers.length > 0 ? ENTITY_TYPE.PROVIDER : companyId ? ENTITY_TYPE.AGENCY : '';
      }

      const newRoles = roles
        ? roles
            .filter((role: any) => (role ? true : false))
            .map((role: any) => {
              return {
                id: role.id,
                value: role.name,
                key: role.key,
              };
            })
        : [];
      setItem({
        id,
        firstName,
        lastName,
        contact,
        email,
        entity,
        role,
        roleIds,
        entityType,
        companyId,
        provider: providers,
        roles: newRoles,
        providerMapping,
        isActive,
      });
    } else {
      setItem(prefilledData);
    }
    resetError();
  }, [data]);

  useEffect(() => {
    const userRoleIds = item.roles ? item.roles.map((r: any) => r.id) : [];

    const roleIdsToMatch = roles
      ? roles
          .filter(
            (role: any) => role.key === USER_ROLE.SB_PROCESSING_LEAD || role.key === USER_ROLE.SB_PROCESSING_OFFICER
          )
          .map((r: any) => r.id)
      : [];

    setShowProvidersForSB(hasOneOfRoles(roleIdsToMatch, userRoleIds));
  }, [item]);

  const resetError = () => {
    setErrors(new Map());
  };

  const setCreateUserMode = () => {
    let prefilledData: any = {};
    if (hasPermission(PERMISSION.user_view_org, userPermissions)) {
      if (loggedInUser.provider) {
        prefilledData = {
          entityType: ENTITY_TYPE.PROVIDER,
          provider: loggedInUser.provider,
        };
        setCreateGlobalUser(false);
      } else if (loggedInUser.companyId) {
        prefilledData = {
          entityType: ENTITY_TYPE.AGENCY,
          companyId: loggedInUser.companyId,
        };
        setCreateGlobalUser(false);
      }
    }
    return prefilledData;
  };

  const getAgencies = (): any[] => {
    return agencies.map((agency: any) => {
      return { id: agency.id, value: agency.companyName || '-' };
    });
  };

  const getProviders = (): any[] => {
    return providers.map((provider: any) => {
      return {
        id: provider.id,
        value: provider.fullName || '-',
        countryName: provider.country,
        countryId: provider.countryId,
      };
    });
  };

  const getRoles = (): any[] => {
    const entityType = item?.entityType;
    const isAgencyOwner = hasPermission(PERMISSION.user_view_org, userPermissions) && loggedInUser.companyId;

    if (!entityType) {
      return [];
    } else {
      return roles
        .filter((role: any) => {
          let isVisible = role && role.entityType == entityType;
          if (isAgencyOwner && role.key === USER_ROLE.AGENCY_OWNER) {
            isVisible = false;
          }
          return isVisible;
        })
        .map((role: any) => {
          return { id: role.id, value: role.name };
        });
    }
  };
  const getSelectedRoleValues = () => {
    return item?.roles?.map((role: any) => role && role.value) || [];
  };

  const getSelectedProviders = () => {
    const providerStringArray = [];
    if (item.provider) {
      for (let i = 0; i < item.provider.length; i++) {
        const id = item.provider[i].id;
        const mainProvider = _.find(providers, (obj) => obj.id == id);
        if (mainProvider) {
          providerStringArray.push(mainProvider.fullName);
        }
      }
    }
    return providerStringArray;
  };

  const createEntity = () => {
    const { roles, id, entityType, ...rest } = item;
    //validations
    const errors = new Map();

    if (!roles || roles.length === 0) {
      errors.set('roles', 'Required');
    }

    if (!entityType || !entityType.trim()) {
      errors.set('entityType', 'Required');
    }

    if (entityType === ENTITY_TYPE.AGENCY && !item.companyId) {
      errors.set('companyId', 'Required');
    }

    if (entityType === ENTITY_TYPE.PROVIDER && (!item.providerMapping || item.providerMapping.lenght == 0)) {
      errors.set('provider', 'Required');
    }

    if (!item.firstName || !item.firstName.trim()) {
      errors.set('firstName', 'Required');
    }

    if (!item.lastName || !item.lastName.trim()) {
      errors.set('lastName', 'Required');
    }

    if (!item.contact || !item.contact.trim()) {
      errors.set('contact', 'Required');
    }

    if (!item.email || !item.email.trim()) {
      errors.set('email', 'Required');
    } else if (!EMAIL_REGEX.test(item.email)) {
      errors.set('email', 'Invalid Email');
    }

    if (errors.size > 0) {
      setErrors(errors);
      return;
    }

    onSave({
      ...data,
      ...rest,
      entityType,
      email: item.email.trim(),
      roleIds: roles?.map((role: any) => role.id),
      name: `${item.firstName} ${item.lastName}`,
      companyId: entityType === ENTITY_TYPE.STARTBLOCKS ? null : item.companyId,
      isActve: item.isActive,
      provider: entityType === ENTITY_TYPE.STARTBLOCKS && !showProvidersForSB ? null : item.providerMapping,
    });
    setItem({});
  };

  const onCloseModel = () => {
    onClose();
  };

  let entityDropdown = (
    <SBDropdown
      placeholder={LABELS.SELECT_ENTITY}
      options={[]}
      labelText={LABELS.ENTITY}
      styles={SBMultiSelectClasses}
      value={item?.['entity']?.['id'] || ''}
      error={errors.get('entity')}
      helperText={errors.get('entity')}
    />
  );

  if (item.entityType === ENTITY_TYPE.AGENCY) {
    entityDropdown = (
      <SBDropdown
        placeholder={LABELS.SELECT_AGENCY}
        options={getAgencies()}
        labelText={LABELS.AGENCY}
        styles={SBMultiSelectClasses}
        onDataChange={(text: any) => {
          setItem({ ...item, companyId: text, provider: null });
        }}
        value={item?.companyId || ''}
        error={errors.get('companyId')}
        helperText={errors.get('companyId')}
      />
    );
  } else if (item.entityType === ENTITY_TYPE.PROVIDER) {
    entityDropdown = (
      <SBMultiSelectWithGroup
        options={getProviders()}
        value={item.providerMapping}
        onChange={(values) => {
          setItem({ ...item, providerMapping: values });
        }}
      />
    );
  } else {
    entityDropdown = <></>;
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.listParent}>
            <SBText text={item.id ? LABELS.UPDATE_USER : LABELS.CREATE_USER} className={classes.selectAgentTxt} />

            <HighlightOffRounded onClick={() => onClose()} style={{ marginLeft: 10, color: AppColor.appWhite }} />
          </div>
          <div className={classes.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SBTextField
                  placeholder="Enter first name"
                  name="firstName"
                  onDataChange={(text) => {
                    setItem({ ...item, firstName: text });
                  }}
                  value={item?.['firstName'] || ''}
                  labelText="First Name"
                  className={classes.inputValue}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('firstName')}
                  helperText={errors.get('firstName')}
                />
              </Grid>
              <Grid item xs={6}>
                <SBTextField
                  placeholder="Enter last name"
                  name="lastName"
                  onDataChange={(text) => {
                    setItem({ ...item, lastName: text });
                  }}
                  value={item?.['lastName'] || ''}
                  labelText="Last Name"
                  className={classes.inputValue}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('lastName')}
                  helperText={errors.get('lastName')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter email"
                  disabled={item.id ? true : false}
                  name="email"
                  onDataChange={(text) => {
                    setItem({ ...item, email: text });
                  }}
                  value={item?.['email'] || ''}
                  labelText="Email"
                  className={classes.inputValue}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('email')}
                  helperText={errors.get('email')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter contact"
                  name="contact"
                  questionObj={{
                    validation: 'Phone',
                  }}
                  onDataChange={(text) => {
                    setItem({ ...item, contact: text });
                  }}
                  value={item?.['contact'] || ''}
                  labelText="Contact"
                  className={classes.inputValue}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('contact')}
                  helperText={errors.get('contact')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBDropdown
                  placeholder={LABELS.ACCOUNT_ACTIVE_PLACEHOLDER}
                  options={ACCOUNT_ACTIVE_LIST}
                  labelText={LABELS.ACCOUNT_ACTIVE_PLACEHOLDER}
                  styles={SBMultiSelectClasses}
                  onDataChange={(value: string) => {
                    setItem({ ...item, isActive: value === 'active' });
                  }}
                  value={item.isActive ? 'active' : 'disabled'}
                  error={errors.get('entityType')}
                  helperText={errors.get('entityType')}
                />
              </Grid>

              {createGlobalUser && (
                <Grid item xs={12}>
                  <SBDropdown
                    placeholder={LABELS.SELECT_ENTITY_TYPE}
                    options={ENTITY_TYPE_LIST}
                    labelText={LABELS.ENTITY_TYPE}
                    styles={SBMultiSelectClasses}
                    onDataChange={(value: any) => {
                      setItem({ ...item, entityType: value, roles: [] });
                    }}
                    value={item?.entityType || ''}
                    error={errors.get('entityType')}
                    helperText={errors.get('entityType')}
                  />
                </Grid>
              )}
              {createGlobalUser && item.entityType !== ENTITY_TYPE.STARTBLOCKS && (
                <Grid item xs={12}>
                  {entityDropdown}
                </Grid>
              )}

              <Grid item xs={12}>
                <SBMultiSelectDropdown
                  placeholder={LABELS.SELECT_ROLES}
                  options={getRoles()}
                  labelText={LABELS.SELECT_ROLES}
                  userStyles={SBMultiSelectClasses}
                  onDataChange={(values: any) => {
                    setItem({ ...item, roles: values });
                  }}
                  value={getSelectedRoleValues()}
                  error={errors.get('roles')}
                  helperText={errors.get('roles')}
                />
              </Grid>

              {createGlobalUser && item.entityType === ENTITY_TYPE.STARTBLOCKS && showProvidersForSB && (
                <Grid item xs={12}>
                  <SBMultiSelectWithGroup
                    options={getProviders()}
                    value={item.providerMapping}
                    onChange={(value) => {
                      // console.log("User Modal", value);
                      setItem({ ...item, providerMapping: value });
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} style={{ display: 'flex' }}>
                <SBButton icon={false} text="Save" className={classes.newAppBt} onClick={createEntity} />
                <SBButton
                  icon={false}
                  text="Cancel"
                  className={clsx(classes.newAppBt, classes.closeBtn)}
                  onClick={onCloseModel}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default UserModal;
