import config from "../config";

export const MODULE_URL = {
  APPLICATION: `${config.BASE_URL}/application`,
  USER: `${config.BASE_URL}/user`,
  AGENT: `${config.BASE_URL}/agent`,
  PROVIDER: `${config.BASE_URL}/country/provider`,
  LPO: `${config.BASE_URL}/lpo`,
  LEADS: `${config.BASE_URL}/lpo/leads`,
  REPORTS: `${config.BASE_URL}/reports`,
  TLVIEWLEADS: `${config.BASE_URL}/lpo/leads/_search`,
  EXPORT_DOWNLOAD_LEADS: `${config.BASE_URL}/lpo/leads/_export`,
  LEAD_STATUSES: `${config.BASE_URL}/lpo/lead-statuses`,
  ALL_LEAD_STATUSES: `${config.BASE_URL}/lpo/all-lead-statuses`,
  LEAD_STATUSE_TIMELINE: `${config.BASE_URL}/lpo/leads-status-history`,

};

export const API_URL = {
  APPLICATION: {
    BLOCK_STATUS: (id: number) => `${MODULE_URL.APPLICATION}/application-block-status/${id}`
  },
  USER: {
    BASE: `${MODULE_URL.USER}`,
    CREATE: `${MODULE_URL.USER}/createUser`,
    LIST: `${MODULE_URL.USER}/userDetail/all`,
    BY_ID: (id: number) => `${MODULE_URL.USER}/${id}`,
    VALIDATE: `${MODULE_URL.USER}/validateUser`,
    CHANGE_PASSWORD: `${MODULE_URL.USER}/changePwdByUdid`,
    FORGOT_PASSWORD: `${MODULE_URL.USER}/forgetPassword`,
    FETCH_PROVIDERS: (id: number) => `${MODULE_URL.USER}/fetch-providers/${id}`,
    FETCH_SB_TEAM: (id: number) => `${MODULE_URL.USER}/fetch-sb-team/${id}`,
    GET_CHILD_AGENTS: (id: number) => `${MODULE_URL.USER}/child-agents/${id}`,
    GET_CHILD_COUNSELLOR: (id: number) =>
      `${MODULE_URL.USER}/childCounsellor/${id}`,
  },
  ENTITY: {
    BASE: `${MODULE_URL.USER}/entity`,
    LIST: `${MODULE_URL.USER}/entity/all`,
    BY_ID: (id: number) => `${MODULE_URL.USER}/entity/${id}`,
  },
  PROVIDER: {
    LIST: `${MODULE_URL.PROVIDER}/all`,
  },
  LPO: {
    LIST: `${MODULE_URL.LPO}`,
    CREATE: `${MODULE_URL.LPO}`,
    BY_ID: (id: number) => `${MODULE_URL.LPO}/${id}`,
    DASHBOARD: `${MODULE_URL.LPO}/lead/status`,
    ONBOARD: `${MODULE_URL.LPO}/onboard`,
    REJECT: (id: number) => `${MODULE_URL.LPO}/reject/${id}`,
    INVITE: (id: number) => `${MODULE_URL.LPO}/invite/${id}`,
    KYCDETAIL: (id: number) => `${MODULE_URL.LPO}/invite/${id}`,
    DOWNLOAD_FILE: (fileName: number, type:string) => `${MODULE_URL.LPO}/taDoc/${fileName}/${type}`,
    DOWNLOAD_CONTRACT: (id: number) => `${config.BASE_URL}/ta/download-contract-ta-signed/${id}`,
  },
  LEADS: {
    LIST: `${MODULE_URL.LEADS}`,
    CREATE: `${MODULE_URL.LEADS}`,
    SUBMIT_TO_SB: `${MODULE_URL.LEADS}/submit-lead-to-startsblock`,
    BY_ID: (id: number) => `${MODULE_URL.LEADS}/${id}`,
    DROPDOWN_DATA: `${MODULE_URL.LEADS}/popup-dropdown-data`,
    TLVIEWLIST: `${MODULE_URL.TLVIEWLEADS}`,
    LEAD_STATUSES:`${MODULE_URL.LEAD_STATUSES}`,
    ALL_LEAD_STATUSES:`${MODULE_URL.ALL_LEAD_STATUSES}`,
    BY_ID_STATUSE_TIMELINE:(id:number)=>`${MODULE_URL.LEAD_STATUSE_TIMELINE}/${id}`,
    UPDATE_LEAD_STATUS:(id:number)=>`${MODULE_URL.LEADS}/${id}/status`,
    DOWNLOAD_LEADS: `${MODULE_URL.EXPORT_DOWNLOAD_LEADS}`,
    ALL_COUNTRYS:`${config.BASE_URL}`
  },
  AGENTS: {
    BY_ID: (id: number) => `${MODULE_URL.AGENT}/details/${id}`,
    STATUSES: `${MODULE_URL.AGENT}/statuses`,
    RESEND_INVITE: (id: number) => `${MODULE_URL.AGENT}/resend-invite/${id}`,
  },
  REPORTS: {
    FILTERS: (field: string, value: string) =>
      `${MODULE_URL.REPORTS}/filters/${field || "-1"}/${value || "-1"}`,
    GENERATE_REPORT: (type: string) => `${MODULE_URL.REPORTS}/${type}`,
    HISTORY: (type: string) => `${MODULE_URL.REPORTS}/history/${type}`,
    DOWNLOAD: (id: number) => `${MODULE_URL.REPORTS}/download/${id}`,
  },
};
