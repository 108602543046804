/* eslint-disable react/no-multi-comp */
import { Theme } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import "react-portal-hint/dist/default.css";
import { QuestionType } from "../../../actions/util/QuestionType";
import SBText from "../../../components/base/SBText";
import Stepper from '@material-ui/core/Stepper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    cardRoot: {
      minWidth: "45vw",
      marginBottom: 30,
      marginTop: 30,
    },
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    filterButton: {
      display: "flex",
    },
    filterIcon: {
      marginRight: theme.spacing(1),
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    cardParent: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#ffffff",
    },
    cardAction: {
      marginBottom: 0,
    },
    componentParent: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    previousBt: {
      height: 20,
      width: 20,
      color: "white",
      filter: "invert(100%)",
      marginRight: theme.spacing(5),
    },
    nextBt: {
      height: 20,
      width: 20,
      color: "white",
      filter: "invert(100%)",
      marginLeft: theme.spacing(5),
    },
    radioParent: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#ededed",
      },
    },
    cardContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      "&:hover": {
        backgroundColor: "#ededed",
      },
    },
    text: {
      fontSize: 12,
      color: "#19201C",
      fontWeight: 600,
    },

    circleView: {
      height: 20,
      width: 20,
      minWidth: 20,
      maxWidth: 26,
      borderRadius: 12.5,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "#5C5F74",
      cursor: "pointer",
      transition: "width 0.5s, height 0.5s",
    },
    circleActive: {
      backgroundColor: "#5C5F74"
    },
    lastSegment: {
      height: 1,
      flex: 1,
      backgroundColor: "#5C5F74",
      display: "flex",
      marginBottom: 30
    },
  })
);

type EmailProgressStepperProps = {
  dataObject: any;
  currentIndex: number;
  onStepperClick: (index: number) => void;
};

const EmailProgressStepper: React.FC<EmailProgressStepperProps> = ({
  dataObject,
  currentIndex,
  onStepperClick
}) => {
  const classes = useStyles();
  let localIndex: number = currentIndex;
  const getStepperBackgroundColor = (item: any) => {
    if (currentIndex === item.id) {
        return "#5C5F74";
    } else {
        return "#FFFFFF";
    }
  };

  const HandleStepperChange = (i:number)=>{
    currentIndex = i + 1;
    onStepperClick(i + 1);
  }


  return (
    <div style={{ display: "flex", justifyContent: "center", width: "70%" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {dataObject.map((item: any, i: number) => {
          const itemClassName =
            i + 1 === currentIndex
              ? clsx(classes.circleView, classes.circleActive)
              : classes.circleView;

          return (
            <>
              <div
                onClick={() => HandleStepperChange(i)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: 'center',
                  rowGap: 10
                }}
              >
                <div>
                    <div
                    className={itemClassName}
                    style={{
                        backgroundColor: getStepperBackgroundColor(item),
                    }}
                    >
                    <SBText
                        text={String(i + 1)}
                        className={classes.text}
                    ></SBText>
                    </div>
                </div>
                <div>
                    <SBText text={item.question} className={classes.text} />
                </div>
              </div>
              {dataObject.length - 1 != i ? (
                <div className={classes.lastSegment} />
              ) : null}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default EmailProgressStepper;
