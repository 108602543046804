import React, { useState } from "react";
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducer';
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
  MISSING_DOCUMENT_EMAIL_SUCCESS,
} from "../../../actions";
import {
  Theme,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';
import SBText from "../../../components/base/SBText";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    paper: { width: "700px", minHeight: 700, maxWidth: 700 },
    headerColor: {
      color: "white",
      fontSize: "14px",
    },
    headerText: {
      backgroundColor: "#5C5F74",
      color: "white"
    },
    alignItemsAndJustifyContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#8BB19C4D'
    },
  })
);
type PropsFromRedux = ConnectedProps<typeof connector>;
type AlertSuccessDialogProps = PropsFromRedux & {
  success: any;
};

const AlertSuccessDialog: React.FC<AlertSuccessDialogProps> = ({
  success
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: MISSING_DOCUMENT_EMAIL_SUCCESS,
      payload: { isLoading: false, type: null }
    })
  };

  return (
    <div>
      <Dialog
        open={success.isLoading}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle className={classes.headerText}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <SBText className={classes.headerColor} text={'Send'} />
            <IconButton aria-label="close" style={{ color: 'white' }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.alignItemsAndJustifyContent}>
          <DialogContentText >
            <Grid style={{ textAlign: 'center' }}>
              <CheckCircleRounded style={{ color: "#8BB19C", fontSize: '3rem' }} />
            </Grid>
            <Grid style={{ textAlign: 'center' }}>
              <SBText text={`Notification sent to the agent and 
                  application status updated to 'More info required for agent'.`} />
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};
const mapState = (state: RootState) => ({
  success: state.sbApplication.alertSuccess
});

const mapDispatch = {
};
const connector = connect(mapState, mapDispatch);
export default connector(AlertSuccessDialog);
