import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: 100,
      width: "100%",
      backgroundColor: "white",
      marginTop: '-2px',
      justifyContent: "space-around",
      borderRadius: 10,
      paddingBottom: 18,
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 18,
      boxShadow: "rgba(0, 0, 0, 0.117647) -1px 2px 6px 4px",
    },

    timerParent: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: 'space-between',
    },

    timerSubParent: {
      display: "flex",
      flexDirection: "row",
      // flex: 1,
      backgroundColor: "white",
      // marginBottom: 10,
      // justifyContent: "space-between",
      
    },
    digitBox: {
      height: 34,
      [theme.breakpoints.up(1440)]: {
        height: 36,
      },
      flex: 1,
      minWidth: 20,
      backgroundColor: "#3E4059",
      border: "1px solid #D27F65",
      borderRadius: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    labelParent: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    label: { color: "black", fontWeight: 400 },
    digit: { 
      color: "white", 
      fontWeight: 500, 
      fontSize: 18 ,
      lineHeight:'22px',
      [theme.breakpoints.up(1440)]: {
        lineHeight:'32px',
        fontSize: 20 ,
      },
    },
  })
);

type TimerProps = { createdDate: any; initiated: boolean };

const Timer: React.FC<TimerProps> = ({ createdDate, initiated, ...rest }) => {
  const calculateTimeLeft = () => {
    const now = moment();
    // console.log("created date in timer:::",(new Date(createdDate)).toUTCString())
    // console.log("moment date", now)
    // console.log("now date in javascript::::",(new Date()).toUTCString())
    const expiration = moment(createdDate);
    const diff = now.diff(expiration);
    const diffDuration = moment.duration(diff);
    return {
      days: diffDuration.days(),
      hours: diffDuration.hours(),
      minutes: diffDuration.minutes(),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (initiated) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
      }, 60000);
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer);
    }
  }, [initiated]);

  let {
    days,
    hours,
    minutes,
  }: { days: any; hours: any; minutes: any } = timeLeft;

  const getDigits = (num: any) => {
    let number = num ? num.toString() : "0";

    if (number.length <= 1) {
      number = `0${number}`;
    }
    let digitArray = Array.from(number.toString()).map(Number);
    return digitArray;
  };

  let dayArray = initiated ? getDigits(days) : [0, 0];
  let hoursArray = initiated ? getDigits(hours) : [0, 0];
  let minutesArray = initiated ? getDigits(minutes) : [0, 0];

  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ opacity: initiated ? 1 : 0.5 }}>
      <div className={classes.timerParent}>
        <div className={classes.timerSubParent}>
          <div className={classes.digitBox}>
            <Typography variant={"h2"} className={classes.digit}>
              {dayArray[0]}
            </Typography>
          </div>
          <div style={{ width: 3 }} />
          <div className={classes.digitBox}>
            <Typography variant={"h2"} className={classes.digit}>
              {dayArray[1]}
            </Typography>
          </div>
        </div>
        <div className={classes.labelParent}>
          <Typography variant={"h6"} className={classes.label}>
            Days
          </Typography>
        </div>
      </div>
      <div style={{ width: 5 }} />
      <div className={classes.timerParent}>
        <div className={classes.timerSubParent}>
          <div className={classes.digitBox}>
            <Typography variant={"h2"} className={classes.digit}>
              {hoursArray[0]}
            </Typography>
          </div>
          <div style={{ width: 3 }} />
          <div className={classes.digitBox}>
            <Typography variant={"h2"} className={classes.digit}>
              {hoursArray[1]}
            </Typography>
          </div>
        </div>
        <div className={classes.labelParent}>
          <Typography variant={"h6"} className={classes.label}>
            Hours
          </Typography>
        </div>
      </div>

      <div style={{ width: 5 }} />
      <div className={classes.timerParent}>
        <div className={classes.timerSubParent}>
          <div className={classes.digitBox}>
            <Typography variant={"h2"} className={classes.digit}>
              {minutesArray[0]}
            </Typography>
          </div>
          <div style={{ width: 3 }} />
          <div className={classes.digitBox}>
            <Typography variant={"h2"} className={classes.digit}>
              {minutesArray[1]}
            </Typography>
          </div>
        </div>
        <div className={classes.labelParent}>
          <Typography variant={"h6"} className={classes.label}>
            Minutes
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Timer;
