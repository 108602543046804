import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionTypes,
  postWithHeader,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../actions";
import config from "../../../config";

export const usePostApproveDisapproveRefereeCheck = () => {
  const dispatch = useDispatch();

  const postRefereeCheckApproval = useCallback(
    async (agentID: number, payload: any) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await postWithHeader(
          `${config.BASE_URL}/agent/ref-check/${agentID}`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          dispatch
        );
        dispatch({
          type: ActionTypes.SET_REFREE_APPROVAL_DEATILS,
          payload: result,
        });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    postRefereeCheckApproval,
  };
};
