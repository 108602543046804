import React from 'react';
import { Chip, createStyles, makeStyles, Theme } from '@material-ui/core';
import { StatusViewItem } from './status-type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: any) => ({
      color: theme.palette.common.white,
      backgroundColor: props.color,
      fontFamily: "Poppins",
      textTransform: "none",
      fontWeight: 400,
      letterSpacing: 0.75,
    }),
  })
);


interface Props {
  item: StatusViewItem;
  className?: string;
}

const StatusChip: React.FC<Props> = ({ item, className }) => {
  const classes = useStyles({ color: item.color });
  const chipClassName = `${className} ${classes.root}`;
  return (
    <Chip
      label={item.label}
      className={chipClassName}
    />
  );
}

export default StatusChip;
