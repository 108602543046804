import { Checkbox, Link, Snackbar, Theme } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import { forgetPassword, login, resetLoginError, setForgotPasswordView, setLoginSuccessFully } from '../../actions';
import { REDIRECT_TO, REMEMBER_ME_USERNAME } from '../../actions/util/constant';
import SBButton from '../../components/base/SBButton';
import SBText from '../../components/base/SBText';
import SBTextField from '../../components/base/SBTextField';
import CommenMessageDialog from '../../components/CommenMessageDialog';
import Page from '../../components/Page';
import { RootState } from '../../reducer';
import AppColor from '../../theme/AppColor';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    formStyle: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    leftPanel: {
      width: '37%',
      backgroundColor: theme.palette.primary.dark,
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    rightPanel: {
      width: '63%',
      backgroundColor: theme.palette.primary.contrastText,
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    card: {
      width: '100%',
      height: '100%',
      overflow: 'visible',
      display: 'flex',
      position: 'relative',
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%',
        width: '50%',
      },
    },
    content: {
      position: 'relative',
    },
    loginBox: {
      padding: theme.spacing(3),
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        width: 400,
      },
      margin: '0 auto',
    },
    media: {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    overlay: {
      color: 'white',
      margin: 'auto',
    },
    loginForm: {},
    avatar: {
      margin: '0 auto',
      width: 200,
      height: 58,
      textAlign: 'center',
      borderRadius: 0,
    },
    centerText: {
      color: '#FFFFFF',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2,
      width: '70%',
      padding: 20,
      textAlign: 'center',
      lineHeight: 1.5,
    },
    bottomText: {
      color: '#FFFFFF',
      fontWeight: 500,
      position: 'absolute',
      bottom: 20,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2,
      width: '100%',
      textAlign: 'center',
      lineHeight: 1.5,
    },
    bottomLinkText: {
      color: '#FFFFFF',
      fontWeight: 500,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2,
      width: '100%',
      textAlign: 'center',
      lineHeight: 1.3,
    },
    link: {
      textDecoration: 'underline',
      padding: theme.spacing(0.5),
      color: '#FFFFFF',
    },
    divider: {
      margin: '0 auto',
      paddingTop: 10,
    },
    input: {
      marginTop: theme.spacing(3),
    },
    subParent: {
      width: '35%',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    imgParent: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      marginBottom: 10,
    },
    socialImage: {
      height: 16,
      marginRight: 10,
    },
    loginButton: {
      borderRadius: 30,
      fontSize: 14,
      height: 50,
      width: '100%',
      marginTop: theme.spacing(3),
    },
    header: {
      color: theme.palette.common.black,
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    signupText: {
      color: '#9EA0A5',
    },
    linkButton: {
      color: '#2463FF',
      fontSize: 14,
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    forgetPasswordBtParent: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 8,
    },
    socialSignInLabel: {
      color: '#9EA0A5',
    },
    signupParents: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
    },
    bottomLinks: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 14,
      color: AppColor.appBlue,
      marginTop: 14,
    },
    rememberMe: {
      marginLeft: -10,
      marginRight: -6,
      marginTop: -3,
    },
    forgotPassword: {
      textDecorationLine: 'underline',
      fontSize: 14,
    },
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type LoginIndexProps = PropsFromRedux & {};

const LoginPage: React.FC<LoginIndexProps> = ({
  setLoginSuccessFully,
  login,
  loginError,
  resetLoginError,
  forgetPassword,
  setForgotPasswordView,
  isForgotPasswordOn,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const [userDetails, setUserDetails] = React.useState({
    username: '',
    password: '',
  });

  const passwordFieldRef = useRef();
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const [displayErrorView, setDisplayErrorView] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [messageDialog, showMessageDialog] = React.useState(false);

  useEffect(() => {
    const rememberMeUserName = localStorage.getItem(REMEMBER_ME_USERNAME);
    setUserDetails({
      ...userDetails,
      username: rememberMeUserName || '',
    });
  }, []);

  const handleChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const loginUser = async () => {
    if (!userDetails.username) {
      alert('Please enter username');
      return;
    }
    if (!userDetails.password) {
      alert('Please enter password');
      return;
    }

    if (rememberMe) {
      localStorage.setItem(REMEMBER_ME_USERNAME, userDetails.username);
    }

    login(userDetails);
  };

  const handleClose = () => {
    setDisplayErrorView(false);
    resetLoginError();
  };

  const forgetUserPassword = async () => {
    if (!email) {
      alert('Please enter your registered email address');
      return;
    }

    if (!validateEmail()) {
      alert('Please enter valid email address');
      return;
    }

    const result: any = await forgetPassword(email);
    if (result.success) {
      showMessageDialog(true);
    } else {
      if (result.error.status == '404') {
        alert(`"${email}" user not exist, Please check your email id`);
      }
    }
  };

  useEffect(() => {
    if (loginError) {
      setDisplayErrorView(true);
    }
  }, [loginError]);

  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <Page className={classes.root} title="Login">
      <div className={classes.leftPanel} />
      <div className={classes.rightPanel}>
        <div className={classes.subParent}>
          <div className={classes.imgParent}>
            <img
              src="/images/logo_large.png"
              style={{
                height: 135,
              }}
            />
          </div>

          {!isForgotPasswordOn ? (
            <>
              <SBText text={'SIGN IN'} className={classes.header} />
              <SBTextField
                placeholder={'Email Address'}
                onChange={handleChange}
                value={userDetails.username}
                name="username"
                type="email"
              />
              <SBTextField
                placeholder={'Password'}
                type="password"
                name="password"
                inputRef={passwordFieldRef}
                className={classes.input}
                value={userDetails.password}
                onChange={handleChange}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    loginUser();
                    // write your functionality here
                  }
                }}
              />

              <div className={classes.bottomLinks}>
                <div>
                  <Checkbox
                    className={classes.rememberMe}
                    onChange={(e) => {
                      setRememberMe(e.target.checked);
                    }}
                    checked={rememberMe}
                  />
                  Remember me
                </div>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    setForgotPasswordView(true);
                  }}
                  className={classes.forgotPassword}
                >
                  Forgot Password
                </Link>
              </div>
              <SBButton text={'Log In'} onClick={loginUser} className={classes.loginButton} />
            </>
          ) : (
            <>
              <SBText text={'FORGOT PASSWORD'} className={classes.header} />
              <SBTextField
                placeholder={'Enter your email address'}
                onChange={(event: any) => setEmail(event.target.value)}
                value={email}
                name="email"
              />

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    setForgotPasswordView(false);
                  }}
                  style={{
                    alignSelf: 'flex-end',
                    fontSize: 14,
                    marginTop: 5,
                    color: AppColor.appBlue,
                    textDecorationLine: 'underline',
                  }}
                >
                  Back To Login
                </Link>
              </div>
              <SBButton
                text={'Reset Password'}
                icon={false}
                onClick={() => forgetUserPassword()}
                className={classes.loginButton}
              />
            </>
          )}

          <CommenMessageDialog
            open={messageDialog}
            onSubmit={() => {
              showMessageDialog(false);
              setForgotPasswordView(false);
              setEmail('');
            }}
            message={'Password reset information has been sent to your registered email.'}
            header={'Message'}
          />
        </div>
        <Snackbar
          open={displayErrorView}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {loginError}
          </Alert>
        </Snackbar>
      </div>
    </Page>
  );
};
const mapState = (state: RootState) => ({
  loginError: state.user.loginError,
  isForgotPasswordOn: state.user.isForgotPasswordOn,
});
const mapDispatch = {
  setLoginSuccessFully,
  login,
  resetLoginError,
  forgetPassword,
  setForgotPasswordView,
};

const connector = connect(mapState, mapDispatch);
export default connector(LoginPage);
