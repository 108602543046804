import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from '../../../actions';
import { toGetRequestURLParam } from '../../../util';
import { API_URL } from '../../../util/apiUrls';
import { FetchDataParams } from '../types';

export const useLoadReportHistory = () => {
  const dispatch = useDispatch();

  const loadReportHistory = useCallback(
    async (reportType: string | undefined, fetchDataParams: FetchDataParams) => {
      return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
        dispatch({ type: ON_LOADING_START });
        try {
          const url = `${API_URL.REPORTS.HISTORY(reportType || '')}${toGetRequestURLParam(fetchDataParams)}`;
          const result = await get(url, dispatch);
          resolve(result);
        } finally {
          dispatch({ type: ON_LOADING_FINISHED });
        }
      });
    },
    [dispatch]
  );

  return {
    loadReportHistory,
  };
};
