import {
  Card,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import Page from "../../../components/Page";
import gradients from "../../../util/gradients";
import redTileBG from "./../../Overview/assets/redMenu.png";
import blueTileBG from "./../../Overview/assets/blueMenu.png";
import greenTileBG from "./../../Overview/assets/greenMenu.png";
import yellowTileBG from "./../../Overview/assets/yellowMenu.png";
import SBText from "../../../components/base/SBText";
import Legend from "../../Overview/Legend/Legend";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import D3PieChart from "../../Overview/D3PieChart/D3PieChart";
import D3BarGraphChart from "./D3BarGraphChart/D3BarGraphChart";
import { LABELS } from "../../../util/labels";
import { lpoDashboardData } from "../store/selectors";
import { getLPODashboardData } from "../store/actions/lpo-dashboard-action";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(3),
      paddingRight: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(3),
      },
      display: "flex",
      flex: 1,
      height: "92vh",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.2)",
        borderRadius: 15,
      },
    },
    grid: {
      marginTop: theme.spacing(2),
    },
    margin: {
      margin: theme.spacing(1),
    },
    boxroot: {
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    details: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    label: {
      marginLeft: theme.spacing(1),
    },
    avatar: {
      backgroundImage: gradients.green,
      height: 48,
      width: 48,
    },
    locationChangeAvatar: {
      backgroundImage: gradients.red,
      height: 48,
      width: 48,
    },
    coeUpdateAvatar: {
      backgroundImage: gradients.orange,
      height: 48,
      width: 48,
    },
    studyPlanSuccessRoot: {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(3),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    studyPlanSuccessDetails: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
    },
    studyPlanSuccessAvatar: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      height: 48,
      width: 48,
    },
    pos: {
      marginBottom: 12,
    },
    cardContent: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      minHeight: 140,
      width: "100%",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#ededed",
      },
    },
    emptyText: {
      color: "#849EBA",
      padding: 20,
    },
    cardImage: {
      height: 70,
      width: 70,
    },
    cardCount: {
      color: "#D27F65",
      textAlign: "left",
      fontWeight: "bold",
      fontSize: 30,
    },
    cardLabel: {
      fontSize: 12,
      color: "#4A4A4A",
      fontWeight: "normal",
      marginTop: 1,
    },
    cardTextParent: {
      marginLeft: 16,
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
    },
    cardButton: { width: "100%" },
    cardParent: {
      borderRadius: 10,
      boxShadow: "rgba(0, 0, 0, 0.117647) -1px 2px 6px 4px",
    },
    labelTextStyle: {},
    cardHeaderText: {
      color: theme.palette.primary.dark,
      fontWeight: 700,
      fontSize: 30,
      marginTop: 300,
    },
    cardSubText: {
      color: theme.palette.primary.dark,
      fontWeight: 500,
      fontSize: 16,
      marginTop: 7,
    },
    image: {
      position: "relative",
    },
    imageSrc: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: "cover",
      height: 200,
      width: 250,
      backgroundColor: "pink",
      backgroundImage: redTileBG,
    },

    gridItem: {
      display: "flex",
      height: 140,
      width: 170,
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(0.6),
      },
    },

    gridDiv: {
      position: "relative",
      display: "flex",
      width: 250,
    },
    outComeHeader: {
      position: "absolute",
      marginTop: 16,
      marginLeft: 16,
      fontWeight: 700,
      color: "white",
      fontSize: 16,
      maxWidth: 120,
    },
    outComeCount: {
      position: "absolute",
      marginTop: 90,
      marginLeft: 16,
      fontWeight: 500,
      color: "white",
      fontSize: 30,
    },
    filterButton: {
      height: 42,
      width: 200,
      border: "1px solid #ced4da",
      backgroundColor: "#F9F9F9",
      display: "flex",
      alignItems: "center",
      fontSize: 10,
      justifyContent: "center",
      marginLeft: 10,
      borderRadius: "10px",
    },
    filterButtonText: { fontSize: 12, fontWeight: 600 },
    gridForCards: {
      paddingLeft: "10%",
    },
    cardContainer: {
      marginTop: "15px",
      width: "100%",
      height: "600px",
      maxHeight: "600px",
      overflowY: "auto",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    },
    dashboardChartContainer: {
      padding: 0,
      marginTop: "1%",
      height: "100%",
      overflowX: "hidden",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.2)",
        borderRadius: 15,
      },
    },
    chartHeader: {
      fontWeight: 500,
      fontSize: "16px",
      color: "rgba(18, 18, 18, 0.5)",
    },
    chartCardContainer: {
      marginTop: "30px",
    },
    barChartLegends: {
      margin: "auto",
      width: "60%",
      textAlign: "center",
      marginTop: "10%",
    },
    legendBox: {
      width: "17px",
      height: "17px",
      backgroundColor: "red",
    },
    legendName: {
      fontWeight: 400,
      fontSize: "16px",
      color: "rgba(18, 18, 18, 0.5)",
    },
    pieChartActionsContainer: {
      marginTop: "3%",
      marginLeft: "3%",
    },
    sectionScroller: {
      margin: "auto",
      padding: "5%",
      height: "470px",
      maxHeight: "470px",
      overflowY: "auto",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,.2)",
        borderRadius: 15,
      },
    },
  })
);

const LPODashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const dashboardData = useSelector(lpoDashboardData);
  const isMobileView = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    dispatch(getLPODashboardData());
  }, []);

  const getConvertpercentage = () => {
    let converted = 0;
    let completed = 0;
    if (dashboardData) {
      converted = _.get(dashboardData, "tilesData[2].count", 0);
      completed = _.get(dashboardData, "tilesData[1].count", 0);
    }
    return `${(converted / (completed || 1)).toFixed(2)}%`;
  };

  const getTotalDisplayCount = () => {
    let count = 0;
    if (
      dashboardData &&
      dashboardData.applicationCovertedStatusChartData &&
      dashboardData.applicationCovertedStatusChartData.length > 0
    ) {
      const openObj = _.find(
        dashboardData.applicationCovertedStatusChartData,
        (obj: any) => obj.name == "Open"
      );

      if (openObj) {
        count = count + openObj.count;
      }

      const closedObj = _.find(
        dashboardData.applicationCovertedStatusChartData,
        (obj: any) => obj.name == "Closed"
      );
      if (closedObj) {
        count = count + closedObj.count;
      }

      const rejectedObj = _.find(
        dashboardData.applicationCovertedStatusChartData,
        (obj: any) => obj.name == "Rejected"
      );
      if (rejectedObj) {
        count = count + rejectedObj.count;
      }

      const withdrawnObj = _.find(
        dashboardData.applicationCovertedStatusChartData,
        (obj: any) => obj.name == "Withdrawn"
      );
      if (withdrawnObj) {
        count = count + withdrawnObj.count;
      }

      const draftObj = _.find(
        dashboardData.applicationCovertedStatusChartData,
        (obj: any) => obj.name == "Draft"
      );
      if (withdrawnObj) {
        count = count + draftObj.count;
      }
    }

    return count;
  };

  return (
    <Page className={classes.root} title="Dashboard">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
          <div className={classes.gridDiv}>
            <SBText
              text={LABELS.TOTAL_LEADS}
              className={classes.outComeHeader}
            />
            <SBText
              text={
                dashboardData && dashboardData.tilesData
                  ? dashboardData.tilesData[0].count
                  : "-"
              }
              className={classes.outComeCount}
            />
            <img src={greenTileBG} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
          <div className={classes.gridDiv}>
            <SBText
              text={LABELS.APPLICATIONS_CONVERTED}
              className={classes.outComeHeader}
            />
            <SBText
              text={
                dashboardData && dashboardData.tilesData
                  ? dashboardData.tilesData[1].count
                  : "-"
              }
              className={classes.outComeCount}
            />
            <img src={blueTileBG} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
          <div className={classes.gridDiv}>
            <SBText
              text={LABELS.COMPLETED_APPLICATIONS}
              className={classes.outComeHeader}
            />
            <SBText
              text={
                dashboardData && dashboardData.tilesData
                  ? dashboardData.tilesData[2].count
                  : "-"
              }
              className={classes.outComeCount}
            />
            <img src={yellowTileBG} />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3} className={classes.gridItem}>
          <div className={classes.gridDiv}>
            <SBText
              text={LABELS.COMPLETION_PERCENTAGE}
              className={classes.outComeHeader}
            />
            <SBText
              text={getConvertpercentage()}
              className={classes.outComeCount}
            />
            <img src={redTileBG} />
          </div>
        </Grid>
      </Grid>
      {!isMobileView ? (
        <Grid container spacing={4}>
          <Grid item sm={12} md={6} className={classes.gridForCards}>
            <div className={classes.chartCardContainer}>
              <Typography className={classes.chartHeader}>
                {LABELS.BAR_CHART_TITLE}
              </Typography>
              {!dashboardData.leadApplicationConvertedChartData ||
              dashboardData.leadApplicationConvertedChartData.length == 0 ? (
                <Card className={classes.cardContainer}>
                  <div className={classes.emptyText}>
                    No data <br></br>
                    Data will be visible here when you add new leads.
                  </div>
                </Card>
              ) : (
                <Card className={classes.cardContainer}>
                  <div className={classes.barChartLegends}>
                    <Grid container spacing={1}>
                      <Grid item md={4} xs={12} xl={6} lg={6}>
                        <Legend
                          legendName={LABELS.LEADS}
                          legendColor={"#8BB19C"}
                        />
                      </Grid>
                      <Grid item md={4} xs={12} xl={6} lg={6}>
                        <Legend
                          legendName={LABELS.APPLICATIONS_CONVERTED}
                          legendColor={"#3D405B"}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div style={{ padding: "5%", marginTop: "6%" }}>
                    {dashboardData?.leadApplicationConvertedChartData &&
                      dashboardData?.leadApplicationConvertedChartData.length >
                        0 && (
                        <D3BarGraphChart
                          data={dashboardData.leadApplicationConvertedChartData}
                        />
                      )}
                  </div>
                </Card>
              )}
            </div>
          </Grid>
          <Grid item sm={12} md={6} className={classes.gridForCards}>
            <div className={classes.chartCardContainer}>
              <Typography className={classes.chartHeader}>
                {LABELS.PIE_CHART_TITLE}
              </Typography>
              {!dashboardData.leadApplicationConvertedChartData ||
              dashboardData.leadApplicationConvertedChartData.length == 0 ? (
                <Card className={classes.cardContainer}>
                  <div className={classes.emptyText}>
                    No data <br></br>
                    Data will be visible here when you add new leads.
                  </div>
                </Card>
              ) : (
                <Card className={classes.cardContainer}>
                  <div className={classes.pieChartActionsContainer}>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: 700,
                        color: "#121212",
                      }}
                    >
                      {LABELS.STATUS_MATRIX}
                    </div>
                    <div>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          marginRight: "10px",
                          color: " rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {LABELS.APPLICATION_COUNT}:
                      </span>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                          color: "#121212",
                        }}
                      >
                        {getTotalDisplayCount()}
                      </span>
                    </div>
                  </div>
                  <div style={{ marginLeft: "0%" }}>
                    {dashboardData?.applicationCovertedStatusChartData && (
                      <D3PieChart
                        data={dashboardData?.applicationCovertedStatusChartData}
                        showDraft={true}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      marginTop: "-20%",
                      width: "20%",
                      marginLeft: "3%",
                      marginBottom: "3%",
                    }}
                  >
                    <div>
                      <Legend
                        legendName={LABELS.OPEN}
                        legendColor={"#ECCC97"}
                      />
                    </div>
                    <Legend legendName={LABELS.DRAFT} legendColor={"#7B8997"} />
                    <Legend
                      legendName={LABELS.CLOSED}
                      legendColor={"#8BB19C"}
                    />
                    <Legend
                      legendName={LABELS.REJECTED}
                      legendColor={"#CF4E0B"}
                    />
                    <Legend
                      legendName={LABELS.WITHDRAWN}
                      legendColor={"#FF6632"}
                    />
                  </div>
                </Card>
              )}
            </div>
          </Grid>
        </Grid>
      ) : null}
    </Page>
  );
};

export default LPODashboard;
