import { Theme, Grid, Divider, Button, TextField, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import SBDate from '../../../components/base/SBDate';
import SBText from '../../../components/base/SBText';
import moment from 'moment';
import { ReportFilterMasterType, ReportFilterType, ReportFilterVisibilityType, ReportItem } from '../types';
import { formDialogStyle } from '../../../styles';
import {
  DEFAULT_FILTER_DATA,
  DEFAULT_REPORT_FILTER,
  getFilterVisibility,
  SERVER_PAYLOAD_DATE_FORMAT,
} from '../constants';
import { useLoadReportFilterMaster } from '../hooks/useLoadReportFilterMaster';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import { PERMISSION } from '../../../util/rolePermissionsUtil';

const useFormStyles = makeStyles((theme: Theme) => createStyles(formDialogStyle(theme)));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiDivider-root': {
        marginTop: 12,
        marginBottom: 16,
      },

      '& .MuiSelect-outlined': {
        paddingTop: 14.5,
      },
    },
    label: {
      textAlign: 'left',
      color: '#64667C',
      fontSize: 12,
    },
    groupLabel: {
      textAlign: 'left',
      fontWeight: 500,
      color: '#64667C',
    },
  })
);
interface Props {
  report: ReportItem | undefined;
  onFilterChange: (filterValue: ReportFilterType) => void;
}

const ReportFilter: React.FC<Props> = ({ report, onFilterChange }) => {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const userPermissions: PERMISSION[] = useUserPermissions();
  const { loadReportFilterMaster } = useLoadReportFilterMaster();

  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [filterVisibility, setFilterVisibility] = useState<ReportFilterVisibilityType>();
  const [filterMaster, setFilterMaster] = useState<ReportFilterMasterType>(DEFAULT_FILTER_DATA);
  const [currentFilter, setCurrentFilter] = useState<ReportFilterType>(DEFAULT_REPORT_FILTER);

  useEffect(() => {
    loadFilterMaster('');
    setFilterVisibility(getFilterVisibility(report, userPermissions));
  }, []);

  useEffect(() => {
    setStartDate(moment().startOf('month'));
    setEndDate(moment().endOf('month'));
    setFilterVisibility(getFilterVisibility(report, userPermissions));
  }, [report]);

  const handleChange = ({ target }: any) => {
    const { name, value } = target;
    updateFilterData(name, value);
  };

  const updateFilterData = (field: string, value: any) => {
    setCurrentFilter((filter: any) => {
      return { ...filter, [field]: value };
    });
  };

  useEffect(() => {
    if (currentFilter.destCountry) {
      setCurrentFilter((filter: any) => {
        return { ...filter, destCity: '', provider: '', intake: '' };
      });
      loadFilterMaster('destCountry');
    }
  }, [currentFilter.destCountry]);

  useEffect(() => {
    if (currentFilter.destCity) {
      setCurrentFilter((filter: any) => {
        return { ...filter, provider: '' };
      });
      loadFilterMaster('destCity');
    }
  }, [currentFilter.destCity]);

  useEffect(() => {
    if (currentFilter.srcCountry) {
      setCurrentFilter((filter: any) => {
        return { ...filter, srcCity: '' };
      });
      loadFilterMaster('srcCountry');
    }
  }, [currentFilter.srcCountry]);

  useEffect(() => {
    if (currentFilter.cph) {
      setCurrentFilter((filter: any) => {
        return { ...filter, agency: '', counsellor: '' };
      });
      loadFilterMaster('cph');
    }
  }, [currentFilter.cph]);

  useEffect(() => {
    if (currentFilter.agency) {
      setCurrentFilter((filter: any) => {
        return { ...filter, counsellor: '' };
      });
      loadFilterMaster('agency');
    }
  }, [currentFilter.agency]);

  useEffect(() => {
    onFilterChange({
      ...currentFilter,
      startDate: startDate.format(SERVER_PAYLOAD_DATE_FORMAT),
      endDate: endDate.format(SERVER_PAYLOAD_DATE_FORMAT),
    });
  }, [currentFilter, startDate, endDate]);

  const loadFilterMaster = async (filterField: string) => {
    const result = await loadReportFilterMaster(filterField, (currentFilter as any)[filterField]);
    if (!filterField) {
      setFilterMaster(result);
    } else {
      setFilterMaster((master: any) => {
        return { ...master, ...result };
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className={classes.groupLabel}>{'Origin'}</div>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SBText text={'Country'} className={classes.label} />
              <TextField
                select
                name="srcCountry"
                value={currentFilter.srcCountry}
                className={formClasses.textField}
                variant="outlined"
                onChange={handleChange}
              >
                {filterMaster?.srcCountries?.map((country) => (
                  <MenuItem key={country.id} value={country.value}>
                    {country.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item xs={6}>
              <SBText text={"City"} className={classes.label} />
              <TextField
                select
                name="srcCity"
                value={currentFilter.srcCity}
                className={formClasses.textField}
                variant="outlined"
                onChange={handleChange}
              >
                {filterMaster?.srcCities?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Divider />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className={classes.groupLabel}>{'Destination'}</div>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SBText text={'Country'} className={classes.label} />
              <TextField
                select
                name="destCountry"
                value={currentFilter.destCountry}
                className={formClasses.textField}
                variant="outlined"
                onChange={handleChange}
              >
                {filterMaster?.destCountries?.map((country) => (
                  <MenuItem key={country.id} value={country.value}>
                    {country.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <SBText text={'City'} className={classes.label} />
              <TextField
                select
                name="destCity"
                value={currentFilter.destCity}
                className={formClasses.textField}
                variant="outlined"
                onChange={handleChange}
              >
                {filterMaster?.destCities?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <SBText text={'Institute'} className={classes.label} />
              <TextField
                select
                name="provider"
                value={currentFilter.provider}
                className={formClasses.textField}
                variant="outlined"
                onChange={handleChange}
              >
                {filterMaster?.providers?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <SBText text={'Intake'} className={classes.label} />
              <TextField
                select
                name="intake"
                value={currentFilter.intake}
                className={formClasses.textField}
                variant="outlined"
                onChange={handleChange}
              >
                {filterMaster?.intakes?.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />

      {(filterVisibility?.cph || filterVisibility?.agency || filterVisibility?.counsellor) && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className={classes.groupLabel}>{'Agency'}</div>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={2}>
                {filterVisibility?.cph && (
                  <Grid item xs={6}>
                    <SBText text={'CPH'} className={classes.label} />
                    <TextField
                      select
                      name="cph"
                      value={currentFilter.cph}
                      className={formClasses.textField}
                      variant="outlined"
                      onChange={handleChange}
                    >
                      {filterMaster?.cphs?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {`${option.id} ${option.value ? '(' + option.value + ')' : ''}`}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {filterVisibility?.agency && (
                  <Grid item xs={6}>
                    <SBText text={'Agency'} className={classes.label} />
                    <TextField
                      select
                      name="agency"
                      value={currentFilter.agency}
                      className={formClasses.textField}
                      variant="outlined"
                      onChange={handleChange}
                    >
                      {filterMaster?.agencies?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {filterVisibility?.counsellor && (
                  <Grid item xs={6}>
                    <SBText text={'Counsellor'} className={classes.label} />
                    <TextField
                      select
                      name="counsellor"
                      value={currentFilter.counsellor}
                      className={formClasses.textField}
                      variant="outlined"
                      onChange={handleChange}
                    >
                      {filterMaster?.counsellors?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </div>
      )}

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div className={classes.groupLabel}>{'Application created date range'}</div>
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SBText text={'Start Date'} className={classes.label} />
              <SBDate
                value={startDate}
                onDataChange={(value: any) => {
                  setStartDate(moment(value));
                }}
                disabled={false}
              ></SBDate>
            </Grid>
            <Grid item xs={6}>
              <SBText text={'End Date'} className={classes.label} />
              <SBDate
                value={endDate}
                min
                onDataChange={(value: any) => {
                  setEndDate(moment(value));
                }}
                disabled={false}
              ></SBDate>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider style={{ marginRight: -32, marginLeft: -32 }} />
    </div>
  );
};

export default ReportFilter;
