import React from "react";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type InfoDialogProps = {
  className?: string;
  open: boolean;
  onClose: (dataObj: any) => void;
  message: string;
  dataObject: any;
};
const SBValidationErrorDialog: React.FC<InfoDialogProps> = ({
  open,
  onClose,
  message,
  dataObject,
}) => {
  const [inputText, setInputText] = React.useState("");

  const onChangeHandler = (e: any) => {
    setInputText(e.target.value);
  };

  const handleSubmit = () => {
    onClose(dataObject);
  };

  const handleClose = () => {
    onClose(dataObject);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SBValidationErrorDialog;
