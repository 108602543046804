import React, { useState } from "react";
import {
  Popover,
  List,
  ListSubheader,
  IconButton,
  Button,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppColor from "../../../theme/AppColor";
import SBText from "../../base/SBText";
import moment from "moment";
import ArrowDownwardIcon from "@material-ui/icons/Close";
import CloseOutline from "@material-ui/icons/CloseOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { getApplicationIdInString } from "../../../util";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useDownloadReport } from "../../../views/Reports/hooks/useDownloadReport";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  root: {
    width: "100%",
    paddingLeft: 0,
    // backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  notificationHeader: {
    fontFamily: "Poppins",
    textTransform: "none",
    fontWeight: 600,
    letterSpacing: 0.75,
    fontSize: 20,
    color: "#1B2B4B",
  },
  headerParent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 10,
    justifyContent: "space-between",
    // paddingRight: 30,
  },
  popWindow: {
    backgroundColor: AppColor.appWhite,
    height: "90vh",
    width: 500,
  },
  tabParents: {
    display: "flex",
    paddingLeft: 30,
    paddingRight: 30,
    height: 40,
    backgroundColor: "white",
    position: "relative",
    alignItems: "flex-end",
  },
  tabDivider: {
    height: 2,
    backgroundColor: "#EBECF0",
    flex: 1,
    // backgroundColor: "pink",
    bottom: 0,
    left: 30,
    right: 30,
    display: "flex",
  },

  tabSelected: {
    display: "flex",
    height: 40,
    flexDirection: "row",
    width: 120,
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    // borderBottomWidth: 3,
    // borderBottomColor: "#1D54C5",
    // borderBottomStyle: "solid",
  },
  tabSelectedDivider: {
    position: "absolute",
    height: 2,
    width: "100%",
    backgroundColor: "#1D54C5",
    bottom: 0,
  },
  listSection: {
    backgroundColor: "inherit",
    padding: 0,
  },
  ul: {
    padding: 0,
  },
  userNameAb: {
    height: 30,
    width: 30,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  listItemHeader: {
    color: "#1B2B4B",
    fontSize: 12,
    fontWeight: 500,
  },
  listItemComments: {
    color: "#1B2B4B",
    fontSize: 11,
    fontWeight: 400,
  },
  listItemTime: {
    color: "#88909F",
    fontSize: 12,
    fontWeight: 500,
  },
  readParentCircle: {
    height: 18,
    width: 18,
    backgroundColor: "#EBECF0",
    borderRadius: 9,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  readChildCircle: {
    height: 12,
    width: 12,
    backgroundColor: "#1D54C5",
    borderRadius: 6,
  },
  margin: { height: 18, width: 18, marginRight: 7 },
  listItem: {
    paddingLeft: 0,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      background: "#F4F5F7",
    },
  },
}));
type NotificationPopoverProps = {
  className?: string;
  notifications: any;
  anchorEl: any;
  onClose: () => void;
  onListItemClick: (obj: any) => void;
  onCloseNotificationItem: (obj: any) => void;
  onCloseAll: (type: any) => void;
  commentNotification: number;
  reminderNotification: number;
};
const NotificationPopover: React.FC<NotificationPopoverProps> = ({
  children,
  onClose,
  className,
  notifications,
  anchorEl,
  onListItemClick,
  onCloseNotificationItem,
  commentNotification,
  reminderNotification,
  onCloseAll,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const { downloadReport } = useDownloadReport();

  const handleClose = () => {
    onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //console.log("notifications@@@@@@", notifications);

  // {notifications.map((notificationObj: any, index: number) => (
  //     <>
  //       <ListItem
  //           alignItems="flex-start"
  //           onClick={() => onListItemClick(notificationObj)}
  //       >
  //         <ListItemText
  //             primary={notificationObj.question}
  //             secondary={
  //               <React.Fragment>
  //                 <Typography
  //                     component="span"
  //                     variant="body2"
  //                     className={classes.inline}
  //                     color="textPrimary"
  //                 >
  //                   {`${notificationObj.name}:-`}
  //                 </Typography>
  //                 {notificationObj.comment}
  //               </React.Fragment>
  //             }
  //         />
  //       </ListItem>
  //       {index != notifications.length - 1 ? (
  //           <Divider
  //               variant="middle"
  //               component="li"
  //               color={"#000000"}
  //               light={false}
  //               style={{ backgroundColor: "#BCCAD2" }}
  //           />
  //       ) : null}
  //     </>
  // ))}

  const getUserNameInitial = (user: any) => {
    return user.name.substr(0, 1).toUpperCase();
  };

  const getUserNameStyle = (user: any) => {
    const nameLetter = user.name.substr(0, 1).toUpperCase();

    switch (nameLetter) {
      case "A":
      case "B":
      case "C":
      case "D":
      case "E":
      case "F":
      case "G":
      case "H":
        return {
          backgroundColor: AppColor.appGreen,
          fontSize: 14,
          color: AppColor.appBlack,
        };

        break;

      case "I":
      case "J":
      case "K":
      case "L":
      case "M":
      case "N":
      case "O":
      case "P":
        return {
          backgroundColor: "#254C9A",
          fontSize: 14,
          color: AppColor.appWhite,
        };

        break;
      case "Q":
      case "R":
      case "S":
      case "T":
      case "U":
      case "V":
      case "W":
      case "X":
      case "Y":
      case "Z":
        return {
          backgroundColor: "#F29D42",
          fontSize: 14,
          color: AppColor.appBlack,
        };

        break;

      default:
        return {
          backgroundColor: "#F29D42",
          fontSize: 14,
          color: AppColor.appBlack,
        };
        break;
    }
  };

  const getTime = (date: any) => {
    // console.log("date::::",date)
    const now = moment();
    const expiration = moment(date);
    const diff = now.diff(expiration);
    const diffDuration = moment.duration(diff);
    try {
      let current_date: any = new Date();
      let created_date: any = new Date(date);
      let diffInMilliSeconds = Math.abs(current_date - created_date) / 1000;
      let output: any;
      const days = Math.floor(diffInMilliSeconds / 86400);
      diffInMilliSeconds -= days * 86400;
      const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
      diffInMilliSeconds -= hours * 3600;
      const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
      if (days === 0) {
        if (hours === 0) {
          if (minutes == 1) {
            output = `${minutes} minute ago`;
          } else {
            output = `${minutes} minutes ago`;
          }
        } else {
          output = `${hours} hours ago`;
        }
      } else if (days > 7) {
        output = `few days ago`;
      } else {
        if (days == 1) {
          output = `${days} day ago`;
        } else {
          output = `${days} days ago`;
        }
      }

      return output;
    } catch (error) {
      console.log(error);
      return NaN;
    }
  };

  const onMouseEnter = (index: number) => {
    setHoverIndex(index);
  };

  const onMouseLeave = () => {
    setHoverIndex(-1);
  };

  const onCloseIconClick = (event: any, item: any) => {
    event.stopPropagation();
    onCloseNotificationItem(item);
  };

  const getStudentName = (item: any) => {
    if (item.studentName) {
      return ` | ${item.studentName}`;
    } else {
      return "";
    }
  };

  // console.log("Notification List ::::", notifications);

  const renderCommentView = () => {
    return (
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <List className={classes.root}>
          {notifications.comments.map((section: any, index: number) => (
            <li
              key={`section-${section.header}`}
              className={classes.listSection}
            >
              <ul className={classes.ul}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ListSubheader
                    style={{ paddingLeft: 15, backgroundColor: "white" }}
                  >{`${section.header}`}</ListSubheader>

                  {index == 0 ? (
                    <Button
                      size="small"
                      style={{ fontSize: 10, fontFamily: "Poppins" }}
                      onClick={() => {
                        let list: any = [];
                        for (
                          let i = 0;
                          i < notifications.comments.length;
                          i++
                        ) {
                          const ids = notifications.comments[i].items.map(
                            (item: any) => item.id
                          );
                          list = list.concat(ids);
                        }
                        onCloseAll(list);
                      }}
                    >
                      Clear All
                      <CloseOutline
                        fontSize="inherit"
                        style={{ marginLeft: 7, height: 16, width: 16 }}
                      />
                    </Button>
                  ) : null}
                </div>
                {section.items.map((item: any, index: number) => {
                  const styleData = getUserNameStyle(item.sender);
                  return (
                    <div
                      key={`item-${section.header}-${item.id}`}
                      className={classes.listItem}
                      style={{
                        marginTop: index != 0 ? 10 : 0,
                      }}
                      onMouseEnter={() => onMouseEnter(index)}
                      onMouseLeave={() => onMouseLeave()}
                      onClick={() => onListItemClick(item)}
                    >
                      <div style={{ marginRight: 16 }}>
                        <div
                          className={classes.userNameAb}
                          style={{
                            backgroundColor: styleData.backgroundColor,
                            marginTop: 5,
                            marginLeft: 15,
                          }}
                        >
                          <SBText
                            text={getUserNameInitial(item.sender)}
                            style={{
                              color: styleData.color,
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ width: "78%" }}>
                        <SBText
                          text={`${item.headerComments}`}
                          className={classes.listItemHeader}
                        />
                        <SBText
                          text={`${item.comment}`}
                          className={classes.listItemComments}
                        />
                        <SBText
                          text={`${getTime(
                            item.createdAt
                          )} | ${getApplicationIdInString(
                            item.applicationId
                          )}${getStudentName(item)} `}
                          className={classes.listItemTime}
                        />
                      </div>
                      <div
                        style={{
                          width: 45,
                          flexDirection: "row",
                          display: "flex",
                          marginRight: 15,
                        }}
                      >
                        {hoverIndex === index ? (
                          <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            size="small"
                            onClick={(event) => onCloseIconClick(event, item)}
                          >
                            <ArrowDownwardIcon fontSize="inherit" />
                          </IconButton>
                        ) : (
                          <div className={classes.margin} />
                        )}

                        {!item.read ? (
                          <div className={classes.readParentCircle}>
                            <div className={classes.readChildCircle}></div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </ul>
            </li>
          ))}
        </List>
      </div>
    );
  };

  const renderReminderView = () => {
    return (
      <div style={{ paddingLeft: 15, paddingRight: 15 }}>
        <List className={classes.root}>
          {notifications.reminder.map((section: any, index: number) => (
            <li
              key={`section-${section.header}`}
              className={classes.listSection}
            >
              <ul className={classes.ul}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ListSubheader
                    style={{ paddingLeft: 15, backgroundColor: "white" }}
                  >{`${section.header}`}</ListSubheader>

                  {index == 0 ? (
                    <Button
                      size="small"
                      style={{ fontSize: 10, fontFamily: "Poppins" }}
                      onClick={() => {
                        let list: any = [];
                        for (
                          let i = 0;
                          i < notifications.reminder.length;
                          i++
                        ) {
                          const ids = notifications.reminder[i].items.map(
                            (item: any) => item.id
                          );
                          list = list.concat(ids);
                        }
                        onCloseAll(list);
                      }}
                    >
                      Clear All
                      <CloseOutline
                        fontSize="inherit"
                        style={{ marginLeft: 7, height: 16, width: 16 }}
                      />
                    </Button>
                  ) : null}
                </div>
                {section.items.map((item: any, index: number) => {
                  const styleData = getUserNameStyle({ name: "Reminder" });
                  return (
                    <div
                      key={`item-${section.header}-${item.id}`}
                      className={classes.listItem}
                      style={{
                        marginTop: index != 0 ? 10 : 0,
                      }}
                      onMouseEnter={() => onMouseEnter(index)}
                      onMouseLeave={() => onMouseLeave()}
                      onClick={() => onListItemClick(item)}
                    >
                      <div style={{ marginRight: 16 }}>
                        <div
                          className={classes.userNameAb}
                          style={{
                            backgroundColor: styleData.backgroundColor,
                            marginTop: 5,
                            marginLeft: 15,
                          }}
                        >
                          <SBText
                            text={getUserNameInitial({ name: "Reminder" })}
                            style={{
                              color: styleData.color,
                              fontSize: 12,
                              fontWeight: 500,
                            }}
                          />
                        </div>
                      </div>
                      <div style={{ width: "78%" }}>
                        <SBText
                          text={`${item.headerComments}`}
                          className={classes.listItemHeader}
                        />
                        <SBText
                          text={`${item.comment}`}
                          className={classes.listItemComments}
                        />
                        <SBText
                          text={`${getTime(item.createdAt)}`}
                          className={classes.listItemTime}
                        />
                      </div>
                      {item?.category == "REPORT" && (
                        <div
                          style={{
                            width: 30,
                            marginRight: 8,
                            marginLeft: 10,
                          }}
                        >
                          <Tooltip title={"Download Report"}>
                            <CloudDownloadIcon
                              style={{ fontSize: "30px", cursor: "pointer" }}
                              onClick={(e) => {
                                if (item.additionalInfo) {
                                  const info = JSON.parse(item.additionalInfo);
                                  if (info && info.id) {
                                    downloadReport(info);
                                  }
                                }
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                      <div
                        style={{
                          width: 45,
                          flexDirection: "row",
                          display: "flex",
                          marginRight: 15,
                        }}
                      >
                        {hoverIndex === index ? (
                          <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            size="small"
                            onClick={(event) => onCloseIconClick(event, item)}
                          >
                            <ArrowDownwardIcon fontSize="inherit" />
                          </IconButton>
                        ) : (
                          <div className={classes.margin} />
                        )}

                        {!item.read ? (
                          <div className={classes.readParentCircle}>
                            <div className={classes.readChildCircle}></div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </ul>
            </li>
          ))}
        </List>
      </div>
    );
  };

  const getCommentsTabText = () => {
    if (commentNotification > 0) {
      return `Comments(${commentNotification})`;
    } else {
      return `Comments`;
    }
  };

  const getReminderTabText = () => {
    if (reminderNotification > 0) {
      return `Reminders(${reminderNotification})`;
    } else {
      return `Reminders`;
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      {/*"#EBEFF2"*/}
      <div className={classes.popWindow}>
        <div className={classes.headerParent}>
          <SBText
            text={"Notifications"}
            className={classes.notificationHeader}
          />
          <IconButton onClick={() => onClose()}>
            <HighlightOffIcon />
          </IconButton>
        </div>
        <div className={classes.tabParents}>
          <div className={classes.tabDivider} />

          <div
            style={{
              display: "flex",
              height: 40,
              flexDirection: "row",
              position: "absolute",
            }}
          >
            <div
              className={classes.tabSelected}
              onClick={() => setSelectedTab(0)}
            >
              <SBText
                text={getCommentsTabText()}
                style={{
                  color: selectedTab == 0 ? "#1D54C5" : "#46536D",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              />
              {selectedTab == 0 ? (
                <div className={classes.tabSelectedDivider} />
              ) : null}
            </div>

            <div
              className={classes.tabSelected}
              style={{ marginLeft: 20 }}
              onClick={() => setSelectedTab(1)}
            >
              <SBText
                text={getReminderTabText()}
                style={{
                  color: selectedTab == 1 ? "#1D54C5" : "#46536D",
                  fontSize: 14,
                  fontWeight: 500,
                }}
              />
              {selectedTab == 1 ? (
                <div className={classes.tabSelectedDivider} />
              ) : null}
            </div>
          </div>
        </div>
        {selectedTab == 0 ? renderCommentView() : renderReminderView()}
      </div>
    </Popover>
  );
};

export default NotificationPopover;
