import React, { useState, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, TextField } from "@material-ui/core";
import { useHistory } from "react-router";
import ButtonBase from "@material-ui/core/ButtonBase";
import { RootState } from "../../reducer";
import { connect, ConnectedProps } from "react-redux";
import EntityTable from "./EntityTable";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SBButton from "../../components/base/SBButton";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import {
  createNewApplication,
  createNewEntity,
  getEntityList,
  getEntity,
  updateEntity,
  dispatchEntity,
  getAllAgencies,
} from "../../actions";
import SBText from "../../components/base/SBText";
import EntityModel from "./EntityModel";
import { LABELS } from "../../util/labels";
import { hasPermission, PERMISSION } from "../../util/rolePermissionsUtil";
import { useUserPermissions } from "../../hooks/useUserPermissions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#F1F1F1",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    newAppBt: {
      width: 130,
      height: 50,
      borderRadius: 30,
      textTransform: "none",
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        width: 130,
        fontSize: 14,
      },
    },
    tabBasic: {
      height: 40,
      width: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tabSelected: {
      height: 50,
      width: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cardContent: {
      display: "flex",
      minHeight: "70vh",
      flexDirection: "column",
      padding: 0,
    },
    nameCircle: {
      height: 78,
      width: 78,
      borderRadius: 40,
      backgroundColor: "#727487",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    circleText: { color: "white", fontWeight: "bold" },
    name: { color: "black", fontWeight: "bold" },
    appId: { color: "#898989", marginTop: 10 },
    headerTextParent: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 35,
    },
    cardHeader: {
      flex: 0.1,
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: 22,
    },
    cardStatus: {
      flex: 0.6,
      width: "100%",
      display: "flex",
      backgroundColor: "pink",
      flexDirection: "row",
    },
    customWidth: {
      "& div": {
        // this is just an example, you can use vw, etc.
        width: "200px",
        fontFamily: "Poppins",
        borderRadius: 0,
      },
    },
    input: {
      height: 40,
      border: "none",
      backgroundColor: "white",
      marginLeft: 10,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
    tabsParent: {
      height: 60,
      display: "flex",
      marginTop: 20,
      justifyContent: "space-between",
    },
    listContainer: {
      height: "calc(100vh - 242px)",
      [theme.breakpoints.up(1920)]: {
        height: "calc(100vh - 242px)",
      },
      overflow: "hidden",
    },
    searchSortParents: {
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.up(1920)]: {
        height: 60,
        alignItems: "unset",
      },
    },
    tabSubParents: {
      display: "flex",
      alignItems: "flex-end",
    },
    searchbox: {
      border: "none",
      "& .MuiOutlinedInput-root": {
        position: "unset",
      },
      "& .MuiInputBase-input": {
        color: "#000000",
        fontFamily: "Poppins",
        fontWeight: 400,
        letterSpacing: "0.75px",
        textTransform: "none",
        fontSize: 14,
      },
      "& fieldset": {
        border: "unset",
      },
    },
    inherited: {
      "& .MuiFormControl-root": {
        position: "unset",
      },
      height: "fit-content",
    },
    tabDivider: { width: 10 },
  })
);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SBApplicationListProps = PropsFromRedux & {};

const SBProviderApplicationList: React.FC<SBApplicationListProps> = ({
  agencies,
  getAllAgencies,
  entityListDetails,
  getEntityList,
  updateEntity,
  createNewEntity,
  getEntity,
  dispatchEntity,
  selectedEntityDetails,
  ...rest
}) => {
  const classes = useStyles();
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(10);
  const [newPage, setNewPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [openModel, setOpenModel] = React.useState(false);

  useEffect(() => {
    loadApplicationList();
    loadAllAgencies();
    return () => {};
  }, []);

  const hasPerm = (perm: string) => {
    return hasPermission(perm, userPermissions);
  };

  const loadApplicationList = async () => {
    await getEntityList({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      searchText: searchText,
    });
  };

  const loadAllAgencies = async () => {
    await getAllAgencies();
  };

  const onChangeSearchText = async (event: any) => {
    setSearchText(event.target.value);
  };

  const onSearchClick = async () => {
    setPage(0);
    await getEntityList({
      offset: 0,
      limit: rowsPerPage,
      searchText: searchText,
    });
  };

  const handleChangePage = async (
    // event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    await getEntityList({
      offset: rowsPerPage * (newPage + 1),
      limit: rowsPerPage,
      searchText: searchText,
    });
  };

  const onClose = (): void => {
    dispatchEntity(undefined);
    setOpenModel(false);
  };

  const onModelClick = (item: any) => {
    getEntity(item.id);
    setOpenModel(true);
  };

  const onCreateOrUpdate = async (item: any) => {
    const result: any = item.id
      ? await updateEntity(item)
      : await createNewEntity(item);
    if (!result.error) {
      setOpenModel(false);
      dispatchEntity(undefined);
      setPage(0);
      setNewPage(0);
    }
  };

  // @ts-ignore
  return (
    <div className={classes.root}>
      {hasPerm(PERMISSION.user_agencies_create) && (
        <SBButton
          icon={false}
          text={LABELS.NEW_AGENCY}
          className={classes.newAppBt}
          onClick={() => {
            dispatchEntity(undefined);
            setOpenModel(true);
          }}
        />
      )}

      <div className={classes.tabsParent}>
        {/* tabs for user,role,entity */}
        {rest.children}

        <div className={classes.searchSortParents}>
          <div className={classes.inherited}>
            <TextField
              id="input-with-icon-textfield"
              variant="outlined"
              placeholder={"Search Text"}
              className={classes.searchbox}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={onSearchClick}>
                    <SearchIcon />
                  </InputAdornment>
                ),
                className: classes.input,
              }}
              onChange={onChangeSearchText}
            />
          </div>
        </div>
      </div>
      <div className={classes.listContainer}>
        {entityListDetails?.count === 0 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <AnnouncementIcon
              style={{ fill: "#8F928E", height: 150, width: 150, opacity: 0.6 }}
            />
            <SBText
              text={`No entities exist`}
              style={{
                fontSize: 20,
                fontFamily: "Poppins",
                fontWeight: "600",
                color: "#8F928E",
              }}
            />
          </div>
        ) : (
          <EntityTable
            handlePageChange={handleChangePage}
            data={entityListDetails?.rows || []}
            userPermissions={userPermissions}
            newPage={newPage}
            setNewPage={(value) => setNewPage(value)}
            onModelClick={onModelClick}
          />
        )}
      </div>
      <EntityModel
        open={openModel}
        onClose={onClose}
        entities={agencies || []}
        data={selectedEntityDetails}
        onSave={(item) => onCreateOrUpdate(item)}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  entityListDetails: state.entities.entityListDetails,
  agencies: state.entities.agencies,
  selectedEntityDetails: state.entities.selectedEntityDetails,
});

const mapDispatch = {
  createNewApplication,
  getAllAgencies,
  getEntityList,
  createNewEntity,
  updateEntity,
  getEntity,
  dispatchEntity,
};

const connector = connect(mapState, mapDispatch);
export default connector(SBProviderApplicationList);
