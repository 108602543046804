import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import _, { update } from "lodash";
import SBButton from "../../../components/base/SBButton";
import AppColor from "../../../theme/AppColor";
import SBText from "../../../components/base/SBText";
import SBFilterDropdown from "../../../components/base/SBFilterDropdown";
import SBTextField from "../../../components/base/SBTextField";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    cancelButton: {
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: "#FAFAFA",
      borderColor: AppColor.appBlue,
      borderWidth: 1,
      borderStyle: "solid",
      color: AppColor.appBlue,
    },
    requestButton: {
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 16,
      marginBottom: 10,
    },
    paper: { width: "450px", minHeight: 300 },
    checkButtonText: {
      marginLeft: 6,
      fontSize: 16,
      fontWeight: 600,
      color: AppColor.appBlue,
    },
    uncheckButton: { opacity: 0.5, height: 30, width: 30 },
    checkButton: { height: 30, width: 30 },
    checkedText: { color: "black" },
    arrow: {
      "&:before": {
        border: "0px solid #E6E8ED",
      },
      color: AppColor.appBlue,
    },
    tooltip: {
      backgroundColor: AppColor.appBlue,
      border: "0px solid #E6E8ED",
      color: "white",
      width: 120,
      textAlign: "center",
    },
    textAreaWidth: {
      border: "1px solid black",
      borderRadius: "5px",
      fontFamily: "Poppins",
    },
    label: {
      textAlign: "left",
      color: theme.palette.primary.light,
      display: "flex",
    },
    alertTitle: {
      fontFamily: "poppins",
      letterSpacing: 0.15,
      fontWeight: 600,
      backgroundColor: AppColor.appBlue,
      height: 50,
      paddingTop: 5,
      paddingBottom: 5,
    },
    mainTitle: {
      fontSize: 16,
      color: "white",
      fontWeight: 600,
    },
    subTitle: {
      fontSize: 12,
      color: "white",
    },
    dialogContentParent: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
  })
);

type ChangeBasicDetailDialogProps = {
  className?: string;
  open: boolean;
  onSubmit: (obj: any) => void;
  data: any;
  onClose: () => void;
};
const ChangeBasicDetailDialog: React.FC<ChangeBasicDetailDialogProps> = ({
  open,
  onSubmit,
  onClose,
  data,
}) => {
  const [answerData, setAnswerData] = useState([]);

  const [updatedAnswers, setUpdatedAnswers] = useState({ values: [] });
  const classes = useStyles();

  useEffect(() => {
    if (data) {
      setAnswerData(data);
    }
  }, [data]);

  const handleClose = () => {
    onClose();
    setAnswerData([]);
    setUpdatedAnswers({ values: [] });
  };

  const onSubmitAction = () => {
    if(updatedAnswers.values && updatedAnswers.values.length>0) {
        onSubmit(updatedAnswers)
        handleClose()
    } else {
        handleClose()
    }
  };

  const onDropDownChange = (value: string, question: any, index: number) => {
    let newArr: any = [...answerData]; // copying the old datas array
    newArr[index].answer = value; // replace e.target.value with whatever you want to change it to
    setAnswerData(newArr);
    const selectedAnswer = _.find(
      question.options,
      (obj: any) => obj.value == value
    );

    if (selectedAnswer && value) {
      const answerObject: any = _.cloneDeep(updatedAnswers);
      const answerIndex = _.findIndex(
        answerObject.values,
        (obj: any) => obj.questionId == question.id
      );

      if (answerIndex != -1) {
        answerObject.values[answerIndex].value.id = selectedAnswer.id;
        answerObject.values[answerIndex].value.label = selectedAnswer.id;
      } else {
        const newAnswer = {
          questionId: question.id,
          value: {
            id: selectedAnswer.id,
            label: selectedAnswer.id,
          },
        };
        answerObject.values.push(newAnswer);
      }
      setUpdatedAnswers(answerObject);
    }
  };

  const onChangeText = (value: string, question: any, index: number) => {
    let newArr: any = [...answerData]; // copying the old datas array
    newArr[index].answer = value; // replace e.target.value with whatever you want to change it to
    setAnswerData(newArr);
    if(value && value.trim()) {
    const answerObject: any = _.cloneDeep(updatedAnswers);
    const answerIndex = _.findIndex(
      answerObject.values,
      (obj: any) => obj.questionId == question.id
    );

    if (answerIndex != -1) {
      answerObject.values[answerIndex].value.id = value.trim();
      answerObject.values[answerIndex].value.label = value.trim();
    } else {
      const newAnswer = {
        questionId: question.id,
        value: {
          id: value.trim(),
          label: value.trim(),
        },
      };
      answerObject.values.push(newAnswer);
    }
    setUpdatedAnswers(answerObject);
}
  };

  const isQuestionValid = (question: any) => {
    if (question) {
      if (
        (question.key && question.key.includes("PASSPORT")) ||
        question.question.includes("passport") ||
        (question.key && question.key == "Q_NATIONALITY")
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.alertTitle}>
          <SBText
            className={classes.mainTitle}
            text={"Update Applicant Basic Details"}
          />
          <SBText
            className={classes.subTitle}
            text={"Title, Name, Currently Located etc... "}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContentParent}>
            {answerData &&
              answerData.map((obj: any, index: number) => {
                if (isQuestionValid(obj.question)) {
                  return (
                    <>
                      <div style={{ marginTop: 7 }} />
                      <div style={{ height: 90 }}>
                        {obj.question.type == "TextField" ? (
                          <SBTextField
                            placeholder=""
                            name="roleName"
                            onDataChange={(text) =>
                              onChangeText(text, obj.question, index)
                            }
                            labelText={obj.question.question}
                            value={obj.answer}
                          />
                        ) : (
                          <SBFilterDropdown
                            data={obj.question.options}
                            value={obj.answer}
                            labelText={obj.question.question}
                            onChange={(value: any) =>
                              onDropDownChange(value, obj.question, index)
                            }
                          />
                        )}
                      </div>
                    </>
                  );
                }
              })}
          </div>
        </DialogContent>
        <DialogActions>
          <SBButton
            icon={false}
            text={"Cancel"}
            onClick={() => handleClose()}
            className={classes.requestButton}
          />
          <SBButton
            icon={false}
            text={"Submit"}
            onClick={() => onSubmitAction()}
            className={classes.requestButton}
          />
          {/*<SBButton icon={false} text={"OK"} onClick={handleSubmit} />*/}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeBasicDetailDialog;
