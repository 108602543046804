import { TablePagination, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import NoDataMessage from "../../../components/NoDataMessage";
import React, { useCallback, useEffect, useState } from "react";
import { tableViewHeaderStyle, tableViewRootStyle } from "../../../styles";
import { useFetchAgentData } from "../hooks/useFetchAgentData";
import { useFetchLegacyAgent } from "../hooks/useFetchLegacyDetails";
import { AgentTableViewItem } from "../types/agent-table-view-item";
import { mapAgentDataToTableViewItem } from "../utils/agents-data-mapping";
import AgentTable from "./AgentTable";
import LegacyAgentTable from "./LegacyAgentTable/LegacyAgentTable";
import ToolBar from "./ToolBar";
import AddAgentModal from "./AddAgentModal";
import { FormCloseOptions } from "./AddAgentModal/create-agent-form.types";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAgentPaginatedData,
  selectedAgentDetails,
  getAllOnBoardAgentsRequest
} from "../store/selectors";
import { Agent } from "../types/agent";
import { useFetchAgentDetails } from "../hooks/useFetchAgentDetails";
import { AgentPaginatedData } from "../types/agent-paginated-data";
import { DEFAULT_FETCH_PARAMS, PAGE_SIZES } from "../constants";
import { FetchAgentParams } from "../types/fetch-agent-params";
import { useOnboardRequestAgent } from "../hooks/useOnboardRequestAgent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...tableViewRootStyle(theme),
    },
    header: {
      ...tableViewHeaderStyle,
    },
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    titleBtnContainer: {
      marginBottom: 7,
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const AgentTableView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [isAgentModalOpen, setAgentModalOpen] = useState(false);
  const { fetchData } = useFetchAgentData();
  const { fetchAgentDetails } = useFetchAgentDetails();
  const { fetchLegacyData } = useFetchLegacyAgent();
  const { fetchOnboardRequestAgentData } = useOnboardRequestAgent();
  // const agents = useSelector(selectAgents) || [];
  const { legacyAgentReducer } = useSelector((state: any) => state);
  const { onboardRequestAgentReducer } = useSelector((state: any) => state);
  const selectedAgentDetail: Agent = useSelector(selectedAgentDetails);
  const tableData: AgentPaginatedData = useSelector(selectAgentPaginatedData);
  const onBoardTableData: AgentPaginatedData = useSelector(getAllOnBoardAgentsRequest);
  const [agentList, setAgentList] = useState<AgentTableViewItem[]>([]);
  const [onBoardAgentList, setOnBoardAgentList] = useState<AgentTableViewItem[]>([]);
  const [fetchAgentParams, setFetchAgentParams] = useState<FetchAgentParams>(
    DEFAULT_FETCH_PARAMS
  );
  const [fetchOnBoardAgentParams, setFetchOnBoardAgentParams] = useState<FetchAgentParams>(
    DEFAULT_FETCH_PARAMS
  );


  useEffect(() => {
    fetchData(fetchAgentParams);
    fetchLegacyData(0, 5);
    fetchOnboardRequestAgentData(0, 5);
  }, [fetchData, fetchLegacyData]);

  useEffect(() => {
    fetchData(fetchAgentParams);
  }, [fetchAgentParams]);

  useEffect(() => {
    fetchOnboardRequestAgentData(fetchOnBoardAgentParams.offset, fetchOnBoardAgentParams.limit);
  }, [fetchOnBoardAgentParams]);

  useEffect(() => {
    setAgentList(tableData.data.map(mapAgentDataToTableViewItem));
  }, [tableData]);
  
  useEffect(() => {
    setOnBoardAgentList(onBoardTableData.data.map(mapAgentDataToTableViewItem));
  }, [onBoardTableData]);

 
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const onViewAgent = (agent: AgentTableViewItem) => {
    fetchAgentDetails(agent.id as number);
    setAgentModalOpen(true);
  };
  const handleAgentModalClose = useCallback(
    (options: FormCloseOptions) => {
      setAgentModalOpen(false);
      if (options.sucessful) {
        fetchData(fetchAgentParams);
      }
    },
    [setAgentModalOpen, fetchData]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setFetchAgentParams({
      ...fetchAgentParams,
      offset: newPage * tableData.pageSize,
    });
  };

  const handleOnBoardRequestPageChange = (event: unknown, newPage: number) => {
    setFetchOnBoardAgentParams({
      ...fetchOnBoardAgentParams,
      offset: newPage * onBoardTableData.pageSize,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pageSize: number = parseInt(event.target.value, 10);
    setFetchAgentParams({
      ...fetchAgentParams,
      limit: pageSize,
      offset: 0,
    });
  };

  const handleonBoardAgentChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pageSize: number = parseInt(event.target.value, 10);
    setFetchOnBoardAgentParams({
      ...fetchOnBoardAgentParams,
      limit: pageSize,
      offset: 0,
    });
  };

  const handleOnSearchOrFilter = (searchBy: string, filterArr: string) => {
    setFetchAgentParams({
      ...fetchAgentParams,
      offset: 0,
      search: searchBy,
      filters: filterArr,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>Agents</div>
      <ToolBar handleOnSearchOrFilter={handleOnSearchOrFilter} />
      {agentList.length <= 0 ? (
        <NoDataMessage message={`No agents found.`} />
      ) : (
        <>
          <AppBar
            position="static"
            color="default"
            style={{ boxShadow: "none" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Agents" />
              <Tab label="Cross Agency" />
              <Tab label="Onboarding Request" />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <AgentTable agentList={agentList} onViewAgent={onViewAgent} />
            <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              component="div"
              count={tableData.totalRecords}
              rowsPerPage={tableData.pageSize}
              page={tableData.currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LegacyAgentTable
              agents={legacyAgentReducer.agents}
              totalAgents={legacyAgentReducer.totalAgentCount}
            />
          </TabPanel>

          <TabPanel value={value} index={2}>
          <AgentTable agentList={onBoardAgentList} onViewAgent={onViewAgent} />
          <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              component="div"
              count={onBoardTableData.totalRecords}
              rowsPerPage={onBoardTableData.pageSize}
              page={onBoardTableData.currentPage}
              onPageChange={handleOnBoardRequestPageChange}
              onRowsPerPageChange={handleonBoardAgentChangeRowsPerPage}
            />
          </TabPanel>
          <AddAgentModal
            open={isAgentModalOpen}
            onClose={handleAgentModalClose}
            selectedAgentDetail={selectedAgentDetail}
          />
        </>
      )}
    </div>
  );
};

export default AgentTableView;
