import React from 'react';
import AnnouncementIcon from "@material-ui/icons/Announcement";
import SBText from '../base/SBText';

interface Props {
  message: string;
}

const defaultMessage = 'No data found';

const NoDataMessage: React.FC<Props> = ({ message = defaultMessage }) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <AnnouncementIcon
        style={{ fill: "#8F928E", height: 150, width: 150, opacity: 0.6 }}
      />
      <SBText
        text={message}
        style={{
          fontSize: 20,
          fontFamily: "Poppins",
          fontWeight: "600",
          color: "#8F928E",
        }}
      />
    </div>
  )
}

export default NoDataMessage;
