import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import "./D3BarGraphChart.css";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

interface D3BarGraphChartProps {
  data: any;
}

const D3BarGraphChart: React.FC<D3BarGraphChartProps> = ({ data }) => {
  const [startingPointState, setStartingPointState] = useState(0);
  const [endingPointState, setEndingPointState] = useState(8);

  let svg,
    margin,
    width,
    height: any,
    x0: any,
    x1: any,
    y: any,
    z: any,
    keys: any,
    g;

  const formatData = () => {
    let tempData: any = [];
    data.forEach((element: any, index: number) => {
      if (index >= startingPointState && index <= endingPointState) {
        tempData.push({
          Date: element.day + " " + element.month,
          lead: element.lead,
          convertedApp: element.convertedApp,
        });
      }
    });
    return tempData;
  };

  const inititaeSvg = () => {
    d3.selectAll(".svgContainerForBarGraph svg").remove();
    let svgHeight = 300;
    let svgWidth = divRef!.current?.offsetWidth ?? 100 - 200;

    svg = d3
      .select(divRef.current)
      .append("svg")
      .attr("width", svgWidth)
      .attr("height", svgHeight)
      .attr(
        "transform",
        //"translate(" + svgWidth / 2 + "," + svgHeight / 2 + ")"
        "translate(" + 0 + "," + 0 + ")"
      );

    margin = { top: 20, right: 20, bottom: 30, left: 40 };

    width = +svgWidth - margin.left - margin.right;

    height = +svgHeight - margin.top - margin.bottom;

    g = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    x0 = d3.scaleBand().rangeRound([0, width]).paddingInner(0.1);

    x1 = d3.scaleBand().padding(0.05);

    y = d3.scaleLinear().rangeRound([height, 0]);

    z = d3.scaleOrdinal().range(["#8BB19C", "#3D405B"]);

    interface Data {
      [key: string]: string | number | any;
      Date: string;
      lead: number;
      convertedApp: number;
    }

    let data: Data[] = formatData();

    keys = Object.keys(data[0]).slice(1);

    x0.domain(
      data.map(function (d) {
        return d.Date;
      })
    );
    x1.domain(keys).rangeRound([0, 24]);
    y.domain([
      0,
      d3.max(data, function (d) {
        return d3.max(keys, function (key: any) {
          return d[key];
        });
      }),
    ]).nice();

    g.append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr("transform", function (d) {
        return "translate(" + (x0(d.Date) + 13) + ",0)";
      })
      .selectAll("rect")
      .data(function (d) {
        return keys.map(function (key: string) {
          return { key: key, value: d[key] };
        });
      })
      .enter()
      .append("rect")
      .attr("x", function (d: any) {
        return x1(d.key);
      })
      .attr("y", function (d: any) {
        return y(d.value);
      })
      .attr("rx", "1%")
      .attr("width", 12)
      .attr("height", function (d: any) {
        return height - y(d.value);
      })
      .attr("fill", function (d: any) {
        return z(d.key);
      })
      .on("mouseover", function (event, d: any) {
        d3.select("#tooltipGraphChart")
          .style("left", event.clientX + "px")
          .style("top", event.clientY + "px")
          .style("opacity", 1)
          .select("#value")
          .text(d.value);
      })
      .on("mouseout", function () {
        d3.select("#tooltipGraphChart").style("opacity", 0);
      });

    g.append("g")
      .attr("class", "barGraphAxis")
      .attr("transform", "translate(0," + (height + 10) + ")")
      .call(d3.axisBottom(x0));

    g.append("g")
      .attr("class", "barGraphAxis")
      .call(d3.axisLeft(y).ticks(null, "s"))
      .append("text")
      .attr("x", 2)
      .attr("y", y(y.ticks().pop()) + 0.5)
      .attr("dy", "0.32em")
      .attr("fill", "#727487")
      .attr("font-weight", "400")
      .attr("text-anchor", "start");
  };

  useEffect(() => {
    inititaeSvg();
  }, []);

  useEffect(() => {
    inititaeSvg();
  }, [startingPointState, endingPointState]);

  const handleClickNext = () => {
    if (endingPointState + 8 <= data.length) {
      setStartingPointState(8 + startingPointState);
      setEndingPointState(8 + endingPointState);
    } else {
      setStartingPointState(data.length - 8);
      setEndingPointState(data.length);
    }
  };

  const handleClickPrevious = () => {
    if (startingPointState - 8 >= 0) {
      setStartingPointState(startingPointState - 8);
      setEndingPointState(endingPointState - 8);
    } else {
      setStartingPointState(0);
      setEndingPointState(8);
    }
  };

  const divRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div id="tooltipGraphChart" className="hidden">
        <p>
          <span id="value"></span>
        </p>
      </div>
      <div
        className="svgContainerForBarGraph"
        id="svgContainerForBarGraph"
        ref={divRef}
      ></div>
      <div className="navigationButtonContainer">
        <div style={{ float: "left" }}>
          {startingPointState != 0 && (
            <ChevronLeftIcon
              className="navigationButton"
              onClick={handleClickPrevious}
            />
          )}
        </div>
        <div style={{ float: "right" }}>
          {endingPointState < data.length && (
            <ChevronRightIcon
              className="navigationButton"
              onClick={handleClickNext}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default D3BarGraphChart;
