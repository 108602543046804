import { useMediaQuery, useTheme } from "@material-ui/core";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from "../../../actions";
import { API_URL } from "../../../util/apiUrls";
import { LeadAction } from "../store/actions/action-types";
import { FetchLeadsParams } from "../types";
import { getLeadsRequestQuery } from "../utils";

export const useFetchLeadsPaginatedData = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"), {noSsr: true});
  const fetchData = useCallback(
    async (params: FetchLeadsParams) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(
          `${API_URL.LEADS.LIST}${getLeadsRequestQuery(params)}`,
          dispatch
        );
        if(isMobileView) {
          dispatch({
            type: LeadAction.SET_LEADS_MOBILE_DATA,
            payload: result,
          });
        } else {
          dispatch({
            type: LeadAction.SET_LEADS_PAGINATED_DATA,
            payload: result,
          });
        }
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch, isMobileView]
  );

  return {
    fetchData,
  };
};
