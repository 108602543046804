import React, { useEffect, useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import {
  makeStyles,
  createStyles,
  Theme,
  Modal,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import _ from "lodash";
import SBSearch from "../SBSearch";
import SBText from "../SBText";
import SBButton from "../SBButton";
import { HighlightOffRounded } from "@material-ui/icons";
import AppColor from "../../../theme/AppColor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryBox: {
      width: "100%",
      padding: "10px 12px 6px 12px",
      color: "#263238",
      fontFamily: "Poppins",
      letterSpacing: "0.75px",
      borderRadius: "5px",
      border: "1px solid rgba(0,0,0,0.15)",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#FCFBFB",
      minHeight: "52px",

      "&:hover": {
        borderColor: "#3E4059",
        borderWodth: "2px",
      },

      "& .chip": {
        fontSize: "12px",
        color: "#263238",
        backgroundColor: "#eceff1",
        borderRadius: "20px",
        padding: "2px 12px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        display: "inline-flex",
        marginRight: "4px",
        marginBottom: "4px",
        height: "30px",
        alignItems: "center",
      },
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`,
      width: "50%",
      height: "60%",
      overflow: "auto",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],

      "& .modal-title": {
        flex: "0 0 30px",
        padding: "15px 10px",
        backgroundColor: "#5C5F74",
        color: "white",
        fontFamily: "Poppins",
        fontWeight: 500,
        display: "flex",
        justifyContent: "space-between",
      },

      "& .modal-body": {
        padding: "10px",
        overflow: "auto",

        "& .search-box": {
          border: "1px solid rgba(0,0,0,0.15)",
          borderRadius: "5px",
          marginBottom: "-2px",
          "&:hover": {
            borderColor: "#727487",
            borderWodth: "2px",
          },
        },

        "& .MuiButtonBase-root": {
          padding: "2px 6px",
        },

        "& .group-selector": {
          "& .MuiFormControlLabel-label": {
            fontFamily: "Poppins",
            fontWeight: 500,
            fontSize: "14px",
          },
        },

        "& .provider-selector": {
          "& .MuiFormControlLabel-label": {
            fontFamily: "Poppins",
            fontSize: "14px",
          },
        },
      },

      "& .modal-action": {
        padding: "10px",
        flex: "0 0 30px",
        borderTop: "1px solid #DDD",
      },
    },
  })
);

interface SBMultiSelectWithGroupProps {
  onChange?: (value: any) => void;
  options?: object[];
  value?: any;
  groupBy?: string;
  fieldLabel?: string;
  placeHolder?: string;
}

const SBMultiSelectWithGroup: React.FC<SBMultiSelectWithGroupProps> = ({
  onChange,
  groupBy = "countryId",
  options = [],
  value = [],
  fieldLabel = "Provider(s)",
  placeHolder = "Select Provider(s)",
}) => {
  const classes = useStyles();
  const [selection, setSelection] = useState<any>({});
  const [filteredOptions, setFilteredOptions] = useState<object[]>([
    ...options,
  ]);
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);

  useEffect(() => {
    const selectionMap: any = {};
    if (groupBy) {
      const groupedOptions = _.groupBy(
        filteredOptions,
        (option: any) => option[groupBy]
      );
      _.keys(groupedOptions).forEach((group: any) => {
        selectionMap[`G${group}`] = false;
        groupedOptions[group].forEach((option: any) => {
          selectionMap[`O${option.id}`] = false;
        });
      });
    } else {
      options.forEach((option: any) => {
        selectionMap[`O${option.id}`] = false;
      });
    }
    setSelection(selectionMap);
  }, [options]);

  useEffect(() => {
    // console.log("value ", value);
    if (value.length > 0) {
      const opt: any = {};
      value.forEach((val: any) => {
        if (val.providerId) {
          opt[`O${val.providerId}`] = true;
        } else if (val.countryId) {
          opt[`G${val.countryId}`] = true;
          options
            .filter((o: any) => o[groupBy] == val.countryId)
            .forEach((o: any) => {
              opt[`O${o.id}`] = true;
            });
        }
      });
      setSelection((oldSelection: any) => {
        return {
          ...oldSelection,
          ...opt,
        };
      });
    }
  }, [value]);

  const renderValues = () => {
    if (value && value.length > 0) {
      const groupNames: any = {};
      const childNames: any = {};
      options.forEach((option: any) => {
        groupNames[option.countryId] = option.countryName;
        childNames[option.id] = option.value;
      });
      return value.map((val: any) => {
        if (val.providerId) {
          return (
            <div className="chip" key={`O${val.providerId}`}>
              {childNames[val.providerId] || val.providerId}
            </div>
          );
        } else if (val.countryId) {
          return (
            <div
              className="chip"
              key={`G${val.countryId}`}
              style={{ fontWeight: 600 }}
            >
              {groupNames[val.countryId] || val.countryId} (All)
            </div>
          );
        }
      });
    } else {
      return (
        <div
          style={{ color: "#5C707C", fontSize: "14px", marginBottom: "4px" }}
        >
          {placeHolder}
        </div>
      );
    }
  };

  const handleOptionsModalClose = () => {
    setOptionsOpen(false);
  };

  const getGroupedOptions = () => {
    if (groupBy) {
      const groupedOptions: any = _.groupBy(
        filteredOptions,
        (option: any) => option[groupBy]
      );
      const gOptions: any[] = [];
      _.keys(groupedOptions).forEach((group: any) => {
        gOptions.push(
          <div
            key={`G${group}`}
            style={{
              backgroundColor: "#F4F6F8",
              flex: "1 1 100%",
              padding: "7px 10px",
              margin: "15px 0 0 0",
              fontWeight: 500,
            }}
          >
            <FormControlLabel
              className="group-selector"
              control={
                <Checkbox
                  disableRipple
                  checked={selection[`G${group}`]}
                  onChange={handleGroupOptionSelection}
                  name={`G${group}`}
                  value={group}
                  color="primary"
                />
              }
              label={groupedOptions[group][0]["countryName"]}
            />
          </div>
        );
        groupedOptions[group].forEach((option: any) =>
          gOptions.push(getOption(option))
        );
      });
      return gOptions;
    } else {
      return options.map((option: any) => getOption(option));
    }
  };

  const getOption = (option: any) => {
    return (
      <div
        key={`O${option.id}`}
        style={{ flex: "1 1 50%", padding: "5px 10px", fontSize: "14px" }}
      >
        <FormControlLabel
          className="provider-selector"
          control={
            <Checkbox
              disableRipple
              checked={selection[`O${option.id}`]}
              onChange={handleOptionSelection}
              name={`O${option.id}`}
              value={JSON.stringify(option)}
              color="primary"
            />
          }
          label={option.value}
        />
      </div>
    );
  };

  const handleGroupOptionSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const groupOptions: any = {};
    options
      .filter((o: any) => o[groupBy] == event.target.value)
      .forEach((o: any) => {
        groupOptions[`O${o.id}`] = event.target.checked;
      });
    setSelection({
      ...selection,
      ...groupOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleOptionSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    const option = JSON.parse(event.target.value);
    const groupKey = `G${option[groupBy]}`;

    const updates = {
      ...selection,
      [event.target.name]: checked,
    };

    //Uncheck group selection when any of child gets unselected
    if (!checked) {
      updates[groupKey] = checked;
    }

    setSelection(updates);
  };

  const onSave = () => {
    setOptionsOpen(false);
    // console.log("selection ", selection);

    const optionsGroupMap: any = {};
    if (groupBy) {
      const groupedOptions = _.groupBy(
        filteredOptions,
        (option: any) => option[groupBy]
      );
      _.keys(groupedOptions).forEach((group: any) => {
        groupedOptions[group].forEach((option: any) => {
          optionsGroupMap[`O${option.id}`] = `G${group}`;
        });
      });
    }

    // Ignore child options if group is selected
    const selectedOptions: any = [];
    Object.keys(selection).forEach((option: string) => {
      if (selection[option]) {
        const groupSelection = selection[optionsGroupMap[option]];
        if (!groupSelection) {
          selectedOptions.push(getSelectedOutput(option));
        }
      }
    });

    // console.log("selectedOptions ", selectedOptions);
    onChange && onChange(selectedOptions);
  };

  const getSelectedOutput = (option: string) => {
    if (option.startsWith("G")) {
      const groupId = option.substring(1);
      return { countryId: groupId, providerId: null };
    } else if (option.startsWith("O")) {
      const childId = option.substring(1);
      return { countryId: null, providerId: childId };
    }
  };

  return (
    <div>
      {fieldLabel ? <SBText text={fieldLabel} color={"#736F6F"} /> : null}

      <div className={classes.summaryBox}>
        <div style={{ flex: "1" }}>{renderValues()}</div>
        <EditIcon
          style={{ flex: "0 0 20px", cursor: "pointer", marginTop: "-2px" }}
          onClick={(e) => setOptionsOpen(true)}
        />
      </div>

      <Modal
        open={optionsOpen}
        // onClose={handleOptionsModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={classes.paper}>
          <div className="modal-title">
            <span>Select {fieldLabel}</span>
            <HighlightOffRounded
              onClick={() => handleOptionsModalClose()}
              style={{ marginLeft: 10, color: AppColor.appWhite }}
            />
          </div>
          <div className="modal-body">
            <SBSearch
              placeHolder="Search Provider"
              onSearch={(searchText: string) => {
                let searchBy = searchText.toLowerCase();
                setFilteredOptions(
                  options.filter((option: any) =>
                    option.value.toLowerCase().includes(searchBy)
                  )
                );
              }}
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              {getGroupedOptions()}
            </div>
          </div>
          <div className="modal-action">
            <SBButton icon={false} text="Save" onClick={onSave} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SBMultiSelectWithGroup;
function handleOptionsModalClose(): void {
  throw new Error("Function not implemented.");
}
