import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionTypes,
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../actions";
import config from "../../../config";

export const useFetchAgentContractData = () => {
  const dispatch = useDispatch();

  const fetchAgentContractData = useCallback(
    async (agentID) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(
          `${config.BASE_URL}/agent/contract/${agentID}`,
          dispatch
        );
        dispatch({
          type: ActionTypes.SET_AGENT_CONTRACT_DETAILS,
          payload: result,
        });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    fetchAgentContractData,
  };
};
