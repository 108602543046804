import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Link,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import SBText from "../../../components/base/SBText";
import StatusChip from "../../../components/StatusChip";
import { tableStyles } from "../../../styles/table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InfoIcon from "@material-ui/icons/Info";
import { LABELS } from "../../../util/labels";
import {
  LeadTableItem,
  LeadTableActionItem,
  LEAD_TABLE_ACTION,
} from "../types";
import { SBTooltip } from "../../../components/base/SBTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(tableStyles(theme))
);
interface Props {
  leadList: LeadTableItem[];
  onLeadActionClick: (lead: LeadTableItem, action: LEAD_TABLE_ACTION) => void;
  onViewLead: (lead: LeadTableItem) => void;
  handleScrollEnd: () => void;
}

const LeadsTable: React.FC<Props> = ({
  leadList,
  onLeadActionClick,
  handleScrollEnd,
  onViewLead,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const showActionColumn = false;
  const capitalizedCellClassName = `${classes.bodyText}  ${classes.capitalize}`;
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const handleViewClick = (lead: LeadTableItem) => {
    onViewLead(lead);
  };

  const handleActionClick = (
    e: any,
    lead: LeadTableItem,
    action: LEAD_TABLE_ACTION
  ) => {
    e.stopPropagation();
    onLeadActionClick(lead, action);
  };

  const handleScroll = (e: any) => {
    let element = e.target;
    if (
      isMobileView &&
      element.scrollHeight - Math.round(element.scrollTop) ===
        element.clientHeight
    ) {
      handleScrollEnd();
    }
  };

  return (
    <>
      <div className={classes.mainLayout}>
        <div className={classes.tableParents}>
          <TableContainer onScroll={handleScroll} component={Paper}>
            <Table className={classes.table} stickyHeader>
              <TableHead className={classes.TableRow}>
                {isMobileView ? (
                  <TableRow>
                    <TableCell align="left">
                      <SBText
                        text={LABELS.APPLICANT_NAME}
                        className={classes.HeaderPadding}
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell align="left">
                      <SBText
                        text={LABELS.APPLICANT_NAME}
                        className={classes.HeaderPadding}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={LABELS.EMAIL}
                        className={classes.HeaderPadding}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={LABELS.PHONE_NUMBER}
                        className={classes.HeaderPadding}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={LABELS.CITY}
                        className={classes.HeaderPadding}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={LABELS.COUNTRY}
                        className={classes.HeaderPadding}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={LABELS.PREFERRED_COUNTRY}
                        className={classes.HeaderPadding}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={LABELS.PREFERRED_COURSE}
                        className={classes.HeaderPadding}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={LABELS.STATUS}
                        className={classes.HeaderPadding}
                      />
                    </TableCell>
                    {showActionColumn && (
                      <TableCell align="left">
                        <SBText
                          text={LABELS.ACTIONS}
                          className={classes.HeaderPadding}
                        />
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      className={classes.padding}
                    ></TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {leadList.map((item: LeadTableItem) => {
                  
                    return isMobileView ? (
                      <TableRow
                        onClick={() => handleViewClick(item)}
                        key={item.id}
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <TableCell
                          component="th"
                          className={classes.applicantCol}
                        >
                          <div
                            className={classes.statusRenderer}
                            style={{
                              backgroundColor: item.statusViewDetails.color,
                            }}
                          ></div>
                          <SBText
                            text={item.name}
                            style={{
                              paddingLeft: "18px",
                            }}
                            className={capitalizedCellClassName}
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        onClick={(e: any) => {}}
                        key={item.id}
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <TableCell component="th">
                          <SBText
                            text={item.name}
                            className={capitalizedCellClassName}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <SBText
                            text={item.emailId}
                            className={classes.bodyText}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <SBText
                            text={item.phoneNumber}
                            className={classes.bodyText}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <SBText
                            text={item.applicantCity}
                            className={capitalizedCellClassName}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <SBText
                            text={item.applicantCountry}
                            className={capitalizedCellClassName}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <SBText
                            text={item.preferredCountry}
                            className={capitalizedCellClassName}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <SBText
                            text={item.preferredCourse}
                            className={capitalizedCellClassName}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <StatusChip
                            item={item.statusViewDetails}
                            className={classes.bodyText}
                          />
                        </TableCell>
                        {showActionColumn && (
                          <TableCell align="left">
                            {item.actionLinks.map(
                              (actionItem: LeadTableActionItem) => (
                                <Link
                                  key={actionItem.action}
                                  component="button"
                                  variant="body2"
                                  onClick={(e: any) =>
                                    handleActionClick(
                                      e,
                                      item,
                                      actionItem.action
                                    )
                                  }
                                >
                                  {actionItem.label}
                                </Link>
                              )
                            )}
                          </TableCell>
                        )}
                        <TableCell align="left" className={classes.padding}>
                          <div className={classes.rowActions}>
                            <SBTooltip
                              title={`Additional Comment: ${
                                item.comment || "NA"
                              }`}
                            >
                              <div className={classes.inlineAction}>
                                <InfoIcon />
                              </div>
                            </SBTooltip>

                            <SBTooltip title="Details">
                              <div
                                className={classes.inlineAction}
                                onClick={(event) => handleViewClick(item)}
                              >
                                <VisibilityIcon />
                              </div>
                            </SBTooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default LeadsTable;
