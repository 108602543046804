import { SvgIcon } from "@material-ui/core";
import React from "react";

export function EnglishIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 57 57" fill="none">
      <g opacity="0.6">
        <path
          d="M46.75 5H8.75C6.1375 5 4 7.1375 4 9.75V52.5L13.5 43H46.75C49.3625 43 51.5 40.8625 51.5 38.25V9.75C51.5 7.1375 49.3625 5 46.75 5ZM46.75 38.25H11.5288L8.75 41.0288V9.75H46.75V38.25Z"
          fill="#3D405B"
        />
        <path
          d="M19.012 27.454H15.344L14.756 29.19H12.25L15.806 19.362H18.578L22.134 29.19H19.6L19.012 27.454ZM18.396 25.606L17.178 22.008L15.974 25.606H18.396Z"
          fill="#3D405B"
        />
        <path
          d="M29.9163 24.15C30.4856 24.2713 30.9429 24.556 31.2883 25.004C31.6336 25.4427 31.8063 25.9467 31.8063 26.516C31.8063 27.3373 31.5169 27.9907 30.9383 28.476C30.3689 28.952 29.5709 29.19 28.5443 29.19H23.9663V19.362H28.3903C29.3889 19.362 30.1683 19.5907 30.7283 20.048C31.2976 20.5053 31.5823 21.126 31.5823 21.91C31.5823 22.4887 31.4283 22.9693 31.1203 23.352C30.8216 23.7347 30.4203 24.0007 29.9163 24.15ZM26.3603 23.338H27.9283C28.3203 23.338 28.6189 23.254 28.8243 23.086C29.0389 22.9087 29.1463 22.652 29.1463 22.316C29.1463 21.98 29.0389 21.7233 28.8243 21.546C28.6189 21.3687 28.3203 21.28 27.9283 21.28H26.3603V23.338ZM28.1243 27.258C28.5256 27.258 28.8336 27.1693 29.0483 26.992C29.2723 26.8053 29.3843 26.5393 29.3843 26.194C29.3843 25.8487 29.2676 25.578 29.0343 25.382C28.8103 25.186 28.4976 25.088 28.0963 25.088H26.3603V27.258H28.1243Z"
          fill="#3D405B"
        />
        <path
          d="M33.5388 24.262C33.5388 23.2913 33.7488 22.428 34.1688 21.672C34.5888 20.9067 35.1721 20.314 35.9188 19.894C36.6748 19.4647 37.5288 19.25 38.4808 19.25C39.6474 19.25 40.6461 19.558 41.4768 20.174C42.3074 20.79 42.8628 21.63 43.1428 22.694H40.5108C40.3148 22.2833 40.0348 21.9707 39.6708 21.756C39.3161 21.5413 38.9101 21.434 38.4528 21.434C37.7154 21.434 37.1181 21.6907 36.6608 22.204C36.2034 22.7173 35.9748 23.4033 35.9748 24.262C35.9748 25.1207 36.2034 25.8067 36.6608 26.32C37.1181 26.8333 37.7154 27.09 38.4528 27.09C38.9101 27.09 39.3161 26.9827 39.6708 26.768C40.0348 26.5533 40.3148 26.2407 40.5108 25.83H43.1428C42.8628 26.894 42.3074 27.734 41.4768 28.35C40.6461 28.9567 39.6474 29.26 38.4808 29.26C37.5288 29.26 36.6748 29.05 35.9188 28.63C35.1721 28.2007 34.5888 27.608 34.1688 26.852C33.7488 26.096 33.5388 25.2327 33.5388 24.262Z"
          fill="#3D405B"
        />
      </g>
    </SvgIcon>
  );
}
