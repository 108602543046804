import { getLPOTableActions } from ".";
import { LPO, LPOStatusDetails, LPOTableItem } from "../types";

export const mapLPODataToTableViewItem = (lpo: LPO): LPOTableItem => {
  return {
    ...lpo,
    name: `${lpo.firstName} ${lpo.lastName}`,
    statusViewDetails: LPOStatusDetails[lpo.status],
    actionLinks: getLPOTableActions(lpo.status),
  };
};
