import React, { useEffect, useState } from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import TextField from '@material-ui/core/TextField';

const theme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: '1px solid',
          borderColor: '#D5D4DC',
        },
        '&:focused:not($disabled):not($hover):not($error) $notchedOutline': {
          border: '2px solid',
          borderColor: '#0F62FE',
        },
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginTop: '20px',
      padding: '0 10%',
    },
    sectionHeading: {
      color: '#5C5F74',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 700,
      marginBottom: '5px',
    },
    sectionBody: {
      width: '100%',
    },
    helperText: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      fontWeight: 400,
      color: '#C83532',
      backgroundColor: '#ffffff',
      width: '100%',
      margin: '0',
      paddingTop: '8px',
    },
    textbox: {
      width: '100%',
      //   marginTop: "8px",
      marginBottom: '8px',
    },
    textboxError: {
      width: '100%',
      marginTop: '8px',
      marginBottom: '8px',
      backgroundColor: '#FBEFEF',
    },
    input: {
      border: '4px solid white',
      backgroundColor: 'white',
      '&::placeholder': {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: 400,
        color: '#8B849B',
      },
      '&:hover': {
        backgroundColor: '#F5F8FF',
      },
    },
    addRecipentButton: {
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: 400,
      color: '#8B849B',
      cursor: 'pointer',
    },
  })
);

interface AddRecepientsProps {
  getRecepients: any;
  primaryEmailId: string;
}

const AddRecepients: React.FC<AddRecepientsProps> = ({ getRecepients, primaryEmailId }) => {
  const classes = useStyles();

  const [primaryMailId, setPrimaryMailId] = useState(primaryEmailId);
  const [secondaryMailId, setSecondaryMailId] = useState('');

  const handlePrimaryRecepientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrimaryMailId(event.target.value);
  };

  const handleSecondaryRecepientChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecondaryMailId(event.target.value);
    getRecepients(primaryMailId + ',' + event.target.value);
  };

  // useEffect(() => {
  //   return () => {
  //     getRecepients(primaryMailId + "," + secondaryMailId);
  //   };
  // }, [primaryMailId, secondaryMailId]);

  return (
    <>
      <div className={classes.section}>
        <div className={classes.sectionHeading}>Primary Recipient</div>
        <div className={classes.sectionBody}>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12} xl={12} lg={12}>
              <MuiThemeProvider theme={theme}>
                <TextField
                  id="outlined-full-width"
                  placeholder="agent@email.com"
                  className={true ? classes.textboxError : classes.textbox}
                  fullWidth
                  value={primaryMailId}
                  margin="normal"
                  autoComplete="off"
                  onChange={handlePrimaryRecepientChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                    style: {
                      borderRadius: '10px',
                    },
                    classes: { input: classes.input },
                  }}
                  variant="outlined"
                  error={false}
                  helperText=""
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </MuiThemeProvider>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.sectionHeading}>Additional Recipient</div>
        <div className={classes.sectionBody}>
          <Grid container spacing={1}>
            <Grid item md={4} xs={12} xl={12} lg={12}>
              <MuiThemeProvider theme={theme}>
                <TextField
                  id="outlined-full-width"
                  placeholder="agent@email.com"
                  className={true ? classes.textboxError : classes.textbox}
                  fullWidth
                  margin="normal"
                  value={secondaryMailId}
                  onChange={handleSecondaryRecepientChange}
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '10px',
                    },
                    classes: { input: classes.input },
                  }}
                  variant="outlined"
                  error={false}
                  helperText="Optional"
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              </MuiThemeProvider>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* <div className={classes.section}>
        <div className={classes.addRecipentButton}>
          <AddCircleOutlineOutlinedIcon style={{ fontSize: "14px" }} />
          <span>Add another recipient</span>
        </div>
      </div> */}
    </>
  );
};

export default AddRecepients;
