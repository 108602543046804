import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import SBText from "../../../../../components/base/SBText";
import AppColor from "../../../../../theme/AppColor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterBtn: {
      width: 100,
      height: 40,
      marginLeft: 10,
      borderWidth: 1,
      borderStyle: "solid",
      backgroundColor: "white",
      borderColor: AppColor.appLightGrey,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
  })
);

const Filter = (props: any) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.filterBtn}
      onClick={() => props.onFilterButtonClick()}
    >
      <FilterListIcon />
      <SBText text={"Filter"} style={{ marginLeft: 10 }} />
    </Button>
  );
};

export default Filter;
