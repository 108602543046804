import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
  post,
} from "../../../actions";
import { API_URL } from "../../../util/apiUrls";
import { ReportFilterType } from "../types";

export const useGenerateReport = () => {
  const dispatch = useDispatch();

  const generateReport = useCallback(
    async (
      type: string,
      reportName: string,
      reportFilter: ReportFilterType
    ) => {
      return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
          const filter = {
            ...reportFilter,
            startDate: `${reportFilter.startDate} 00:00:00`,
            endDate: `${reportFilter.endDate} 23:59:59`,
          };
          try {
            const result = await post(
              API_URL.REPORTS.GENERATE_REPORT(type),
              { reportFilter: filter, reportName },
              dispatch
            );
            resolve(result);
          } catch (e) {
            reject(e);
          }
        }
      );
    },
    [dispatch]
  );

  return {
    generateReport,
  };
};
