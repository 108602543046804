// @ts-nocheck
import { createSelector } from 'reselect';
import { RootState } from '../reducer';
import _ from 'lodash';

const applicationListDetails = (state: RootState) => state.sbApplication.applicationListDetails;

const dmsApplications = (state: RootState) => state.sbApplication.dmsCurrentAppDetails;

export const getFormattedApplicationList = createSelector(applicationListDetails, (applicationListDetails) => {
  const applicationList = [];
  // console.log('applicationListDetails', applicationListDetails);
  if (applicationListDetails) {
    const rows = applicationListDetails.rows;
    for (let i = 0; i < rows.length; i++) {
      // console.log('rows[i]', rows[i]);
      const appObj: any = {};
      appObj.id = rows[i].id;
      appObj.assignedUser = rows[i].assignedUserDetail;
      appObj.appName = rows[i].name;
      appObj.studentId = rows[i].studentId;
      appObj.applicantId= rows[i].applicantId;
      appObj.leadId = rows[i].leadId;
      appObj.createdUser = rows[i].createdUser;
      appObj.assignedByProvider = rows[i].assignedByProviderDetail;
      appObj.group = rows[i].groups;
      appObj.status = rows[i].status?.status;
      appObj.answers = getAnswerObj(rows[i].keyAnswers);
      appObj.updatedAt = rows[i].updatedAt;
      appObj.createdAt = rows[i].createdAt;
      appObj.outcomes = rows[i].outcomes;
      appObj.outcomesMasterList = rows[i].outcomesMasterList;
      appObj.appId = rows[i].id;
      appObj.isConditionalAllAnswered = rows[i].isConditionalAllAnswered;
      appObj.isUnConditionalAllAnswered = rows[i].isUnConditionalAllAnswered;
      appObj.isCOEAllAnswered = rows[i].isCOEAllAnswered;
      appObj.outcomesResult = rows[i].outcomesResult;
      appObj.files = rows[i].files;
      appObj.reason = rows[i].reason;
      appObj.lockStatus = rows[i].lockStatus;
      appObj.lockedDetails = rows[i].lockedDetails;
      appObj.deferred = rows[i].deferred ? rows[i].deferred : [];
      appObj.status = rows[i].status.status;
      appObj.subStatus = rows[i].status.subStatusObj?.name;
      applicationList.push(appObj);
    }
  }

  return applicationList;
});

export const getFormattedDMSApplicationList = createSelector(dmsApplications, (dmsApplication) => {

  const sortOrder = [
    'Registration',
    'Academic',
    'Work Exp',
    'Marriage',
    'English',
    'Sponsorship',
    'Sponsor',
    'Sponsor-1',
    'Sponsor-2',
    'Acceptance',
    'Completion',
    'Student Info',
    'Offer Letter',
    'Sponsorship (CAS) Block',
    'Visa',
    'Additional Documents'
  ];

  //FixME: Group name string check, This will skip new groups which are not in the above list
  const groupList = [];
  if (dmsApplication) {
    for (let i = 0; i < sortOrder.length; i++) {
      const groupObj = _.find(dmsApplication.groups, (obj: any) => obj.name == sortOrder[i]);
      if (groupObj) {
        groupList.push(groupObj);
      }
    }
  }

  return groupList;
});

export const getAnswerObj = (answers: any) => {
  // applicationId: 4
  // createdAt: "2021-01-01T17:51:13.056Z"
  // createdBy: null
  // id: 23
  // isActive: true
  // key: "Q_NAME"
  // updatedAt: "2021-01-01T17:51:13.056Z"
  // updatedBy: null
  // value: "Rahul"
  let answerValue: any = undefined;
  // console.log("answers", answers);
  if (answers && answers.length > 0) {
    const obj = {
      name: '-',
      destCountry: '-',
      courseLevel: '-',
      provider: '-',
      nationality: '-',
      campus: '-',
      intake: '-',
      course: '-',
      passport: '-',
    };
    // added new logic for firstname lastname changes

    const passportNumber = _.find(answers, (obj: any) => obj.key == 'Q_PASSPORT_NUMBER' && obj.value);
    const passport = _.find(answers, (obj: any) => obj.key == 'Q_PASSPORT' && obj.value);

    if (passportNumber) {
      obj.passport = passportNumber.value;
    }

    if (passport) {
      obj.passport = passport.value;
    }

    const firstName = _.find(answers, (obj: any) => obj.key == 'Q_FIRSTNAME');
    const lastName = _.find(answers, (obj: any) => obj.key == 'Q_LASTNAME');
    if (firstName && lastName) {
      let name = '';
      if (firstName.value) {
        name = firstName.value;
      }
      if (lastName.value) {
        if (name) {
          name = name + ' ' + lastName.value;
        } else {
          name = lastName.value;
        }
      }
      obj.name = name;
    } else {
      const fullName = _.find(answers, (obj: any) => obj.key == 'Q_NAME');
      if (fullName) {
        obj.name = fullName.value;
      }
    }

    for (let i = 0; i < answers.length; i++) {
      // if (answers[i].key == "Q_NAME") {
      //   obj.name = answers[i].value;
      // }
      if (answers[i].key == 'Q_DESTCOUNTRY') {
        obj.destCountry = answers[i].value;
      }
      if (answers[i].key == 'Q_COURSELEVEL') {
        obj.courseLevel = answers[i].value;
      }
      if (answers[i].key == 'Q_PROVIDER') {
        obj.provider = answers[i].value;
      }
      if (answers[i].key == 'Q_NATIONALITY') {
        obj.nationality = answers[i].value;
      }
      if (answers[i].key == 'Q_CAMPUS') {
        obj.campus = answers[i].value;
      }
      if (answers[i].key == 'Q_INTAKE') {
        obj.intake = answers[i].value;
      }
      if (answers[i].key == 'Q_COURSE') {
        obj.course = answers[i].value;
      }

      answerValue = obj;
    }
  }

  return answerValue;
};
