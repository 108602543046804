import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";

import ContractDetails from "./ContractDetails/ContractDetails";
import AttachContract from "./AttachContract/AttachContract";
import AddRecepients from "./AddRecepients/AddRecepients";
import Final from "./Final/FInal";

import { useSendContract } from "../../../Agent/hooks/useSendContract";

const mail_format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function getSteps() {
  return ["Contract Details", "Attach Contract", "Add Recipients"];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      position: "absolute",
      width: 596,
      backgroundColor: theme.palette.background.paper,
    },
    header: {
      width: "100%",
      backgroundColor: "#5C5F74",
      padding: "9px 28px",
      color: "white",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
    },
    body: {
      backgroundColor: "white",
      padding: "20px 0px",
    },
    sectionData: {
      marginTop: "5px",
    },
    extraPadding: {
      padding: "0 20px",
    },
    icon: {
      fontSize: "16px",
      cursor: "pointer",
      fontWeight: 500,
    },
    stepperRoot: {
      width: "100%",
    },
    footer: {
      marginTop: "20px",
      borderTop: "1px solid #EBEFF2",
      padding: "24px 20px",
    },
    footerContainer: {
      width: "100%",
    },
    filledButton: {
      backgroundColor: "#D27F65",
      color: "white",
      float: "right",
    },
    buttonContainer: {
      border: "1px solid #D27F65",
    },
    label: {
      textTransform: "none",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
      color: "#D27F65",
    },
    errorMessage: {
      color: "red",
      textAlign: "center",
      fontSize: "12px",
    },
  })
);

interface SendContractModalProps {
  agentId: number;
  emailId: string;
  onModalCloseClick: any;
}

const SendContractModal: React.FC<SendContractModalProps> = ({
  agentId,
  emailId,
  onModalCloseClick,
}) => {
  const classes = useStyles();
  const steps = getSteps();
  const { postSendContract } = useSendContract();

  const { sendContractReducer } = useSelector((state: any) => state);
  const formData = new FormData();
  const [currentStep, setCurrentStep] = useState(0);
  const [contractFile, setContractFile] = useState({});
  const [annexureFile, setAnnexureFile] = useState<any>([]);
  const [contractStartDate, setContractStartDate] = useState(new Date());
  const [contractEndDate, setContractEndDate] = useState(new Date());
  const [isAutoRenew, setIsAutoRenew] = useState(false);
  const [recepientsMailIds, setRecepientsMailIds] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleStepperClick = (stepNo: number) => () => {
    setCurrentStep(stepNo);
  };

  const handleNextClick = () => {
    const todaysDate = new Date();
    if (
      todaysDate.setHours(0, 0, 0, 0) >
        contractStartDate.setHours(0, 0, 0, 0) ||
      todaysDate.setHours(0, 0, 0, 0) > contractEndDate.setHours(0, 0, 0, 0) ||
      !contractEndDate ||
      !contractStartDate
    ) {
      setErrorMessage("Dates should not be less than todays date");
      return;
    }
    if (contractStartDate > contractEndDate) {
      setErrorMessage("End date should not be less than the start date");
      return;
    }
    if (
      ((Object.keys(contractFile).length === 0 &&
        contractFile.constructor === Object) ||
        (Object.keys(annexureFile).length === 0 &&
          annexureFile.constructor === Object)) &&
      currentStep == 1
    ) {
      setErrorMessage("Please attach contract and annexure files");
      return;
    }
    let primaryEmailId = recepientsMailIds.split(",")[0];
    let additionalEmailId = recepientsMailIds.split(",")[1];

    if (additionalEmailId && currentStep == 2) {
      if (!mail_format.test(additionalEmailId)) {
        setErrorMessage("not a valid email id");
        return;
      }
      if (primaryEmailId === additionalEmailId) {
        setErrorMessage("Additional email id cant be same as primary email id");
        return;
      }
    }
    setErrorMessage("");
    if (currentStep == 2) {
      formData.append("startDate", contractStartDate.toISOString().toString());
      formData.append("endDate", contractEndDate.toISOString().toString());
      formData.append("isAutoRenew", isAutoRenew == false ? "false" : "true");
      formData.append("recipients", recepientsMailIds);
      formData.append("contract", contractFile as any);
      annexureFile.forEach((fileEle: any, ind: number) => {
        formData.append("annexure", fileEle);
      });
      postSendContract(agentId, formData);
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleGetContractAttachment = (file: any) => {
    setContractFile(file);
  };

  const handleGetAnnexureAttachment = (file: any) => {
    setAnnexureFile((prevState: any) => [
      ...prevState, file
    ]);
  };

  const handleRemoveAnnexureAttachment = (index:  number) => {
    const currentState = [...annexureFile];
    currentState.splice(index, 1);
    setAnnexureFile(currentState);
  }

  const handleGetDates = (startDate: any, enddate: any, isAutoRenew: any) => {
    setContractStartDate(startDate);
    setContractEndDate(enddate);
    setIsAutoRenew(isAutoRenew);
  };

  useEffect(() => {
    if (sendContractReducer.contractSend.length !== 0)
      setCurrentStep(currentStep + 1);
  }, [sendContractReducer]);

  const handleGetRecepients = (recepientsArray: any) => {
    setRecepientsMailIds(recepientsArray);
  };

  const body = (
    <div className={classes.paper}>
      <div className={classes.header}>
        <Grid container spacing={1}>
          <Grid item md={4} xs={12} xl={6} lg={6}>
            Send Contract
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            xl={6}
            lg={6}
            style={{ textAlign: "right" }}
          >
            <CloseIcon
              className={classes.icon}
              onClick={() => onModalCloseClick()}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.body}>
        {currentStep < 3 && (
          <div className={classes.sectionData}>
            <div className={classes.stepperRoot}>
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton onClick={handleStepperClick(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </div>
          </div>
        )}

        <div className={classes.sectionData}>
          {currentStep == 0 && (
            <ContractDetails
              getDate={handleGetDates}
              startDateProps={contractStartDate}
              endDateProps={contractEndDate}
            />
          )}
          {currentStep == 1 && (
            <AttachContract
              getContractAttachment={handleGetContractAttachment}
              getAnnexureAttachment={handleGetAnnexureAttachment}
              contractAttachedFile={contractFile}
              removeAnnexureAttachment={handleRemoveAnnexureAttachment}
            />
          )}
          {currentStep == 2 && (
            <AddRecepients
              getRecepients={handleGetRecepients}
              primaryEmailId={emailId}
            />
          )}
          {currentStep == 3 && <Final emailId={recepientsMailIds} />}
        </div>
      </div>

      <div className={classes.footer}>
        <div className={classes.footerContainer}>
          <div className={classes.errorMessage}>{errorMessage}</div>
          <Grid container spacing={1}>
            <Grid
              item
              md={4}
              xs={12}
              xl={6}
              lg={6}
              style={{ textAlign: "left" }}
            >
              {currentStep !== 0 && currentStep !== 3 && (
                <Button
                  variant="outlined"
                  classes={{
                    root: classes.buttonContainer,
                    label: classes.label,
                  }}
                  onClick={handlePreviousClick}
                >
                  Back
                </Button>
              )}
            </Grid>
            <Grid
              item
              md={4}
              xs={12}
              xl={6}
              lg={6}
              style={{ textAlign: "right" }}
            >
              {currentStep !== 3 && (
                <Button
                  variant="contained"
                  className={classes.filledButton}
                  onClick={handleNextClick}
                >
                  Next
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        className={classes.modal}
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default SendContractModal;
