import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createBrowserHistory } from 'history';
import React from 'react';
import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider as StoreProvider } from 'react-redux';
import { Router } from 'react-router-dom';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import RouteComponent from './RouteComponent';
import { configureStore } from './store';
import { theme } from './theme';

const history = createBrowserHistory();
const store = configureStore();

function App() {
  return (
    <StoreProvider store={store}>
      <ThemeProvider theme={theme}>
        <StylesProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              <RouteComponent />
            </Router>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
