import React, { useState } from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SBButton from "../../../components/base/SBButton";
import AppColor from "../../../theme/AppColor";
import SBText from "../../../components/base/SBText";
import clsx from "clsx";
import SBSimpleFileSelector from "../../../components/base/SBSimpleFileSelector";
import { getApplicationIdInString } from "../../../util";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    cancelButton: {
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: "#FAFAFA",
      borderColor: AppColor.appBlue,
      borderWidth: 1,
      borderStyle: "solid",
      color: AppColor.appBlue,
    },
    requestButton: {
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 16,
      marginBottom: 10,
    },
    paper: { width: "450px", minHeight: 300 },
    checkButtonText: {
      marginLeft: 6,
      fontSize: 16,
      fontWeight: 600,
      color: AppColor.appBlue,
    },
    uncheckButton: { opacity: 0.5, height: 30, width: 30 },
    checkButton: { height: 30, width: 30 },
    checkedText: { color: "black" },
    arrow: {
      "&:before": {
        border: "0px solid #E6E8ED",
      },
      color: AppColor.appBlue,
    },
    tooltip: {
      backgroundColor: AppColor.appBlue,
      border: "0px solid #E6E8ED",
      color: "white",
      width: 120,
      textAlign: "center",
    },
    textAreaWidth: {
      border: "1px solid black",
      borderRadius: "5px",
      fontFamily: "Poppins",
    },
    label: {
      textAlign: "left",
      color: theme.palette.primary.light,
      display: "flex",
    },
  })
);

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
type LockApplicationActionDialogProps = {
  className?: string;
  open: boolean;
  onIntervention: () => void;
  onDecline: () => void;
  application: any;
  onSubmit: (
    applicationId: number,
    action: string,
    comments: string,
    file: any
  ) => void;
  onClose: () => void;
};
const LockApplicationActionDialog: React.FC<LockApplicationActionDialogProps> = ({
  open,
  onIntervention,
  onDecline,
  application,
  // setOpen,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState("");
  const [comments, setComments] = useState("");
  const [file, setFile] = useState(undefined);

  const [commentError, showCommentError] = useState(false);
  const [actionError, showActionError] = useState(false);
  const [fileError, showFileError] = useState(false);

  // console.log("Application Id ", application);

  const handleClose = () => {
    setFile(undefined);
    setComments("");
    setChecked("");
    showCommentError(false);
    showActionError(false);
    showFileError(false);
    onClose();
    // setOpen(false);
    // onSubmit();
  };

  const onSubmitAction = () => {
    if (!checked) {
      // alert("Please select your action");
      showActionError(true);
      return;
    }

    if (!comments) {
      // alert("Please enter your comments");
      showCommentError(true);
      return;
    }

    if (!file) {
      // alert("Please enter your comments");
      showFileError(true);
      return;
    }

    onSubmit(application.id, checked, comments, file);
    setFile(undefined);
    setComments("");
    setChecked("");
  };

  const onFileSelected = (fileObj: any) => {
    // console.log("fileObj", fileObj);
    setFile(fileObj);
  };

  const onDeleteFile = () => {
    setFile(undefined);
  };

  const getFileName = () => {
    if (file) {
      // @ts-ignore
      const fileName = file.name.split("#")[1];
      return fileName;
    } else {
      return "";
    }
  };

  const getPreparedFileName = () => {
    if (application) {
      const fileName = `${getApplicationIdInString(
        application.id
      )}_rejected_proof`;
      return fileName;
    } else {
      return `rejected_proof`;
    }
  };

  const getMessage = () => {
    // actionTaken: null
    // actionTakenUser: null
    // actionTakenUserId: null
    // applicationId: 118
    // comments: null
    // createdAt: "2021-06-20T20:29:19.154Z"
    // duplicateApplicationId: 109
    // id: 30
    // key: null
    // proofFileName: null
    // updatedAt: "2021-06-20T20:29:19.154Z"
    //
    if (application) {
      const message = `${
        application.createdUser.name
      } has trying to create application which has same passport with ${getApplicationIdInString(
        application.lockedDetails.duplicateApplicationId
      )}. Please review the application and take an appropriate intervention action.`;

      return message;
    } else {
      return "";
    }
  };

  const getUnlockHintMessage = () => {
    if (application) {
      const message = `This action will reject application  ${getApplicationIdInString(
        application.lockedDetails.duplicateApplicationId
      )}  and will allow this application ${getApplicationIdInString(
        application.id
      )} to proceed.`;

      return message;
    } else {
      return "";
    }
  };

  const getRejectHintMessage = () => {
    if (application) {
      const message = `This application  ${getApplicationIdInString(
        application.id
      )}  will rejected and there will be no impact on previous  application  ${getApplicationIdInString(
        application.lockedDetails.duplicateApplicationId
      )}.`;

      return message;
    } else {
      return "";
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontFamily: "poppins",
            letterSpacing: 0.15,
            fontWeight: 600,
          }}
        >
          {`${getApplicationIdInString(
            application ? application.id : -1
          )} is Locked !`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontFamily: "poppins", letterSpacing: 0.15 }}
          >
            {getMessage()}

            {/*Due to the duplicate student entry, this application is locked and*/}
            {/*under review by the support team. To follow up with the support*/}
            {/*team, please email at techsupport@startblocks.com*/}
          </DialogContentText>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "60%",
              alignSelf: "center",
            }}
          >
            {/*<Tooltip*/}
            {/*  title={*/}
            {/*    "Due to the duplicate student entry, this application is locked and\n" +*/}
            {/*    "            under review by the support team. To follow up with the support\n" +*/}
            {/*    "            team, please email at techsupport@startblocks.com"*/}
            {/*  }*/}
            {/*  arrow*/}
            {/*  classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}*/}
            {/*>*/}
            {/*  <Button onClick={() => setChecked("unlock")}>*/}
            {/*    {checked == "unlock" ? (*/}
            {/*      <CheckCircleIcon*/}
            {/*        fontSize={"large"}*/}
            {/*        className={classes.checkButton}*/}
            {/*      />*/}
            {/*    ) : (*/}
            {/*      <CheckCircleOutlineIcon*/}
            {/*        fontSize={"large"}*/}
            {/*        className={classes.uncheckButton}*/}
            {/*      />*/}
            {/*    )}*/}
            {/*    <SBText*/}
            {/*      text={"unlock"}*/}
            {/*      className={clsx(classes.checkButtonText, {*/}
            {/*        [classes.checkedText]: checked == "unlock", //only when open === true*/}
            {/*      })}*/}
            {/*    />*/}
            {/*  </Button>*/}
            {/*</Tooltip>*/}

            <Tooltip
              title={getUnlockHintMessage()}
              arrow
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            >
              <Button onClick={() => setChecked("override")}>
                {checked == "override" ? (
                  <CheckCircleIcon
                    fontSize={"large"}
                    className={classes.checkButton}
                  />
                ) : (
                  <CheckCircleOutlineIcon
                    fontSize={"large"}
                    className={classes.uncheckButton}
                  />
                )}
                <SBText
                  text={"Unlock"}
                  className={clsx(classes.checkButtonText, {
                    [classes.checkedText]: checked == "override", //only when open === true
                  })}
                />
              </Button>
            </Tooltip>

            <Tooltip
              title={getRejectHintMessage()}
              arrow
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
            >
              <Button onClick={() => setChecked("reject")}>
                {checked == "reject" ? (
                  <CheckCircleIcon
                    fontSize={"large"}
                    className={classes.checkButton}
                  />
                ) : (
                  <CheckCircleOutlineIcon
                    fontSize={"large"}
                    className={classes.uncheckButton}
                  />
                )}
                <SBText
                  text={"Reject"}
                  className={clsx(classes.checkButtonText, {
                    [classes.checkedText]: checked == "reject", //only when open === true
                  })}
                />
              </Button>
            </Tooltip>
          </div>

          <SBText
            text={"Please select your action"}
            style={{
              fontSize: 12,
              color: !checked && actionError ? "red" : "white",
            }}
          />

          <SBText
            text={"Comments*"}
            className={classes.label}
            style={{ marginTop: 10 }}
          />
          <TextField
            className={classes.textAreaWidth}
            id="outlined-multiline-static"
            variant="outlined"
            onChange={(event) => setComments(event.target.value)}
            value={comments}
            name="refNum"
            multiline
            rows={4}
            fullWidth={true}
          />
          <SBText
            text={"Please enter your comments"}
            style={{
              fontSize: 12,
              color: !comments && commentError ? "red" : "white",
            }}
          />

          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <SBSimpleFileSelector
              setUploadError={() => console.log("setUploadError ")}
              onFileSelected={(file) => onFileSelected(file)}
              onDeleteFile={() => onDeleteFile()}
              // size={"12"}
              value={getFileName()}
              onDownloadFile={() => console.log("onDownloadFile ")}
              fileNameExtension={getPreparedFileName()}
              labelText={"Upload Proof*"}
              showDownload={false}
            />
            <SBText
              text={"Please select proof as a attachment"}
              style={{
                fontSize: 12,
                color: !file && fileError ? "red" : "white",
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <SBButton
            icon={false}
            text={"Cancel"}
            onClick={() => handleClose()}
            className={classes.requestButton}
          />
          <SBButton
            icon={false}
            text={"Submit"}
            onClick={() => onSubmitAction()}
            className={classes.requestButton}
          />
          {/*<SBButton icon={false} text={"OK"} onClick={handleSubmit} />*/}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LockApplicationActionDialog;
