import { makeStyles, Theme, Tooltip } from "@material-ui/core";
import React from "react";

const useStylesTooltip = makeStyles((theme: Theme) => ({
  arrow: {
    color: "#000000"
    
  },
  tooltip: {
    backgroundColor: "#000000",
    fontSize:12, 
    width:120,
    textAlign:'center',
    fontFamily:'Poppins'
  },
}));

export function SBTooltip(props: any) {
  const classes = useStylesTooltip();
  return <Tooltip arrow classes={classes} {...props} />;
}
