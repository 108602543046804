import React from "react";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import SBText from "../base/SBText";
import SBButton from "../base/SBButton";
import close from "./assets/close_model.png";
import AppColor from "../../theme/AppColor";
import SBTextField from "../base/SBTextField";
// import logo from "./assets/atmc_logo.gif";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      height: "100vh",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "red",
      // border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      width: "50%",
      minWidth: 500,
      maxHeight: "90%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    headerParent: {
      height: 60,
      width: "100%",
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      justifyContent: "space-between",
    },
    headerText: {
      color: theme.palette.primary.contrastText,
      fontSize: 24,
      fontWeight: 500,
    },
    body: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll",
    },
    subjectText: {
      fontSize: 14,
      fontWeight: 700,
    },
    bodyText: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    },
    rowText: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    },
    tableView: {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#000000",
    },
    tableRow: {
      borderBottomColor: "#000000",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      height: 50,
      display: "flex",
    },
    tableTextRow: {
      display: "flex",
      flex: 0.6,
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableButtonRow: {
      display: "flex",
      flex: 0.4,
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableSmallDivider: { width: 1, backgroundColor: "#000000" },
    approveBt: {
      backgroundColor: "#8BB19C",
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    disApproveBt: {
      backgroundColor: "#8BB19C",
      height: 38,
      borderRadius: 20,
      flex: 1,
    },

    greyBt: {
      backgroundColor: "#979797",
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    okTOProceedBt: {
      backgroundColor: AppColor.appRed,
      height: 38,
      borderRadius: 20,
      width: 200,
      marginLeft: 30,
    },
    middleDivider: {
      width: theme.spacing(2),
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type SBCapetchModelProps = {
  className?: string;
  open: boolean;
  onConfirm: (
    dataObject: any,
    verificationResult: any,
    captcha: string
  ) => void;
  onCancel: () => void;
  dataObject: any;
  verificationResult: any;
};
const SBCapetchModel: React.FC<SBCapetchModelProps> = ({
  open,
  onConfirm,
  onCancel,
  verificationResult,
  dataObject,
}) => {
  const classes = useStyles();
  const [captcha, setCaptcha] = React.useState("");

  const okToProceed = () => {
    if (captcha) {
      setCaptcha("");
      onConfirm(dataObject, verificationResult, captcha);
    }
  };

  const handleClose = () => {
    setCaptcha("");
    onCancel();
  };

  const handleChange = (event: any) => {
    setCaptcha(event.target.value);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.headerParent}>
            <SBText
              text={"Please enter capetcha"}
              className={classes.headerText}
            />

            <img src={close} onClick={onCancel} />
          </div>
          <div
            style={{
              textAlign: "center",
              backgroundColor: "#E6E6E6",
              height: 80,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SBTextField
              placeholder={"Enter captcha visible in above image"}
              onChange={handleChange}
              value={captcha}
              onDataChange={() => console.log()}
            />

            </div>

          <div className={classes.body}>
            <img
              alt=""
              src={`data:image/jpeg;base64,${verificationResult.image}`}
              style={{ objectFit: "contain" }}
            />
          </div>

          <div
            style={{
              textAlign: "center",
              backgroundColor: "#E6E6E6",
              height: 80,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          > 
            <SBButton
              text={"Submit"}
              icon={false}
              onClick={() => okToProceed()}
              className={classes.okTOProceedBt}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default SBCapetchModel;
