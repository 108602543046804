import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducer";
import { ActionType, listPageSearchParam } from "../reducer/types";
import { API_URL } from "../util/apiUrls";
import { post } from "./common";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";

export const FETCH_ALL_PROVIDERS_SUCCESS = "FETCH_ALL_PROVIDERS_SUCCESS";
export const FETCH_PROVIDERS_SUCCESS = "FETCH_PROVIDERS_SUCCESS";

export const getAllProviders = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch, getState) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });
      const result = await post(API_URL.PROVIDER.LIST, {}, dispatch);
      await dispatch({
        type: FETCH_ALL_PROVIDERS_SUCCESS,
        payload: result,
      });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    }
  );
};

export const getProviderList = (
  listParams: listPageSearchParam
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  const { offset } = listParams;
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      dispatch({ type: ON_LOADING_START });
      const result = await post(API_URL.PROVIDER.LIST, listParams, dispatch);
      await dispatch({
        type: FETCH_PROVIDERS_SUCCESS,
        payload: result,
      });
      resolve(result);
      dispatch({ type: ON_LOADING_FINISHED });
    }
  );
};
