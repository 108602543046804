import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Link,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { createStyles, makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory } from 'react-router';
import { deferApplication, downloadOutComes } from '../../../actions';
import SBButton from '../../../components/base/SBButton';
import SBText from '../../../components/base/SBText';
import SBDefermentDetailsDialog from '../../../components/SBDefermentDetailsDialog';
import { RootState } from '../../../reducer';
import AppColor from '../../../theme/AppColor';
import { encryptText, getApplicationIdInString } from '../../../util';
import DeferedApplicationDialog from '../../SBApplication/DeferedApplicationDialog';
import ApplicationStatusStepper from '../ApplicationStatusStepper';
import rejectedIcon from '../assets/rejectedIcon.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameCircle: {
      borderRadius: 50,
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 40,
      width: 40,
      minWidth: 40,
      [theme.breakpoints.up(1920)]: {
        // height: 70,
        // width: 70,
      },
    },
    circleText: { color: 'white', fontWeight: 'bold' },
    name: { color: 'black', fontWeight: 'bold' },
    appId: { color: '#898989', marginTop: 10 },
    headerTextParent: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20,
      [theme.breakpoints.up(1440)]: {
        // fontSize: 14,
      },
    },

    cardContent: {
      display: 'flex',
      height: 'scroll',
      flexDirection: 'column',
      padding: 0,
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    statusView: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      flex: 1,
    },
    statusText: {
      color: '#676666',
      marginTop: 10,
    },
    stepperView: {
      display: 'flex',
      alignItems: 'center',
    },

    bottomBtParent: {
      display: 'flex',
      height: 40,
      width: 70,
      backgroundColor: '#E5E5E5',
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },

    bottomBtParentDisabled: {
      display: 'flex',
      height: 40,
      width: 70,
      backgroundColor: '#E5E5E5',
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0.5,
    },

    btIcon: { objectFit: 'contain' },
    btDownloadIcon: { objectFit: 'contain', marginLeft: 5, cursor: 'pointer' },

    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: 10,
      [theme.breakpoints.up(1920)]: {},
    },
    cardStatus: {
      height: 'auto',
      display: 'flex',
      flexDirection: 'row',
      padding: '10px',
    },

    textView: {
      flex: 0.2,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingLeft: 16,
      paddingRight: 16,
    },
    bottomView: {
      height: 60,
      width: '100%',
      display: 'flex',
      padding: 16,
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up(1920)]: {
        height: 70,
      },
    },
    viewBt: {
      height: 30,
      borderRadius: 30,
      width: 80,
    },
    card: {
      paddingBottom: 0,
      borderColor: '#A9A9A9',
      borderRadius: 5,
      height: 'scroll',
    },
    divider: { backgroundColor: '#A9A9A9' },
    levelText: {
      fontWeight: 500,
      fontSize: 18,
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.up(1920)]: {
        // fontSize: 40,
      },
    },
    studentName: {
      fontWeight: 600,
      fontSize: 18,
      [theme.breakpoints.up(1920)]: {
        // fontSize: 20,
      },
    },
    studentApplicationID: {
      color: '#898989',
      fontSize: 13,
      marginTop: 8,
      lineHeight: '20px',
      [theme.breakpoints.up(1920)]: {},
    },
    infoText: {
      fontSize: 12,
    },
    timerImg: {
      height: 60,
      [theme.breakpoints.up(1920)]: {
        height: 80,
      },
    },
    buttonStyle: {
      height: 30,
      backgroundColor: AppColor.appWhite,
      textTransform: 'none',
      marginRight: 10,
      borderRadius: 15,
      color: AppColor.appBlue,
      borderWidth: 1,
      borderColor: AppColor.appBlue,
      borderStyle: 'solid',
      '&:hover': {
        backgroundColor: '#F8F8F8',
      },
    },
    buttonDisabledStyle: {
      height: 30,
      backgroundColor: '#E0E0E0',
      textTransform: 'none',
      marginRight: 10,
      borderRadius: 15,
      color: AppColor.appBlue,
    },
  })
);

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#4c4c4c',
  },
  tooltip: {
    backgroundColor: '#4c4c4c',
  },
}));

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type ApplicationCardProps = PropsFromRedux & {
  data: any;
  selectedTab: string;
  onShowReasonDetails: (app: any) => void;
};

const ApplicationCard: React.FC<ApplicationCardProps> = ({
  data,
  downloadOutComes,
  selectedTab,
  onShowReasonDetails,
  deferApplication,
  ...rest
}) => {
  const history = useHistory();
  const classes = useStyles();

  // console.log("Arest", window.innerWidth);
  // console.log("data", data);
  const [deferDetailDialog, showDeferDetailDialog] = React.useState(false);
  const [deferDetailsApplication, setDeferDetailsApplication] = React.useState(undefined);

  const [deferApplicationDialog, showDeferApplicationDialog] = React.useState(false);

  const outcomes = data.outcomes;

  const unConditionalOfferLetter = _.find(
    outcomes,
    (obj: any) => obj.outcome == 'UnConditionalOffer' && !obj.isDeleted
  );

  const conditional = _.find(outcomes, (obj: any) => obj.outcome == 'ConditionalOffer' && !obj.isDeleted);

  const COE = _.find(outcomes, (obj: any) => obj.outcome == 'IssueOfCOE' && !obj.isDeleted);

  // const isUnConditionalOfferLetterReceived =

  const onDownloadOutComes = (outCome: any) => {
    console.log('outCome', outCome);
    if (outCome) {
      downloadOutComes(outCome);
    }
  };

  const getLevel = () => {
    if (conditional && unConditionalOfferLetter && COE) {
      return 3;
    }

    if (conditional && unConditionalOfferLetter && !COE) {
      return 2;
    }

    if (conditional && !unConditionalOfferLetter && !COE) {
      return 1;
    }

    return 0;
  };

  const onDeferredApplication = async (
    deferredTo: string,
    status: string,
    comments: string,
    date: string,
    applicationId: number
  ) => {
    const body = {
      deferredTo,
      status,
      comments,
      applicationId,
      date,
    };

    await deferApplication(body);
  };

  const isDeferredDisplay = () => {
    if (!data.deferred || data.deferred.length == 0) {
      return true;
    } else {
      let deferredList = _.cloneDeep(data.deferred);
      deferredList = _.sortBy(deferredList, function (dateObj) {
        return new Date(dateObj.createdAt);
      }).reverse();
      if (!deferredList[0].approvedStatus) {
        return false;
      } else {
        return true;
      }
    }
  };

  const getOutcomeMasterArray = (application: any) => {
    let outcomes = application.outcomesMasterList;
    outcomes = _.sortBy(outcomes, (obj) => obj.outcomeSequence);
    return outcomes;

    // 0: {name: 'IssueOfCOE', code: 'IssueOfCOE', outcomePercentage: '100', outcomeSequence: '3', outcomeLabel: 'COE'}
    // 1: {name: 'UnConditionalOffer', code: 'UnConditionalOffer', outcomePercentage: '85', outcomeSequence: '2', outcomeLabel: 'Offer'}
    // 2: {name: 'ConditionalOffer', code: 'ConditionalOffer', outcomePercentage: '25', outcomeSequence: '1', outcomeLabel: 'C-Offer'}
  };

  const getApplicationSubHeader = () => {
    if (selectedTab == 'Current' && data.deferred.length > 0) {
      // console.log("Data::", data.deferred);
      const deferredArray = _.sortBy(data.deferred, (dateObj) => {
        return dateObj.id;
      }).reverse();
      // console.log("deferredArray::", deferredArray);
      // console.log("nextTerm");
      let nextTerm = deferredArray[0].nextTerm;
      let tooltipText = '';
      let backgroundColor = '';
      if (!deferredArray[0].approvedStatus) {
        backgroundColor = AppColor.appLightGrey;
        tooltipText = `Deferment is in progress for term ${nextTerm}`;
      } else if (deferredArray[0].approvedStatus == 'Approve') {
        backgroundColor = AppColor.appGreen;
        tooltipText = `Application is Deferred for ${nextTerm}`;
      } else {
        backgroundColor = AppColor.appRed;
        tooltipText = `Application deferment rejected for ${nextTerm}`;
      }

      // const deferrred = data.

      return (
        <Typography className={classes.studentApplicationID} variant={'overline'}>
          {`${isDataAvailable ? getApplicationIdInString(data.appId) : '-'} | ${
            isDataAvailable ? data.answers.destCountry : '-'
          } | ${isDataAvailable ? data.answers.provider : '-'} | ${isDataAvailable ? data.answers.intake : '-'}`}
          <div
            style={{
              marginLeft: 7,
              backgroundColor: backgroundColor,
              width: 20,
              height: 20,
              display: 'inline',
              paddingLeft: 6,
              paddingRight: 4,
              paddingTop: 2,
              paddingBottom: 2,
              borderRadius: 10,
            }}
            onClick={() => showDeferDetailDialog(true)}
          >
            <Tooltip title={tooltipText}>
              <Link style={{ color: 'white' }}>D</Link>
            </Tooltip>
          </div>
        </Typography>
      );
    } else {
      return (
        <SBText
          text={`${isDataAvailable ? getApplicationIdInString(data.appId) : '-'} | ${
            isDataAvailable ? data.answers.destCountry : '-'
          } | ${isDataAvailable ? data.answers.provider : '-'} | ${isDataAvailable ? data.answers.intake : '-'}`}
          className={classes.studentApplicationID}
          variant={'overline'}
        />
      );
    }
  };

  // console.log("Data::", data);

  const isDataAvailable = data && data.answers ? true : false;
  const isCanDefer = isDeferredDisplay();


  return (
    <Grid item md={6} xs={12} sm={12} lg={4}>
      <Card {...rest} className={classes.card} variant={'outlined'}>
        <CardContent className={classes.cardContent}>
          <div className={classes.cardHeader}>
            <div className={classes.nameCircle}>
              <SBText text={'L' + getLevel()} className={classes.levelText} />
            </div>
            <div className={classes.headerTextParent}>
              <SBText text={isDataAvailable ? data.answers.name : '-'} className={classes.studentName} />
              {getApplicationSubHeader()}
              {/*<SBText*/}
              {/*  text={`${*/}
              {/*    isDataAvailable ? getApplicationIdInString(data.appId) : "-"*/}
              {/*  } | ${isDataAvailable ? data.answers.destCountry : "-"} | ${*/}
              {/*    isDataAvailable ? data.answers.provider : "-"*/}
              {/*  } | ${isDataAvailable ? data.answers.intake : "-"}`}*/}
              {/*  className={classes.studentApplicationID}*/}
              {/*  variant={"overline"}*/}
              {/*/>*/}
            </div>

            {selectedTab == 'Rejected' ? (
              data.reason ? (
                <BootstrapTooltip title={'Reason : ' + data.reason}>
                  <IconButton onClick={() => onShowReasonDetails(data)}>
                    <img
                      src={rejectedIcon}
                      style={{
                        height: 30,
                        width: 30,
                        // marginTop: 5,
                        // marginRight: 5,
                      }}
                    />
                  </IconButton>
                </BootstrapTooltip>
              ) : (
                <img
                  src={rejectedIcon}
                  style={{
                    height: 30,
                    width: 30,
                    // marginTop: -75,
                    marginRight: 5,
                  }}
                />
              )
            ) : null}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.cardStatus}>
            <div className={classes.stepperView}>
              <ApplicationStatusStepper
                unConditionalOfferLetter={unConditionalOfferLetter}
                conditional={conditional}
                COE={COE}
                outcomeMaster={getOutcomeMasterArray(data)}
                onDownloadOutcome={(outcome: any) => onDownloadOutComes(outcome)}
                data={data}
              />
            </div>

            {/*TODO We will need this in future  */}
            {/* <div className={classes.statusView} hidden = {true}>
              <img src={mainTimer} className={classes.timerImg} />

              <Typography variant={"h5"} className={classes.statusText}>
                On Track
              </Typography>
            </div> */}
          </div>
          <Divider className={classes.divider} />
          <div className={classes.bottomView}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'flex-end',
              }}
            >

              {data.status == "Open" &&  !data.lockStatus ? (
                <Tooltip title={'Defer application to future term'}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => (isCanDefer ? showDeferApplicationDialog(true) : null)}
                    disabled={!isCanDefer}
                    className={isCanDefer ? classes.buttonStyle : classes.buttonDisabledStyle}
                  >
                    {isCanDefer ? 'Defer' : 'Deferment In-Progress'}
                  </Button>
                </Tooltip>
              ) : null}

              <SBButton
                icon={false}
                text={'View'}
                className={classes.viewBt}
                onClick={() => {
                  history.push('/app/' + encryptText(data.appId) + '/group/-1/question/-1');
                }}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      {deferApplicationDialog ? (
        <DeferedApplicationDialog
          open={deferApplicationDialog}
          onSubmit={(deferredTo: string, reason: string, remarks: string, date: string, applicationId: number) =>
            onDeferredApplication(deferredTo, reason, remarks, date, applicationId)
          }
          onClose={() => showDeferApplicationDialog(false)}
          sbApplication={null}
          currentApplicationDetails={null}
          dataObj={data}
        />
      ) : null}
      {deferDetailDialog ? (
        <SBDefermentDetailsDialog
          open={deferDetailDialog}
          onClose={() => showDeferDetailDialog(false)}
          deferredDetailApplication={data}
        />
      ) : null}
    </Grid>
  );
};

const mapState = (state: RootState) => ({});

const mapDispatch = {
  downloadOutComes,
  deferApplication,
};

const connector = connect(mapState, mapDispatch);
export default connector(withWidth()(ApplicationCard));

// export default ApplicationCard;
