import React, { useEffect, useState } from 'react';
import { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import _ from 'lodash';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Theme,Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RootState } from '../../../reducer';
import { useHistory } from 'react-router';
import MessageChat from './MessageChat';
import ActivityLog from './ActivityLog';
import SBText from '../../../components/base/SBText';
import SBButton from '../../../components/base/SBButton';
import { getActivityLogsByQuestion, getQuestionsComments, postComments } from '../../../actions';
import moment from 'moment';
import Timer from '../Timer';
import { LABELS } from '../../../util/labels';
import LeadsDetails from '../../Leads/LeadsDetails';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Lead } from '../../Leads/types';
import { useDispatch, useSelector } from 'react-redux';
import { selectedLeadDetails } from '../../Leads/store/selectors';
import { getLead } from '../../Leads/store/actions/get-lead-action';
import Lottie from 'lottie-react';
import animationData from '../../../components/LoadingDialog/assets/loaderv2.json';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    rightHeaderPanel: {
      height: 48,
      width: '100%',
      backgroundColor: theme.palette.primary.dark,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '3%',
    },
    parent: {
      height: '82vh',
      display: 'flex',
      width: '100%',
      overflowY: 'hidden',
      flexDirection: 'column',
    },
    descriptionParent: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 10,
      minHeight: 140,
      // backgroundColor: AppColor.appLightGrey,
    },
    descriptionText: {
      textAlign: 'left',
      marginTop: 10,
      fontSize: 12,
    },
    chatBox: {},
    headerText: {
      color: theme.palette.primary.contrastText,
      fontWeight: 700,
      fontSize: '1.059rem',
    },

    subHeader: {
      fontSize: 16,
      color: theme.palette.primary.dark,
    },
    fibiButton: {
      borderRadius: 30,
      maxHeight: 30,
    },
    fibiParent: {
      width: '100%',
      height: 50,
      justifyContent: 'center',
      display: 'flex',
      paddingTop: 5,
      paddingBottom: 5,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: '#D5D5D5',
    },

    accoudianHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    loaderParent: {
      marginTop: 0,
      alignItems: 'center',
      justifyContent: 'center',
      height: 300,
      display: 'flex',
      width: '100%',
    },
  })
);

const useStylesLeads = makeStyles((theme) => ({
  root: {
    marginBottom: '25px',
    '&.Mui-expanded': {
      margin: '0 0 25px 0',
    },
    '& .MuiAccordionSummary-root': {
      borderBottom: '1px solid #EEE',
      '&.Mui-expanded': {
        minHeight: 'auto',
      },
    },
    '& .MuiAccordionSummary-content': {
      color: '#5C5F74',
      fontSize: '1.06rem',
      fontFamily: 'Poppins',
      '&.Mui-expanded': {
        margin: '12px 0',
      },
    },
    '& .MuiAccordionDetails-root': {
      paddingTop: 0,
    },
  },
}));

const CustomAccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(AccordionSummary);

type PropsFromRedux = ConnectedProps<typeof connector>;
type LoginIndexProps = PropsFromRedux & {
  questionList: [any];
};
const RightBlockPanel: React.FC<LoginIndexProps> = ({
  sbApplication,
  counter,
  getActivityLogsByQuestion,
  activityLogs,
  getQuestionsComments,
  comments,
  postComments,
  user,
  currentApplicationDetails,
  questionList,
  internalLoading,
  ...rest
}) => {
  const history = useHistory();
  const classes = useStyles();
  const classesLeads = useStylesLeads();
  const [searchText, setSearchText] = useState('');
  const [message, setMessage] = useState<string>('');

  const [leadExpanded, setLeadExpanded] = useState(false);
  const [commentExpanded, setCommentExpanded] = useState(false);
  const [activityLogExpanded, setActivityLogExpanded] = useState(false);
  const [isCommentLoading, setCommentLoading] = useState(false);
  const [isActivityLogLoading, setActivityLogLoading] = useState(false);
  const [isLeadDetailsLoading, setLeadDetailsLoading] = useState(false);
  const dispatch = useDispatch();
  const leadData: Lead = useSelector(selectedLeadDetails);

  useEffect(() => {
    if (
      sbApplication.activeGroup != 'PreQuestions' &&
      questionList &&
      questionList.length > 0 &&
      questionList[counter]
    ) {
      // get activity logs && comments
      setLeadExpanded(false);
      setCommentExpanded(false);
      setActivityLogExpanded(false);
    }
  }, [sbApplication.activeGroup, counter]);

  const infoText =
    questionList && questionList.length > 0 && questionList[counter] ? questionList[counter].information : '-';

  const formattedDate = (date: Date) => {
    return moment(date).format('DD MMM YYYY HH:mm');
  };

  const getActivityLogs = () => {
    if (searchText) {
      const text = searchText.toLowerCase();
      const filteredList = _.filter(activityLogs, (o: any) => {
        return (
          o.activity.toLowerCase().includes(text) ||
          o.createdUser.name.toLowerCase().includes(text) ||
          o.createdUser.email.toLowerCase().includes(text) ||
          formattedDate(o.createdAt).toLowerCase().includes(text)
        );
      });
      return filteredList;
    } else {
      return activityLogs;
    }
  };

  const onSendMessage = () => {
    if (message) {
      postComments(
        {
          comment: message,
        },
        questionList[counter].id
      );

      setMessage('');
    }
  };

  const getComments = () => {
    if (
      sbApplication.activeGroup != 'PreQuestions' &&
      questionList &&
      questionList.length > 0 &&
      questionList[counter]
    ) {
      const questionId = [];
      const question = questionList[counter];

      questionId.push(question.id);
      if (question.questionList && question.questionList.length > 0) {
        for (let i = 0; i < question.questionList.length; i++) {
          questionId.push(question.questionList[i].id);
        }
      }
      setCommentLoading(true);
      getQuestionsComments(questionList[counter].id);
      setCommentLoading(false);
    }
  };

  const getActivityLogsList = () => {
    if (
      sbApplication.activeGroup != 'PreQuestions' &&
      questionList &&
      questionList.length > 0 &&
      questionList[counter]
    ) {
      const questionId = [];
      const question = questionList[counter];

      questionId.push(question.id);
      if (question.questionList && question.questionList.length > 0) {
        for (let i = 0; i < question.questionList.length; i++) {
          questionId.push(question.questionList[i].id);
        }
      }
      setActivityLogLoading(true);
      getActivityLogsByQuestion(questionId);
      setActivityLogLoading(false);
    }
  };

  const getLeadsDetails = () => {
    setLeadDetailsLoading(true);
    dispatch(getLead(currentApplicationDetails.leadId));
    setLeadDetailsLoading(false);
  };

  const onExpandLeads = () => {
    if (!leadExpanded) {
      getLeadsDetails();
    }
    setLeadExpanded(!leadExpanded);
  };

  const onExpandComments = () => {
    if (!commentExpanded) {
      getComments();
    }
    setCommentExpanded(!commentExpanded);
  };

  const onExpandActivityLog = () => {
    if (!activityLogExpanded) {
      getActivityLogsList();
    }
    setActivityLogExpanded(!activityLogExpanded);
  };

  return (
    <div className={classes.root}>
      <div className={classes.descriptionParent}>
        <Timer
          createdDate={currentApplicationDetails.createdAt}
          initiated={sbApplication.initialStateCompleted && !currentApplicationDetails.lockStatus}
        />
      </div>
      {currentApplicationDetails.leadId && (
        <Accordion expanded={leadExpanded}>
          <CustomAccordionSummary>
            <div className={classes.accoudianHeader}>
              <SBText text={LABELS.LEAD_DETAILS} className={classes.subHeader} />
              <IconButton size={'small'} onClick={() => onExpandLeads()}>
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            </div>
          </CustomAccordionSummary>
          <AccordionDetails>
            {isLeadDetailsLoading && internalLoading ? (
              <div className={classes.loaderParent}>
                <Lottie autoPlay={true} animationData={animationData} loop={true} style={{ height: 50, width: 50 }} />
              </div>
            ) : (
              <LeadsDetails leadData={leadData} />
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {sbApplication.initialStateCompleted ? (
        <Accordion expanded={commentExpanded}>
          <CustomAccordionSummary>
            <div className={classes.accoudianHeader}>
              <SBText text={'Comments'} className={classes.subHeader} />
              <div>
                {commentExpanded ? (
                  <Tooltip title="Reload Comments">
                  <IconButton size={'small'} onClick={() => getComments()}>
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                  </Tooltip>
                ) : null}
                <IconButton size={'small'} onClick={() => onExpandComments()}>
                  <ExpandMoreIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          </CustomAccordionSummary>
          <AccordionDetails >
            {isCommentLoading && internalLoading ? (
              <div className={classes.loaderParent}>
                <Lottie autoPlay={true} animationData={animationData} loop={true} style={{ height: 50, width: 50 }} />
              </div>
            ) : (
              <MessageChat
                comments={comments}
                onTextChange={(text) => setMessage(text)}
                value={message}
                onSendMessage={() => onSendMessage()}
                user={user.user ? user.user : {}}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ) : null}
      {sbApplication.initialStateCompleted ? (
        <Accordion expanded={activityLogExpanded}>
          <CustomAccordionSummary>
            <div className={classes.accoudianHeader}>
              <SBText text={'Change Log'} className={classes.subHeader} />
              <div>
                {activityLogExpanded ? (
                   <Tooltip title="Reload Change Logs">
                  <IconButton size={'small'} onClick={() => getActivityLogsList()}>
                    <RefreshIcon fontSize="small" />
                  </IconButton>
                  </Tooltip>
                ) : null}

                <IconButton size={'small'} onClick={() => onExpandActivityLog()}>
                  <ExpandMoreIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          </CustomAccordionSummary>
          <AccordionDetails>
            {isActivityLogLoading && internalLoading ? (
              <div className={classes.loaderParent}>
                <Lottie autoPlay={true} animationData={animationData} loop={true} style={{ height: 50, width: 50 }} />
              </div>
            ) : (
              <ActivityLog
                data={getActivityLogs()}
                onSearchTextChange={(text) => setSearchText(text)}
                value={searchText}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ) : null}
    </div>
  );
};
//

const mapState = (state: RootState) => ({
  // @ts-ignore
  sbApplication: state.sbApplication.sbApplication,
  // @ts-ignore
  counter: state.sbApplication.counter,
  // @ts-ignore
  activityLogs: state.sbApplication.activityLogs,
  // @ts-ignore
  comments: state.sbApplication.comments,
  user: state.user.user,
  // @ts-ignore
  currentApplicationDetails: state.sbApplication.currentApplicationDetails,
  internalLoading: state.application.internalLoading,
});

const mapDispatch = {
  getActivityLogsByQuestion,
  getQuestionsComments,
  postComments,
};
//
const connector = connect(mapState, mapDispatch);
export default connector(RightBlockPanel);
