import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import SBButton from "../../../components/base/SBButton";
import AppColor from "../../../theme/AppColor";
import _ from 'lodash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    cancelButton: {
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: "#FAFAFA",
      borderColor: AppColor.appBlue,
      borderWidth: 1,
      borderStyle: "solid",
      color: AppColor.appBlue,
    },
    requestButton: {
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor:'red'
    },
  })
);

type DuplicateApplicationDialogProps = {
  className?: string;
  open: boolean;
  onDeleteApplication: () => void;
  onChangeCourseApplication: () => void;
  questions: any,
  hideDelete: boolean
};
const DuplicateApplicationDialog: React.FC<DuplicateApplicationDialogProps> = ({
  open,
  onDeleteApplication,
  onChangeCourseApplication,
  questions,
  hideDelete
  // setOpen,
}) => {
  const classes = useStyles();
  //console.log("questions 111", questions)

  const handleClose = (event:any, reason:any) => {
      console.log("reason >>>>>",reason)
    onChangeCourseApplication();
    // setOpen(false);
    // onSubmit();
  };

  const getRequiredAnswers = () => {
    const answer = {
      passport: '',
      course: '',
      city: '',
      provider: '',
    };
    const passportQuestion = _.find(questions, (obj) => obj.key == 'Q_PASSPORT_NUMBER');
    const courseQuestion: any = _.find(questions, (obj) => obj.key == 'Q_COURSE');
    const cityQuestion = _.find(questions, (obj) => obj.key == 'Q_CAMPUS');
    const providerQuestion = _.find(questions, (obj) => obj.key == 'Q_PROVIDER');

    if (passportQuestion && passportQuestion.answer) {
      answer.passport = passportQuestion.answer.answer;
    }

    if (courseQuestion && courseQuestion.answer) {
      const option = _.find(courseQuestion.options, (obj) => obj.id == courseQuestion.answer.optionId);
      if (option) {
        answer.course = option.value;
      }
    }

    if (cityQuestion && cityQuestion.answer) {
      const option = _.find(cityQuestion.options, (obj) => obj.id == cityQuestion.answer.optionId);
      if (option) {
        answer.city = option.value;
      }
    }

    if (providerQuestion && providerQuestion.answer) {
      const option = _.find(providerQuestion.options, (obj) => obj.id == providerQuestion.answer.optionId);
      if (option) {
        answer.provider = option.value;
      }
    }

    return answer
  };


 
  const answerObj = getRequiredAnswers();
  //console.log("Answer :::", answerObj)
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={true}
    
      >
        <DialogTitle id="alert-dialog-title">Duplicate Application</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>The application of Passport no. <b>{answerObj.passport}</b> and the course <b>{answerObj.course}, {answerObj.provider}, {answerObj.city} </b> is already Open.

            <br/><br/>You can change course if the student is intending to apply to another course  {!hideDelete ? "<br/><br/>  OR <br/><br/> Delete this application and open existing one." : ''}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SBButton
            icon={false}
            text={"Change Course"}
            onClick={() => onChangeCourseApplication()}
            className={classes.cancelButton}
          />
          {!hideDelete ? 
          <SBButton
            icon={false}
            text={"Delete Application"}
            onClick={() => onDeleteApplication()}
            className={classes.requestButton}
          /> : null }
          {/*<SBButton icon={false} text={"OK"} onClick={handleSubmit} />*/}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DuplicateApplicationDialog;
