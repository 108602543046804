import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import SBText from "../../../../components/base/SBText";
import Link from "@material-ui/core/Link";
import StatusChip from "../../../../components/StatusChip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { tableStyles } from "../../../../styles/table";
import { AgentTableViewItem } from "../../types/agent-table-view-item";
import ReferenceCheckModal from "../ReferenceCheckModal/ReferenceCheckModal";
import { useFetchAgentData } from "../../hooks/useFetchAgentData";
import SendContractModal from "../SendContractModal/SendContractModal";
import OnboardAgentConfirmationModal from "../OnboardAgentConfirmationModal/OnboardAgentConfirmationModal";
import SideBar from "../SideBar/SideBar";
import { LABELS } from "../../../../util/labels";
import ResendInviteConfirmationModal from "../ResendInviteConfirmationModal";
import { useSelector } from "react-redux";
import { selectResendInviteResponse } from "../../store/selectors";
import { resendInviteApiResponse } from "../../types";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme: Theme) => createStyles(tableStyles(theme)));

interface Props {
  agentList: any;
  onViewAgent: (agent: AgentTableViewItem) => void;
}

const AgentTable: React.FC<Props> = ({ agentList, onViewAgent }) => {
  const classes = useStyles();

  const { fetchData } = useFetchAgentData();
  const resendInviteResponse: resendInviteApiResponse = useSelector(
    selectResendInviteResponse
  );

  const [isReferenceCheckModalOpen, setIsReferenceCheckModalOpen] = useState(
    false
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSendContractModalOpen, setIsSendContractModalOpen] = useState(false);
  const [isOnboardAgentModalOpen, setIsOnboardAgentModalOpen] = useState(false);
  const [agentIdForReferenceCheck, setAgentIdForReferenceCheck] = useState(0);
  const [agentIdForSendContract, setAgentIdForSendContract] = useState(0);
  const [agentEmailForSendContract, setAgentEmailForSendContract] = useState(
    ""
  );
  const [agentIdForSidebar, setAgentIdForSidebar] = useState(0);
  const [agentIdForOnboardAgent, setAgentIdForOnboardAgent] = useState(0);
  const [resendConfirmOpen, setResendConfirmOpen] = useState(false);
  const [
    selectedAgentToResendInvite,
    setSelectedAgentToResendInvite,
  ] = useState<AgentTableViewItem | null>(null);
  const capitalizedCellClassName = `${classes.bodyText}  ${classes.capitalize}`;
  const [snackBarMessage, setSnackBarMessage] = useState<string>("");

  useEffect(() => {
    if (resendInviteResponse.status === "success") {
      setSnackBarMessage(
        `Invitation has been sent successfully to '${resendInviteResponse.response.companyEmail}'`
      );
    }
  }, [resendInviteResponse]);

  const handleSnackBarClose = () => {
    setSnackBarMessage("");
  };

  const handleReferenceCheckClick = (e: any, agentId: number | undefined) => {
    e.stopPropagation();
    setIsReferenceCheckModalOpen(true);
    setAgentIdForReferenceCheck(agentId !== undefined ? agentId : 0);
  };
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: "#4c4c4c",
    },
    tooltip: {
      backgroundColor: "#4c4c4c",
    },
  }));
  function BootstrapTooltip(props: any) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
  }

  const handleSendContractClick = (
    e: any,
    agentId: number | undefined,
    email: string | undefined
  ) => {
    e.stopPropagation();
    setIsSendContractModalOpen(true);
    setAgentIdForSendContract(agentId !== undefined ? agentId : 0);
    setAgentEmailForSendContract(email !== undefined ? email : "");
  };

  const handleAgentOnboardClick = (e: any, agentID: any) => {
    e.stopPropagation();
    setAgentIdForOnboardAgent(agentID);
    setIsOnboardAgentModalOpen(true);
  };

  const handleReferenceCheckModalClose = () => {
    setIsReferenceCheckModalOpen(false);
  };

  const handleSendContractModalClose = () => {
    setIsSendContractModalOpen(false);
  };
  const handleViewClick = (event: any, agent: AgentTableViewItem) => {
    event.stopPropagation();
    onViewAgent(agent);
  };
  const openSideBar = (e: any, agentID: any) => {
    e.stopPropagation();
    setAgentIdForSidebar(agentID);
    setIsSidebarOpen(true);
  };

  const confirmResendInvite = (agent: AgentTableViewItem) => {
    setSelectedAgentToResendInvite(agent);
    setResendConfirmOpen(true);
  };

  const handleResendConfirmClose = () => {
    setSelectedAgentToResendInvite(null);
    setResendConfirmOpen(false);
  };

  return (
    <>
      <div className={classes.mainLayout}>
        {isOnboardAgentModalOpen && (
          <OnboardAgentConfirmationModal
            onModalCloseClick={setIsOnboardAgentModalOpen}
            agentId={agentIdForOnboardAgent}
          />
        )}
        {isSidebarOpen && (
          <SideBar
            agentsData={agentList}
            currentAgentId={agentIdForSidebar}
            onSideBarClose={setIsSidebarOpen}
          />
        )}
        {isReferenceCheckModalOpen && (
          <ReferenceCheckModal
            agentId={agentIdForReferenceCheck}
            onModalCloseClick={handleReferenceCheckModalClose}
          />
        )}
        {isSendContractModalOpen && (
          <SendContractModal
            agentId={agentIdForSendContract}
            emailId={agentEmailForSendContract}
            onModalCloseClick={handleSendContractModalClose}
          />
        )}
        <div className={classes.tableParents}>
          <TableContainer component={Paper}>
            <Table className={classes.table} stickyHeader>
              <TableHead className={classes.TableRow}>
                <TableRow>
                  <TableCell align="left">
                    <SBText
                      text="Company Name"
                      className={classes.HeaderPadding}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <SBText
                      text="Key Staff"
                      className={classes.HeaderPadding}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <SBText
                      text="Key Staff's Job Title"
                      className={classes.HeaderPadding}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text="Email" className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text="Phone" className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text="Status" className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text="Actions" className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.padding}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agentList.map((item: AgentTableViewItem) => (
                  <TableRow
                    onClick={(e: any) => openSideBar(e, item.id)}
                    key={item.id}
                    style={{
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th">
                      <SBText
                        text={item.companyName}
                        className={capitalizedCellClassName}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={`${item.firstKeyPerson.firstName} ${item.firstKeyPerson.lastName}`}
                        className={capitalizedCellClassName}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={item.firstKeyPerson.jobTitle}
                        className={classes.bodyText}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={item.companyEmail}
                        className={classes.bodyText}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={item.companyPhone}
                        className={capitalizedCellClassName}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <StatusChip
                          item={item.statusViewDetails}
                          className={classes.bodyText}
                        />
                        {item.status == 2 && (
                          <Tooltip title="Resend Invite">
                            <RefreshIcon
                              style={{ marginLeft: 5 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                confirmResendInvite(item);
                              }}
                            ></RefreshIcon>
                          </Tooltip>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      {(item.status === 5 || item.status === 3) && (
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e: any) =>
                            handleReferenceCheckClick(e, item.id)
                          }
                        >
                          Reference Check
                        </Link>
                      )}
                      {(item.status === 7 || item.status === 8) && (
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e: any) =>
                            handleSendContractClick(
                              e,
                              item.id,
                              item.companyEmail
                            )
                          }
                        >
                          Send Contract
                        </Link>
                      )}
                      {item.status === 10 && (
                        <Link
                          component="button"
                          variant="body2"
                          onClick={(e: any) =>
                            handleAgentOnboardClick(e, item.id)
                          }
                        >
                          Onboard Agent
                        </Link>
                      )}
                    </TableCell>
                    <TableCell align="left" className={classes.padding}>
                      <BootstrapTooltip title={LABELS.VIEW_DETAILS}>
                        <div onClick={(event) => handleViewClick(event, item)}>
                          <VisibilityIcon />
                        </div>
                      </BootstrapTooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <ResendInviteConfirmationModal
        agent={selectedAgentToResendInvite}
        resendConfirmOpen={resendConfirmOpen}
        handleClose={handleResendConfirmClose}
      />
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AgentTable;
