import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppColor from '../../theme/AppColor';
import SBText from '../../components/base/SBText';
import { colors, Button } from '@material-ui/core';

import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import SBTextField from '../../components/base/SBTextField';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    width: '27%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '24%',
    position: 'relative',
    borderRadius: 4,
  },
  listParent: {
    minHeight: 60,
    width: '100%',
    backgroundColor: AppColor.appBlue,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    width: '84%',
  },
  selectAgentTxt: {
    fontSize: 20,
    color: AppColor.appWhite,
    fontWeight: 500,
    [theme.breakpoints.up(1920)]: {
      fontSize: 22,
    },
  },
  modalTxt: {
    color: '#000000',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.2,
  },
  multifeildTxt: {
    color: '#b52c00',
    fontWeight: 400,
    letterSpacing: '0.75px',
    fontSize: 9,
    marginTop: '0px',
  },
  multifeildTxt1: {
    color: '#000000',
    fontWeight: 400,
    letterSpacing: '0.75px',
    fontSize: 9,
    marginTop: 25,
  },
  searchInputTxt: {
    fontSize: 12,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
    height: 100,
    textAlign: 'start',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    marginTop: 16,
  },
}));

type ApproveRejectPopupProps = {
  className?: string;
  open: any;
  onClose: () => void;
  Action: string;
  label: string;
  questionId: Number;
  groupId: any;
  onAppoveOrReject: (reviewStatus: boolean, comment: string, questionId: Number, groupId: any) => void;
  isVNTUser: boolean;
  onSearchText: (searchText: any) => void;
};

const ApproveRejectPopup: React.FC<ApproveRejectPopupProps> = ({
  children,
  onClose,
  className,
  open,
  Action,
  label,
  questionId,
  groupId,
  isVNTUser,
  onSearchText,
  onAppoveOrReject,
  ...rest
}) => {
  const classes = useStyles();
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState('');
  const handleSubmit = () => {
    if( Action === 'Approve') {
      onAppoveOrReject(true, comment, questionId, groupId);
    } else {
      if (comment && comment.length > 0) {
      onAppoveOrReject(false, comment, questionId, groupId);
      } else {
        setCommentError('*Reason for rejection/approval is mandatory');
      }
    }
  };

  const onCloseModel = () => {
    onClose();
  };

  const messageActionText = Action == 'Reject' ? 'rejection' : 'approve';
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.listParent}>
            <SBText text={Action} className={classes.selectAgentTxt} />
            <CloseIcon
              onClick={() => onClose()}
              style={{ marginLeft: 10, color: AppColor.appWhite, cursor: 'pointer' }}
            />
          </div>
          <div
            style={{
              width: '100%',
              marginTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <SBText text={`Are you sure you want to ${Action} the "${label}" ?`} className={classes.modalTxt} />
            <SBText
              text={`Provide reason for the ${messageActionText}.This will be visible to agent`}
              className={classes.multifeildTxt1}
            />
            <SBTextField
              multiline={true}
              placeholder={'Add comments here'}
              value={comment}
              name="agent"
              className={classes.searchInputTxt}
              onDataChange={(text) => {
                setComment(text);
              }}
              inputProps={{
                maxlength: 255,
              }}
              error={!!commentError}
              helperText={commentError}
              rows={4}
            />
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              style={{ margin: '10px', width: 110, textTransform: 'none' }}
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              style={{ margin: '10px', width: 110, textTransform: 'none' }}
              variant="contained"
              onClick={() => handleSubmit()}
            >
              {Action}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ApproveRejectPopup;
