import React, { useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, Fade, Menu, MenuItem, Theme } from '@material-ui/core';
import _ from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducer';
import { getFormattedApplicationList } from '../../../selector/applicationList';
import {
  clearSBApplicationFilter,
  getApplicationFilters,
  getApplicationList,
  setSBApplicationFilter,
  setSBApplicationFilterObj,
  getCourseListByProviderId
} from '../../../actions';
import SBButton from '../../../components/base/SBButton';
import CloseIcon from '@material-ui/icons/Close';
import AppColor from '../../../theme/AppColor';
import SBFilterMultiselectDropdown from '../../../components/base/SBFilterMultiselectDropdown';
import SBText from '../../../components/base/SBText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/Cancel';
import { hasOneOfPermissions, PERMISSION } from '../../../util/rolePermissionsUtil';
import { useUserPermissions } from '../../../hooks/useUserPermissions';

type PropsFromRedux = ConnectedProps<typeof connector>;
type SBApplicationListProps = PropsFromRedux & {
  filterOpen: boolean;
  onClose: Function;
  tabString: string;
  selectedAssign: string;
  onApplyFilter: Function;
  onClearFilter: Function;
  assignedTo: any;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: { marginTop: theme.spacing(2) },
    drawerRoot: {
      width: 360,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    filterButtonParent: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      justifyContent: 'space-between',
    },
    clearButton: {
      backgroundColor: 'white',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: AppColor.appBlue,
      '&:hover': {
        backgroundColor: '#fafafa',
      },
      color: AppColor.appBlue,
    },
    headerText: {
      fontSize: 16,
      fontWeight: 600,
      marginTop: 30,
      marginBottom: 5,
      color: AppColor.appBlue,
    },
    filterButtonText: {
      fontWeight: 400,
      letterSpacing: '0.75px',
      textTransform: 'none',
      fontSize: 14,
    },
    sort: {
      // width: 260,
      borderRadius: 5,
      display: 'flex',
      height: 45,
      paddingLeft: 10,
      paddingRight: 10,
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#FCFBFB',
      border: '1px solid #3E4059',
    },
    label: {
      textAlign: 'left',
      color: '#736F6F',
      marginTop: theme.spacing(2),
    },
  })
);
const ApplicationFilter: React.FC<SBApplicationListProps> = ({
  filterOpen,
  onClose,
  getApplicationFilters,
  sbApplicationFilterList,
  sbApplicationFilter,
  getApplicationList,
  tabString,
  clearSBApplicationFilter,
  selectedAssign,
  setSBApplicationFilterObj,
  onApplyFilter,
  onClearFilter,
  providerList,
  assignedTo,
  user,
  setSBApplicationFilter,
  getCourseListByProviderId,
}) => {
  const classes = useStyles();
  const userPermissions: PERMISSION[] = useUserPermissions();
  // useEffect(() => {
  //   if (!sbApplicationFilterList) {
  //     getApplicationFilters();
  //   }
  // }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userAncherEL, setUserAnchorEl] = React.useState(null);
  const [countryAnchorEl, setcountryAnchorEL] = React.useState(null);
  const [buttonWidth, setButtonWidth] = React.useState<number | undefined>(undefined);
  const [assignedToObj, setAssignedTo] = React.useState<any | null>(assignedTo);
  const assignedList = ['Assigned', 'Unassigned', 'Clear All'];

  const [currentFilter, setCurrentFilter] = React.useState(sbApplicationFilter);
  const [assignFilter, setAssignFilter] = React.useState<string | null>(selectedAssign);
  const handleMenuClose = () => {
    setAnchorEl(null);
    setUserAnchorEl(null);
    setcountryAnchorEL(null);
  };


  useEffect(() => {
    setCurrentFilter(sbApplicationFilter);
  }, [sbApplicationFilter]);

  useEffect(() => {
    setAssignFilter(selectedAssign);
  }, [selectedAssign]);

  const onSelectedAssign = async (name: string) => {
    if (name == 'Clear All') {
      setAssignFilter(null);
    } else {
      setAssignFilter(name);
    }

    if (name != 'Assigned') {
      setAssignedTo(null);
    }
    setAnchorEl(null);
  };

  const onCountrySelected = async (obj: any) => {
    const tempFilter = _.cloneDeep(currentFilter);
    tempFilter['country'] = obj;
    tempFilter.provider = [];
    tempFilter.outcomes = [];
    tempFilter.subStatus = [];
    tempFilter.intakes = [];
    // tempFilter.applicationStatus = [];

    setCurrentFilter(tempFilter);
    await getApplicationFilters(obj.id);
    setcountryAnchorEL(null);
  };

  const onSelectAssinedTo = async (obj: any) => {
    setAssignedTo(obj);
    setUserAnchorEl(null);
  };

  const onSetApplicationFilter = (key: string, value: any) => {
    const tempFilter = _.cloneDeep(currentFilter);
    tempFilter[key] = value;
    setCurrentFilter(tempFilter);
    if(key == "provider") {
      if(tempFilter.provider && tempFilter.provider.length>0) {
        const providerId: number[] = _.map(tempFilter.provider, (obj: any) => obj.id);
        getCourseListByProviderId(providerId)
      }
    }
  };

  const onClearChangeFilter = async () => {
    setAssignFilter(null);
    setAssignedTo(null);
    onClose();
    onClearFilter();
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserClick = (event: any) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleCountryClick = (event: any) => {
    setcountryAnchorEL(event.currentTarget);
  };

  const onApplyChangeFilter = async () => {
    onApplyFilter(currentFilter, assignFilter, assignedToObj);
    onClose();
  };

  const hasPerm = (perm: PERMISSION[]) => {
    return hasOneOfPermissions(perm, userPermissions);
  };

  console.log("sbApplicationFilterList",sbApplicationFilterList)

  return (
    <Drawer anchor={'right'} open={filterOpen} onClose={() => onClose()}>
      {sbApplicationFilterList ? 
      <div className={classes.drawerRoot}>
        <div style={{ padding: 20, height: '85%', overflowY: 'scroll', display: 'flex', flexDirection: 'column' }}>
          <Button onClick={() => onClose()} style={{ marginLeft: 'auto' }}>
            <CloseIcon /> Close
          </Button>

          <div>
            <SBText text={'By Status'} className={classes.headerText} />
            <Divider />
            <SBFilterMultiselectDropdown
              value={currentFilter.applicationStatus}
              options={
                sbApplicationFilterList && sbApplicationFilterList.applicationStatus
                  ? sbApplicationFilterList.applicationStatus
                  : []
              }
              labelText={'Status'}
              className={classes.dropdown}
              onDataChange={(value: any) => onSetApplicationFilter('applicationStatus', value)}
            />

            {hasPerm([
              PERMISSION.applications_provider_view_own,
              PERMISSION.applications_provider_view_org,
              PERMISSION.applications_provider_view_global,
              PERMISSION.applications_provider_view_sb_own,
              PERMISSION.applications_provider_view_sb_org,
              PERMISSION.applications_provider_view_cph,
            ]) ? (
              <>
                <SBText text={'By Assigned/Unassigned Status'} className={classes.headerText} />
                <Divider />

                <SBText text={'Assigned/Unassigned By'} className={classes.label} color={'#736F6F'} />
                <Button className={classes.sort} onClick={handleClick} fullWidth={true}>
                  <SBText text={assignFilter ? assignFilter : ''} className={classes.filterButtonText} />
                  <ExpandMoreIcon />
                </Button>
              </>
            ) : null}
            {assignFilter == 'Assigned' &&
            hasPerm([
              PERMISSION.applications_provider_view_org,
              PERMISSION.applications_provider_view_global,
              PERMISSION.applications_provider_view_sb_own,
              PERMISSION.applications_provider_view_sb_org,
            ]) ? (
              <>
                <SBText text={'Assigned User'} className={classes.label} color={'#736F6F'} />
                <Button
                  className={classes.sort}
                  onClick={handleUserClick}
                  fullWidth={true}
                  ref={(el: any) => {
                    if (!el) return;
                    // make sure we only update chart dimensions on certain conditions (otherwise will trigger infinite re-renders)
                    setButtonWidth(Math.round(el.getBoundingClientRect().width));
                  }}
                >
                  <SBText text={assignedToObj ? assignedToObj.name : ''} className={classes.filterButtonText} />
                  <ExpandMoreIcon />
                </Button>
              </>
            ) : null}

            <>
              <SBText
                text={hasPerm([PERMISSION.applications_provider_view_global]) ? 'By Country/Provider/Term' : 'By Term'}
                className={classes.headerText}
              />
              {hasPerm([
                PERMISSION.applications_provider_view_global,
                PERMISSION.applications_provider_view_sb_own,
                PERMISSION.applications_provider_view_sb_org,
                PERMISSION.applications_provider_view_cph,
              ]) ? (
                <>
                  <SBText text={'Country'} className={classes.label} color={'#736F6F'} />
                  <Button className={classes.sort} onClick={handleCountryClick} fullWidth={true}>
                    <SBText
                      text={currentFilter && currentFilter.country ? currentFilter.country.value : ''}
                      className={classes.filterButtonText}
                    />
                    <ExpandMoreIcon />
                  </Button>

                  <SBFilterMultiselectDropdown
                    value={currentFilter.provider}
                    options={
                      sbApplicationFilterList && currentFilter?.country && sbApplicationFilterList.providers
                        ? sbApplicationFilterList.providers
                        : []
                    }
                    labelText={'Providers'}
                    className={classes.dropdown}
                    onDataChange={(value: any) => onSetApplicationFilter('provider', value)}
                  />
                </>
              ) : null}

              {hasPerm([
                PERMISSION.applications_provider_view_own,
                PERMISSION.applications_provider_view_org,
                PERMISSION.applications_provider_view_global,
                PERMISSION.applications_provider_view_sb_own,
                PERMISSION.applications_provider_view_sb_org,
                PERMISSION.applications_provider_view_cph,
              ]) ? (
                <SBFilterMultiselectDropdown
                  value={currentFilter.course}
                  options={
                    sbApplicationFilterList && sbApplicationFilterList.course && currentFilter?.country && currentFilter?.provider
                      ? sbApplicationFilterList.course
                      : []
                  }
                  labelText={'Course'}
                  className={classes.dropdown}
                  onDataChange={(value: any) => onSetApplicationFilter('course', value)}
                />
              ) : null}

              {hasPerm([
                PERMISSION.applications_provider_view_own,
                PERMISSION.applications_provider_view_org,
                PERMISSION.applications_provider_view_global,
                PERMISSION.applications_provider_view_sb_own,
                PERMISSION.applications_provider_view_sb_org,
                PERMISSION.applications_provider_view_cph,
              ]) ? (
                <SBFilterMultiselectDropdown
                  value={currentFilter.intakes}
                  options={
                    sbApplicationFilterList && sbApplicationFilterList.intakes && currentFilter?.country
                      ? sbApplicationFilterList.intakes
                      : []
                  }
                  labelText={'Intakes'}
                  className={classes.dropdown}
                  onDataChange={(value: any) => onSetApplicationFilter('intakes', value)}
                />
              ) : null}
            </>

            {hasPerm([
              PERMISSION.applications_provider_view_own,
              PERMISSION.applications_provider_view_org,
              PERMISSION.applications_provider_view_global,
              PERMISSION.applications_provider_view_sb_own,
              PERMISSION.applications_provider_view_sb_org,
              PERMISSION.applications_provider_view_cph,
            ]) ? (
              <>
                <SBText text={'By Application/Outcome Status'} className={classes.headerText} />
                <Divider />

                <SBFilterMultiselectDropdown
                  value={currentFilter.subStatus}
                  options={
                    sbApplicationFilterList && sbApplicationFilterList.applicationSubStatus && currentFilter?.country
                      ? sbApplicationFilterList.applicationSubStatus
                      : []
                  }
                  labelText={'Sub Status'}
                  className={classes.dropdown}
                  onDataChange={(value: any) => onSetApplicationFilter('subStatus', value)}
                />
                <SBFilterMultiselectDropdown
                  value={currentFilter.outcomes}
                  options={
                    sbApplicationFilterList && sbApplicationFilterList.outcomes && currentFilter?.country
                      ? sbApplicationFilterList.outcomes
                      : []
                  }
                  labelText={'Outcome'}
                  className={classes.dropdown}
                  onDataChange={(value: any) => onSetApplicationFilter('outcomes', value)}
                />
              </>
            ) : null}

            {hasPerm([PERMISSION.applications_provider_view_cph, PERMISSION.applications_provider_view_global]) ? (
              <>
                <SBText text={'By Agencies'} className={classes.headerText} />
                <Divider />

                <SBFilterMultiselectDropdown
                  value={currentFilter.agencies ? currentFilter.agencies : []}
                  options={
                    sbApplicationFilterList && sbApplicationFilterList.agencies ? sbApplicationFilterList.agencies : []
                  }
                  labelText={'Agencies'}
                  className={classes.dropdown}
                  onDataChange={(value: any) => onSetApplicationFilter('agencies', value)}
                />
              </>
            ) : null}
          </div>
        </div>

        <div className={classes.filterButtonParent}>
          <SBButton text={'Apply filter'} icon={false} onClick={() => onApplyChangeFilter()} />
          <div style={{ height: 20 }}></div>
          <SBButton
            text={'Clear filter'}
            icon={false}
            onClick={() => onClearChangeFilter()}
            className={classes.clearButton}
          />
        </div>
      </div> : <div className={classes.drawerRoot}/>}
      {/*{list(anchor)}*/}

      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl ? true : false}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
      >
        {assignedList.map((name: any, i) => (
          <MenuItem
            onClick={(event) => {
              onSelectedAssign(name);
            }}
            style={{
              padding: 0,
            }}
            value={name}
          >
            <div
              style={{
                display: 'flex',
                height: 40,
                alignItems: 'center',
                width: 260,
                justifyContent: 'space-between',
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <SBText text={name} />

              {name == 'Clear All' ? <CancelIcon /> : null}
            </div>
          </MenuItem>
        ))}
      </Menu>

      {sbApplicationFilterList ? (
        <Menu
          id="fade-menu"
          anchorEl={countryAnchorEl}
          keepMounted
          open={countryAnchorEl ? true : false}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
        >
          {sbApplicationFilterList.country.map((obj: any, i: number) => (
            <MenuItem
              onClick={(event) => {
                onCountrySelected(obj);
              }}
              style={{
                padding: 0,
              }}
              value={obj.value}
            >
              <div
                style={{
                  display: 'flex',
                  height: 40,
                  alignItems: 'center',
                  width: 260,
                  justifyContent: 'space-between',
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <SBText text={obj.value} />
              </div>
            </MenuItem>
          ))}
        </Menu>
      ) : null}

      <Menu
        id="fade-menu"
        anchorEl={userAncherEL}
        keepMounted
        open={userAncherEL ? true : false}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            width: buttonWidth ? buttonWidth : 260,
            marginTop: 10,
            maxHeight: 500,
          },
        }}
      >
        <MenuItem
          onClick={(event) => {
            // onSelectedAssign(name);
            onSelectAssinedTo(user.user);
          }}
          style={{
            padding: 0,
          }}
          value={user.user.name}
        >
          <div
            style={{
              display: 'flex',
              height: 50,
              justifyContent: 'center',
              width: buttonWidth ? buttonWidth : 260,
              paddingLeft: 10,
              paddingRight: 10,
              flexDirection: 'column',
            }}
          >
            <SBText text={'Self'} style={{ color: AppColor.appBlue }} />
            <SBText text={user.user.email} style={{ fontSize: 10, marginTop: -5, color: '#736F6F' }} />
          </div>
        </MenuItem>

        {providerList.map((userObj: any, i: number) => (
          <MenuItem
            onClick={(event) => {
              // onSelectedAssign(name);
              onSelectAssinedTo(userObj);
            }}
            style={{
              padding: 0,
            }}
            value={userObj.name}
          >
            <div
              style={{
                display: 'flex',
                height: 50,
                justifyContent: 'center',
                width: buttonWidth ? buttonWidth : 260,
                paddingLeft: 10,
                paddingRight: 10,
                flexDirection: 'column',
              }}
            >
              <SBText text={userObj.name} style={{ color: AppColor.appBlue }} />
              <SBText text={userObj.email} style={{ fontSize: 10, marginTop: -5, color: '#736F6F' }} />
            </div>
          </MenuItem>
        ))}
      </Menu>
    </Drawer>
  );
};

const mapState = (state: RootState) => ({
  // @ts-ignore
  sbApplicationFilterList: state.sbApplication.sbApplicationFilterList,
  // @ts-ignore
  sbApplicationFilter: state.sbApplication.sbApplicationFilter,

  // @ts-ignore
  providerList: state.sbApplication.providerList,
  user: state.user.user,
});

const mapDispatch = {
  getApplicationFilters,
  getApplicationList,
  clearSBApplicationFilter,
  setSBApplicationFilterObj,
  setSBApplicationFilter,
  getCourseListByProviderId
};

const connector = connect(mapState, mapDispatch);
export default connector(ApplicationFilter);
