import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionTypes,
  postWithHeader,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../actions";
import config from "../../../config";
import { LpoAction } from "../store/actions/action-types";

export const useUploadSignedContract = () => {
  const dispatch = useDispatch();

  const postUploadSignedContract = useCallback(
    async (Id: number, payload: any) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await postWithHeader(
          `${config.BASE_URL}/ta/upload-contract-tl-signed/${Id}`,
          payload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
          dispatch
        );
        dispatch({
          type: LpoAction.SET_LPO_STATUS,
          payload: result,
        });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    postUploadSignedContract,
  };
};
