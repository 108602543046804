import React, { useState } from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import SBButton from "../../../components/base/SBButton";
import AppColor from "../../../theme/AppColor";
import SBText from "../../../components/base/SBText";
import SBFilterDropdown from "../../../components/base/SBFilterDropdown";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    cancelButton: {
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: "#FAFAFA",
      borderColor: AppColor.appBlue,
      borderWidth: 1,
      borderStyle: "solid",
      color: AppColor.appBlue,
    },
    requestButton: {
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 16,
      marginBottom: 10,
    },
    paper: { width: "450px", minHeight: 300 },
    checkButtonText: {
      marginLeft: 6,
      fontSize: 16,
      fontWeight: 600,
      color: AppColor.appBlue,
    },
    uncheckButton: { opacity: 0.5, height: 30, width: 30 },
    checkButton: { height: 30, width: 30 },
    checkedText: { color: "black" },
    arrow: {
      "&:before": {
        border: "0px solid #E6E8ED",
      },
      color: AppColor.appBlue,
    },
    tooltip: {
      backgroundColor: AppColor.appBlue,
      border: "0px solid #E6E8ED",
      color: "white",
      width: 120,
      textAlign: "center",
    },
    textAreaWidth: {
      border: "1px solid black",
      borderRadius: "5px",
      fontFamily: "Poppins",
    },
    label: {
      textAlign: "left",
      color: theme.palette.primary.light,
      display: "flex",
    },
    alertTitle: {
      fontFamily: "poppins",
      letterSpacing: 0.15,
      fontWeight: 600,
      backgroundColor: AppColor.appBlue,
      height: 50,
      paddingTop: 5,
      paddingBottom: 5,
    },
    mainTitle: {
      fontSize: 16,
      color: "white",
      fontWeight: 600,
    },
    subTitle: {
      fontSize: 12,
      color: "white",
    },
    dialogContentParent: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
  })
);

type ChangeCountryDialogProps = {
  className?: string;
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  sbApplication?: any;
  currentApplicationDetails: any;
  dataObj?: any;
  countryList: any;
  termsList: any;
  onValueChange: any;
};
const ChangeCountryDialog: React.FC<ChangeCountryDialogProps> = ({
  open,
  onSubmit,
  onClose,
  dataObj,
  countryList,
  termsList,
  onValueChange,
}) => {
  const classes = useStyles();

  const [countryError, setCountryError] = useState("");
  const [cityError, setCityError] = useState("");
  const [providerError, setProviderError] = useState("");
  const [courseCategoryError, setCourseCategoryError] = useState("");
  const [courseError, setCourseError] = useState("");
  const [termsError, setTermsError] = useState("");

  const handleClose = () => {
    setCountryError("");
    setCityError("");
    setProviderError("");
    setCourseCategoryError("");
    setCourseError("");
    setTermsError("");
    onClose();
  };

  const onSubmitAction = () => {
    if (!dataObj.country) {
      setCountryError("Please select destination country");
      return;
    }

    if (!dataObj.city) {
      setCityError("Please select city");
      return;
    }

    if (!dataObj.provider) {
      setProviderError("Please select provider");
      return;
    }

    if (!dataObj.category) {
      setCourseCategoryError("Please select category");
      return;
    }

    if (!dataObj.course) {
      setCourseError("Please select course");
      return;
    }

    if (!dataObj.terms) {
      setTermsError("Please select terms");
      return;
    }

    
    onSubmit();
    setCountryError("");
    setCityError("");
    setProviderError("");
    setCourseCategoryError("");
    setCourseError("");
    setTermsError("");
    onClose();
  };

  const getCountry = () => {
    const country = [];
    for (let i = 0; i < countryList.length; i++) {
      const obj = {
        id: countryList[i].id,
        value: countryList[i].name,
      };
      country.push(obj);
    }
    return country;
  };

  const getCity = () => {
    if (dataObj && dataObj.country && countryList.length > 0) {
      const countryObj = _.find(
        countryList,
        (obj: any) => obj.name == dataObj.country
      );
      const providers = countryObj.providers;
      let array: any = [];
      const obj = {
        id: -11,
        value: "Any",
      };
      array.push(obj);
      providers.forEach(function (value: any) {
        const index = _.findIndex(array, (obj: any) => obj.value == value.city);
        if (index == -1) {
          const obj = {
            id: value.id,
            value: value.city,
          };
          array.push(obj);
        }
      });
      return array;
    } else {
      return [];
    }
  };

  const getProviderList = () => {
    if (dataObj && dataObj.country && countryList.length > 0) {
      const countryObj = _.find(
        countryList,
        (obj: any) => obj.name == dataObj.country
      );

      const providers = countryObj.providers;

      const array: any = [];
      if (dataObj.city) {
        const obj = {
          id: -11,
          value: "Any",
        };
        array.push(obj);
        for (let i = 0; i < providers.length; i++) {
          if (providers[i].city == dataObj.city) {
            const obj = {
              id: providers[i].id,
              value: providers[i].name,
            };
            array.push(obj);
          }
        }
      }
      return array;
    } else {
      return [];
    }
  };

  const getCourseCategory = () => {
    if (dataObj && dataObj.provider && countryList.length > 0) {
      const countryObj = _.find(
        countryList,
        (obj: any) => obj.name == dataObj.country
      );
      const providers = countryObj.providers;
      const selectedProviders = _.find(
        providers,
        (obj: any) => obj.name == dataObj.provider
      );
      if (selectedProviders) {
        const category = selectedProviders.courseCategory;
        const array: any = [];
        for (let i = 0; i < category.length; i++) {
          const obj = {
            id: category[i].id,
            value: category[i].name,
          };
          array.push(obj);
        }

        return array;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const getCourse = () => {
    if (
      dataObj &&
      dataObj.provider &&
      dataObj.category &&
      countryList.length > 0
    ) {
      const countryObj = _.find(
        countryList,
        (obj: any) => obj.name == dataObj.country
      );
      const providers = countryObj.providers;
      const selectedProviders = _.find(
        providers,
        (obj: any) => obj.name == dataObj.provider
      );
      const array: any = [];

      if (selectedProviders) {
        const category = selectedProviders.courseCategory;

        const selectedCategories = _.find(
          category,
          (obj: any) => obj.name == dataObj.category
        );

        if (selectedCategories) {
          const course = selectedCategories.course;
          for (let i = 0; i < course.length; i++) {
            const obj = {
              id: course[i].id,
              value: course[i].name,
            };
            array.push(obj);
          }
        }

        return array;
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const getTerms = () => {
    if (termsList && termsList.length > 0) {
      const array: any = [];
      for (let i = 0; i < termsList.length; i++) {
        const obj = {
          id: i + 1,
          value: termsList[i].value,
        };
        array.push(obj);
      }
      return array;
    } else {
      return [];
    }
  };

  const onDropDownChange = (key: string, value: string) => {
    onValueChange(key, value);

    if (key == "country") {
      setCountryError("");
    }

    if (key == "city") {
      setCityError("");
    }

    if (key == "provider") {
      setProviderError("");
    }

    if (key == "category") {
      setCourseCategoryError("");
    }

    if (key == "course") {
      setCourseError("");
    }

    if (key == "terms") {
      setTermsError("");
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.alertTitle}>
          <SBText className={classes.mainTitle} text={"Update Application"} />
          <SBText
            className={classes.subTitle}
            text={"Change country, city, provider, category, or course "}
          />
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContentParent}>
            <div style={{ marginTop: 7 }} />
            <div style={{ height: 90 }}>
              <SBFilterDropdown
                data={getCountry()}
                value={dataObj && dataObj.country ? dataObj.country : ""}
                labelText={"Select Country"}
                onChange={(value: any) => onDropDownChange("country", value)}
              />
              {countryError ? (
                <SBText
                  text={countryError}
                  style={{
                    fontSize: 12,
                    color: "red",
                  }}
                />
              ) : null}
            </div>

            <div style={{ marginTop: 7 }} />
            <div style={{ height: 90 }}>
              <SBFilterDropdown
                data={getCity()}
                value={dataObj && dataObj.city ? dataObj.city : ""}
                labelText={"Select City"}
                onChange={(value: any) => onDropDownChange("city", value)}
              />
              {cityError ? (
                <SBText
                  text={cityError}
                  style={{
                    fontSize: 12,
                    color: "red",
                  }}
                />
              ) : null}
            </div>

            <div style={{ marginTop: 7 }} />
            <div style={{ height: 90 }}>
              <SBFilterDropdown
                data={getProviderList()}
                value={dataObj && dataObj.provider ? dataObj.provider : ""}
                labelText={"Select Provider"}
                onChange={(value: any) => onDropDownChange("provider", value)}
              />
              {providerError ? (
                <SBText
                  text={providerError}
                  style={{
                    fontSize: 12,
                    color: "red",
                  }}
                />
              ) : null}
            </div>

            <div style={{ marginTop: 7 }} />
            <div style={{ height: 90 }}>
              <SBFilterDropdown
                data={getCourseCategory()}
                value={dataObj && dataObj.category ? dataObj.category : ""}
                labelText={"Select Course Category"}
                onChange={(value: any) => onDropDownChange("category", value)}
              />
              {courseCategoryError ? (
                <SBText
                  text={courseCategoryError}
                  style={{
                    fontSize: 12,
                    color: "red",
                  }}
                />
              ) : null}
            </div>

            <div style={{ marginTop: 7 }} />
            <div style={{ height: 90 }}>
              <SBFilterDropdown
                data={getCourse()}
                value={dataObj && dataObj.course ? dataObj.course : ""}
                labelText={"Select Course"}
                onChange={(value: any) => onDropDownChange("course", value)}
              />
              {courseError ? (
                <SBText
                  text={courseError}
                  style={{
                    fontSize: 12,
                    color: "red",
                  }}
                />
              ) : null}
            </div>

            <div style={{ marginTop: 7 }} />
            <div style={{ height: 90 }}>
              <SBFilterDropdown
                data={getTerms()}
                value={dataObj && dataObj.terms ? dataObj.terms : ""}
                labelText={"Select Terms"}
                onChange={(value: any) => onDropDownChange("terms", value)}
              />
              {termsError ? (
                <SBText
                  text={termsError}
                  style={{
                    fontSize: 12,
                    color: "red",
                  }}
                />
              ) : null}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <SBButton
            icon={false}
            text={"Cancel"}
            onClick={() => handleClose()}
            className={classes.requestButton}
          />
          <SBButton
            icon={false}
            text={"Submit"}
            onClick={() => onSubmitAction()}
            className={classes.requestButton}
          />
          {/*<SBButton icon={false} text={"OK"} onClick={handleSubmit} />*/}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChangeCountryDialog;
