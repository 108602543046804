import { FetchAgentParams } from "../types/fetch-agent-params";

export const PAGE_SIZES: number[] = [5, 10, 20, 50];
export const DEFAULT_PAGE_SIZE: number = PAGE_SIZES[0];
export const FIRST_PAGE: number = 0;
export const DEFAULT_FETCH_PARAMS: FetchAgentParams = {
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
  search: "",
  filters: ""
};
