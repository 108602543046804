import { EMAIL_REGEX } from "../../../util/constants";
import { LPO } from "../types";

export const validateLPO = (lpo: LPO): Map<string, string> => {
  const errors: Map<string, string> = new Map();

  if (!lpo.firstName || !lpo.firstName.trim()) {
    errors.set("firstName", "Please enter First Name");
  }

  if (!lpo.lastName || !lpo.lastName.trim()) {
    errors.set("lastName", "Please enter Last Name");
  }

  if (!lpo.phone || !lpo.phone.trim()) {
    errors.set("phone", "Please enter Phone");
  }

  if (!lpo.city || !lpo.city.trim()) {
    errors.set("city", "Please enter City");
  }

  if (!lpo.occupation || !lpo.occupation.trim()) {
    errors.set("occupation", "Please enter Occupation");
  }

  if (!lpo.email || !lpo.email.trim()) {
    errors.set("email", "Please enter Email");
  } else if (!EMAIL_REGEX.test(lpo.email)) {
    errors.set("email", "Invalid Email");
  }

  return errors;
};
