import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from "../../../actions";
import { API_URL } from "../../../util/apiUrls";
import { FetchTlLeadsParams } from "../types";
import {getDownloadLeadsRequestQuery } from "../utils";
import fileDownload from 'js-file-download';
import moment from "moment";


export const useDownloadLeadsData = () => {
  const dispatch = useDispatch();
  const downloadLeads = useCallback(
    async (params: FetchTlLeadsParams) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(
          `${API_URL.LEADS.DOWNLOAD_LEADS}${getDownloadLeadsRequestQuery(params)}`,
          {
            responseType: 'blob',
          },
          dispatch
        );     
        fileDownload(result, moment().format('[./Leads-]MMMDDYYYY_h_mm_ss[.xls]'));
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    downloadLeads,
  };
};
