export enum GroupStatus {
  NotStarted = 'NotStarted',
  PartiallyComplete = 'PartiallyComplete',
  Complete = 'Complete',
}
export const getGroupStatusByQuestionAnswer = (questionList: any) => {
  let isAllQuestionAnswerd = false;
  let isPartiallyQuestionStarted = false;
  for (let i = 0; i < questionList.length; i++) {
    const question = questionList[i];
    const childQuestionList = question.questionList;
    if (question.type !== 'Label') {
      if (question.answer) {
        isPartiallyQuestionStarted = true;
        isAllQuestionAnswerd = true;
      } else {
        isAllQuestionAnswerd = false;
        break;
      }
    }

    if (childQuestionList && childQuestionList.length > 0) {
      for (let j = 0; j < childQuestionList.length; j++) {
        const childQuestion = childQuestionList[j];
        if (childQuestion.type !== 'Label') {
          if (childQuestion.answer) {
            isPartiallyQuestionStarted = true;
            isAllQuestionAnswerd = true;
          } else {
            isAllQuestionAnswerd = false;
            break;
          }
        }
      }
    }
  }

  if (isAllQuestionAnswerd) {
    return GroupStatus.Complete;
  } else if (!isAllQuestionAnswerd && isPartiallyQuestionStarted) {
    return GroupStatus.PartiallyComplete;
  } else {
    return GroupStatus.NotStarted;
  }
};
