import { SvgIcon } from "@material-ui/core";
import React from "react";

export function MarriageIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 57 57" fill="none">
      <g opacity="0.6">
        <path
          d="M28.5 47.2575C32.435 47.2575 35.625 41.154 35.625 33.625C35.625 26.096 32.435 19.9925 28.5 19.9925C24.565 19.9925 21.375 26.096 21.375 33.625C21.375 41.154 24.565 47.2575 28.5 47.2575Z"
          fill="#3D405B"
        />
        <path
          d="M21.47 45.1913C20.6863 45.3813 19.855 45.5 19 45.5C12.445 45.5 7.125 40.18 7.125 33.625C7.125 27.07 12.445 21.75 19 21.75C19.855 21.75 20.6863 21.8688 21.47 22.0588C22.3962 20.7288 23.56 19.3988 25.0087 18.1875C23.1562 17.4512 21.1375 17 19 17C9.8325 17 2.375 24.4575 2.375 33.625C2.375 42.7925 9.8325 50.25 19 50.25C21.1375 50.25 23.1562 49.7988 25.0087 49.0625C23.56 47.8512 22.3962 46.5213 21.47 45.1913Z"
          fill="#3D405B"
        />
        <path
          d="M38 17C35.8625 17 33.8438 17.4512 31.9913 18.1875C33.44 19.3988 34.6037 20.7288 35.53 22.0588C36.3138 21.8688 37.145 21.75 38 21.75C44.555 21.75 49.875 27.07 49.875 33.625C49.875 40.18 44.555 45.5 38 45.5C37.145 45.5 36.3138 45.3813 35.53 45.1913C34.6037 46.5213 33.44 47.8512 31.9913 49.0625C33.8438 49.7988 35.8625 50.25 38 50.25C47.1675 50.25 54.625 42.7925 54.625 33.625C54.625 24.4575 47.1675 17 38 17Z"
          fill="#3D405B"
        />
        <path
          d="M29 18L23 10.3548L25.2918 6L32.7082 6L35 10.3548L29 18Z"
          fill="#3D405B"
        />
      </g>
    </SvgIcon>
  );
}
