import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import config from "../config";
import { RootState } from "../reducer";
import { ActionType, EmailTemplate, UserListFilter } from "../reducer/types";
import { API_URL } from "../util/apiUrls";
import { ENTITY_TYPE } from "../util/constants";
import { get, post, put } from "./common";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const APPEND_USERS = 'APPEND_USERS';

export const UPDATE_USERS_SEARCH_PARAMS = 'UPDATE_USERS_SEARCH_PARAMS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

export const UPDATE_USER_LIST_FILTER = 'UPDATE_USER_LIST_FILTER';
export const CLEAR_USER_LIST_FILTER = 'CLEAR_USER_LIST_FILTER';
export const SET_COUNSELLOR = 'SET_COUNSELLOR';
export const COUNSELLOR_EXPAND_COLLAPSE = 'COUNSELLOR_EXPAND_COLLAPSE'

interface UserSearchParams {
    limit: number;
    offset: number;
    searchText: string;
    filter?: UserListFilter;
}
export const getUserList = (userSearchParams: UserSearchParams): ThunkAction<
    void,
    RootState,
    unknown,
    ActionType
> => async (dispatch, getState) => {
    const { offset } = userSearchParams;
    return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
            dispatch({ type: ON_LOADING_START });
            const result = await post(
                API_URL.USER.LIST,
                userSearchParams,
                dispatch
            );
            await dispatch({
                type: offset > 0 ? APPEND_USERS : FETCH_USERS_SUCCESS,
                payload: result,
            });
            resolve(result);
            dispatch({ type: ON_LOADING_FINISHED });
        }
    );
};

interface EntityParams {
    name: string;
    key: string;
    description?: string;
    permissions: any[];
    [x: string]: any;
}

export const createNewUser = (roleParams: EntityParams
): ThunkAction<void, RootState, unknown, ActionType> => async (
    dispatch,
    getState
) => {
        return new Promise(
            async (resolve: (x: any) => void, reject: (x: any) => void) => {
                dispatch({ type: ON_LOADING_START });
                const result = await post(
                    API_URL.USER.CREATE,
                    roleParams,
                    dispatch
                );
                dispatch(getUserList({ offset: 0, searchText: '', limit: 10 }))
                resolve(result);
                dispatch({ type: ON_LOADING_FINISHED });
            }
        );
};

export const updateUser = (roleParams: EntityParams
): ThunkAction<void, RootState, unknown, ActionType> => async (
    dispatch,
    getState
) => {
    const { ...rest } = roleParams;
        return new Promise(
            async (resolve: (x: any) => void, reject: (x: any) => void) => {
                dispatch({ type: ON_LOADING_START });

                const result = await put(
                    API_URL.USER.BY_ID(rest.id),
                    roleParams,
                    dispatch
                );
                dispatch(getUserList({ offset: 0, searchText: '', limit: 10 }))
                resolve(result);
                dispatch({ type: ON_LOADING_FINISHED });
            }
        );
};

export const getUser = (id: number
): ThunkAction<void, RootState, unknown, ActionType> => async (
    dispatch,
    getState
) => {
        return new Promise(
            async (resolve: (x: any) => void, reject: (x: any) => void) => {
                dispatch({ type: ON_LOADING_START });

                const result = await get(
                    API_URL.USER.BY_ID(id),
                    dispatch
                );
                resolve(result);
                await dispatchUser(result)(dispatch);
                dispatch({ type: ON_LOADING_FINISHED });
            }
        );
    };

export const dispatchUser = (result: any) => async (dispatch: Dispatch) => {
    dispatch({
        type: FETCH_USER_SUCCESS,
        payload: result,
    });
};

export const updateUserListFilter = (filter: UserListFilter) => async (dispatch: Dispatch) => {
    dispatch({
        type: UPDATE_USER_LIST_FILTER,
        payload: filter,
    }); 
}

export const clearUserListFilter = () => async (dispatch: Dispatch) => {
    dispatch({
        type: CLEAR_USER_LIST_FILTER
    }); 
}

export const getChildCounsellor = ( companyId: number): ThunkAction<
    void,
    RootState,
    unknown,
    ActionType
> => async (dispatch, getState) => {
    return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
            dispatch({ type: ON_LOADING_START });
            const result = await get(
                API_URL.USER.GET_CHILD_COUNSELLOR(companyId),
                dispatch
            );
         
            await dispatch({
                type: SET_COUNSELLOR,
                payload: {result, companyId},
            });
            resolve(result);
            dispatch({ type: ON_LOADING_FINISHED });
        }
    );
};

export const updateCounsllorCollapsExpand = (companyId: any) => async (dispatch: Dispatch) => {
    dispatch({
        type: COUNSELLOR_EXPAND_COLLAPSE,
        payload: companyId
    }); 
}
