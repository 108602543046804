import { ThunkAction } from "redux-thunk";
import { LpoAction } from ".";
import {
  post,
  put,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../../actions";
import { RootState } from "../../../../reducer";
import { ActionType } from "../../../../reducer/types";
import { API_URL } from "../../../../util/apiUrls";
import { LPO, LPOModalAPIStatus } from "../../types";
import { get as _get } from "lodash";

export const saveLPOAction = (
  lpo: LPO
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ON_LOADING_START });

  let apiStatus: LPOModalAPIStatus;
  try {
    //Create LPO
    const result = lpo.id
      ? await put(API_URL.LPO.BY_ID(lpo.id), lpo, dispatch)
      : await post(API_URL.LPO.CREATE, lpo, dispatch);
    apiStatus = {
      status: "success",
      errors: new Map(),
    };
  } catch (e) {
    const errrosMap = new Map<string, string>();
    const errors = _get(e, "response.data.message");
    if (errors && Array.isArray(errors)) {
      errors.forEach((error: any) => {
        errrosMap.set(error.field, error.message);
      });
    }
    apiStatus = {
      status: "error",
      errors: errrosMap,
    };
  }

  dispatch({
    type: LpoAction.SET_LPO_MODAL_API_STATUS,
    payload: apiStatus,
  });
  dispatch({ type: ON_LOADING_FINISHED });
};
