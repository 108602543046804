import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  get,
  post,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../../actions";
import { RootState } from "../../../../reducer";
import { ActionType } from "../../../../reducer/types";
import { API_URL } from "../../../../util/apiUrls";
import { FetchLPOParams } from "../../types";
import { getLPORequestQuery } from "../../utils";
import { LpoAction } from "./action-types";

export const onboardLPOAction = (
  id: number,
  fetchLPOParams: FetchLPOParams
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ON_LOADING_START });

  //Onboard LPO
  const result = await post(API_URL.LPO.ONBOARD, { id }, dispatch);

  //Reload LPO list
  if (fetchLPOParams) {
    const result = await get(
      `${API_URL.LPO.LIST}${getLPORequestQuery(fetchLPOParams)}`,
      dispatch
    );
    dispatch({ type: LpoAction.SET_LPO_PAGINATED_DATA, payload: result });
  }

  dispatch({ type: ON_LOADING_FINISHED });
};




