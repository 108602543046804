import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  IconButton,
} from "@material-ui/core";
import SBText from "../../../components/base/SBText";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import AppColor from "../../../theme/AppColor";
import _ from "lodash";
import SBLinkButton from "../../../components/base/SBLinkButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
    modal: {
      display: "flex",
      alignItems: "center",
      height: "100vh",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "red",
      // border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      width: "40%",
      minWidth: 400,
      maxHeight: "95%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    headerParent: {
      height: 60,
      width: "100%",
      backgroundColor: theme.palette.primary.dark,
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      justifyContent: "space-between",
    },
    headerText: {
      color: theme.palette.primary.contrastText,
      fontSize: 18,
      fontWeight: 500,
      [theme.breakpoints.up(1920)]: {
        fontSize: 22,
      },
    },
    body: {
      backgroundColor: theme.palette.common.white,
      position: "relative",
      // padding: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      overflowY: "auto",
      height: "80vh",
      fontSize: 16,
      [theme.breakpoints.up(1920)]: {
        fontSize: 18,
      },
    },
    subjectText: {
      fontSize: 14,
      fontWeight: 700,
    },
    bodyText: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    },
    rowText: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    },
    tableView: {
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#000000",
    },
    tableRow: {
      borderBottomColor: "#000000",
      borderBottomStyle: "solid",
      borderBottomWidth: 1,
      height: 50,
      display: "flex",
    },
    tableTextRow: {
      display: "flex",
      flex: 0.6,
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableButtonRow: {
      display: "flex",
      flex: 0.4,
      alignItems: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableSmallDivider: { width: 1, backgroundColor: "#000000" },
    approveBt: {
      backgroundColor: "#8BB19C",
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    disApproveBt: {
      backgroundColor: "#8BB19C",
      height: 38,
      borderRadius: 20,
      flex: 1,
    },

    greyBt: {
      backgroundColor: "#979797",
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    okTOProceedBt: {
      backgroundColor: AppColor.appGreen,
      height: 38,
      borderRadius: 20,
      width: 340,
      marginLeft: 30,
    },
    notValid: {
      backgroundColor: AppColor.appRed,
      height: 38,
      width: 215,
      borderRadius: 20,
    },
    middleDivider: {
      width: theme.spacing(2),
    },
  })
);


type PreQuestionDetailsModelProps = {
  className?: string;
  open: boolean;
  onCancel: () => void;
  onChange: (obj: any) => void;
  onChangeOther: (obj:any) => void;
  data: any;
  isChangeAvailable: boolean;
  application: any;
};
const PreQuestionDetailsModel: React.FC<PreQuestionDetailsModelProps> = ({
  open,
  onCancel,
  onChange,
  onChangeOther,
  data,
  isChangeAvailable,
  application
}) => {

  const isNewApp = application.isNewApp
  const classes = useStyles();
  const getSortedQuestions = () => {
      let question: any = [];
    if (data && data.questions) {
      for (let i = 0; i < data.questions.length; i++) {
        if (data.questions[i].status != "DISABLED") {
          question.push(data.questions[i]);
          if(data.questions[i].questionList && data.questions[i].questionList.length>0) {
            for (let j = 0; j < data.questions[i].questionList.length; j++) {
              if (data.questions[i].questionList[j].status != "DISABLED") {
                question.push(data.questions[i].questionList[j]);
              }
            }
          }
        }
      }

      if (question[0].questionOrder) {
        question = _.sortBy(question, (dateObj) => {
          return parseFloat(dateObj.questionOrder);
        });
      } else {
        question = _.sortBy(question, (dateObj) => {
          return parseFloat(dateObj.order);
        });
      }
    }
    return question;
  };

  const questionList = getSortedQuestions();

  const handleClose = () => {
    onCancel();
  };

  const handleOnChange = (question:any) => {
    const selectedDetails: any = {};
    const others:any = []
    for (let i = 0; i < questionList.length; i++) {
      if (questionList[i].key == "Q_DESTCOUNTRY") {
        selectedDetails["country"] = getQuestionAnswer(questionList[i]);
      } else if (questionList[i].key == "Q_CAMPUS") {
        selectedDetails["city"] = getQuestionAnswer(questionList[i]);
      } else if (questionList[i].key == "Q_PROVIDER") {
        selectedDetails["provider"] = getQuestionAnswer(questionList[i]);
      } else if (questionList[i].key == "Q_COURSELEVEL") {
        selectedDetails["category"] = getQuestionAnswer(questionList[i]);
      } else if (questionList[i].key == "Q_COURSE") {
        selectedDetails["course"] = getQuestionAnswer(questionList[i]);
      } else if (questionList[i].key == "Q_INTAKE") {
        selectedDetails["terms"] = getQuestionAnswer(questionList[i]);
      }  else {
        others.push({question:questionList[i], answer: getQuestionAnswer(questionList[i])})
      } 
    }

    // console.log("selected Details ::::", selectedDetails)
    // console.log("Pther ", others)
    if (
      question.key == "Q_DESTCOUNTRY" ||
      question.key == "Q_CAMPUS" ||
      question.key == "Q_PROVIDER" ||
      question.key == "Q_COURSELEVEL" ||
      question.key == "Q_COURSE" ||
      question.key == "Q_INTAKE"
    ) {
      onChange({ selectedDetails, type: question.key });
    } else {
      onChangeOther(others)
      console.log("Pther ", others)
    }
  
  };

const getQuestionAnswer = (question: any) => {
  const answerObj = _.find(application.allAnswers, (obj:any)=> obj.id == question.id)
  if(answerObj) {
    return answerObj.finalAnswer
  } else {
    return "" 
  }
  };

  console.log("isChange Available", isChangeAvailable);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.headerParent}>
            <SBText
              text={"Application Details"}
              className={classes.headerText}
            />
            <IconButton onClick={() => handleClose()}>
              <HighlightOffIcon style={{ color: "white" }} />
            </IconButton>
            {/*<img src={close} onClick={handleClose} style={{ width: 25 }} />*/}
          </div>

          <div className={classes.body}>
            {questionList.map((question: any) => (
              <div
                style={{
                  minHeight: 60,
                  display: "flex",
                  flexDirection: "column",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  borderBottomColor: AppColor.appLightGrey,
                  padding: 10,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                <SBText text={question.question} style={{ fontSize: 14 }} />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SBText
                    text={getQuestionAnswer(question)}
                    style={{ fontSize: 12, color: AppColor.appDarkGrey }}
                  />

                  {question.key &&
                  (question.key == "Q_DESTCOUNTRY" ||
                    question.key == "Q_CAMPUS" ||
                    question.key == "Q_PROVIDER" ||
                    question.key == "Q_COURSELEVEL" ||
                    question.key == "Q_COURSE" ||
                    question.key == "Q_INTAKE") || isNewApp &&
                  isChangeAvailable ? (
                    <SBLinkButton
                      text={"Change"}
                      onClick={() => handleOnChange(question)}
                      style={{ fontSize: 10, textTransform: "none" }}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default PreQuestionDetailsModel;
