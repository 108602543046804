import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Theme,
  useMediaQuery,
  MenuItem,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import AppColor from '../../../theme/AppColor';
import { Lead, LEAD_STATUS, createdByUser } from '../types';
import SBText from '../../../components/base/SBText';
import SBButton from '../../../components/base/SBButton';
import { LABELS } from '../../../util/labels';
import { number } from 'yup/lib/locale';
// import { formDialogStyle } from '../../../styles';

// const useStyles = makeStyles((theme: Theme) => createStyles(formDialogStyle(theme)));
const useStyles = makeStyles((theme) => ({
  paper: {
    // backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '58%',
    // boxShadow: theme.shadows[5],
  },
  sbDialog: {
    '& .MuiDialogTitle-root': {
      padding: 0,
      '& .MuiTypography-root': {
        minHeight: 70,
        width: '100%',
        backgroundColor: AppColor.appBlue,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        justifyContent: 'space-between',
        '& p': {
          fontSize: 16,
          color: AppColor.appWhite,
          fontWeight: 600,
          paddingLeft: 20,
        },
      },
    },
    '& .MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '15px',
      marginBottom: '15px',
      borderTop: '1px solid #DDD',
      padding: 0,
      paddingTop: 20,
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 5,
    },
  },
  formField: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: '0px 14px 12px',
    alignItems: 'flex-start',
    gap: 6,
  },
  Label: {
    width: '100%',
    paddingTop: '15px',
    paddingBottom: '5px',
  },
  LabelText: {
    color: AppColor.appDarkGrey,
    fontSize: 13,
  },
  ValueText: {
    fontSize: 15,
  },
  multipleButtonsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    '& .MuiButtonBase-root': {
      paddingRight: '12px',
      paddingLeft: '12px',
      marginLeft: '12px',
    },
  },
}));
interface LeadsModalProps {
  leadData?: Lead;
  open: any;
  onClose: () => void;
}

const defaultFormData: Lead = {
  id: null,
  applicantFirstName: '',
  applicantLastName: '',
  emailId: '',
  phoneNumber: '',
  applicantCity: '',
  applicantCountry: '',
  preferredCountry: '',
  preferredCourse: '',
  convertedApplicationId: null,
  createdBy: null,
  status: LEAD_STATUS.DRAFT,
  comment: '',
  createdByUser: { id: null, name: '', email: '' },
};

const LeadsModal: React.FC<LeadsModalProps> = ({ leadData = defaultFormData, open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  console.log('leadData', leadData);
  const [formData, setFormData] = useState<Lead>(leadData);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    resetForm();
  }, [open]);

  useEffect(() => {
    setFormData(leadData);
    setFormDisabled(leadData.status === LEAD_STATUS.SUBMITTED_TO_SB);
  }, [leadData]);

  const resetForm = () => {
    setFormData(defaultFormData);
  };

  return (
    <Dialog
      fullScreen={isMobileView}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      className={classes.sbDialog}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        <SBText style={{ fontSize: 22 }} text={LABELS.LEAD_DETAILS} />
        <IconButton aria-label="close" onClick={() => onClose()}>
          <CloseIcon onClick={() => onClose()} style={{ color: AppColor.appWhite }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form autoComplete="off" style={{ paddingTop: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                  <SBText className={classes.LabelText} text={LABELS.APPLICANT_FIRST_NAME} />
                </div>
                <SBText className={classes.ValueText} text={formData.applicantFirstName} />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                  <SBText className={classes.LabelText} text={LABELS.APPLICANT_LAST_NAME} />
                </div>
                <SBText className={classes.ValueText} text={formData.applicantLastName} />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                  <SBText className={classes.LabelText} text={LABELS.PHONE_NUMBER} />
                </div>
                {/* <MuiPhoneNumber
                  defaultCountry={'in'}
                  className={classes.ValueText}
                  fullWidth
                  disabled={formDisabled}
                  variant="outlined"
                  autoFormat={false}
                  countryCodeEditable={false}
                  disableAreaCodes={true}
                  value={formData.phoneNumber}
                  style={{ border: 0 }}
                /> */}
                <SBText className={classes.ValueText} text={formData.phoneNumber} />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                  <SBText className={classes.LabelText} text={LABELS.EMAIL} />
                </div>
                <SBText className={classes.ValueText} text={formData.emailId} />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                  <SBText className={classes.LabelText} text={LABELS.APPLICANT_CITY} />
                </div>
                <SBText className={classes.ValueText} text={formData.applicantCity} />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                  <SBText className={classes.LabelText} text={LABELS.APPLICANT_COUNTRY} />
                </div>
                <SBText className={classes.ValueText} text={formData.applicantCountry} />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                  <SBText className={classes.LabelText} text={LABELS.PREFERRED_COUNTRY} />
                </div>
                <SBText className={classes.ValueText} text={formData.preferredCountry} />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                  <SBText className={classes.LabelText} text={LABELS.PREFERRED_COURSE} />
                </div>
                <SBText className={classes.ValueText} text={formData.preferredCourse} />
              </div>
            </Grid>
            <Grid item xs={12} style={{ padding: 0 }}>
              <div className={classes.formField} style={{ width: '100%' }}>
                <div className={classes.Label}>
                  <SBText className={classes.LabelText} text={LABELS.ADDITIONAL_COMMENT} />
                </div>
                <SBText className={classes.ValueText} text={formData.comment} />
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <SBButton icon={false} text={LABELS.CLOSE} color="primary" onClick={() => onClose()} />
      </DialogActions>
    </Dialog>
  );
};

export default LeadsModal;
