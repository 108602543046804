import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Dashboard from '../../components/layout/Dashboard';
// import Application from "../Application";
import NewApplication from '../SBApplication';
import Inbox from '../Inbox';
import Admin from '../Admin';
import SBApplicationList from '../SBApplicationList';
import SBProviderApplicationList from '../SBProviderApplicationList';
import DMS from '../DMS';
import DashboardDefault from '../../views/Overview';
import CompanyProfile from '../CompanyProfile';
import AgentView from '../Agent';
import { PAGE_ROUTE } from '../../util/pageRoutes';
import GuardedRoute from './GuardedRoute';
import { PERMISSION } from '../../util/rolePermissionsUtil';
import LPO from '../LPO';
import Leads from '../Leads';
import DashboardRoute from './DashboardRoute';
import Reports from '../Reports';

type DashboardRouteProps = {};
const DashboardRouteComponent: React.FC<DashboardRouteProps> = ({ ...rest }) => {
  return (
    <Dashboard {...rest}>
      <Switch>
        <GuardedRoute
          path={'/app/:id/group/:groupId/question/:questionId'}
          component={NewApplication}
          permission={PERMISSION.applications}
        />
        <GuardedRoute path={'/inbox/:applicationId'} component={Inbox} permission={PERMISSION.verifications} />
        <GuardedRoute path={PAGE_ROUTE.INBOX} component={Inbox} permission={PERMISSION.verifications} />
        <GuardedRoute path={PAGE_ROUTE.USERS} component={Admin} permission={PERMISSION.user} />
        <GuardedRoute
          path={PAGE_ROUTE.APP_LIST_AGENCY}
          component={SBApplicationList}
          permission={PERMISSION.applications_agent_view}
        />
        <GuardedRoute
          path={PAGE_ROUTE.APP_LIST_PROVIDER}
          component={SBProviderApplicationList}
          permission={PERMISSION.applications_provider_view}
        />
        <GuardedRoute path={'/files/:applicationId/:block'} component={DMS} permission={PERMISSION.dms} />
        <GuardedRoute path={PAGE_ROUTE.DMS} component={DMS} permission={PERMISSION.dms} />
        <GuardedRoute path={PAGE_ROUTE.TA} component={LPO} permission={PERMISSION.ta} />

        <GuardedRoute path={PAGE_ROUTE.LEADS} component={Leads} permission={PERMISSION.ta} />

        <GuardedRoute path={PAGE_ROUTE.REPORTS} component={Reports} permission={PERMISSION.reports} />
        <GuardedRoute path={PAGE_ROUTE.AGENTS} component={AgentView} permission={PERMISSION.agentManagement} />
        <GuardedRoute path={PAGE_ROUTE.COMPANY} component={CompanyProfile} permission={PERMISSION.common} />
        <DashboardRoute path={'/'} component={DashboardDefault} />
      </Switch>
    </Dashboard>
  );
};

export default DashboardRouteComponent;
