import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
  post,
} from "../../../actions";
import { API_URL } from "../../../util/apiUrls";
import { LpoAction } from "../store/actions/action-types";
import { FetchLPOParams } from "../types";
import { getLPORequestQuery } from "../utils";

export const useRejectLPO = () => {
  const dispatch = useDispatch();

  const rejectLPO = useCallback(
    async (lpoId: number, fetchLPOParams: FetchLPOParams, reason:string) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await post(`${API_URL.LPO.REJECT(lpoId)}`, {reason}, dispatch);

        //Reload LPO list
        if (fetchLPOParams) {
          const result = await get(
            `${API_URL.LPO.LIST}${getLPORequestQuery(fetchLPOParams)}`,
            dispatch
          );
          dispatch({ type: LpoAction.SET_LPO_PAGINATED_DATA, payload: result });
        }
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    rejectLPO,
  };
};
