import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AppColor from "../../theme/AppColor";
import SBText from "../../components/base/SBText";
import { IconButton, List, TextField } from "@material-ui/core";
import moment from "moment";
import Button from "@material-ui/core/Button";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import { getApplicationIdInString } from "../../util";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import _ from "lodash";
import SBButton from "../../components/base/SBButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SBFilterDropdown from "../../components/base/SBFilterDropdown";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    display: "flex",
    flexDirection: "column",
    width: 500,
    height: 300,
    alignItems: "center",
  },
  root: {
    width: "100%",
    paddingLeft: 0,
    overflowY: "scroll",
    // backgroundColor: theme.palette.background.paper,
  },
  Lable: {
    width: "80%",
    paddingTop: "15px",
    paddingBottom: "5px",
  },
  fileUpload: {
    height: "100px !important",
    border: "1px solid black !important",
  },
  ul: {
    padding: 0,
  },
  lableFont: {
    fontSize: "14px",
  },
  textAreaWidth: {
    width: "90%",
    border: "1px solid black",
    borderRadius: "5px",
    // height: 100,
    marginTop: 20,
  },
}));

type ApplicationStatusReasonModelProps = {
  className?: string;
  open: any;
  onClose: () => void;
  onSetReason: (obj: any, other:any) => void;
  reason: string;
  data: any
};

const ApplicationStatusReasonModel: React.FC<ApplicationStatusReasonModelProps> = ({
  children,
  onClose,
  className,
  open,
  onSetReason,
  data,
  ...rest
}) => {
  const classes = useStyles();
  const [reason, setReason] = useState("");
  const [other, setOther] = useState("");

  const handleClose = () => {
    // setOpen(false);
  };

  const onCloseModel = () => {
    setReason("");
    onClose();
  };

  const handleChange = (event: any) => {
    // event.persist();
    // const value = event.target.value;
    // const name = event.target.name;
    // setFormData({ ...formdata, [name]: value });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div
            style={{
              minHeight: 50,
              width: "100%",
              backgroundColor: AppColor.appBlue,
              display: "flex",
              alignItems: "center",
              paddingLeft: 16,
              paddingRight: 16,
              justifyContent: "space-between",
            }}
          >
            <SBText
              text={"Enter Reason"}
              style={{
                fontSize: 20,
                color: AppColor.appWhite,
                fontWeight: 700,
              }}
            />
            <IconButton
              onClick={() => {
                onClose();
              }}
            >
              <HighlightOffIcon style={{ color: "white" }} />
            </IconButton>
          </div>

          <div style={{width:'90%', marginTop:10}}>
          <SBFilterDropdown
            data={data}
            value={reason}
            labelText={"Select Reason"}
            onChange={(value: any) => setReason(value)}
          />
          </div>    

          {/*<div className={classes.Lable}>*/}
          {/*  <SBText className={classes.lableFont} text="Reference Number" />*/}
          {/*</div>*/}
         
          <TextField
            id="outlined-multiline-static"
            multiline
            rows={2}
            variant="outlined"
            className={classes.textAreaWidth}
            value={other}
            onChange={(event) => setOther(event.target.value)}
            disabled={!reason || reason != "Other"}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: 80,
              paddingLeft: 20,
              paddingRight: 20,
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <SBButton
              text={"Done"}
              icon={false}
              onClick={() => {
                if (reason) {
                  if(reason == "Other" && !other) {
                    return
                  }
                  onSetReason(reason, other);
                  setReason("");
                  setOther("");
                }
              }}
              style={{ height: 45, width: 100 }}
            />
          </div>

          {/*<TextField*/}
          {/*  className={classes.textAreaWidth}*/}
          {/*  id="outlined-multiline-static"*/}
          {/*  variant="outlined"*/}
          {/*  onChange={handleChange}*/}
          {/*  value={"data"}*/}
          {/*  name="refNum"*/}
          {/*/>*/}
        </div>
      </Fade>
    </Modal>
  );
};

export default ApplicationStatusReasonModel;
