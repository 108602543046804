import React, { useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import {
  makeStyles,
  createStyles,
  Theme,
  TextField,
  InputAdornment,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchbox: {
      border: "none",
      width:"100%",
      "& .MuiOutlinedInput-root": {
        position: "unset",
      },
      "& .MuiInputBase-input": {
        color: "#000000",
        fontFamily: "Poppins",
        fontWeight: 400,
        letterSpacing: "0.75px",
        textTransform: "none",
        fontSize: 14,
      },
      "& fieldset": {
        border: "unset",
      },
    },
    inherited: {
      "& .MuiFormControl-root": {
        position: "unset",
      },
      height: "fit-content",
    },
    input: {
      height: 40,
      width: '100%',
      border: "none",
      backgroundColor: "white",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
  })
);

interface SearchProps {
  onSearch?: any;
  placeHolder?: string;
}

const SBSearch: React.FC<SearchProps> = ({
  onSearch,
  placeHolder = "Search Text",
}) => {
  const [searchText, setSearchText] = useState("");

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      onSearch(searchText);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.inherited}>
      <TextField
        id="input-with-icon-textfield"
        variant="outlined"
        placeholder={placeHolder}
        className={`search-box ${classes.searchbox}`}
        onChange={handleTextChange}
        value={searchText}
        onKeyPress={handleKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment
              style={{ cursor: "pointer" }}
              position="end"
              onClick={() => onSearch(searchText)}
            >
              <SearchIcon />
            </InputAdornment>
          ),
          className: classes.input,
        }}
      />
    </div>
  );
};

export default SBSearch;
