import { connect, ConnectedProps } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../../reducer';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme, Divider, TextField } from '@material-ui/core';
import clsx from 'clsx';
import SBText from '../../../components/base/SBText';
import _ from 'lodash';
import { getApplicationIdInString } from '../../../util';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: 3,
      overflowX: 'hidden',
      overflowY: 'hidden',
      height: 'calc(100% - 52px)',
    },
    sort: {
      width: 260,
      borderWidth: 0,
      borderRadius: 0,
    },
    listItem: {
      height: 78,
      width: '100%',
      padding: 16,
      '&:hover': {
        backgroundColor: '#F7F7F7',
      },
    },
    listItemSelected: {
      height: 78,
      width: '100%',
      padding: 16,
      backgroundColor: '#EFEFEF',
    },
    studentNameText: {
      color: '#8F928E',
      marginTop: 5,
      fontSize: 12,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    applicationID: {
      fontSize: 14,
      fontWeight: 500,
      [theme.breakpoints.up(1920)]: {
        fontSize: 15,
      },
    },
    listParent: {
      height: 'calc(100% - 60px)',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    emptyText: {
      display: 'flex',
      justifyContent: 'space-around',
      color: 'gray',
      height: '100%',
      alignItems: 'center',
    },
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type DMSApplicationListProps = PropsFromRedux & {
  className?: string;
  selectedApplication: string;
  onListItemClick: (obj: any, data: any) => void;
  list: any;
  onSearchText: (text: string) => void;
};
const DMSApplicationList: React.FC<DMSApplicationListProps> = ({
  onListItemClick,
  list,
  onSearchText,
  selectedApplication,
  className,
}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (selectedApplication && list) {
      const index = list.map((app: any) => app.id).indexOf(+selectedApplication);
      if (index > -1) {
        onItemClick(list[index], index);
        document?.getElementById(`app-${selectedApplication}`)?.scrollIntoView();
      }
    }

    if (selectedApplication != '0') {
      setSearchText(selectedApplication ? getApplicationIdInString(selectedApplication) : selectedApplication);
    }
  }, [list, selectedApplication]);

  const onItemClick = (object: any, index: number) => {
    setSelectedIndex(index);
    onListItemClick(object, index);
  };

  const getStudentName = (answers: any) => {
    const firstName = _.find(answers, (obj: any) => obj.key == 'Q_FIRSTNAME');
    const lastName = _.find(answers, (obj: any) => obj.key == 'Q_LASTNAME');
    if (firstName && lastName) {
      let name = '';
      if (firstName.value) {
        name = firstName.value;
      }
      if (lastName.value) {
        if (name) {
          name = name + ' ' + lastName.value;
        } else {
          name = lastName.value;
        }
      }
      return name;
    } else {
      const studentNameObj = _.find(answers, (obj: any) => obj.key == 'Q_NAME');
      if (studentNameObj != null) {
        return studentNameObj.value;
      } else {
        return '';
      }
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      {/*<SortBy className={classes.sort} dropDownWidth={260} />*/}
      {/*<Divider />*/}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'white',
          alignItems: 'center',
          paddingLeft: 10,
          paddingRight: 5,
          height: 50,
          marginBottom: 10,
        }}
      >
        <SearchIcon />

        <TextField
          id="standard-basic"
          fullWidth={true}
          InputProps={{ disableUnderline: true }}
          placeholder={'Search by Application Id'}
          style={{ marginLeft: 10 }}
          onChange={(event) => setSearchText(event.target.value)}
          value={searchText}
          onKeyPress={(ev: any) => {
            if (ev.key === 'Enter') {
              onSearchText(searchText);
              ev.preventDefault();
            }
          }}
        />
      </div>
      {/*<Divider />*/}
      <div className={classes.listParent}>
        {list &&
          list?.map((object: any, index: number) => (
            <div key={object.id} id={`app-${object.id}`}>
              <div
                style={{ display: 'flex' }}
                className={selectedIndex === index ? classes.listItemSelected : classes.listItem}
                onClick={() => onItemClick(object, index)}
              >
                <div style={{ flex: 1, minWidth: 0 }}>
                  <SBText text={getApplicationIdInString(object.id)} className={classes.applicationID} />
                  <SBText text={getStudentName(object.keyAnswers)} className={classes.studentNameText} />
                </div>
                <div
                  style={{
                    flex: '0 0 50px',
                    padding: '5px 1px',
                    borderRadius: '10px',
                    background:
                      object.uploadedMandatoryFiles === object.totalMandatoryFiles
                        ? '#E9F3EB'
                        : (object.uploadedMandatoryFiles / object.totalMandatoryFiles) * 100 > 50
                        ? '#F7EBD5'
                        : '#EDCCC1',
                    fontSize: '14px',
                    textAlign: 'center',
                    lineHeight: '18px',
                  }}
                >
                  {object.uploadedMandatoryFiles}/{object.totalMandatoryFiles} <small>Files</small>
                </div>
              </div>
              <Divider />
            </div>
          ))}

        {searchText && list && list.length == 0 && <div className={classes.emptyText}>No Applications found</div>}
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(DMSApplicationList);
