import { Theme } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import AppColor from '../../theme/AppColor';
import SBButton from '../base/SBButton';
import SBText from '../base/SBText';
import close from './assets/close_model.png';

// import logo from "./assets/atmc_logo.gif";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      height: '100vh',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'red',
      // border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      width: '60%',
      minWidth: 600,
      maxHeight: '95%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerParent: {
      height: 60,
      width: '100%',
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      justifyContent: 'space-between',
    },
    headerText: {
      color: theme.palette.primary.contrastText,
      fontSize: 18,
      fontWeight: 500,
      [theme.breakpoints.up(1920)]: {
        fontSize: 22,
      },
    },
    body: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      height: '80vh',
      fontSize: 16,
      [theme.breakpoints.up(1920)]: {
        fontSize: 18,
      },
    },
    subjectText: {
      fontSize: 14,
      fontWeight: 700,
    },
    bodyText: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    rowText: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    tableView: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#000000',
    },
    tableRow: {
      borderBottomColor: '#000000',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      height: 50,
      display: 'flex',
    },
    tableTextRow: {
      display: 'flex',
      flex: 0.6,
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableButtonRow: {
      display: 'flex',
      flex: 0.4,
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableSmallDivider: { width: 1, backgroundColor: '#000000' },
    approveBt: {
      backgroundColor: '#8BB19C',
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    disApproveBt: {
      backgroundColor: '#8BB19C',
      height: 38,
      borderRadius: 20,
      flex: 1,
    },

    greyBt: {
      backgroundColor: '#979797',
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    okTOProceedBt: {
      backgroundColor: AppColor.appGreen,
      height: 38,
      borderRadius: 20,
      width: 340,
      marginLeft: 30,
    },
    notValid: {
      backgroundColor: AppColor.appRed,
      height: 38,
      width: 215,
      borderRadius: 20,
    },
    middleDivider: {
      width: theme.spacing(2),
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type SBAutoVerificationTemplateProps = {
  className?: string;
  open: boolean;
  onConfirm: (body: any) => void;
  onCancel: () => void;
  onReject: (body: any) => void;
  showButton: boolean;
  value: any;
  dataObject: any;
};
const SBAutoVerificationTemplate: React.FC<SBAutoVerificationTemplateProps> = ({
  open,
  onConfirm,
  onCancel,
  showButton,
  value,
  onReject,
  dataObject,
}) => {
  const classes = useStyles();
  const [inputText, setInputText] = React.useState('');

  const handleClose = () => {
    onCancel();
  };

  const onApprovedClicked = () => {
    // questionId
    //  status
    //rollNo

    const body = {
      questionId: dataObject.id,
      status: 'Approved',
      rollNo: value.rollNo,
    };
    onConfirm(body);
  };

  const onRejectClicked = () => {
    // questionId
    //  status
    //rollNo

    const body = {
      questionId: dataObject.id,
      status: 'Disapproved',
      rollNo: value.rollNo,
    };
    onReject(body);
  };

  const getBody = (value: any) => {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: value }} contentEditable={'false'}></div>
      </div>
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.headerParent}>
            <SBText text={'Marksheet'} className={classes.headerText} />
            <img src={close} onClick={handleClose} style={{ width: 25 }} />
          </div>

          <div className={classes.body}>{value && value.html ? getBody(value.html) : ''}</div>

          {showButton ? (
            <div
              style={{
                backgroundColor: 'white',
                display: 'flex',
                height: 50,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SBButton
                text={'Information Mismatch'}
                icon={false}
                onClick={() => onRejectClicked()}
                className={classes.notValid}
              />

              <SBButton
                text={'I verify that the Information is Correct'}
                icon={false}
                onClick={() => onApprovedClicked()}
                className={classes.okTOProceedBt}
              />
            </div>
          ) : null}
        </div>
      </Fade>
    </Modal>
  );
};

export default SBAutoVerificationTemplate;
