import * as actionTypes from '../actions';
import {ActionType} from './types'

const initialState = {
    user: {firstName:'Yogesh'},
    currentAppointmentIndex: 0
};

const sessionReducer = (state:any = initialState, action:ActionType) => {
    switch (action.type) {
        case actionTypes.SESSION_LOGIN: {
            return {
                ...initialState,
                user: action.payload,
            };
        }
        case actionTypes.SESSION_LOGOUT: {
            return {
                ...state,
                user: null
            };
        }
        default: {
            return state;
        }
    }
};

export default sessionReducer;
