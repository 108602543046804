import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';

export const tableStyles: (theme: Theme) => StyleRules = (theme: Theme): StyleRules => ({
  TableRow: {
    backgroundColor: '#efefef',
    fontWeight: 500,
  },
  TableBodyRow: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  HeaderPadding: {
    fontWeight: 500,
    fontSize: '13px',
  },
  bodyText: {
    fontSize: '13px',
  },
  tableParents: {
    display: 'flex',
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  table: {
    [theme.breakpoints.up('md')]: {
      minWidth: 700,
    },
    '& .MuiTableCell-root': {
      [theme.breakpoints.down('xs')]: {
        padding: 15,
      },
    },
  },
  mainLayout: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.117647) 2px 4px 6px 4px',
    padding: 16,
    // overflow: "scroll",
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 285px)',
    },
    overflow: 'hidden',
  },
  statusRenderer: {
    height: 8,
    width: 8,
    borderRadius: 8,
    position: 'absolute',
    top: '42%',
  },
  applicantCol: {
    display: 'flex',
    position: 'relative',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  rowActions: { display: 'flex' },
  inlineAction: {
    paddingRight: '10px',
  },
  tableWithPagination: {
    height: 'calc(100% - 60px)',
  },
});
