import { List, ListItem, ListItemText, Theme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Lead } from '../types';
import { LABELS } from '../../../util/labels';
import { getLead } from '../store/actions/get-lead-action';
import { selectedLeadDetails } from '../store/selectors';
import SBText from '../../../components/base/SBText';

const useStylesLeads = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiListItem-gutters': {
      padding: 0,
    },
    '& .MuiTypography-body1': {
      color: '#727487',
      fontSize: '12px',
      lineHeight: 1.3,
    },
    '& .MuiTypography-body2': {
      color: '#263238',
      fontSize: '14px',
    },
  },

  headerText: {
    color: theme.palette.common.black,
    fontFamily: 'Poppins',
    textTransform: 'none',
    fontWeight: 400,
    letterSpacing: 0.75,
    fontSize: 8,
  },
  valueText: {
    color: '#00000',
    fontFamily: 'Poppins',
    textTransform: 'none',
    fontWeight: 600,
    letterSpacing: 0.75,
  },
}));

interface LeadsDetailsProps {
  leadData: Lead;
}

const LeadsDetails: React.FC<LeadsDetailsProps> = ({ leadData }) => {
  const classes = useStylesLeads();


  return (
    <List className={classes.root}>
      <ListItem>
        <ListItemText>
          <SBText text={`${LABELS.APPLICANT_FIRST_NAME}:`} className={classes.headerText} />
          <SBText text={leadData?.applicantFirstName} className={classes.valueText} />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <SBText text={`${LABELS.APPLICANT_LAST_NAME}:`} className={classes.headerText} />
          <SBText text={leadData?.applicantLastName} className={classes.valueText} />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <SBText text={`${LABELS.PHONE_NUMBER}:`} className={classes.headerText} />
          <SBText text={leadData?.phoneNumber} className={classes.valueText} />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <SBText text={`${LABELS.EMAIL}:`} className={classes.headerText} />
          <SBText text={leadData?.emailId} className={classes.valueText} />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <SBText text={`${LABELS.APPLICANT_CITY}:`} className={classes.headerText} />
          <SBText text={leadData?.applicantCity} className={classes.valueText} />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <SBText text={`${LABELS.APPLICANT_COUNTRY}:`} className={classes.headerText} />
          <SBText text={leadData?.applicantCountry} className={classes.valueText} />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <SBText text={`${LABELS.PREFERRED_COUNTRY}:`} className={classes.headerText} />
          <SBText text={leadData?.preferredCountry} className={classes.valueText} />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <SBText text={`${LABELS.PREFERRED_COURSE}:`} className={classes.headerText} />
          <SBText text={leadData?.preferredCourse} className={classes.valueText} />
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText>
          <SBText text={`${LABELS.ADDITIONAL_COMMENT}:`} className={classes.headerText} />
          <SBText text={leadData?.comment || '-'} className={classes.valueText} />
        </ListItemText>
      </ListItem>
    </List>
  );
};

export default LeadsDetails;
