import * as actionTypes from "../actions";
import {
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE,
  INITIAL_TABLE_DATA,
} from "../views/ProviderList/constants";
import { ProviderPaginatedData } from "../views/ProviderList/types";
import { ActionType, Provider } from "./types";

type ProviderState = {
  all: Provider[];
  tableData: ProviderPaginatedData;
};

const initialState: ProviderState = {
  all: [],
  tableData: { ...INITIAL_TABLE_DATA },
};

const providerReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_PROVIDERS_SUCCESS: {
      return {
        ...state,
        all: action.payload?.data,
      };
    }
    case actionTypes.FETCH_PROVIDERS_SUCCESS: {
      return {
        ...state,
        tableData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default providerReducer;
