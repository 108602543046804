export const PAGE_ROUTE = {
  HOME: '/home',
  // DASHBOARD: '/',
  APP_LIST_AGENCY: '/appList',
  APP_LIST_PROVIDER: '/applications',
  INBOX: '/inbox',
  REPORTS: '/reports',
  DMS: '/files',
  LPO: '/lpo',
  TA: '/ta',
  LEADS: '/leads',
  AGENTS: '/agent',
  COMPANY: '/companyProfile',
  USERS: '/users',
};
