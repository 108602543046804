import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppColor from '../../theme/AppColor';
import SBText from '../../components/base/SBText';
import { colors, Divider } from '@material-ui/core';

import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import CheckIcon from '@material-ui/icons/Check';

import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    width: '24%',
    display: 'flex',
    flexDirection: 'column',
    height: '70%',
    marginLeft: '24%',
    position: 'relative',
    borderRadius: 4,
  },
  rejBtn: { backgroundColor: '#f3d3c9', padding: '2px 5px 2px 5px', borderRadius: 2, minWidth: 0, maxHeight: 23 },
  docBtn: { backgroundColor: '#cacbcf', padding: '2px 5px 2px 5px', borderRadius: 2, minWidth: 0, maxHeight: 23 },
  aprBtn: { backgroundColor: '#c5e1bc', padding: '2px 5px 2px 5px', borderRadius: 2, minWidth: 0, maxHeight: 23 },
  createdByText: {
    fontSize: 11,
    fontWeight: 500,
    color: '#393b50',
    [theme.breakpoints.up(1920)]: {
      fontSize: 12,
    },
    marginLeft: 11,
  },
  listParent: {
    minHeight: 60,
    width: '100%',
    backgroundColor: AppColor.appBlue,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },

  selectAgentTxt: {
    fontSize: 20,
    color: AppColor.appWhite,
    fontWeight: 500,
    [theme.breakpoints.up(1920)]: {
      fontSize: 22,
    },
  },
  createdAtText: {
    fontSize: 10,
    color: '#63667C',
    marginLeft: 11,
  },
  labelText: {
    color: '#63667C',
    fontSize: 13,
    margin: '20px 20px 0px 20px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  commentText: {
    fontSize: 10,
    color: '#63667C',
  },
  reviewListParent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    flexDirection: 'column',
  },
}));

type ReviewHistoryProps = {
  className?: string;
  open: any;
  onClose: () => void;
  Action: string;
  label: string;
  questionId: Number;
  onAppoveOrReject: (reviewStatus: boolean, comment: string, questionId: Number) => void;
  clearReviewHistory: () => void;
  data: any;
  // onSelectAgent: (obj: any) => void;
  // onGetSubAgent: (companyId: number) => void;
  // onCollapseExpand: (companyId: number) => void;
  isVNTUser: boolean;
  onSearchText: (searchText: any) => void;
};

const ReviewHistory: React.FC<ReviewHistoryProps> = ({
  children,
  onClose,
  className,
  open,
  Action,
  label,
  questionId,
  data,
  // onSelectAgent,
  // onGetSubAgent,
  // onCollapseExpand,
  isVNTUser,
  onSearchText,
  onAppoveOrReject,
  clearReviewHistory,
  ...rest
}) => {
  const classes = useStyles();
  const onCloseModel = () => {
    clearReviewHistory();
    onClose();
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.listParent}>
            <SBText text= {"Review Log"} className={classes.selectAgentTxt} />
            <CloseIcon
              onClick={() => onCloseModel()}
              style={{ marginLeft: 10, color: AppColor.appWhite, cursor: 'pointer' }}
            />
          </div>

          <div style={{ minHeight: 60, overflowY: 'scroll' }}>
            <SBText text={label} className={classes.labelText} />
            {data.map((item: any, mainIndex: number) => (
              <div style={{ margin: 20 }}>
                <div style={{ display: 'flex' }}>
                  {item.reviewStatus === false && (
                    <div className={classes.rejBtn}>
                      <CloseIcon
                        style={{
                          color: '#606378',
                          fontSize: 18,
                        }}
                      />
                    </div>
                  )}
                  {item.reviewStatus === null && (
                    <div className={classes.docBtn}>
                      <InsertDriveFileOutlinedIcon
                        style={{
                          color: '#606378',
                          fontSize: 18,
                        }}
                      />
                    </div>
                  )}
                  {item.reviewStatus === true && (
                    <div className={classes.aprBtn}>
                      <CheckIcon
                        style={{
                          color: '#606378',
                          fontSize: 18,
                        }}
                      />
                    </div>
                  )}
                  <SBText text={item.createdUser.name} className={classes.createdByText} />
                  <SBText
                    text={item.createdAt ? moment(item.createdAt).format('DD MMM YYYY HH:mm') : '-'}
                    className={classes.createdAtText}
                  />
                </div>
                <div>
                  <SBText text={item.reviewStatus === null  && !item.comment ? "New document uploaded" : item.comment} className={classes.commentText} />
                  <Divider style={{ marginTop: 10 }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ReviewHistory;
