import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionTypes,
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../actions";
import config from "../../../config";

export const useFetchRefreeDetails = () => {
  const dispatch = useDispatch();

  const fetchRefreeDetails = useCallback(
    async (agentID: number) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(
          `${config.BASE_URL}/agent/ref-check/${agentID}`,
          dispatch
        );
        dispatch({ type: ActionTypes.SET_REFREE_DEATILS, payload: result });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    fetchRefreeDetails,
  };
};
