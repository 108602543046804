import { ThunkAction } from "redux-thunk";
import {
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../../actions";
import { RootState } from "../../../../reducer";
import { ActionType } from "../../../../reducer/types";
import { API_URL } from "../../../../util/apiUrls";
import { LpoAction } from "./action-types";

export const getLPO = (
  id: number
): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  dispatch({ type: ON_LOADING_START });

  const result = await get(API_URL.LPO.BY_ID(id), dispatch);

  await dispatch({
    type: LpoAction.SET_SELECTED_LPO_DETAILS,
    payload: result,
  });
  dispatch({ type: ON_LOADING_FINISHED });
};
