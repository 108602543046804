import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  FileCopyOutlined,
  CloseRounded,
  LocationOn,
  WidgetsOutlined,
  CallMadeOutlined,
  ThumbUpAlt,
  OpenInNew,
} from "@material-ui/icons";

import SBText from "../../../components/base/SBText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerSize: {
      fontWeight: 500,
    },
    subheader: {
      fontSize: "12px",
    },
    Lable: {
      width: "80%",
      paddingTop: "15px",
      paddingBottom: "5px",
    },
    saveBtn: {
      height: 40,
      borderRadius: 30,
      width: 93,
      backgroundColor: "#5C5F74",
      color: "white",
      fontSize: "12px",
    },
  })
);

export default function EventModal(EventModalProps: any) {
  const classes = useStyles();

  let getTime = (time: any) => {
    var date = new Date(time);

    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  let getheader = (e: any) => {
    if (e.eventType === "Block") {
      return `${e.event}: ${e.groups.name}`;
    } else if (e.eventType === "Outcome") {
      let arr = e.event.split(":");
      console.log(arr);
      if (arr[1] == " ConditionalOffer") {
        return `${arr[0]}: Conditional Offer Letter`;
      }
      if (arr[1] == " UnConditionalOffer") {
        return `${arr[0]}: UnConditional Offer Letter`;
      }
      if (arr[1] == " IssueOfCOE") {
        return `${arr[0]}: COE`;
      }
      return e.event;
    } else {
      return e.event;
    }
  };
  return (
    <div>
      <p id="simple-modal-description" style={{ paddingTop: 10 }}>
        {EventModalProps.events?.map((e: any, i: any) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  minWidth: "50px",
                  minHeight: "50px",
                  alignItems: "center",
                  borderRadius: "25px",
                  justifyContent: "center",
                }}
              >
                <ThumbUpAlt
                  style={{
                    fontSize: "2.5em",
                    color: "rgba(33, 33, 33, 0.18)",
                    display: `${e.eventType === "Question" ? "block" : "none"}`,
                  }}
                />
                <FileCopyOutlined
                  style={{
                    fontSize: "2.5em",
                    color: "rgba(33, 33, 33, 0.18)",
                    display: `${e.eventType === "File" ? "block" : "none"}`,
                  }}
                />
                <CloseRounded
                  style={{
                    fontSize: "2.5em",
                    color: "rgba(33, 33, 33, 0.18)",
                    display: `${
                      e.eventType === "ResponseVerification" ? "block" : "none"
                    }`,
                  }}
                />
                <LocationOn
                  style={{
                    fontSize: "2.5em",
                    color: "rgba(33, 33, 33, 0.18)",
                    display: `${e.eventType === "Outcome" ? "block" : "none"}`,
                  }}
                />
                <WidgetsOutlined
                  style={{
                    fontSize: "2.5em",
                    color: "rgba(33, 33, 33, 0.18)",
                    display: `${e.eventType === "Block" ? "block" : "none"}`,
                  }}
                />
                <CallMadeOutlined
                  style={{
                    fontSize: "2.5em",
                    color: "rgba(33, 33, 33, 0.18)",
                    display: `${
                      e.eventType === "RequestVerification" ? "block" : "none"
                    }`,
                  }}
                />
                <OpenInNew
                  style={{
                    fontSize: "2.5em",
                    color: "rgba(33, 33, 33, 0.18)",
                    display: `${
                      e.eventType === "Application" ? "block" : "none"
                    }`,
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <SBText className={classes.headerSize} text={getheader(e)} />
                <SBText
                  className={classes.subheader}
                  text={`${
                    e.createdUser ? e.createdUser.name : ""
                  } on ${getTime(e.createdAt)}`}
                />
              </div>
            </div>
            <div style={{ flex: "1", width: "100%", display: "flex" }}>
              <div
                style={{
                  width: "50px",
                  height: "100%",
                  display: "flex",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "2px",
                    backgroundColor: "rgba(33, 33, 33, 0.18)",
                    height: "20px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </p>
    </div>
  );
}
