import React from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Theme, TextField, Select } from "@material-ui/core";
import SBText from "../SBText";
import {
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 5,
      height: 45,
      backgroundColor: "#FCFBFB",
      "&:hover": {
        borderColor: "#727487",
      },
      "&$focused": {
        // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: "#727487",
      },
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      marginBottom: 2,
      // overflow: "hidden",
    },
    label: {
      textAlign: "left",
      color: "#736F6F",
    },
  })
);

type SBDateProps = {
  className?: string;
  [x: string]: any;
  value: any;
  questionObj?: any;
  disabled: boolean;
  validationFromOther?: any;
};
const SBDate: React.FC<SBDateProps> = ({
  className,
  value,
  questionObj,
  disabled,
  validationFromOther,
  ...rest
}) => {
  const classes = { ...useStyles(), ...(rest.styles?.() || {}) };

  const handleDateChange = (date: any) => {
    const formmatedDate = moment(date).format("YYYY-MM-DD");
    rest.onDataChange(formmatedDate, questionObj);
  };

  const getMaxMinDate = () => {
    if (validationFromOther) {
      return validationFromOther;
    }

    if (
      !questionObj ||
      !questionObj.validation ||
      questionObj.validation == "Date"
    ) {
      return { maxDate: undefined, minDate: undefined, message: "" };
    } else {
      const validationString = questionObj.validation.substring(
        questionObj.validation.indexOf("(") + 1,
        questionObj.validation.indexOf(")")
      );
      const mainFilter = validationString.split("|");
      let type: string = "";
      let max: any = "";
      let min: any = "";
      for (let i = 0; i < mainFilter.length; i++) {
        if (i == 0) {
          type = mainFilter[i].split(":")[0];
        } else {
          const maxMin = mainFilter[i].split(":")[0];
          if (maxMin === "Max") {
            max = mainFilter[i].split(":")[1];
          }
          if (maxMin === "Min") {
            min = mainFilter[i].split(":")[1];
          }
        }
      }

      max = parseInt(max);
      min = parseInt(min);

      let maxDate = undefined;
      let minDate = undefined;

      if (!isNaN(max)) {
        if (max < 0) {
          // @ts-ignore
          maxDate = moment().subtract(max * -1, type);
        } else {
          // @ts-ignore
          maxDate = moment().add(max, type);
        }
      }
      // .format("DD/mm/yyyy");
      if (!isNaN(min)) {
        if (max < 0) {
          // @ts-ignore
          minDate = moment().subtract(min * -1, type);
        } else {
          // @ts-ignore
          maxDate = moment().add(max, type);
        }
      }

      return {
        maxDate: maxDate,
        minDate: minDate,
        message: questionObj.errorMessage,
      };
    }
  };

  const validation = getMaxMinDate();

  const getDatetype = () => {
    if (questionObj && questionObj.dateType) {
      return [questionObj.dateType];
    } else {
      return undefined;
    }
  };

  const getDateFormat = () => {
    if (questionObj && questionObj.dateType) {

      if(questionObj.dateType == "year") {
        return "YYYY"
      } else if (questionObj.dateType == "month") {
        return "MMMM"
      } else {
        return  "DD/MM/YYYY" 
      }
    } else {
      return  "DD/MM/YYYY" 
    }
  };

  const getDatePlaceHolder = () => {
    if (questionObj && questionObj.dateType) {

      if(questionObj.dateType == "year") {
        return "yyyy"
      } else if (questionObj.dateType == "month") {
        return "mm"
      } else {
        return  "dd/mm/yyyy" 
      }
    } else {
      return  "dd/mm/yyyy" 
    }
  };

  return (
    <>
      {rest.labelText ? (
        <SBText text={rest.labelText}   
        className={clsx(classes.label, rest.labelStyle)} />
      ) : null}

      <KeyboardDatePicker
        margin="normal"
        key={questionObj && questionObj.id? questionObj.id : "XXX"}
        format= {getDateFormat() }
        placeholder={getDatePlaceHolder()}
        id="date-picker-dialog"
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        InputProps={{
          disableUnderline: true,
          className: clsx(classes.input, className),
        }}
        value={value}
        error={false}
        fullWidth
        helperText={""}
        style={{
          borderWidth: 1,
          borderColor: "#3E4059",
          borderStyle: "solid",
          height: 45,
          borderRadius: 5,
          alignItems: "center",
          paddingLeft: 16,
          paddingTop: 6,
          backgroundColor: "#FCFBFB",
          margin: "unset",
        }}
        autoOk={true}
        disabled={
          (questionObj &&
            questionObj.status &&
            questionObj.status === "DISABLED") ||
          disabled || questionObj?.isLocked 
            ? true
            : false
        }
        maxDate={validation.minDate}
        minDate={validation.maxDate}
        maxDateMessage={validation.message}
        minDateMessage={validation.message}
        views={getDatetype()}

      />
    </>
  );
};

export default SBDate;
