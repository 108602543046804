import { get,postWithHeader, postWithoutHeader } from "./common";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducer";
import { ActionType } from "../reducer/types";
import config from "../config";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";
import _ from "lodash";


export const getTAUsreDetails = (
    emailCode: any
  ): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    return new Promise(
      async (resolve: (x: any) => void, reject: (x: any) => void) => {
        try {
          dispatch({ type: ON_LOADING_START });
          const result = await get(
            `${config.BASE_URL}/ta/getUserDetails/${emailCode}`
          );
          resolve(result);
          dispatch({ type: ON_LOADING_FINISHED });
        } catch (error) {
          dispatch({ type: ON_LOADING_FINISHED });
          if (error && error.response) {
            reject(error.response.data);
          }
        }
      }
    );
  };

  export const sendOTP = (
    body: any
  ): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    return new Promise(
      async (resolve: (x: any) => void, reject: (x: any) => void) => {
        try {
          dispatch({ type: ON_LOADING_START });
          const result = await postWithoutHeader(
            `${config.BASE_URL}/ta/sendOTP`, body
          );
          resolve(result);
          dispatch({ type: ON_LOADING_FINISHED });
        } catch (error) {
          dispatch({ type: ON_LOADING_FINISHED });
          if (error && error.response) {
            reject(error.response.data);
          }
        }
      }
    );
  };

  export const verifyOTP = (
    body: any
  ): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    return new Promise(
      async (resolve: (x: any) => void, reject: (x: any) => void) => {
        try {
          dispatch({ type: ON_LOADING_START });
          const result = await postWithoutHeader(
            `${config.BASE_URL}/ta/verifyOTP`, body
          );
          resolve(result);
          dispatch({ type: ON_LOADING_FINISHED });
        } catch (error) {
          dispatch({ type: ON_LOADING_FINISHED });
          if (error && error.response) {
            reject(error.response.data);
          }
        }
      }
    );
  };


  export const updateTADetails = (
    fileArray: any, taId: any, body:any
  ): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    return new Promise(
      async (resolve: (x: any) => void, reject: (x: any) => void) => {
        try {
          dispatch({ type: ON_LOADING_START });
          for(let i=0;i<fileArray.length;i++) {
             const obj = fileArray[i] 
            const result  = await uploadKYCProofs(obj.file, obj.key,taId)
            if(result && result.success) {
                fileArray[i]["uploaded"] = true   
            }
          }
          const index = _.findIndex(fileArray, (obj:any)=> !obj.uploaded)
           
          if(index == -1) {
            const dataUpdateResult = await postWithoutHeader(
                `${config.BASE_URL}/ta/updateOtherDetails`,
                body
              );
              resolve(dataUpdateResult);
          }   
          dispatch({ type: ON_LOADING_FINISHED });
        } catch (error) {
          dispatch({ type: ON_LOADING_FINISHED });
          if (error && error.response) {
            reject(error.response.data);
          }
        }
      }
    );
  };





  export const uploadKYCProofs = (
    file: any,
    key: any,
    taId: any,
  ) => {
    return new Promise(
      async (resolve: (x: any) => void, reject: (x: any) => void) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("key", key);
        formData.append("id", taId);
        // console.log("appid" + applicationId);
        //console.log("file" + file);
        try {
          const response = await postWithHeader(
            `${config.BASE_URL}/ta/upload-ta-proof`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
              onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                //console.log("loaded", loaded);
                //console.log("total", total);
                const percent = Math.floor((loaded * 100) / total);
              },
            }
          );
          //console.log("file upload response",response);
          resolve(response);
        } catch (error) {
          //console.log("Error In Upload::", error);
          if (error && error.response) {
            reject(error.response.data);
          }
        }
      }
    );
  };

  export const uploadTaContract = (
    file: any, taId: any,
  ): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
    return new Promise(
      async (resolve: (x: any) => void, reject: (x: any) => void) => {
        try {
          const formData = new FormData();
          formData.append("file", file);
          dispatch({ type: ON_LOADING_START });
          const response = await postWithHeader(
            `${config.BASE_URL}/ta/upload-contract-ta-signed/${taId}`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          resolve(response);
          dispatch({ type: ON_LOADING_FINISHED });
        } catch (error) {
          dispatch({ type: ON_LOADING_FINISHED });
          if (error && error.response) {
            reject(error.response.data);
          }
        }
      }
    );
  };