import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import SBButton from "../base/SBButton";
import SBText from "../base/SBText";
import AppColor from "../../theme/AppColor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
  })
);

type ErrorDialogProps = {
  className?: string;
  open: boolean;
  onSubmit: () => void;
  message: string;
  header: string;
};
const PartialContentErrorDialog: React.FC<ErrorDialogProps> = ({
  open,
  message,
  header,
  // setOpen,
  onSubmit,
}) => {
  const handleSubmit = () => {
    // setOpen(false);
    onSubmit();
  };

  const handleClose = () => {
    // setOpen(false);
    onSubmit();
  };

  const messageList = message ? message.split("\n") : "";
  let mainMessage = "";
  let subMessageList: any = [];
  if (messageList && messageList.length > 0) {
    mainMessage = messageList[0];
    if (messageList.length > 1) {
      subMessageList = messageList[1].split(",");
    }
  }
  //console.log("messageList", messageList);
  //console.log("subMessageList", subMessageList);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <SBText text={mainMessage} style={{ color: AppColor.appDarkGrey }} />
          <div style={{ marginTop: 10 }}>
            {subMessageList.map((msg: any, index: number) => (
              <SBText
                text={`${index + 1}) ${msg}`}
                style={{ color: AppColor.appRed, fontWeight: "600" }}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <SBButton icon={false} text={"Ok"} onClick={handleSubmit} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PartialContentErrorDialog;
