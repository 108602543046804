import * as actionTypes from "../actions";
import { ActionType, LPC, LPCStatus } from "./types";
import { isDate } from 'lodash';

const getLPCStatusById = (id: number): LPCStatus => {
  if (id in LPCStatus) {
    return id as LPCStatus;
  }
  return LPCStatus.ENQUIRED;
}

const lpcDefaultData: LPC = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  occupation: '',
  country: '',
  city: '',
  currentStatusId: 1,
  status: LPCStatus.ENQUIRED,
  timeToConnectHour: 0,
  timeToConnectMin: 0,
  prevWorkDescription: '',
  createdAt: new Date(),
  updatedAt: new Date()
};

interface LPCState {
  lpcData: LPC[]
}

const initialState: LPCState = {
  lpcData: []
}

const processLPCRawData = (data: any[]): LPC[] => data.map(item => {
  const {
    createdAt: createAtStr = '',
    updatedAt: updatedAtstr = '',
    currentStatusId
  } = item;
  let createdAt = new Date(createAtStr);
  if (!isDate(createdAt)) {
    createdAt = new Date();
  }
  let updatedAt = new Date(updatedAtstr);
  if (!isDate(updatedAt)) {
    updatedAt = new Date();
  }
  return {
    ...lpcDefaultData,
    ...item,
    status: getLPCStatusById(currentStatusId),
    createdAt,
    updatedAt
  };
});

const lpcReducer = (state = initialState, action: ActionType): LPCState => {
  switch (action.type) {
    case actionTypes.SET_LPC_LIST: {
      return {
        ...state,
        lpcData: processLPCRawData(action.payload)
      }
    }
    default:
      return state;
  }
}

export default lpcReducer;
