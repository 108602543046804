import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RootState } from '../../../reducer';
import { makeStyles, createStyles } from '@material-ui/styles';
import {
  Theme,
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Grid,
} from '@material-ui/core';
import SBButton from '../../../components/base/SBButton';
import SBText from '../../../components/base/SBText';
import AppColor from '../../../theme/AppColor';
import EmailProgressStepper from './EmailProgressStepper';
import CloseIcon from '@material-ui/icons/Close';
import EmailBody from './EmailBody';
import ReceipientsBody from '../Receipients';
import { sendMissingDocumentEmail } from '../../../actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    cancelButton: {
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 16,
      marginBottom: 10,
      backgroundColor: '#FAFAFA',
      borderColor: AppColor.appBlue,
      borderWidth: 1,
      borderStyle: 'solid',
      color: AppColor.appBlue,
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    },
    requestButton: {
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 16,
      marginBottom: 10,
    },
    paper: { width: '100%', minHeight: 700, maxWidth: '100%', minWidth: 700 },
    checkButtonText: {
      marginLeft: 6,
      fontSize: 16,
      fontWeight: 600,
      color: AppColor.appBlue,
    },
    uncheckButton: { opacity: 0.5, height: 30, width: 30 },
    checkButton: { height: 30, width: 30 },
    checkedText: { color: 'black' },
    arrow: {
      '&:before': {
        border: '0px solid #E6E8ED',
      },
      color: AppColor.appBlue,
    },
    tooltip: {
      backgroundColor: AppColor.appBlue,
      border: '0px solid #E6E8ED',
      color: 'white',
      width: 120,
      textAlign: 'center',
    },
    textAreaWidth: {
      border: '1px solid black',
      borderRadius: '5px',
      fontFamily: 'Poppins',
    },
    label: {
      textAlign: 'left',
      color: theme.palette.primary.light,
      display: 'flex',
    },
    headerColor: {
      color: 'white',
      fontSize: '14px',
    },
    headerText: {
      backgroundColor: '#5C5F74',
      color: 'white',
    },
    stepperContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 10,
      paddingTop: 10,
      paddingBottom: 10,
      width: '100%',
      height: 'inherit',
    },
    logo: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 10,
    },
    logoheight: {
      height: 100,
    },
    'MuiTypography-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    boldFont: {
      fontWeight: 600,
    },
  })
);
type PropsFromRedux = ConnectedProps<typeof connector>;
type AgentEmailBodyDialogProps = PropsFromRedux & {
  className?: string;
  open: boolean;
  onClose: () => void;
  applicationId: any;
  sendMissingDocumentEmail: any;
  missingDocumentOverallComment: any;
  missingDocumentAdditionalComments: [];
};

const AgentEmailBodyDialog: React.FC<AgentEmailBodyDialogProps> = ({
  open,
  onClose,
  applicationId,
  sendMissingDocumentEmail,
  missingDocumentOverallComment,
  missingDocumentAdditionalComments,
}) => {
  const classes = useStyles();
  const [CurrentIndex, ChangeCurrentIndex] = React.useState<number>(1);
  const [receipient, changeReceipients] = React.useState<string>('');
  let title = '';
  let body;
  // console.log("Application Id ", application);
  const handleClose = () => {
    onClose();
    // setOpen(false);
    // onSubmit();
  };

  const dataObject = [
    {
      id: 1,
      question: 'Email Content',
      title: 'Send Notification',
    },
    {
      id: 2,
      question: 'Add recepients',
      title: 'Add Recepients',
    },
  ];

  const onStepperClick = async (index: number) => {
    ChangeCurrentIndex(index);
    console.log(index);
    getByTitle();
  };

  const ChangeStep = async () => {
    ChangeCurrentIndex(2);
  };

  const getByTitle = () => {
    var filter = dataObject.filter((x) => x.id === CurrentIndex)[0];
    title = filter.title;
  };

  const omAddreceipients = (rcpt: string) => {
    changeReceipients(rcpt);
  };

  const sendEmail = () => {
    const body: any = {
      applicationId,
      comment: missingDocumentOverallComment,
      toEmail: receipient,
      documentComments: missingDocumentAdditionalComments,
    };
    sendMissingDocumentEmail(body);
    handleClose();
  };

  return (
    <div>
      {getByTitle()}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle className={classes.headerText}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <SBText className={classes.headerColor} text={title} />
            <IconButton aria-label="close" style={{ color: 'white' }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ fontFamily: 'poppins', letterSpacing: 0.15 }}>
            <div className={classes.stepperContainer}>
              <EmailProgressStepper
                dataObject={dataObject}
                currentIndex={CurrentIndex}
                onStepperClick={(index: number) => onStepperClick(index)}
              />
            </div>
            <Divider variant="middle" />
            {CurrentIndex === 1 ? (
              <EmailBody applicationId={applicationId} />
            ) : (
              <ReceipientsBody
                onAddReceipients={(receipients: string) => omAddreceipients(receipients)}
                applicationId={applicationId}
              />
            )}
          </DialogContentText>
        </DialogContent>

        <DialogContentText>
          <Divider variant="middle" />
          {
            <div style={{ padding: '1rem 2rem' }}>
              <SBText variant="subtitle2" text="Do you want to send this notification?" className={classes.boldFont}>
                {' '}
              </SBText>
              <SBText text='this will change the application status to "more info required from agent"'></SBText>
            </div>
          }
        </DialogContentText>
        <DialogActions>
          <SBButton icon={false} text={'Cancel'} onClick={() => handleClose()} className={classes.cancelButton} />
          {CurrentIndex === 1 ? (
            <SBButton icon={false} text={'Next'} onClick={() => ChangeStep()} className={classes.requestButton} />
          ) : (
            <SBButton icon={false} text={'Send'} onClick={sendEmail} className={classes.requestButton} />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
const mapState = (state: RootState) => ({
  missingDocumentOverallComment: state.sbApplication.missingDocumentOverallComment,
  missingDocumentAdditionalComments: state.sbApplication.missingDocumentAdditionalComments,
});

const mapDispatch = {
  sendMissingDocumentEmail,
};
const connector = connect(mapState, mapDispatch);
export default connector(AgentEmailBodyDialog);
