import { RootState } from "../../../../reducer";

export const selectAgentPaginatedData = (state: RootState) =>
  state.agent.paginatedData;

export const selectedAgentDetails = (state: RootState) =>
  state.agent.selectedAgentDetails;
  
export const selectResendInviteResponse = (state: RootState) =>
  state.agent.resendInviteResponse;

export const filteredStatuses = (state: RootState) =>
  state.agent.filteredStatuses;

export const allAgentStatuses = (state: RootState) =>
  state.agent.statuses;

  export const getAllOnBoardAgentsRequest = (state: RootState) =>
  state.onboardRequestAgentReducer.paginatedData;

