import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';

export const VIEW_FILE = '@fileViewer/VIEW_FILE';
export const CLOSE_FILE_VIEWER = '@fileViewer/CLOSE_FILE_VIEWER';

export const closeFileViewer = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: CLOSE_FILE_VIEWER });
};
