import { Snackbar, Theme } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { createStyles, makeStyles } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { onUserLoggedIn, resetError, resetPartialFilledError, resetSessionError } from '../../../actions';
import { REDIRECT_TO } from '../../../actions/util/constant';
import { RootState } from '../../../reducer';
import LoginErrorDialog from '../../ErrorDialog/LoginErrorDialog';
import LoadingDialog from '../../LoadingDialog';
import PartialContentErrorDialog from '../../PartialContentErrorDialog';
import NavBar from './NavBar';

type PropsFromRedux = ConnectedProps<typeof connector>;
type RouteComponentProps = PropsFromRedux & {
  session?: any;
  children: any;
};

const Dashboard: React.FC<RouteComponentProps> = ({
  onUserLoggedIn,
  user,
  loading,
  children,
  fileUploading,
  fileUploadingInfo,
  resetError,
  // displayErrorView,
  errorMessage,
  sessionError,
  resetSessionError,
  resetPartialFilledError,
  partialFilledError,
  applicationDetailLoading,
  applicationQuestionLoading,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [displayErrorView, setDisplayErrorView] = React.useState(false);
  const [partialErrorDialog, setPartialErrorDialog] = React.useState(false);
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);

  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = () => {
    resetError();
    setDisplayErrorView(false);
    //console.log("Close");
  };

  useEffect(() => {
    if (errorMessage) {
      setDisplayErrorView(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (partialFilledError) {
      setPartialErrorDialog(true);
    }
  }, [partialFilledError]);

  const onSessionEnd = () => {
    localStorage.setItem(REDIRECT_TO, location.pathname);
    history.push('/login');
    resetSessionError();
  };

  const addOverlaysComponent = () => {
    return (
      <>
        <Snackbar
          open={displayErrorView}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          style={{ marginTop: 50 }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <LoadingDialog open={loading || applicationQuestionLoading || applicationDetailLoading} />
        <LoginErrorDialog open={sessionError} onSubmit={onSessionEnd} setOpen={() => console.log('User >>>')} />
        <PartialContentErrorDialog
          open={partialErrorDialog}
          onSubmit={async () => {
            setPartialErrorDialog(false);
            setTimeout(() => {
              resetPartialFilledError();
            }, 100);
          }}
          message={partialFilledError}
          header={'Error'}
        />
      </>
    );
  };

  return (
    <div>
      <SnackbarProvider
        maxSnack={8}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ width: 300 }}
        hideIconVariant={true}
      >
        <NavBar
          onMobileClose={() => setOpenNavBarMobile(false)}
          openMobile={openNavBarMobile}
          session={user}
          children={children}
        />

        {addOverlaysComponent()}
      </SnackbarProvider>
    </div>
  );
};

const mapState = (state: RootState) => ({
  user: state.user.user,
  loading: state.application.loading,
  applicationDetailLoading: state.application.applicationDetailLoading,
  applicationQuestionLoading: state.application.applicationQuestionLoading,
  fileUploading: state.application.fileUploading,
  fileUploadingInfo: state.application.fileUploadingInfo,
  errorMessage: state.application.errorMessage,
  sessionError: state.user.sessionError,
  partialFilledError: state.application.partialFilledError,
  // displayErrorView: state.application.displayErrorView,
});

const mapDispatch = {
  onUserLoggedIn,
  resetError,
  resetSessionError,
  resetPartialFilledError,
};

const connector = connect(mapState, mapDispatch);
export default connector(Dashboard);
