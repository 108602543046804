import fileDownload from "js-file-download";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from "../../../actions";
import { API_URL } from "../../../util/apiUrls";

export const useDownloadReport = () => {
  const dispatch = useDispatch();

  const downloadReport = useCallback(
    async (history: any) => {
      return new Promise(
        async (resolve: (x: any) => void, reject: (x: any) => void) => {
          dispatch({ type: ON_LOADING_START });
          try {
            const result = await get(
              API_URL.REPORTS.DOWNLOAD(history.id),
              {
                responseType: "blob",
              },
              dispatch
            );

            const s3FileName = history.fileName;
            const reportFileName = s3FileName.substr(
              s3FileName.lastIndexOf("_") + 1
            );

            fileDownload(result, reportFileName);
            resolve(result);
          } finally {
            dispatch({ type: ON_LOADING_FINISHED });
          }
        }
      );
    },
    [dispatch]
  );

  return {
    downloadReport,
  };
};
