import { EMAIL_REGEX } from "../../../util/constants";
import { Lead } from "../types";

export const validateLead = (
  lead: Lead,
  asDraft: boolean
): Map<string, string> => {
  const errors: Map<string, string> = new Map();

  if (!lead.applicantFirstName || !lead.applicantFirstName.trim()) {
    errors.set("applicantFirstName", "Please enter Applicant First Name");
  }

  if (!lead.phoneNumber || !lead.phoneNumber.trim()) {
    errors.set("phoneNumber", "Please enter Phone Number");
  }

  if (!asDraft) {
    if (!lead.applicantLastName || !lead.applicantLastName.trim()) {
      errors.set("applicantLastName", "Please enter Applicant Last Name");
    }

    if (!lead.emailId || !lead.emailId.trim()) {
      errors.set("emailId", "Please enter Email Id");
    } else if (!EMAIL_REGEX.test(lead.emailId)) {
      errors.set("emailId", "Invalid Email");
    }

    if (!lead.applicantCity || !lead.applicantCity.trim()) {
      errors.set("applicantCity", "Please enter Applicant City");
    }

    if (!lead.applicantCountry || !lead.applicantCountry.trim()) {
      errors.set("applicantCountry", "Please enter Applicant Country");
    }

    if (!lead.preferredCountry || !lead.preferredCountry.trim()) {
      errors.set("preferredCountry", "Please enter preffered Country");
    }

    if (!lead.preferredCourse || !lead.preferredCourse.trim()) {
      errors.set("preferredCourse", "Please enter preffered Course");
    }
  }

  return errors;
};
