import { ActionTypes } from "../actions/agent";
import { ActionType } from "../../../../reducer/types";

interface SubmittedDocumentsState {
  supportingDocument: [];
}

const initialState: SubmittedDocumentsState = {
  supportingDocument: [],
};

const downloadSubmittedDocumentsReducer = (
  state = initialState,
  action: ActionType
): SubmittedDocumentsState => {
  switch (action.type) {
    case ActionTypes.SET_SUBMITTED_DOCUMENT: {
      return {
        ...state,
        supportingDocument: action.payload,
      };
    }
    default:
      return state;
  }
};

export default downloadSubmittedDocumentsReducer;
