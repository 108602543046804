import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionTypes,
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../actions";
import config from "../../../config";

export const useFetchSupportingDocuments = () => {
  const dispatch = useDispatch();

  const fetchSupportingDocuments = useCallback(
    async (id, documentType) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(
          `${config.BASE_URL}/agent-application/supporting-doc/${id}/download?documentType=${documentType}`,
          { responseType: "blob" },
          dispatch
        );
        dispatch({
          type: ActionTypes.SET_SUPPORTING_DOCUMENT,
          payload: result,
        });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    fetchSupportingDocuments,
  };
};
