import { get } from "./common";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducer";
import { ActionType } from "../reducer/types";

export const SESSION_LOGIN = "SESSION_LOGIN";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const ON_LOADING_START = "ON_LOADING_START";
export const ON_LOADING_FINISHED = "ON_LOADING_FINISHED";
export const ON_FILE_UPLOADING_START = "ON_FILE_UPLOADING_START";
export const ON_FILE_UPLOADING_FINISHED = "ON_FILE_UPLOADING_FINISHED";
export const ON_FILE_UPLOADING_PROGRESS = "ON_FILE_UPLOADING_PROGRESS";

export const ON_INTERNAL_LOADING_START = "ON_INTERNAL_LOADING_START";
export const ON_INTERNAl_LOADING_FINISHED = "ON_INTERNAL_LOADING_FINISHED";
export interface LoggedInUserData {
  userId: String;
}

export interface LoginPayload {}
export const onUserLoggedIn = (
  loggedInUserData: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: ON_LOADING_START });
  try {
    // dispatch(getAllUnits())
    dispatch({
      type: SESSION_LOGIN,
      payload: loggedInUserData,
    });
  } catch (error) {
    console.log("Errror in loadingg user:", error);
  }
  dispatch({ type: ON_LOADING_FINISHED });
};
