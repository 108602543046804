import { isDate } from "lodash";
import { ActionType } from "../../../../reducer/types";
import { DEFAULT_PAGE_SIZE, FIRST_PAGE } from "../../constants";
import { AgentStatus, resendInviteApiResponse } from "../../types";
import { AgentPaginatedData } from "../../types/agent-paginated-data";
import { ActionTypes } from "../actions/agent";

const getAgentStatusById = (id: number): AgentStatus => {
  if (id in AgentStatus) {
    return id as AgentStatus;
  }
  return AgentStatus.created;
};

interface AgentState {
  paginatedData: AgentPaginatedData;
  totalAgentCount: number;
  selectedAgentDetails: any;
  statuses: any[],
  filteredStatuses: any[],
  resendInviteResponse: resendInviteApiResponse;
}

const initialState: AgentState = {
  paginatedData: {
    totalRecords: 0,
    currentPage: FIRST_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalPages: 1,
    data: [],
  },
  totalAgentCount: 0,
  resendInviteResponse: {
    status: "ideal",
    error: "",
    response: undefined
  },
  filteredStatuses: [],
  statuses: [],
  selectedAgentDetails: undefined
};

const processAgentRawData = (data: any): any =>
  data.map((item: any) => {
    const {
      createdAt: createAtStr = "",
      updatedAt: updatedAtstr = "",
      statuses: [{ statusId: currentStatusId }],
    } = item;
    let createdAt = new Date(createAtStr);
    if (!isDate(createdAt)) {
      createdAt = new Date();
    }
    let updatedAt = new Date(updatedAtstr);
    if (!isDate(updatedAt)) {
      updatedAt = new Date();
    }
    return {
      ...item,
      status: getAgentStatusById(currentStatusId),
      createdAt,
      updatedAt,
    };
  });

const onboardRequestAgentReducer = (state = initialState, action: ActionType): AgentState => {
  switch (action.type) {
    case ActionTypes.SET_ONBOARD_REQUEST_AGENT_LIST: {
      action.payload.data = processAgentRawData(action.payload.data);
      return {
        ...state,
        paginatedData: action.payload
      };
    }
    default:
      return state;
  }
};

export default onboardRequestAgentReducer;
