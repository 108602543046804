import _ from "lodash";
import { ActionType } from "../../../../reducer/types";
import {
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE,
  LPO_API_IDEAL_STATUS,
} from "../../constants";
import { LPOModalAPIStatus, LPOPaginatedData } from "../../types";
import { LpoAction } from "../actions/action-types";

interface LPOState {
  paginatedData: LPOPaginatedData;
  modalAPIStatus: LPOModalAPIStatus;
  selectedLPODetails: any;
  lpoDashboardData: any;
}

const initialStateLPO: LPOState = {
  paginatedData: {
    totalRecords: 0,
    currentPage: FIRST_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalPages: 1,
    data: [],
  },
  modalAPIStatus: LPO_API_IDEAL_STATUS,
  selectedLPODetails: undefined,
  lpoDashboardData: {},
};

const lpoReducer = (state = initialStateLPO, action: ActionType): LPOState => {
  switch (action.type) {
    case LpoAction.SET_LPO_PAGINATED_DATA: {
      return {
        ...state,
        paginatedData: action.payload,
      };
    }
    case LpoAction.SET_LPO_MODAL_API_STATUS: {
      return {
        ...state,
        modalAPIStatus: action.payload,
      };
    }
    case LpoAction.SET_LPO_DASHBOARD_DETAILS_DATA: {
      return {
        ...state,
        lpoDashboardData: action.payload,
      };
    }
    case LpoAction.SET_SELECTED_LPO_DETAILS: {
      return {
        ...state,
        selectedLPODetails: action.payload,
      };
    }
    case LpoAction.SET_LPO_STATUS: {
      const tempObj: any = _.cloneDeep(state.paginatedData);
      const dataIndex = _.findIndex(tempObj.data, (item: any) => item.email === action.payload.email);
      if (dataIndex !== -1) {            
         tempObj.data[dataIndex].status = 'approved';
      }
      return {
        ...state,
        paginatedData: tempObj,
      };
    }
    default:
      return state;
  }
};

export default lpoReducer;
