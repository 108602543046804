import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { getProviderDetails, setLoginSuccessFully, setNetworkStatus, setProviders } from './actions';
import { getLoggedInData } from './actions/util';
import { REDIRECT_TO } from './actions/util/constant';
import SBText from './components/base/SBText';
import { RootState } from './reducer';
import ChangePassword from './views/ChangePassword';
import DashboardRouteComponent from './views/DashboardRoutes';
import LoginPage from './views/LoginPage';
import KYCVerificationForm from './views/TAViews/KYCVerificationForm';

type PropsFromRedux = ConnectedProps<typeof connector>;
type RouteComponentProps = PropsFromRedux & {};

const RouteComponent: React.FC<RouteComponentProps> = ({
  isUserLoggedIn,
  setLoginSuccessFully,
  getProviderDetails,
  setProviders,
  setNetworkStatus,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserDetails();
    setNetworkStatus(navigator.onLine);
    window.addEventListener('online', function () {
      setNetworkStatus(true);
    });

    window.addEventListener('offline', function () {
      setNetworkStatus(false);
    });
  }, []);

  const getUserDetails = async () => {
    const userData = getLoggedInData();
    if (userData) {
      await setLoginSuccessFully(userData);
      await setProviders(userData.user.providers);
    } else {
      console.log('window.location.href', window.location.href.includes('agent-onboarding'));
      if (
        !location.pathname.includes('changePassword') &&
        !location.pathname.includes('setPassword') &&
        !location.pathname.includes('agent-onboarding') &&
        !location.pathname.includes('ta-verification') &&
        !location.pathname.includes('login')
      ) {
        localStorage.setItem(REDIRECT_TO, location.pathname);
        history.push('/login');
      }
    }
    setLoading(false);
  };

  return (
    <div>
      {loading ? (
        <SBText text={'Loading...'} />
      ) : (
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route path="/changePassword/:id" exact>
            <ChangePassword />
          </Route>
          <Route path="/setPassword/:id" exact>
            <ChangePassword setPassword={true} />
          </Route>
          <Route path={['/ta-verification/:id', '/ta-verification']} exact component={KYCVerificationForm} />
          <Route path="/" component={isUserLoggedIn ? DashboardRouteComponent : LoginPage} />
        </Switch>
      )}
    </div>
  );
};
// export default RouteComponent;

const mapState = (state: RootState) => ({
  isUserLoggedIn: state.user.isUserLoggedIn,
});

const mapDispatch = {
  setLoginSuccessFully,
  getProviderDetails,
  setProviders,
  setNetworkStatus,
};

const connector = connect(mapState, mapDispatch);
export default connector(RouteComponent);
