import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme, TextField, Drawer, IconButton } from '@material-ui/core';
import _ from 'lodash';
import {useSelector } from 'react-redux';

import SBButton from '../../../components/base/SBButton';
import CloseIcon from '@material-ui/icons/Close';
import AppColor from '../../../theme/AppColor';
import SBText from '../../../components/base/SBText';
import { allLeadStatusesDropdownsData, getDestinationCountrys } from '../store/selectors';
import { LABELS } from '../../../util/labels';
import { FetchTlLeadsParams } from '../types';
import SBDropdown from '../../../components/base/SBDropdown';
type SBApplicationListProps =  {
  filterOpen: boolean;
  onClose: Function;
  onApplyFilter: Function;
  onClearFilter: Function;
  fetchTLLeadParams: FetchTlLeadsParams;
  setFetchTLLeadParams: Function;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: { fontFamily: 'Poppins' },
   
    drawerRoot: {
      width: 360,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    filterButtonParent: {
      display: 'flex',
      flexDirection: 'column',
      padding: 20,
      justifyContent: 'space-between',
    },
    clearButton: {
      backgroundColor: 'white',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: AppColor.appBlue,
      '&:hover': {
        backgroundColor: '#fafafa',
      },
      color: AppColor.appBlue,
    },
    label: {
      textAlign: 'left',
      color: '#736F6F',
      marginTop: theme.spacing(2),
    },
    textField: {
      width: '100%',
      borderRadius: '5px',
      '&$error': {
        color: 'red',
      },
    },
    requiredFieldLabel: {
      fontWeight: 400,
      fontSize: '14px',
      color: theme.palette.primary.light,
      marginTop: 10,
      marginBottom: 10,
    },
    input1: {
      padding: '14.5px 14px',
    },
  })
);
const LeadsFilter: React.FC<SBApplicationListProps> = ({
  filterOpen,
  onClose,
  fetchTLLeadParams,
  onApplyFilter,
  onClearFilter,
  setFetchTLLeadParams,
}) => {
  const classes = useStyles();
  const [currentFilter, setCurrentFilter] = React.useState<FetchTlLeadsParams>(fetchTLLeadParams);
  const allLead_Statuses = useSelector(allLeadStatusesDropdownsData);
  const DestinationCountrys = useSelector(getDestinationCountrys);
  const getall_LeadStatuses = (): any[] => {
    return allLead_Statuses.map((agency: any) => {
      return { id: agency.status, value: agency.label };
    });
  };
  const getallCountries = (): any[] => {
    return countries.map((agency: any) => {
      return { id: agency, value: agency };
    });
  };
  const getDestinationCountries = (): any[] => {
    return DestinationCountrys.map((agency: any) => {
      return { id: agency.name, value: agency.name };
    });
  };

  const onClearChangeFilter = async () => {
    onClose();
    onClearFilter();
  };

  const onChangeFilterApply = () => {
    onApplyFilter(currentFilter);
    onClose();
  };

  return (
    <Drawer anchor={'right'} open={filterOpen} onClose={() => onClose()}>
      <div className={classes.drawerRoot}>
        <div>
          <div
            style={{
              paddingLeft: 20,
              display: 'flex',
              backgroundColor: AppColor.appBlue,
              justifyContent: 'space-between',
              alignItems: 'center',
              height: 64,
            }}
          >
            <SBText style={{ fontSize: 22, color: AppColor.appWhite }} text={LABELS.LEAD_DETAILS} />
            <IconButton aria-label="close" onClick={() => onClose()}>
              <CloseIcon
                onClick={() => onClose()}
                style={{ marginLeft: 10, color: AppColor.appWhite, cursor: 'pointer' }}
              />
            </IconButton>
          </div>
          <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
            <SBText className={classes.requiredFieldLabel} text={LABELS.TA_NAME} />
            <TextField
              className={classes.textField}
              variant="outlined"
              onChange={(e: any) => setCurrentFilter((prevFilter) => ({ ...prevFilter, taName: e.target.value }))}
              value={currentFilter.taName}
              name="TAName"
              InputProps={{ classes: { input: classes.input1 } }}
              // helperText={formErrors.get('applicantFirstName')}
            />
            <SBText className={classes.requiredFieldLabel} text={LABELS.TA_EMAIL} />
            <TextField
              className={classes.textField}
              variant="outlined"
              onChange={(e: any) => setCurrentFilter((prevFilter) => ({ ...prevFilter, taEmail: e.target.value }))}
              value={currentFilter.taEmail}
              name="TAEmail"
              InputProps={{ classes: { input: classes.input1 } }}
              // helperText={formErrors.get('applicantFirstName')}
            />
            <SBText className={classes.requiredFieldLabel} text={LABELS.ORIGIN_OF_LEAD} />
            <SBDropdown
              placeholder={'Origin of lead'}
              options={getallCountries()}
              // labelText={'Origin of lead'}
              className={classes.dropdown}
              onDataChange={(value: any) => setCurrentFilter((prevFilter) => ({ ...prevFilter, originOfLead: value }))}
              value={currentFilter.originOfLead}
            />
            <SBText className={classes.requiredFieldLabel} text={LABELS.DESTINATION_OF_LEAD} />
            <SBDropdown
              placeholder={'Destination of lead'}
              options={getDestinationCountries()}
              // labelText={'Destination of lead'}
              className={classes.dropdown}
              onDataChange={(value: any) =>
                setCurrentFilter((prevFilter) => ({ ...prevFilter, destinationOfLead: value }))
              }
              value={currentFilter.destinationOfLead}
            />
            <SBText className={classes.requiredFieldLabel} text={LABELS.STATUS} />
            <SBDropdown
              placeholder={`Status`}
              options={getall_LeadStatuses()}
              // labelText={'Status'}
              className={classes.dropdown}
              onDataChange={(value: any) => setCurrentFilter((prevFilter) => ({ ...prevFilter, status: value }))}
              value={currentFilter.status}
            />
          </div>
        </div>

        <div className={classes.filterButtonParent}>
          <SBButton text={'Apply filter'} icon={false} onClick={() => onChangeFilterApply()} />
          <div style={{ height: 20 }}></div>
          <SBButton
            text={'Clear filter'}
            icon={false}
            onClick={() => onClearChangeFilter()}
            className={classes.clearButton}
          />
        </div>
      </div>
    </Drawer>
  );
};


const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas (the)',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia (Plurinational State of)',
  'Bonaire, Sint Eustatius and Saba',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory (the)',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands (the)',
  'Central African Republic (the)',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands (the)',
  'Colombia',
  'Comoros (the)',
  'Congo (the Democratic Republic of the)',
  'Congo (the)',
  'Cook Islands (the)',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic (the)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands (the) [Malvinas]',
  'Faroe Islands (the)',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories (the)',
  'Gabon',
  'Gambia (the)',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Holy See (the)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea (the Democratic People's Republic of)",
  'Korea (the Republic of)',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic (the)",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands (the)',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia (Federated States of)',
  'Moldova (the Republic of)',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands (the)',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger (the)',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands (the)',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines (the)',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of North Macedonia',
  'Romania',
  'Russian Federation (the)',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Martin (French part)',
  'Saint Pierre and Miquelon',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten (Dutch part)',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan (the)',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands (the)',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United States Minor Outlying Islands (the)',
  'United States of America (the)',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela (Bolivarian Republic of)',
  'Viet Nam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

export default LeadsFilter;
