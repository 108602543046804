import { post, put } from "../../../../actions";
import config from "../../../../config";
import { AgentFormData } from "./create-agent-form.types";

export const createAgent = async (agentData: AgentFormData, selectedAgentDetail: any) => {
  const data: any = {
    companyName: agentData.companyName,
    companyEmail: agentData.companyEmail,
    companyPhone: agentData.companyPhone,
    userAgencyId: agentData.userAgencyId,
    firstKeyPerson: {
      firstName: agentData.keyPersonFirstName,
      lastName: agentData.keyPersonLastName,
      title: agentData.keyPersonTitle,
      jobTitle: agentData.keyPersonJobTitle,
      isExperiencedAgent: agentData.keyPersonIsExperiencedAgent,
    },
  };
  if (selectedAgentDetail) {
    data.id = selectedAgentDetail.id;
    data.firstKeyPerson.id = selectedAgentDetail.keyPersons[0].id;
    return await put(`${config.BASE_URL}/agent/${selectedAgentDetail.id}`, data);
  }
  return await post(`${config.BASE_URL}/agent`, data);
};
