import * as actionTypes from "../actions";
import { ActionType } from "./types";
import _ from "lodash";
const initialState: any = {
  applicationMailList: [],
  applicationMailDetails: [],
};

const inboxReducers = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SET_VERIFICATION_EMAIL: {
      return {
        ...state,
        applicationMailList: action.payload,
      };
    }
    case actionTypes.RESET_VERIFICATION_EMAIL: {
      return {
        ...state,
        applicationMailList: [],
        applicationMailDetails: [],
      };
    }
    case actionTypes.SET_EMAIL_VERIFICATION_DETAILS: {
      return {
        ...state,
        applicationMailDetails: action.payload,
      };
    }
    case actionTypes.ADD_NEW_EVENT: {
      const allEvents: any = _.cloneDeep(state.applicationMailDetails);
      const index = _.findIndex(allEvents, (obj: any) => obj.id == action.payload.eventId && obj.applicationId == action.payload.applicationId)
      if (index !== -1) {
        allEvents[index].events.push(action.payload.result)
      }
      return {
        ...state,
        applicationMailDetails: allEvents,
      };
    }
    case actionTypes.RESET_EMAIL_VERIFICATION_DETAILS: {
      return {
        ...state,
        applicationMailDetails: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default inboxReducers;
