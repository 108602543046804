import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, Typography, Modal } from "@material-ui/core";
import { useHistory } from "react-router";
import buble from "./assets/graph_buble.png";
import SBText from "../../../components/base/SBText";
import SimpleModal from "./checklistModal";
import _ from "lodash";
import { EOF } from "dns";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
    },

    mainGraph: {
      height: 55,
      display: "flex",
      borderWidth: 8,
      borderColor: "white",
      borderStyle: "solid",
      borderRadius: 30,
      boxShadow: "rgba(0, 0, 0, 0.117647) -1px 2px 6px 4px",
      width: "100%",
    },

    graphText: {
      position: "absolute",
      width: 100,
      textAlign: "center",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      marginTop: 60,
      cursor: "pointer",
    },
    dottedLines: {
      height: 60,
      width: 1,
      borderRightColor: "black",
      borderRightWidth: 2,
      borderRightStyle: "dashed",
      display: "flex",
      position: "absolute",
    },
    dottedLastLines: {
      height: 58,
      width: 1,
      borderRightColor: "black",
      borderRightWidth: 2,
      borderRightStyle: "dashed",
      display: "flex",
      position: "absolute",
      marginTop: 2,
    },
    bubbleText: {
      fontFamily: "Poppins",
      color: theme.palette.primary.contrastText,
      fontWeight: 500,
    },
    dummyGraph: {
      width: "100%",
      height: 60,
      borderWidth: 10,
      borderStyle: "solid",
      borderColor: "rgba(0,0,0,0.0)",
      borderRadius: 30,
      // backgroundColor: "rgba(255,255,255,0.5)",
      display: "flex",
      // marginTop: (height * 3) / 194 + "%",
    },
    dummyGraphParent: {
      display: "flex",
      position: "absolute",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    graphDaysText: {
      fontSize: 12,
      color: theme.palette.primary.contrastText,
      textAlign: "center",
      fontWeight: 500,
      WebkitLineClamp: 2,
      position: "relative",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
    },
    EOAgraphDaysText: {
      fontSize: 12,
      color: theme.palette.primary.contrastText,
      textAlign: "center",
      width: "85%",
      fontWeight: 500,
      WebkitLineClamp: 2,
      position: "relative",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
    },
  })
);

type StatusGraphProps = {
  initialState: boolean;
  outcomes: any;
  createdAt: any;
  appId: any;
  getConditionalOfferQuestions: any;
  getUnConditionalOfferQuestions: any;
  getCOEOfferQuestions: any;
  getOutcomeQuestion: any;
  outcomeQuestionsByType: any;
  outcomeMaster: any;
};

const StatusGraph: React.FC<StatusGraphProps> = ({
  initialState,
  outcomes,
  createdAt,
  appId,
  getConditionalOfferQuestions,
  getUnConditionalOfferQuestions,
  getCOEOfferQuestions,
  outcomeQuestionsByType,
  getOutcomeQuestion,
  outcomeMaster,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [outcomeType, setoutcomeType] = useState("");
  const [width, setWidth] = useState(-1);
  const [height, setHeight] = useState(-1);
  // console.log("created at in statusgraph:::", createdAt)
  const [mainGraphWidth, setMainGraphWidth] = useState(-1);

  const mainOutcomes = outcomeMaster
    ? _.sortBy(outcomeMaster, (obj: any) => obj.outcomeSequence)
    : [];

  // console.log("outcomeMaster", outcomeMaster);

  const conditionalOfferLetter = _.find(
    outcomes,
    (obj: any) => obj.outcome == "ConditionalOffer" && !obj.isDeleted
  );

  const unConditionalOfferLetter = _.find(
    outcomes,
    (obj: any) => obj.outcome == "UnConditionalOffer" && !obj.isDeleted
  );

  const GTE = _.find(
    outcomes,
    (obj: any) => obj.outcome == "RequestForPayment" && !obj.isDeleted
  );

  const COE = _.find(
    outcomes,
    (obj: any) => obj.outcome == "IssueOfCOE" && !obj.isDeleted
  );

  const getDateDifference = (fromDate: string, toDate: string) => {
    // get the difference between the moments
    let now: any = new Date(fromDate);
    //console.log("gateDateDifference now:::", now);
    let expiration: any = new Date(toDate);
    //console.log("getDateDifference expiration:::", expiration);
    let diffInMilliSeconds = Math.abs(now - expiration) / 1000;

    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days} day, ` : `${days} days, `;
    }

    difference +=
      hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

    difference +=
      minutes === 0 || hours === 1
        ? `${minutes} minutes`
        : `${minutes} minutes`;

    //
    //
    //
    //console.log(difference);
    let output: any;
    if (days === 0 && hours == 0) {
      output = `${minutes} ${minutes > 1 ? "Mins" : "Min"}`;
    }

    if (days == 0 && hours > 0) {
      output = `${hours}  ${hours > 1 ? "Hour" : "Hours"}`;
    }

    if (days > 0 && hours == 0) {
      output = `${days}  ${days > 1 ? "Days" : "Day"}`;
    }

    if (days > 0 && hours > 0) {
      output = `${days} ${days > 1 ? "Days" : "Day"} ${hours} ${
        hours > 1 ? "Hours" : "Hour"
      }`;
    }
    return output;
  };

  const getConditionViewDate = () => {
    return getDateDifference(conditionalOfferLetter.updatedAt, createdAt);
  };

  const getUnconditionalViewDate = () => {
    let startDate;

    if (conditionalOfferLetter) {
      startDate = conditionalOfferLetter.updatedAt;
    }

    if (!startDate) {
      startDate = createdAt;
    }

    return getDateDifference(unConditionalOfferLetter.updatedAt, startDate);
  };

  const getGTEReceivedViewDate = () => {
    let startDate;

    if (unConditionalOfferLetter) {
      startDate = unConditionalOfferLetter.updatedAt;
    }

    if (!startDate && conditionalOfferLetter) {
      startDate = conditionalOfferLetter.updatedAt;
    }

    if (!startDate && !conditionalOfferLetter) {
      startDate = createdAt;
    }

    return getDateDifference(GTE.updatedAt, startDate);
  };

  const getECOEViewDate = () => {
    let startDate;

    if (GTE) {
      startDate = GTE.updatedAt;
    }

    if (!startDate && unConditionalOfferLetter) {
      startDate = unConditionalOfferLetter.updatedAt;
    }

    if (!startDate && conditionalOfferLetter) {
      startDate = conditionalOfferLetter.updatedAt;
    }

    if (!startDate && !conditionalOfferLetter) {
      startDate = createdAt;
    }

    return getDateDifference(COE.updatedAt, startDate);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getConditionView = (
    color: string,
    percentage: string,
    text: string
  ) => {
    return (
      <div
        style={{
          display: "flex",
          width: percentage,
          backgroundColor: color, //"#8BB19C",
          borderTopLeftRadius: 30,
          borderBottomLeftRadius: 30,
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: 7,
          paddingRight: 7,
        }}
      >
        {conditionalOfferLetter ? (
          <SBText
            text={getConditionViewDate()}
            className={classes.graphDaysText}
          />
        ) : null}

        {/*<Typography variant={"h6"} style={{ color: "white" }}>*/}
        {/*  {text}*/}
        {/*</Typography>*/}
      </div>
    );
  };

  const getEOE = (color: string, percentage: string, text: string) => {
    return (
      <div
        style={{
          display: "flex",
          width: percentage,
          backgroundColor: color, //"#D27F65",
          borderTopRightRadius: 30,
          borderBottomRightRadius: 30,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {COE ? (
          <SBText
            text={getECOEViewDate()}
            className={classes.EOAgraphDaysText}
          />
        ) : null}
      </div>
    );
  };

  const getGTEReceived = (color: string, percentage: string, text: string) => {
    return (
      <div
        style={{
          display: "flex",
          width: percentage,
          backgroundColor: color, //"#D27F65",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {GTE ? (
          <SBText
            text={getGTEReceivedViewDate()}
            className={classes.graphDaysText}
          />
        ) : null}
      </div>
    );
  };

  const getUnconditionalView = (
    color: string,
    percentage: string,
    text: string
  ) => {
    return (
      <div
        style={{
          display: "flex",
          width: percentage,
          backgroundColor: color, //"#D27F65",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {unConditionalOfferLetter ? (
          <SBText
            text={getUnconditionalViewDate()}
            className={classes.graphDaysText}
          />
        ) : null}
      </div>
    );
  };

  const getDisabledView = (color: string, percentage: string, text: string) => {
    return (
      <div
        style={{
          display: "flex",
          width: percentage,
          backgroundColor: color, //"#D27F65",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 30,
        }}
      >
        <Typography variant={"h6"} style={{ color: "white" }}>
          {"Answer the question to start application"}
        </Typography>
      </div>
    );
  };

  const renderGraph = () => {
    const items = [];

    if (initialState) {
      items.push(
        getConditionView(
          conditionalOfferLetter || unConditionalOfferLetter || GTE || COE
            ? "#8BB19C"
            : "#D27F65",
          "25%",
          "4 days,  1 hour"
        )
      );
      items.push(
        getUnconditionalView(
          unConditionalOfferLetter || GTE || COE ? "#8BB19C" : "#D27F65",
          "60%",
          "4 days,  1 hour"
        )
      );

      items.push(getEOE(COE ? "#8BB19C" : "#D27F65", "15%", "4 days,  1 hour"));
    } else {
      items.push(getDisabledView("#C4C4C4", "100%", "4 days,  1 hour"));
    }
    return items;
  };

  const getBubbleImage = () => {
    return require("./assets/graph_buble.png");
  };

  const getDummyViewStyle = (percentage: number, border: string) => {
    const style: any = {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      width: percentage + "%",
      height: "100%",
    };

    if (border == "left") {
      style.borderTopLeftRadius = 30;
      style.borderBottomLeftRadius = 30;
    }

    if (border == "right") {
      style.borderTopRightRadius = 30;
      style.borderBottomRightRadius = 30;
    }

    return style;
  };

  const getBubbleLeftMargin = () => {
    let margin = 3;
    if (conditionalOfferLetter) {
      margin = 25.5;
    }

    if (unConditionalOfferLetter) {
      margin = 82;
    }

    if (COE) {
      margin = 93;
    }

    const marginString =
      margin - (((height * 10) / 196) * 100) / width / 2 + "%";

    return marginString;
  };

  const getBubbleText = () => {
    console.log("Window Width", window.innerWidth);
    let margin = 0;
    if (conditionalOfferLetter) {
      margin = (23 * window.innerWidth) / 1792;
    }

    if (unConditionalOfferLetter) {
      margin = (82 * window.innerWidth) / 1792;
    }

    if (COE) {
      margin = (95 * window.innerWidth) / 1792;
    }
    return margin + "%";
  };

  const getBubbleDisplayText = () => {
    let margin = 0;
    if (conditionalOfferLetter) {
      margin = 25;
    }

    if (unConditionalOfferLetter) {
      margin = 85;
    }

    // if (GTE) {
    //   margin = 80;
    // }

    if (COE) {
      margin = 100;
    }

    return margin + "%";
  };

  const getGraphTextFontSize = () => {
    let size = (window.innerWidth * 14) / 1792;

    // console.log("calculated Size >>", size);

    if (size < 10) {
      size = 10;
    }

    if (size > 14) {
      size = 14;
    }

    // console.log("Final Size >>", size);
    return size;
  };
  const openModal = () => {
    //console.log(appId)
    setOpen(true);
  };

  const callConditional = async (outcome: any) => {
    setoutcomeType(outcome);
    await getConditionalOfferQuestions();
    setOpen(true);
  };
  const callUnConditional = async (outcome: any) => {
    setoutcomeType(outcome);
    await getUnConditionalOfferQuestions();
    setOpen(true);
  };
  const callCOE = async (outcome: any) => {
    setoutcomeType(outcome);
    await getCOEOfferQuestions();
    setOpen(true);
  };

  const callQuestions = async (outcomeType: string, label: string) => {
    setoutcomeType(label);
    await getOutcomeQuestion(outcomeType);
    setOpen(true);
  };

  // @ts-ignore
  return (
    <div
      className={classes.root}
      ref={(refObj) => {
        // @ts-ignore
        setWidth(refObj?.clientWidth);
        // @ts-ignore
        setHeight(refObj?.clientHeight);
      }}
    >
      <div
        className={classes.mainGraph}
        // style={{ width }}
        ref={(refObj) => {
          // console.log("width212112", refObj?.clientWidth);
          // @ts-ignore
          setMainGraphWidth(refObj?.clientWidth);
          // @ts-ignore
        }}
      >
        {renderGraph()}
      </div>

      {width && width != -1 ? (
        <div
          className={classes.dummyGraphParent}
          style={{
            height: height - 20,
          }}
        >
          <div className={classes.dummyGraph}>
            {initialState ? (
              <>
                <div
                  style={getDummyViewStyle(25, "left")}
                  onClick={() =>
                    callQuestions(
                      mainOutcomes[0].code,
                      mainOutcomes[0].outcomeLabel
                    )
                  }
                >
                  <div className={classes.dottedLines} />

                  <Typography
                    className={classes.graphText}
                    style={{
                      left: 25 - (100 * 100) / width / 2 + "%",
                      fontSize: getGraphTextFontSize(),
                    }}
                  >
                    {mainOutcomes.length > 0
                      ? mainOutcomes[0].outcomeLabel
                      : ""}
                    {/*Conditional*/}
                  </Typography>
                </div>
                <div
                  style={getDummyViewStyle(60, "none")}
                  onClick={() =>
                    callQuestions(
                      mainOutcomes[1].code,
                      mainOutcomes[1].outcomeLabel
                    )
                  }
                >
                  <div className={classes.dottedLines} />
                  <Typography
                    className={classes.graphText}
                    style={{
                      left: 82 - (100 * 100) / width / 2 + "%",
                      fontSize: getGraphTextFontSize(),
                      cursor: "pointer",
                    }}
                  >
                    {mainOutcomes.length > 0
                      ? mainOutcomes[1].outcomeLabel
                      : ""}
                  </Typography>
                </div>

                <div
                  style={getDummyViewStyle(15, "right")}
                  onClick={() =>
                    callQuestions(
                      mainOutcomes[2].code,
                      mainOutcomes[2].outcomeLabel
                    )
                  }
                >
                  <div
                    className={classes.dottedLastLines}
                    style={{
                      marginRight: 12,
                    }}
                  />

                  <Typography
                    className={classes.graphText}
                    style={{
                      left: 97 - (100 * 100) / width / 2 + "%",
                      fontSize: getGraphTextFontSize(),
                      cursor: "pointer",
                    }}
                  >
                    {mainOutcomes.length > 0
                      ? mainOutcomes[2].outcomeLabel
                      : ""}
                  </Typography>
                </div>

                <Typography
                  style={{
                    backgroundColor: "black",
                    borderRadius: 30,
                    color: "white",
                    position: "absolute",
                    width: 45,
                    left: getBubbleLeftMargin(),
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                >
                  {getBubbleDisplayText()}
                </Typography>
              </>
            ) : null}
          </div>

          <div
            style={{
              width,
              position: "absolute",
              // backgroundColor: "red",
              height: (height * 60) / 196,
              top: (height * 3) / 196 + "%",
            }}
          ></div>
        </div>
      ) : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SimpleModal
          outcomeQuestionsByType={outcomeQuestionsByType}
          outcomeType={outcomeType}
          appId={appId}
          handleclose={handleClose}
        />
      </Modal>
    </div>
  );
};

export default StatusGraph;
