import React, { useEffect, useRef } from "react";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RootState } from '../../../reducer';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { getMissingDocumentTemplate, ADD_MISSING_DOCUMENT_COMMENTS } from '../../../actions';

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo : {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
        },
        logoheight : {
            height: 100
        }
    })
  )

type PropsFromRedux = ConnectedProps<typeof connector>;
type EmailBodyProps = PropsFromRedux & {
    className?: string;
    open?: boolean;
    getMissingDocumentTemplate: any;
    applicationId: any;
  missingDocumentEmailTemplate: { subject: '', content: '' };
  missingDocumentOverallComment: any;
  missingDocumentAdditionalComments: [];
  };

  const EmailBody: React.FC<EmailBodyProps> = ({
    getMissingDocumentTemplate,
    applicationId,
    missingDocumentEmailTemplate,
    missingDocumentOverallComment,
    missingDocumentAdditionalComments
  }) => {
    const classes = useStyles();
    const divRef = useRef<HTMLDivElement>(null);;
    const dispatch = useDispatch();
    
    useEffect(() => {
      getMissingDocumentTemplate(applicationId, missingDocumentOverallComment, missingDocumentAdditionalComments);
    }, []);

    useEffect(() => {
      //const node = document.createRange().createContextualFragment(emailTemplate);
      const handleClickOutside = (event: any) => {
        if (divRef.current && divRef.current.contains(event.target) && event.target.id) {
          //AddMissingDocumentEmailComment(event.target.value);
          const comments = {
            id: event.target.id,
            value: event.target.value
          }
          dispatch({ type: ADD_MISSING_DOCUMENT_COMMENTS, payload: comments });
        }
      };
      document.addEventListener('keyup', handleClickOutside, true);
    }, [missingDocumentEmailTemplate]);

    return(
          <div ref={divRef} dangerouslySetInnerHTML={{ __html: missingDocumentEmailTemplate.content }}></div>
    );
}

const mapState = (state: RootState) => ({
  missingDocumentEmailTemplate: state.sbApplication.emailtemplate,
  missingDocumentOverallComment: state.sbApplication.missingDocumentOverallComment,
  missingDocumentAdditionalComments: state.sbApplication.missingDocumentAdditionalComments
});

const mapDispatch = {
  getMissingDocumentTemplate,
};
const connector = connect(mapState, mapDispatch);
export default connector(EmailBody);