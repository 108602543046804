import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ButtonBase, Theme } from "@material-ui/core";
import { BlockStatus } from "./BlockStatus";
import lock from "../../SBApplication/assets/lock.png";
import unlock from "../../SBApplication/assets/unlock.png";
import {
  hasOneOfPermissions,
  PERMISSION,
} from "../../../util/rolePermissionsUtil";
import { useUserPermissions } from "../../../hooks/useUserPermissions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 130,
      fontFamily: "Poppins",
      marginTop: 2,
      marginBottom: 2,
      "& .block-el": {
        "& .top-notch": {
          display: "flex",
          justifyContent: "space-around",
          "& .notch": {
            width: 22,
            height: 10,
            border: "1px solid black",
            borderBottomWidth: 0,
            margin: "0 6px",
          },
        },
        "& .details": {
          border: "1px solid black",
          height:50,
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
          // padding: "16px 0",
        },

        "&:hover": { opacity: 0.8 },
      },
      "& .block-lock-status": {
        border: "1px solid black",
        borderTopWidth: 0,
        fontSize: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "3px 0",

        "&:hover": { opacity: 0.8 },
      },

      "&.active": {
        marginTop: 0,
        marginBottom: 0,
        borderBottom: "3px solid black",
        "& .block-el": {
          "& .top-notch .notch": {
            borderWidth: 3,
            borderBottomWidth: 0,
          },
          "& .details": {
            borderWidth: 3,
            borderBottomWidth: 0,
          },
        },
        "& .block-lock-status": {
          borderWidth: 3,
          borderTopWidth: 1,
          borderBottomWidth: 0,
        },
      },
    },
  })
);

type BlockProps = {
  sbApplication: any;
  currentApplicationDetails: any;
  blockData: any;
  children: any;
  onBlockClick?: () => void;
  onLockUnlockClick?: () => void;
};

const Block: React.FC<BlockProps> = ({
  sbApplication,
  currentApplicationDetails,
  blockData,
  children,
  onBlockClick,
  onLockUnlockClick,
}) => {
  const classes = useStyles();
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [bgColor, setBgColor] = useState<string>("");
  const [showLockStatus, setShowLockStatus] = useState<boolean>(false);

  useEffect(() => {
    const canLockUnlock =
      hasOneOfPermissions(
        [
          PERMISSION.applications_provider_view_global,
          PERMISSION.applications_provider_view_org,
          PERMISSION.applications_provider_view_own,
          PERMISSION.applications_provider_view_sb_org,
          PERMISSION.applications_provider_view_sb_own,
        ],
        userPermissions
      ) && sbApplication.activeGroup != "PreQuestions" &&  !currentApplicationDetails.lockStatus;

    //sbApplication.initialStateCompleted
    setShowLockStatus(canLockUnlock);
  }, [blockData, sbApplication]);

  useEffect(() => {
    if (!blockData) {
      return;
    }

    let blockColor = "#FFFFFF";
    const status = blockData.status?.status;
    const isActive = blockData?.name == sbApplication.activeGroup;
    if (
      !sbApplication.initialStateCompleted ||
      currentApplicationDetails.lockStatus
    ) {
      blockColor = "#FFFFFF";
    } else {
      if (status === BlockStatus.NotStarted) {
        blockColor = isActive ? "#EB4E1C" : "#D27F65";
      } else if (status === BlockStatus.PartiallyComplete) {
        blockColor = isActive ? "#F9B038" : "#ECCC97";
      } else if (status === BlockStatus.Complete) {
        blockColor = isActive ? "#3BD781" : "#8BB19C";
      }
    }

    setBgColor(blockColor);
  }, [blockData, sbApplication]);

  return (
    <div
      className={`${classes.root} ${
        blockData?.name == sbApplication.activeGroup ? "active" : ""
      }`}
      
    >
      <ButtonBase
        focusRipple
        key={blockData?.id}
        disabled={!sbApplication.initialStateCompleted}
        style={{ display: "block", width: "100%" }}
      >
        <div className="block-el" onClick={onBlockClick}>
          <div className="top-notch">
            <div className="notch" style={{ backgroundColor: bgColor }}></div>
            <div className="notch" style={{ backgroundColor: bgColor }}></div>
            <div className="notch" style={{ backgroundColor: bgColor }}></div>
          </div>
          <div className="details" style={{ backgroundColor: bgColor }}>
            {children}
          </div>
        </div>

        {showLockStatus && (
          <div
            className="block-lock-status"
            onClick={onLockUnlockClick}
            style={{ backgroundColor: "#888A99" }}
          >
            <img
              src={blockData.isLocked ? unlock : lock}
              style={{
                height: 14,
                width: 14,
                marginRight: 4,
                filter: "invert(100%)",
              }}
            />
            <span>{blockData.isLocked ? "Unlock" : "Lock"}</span>
          </div>
        )}
      </ButtonBase>
    </div>
  );
};

export default Block;
