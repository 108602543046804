import React, { useEffect, useState } from "react";

import { Theme, Divider } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";

import SBText from "../../../../../../components/base/SBText";
import SBFilterMultiselectDropdown from "../../../../../../components/base/SBFilterMultiselectDropdown";
import SBButton from "../../../../../../components/base/SBButton";
import { useDispatch, useSelector } from "react-redux";
import { allAgentStatuses, filteredStatuses } from "../../../../store/selectors";
import { getAgentStatuses } from "../../../../store/actions/get-agent-statuses";
import { ActionTypes } from "../../../../store/actions/agent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      width: 350,
      padding: "20px",
    },
    crossButtonContainer: {
      textAlign: "right",
    },
    header: {
      marginTop: "30px",
    },
    headerText: {
      fontSize: 16,
      fontWeight: 600,
      marginTop: 30,
      marginBottom: 5,
    },
    dropdown: { marginTop: theme.spacing(2) },
    filterButtonParent: {
      display: "flex",
      flexDirection: "column",
      padding: 20,
      justifyContent: "space-between",
      position: "absolute",
      bottom: 10,
      width: "100%",
    },
    clearButton: {
      backgroundColor: "white",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "gray",
      "&:hover": {
        backgroundColor: "#fafafa",
      },
      color: "gray",
    },
  })
);

interface FIlterSideBarProps {
  onFilterSideBarClose: any;
  onApplyFilters: any;
  onClearFilters: any;
}

const FIlterSideBar: React.FC<FIlterSideBarProps> = ({
  onFilterSideBarClose,
  onApplyFilters,
  onClearFilters,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const statuses = useSelector(allAgentStatuses);
  const filterValues = useSelector(filteredStatuses);
  const [filterStatusValues, setFilterStatusValues] = useState([]);

  useEffect(() => {
    if(!statuses || statuses.length == 0) {
      dispatch(getAgentStatuses());
    }
  }, []);

  const setFilterValues = (statusList: any[]) => {
    dispatch({ type: ActionTypes.SET_FILTERED_STATUSES, payload: statusList });
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={true}
        onClose={() => onFilterSideBarClose(false)}
      >
        <div className={classes.mainContainer}>
          <div className={classes.header}>
            <div className={classes.crossButtonContainer}>
              <div>
                <CloseIcon
                  onClick={() => onFilterSideBarClose(false)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
          <SBText
            text={"By Application Status"}
            className={classes.headerText}
          />
          <Divider />
          <SBFilterMultiselectDropdown
            value={filterValues}
            options={statuses}
            labelText={"Agent Application Status"}
            className={classes.dropdown}
            onDataChange={(value: any) => {
              let tempValue: any = [];
              let tempStatus: any = [];
              if (value.length) {
                value.forEach((data: any) => {
                  tempValue.push(data);
                  tempStatus.push(data.status || 0);
                });
              }
              setFilterValues(tempValue);
              setFilterStatusValues(tempStatus);
            }}
          />
        </div>
        <div className={classes.filterButtonParent}>
          <SBButton
            text={"Apply filter"}
            icon={false}
            onClick={() => onApplyFilters(filterStatusValues)}
          />
          <div style={{ height: 20 }}></div>
          <SBButton
            text={"Clear filter"}
            icon={false}
            onClick={() => onClearFilters()}
            className={classes.clearButton}
          />
        </div>
      </Drawer>
    </>
  );
};

export default FIlterSideBar;
