import { Backdrop, Divider, Fade, IconButton, Modal, TextField, Theme, Collapse } from '@material-ui/core';
import { HighlightOffRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import MuiPhoneNumber from 'material-ui-phone-number';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppColor from '../../../theme/AppColor';
import { LPO, LPOModalAPIStatus, LPO_STATUS } from '../types';
import SBText from '../../../components/base/SBText';
import SBButton from '../../../components/base/SBButton';
import { statusEditable, validateLPO } from '../utils';
import { LpoAction, saveLPOAction } from '../store/actions';
import { selectLPOModalAPIStatus } from '../store/selectors';
import { LPO_API_IDEAL_STATUS } from '../constants';
import { LABELS } from '../../../util/labels';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import VisibilityIcon from '@material-ui/icons/Visibility';
import verifieldSmall from '../../TAViews/assets/icons8-ok-50.png';
import pendingSmall from '../../TAViews/assets/pendingSmall.png';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    width: '60%',
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    width: '100%',
    paddingLeft: 0,
    overflowY: 'scroll',
  },
  header: {
    minHeight: 70,
    width: '100%',
    backgroundColor: AppColor.appBlue,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
  },
  titleTxt: {
    fontSize: 16,
    color: AppColor.appWhite,
    fontWeight: 600,
    [theme.breakpoints.up(1920)]: {
      fontSize: 18,
    },
    paddingLeft: 20,
  },
  formParent: {
    width: '100%',
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  formField: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: '0px 14px',
    alignItems: 'center',
    width: '50%',
  },
  Label: {
    width: '100%',
    paddingTop: '15px',
    paddingBottom: '5px',
  },
  labelFont: {
    fontWeight: 700,
    fontSize: '14px',
    color: theme.palette.primary.light,
  },
  verificationText: {
    fontWeight: 700,
    fontSize: '14px',
    color: theme.palette.primary.light,
    minWidth: 200,
  },
  subLabelFont: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#90A0B1',
  },
  textField: {
    width: '100%',
    borderRadius: '5px',
    '&$error': {
      color: 'red',
    },
    error: {},
  },

  textFieldInput: {
    height: 45,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '15px',
    marginBottom: '15px',
    paddingRight: '16px',
  },
  requiredField: {
    '&::after': {
      content: "' *'",
      color: 'red',
    },
  },

  headerText: {
    color: 'white',
    fontFamily: 'Poppins',
    textTransform: 'none',
    fontWeight: 600,
  },
  kycHeaderparent: {
    height: 40,
    backgroundColor: '#5C5F74',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    marginTop: 10,
    justifyContent: 'space-between',
  },
  headerIconParent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteButton: {
    height: 40,
    width: 40,
    [theme.breakpoints.up(1366)]: {
      height: 45,
      width: 45,
      marginLeft: 10,
    },
    display: 'flex',
    backgroundColor: '#D27F65DD',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#D27F65',
    },
  },

  verifiedImage: {
    width: '30px',
    height: '30px',
    marginRight: 10,
  },
}));

interface LPOModalProps {
  lpoData?: any;
  open: any;
  onClose: () => void;
  onViewFile: (id: number, type: string, fileName: string) => void;
}

const defaultFormData = {
  basicDetails: {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    occupation: '',
    status: LPO_STATUS.ADDED,
    userId: null,
  },
  kycDetails: null,
  verificationDetails: null,
};

const LPOModal: React.FC<LPOModalProps> = ({ lpoData = defaultFormData, open, onClose, onViewFile }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const apiStatus: LPOModalAPIStatus = useSelector(selectLPOModalAPIStatus);

  const [formData, setFormData] = useState<any>(lpoData);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [basicDetails, setBasicDetails] = useState<boolean>(false);
  const [kycDetails, setKyCDetails] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    if (apiStatus.status === 'error') {
      setFormErrors(apiStatus.errors);
    } else if (apiStatus.status === 'success') {
      onClose();
    }
  }, [apiStatus]);

  useEffect(() => {
    resetForm();
  }, [open]);

  useEffect(() => {
    setFormData(lpoData);
    setFormDisabled(!statusEditable(lpoData.status));
  }, [lpoData]);

  const updateFormData = (field: string, value: any) => {
    setFormData((formDataVal: LPO) => {
      return { ...formDataVal, [field]: value };
    });
  };

  const handleChange = ({ target }: any) => {
    const { name, value } = target;
    updateFormData(name, value);
  };

  const handlePhoneChange = (value: any) => {
    updateFormData('phone', value);
  };

  const isValid = () => {
    const errors = validateLPO(formData);
    setFormErrors(errors);
    return errors.size == 0;
  };

  const resetForm = () => {
    dispatch({
      type: LpoAction.SET_LPO_MODAL_API_STATUS,
      payload: LPO_API_IDEAL_STATUS,
    });
    setFormData(defaultFormData);
    setFormErrors(new Map());
  };

  const handleInviteBtnClick = useCallback(() => {
    if (!isValid()) {
      return;
    }
    dispatch(saveLPOAction(formData));
  }, [formData, dispatch]);

  const getFileName = (selectedFile: any) => {
    if (selectedFile) {
      // @ts-ignore
      const fileName = selectedFile.split('#')[1];
      return fileName;
    } else {
      return '';
    }
  };

  const onViewFileDetails = (id: number, type: string, fileName: string) => {
    if (id && fileName) {
      onViewFile(id, type, fileName);
    } else {
      alert('No file found');
    }
  };

  const requiredFieldLabelClassName = clsx(classes.requiredField, classes.labelFont);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.header}>
            <SBText
              text={formDisabled ? LABELS.VIEW_LPO : formData.id ? LABELS.UPDATE_LPO : LABELS.CREATE_LPO}
              className={classes.titleTxt}
            />

            <IconButton aria-label="close" onClick={() => onClose()}>
              <HighlightOffRounded style={{ color: AppColor.appWhite }} />
            </IconButton>
          </div>

          <div style={{ overflowY: 'scroll' }}>
            <>
              <div className={classes.kycHeaderparent}>
                <div className={classes.headerIconParent}>
                  <SBText text="Basic Info" className={classes.headerText} />
                </div>

                <IconButton onClick={() => setBasicDetails(!basicDetails)}>
                  {!basicDetails ? (
                    <ExpandMoreIcon style={{ color: 'white' }} fontSize={'large'} />
                  ) : (
                    <ExpandLessIcon style={{ color: 'white' }} fontSize={'large'} />
                  )}
                </IconButton>
              </div>

              <Collapse in={basicDetails} timeout="auto" unmountOnExit>
                <div className={classes.formParent}>
                  <form autoComplete="off" className={classes.form}>
                    <div className={classes.fieldsContainer}>
                      <div className={classes.row}>
                        <div className={classes.formField}>
                          <div className={classes.Label}>
                            <SBText className={requiredFieldLabelClassName} text={LABELS.FIRST_NAME} />
                          </div>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            onChange={handleChange}
                            disabled={formDisabled}
                            value={formData.basicDetails.firstName}
                            name="firstName"
                            error={formErrors.has('firstName')}
                            helperText={formErrors.get('firstName')}
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                        </div>
                        <div className={classes.formField}>
                          <div className={classes.Label}>
                            <SBText className={requiredFieldLabelClassName} text={LABELS.LAST_NAME} />
                          </div>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            disabled={formDisabled}
                            onChange={handleChange}
                            value={formData.basicDetails.lastName}
                            name="lastName"
                            error={formErrors.has('lastName')}
                            helperText={formErrors.get('lastName')}
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                        </div>
                      </div>

                      <div className={classes.row}>
                        <div className={classes.formField}>
                          <div className={classes.Label}>
                            <SBText className={requiredFieldLabelClassName} text={LABELS.PHONE} />
                          </div>
                          <MuiPhoneNumber
                            defaultCountry={'in'}
                            fullWidth
                            onChange={handlePhoneChange}
                            variant="outlined"
                            autoFormat={false}
                            disabled={formDisabled}
                            countryCodeEditable={false}
                            disableAreaCodes={true}
                            value={formData.basicDetails.phone}
                            error={formErrors.has('phone')}
                            helperText={formErrors.get('phone')}
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                        </div>

                        <div className={classes.formField}>
                          <div className={classes.Label}>
                            <SBText className={requiredFieldLabelClassName} text={LABELS.EMAIL} />
                          </div>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            onChange={handleChange}
                            disabled={formDisabled}
                            value={formData.basicDetails.email}
                            name="email"
                            error={formErrors.has('email')}
                            helperText={formErrors.get('email')}
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                        </div>
                      </div>

                      <div className={classes.row}>
                        <div className={classes.formField} style={{ width: '100%' }}>
                          <div className={classes.Label}>
                            <SBText className={requiredFieldLabelClassName} text={LABELS.CITY} />
                          </div>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            onChange={handleChange}
                            disabled={formDisabled}
                            value={formData.basicDetails.city}
                            name="city"
                            error={formErrors.has('city')}
                            helperText={formErrors.get('city')}
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                        </div>
                      </div>
                      <div className={classes.row}>
                        <div className={classes.formField} style={{ width: '100%' }}>
                          <div className={classes.Label}>
                            <SBText className={requiredFieldLabelClassName} text={LABELS.OCCUPATION} />
                          </div>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            onChange={handleChange}
                            value={formData.basicDetails.occupation}
                            disabled={formDisabled}
                            name="occupation"
                            error={formErrors.has('occupation')}
                            helperText={formErrors.get('occupation')}
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Collapse>
            </>

            <>
              <div className={classes.kycHeaderparent}>
                <div className={classes.headerIconParent}>
                  <SBText text="KYC Details" className={classes.headerText} />
                </div>

                <IconButton onClick={() => setKyCDetails(!kycDetails)}>
                  {!kycDetails ? (
                    <ExpandMoreIcon style={{ color: 'white' }} fontSize={'large'} />
                  ) : (
                    <ExpandLessIcon style={{ color: 'white' }} fontSize={'large'} />
                  )}
                </IconButton>
              </div>

              <Collapse in={kycDetails} timeout="auto" unmountOnExit>
                <div
                  style={{ width: '100%', display: 'flex', flexDirection: 'column', paddingLeft: 16, paddingRight: 16 }}
                >
                  <div style={{ paddingLeft: 12, paddingTop: 30, paddingBottom: 16 }}>
                    <SBText className={classes.subLabelFont} text={'KYC Documents'} />
                    <Divider />
                  </div>
                  <>
                    <div className={classes.row}>
                      <div className={classes.formField}>
                        <div className={classes.Label}>
                          <SBText className={requiredFieldLabelClassName} text={LABELS.DOC_ID_PROOF_FRONT} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            onChange={handleChange}
                            disabled={formDisabled}
                            value={
                              formData.kycDetails?.doc_id_proof_front && formData.kycDetails?.doc_id_proof_front_name
                                ? getFileName(formData.kycDetails?.doc_id_proof_front_name)
                                : '-'
                            }
                            name="firstName"
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                          <div
                            className={classes.deleteButton}
                            onClick={() =>
                              onViewFileDetails(
                                formData.kycDetails?.doc_id_proof_front && formData.kycDetails?.doc_id_proof_front_name
                                  ? formData.kycDetails?.id
                                  : null,
                                'doc_id_proof_front_name',
                                getFileName(formData.kycDetails?.doc_id_proof_front_name)
                              )
                            }
                          >
                            <VisibilityIcon style={{ color: '#5D5F72' }} />
                          </div>
                        </div>
                      </div>
                      <div className={classes.formField}>
                        <div className={classes.Label}>
                          <SBText className={requiredFieldLabelClassName} text={LABELS.DOC_ID_PROOF_BACK} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            disabled={formDisabled}
                            onChange={handleChange}
                            value={
                              formData.kycDetails?.doc_id_proof_back && formData.kycDetails?.doc_id_proof_back_name
                                ? getFileName(formData.kycDetails?.doc_id_proof_back_name)
                                : '-'
                            }
                            name="lastName"
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                          <div
                            className={classes.deleteButton}
                            onClick={() =>
                              onViewFileDetails(
                                formData.kycDetails?.doc_id_proof_back && formData.kycDetails?.doc_id_proof_back_name
                                  ? formData.kycDetails?.id
                                  : null,
                                'doc_id_proof_back_name',
                                getFileName(formData.kycDetails?.doc_id_proof_back_name)
                              )
                            }
                          >
                            <VisibilityIcon style={{ color: '#5D5F72' }} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={classes.row}>
                      <div className={classes.formField}>
                        <div className={classes.Label}>
                          <SBText className={requiredFieldLabelClassName} text={LABELS.DOC_ADDRESS_PROOF} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            onChange={handleChange}
                            disabled={formDisabled}
                            value={
                              formData.kycDetails?.doc_address_proof && formData.kycDetails?.doc_address_proof_name
                                ? getFileName(formData.kycDetails?.doc_address_proof_name)
                                : '-'
                            }
                            name="firstName"
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                          <div
                            className={classes.deleteButton}
                            onClick={() =>
                              onViewFileDetails(
                                formData.kycDetails?.doc_address_proof && formData.kycDetails?.doc_address_proof_name
                                  ? formData.kycDetails?.id
                                  : null,
                                'doc_address_proof_name',
                                getFileName(formData.kycDetails?.doc_address_proof_name)
                              )
                            }
                          >
                            <VisibilityIcon style={{ color: '#5D5F72' }} />
                          </div>
                        </div>
                      </div>
                      <div className={classes.formField}>
                        <div className={classes.Label}>
                          <SBText className={requiredFieldLabelClassName} text={LABELS.DOC_BANK_DETAILS} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            disabled={formDisabled}
                            onChange={handleChange}
                            value={
                              formData.kycDetails?.doc_bank_details && formData.kycDetails?.doc_bank_details_name
                                ? getFileName(formData.kycDetails?.doc_bank_details_name)
                                : '-'
                            }
                            name="lastName"
                            InputProps={{
                              className: clsx(classes.textFieldInput),
                            }}
                          />
                          <div
                            className={classes.deleteButton}
                            onClick={() =>
                              onViewFileDetails(
                                formData.kycDetails?.doc_bank_details && formData.kycDetails?.doc_bank_details_name
                                  ? formData.kycDetails?.id
                                  : null,
                                'doc_bank_details_name',
                                getFileName(formData.kycDetails?.doc_bank_details_name)
                              )
                            }
                          >
                            <VisibilityIcon style={{ color: '#5D5F72' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>

                  <div style={{ paddingLeft: 12, paddingTop: 30, paddingBottom: 16 }}>
                    <SBText className={classes.subLabelFont} text={'Education Details'} />
                    <Divider />
                  </div>

                  <div className={classes.row}>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText className={requiredFieldLabelClassName} text={LABELS.HIGHER_EDUCATION} />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChange}
                        disabled={formDisabled}
                        value={formData.kycDetails?.higherEducation ? formData.kycDetails?.higherEducation : '-'}
                        name="firstName"
                        InputProps={{
                          className: clsx(classes.textFieldInput),
                        }}
                      />
                    </div>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText className={requiredFieldLabelClassName} text={LABELS.OTHER_HIGHER_EDUCATION} />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        disabled={formDisabled}
                        onChange={handleChange}
                        value={
                          formData.kycDetails?.otherHigherEducation ? formData.kycDetails.otherHigherEducation : '-'
                        }
                        name="lastName"
                        InputProps={{
                          className: clsx(classes.textFieldInput),
                        }}
                      />
                    </div>
                  </div>

                  <div className={classes.row}>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText className={requiredFieldLabelClassName} text={LABELS.BIRTH_DATE} />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChange}
                        disabled={formDisabled}
                        value={formData.kycDetails?.birthDate ? formData.kycDetails?.birthDate : '-'}
                        name="firstName"
                        InputProps={{
                          className: clsx(classes.textFieldInput),
                        }}
                      />
                    </div>
                    <div className={classes.formField}>
                      {/* <div className={classes.Label}>
                    <SBText className={requiredFieldLabelClassName} text={LABELS.OTHER_HIGHER_EDUCATION} />
                  </div>
                  <TextField
                    className={classes.textField}
                    variant="outlined"
                    disabled={formDisabled}
                    onChange={handleChange}
                    value={formData.kycDetails?.otherHigherEducation? formData.kycDetails.otherHigherEducation : "-"}
                    name="lastName"
                    InputProps={{
                      className: clsx(classes.textFieldInput),
                    }}
                  /> */}
                    </div>
                  </div>

                  <div style={{ paddingLeft: 12, paddingTop: 30, paddingBottom: 16 }}>
                    <SBText className={classes.subLabelFont} text={'Address Details'} />
                    <Divider />
                  </div>

                  <div className={classes.row}>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText className={requiredFieldLabelClassName} text={LABELS.ADDRESS_LINE1} />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChange}
                        disabled={formDisabled}
                        value={formData.kycDetails?.line1 ? formData.kycDetails?.line1 : '-'}
                        name="firstName"
                        InputProps={{
                          className: clsx(classes.textFieldInput),
                        }}
                      />
                    </div>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText className={requiredFieldLabelClassName} text={LABELS.ADDRESS_LINE2} />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        disabled={formDisabled}
                        onChange={handleChange}
                        value={formData.kycDetails?.line2 ? formData.kycDetails.line2 : '-'}
                        name="lastName"
                        InputProps={{
                          className: clsx(classes.textFieldInput),
                        }}
                      />
                    </div>
                  </div>

                  <div className={classes.row}>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText className={requiredFieldLabelClassName} text={LABELS.ADDRESS_CITY} />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChange}
                        disabled={formDisabled}
                        value={formData.kycDetails?.city ? formData.kycDetails?.city : '-'}
                        name="firstName"
                        InputProps={{
                          className: clsx(classes.textFieldInput),
                        }}
                      />
                    </div>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText className={requiredFieldLabelClassName} text={LABELS.ADDRESS_STATE} />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        disabled={formDisabled}
                        onChange={handleChange}
                        value={formData.kycDetails?.state ? formData.kycDetails.state : '-'}
                        name="lastName"
                        InputProps={{
                          className: clsx(classes.textFieldInput),
                        }}
                      />
                    </div>
                  </div>

                  <div className={classes.row}>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText className={requiredFieldLabelClassName} text={LABELS.ADDRESS_COUNTRY} />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        onChange={handleChange}
                        disabled={formDisabled}
                        value={formData.kycDetails?.country ? formData.kycDetails?.country : '-'}
                        name="firstName"
                        InputProps={{
                          className: clsx(classes.textFieldInput),
                        }}
                      />
                    </div>
                    <div className={classes.formField}>
                      <div className={classes.Label}>
                        <SBText className={requiredFieldLabelClassName} text={LABELS.ADDRESS_PINCODE} />
                      </div>
                      <TextField
                        className={classes.textField}
                        variant="outlined"
                        disabled={formDisabled}
                        onChange={handleChange}
                        value={formData.kycDetails?.pincode ? formData.kycDetails.pincode : '-'}
                        name="lastName"
                        InputProps={{
                          className: clsx(classes.textFieldInput),
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ paddingLeft: 12, paddingTop: 30, paddingBottom: 16 }}>
                    <SBText className={classes.subLabelFont} text={'Verification Status'} />
                    <Divider />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <SBText className={classes.verificationText} text={LABELS.VERIFICATION_EMAIL} />
                    {formData?.verificationDetails?.emailVerication &&
                    formData?.verificationDetails?.emailVerication == 'completed' ? (
                      <img src={verifieldSmall} className={classes.verifiedImage} />
                    ) : (
                      <img src={pendingSmall} className={classes.verifiedImage} />
                    )}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <SBText className={classes.verificationText} text={LABELS.VERIFICATION_OTP} />
                    {formData?.verificationDetails?.otpVerification &&
                    formData?.verificationDetails?.otpVerification == 'completed' ? (
                      <img src={verifieldSmall} className={classes.verifiedImage} />
                    ) : (
                      <img src={pendingSmall} className={classes.verifiedImage} />
                    )}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <SBText className={classes.verificationText} text={LABELS.VERIFICATION_KYC} />
                    {formData?.verificationDetails?.kycVerification &&
                    formData?.verificationDetails?.kycVerification == 'completed' ? (
                      <img src={verifieldSmall} className={classes.verifiedImage} />
                    ) : (
                      <img src={pendingSmall} className={classes.verifiedImage} />
                    )}
                  </div>
                </div>
              </Collapse>
            </>

            <div style={{ paddingLeft: 12, paddingTop: 30, paddingBottom: 16 }}>
              <Divider />
            </div>

            <div className={classes.btnContainer}>
              <SBButton icon={false} text={LABELS.CLOSE} color="primary" onClick={() => onClose()} />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default LPOModal;
