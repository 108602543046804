import { listPageSearchParam } from "../../../reducer/types";
import { ProviderPaginatedData } from "../types";

export const PAGE_SIZES: number[] = [5, 10, 20, 50];
export const DEFAULT_PAGE_SIZE: number = PAGE_SIZES[0];
export const FIRST_PAGE: number = 0;
export const DEFAULT_FETCH_PARAMS: listPageSearchParam = {
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
  searchText: "",
};

export const INITIAL_TABLE_DATA: ProviderPaginatedData = {
  totalRecords: 0,
  currentPage: FIRST_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  totalPages: 1,
  data: [],
};
