import { ThunkAction } from "redux-thunk";
import {
  ActionTypes,
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../../actions";
import { RootState } from "../../../../reducer";
import { ActionType } from "../../../../reducer/types";
import { API_URL } from "../../../../util/apiUrls";
import { AgentStatus, AgentStatusDetails } from "../../types";

export const getAgentStatuses = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch, getState) => {
  dispatch({ type: ON_LOADING_START });

  const result = await get(API_URL.AGENTS.STATUSES, dispatch);

  const statuses: { status: number; value: string }[] = [];
  result.forEach((status: any) => {
    statuses.push({
      ...status,
      value: AgentStatusDetails[status.status as AgentStatus]?.label,
    });
  });

  await dispatch({
    type: ActionTypes.SET_ALL_AGENT_STATUSES,
    payload: statuses,
  });
  dispatch({ type: ON_LOADING_FINISHED });
};
