/* eslint-disable react/no-multi-comp */
import { Theme } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { createStyles, makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import React from "react";
import "react-portal-hint/dist/default.css";
import { QuestionType } from "../../../actions/util/QuestionType";
import SBText from "../../../components/base/SBText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    cardRoot: {
      minWidth: "45vw",
      marginBottom: 30,
      marginTop: 30,
    },
    container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    results: {
      marginTop: theme.spacing(3),
    },
    filterButton: {
      display: "flex",
    },
    filterIcon: {
      marginRight: theme.spacing(1),
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    cardParent: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "#ffffff",
    },
    cardAction: {
      marginBottom: 0,
    },
    componentParent: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(3),
      textAlign: "left",
    },
    previousBt: {
      height: 20,
      width: 20,
      color: "white",
      filter: "invert(100%)",
      marginRight: theme.spacing(5),
    },
    nextBt: {
      height: 20,
      width: 20,
      color: "white",
      filter: "invert(100%)",
      marginLeft: theme.spacing(5),
    },
    radioParent: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      "&:hover": {
        backgroundColor: "#ededed",
      },
    },
    cardContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      "&:hover": {
        backgroundColor: "#ededed",
      },
    },
    text: {
      fontSize: 12,
      color: "#19201C",
      fontWeight: 600,
    },

    circleView: {
      height: 20,
      width: 20,
      minWidth: 20,
      maxWidth: 26,
      borderRadius: 12.5,
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "#5C5F74",
      cursor: "pointer",
      transition: "width 0.5s, height 0.5s",
    },
    circleActive: {
      width: 26,
      height: 26,
      fontWeight: "bolder",
      borderWidth: 2,
      filter: "brightness(120%)",
    },
    lastSegment: {
      height: 1,
      flex: 1,
      backgroundColor: "#5C5F74",
      display: "flex",
    },
  })
);

type ProgressStepperProps = {
  dataObject: any;
  currentIndex: number;
  onStepperClick: (index: number) => void;
  applicationLocalAnswerd: any
};

const ProgressStepper: React.FC<ProgressStepperProps> = ({
  dataObject,
  currentIndex,
  onStepperClick,
  applicationLocalAnswerd
}) => {
  const classes = useStyles();

  const getStepperBackgroundColor = (item: any) => {
    const completelyFilled = "#8BB19C";
    if (item.questionList && item.questionList.length > 0) {
      let isPartiallyFilled = false;
      let isCompletelyFilled = false;
      let isParentQuestionIsLabel = false;

      if (item.type != QuestionType.Label) {
        if (item.id in  applicationLocalAnswerd && applicationLocalAnswerd[item.id].answer) {
          isCompletelyFilled = false;
          isPartiallyFilled = true;
        } else if (item.answer) {
          isCompletelyFilled = true;
        }
      } else {
        isParentQuestionIsLabel = true;
        // isCompletelyFilled = true;
      }

      const isAllQuestionDisabled = isAllSubQuestionDisabled(item.questionList);

      if (isAllQuestionDisabled) {
        if (isCompletelyFilled) {
          return completelyFilled;
        } else if (isPartiallyFilled) {
          return "#ECCC97";
        } else {
          return "#FFFFFF";
        }
      }

      if (isPartiallyFilled) {
        return "#ECCC97";
      }

      const localAnswer = isAllSubHasLocalAnswer(item.questionList);
      if (localAnswer) {
        return "#ECCC97";
      }
      const allQuestionAnswered = isAllQuestionAnswered(item.questionList);
      if (
        allQuestionAnswered &&
        (isCompletelyFilled || isParentQuestionIsLabel)
      ) {
        if (item.repeat && item.tabs.length) {
          for (let tab of item.tabs) {
            let isallAnswered = isAllQuestionAnswered(tab.questionList);
            if (!isallAnswered) {
              return "#ECCC97";
            }
          }
        }
        return completelyFilled;
      }
      const fewQuestion = isFewQuestionAnswered(item.questionList);

      if (fewQuestion) {
        return "#ECCC97";
      }

      if (!fewQuestion && !isCompletelyFilled) {
        return "#FFFFFF";
      } else {
        return "#ECCC97";
      }
    } else {
      if (item.id in  applicationLocalAnswerd && applicationLocalAnswerd[item.id].answer) {
        return "#ECCC97";
      } else if (item.answer) {
        if (
          item.type === "MultiSelect" &&
          !item.answer.multiSelectedOptions.length
        ) {
          return "#ECCC97";
        }
        return completelyFilled;
      } else {
        return "#FFFFFF";
      }
    }
  };

  const isAllSubHasLocalAnswer = (questionList: any) => {
    let isLocalAnswerFound = false;
    for (let i = 0; i < questionList.length; i++) {
      if (
        questionList[i].type != QuestionType.Label &&
        questionList[i].id in applicationLocalAnswerd && applicationLocalAnswerd[questionList[i].id].answer
      ) {
        isLocalAnswerFound = true;
        break;
      }
    }
    return isLocalAnswerFound;
  };

  const isAllSubQuestionDisabled = (questionList: any) => {
    let isQuestionDisabled = true;
    for (let i = 0; i < questionList.length; i++) {
      if (questionList[i].status && questionList[i].status == "DISABLED") {
        isQuestionDisabled = true;
      } else {
        isQuestionDisabled = false;
        break;
      }
    }
    return isQuestionDisabled;
  };

  const isAllQuestionAnswered = (questionList: any) => {
    let isAllQuestionAnswered = true;
    for (let i = 0; i < questionList.length; i++) {
      if (
        !questionList[i].answer &&
        questionList[i].type != QuestionType.Label
      ) {
        isAllQuestionAnswered = false;
        break;
      }
    }
    return isAllQuestionAnswered;
  };

  const isFewQuestionAnswered = (questionList: any) => {
    let isFewQuestionAnswered = false;
    for (let i = 0; i < questionList.length; i++) {
      if (
        questionList[i].type != QuestionType.Label &&
        questionList[i].answer
      ) {
        isFewQuestionAnswered = true;
        break;
      }
    }
    return isFewQuestionAnswered;
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {dataObject.map((item: any, i: number) => {
          const itemClassName =
            i + 1 === currentIndex
              ? clsx(classes.circleView, classes.circleActive)
              : classes.circleView;

          return (
            <>
              <div
                onClick={() => onStepperClick(i)}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Tooltip
                  title={item.question}
                  placement="bottom"
                  aria-label="add"
                >
                  <div
                    className={itemClassName}
                    style={{
                      backgroundColor: getStepperBackgroundColor(item),
                    }}
                  >
                    <SBText
                      text={String(i + 1)}
                      className={classes.text}
                    ></SBText>
                  </div>
                </Tooltip>
              </div>

              {dataObject.length - 1 != i ? (
                <div className={classes.lastSegment} />
              ) : null}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressStepper;
