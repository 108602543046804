import { connect, ConnectedProps } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../reducer';
import Page from '../../components/Page';
import { createStyles, makeStyles } from '@material-ui/styles';
import {
  Theme,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  Button,
  Switch,
} from '@material-ui/core';
import ApplicationMailList from './ApplicationMailList';
import MessageDialog from '../../components/MessageDialog';
import SBButton from '../../components/base/SBButton';
import SBText from '../../components/base/SBText';
import SBEmailTemplates from '../../components/SBEmailTemplates';
import {
  getApplicationVerificationMails,
  resetApplicationVerificationMailList,
  getVerificationMailDetails,
  getEmailTemplate,
  resetApplicationMailDetails,
  getDeferredEmailTemplate,
  okToProceed,
  getSignedURL,
  viewQuestionFile,
  uploadVerificationEvent,
  okToProceedWithMultipleEmail,
} from '../../actions';
import moment from 'moment';
import SBAutoVerificationTemplate from '../../components/SBAutoVerificationTemplate';
import _ from 'lodash';
import { decryptText, getApplicationIdInString } from '../../util';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useParams } from 'react-router';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import WorkOutlined from '@material-ui/icons/WorkOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import SchoolIcon from '@material-ui/icons/School';
import FileViewer from '../FileViewer';
import { hasOneOfPermissions, PERMISSION } from '../../util/rolePermissionsUtil';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import PublishRoundedIcon from '@material-ui/icons/PublishRounded';
import VerificationResponseModel from './VerificationResponseModel';
import SBEmailWithTabsTemplates from '../../components/SBEmailWithTabsTemplate';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#E5E5E5',
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(4),
      height: 'calc(100vh - 64px)',
    },
    statusParent: {
      height: '100%',
      width: '100%',
      backgroundColor: '#EACB78',
      borderWidth: 0.8,
      borderStyle: 'solid',
      borderColor: '#C99E27',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
    },

    statusApproveParent: {
      height: '100%',
      width: '100%',
      backgroundColor: '#CBDFD4',
      borderColor: '#32A164',
      borderWidth: 0.8,
      borderStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
    },

    statusRejectParent: {
      height: '100%',
      width: '100%',
      backgroundColor: '#E3ACAA',
      borderColor: '#E5706C',
      borderWidth: 0.8,
      borderStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
    },

    approveBt: {
      backgroundColor: 'rgba(245,245,245,1)',
      borderWidth: 1,
      paddingLeft: 10,
      paddingRight: 10,
      height: 30,
      fontSize: 12,
      borderColor: '#4c4c4c',
      borderStyle: 'solid',
      color: '#4c4c4c',
      '&:hover': {
        backgroundColor: '#CBDFD4',
        borderColor: '#32A164',
        boxShadow: 'none',
      },
    },
    viewBt: {
      marginRight: 5,
      borderRadius: 20,
      height: 30,
    },
    initiateBt: {
      backgroundColor: 'rgba(245,245,245,0)',
      height: 30,
      borderWidth: 1,
      borderColor: '#4c4c4c',
      borderStyle: 'solid',
      color: '#4c4c4c',
      fontSize: 12,
      marginLeft: 5,
      '&:hover': {
        backgroundColor: '#DCE8F9',
        borderColor: '#6993CD',
        boxShadow: 'none',
      },
    },
    content: {
      padding: 0,
    },
    inner: {
      display: 'flex',
      width: '100%',
    },
    tableBody: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      // borderBottomColor: "black",
      // borderBottomWidth: 1,
      // borderBottomStyle: "solid",
      height: 55,
    },
    tableRowHeader: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      backgroundColor: '#EFEFEF',
      height: 55,
    },
    tableCell: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },
    CollapseRow: {
      backgroundColor: '#FAFAFA',
      boxShadow: 'inset 0 0 10px rgb(0 0 0 / 12%)',
    },
    tableRowCollapse: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: 55,
    },
    dateTableCell: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    dateTableCellStatus: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Poppins',
      fontWeight: 600,
      letterSpacing: 0.75,
    },
    dateTableCollapseCell: {
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'Poppins',
      borderBottom: 0,
      letterSpacing: 0.75,
    },
    messageTableCell: {
      display: 'flex',
      flex: 0.5,
      alignItems: 'center',
    },
    actionTableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    actionCollaseTableCell: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: 0,
      justifyContent: 'center',
    },
    dateHeaderTableCell: {
      display: 'flex',
    },
    dataIcon: {
      display: 'flex',
    },
    dataCollapseIcon: {
      display: 'flex',
      borderBottom: 0,
    },
    messageHeaderTableCell: {
      display: 'flex',
      flex: 0.5,
    },

    actionHeaderTableCell: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tableValueCell: {
      display: 'flex',
      flex: 1,
    },
    tableHeader: {
      height: 75,
    },
    statusPending: {
      backgroundColor: '#EACB78',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 12,
      borderWidth: 0.8,
      borderStyle: 'solid',
      borderColor: '#C99E27',
    },
    statusApp: {
      backgroundColor: '#CBDFD4',
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: 12,
      borderWidth: 0.8,
      paddingTop: 5,
      paddingBottom: 5,
      borderColor: '#32A164',
      borderStyle: 'solid',
    },
    statusRejected: {
      backgroundColor: '#E3ACAA',
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: 12,
      borderWidth: 0.8,
      borderColor: '#E5706C',
      borderStyle: 'solid',
    },
    tabInactive: {
      height: 50,
      display: 'flex',
      flex: 1,
      maxWidth: '20%',
      backgroundColor: 'white',
      marginRight: 20,
      alignItems: 'center',
      justifyContent: 'center',
    },
    tabSelected: {
      height: 60,
      display: 'flex',
      flex: 1,
      maxWidth: '20%',
      backgroundColor: 'white',
      marginRight: 20,
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      zIndex: 1,
      // boxShadow: "1px -10px 10px 2px  rgba(0, 0, 0, 0.117647)",
      boxShadow: ' -2px -3px 4px 1px rgba(0, 0, 0, 0.1);',
    },

    tabsParent: {
      height: 60,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    tableParents: { border: '1px solid #B0AFAF', display: 'flex', flex: 1 },

    mainLayout: {
      display: 'flex',
      flex: 1,
      backgroundColor: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.117647) 2px 4px 6px 4px',
      padding: 16,
    },
    verticalDivider: { width: 10, backgroundColor: '#E5E5E5' },
    tabText: {
      fontWeight: 500,
      fontSize: 14,
      [theme.breakpoints.up(1920)]: {
        fontSize: 15,
      },
    },
    tableHeaderText: {
      fontWeight: 500,
      fontSize: 14,
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    disapproveText: {
      fontSize: 14,
      fontWeight: 700,
      color: '#D27F65',
      [theme.breakpoints.up(1920)]: {
        fontSize: 15,
      },
    },
    approveText: {
      fontSize: 14,
      fontWeight: 700,
      color: '#8BB19C',
      [theme.breakpoints.up(1920)]: {
        fontSize: 15,
      },
    },
    tableText: {
      fontSize: 14,
    },
    buttonContainer: {
      border: '1px solid #5C5F74',
      borderRadius: 20,
    },
    label: {
      textTransform: 'none',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 500,
      color: '#5C5F74',
    },
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type InboxProps = PropsFromRedux & {
  classes?: string;
  className?: string;
};
const Inbox: React.FC<InboxProps> = ({
  className,
  getApplicationVerificationMails,
  resetApplicationVerificationMailList,
  applicationMailList,
  getVerificationMailDetails,
  applicationMailDetails,
  getEmailTemplate,
  emailTemplate,
  resetApplicationMailDetails,
  getDeferredEmailTemplate,
  okToProceed,
  viewQuestionFile,
  uploadVerificationEvent,
  okToProceedWithMultipleEmail,
  ...rest
}) => {
  const userPermissions: PERMISSION[] = useUserPermissions();
  const flexMappingOther = [0.05, 0.15, 0.15, 0.25, 0.15, 0.15, 0.1];
  const flexMappingProcessingOfficer = [0.03, 0.15, 0.15, 0.2, 0.15, 0.15, 0.08, 0.09];
  const isPO_or_VNT = hasOneOfPermissions(
    [
      PERMISSION.applications_provider_view_org,
      PERMISSION.applications_provider_view_own,
      PERMISSION.applications_provider_view_global,
    ],
    userPermissions
  );
  const flexMapping = isPO_or_VNT ? flexMappingProcessingOfficer : flexMappingOther;
  let { applicationId = '0' } = useParams<{ applicationId: string }>();
  const classes = useStyles();
  const [selectedData, setSelectedData] = useState<any>(null);
  const [messageDialog, showMessageDialog] = useState<any>(false);
  const [completeStatus, setCompleteStatus] = useState<any>(false);
  const [model, showModel] = useState<any>(false);
  const [responseModel, showResponseModel] = useState<any>(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [tabIndex, selectedTabIndex] = useState<any>(0);
  const [appList, setAppList] = useState<any>([]);
  const [sortValue, setSortValue] = useState<any>('');
  const [autoVerificationModel, showAutoVerificationModel] = useState<any>(false);
  const [autoVerificationModelContent, setAutoVerificationModelContent] = useState<any>('');
  const [open, setOpen] = React.useState(-1);
  const [preSelectedApplication, setPreSelectedApplication] = React.useState<string>('');

  useEffect(() => {
    const preSelect = applicationId && applicationId != '0' ? (decryptText(applicationId) as string) : '0';
    if (preSelect != '0') {
      getVerificationMailDetails(Number(preSelect));
      setPreSelectedApplication(preSelect);
    }
  }, [applicationId]);

  useEffect(() => {
    setAppList(applicationMailList);
  }, [applicationMailList]);

  const getStyleAsPerStatus = (status: string) => {
    if (completeStatus) {
      if (status === 'Approved') {
        return classes.statusApproveParent;
      } else {
        return classes.statusRejectParent;
      }
    } else {
      return classes.statusParent;
    }
  };

  useEffect(() => {
    getApplicationVerificationMails();

    return () => {
      resetApplicationMailDetails();
      resetApplicationVerificationMailList();
    };
  }, []);

  const onListItemClick = async (object: any, index: number) => {
    if (object) {
      setSelectedItem(object);
      await getVerificationMailDetails(object.applicationId);
    }
  };

  const onSearchText = (text: string) => {
    if (text) {
      const sortedList = _.filter(applicationMailList, (obj: any) => {
        const applicationId = getApplicationIdInString(obj.applicationId);
        const nameValue = obj.application.keyAnswers[0].value;

        return (
          applicationId.toLowerCase().toLowerCase().includes(text.toLowerCase()) ||
          nameValue.toLowerCase().toLowerCase().includes(text.toLowerCase())
        );
      });

      // console.log("Sorted List :::", sortedList);

      setAppList(sortedList);
      // return sortedList;
    } else {
      setAppList(applicationMailList);
      // return dmsApplicationList;
    }
  };

  const onSortItem = (text: string) => {
    setSortValue(text);

    if (text) {
      const mailList = _.cloneDeep(applicationMailList);
      if (text == 'Application ID') {
        const sortedArray = _.sortBy(mailList, [
          (o: any) => {
            return o.applicationId;
          },
        ]);
        setAppList(sortedArray);
      } else if (text == 'Date') {
        const sortedArray = _.sortBy(mailList, (obj) => {
          return new Date(obj.createdAt);
        });
        setAppList(sortedArray);
      } else if (text == 'Student Name') {
        const sortedArray = _.sortBy(mailList, (obj) => {
          return obj.application.keyAnswers[0].value;
        });
        setAppList(sortedArray);
      }
    } else {
      setAppList(applicationMailList);
    }
  };

  //console.log("Mail Details", applicationMailDetails);

  const getFormattedDate = (date: any) => {
    return moment(date).format('DD MMM YYYY HH:mm');
  };

  const getImageOfEmailTemplate = async (fileName: string) => {
    const signedURL = await getSignedURL(fileName, 'getObject', null);
  };

  const onViewTemplate = async (questionId: any, eventId: any, ids: any) => {
    // console.log("Event >>>", event);
    const result: any = await getEmailTemplate(questionId, eventId, ids);
    if (result.type == 'Auto') {
      if (result.content) {
        setAutoVerificationModelContent({ html: result.content });
        showAutoVerificationModel(true);
      }
    } else {
      if (result) {
        showModel(true);
      }
    }
  };

  const onViewDeferredTemplate = async (deferredId: any, event: any) => {
    const result: any = await getDeferredEmailTemplate(selectedItem.applicationId, deferredId, event);
    if (result) {
      setAutoVerificationModelContent({ html: result });
      showAutoVerificationModel(true);
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Pending':
        return '#f2ca8e';
        break;
      case 'Approved':
        return '#83b49d';
        // code block
        break;
      case 'Completed':
        return '#83b49d';
        break;
      case 'Rejected':
      case 'Cancelled':
        return '#db6b4f';
        break;
      default:
        return '#83b49d';
    }
  };

  const getLatestEventStatus = (eventList: any) => {
    if (eventList && eventList.lenght > 0) {
      const sortedEventList = _.sortBy(eventList, (o: any) => {
        return o.id;
      }).reverse();

      if (sortedEventList[0].status == 'Disapproved') {
        return 'Rejected';
      } else {
        return sortedEventList[0].status;
      }
    } else {
      return '';
    }
  };

  const onResendVerification = (verificationObj: any) => {
    console.log('verificationObj', verificationObj);
    if (verificationObj.questionIds && verificationObj.questionIds.length > 0) {
      const ids = _.map(verificationObj.questionIds, 'questionId');
      onViewTemplate(verificationObj.questionId, -1, ids);
      setSelectedData(verificationObj);
    }
  };

  const okToProceedVerification = async () => {
    showModel(false);
    const ids = _.map(selectedData.questionIds, 'questionId');
    await okToProceedWithMultipleEmail(ids);
    getVerificationMailDetails(selectedData.applicationId);
    setSelectedData(null);
  };

  const setManuallyEvent = (type: string, file: any, comments: string) => {
    showResponseModel(false);
    uploadVerificationEvent(type, selectedData.id, comments, file, selectedItem.applicationId);
  };

  const getEvents = (varificationGroupData: any) => {
    console.log('Event Lust ', varificationGroupData);
    const eventRow = [];
    for (let i = 0; i < varificationGroupData.length; i++) {
      const headerStatus = getLatestEventStatus(varificationGroupData[i].events);
      eventRow.push(
        <>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.dataIcon} style={{ flex: flexMapping[0] }}>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(open === i ? -1 : i)}>
                {open === i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell className={classes.dateTableCell} style={{ flex: flexMapping[1] }}>
              {getGroupIcon(varificationGroupData[i].verificationGroup)}
              {varificationGroupData[i].verificationGroup}
            </TableCell>
            <TableCell className={classes.dateTableCell} style={{ flex: flexMapping[2] }}>
              {getFormattedDate(varificationGroupData[i].createdAt)}
            </TableCell>
            <TableCell className={classes.dateTableCell} style={{ flex: flexMapping[3] }}>
              {varificationGroupData[i].events &&
                varificationGroupData[i].events.length > 0 &&
                varificationGroupData[i].events[0].reference}
            </TableCell>

            <TableCell
              className={classes.dateTableCellStatus}
              style={{ flex: flexMapping[4], color: getStatusColor(headerStatus) }}
            >
              {headerStatus}
            </TableCell>
            <TableCell className={classes.dateTableCell} style={{ flex: flexMapping[5] }}>
              {getFormattedDate(varificationGroupData[i].updatedAt)}
            </TableCell>

            <TableCell className={classes.actionTableCell} style={{ flex: flexMapping[6] }}>
              {headerStatus !== 'Approved' &&
              varificationGroupData[i].questionIds &&
              varificationGroupData[i].questionIds.length > 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Button
                    variant="outlined"
                    classes={{
                      root: classes.buttonContainer,
                      label: classes.label,
                    }}
                    // disabled={true}
                    onClick={() => onResendVerification(varificationGroupData[i])}
                  >
                    Resend
                  </Button>
                </div>
              ) : null}
            </TableCell>

            {isPO_or_VNT && (
              <TableCell className={classes.actionCollaseTableCell} style={{ flex: flexMapping[6] }}>
                <IconButton
                  onClick={() => {
                    setSelectedData(varificationGroupData[i]);
                    showResponseModel(true);
                  }}
                >
                  <PublishRoundedIcon />
                </IconButton>
              </TableCell>
            )}
          </TableRow>
          {/* <TableRow> */}
          {/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}> */}
          <Collapse in={open === i} component="tr" timeout="auto" unmountOnExit className={classes.CollapseRow}>
            <Table>
              <TableBody className={classes.tableBody}>
                {varificationGroupData[i].events.map((evt: any, i: number) => (
                  <TableRow className={classes.tableRowCollapse}>
                    <TableCell className={classes.dataCollapseIcon} style={{ flex: flexMapping[0] }}></TableCell>
                    <TableCell className={classes.dateTableCollapseCell} style={{ flex: flexMapping[1] }}></TableCell>
                    <TableCell className={classes.dateTableCollapseCell} style={{ flex: flexMapping[2] }}></TableCell>
                    <TableCell className={classes.dateTableCollapseCell} style={{ flex: flexMapping[3] }}>
                      {evt.event}
                    </TableCell>
                    <TableCell
                      className={classes.dateTableCellStatus}
                      style={{
                        flex: flexMapping[4],
                        color: getStatusColor(evt.status == 'Disapproved' ? 'Rejected' : evt.status),
                      }}
                    >
                      {evt.status == 'Disapproved' ? 'Rejected' : evt.status}
                    </TableCell>
                    <TableCell className={classes.dateTableCollapseCell} style={{ flex: flexMapping[5] }}>
                      {' '}
                      {getFormattedDate(evt.updatedAt)}
                    </TableCell>
                    <TableCell className={classes.actionCollaseTableCell} style={{ flex: flexMapping[6] }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        {evt.status != 'Cancelled' ? (
                          <SBButton
                            text={'View'}
                            icon={false}
                            className={classes.viewBt}
                            disabled={!evt.fileName}
                            onClick={() => {
                              if (varificationGroupData[i]?.deferredId) {
                                onViewDeferredTemplate(
                                  varificationGroupData[i].deferredId,
                                  varificationGroupData[i].event &&
                                    varificationGroupData[i].event.startsWith('Response Received')
                                    ? true
                                    : false
                                );
                              } else {
                                console.log('getImageOfEmailTemplate', evt);
                                if (evt.fileName) {
                                  viewQuestionFile({ fileName: evt.fileName }, null);
                                }
                                // onViewTemplate(
                                //   varificationGroupData[i].queId,
                                //   varificationGroupData[i].id,
                                //   varificationGroupData[i]
                                // );
                              }
                            }}
                          />
                        ) : null}
                      </div>
                    </TableCell>

                    {isPO_or_VNT && (
                      <TableCell
                        className={classes.actionCollaseTableCell}
                        style={{ flex: flexMapping[6] }}
                      ></TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
          {/* </TableCell> */}
          {/* </TableRow> */}
          <FileViewer />
        </>
      );
    }
    return eventRow;
  };

  const getGroupIcon = (name: string) => {
    const style = {
      color: 'gray',
      fontSize: 18,
      marginRight: 5,
      // margin: "10px auto",
    };

    switch (name) {
      case 'Registration':
        return <DescriptionOutlinedIcon style={style} />;
      case 'Acadmic 12th':
        return <SchoolIcon style={style} />;
      case 'Work Exp':
        return <WorkOutlined style={style} />;
      case 'Savings Verification':
      case 'FD Verification':
      case 'Education loan':
        return <MonetizationOnIcon style={style} />;
      default:
        return <DescriptionOutlinedIcon style={style} />;
    }
  };

  console.log('applicationMailDetails', applicationMailDetails);
  return (
    <Page className={classes.root} title={'Inbox'}>
      <ApplicationMailList
        onListItemClick={(obj: any, index: any) => onListItemClick(obj, index)}
        list={appList}
        onSearchText={(text: string) => onSearchText(text)}
        onSortItemClick={(text: string) => onSortItem(text)}
        sortValue={sortValue}
        selectedItem={selectedItem}
        selectedApplication={preSelectedApplication}
      />
      <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
      {applicationMailDetails && applicationMailDetails.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <div className={classes.mainLayout} style={{ overflowY: 'auto' }}>
            <div className={classes.tableParents}>
              <Table>
                <TableBody className={classes.tableBody}>
                  <TableRow className={classes.tableRowHeader}>
                    <TableCell className={classes.dataIcon} style={{ flex: flexMapping[0] }}>
                      {/* <SBText text={"Type"} className={classes.tableHeaderText} /> */}
                    </TableCell>
                    <TableCell className={classes.dateHeaderTableCell} style={{ flex: flexMapping[1] }}>
                      <SBText text={'Type'} className={classes.tableHeaderText} />
                    </TableCell>
                    <TableCell className={classes.dateHeaderTableCell} style={{ flex: flexMapping[2] }}>
                      <SBText text={'Started'} className={classes.tableHeaderText} />
                    </TableCell>
                    {/* <TableCell className={classes.dateHeaderTableCell}>
                      <SBText text={"Date"} className={classes.tableHeaderText} />
                    </TableCell> */}
                    <TableCell className={classes.dateHeaderTableCell} style={{ flex: flexMapping[3] }}>
                      <SBText text={'Reference'} className={classes.tableHeaderText} />
                    </TableCell>
                    {/* <TableCell className={classes.messageHeaderTableCell}>
                      <SBText className={classes.tableHeaderText} text={"Events"} />
                    </TableCell> */}
                    <TableCell className={classes.dateHeaderTableCell} style={{ flex: flexMapping[4] }}>
                      <SBText className={classes.tableHeaderText} text={'Status'} />
                    </TableCell>
                    <TableCell className={classes.dateHeaderTableCell} style={{ flex: flexMapping[5] }}>
                      <SBText className={classes.tableHeaderText} text={'Last updated'} />
                    </TableCell>
                    <TableCell className={classes.actionHeaderTableCell} style={{ flex: flexMapping[6] }}>
                      <SBText className={classes.tableHeaderText} text={'Action'} />
                    </TableCell>

                    {isPO_or_VNT && (
                      <TableCell className={classes.actionHeaderTableCell} style={{ flex: flexMapping[6] }}>
                        <SBText className={classes.tableHeaderText} text={'Upload Response'} />
                      </TableCell>
                    )}
                  </TableRow>
                  {applicationMailDetails.length > 0 ? getEvents(applicationMailDetails) : null}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            display: 'flex',
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SBText text={'Select Application to view details'} style={{ color: 'grey', fontSize: 18 }} />
        </div>
      )}

      <MessageDialog open={messageDialog} setOpen={(open: boolean) => showMessageDialog(false)} />

      <SBEmailWithTabsTemplates
        open={model}
        onConfirm={() => okToProceedVerification()}
        onCancel={() => showModel(false)}
        showButton={true}
        value={emailTemplate}
      />

      <SBAutoVerificationTemplate
        open={autoVerificationModel}
        onConfirm={() => console.log('Confirm')}
        onCancel={() => {
          showAutoVerificationModel(false);
          setAutoVerificationModelContent('');
        }}
        onReject={() => console.log('Cancel')}
        showButton={false}
        value={autoVerificationModelContent}
        dataObject={null}
      />

      <VerificationResponseModel
        open={responseModel}
        onCancel={() => showResponseModel(false)}
        onConfirm={setManuallyEvent}
      />
    </Page>
  );
};

const mapState = (state: RootState) => ({
  // i : state.inbox.
  applicationMailList: state.inbox.applicationMailList,
  applicationMailDetails: state.inbox.applicationMailDetails,
  // @ts-ignore
  emailTemplate: state.sbApplication.emailtemplate,
});

const mapDispatch = {
  getApplicationVerificationMails,
  resetApplicationVerificationMailList,
  getVerificationMailDetails,
  getEmailTemplate,
  resetApplicationMailDetails,
  getDeferredEmailTemplate,
  okToProceed,
  viewQuestionFile,
  uploadVerificationEvent,
  okToProceedWithMultipleEmail,
};

const connector = connect(mapState, mapDispatch);
export default connector(Inbox);
