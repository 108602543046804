import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { get, ON_LOADING_FINISHED, ON_LOADING_START } from "../../../actions";
import { API_URL } from "../../../util/apiUrls";
import { LeadAction } from "../store/actions/action-types";

export const useFetchLeadStatusesDropdownsData = () => {
  const dispatch = useDispatch();

  const fetchLeadStatusesDropdownData = useCallback(
    async () => {
      
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(
          API_URL.LEADS.LEAD_STATUSES,
          dispatch
        );
        dispatch({
          type: LeadAction.SET_LEAD_STATUSES,
          payload: result,
        });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    fetchLeadStatusesDropdownData,
  };
};
