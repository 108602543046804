import { get, post, postWithHeader, put } from "./common";
import config from "../config";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducer";
import { ActionType, CourseType } from "../reducer/types";
export const SET_FILE_UPLOAD_STATUS = "SET_FILE_UPLOAD_STATUS";
export const SET_FILE_PROPERTIES = "SET_FILE_PROPERTIES";

interface ProgressEventType {
  loaded: number;
  total: number;
}

export const uploadResult = (
  file: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await postWithHeader(
          `${config.BASE_URL}/students/upload-result`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent: ProgressEventType) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);
              dispatch({
                type: SET_FILE_UPLOAD_STATUS,
                payload: percent,
              });
            },
          }
        );
        // console.log(response);
        resolve(response);
        // dispatch({type: ON_LOADING_FINISHED});
      } catch (error) {
        if (error.response) {
          reject(error.response.data);
        }
        // dispatch({type: ON_LOADING_FINISHED});
      }
    }
  );
};

export const uploadContinuingStudents = (
  file: any
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(
    async (resolve: (x: any) => void, reject: (x: any) => void) => {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await postWithHeader(
          `${config.BASE_URL}/students/upload-continuing`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (progressEvent: ProgressEventType) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);
              dispatch({
                type: SET_FILE_UPLOAD_STATUS,
                payload: percent,
              });
            },
          }
        );
        dispatch({
          type: "FILE_UPLOAD_SUCCESSFULLY",
          payload: response,
        });
        resolve(response);
        // dispatch({type: ON_LOADING_FINISHED});
      } catch (error) {
        if (error.response) {
          reject(error.response.data);
        }
        // dispatch({type: ON_LOADING_FINISHED});
      }
    }
  );
};

export const resetResultUploadProgress = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  dispatch({ type: SET_FILE_UPLOAD_STATUS, payload: 0 });
};

export const setFileSelectionProps = (
  title: string,
  multiselect: boolean
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({ type: SET_FILE_PROPERTIES, payload: { title, multiselect } });
};
