import * as actionTypes from "../actions";
import { ActionType } from "./types";
import _ from "lodash";

const initialState = {
  agencies: undefined,
  entityListDetails: undefined,
  selectedEntityDetails: undefined,
};

const entityMgmntReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ALL_AGENCIES_SUCCESS: {
      return {
        ...state,
        agencies: action.payload?.rows,
      };
    }
    case actionTypes.FETCH_ENTITIES_SUCCESS: {
      return {
        ...state,
        entityListDetails: action.payload,
      };
    }
    case actionTypes.APPEND_ENTITIES: {
      const { count, rows } = state.entityListDetails as any;
      action.payload?.rows?.map((entity: any) => {
        rows.push(entity);
      });
      const newCount = action.payload?.count || count;
      return {
        ...state,
        entityListDetails: { count: newCount, rows },
      };
    }
    case actionTypes.FETCH_ENTITY_SUCCESS: {
      return {
        ...state,
        selectedEntityDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default entityMgmntReducer;
