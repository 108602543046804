import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { concat } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeFileViewer } from '../../actions';
import { RootState } from '../../reducer';
import ImageViewer from './ImageViewer';

const imageTypes = ['png', 'jpeg', 'jpg', 'bmp', 'gif', 'pjpeg', 'jfif', 'svg', 'webp'];
const supportedTypes = concat(imageTypes, 'pdf');

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    padding: 5,
    maxHeight: 700,
    height: 700,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  notSupported: {
    height: 200,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const FileViewer: React.FC = () => {
  const dispatch = useDispatch();
  const fileUrl = useSelector((state: RootState) => state.fileViewerReducer.fileUrl);
  const fileName = useSelector((state: RootState) => state.fileViewerReducer.fileName);
  const type = fileName?.split('.').pop()?.toLowerCase();
  const classes = useStyles();
  const open = !!fileName && !!fileUrl;

  const handleClose = () => {
    dispatch(closeFileViewer());
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose}>
      <AppBar className={classes.appBar} color="primary">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {fileName}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {(!type || !supportedTypes.includes(type)) && (
        <div className={classes.notSupported}>
          <span>This file is not supported for view</span>
        </div>
      )}
      <div style={{ minHeight: 700 }}>
        {type === 'pdf' && (
          <iframe
            style={{ textAlign: 'center', padding: 2, width: '100%', height: 700 }}
            src={'https://s3.eu-west-2.amazonaws.com/viewer.startblocks.com/index.html?zoom=page-width#' + fileUrl}
            allowFullScreen
          />
        )}
        {type && imageTypes.includes(type) && (
          <div className={classes.container}>
            {' '}
            <ImageViewer src={fileUrl!} />
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default FileViewer;
