import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppColor from '../../theme/AppColor';
import SBText from '../../components/base/SBText';
import { colors, List, ListItemAvatar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { HighlightOffRounded } from '@material-ui/icons';
import SBTextField from '../../components/base/SBTextField';
import SBMultiSelectDropdown from '../../components/base/SBMultiSelectDropdown';
import ButtonBase from '@material-ui/core/ButtonBase';
import SBButton from '../../components/base/SBButton';
import clsx from 'clsx';
import SBDropdown from '../../components/base/SBDropdown';
import { ENTITY_TYPE_LIST } from '../../util/constants';
import { LABELS } from '../../util/labels';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1920)]: {
      maxHeight: '60%',
    },
    maxHeight: '80%',
  },
  root: {
    width: '100%',
    paddingLeft: 0,
    overflowY: 'scroll',
    // backgroundColor: theme.palette.background.paper,
  },
  ul: {
    padding: 0,
  },
  companyText: {
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  agentText: {
    fontSize: 11,
    fontWeight: 500,
    color: AppColor.appLightGrey,
    [theme.breakpoints.up(1920)]: {
      fontSize: 12,
    },
  },
  listParent: {
    minHeight: 70,
    width: '100%',
    backgroundColor: AppColor.appBlue,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
  },
  formContainer: {
    padding: 16,
    overflowY: 'auto',
    height: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: AppColor.appLightGrey,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.blueGrey[50],
    },
  },
  selectAgentTxt: {
    fontSize: 16,
    color: AppColor.appWhite,
    fontWeight: 600,
    [theme.breakpoints.up(1920)]: {
      fontSize: 18,
    },
  },
  searchInputTxt: {
    fontSize: 12,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  inputValue: {
    fontSize: '12px !important',
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px !important',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px !important',
    },
  },
  newAppBt: {
    width: 130,
    height: 50,
    borderRadius: 30,
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.up(1920)]: {
      width: 130,
      fontSize: 14,
    },
  },
  closeBtn: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #cac8c8',
    marginLeft: 15,
  },
}));

const SBTextFieldClasses = makeStyles((theme) => ({
  label: {
    textAlign: 'left',
    color: '#000000',
    marginBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px',
    },
    fontSize: '12px',
  },
}));
const SBMultiSelectClasses = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  chip: {
    margin: 3,
    fontSize: 12,
    padding: 1,
    maxWidth: '100%',
  },
  label: {
    textAlign: 'left',
    color: '#000000',
    marginBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px',
    },
    fontSize: '12px',
  },
}));

type RoleModelProps = {
  className?: string;
  open: any;
  onClose: () => void;
  data: any;
  onSave: (obj: any) => void;
  permissions: any[];
  roles: any[];
};

const RoleModel: React.FC<RoleModelProps> = ({
  children,
  onClose,
  className,
  open,
  data,
  onSave,
  permissions,
  roles,
  ...rest
}) => {
  const classes = useStyles();
  const [item, setItem] = useState<any>({});
  const [errors, setErrors] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    if (data) {
      const { id, key, name, description, permissions, entityType } = data;
      const newPermissions = permissions
        ? permissions.map((permission: any) => {
            return {
              id: permission.id,
              value: permission.name,
            };
          })
        : [];
      setItem({
        id,
        key,
        name,
        description,
        permissions: newPermissions,
        entityType,
      });
      setErrors(new Map());
    } else {
      setItem({});
      setErrors(new Map());
    }
  }, [data]);

  const onCloseModel = () => {
    setItem({});
    onClose();
    setErrors(new Map());
  };

  const checkIfRoleNameExist = (): boolean => {
    const foundItem = item.id
      ? roles?.filter((r) => r.id != item.id).find((role) => role.name.trim() === item.name.trim())
      : roles?.find((role) => role.name.trim() === item.name.trim());
    return !!foundItem;
  };

  const checkIfRoleKeyExist = (): boolean => {
    const foundItem = item.id
      ? roles?.filter((r) => r.id != item.id).find((role) => role.key.trim() === item.key.trim())
      : roles?.find((role) => role.key.trim() === item.key.trim());
    return !!foundItem;
  };

  const createRole = () => {
    const { permissions, id, ...rest } = item;
    //validations
    const errors = new Map();
    if (!item.name || !item.name.trim()) {
      errors.set('name', 'Required');
    } else if (checkIfRoleNameExist()) {
      errors.set('name', 'Duplicate');
    }

    if (!item.entityType || !item.entityType.trim()) {
      errors.set('entityType', 'Required');
    }

    if (!item.key || !item.key.trim()) {
      errors.set('key', 'Required');
    } else if (checkIfRoleKeyExist()) {
      errors.set('key', 'Duplicate');
    }

    if (!item.permissions || item.permissions.length === 0) {
      errors.set('permissions', 'Required');
    }
    if (errors.size > 0) {
      setErrors(errors);
      return;
    }
    const newPermissions = permissions?.map((permission: any) => ({ id: permission.id }));
    onSave({ ...data, ...rest, permissions: newPermissions });
  };

  const getPermissionOptions = (): any[] => {
    return permissions.map((permission) => {
      return { id: permission.id, value: permission.name };
    });
  };

  const getSelectedPermissionValues = () => {
    const dropdownValue = item?.permissions?.map((permisssion: any) => {
      return permisssion && permisssion.value;
    });
    return dropdownValue || [];
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.listParent}>
            <SBText text={item.id ? LABELS.UPDATE_ROLE : LABELS.CREATE_ROLE} className={classes.selectAgentTxt} />

            <HighlightOffRounded onClick={onCloseModel} style={{ marginLeft: 10, color: AppColor.appWhite }} />
          </div>
          <div className={classes.formContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter role name"
                  name="roleName"
                  onDataChange={(text) => {
                    setItem({ ...item, name: text });
                  }}
                  labelText="Role Name"
                  className={classes.inputValue}
                  value={item?.['name'] || ''}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('name')}
                  helperText={errors.get('name')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter role code"
                  name="roleCode"
                  onDataChange={(text) => {
                    setItem({ ...item, key: text });
                  }}
                  labelText="Role Code"
                  className={classes.inputValue}
                  value={item?.['key'] || ''}
                  disabled={item}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('key')}
                  helperText={errors.get('key')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter role description"
                  name="roleDescription"
                  onDataChange={(text) => {
                    setItem({ ...item, description: text });
                  }}
                  labelText="Role Description"
                  className={classes.inputValue}
                  value={item?.['description'] || ''}
                  styles={SBTextFieldClasses}
                />
              </Grid>
              <Grid item xs={12}>
                <SBDropdown
                  placeholder={`Select ${LABELS.ENTITY_TYPE}`}
                  options={ENTITY_TYPE_LIST}
                  labelText={LABELS.ENTITY_TYPE}
                  styles={SBMultiSelectClasses}
                  onDataChange={(value: any) => {
                    setItem({ ...item, entityType: value });
                  }}
                  value={item?.entityType || ''}
                  error={errors.get('entityType')}
                  helperText={errors.get('entityType')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBMultiSelectDropdown
                  placeholder="Select permissions"
                  options={getPermissionOptions()}
                  labelText={'Permissions'}
                  userStyles={SBMultiSelectClasses}
                  value={getSelectedPermissionValues()}
                  onDataChange={(values: any) => {
                    setItem({
                      ...item,
                      permissions: values,
                    });
                  }}
                  error={errors.get('permissions')}
                  helperText={errors.get('permissions')}
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonBase className={classes.newAppBt}>
                  <SBButton icon={false} text="Save" className={classes.newAppBt} onClick={createRole} />
                </ButtonBase>
                <ButtonBase className={classes.newAppBt}>
                  <SBButton
                    icon={false}
                    text="Cancel"
                    className={clsx(classes.newAppBt, classes.closeBtn)}
                    onClick={onCloseModel}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default RoleModel;
