import { createSelector } from "reselect";
import { RootState } from "../reducer";
import moment from "moment";
import _ from "lodash";
const notificationList = (state: RootState) => state.application.notifications;

export const getFormattedNotificationList = createSelector(
  notificationList,
  (notificationList) => {
    // console.log("NotificationXXXXXX", notificationList);
    const sortedCommentsNotification: any = [
      { header: "Today", items: [] },
      { header: "Yesterday", items: [] },
      { header: "Older", items: [] },
    ];
    const sortedReminderNotification: any = [
      { header: "Today", items: [] },
      { header: "Yesterday", items: [] },
      { header: "Older", items: [] },
    ];

    const REFERENCE = moment(); // fixed just for testing, use moment();
    const TODAY = REFERENCE.clone().startOf("day");
    const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
    if (notificationList) {
      for (let i = 0; i < notificationList.length; i++) {
        const momentDate = moment(notificationList[i].createdAt);
       

        // console.log("momentDate", momentDate);
        if (momentDate.isSame(TODAY, "d")) {
          notificationList[i].notificationType === "CN"
            ? sortedCommentsNotification[0].items.push(notificationList[i])
            : sortedReminderNotification[0].items.push(notificationList[i]);
        } else if (momentDate.isSame(YESTERDAY, "d")) {
          notificationList[i].notificationType === "CN"
            ? sortedCommentsNotification[1].items.push(notificationList[i])
            : sortedReminderNotification[1].items.push(notificationList[i]);
        } else {
          notificationList[i].notificationType === "CN"
            ? sortedCommentsNotification[2].items.push(notificationList[i])
            : sortedReminderNotification[2].items.push(notificationList[i]);
        }
      }
    }

    //console.log("commentsNotification", sortedCommentsNotification);
    //console.log("reminderNotification", sortedReminderNotification);

    const commentsNotification = [];
    const reminderNotification = [];

    for (let i = 0; i < sortedCommentsNotification.length; i++) {
      if (sortedCommentsNotification[i].items.length > 0) {
        const sortedArray = _.orderBy(
          sortedCommentsNotification[i].items,
          (o: any) => {
            return moment(o.createdAt);
          },
          ["desc"]
        );
        sortedCommentsNotification[i].items = sortedArray;
        commentsNotification.push(sortedCommentsNotification[i]);
      }
      if (sortedReminderNotification[i].items.length > 0) {
        const sortedArray = _.orderBy(
          sortedReminderNotification[i].items,
          (o: any) => {
            return moment(o.createdAt);
          },
          ["desc"]
        );
        sortedReminderNotification[i].items = sortedArray;
        reminderNotification.push(sortedReminderNotification[i]);
      }
    }

    return {
      comments: commentsNotification,
      reminder: reminderNotification,
    };
  }
);
