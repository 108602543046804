import axios from 'axios';
import { includes } from 'lodash';
import config from '../config';
import { getAccessToken } from './util';
import { ON_LOADING_FINISHED } from './sessionActions';
import { PARTIAL_DATA_FILLED_ERROR, SET_ERROR, SHOW_SESSION_TIMEOUT_ERROR } from './appError';

const instance = axios.create({
  baseURL: config.BASE_URL,
  timeout: 1000000,
});

const fileUploadInstance = axios.create({
  baseURL: config.BASE_URL,
  timeout: 100000,
});

const autoVerificationInstance = axios.create({
  baseURL: config.BASE_URL,
  timeout: 1000000,
});

const instanceWithNoAuth = axios.create({
  timeout: 60000,
});

const successStatuses = [200, 201];
const isSuccessful = (responseStatus) => includes(successStatuses, responseStatus);

// Add a request interceptor
instance.interceptors.request.use(
  async (config) => {
    const tokenData = await getAccessToken();
    if (tokenData && !instance.skipToken) {
      config.headers['Authorization'] = `Bearer ${tokenData}`;
    }
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

autoVerificationInstance.interceptors.request.use(
  async (config) => {
    const tokenData = await getAccessToken();
    if (tokenData && !instance.skipToken) {
      config.headers['Authorization'] = `Bearer ${tokenData}`;
    }
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

fileUploadInstance.interceptors.request.use(
  async (config) => {
    const tokenData = await getAccessToken();
    if (tokenData) {
      config.headers['Authorization'] = `Bearer ${tokenData}`;
    }
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
fileUploadInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export const get = (url, config, dispatch) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, config)
      .then((result) => {
        // console.log("Result in get Main ", result);
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        // console.log("Error in get Main ", error);

        if (dispatch) {
          if (error && error.response && error.response.status && error.response.status == 401) {
            // console.log("Session Time Out Error333", error.response);
            dispatch({
              type: SHOW_SESSION_TIMEOUT_ERROR,
            });
          } else {
            dispatch({
              type: SET_ERROR,
              payload: getParsedErrorMessage(error),
            });
          }
          dispatch({ type: ON_LOADING_FINISHED });
        }
        reject(error);
      });
  });
};

export const getWithNoAuth = (url, config, dispatch) => {
  return new Promise((resolve, reject) => {
    console.log('URL ', url);
    instanceWithNoAuth
      .get(url, config)
      .then((result) => {
        console.log('Result in get Main ', result);
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        // console.log("Error in get Main ", error);

        if (dispatch) {
          if (error && error.response && error.response.status && error.response.status == 401) {
            // console.log("Session Time Out Error333", error.response);
            dispatch({
              type: SHOW_SESSION_TIMEOUT_ERROR,
            });
          } else {
            dispatch({
              type: SET_ERROR,
              payload: getParsedErrorMessage(error),
            });
          }
          dispatch({ type: ON_LOADING_FINISHED });
        }
        reject(error);
      });
  });
};

export const post = (url, data, dispatch) => {
  // console.log("data in Post ::" + url, data);
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then((result) => {
        if (isSuccessful(result.status)) {
          resolve(result.data);
        } else {
          //console.log("error in Post111", result.data);
          //handle this error when no content found
          if (result.data && result.data.status === 206 && dispatch) {
            dispatch({
              type: PARTIAL_DATA_FILLED_ERROR,
              payload: result.data.message,
            });
            reject(undefined);
          }
        }
      })
      .catch((error) => {
        console.log('error in Post', error);
        if (dispatch) {
          if (error && error.response && error.response.status && error.response.status == 401) {
            dispatch({
              type: SHOW_SESSION_TIMEOUT_ERROR,
            });
          } else {
            dispatch({
              type: SET_ERROR,
              payload: getParsedErrorMessage(error),
            });
          }

          dispatch({ type: ON_LOADING_FINISHED });
        }
        reject(error);
      });
  });
};

export const autoVerificationPost = (url, data, dispatch) => {
  // console.log("data in Post ::" + url, data);
  return new Promise((resolve, reject) => {
    autoVerificationInstance
      .post(url, data)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          console.log('error in Post111', result.data);
          //handle this error when no content found
          if (result.data && result.data.status === 206 && dispatch) {
            dispatch({
              type: PARTIAL_DATA_FILLED_ERROR,
              payload: result.data.message,
            });
            reject(undefined);
          }
        }
      })
      .catch((error) => {
        console.log('error in Post', error);
        if (dispatch) {
          if (error && error.response && error.response.status && error.response.status == 401) {
            dispatch({
              type: SHOW_SESSION_TIMEOUT_ERROR,
            });
          } else {
            dispatch({
              type: SET_ERROR,
              payload: getParsedErrorMessage(error),
            });
          }

          dispatch({ type: ON_LOADING_FINISHED });
        }
        reject(error);
      });
  });
};

const getParsedErrorMessage = (error) => {
  let errorMessage = '';
  if (error.response) {
    console.log('Error error.response::::', error.response.data && error.response.data[0]);
    const responseData = error.response && error.response.data ? error.response.data : [];
    if (responseData && Array.isArray(responseData)) {
    } else {
      if (responseData.message) {
        if (!Array.isArray(responseData.message)) {
          errorMessage = responseData.message;
        }
      } else {
        if (error.response.config.responseType == 'blob') {
          errorMessage = 'File not found.';
        } else {
          errorMessage = 'Something went wrong, Please try again later';
        }
      }
    }
  } else if (error.request) {
    if (error.code && error.code === 'ECONNABORTED') {
      errorMessage = 'The request has timed out, Please try again';
    }
  } else {
    console.log('Error error.message::::', error.message);
  }

  // if (responseData)
  if (!errorMessage) {
    errorMessage = 'Something went wrong, Please try again later';
  }

  return errorMessage;
};

export const postWithoutHeader = (url, data, dispatch) => {
  return new Promise((resolve, reject) => {
    instanceWithNoAuth
      .post(url, data)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        if (dispatch) {
          dispatch({ type: SET_ERROR, payload: getParsedErrorMessage(error) });
          dispatch({ type: ON_LOADING_FINISHED });
        }
        // console.log("Error::::", JSON.stringify(error));

        reject(error);
      });
  });
};
export const postWithHeader = (url, data, header, dispatch) => {
  return new Promise((resolve, reject) => {
    fileUploadInstance
      .post(url, data, header)
      .then((result) => {
        if (result.status === 200 || result.status === 201) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        if (dispatch) {
          if (error && error.response && error.response.status && error.response.status == 401) {
            // dispatch({ type: ON_LOADING_FINISHED });
            dispatch({
              type: SHOW_SESSION_TIMEOUT_ERROR,
            });
          } else {
            if (!url.includes('submitFileAnswer')) {
              dispatch({
                type: SET_ERROR,
                payload: getParsedErrorMessage(error),
              });
            }
          }
        }

        reject(error);
      });
  });
};

export const put = (url, data, dispatch) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        if (dispatch) {
          if (error && error.response && error.response.status && error.response.status == 401) {
            dispatch({
              type: SHOW_SESSION_TIMEOUT_ERROR,
            });
          } else {
            dispatch({
              type: SET_ERROR,
              payload: getParsedErrorMessage(error),
            });
          }
          dispatch({ type: ON_LOADING_FINISHED });
        }
        reject(error);
      });
  });
};

export const putWithHeader = (url, data, header) => {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data, header)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const remove = (url, data, dispatch) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        data,
      })
      .then((result) => {
        if (result.status === 200) {
          //console.log("Remove File In main :", result);
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        if (dispatch) {
          if (error && error.response && error.response.status && error.response.status == 401) {
            dispatch({
              type: SHOW_SESSION_TIMEOUT_ERROR,
            });
          } else {
            dispatch({
              type: SET_ERROR,
              payload: getParsedErrorMessage(error),
            });
          }
        }
        reject(error);
      });
  });
};

export const patch = (url, data) => {
  return new Promise((resolve, reject) => {
    instance
      .patch(url, data)
      .then((result) => {
        if (result.status === 200) {
          resolve(result.data);
        } else {
          reject(result.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadFileS3 = (url, file, options, dispatch, progressCallback) => {
  return new Promise((resolve, reject) => {
    console.log('File Upload Start ***::::', '**************' + url);
    const config = {
      onUploadProgress: (progressEvent) => {
        if (progressCallback) {
          const { loaded, total } = progressEvent;
          const value = (loaded * 100) / total;
          let precentage = Math.floor(value);
          // if (precentage < 100) {
          // console.log('** P', precentage);
          progressCallback(precentage);
          // }
        }
      },
    };

    instanceWithNoAuth
      .put(url, file, config)
      .then((result) => {
        console.log('File Upload Result ', result);
        resolve(result);
      })
      .catch((error) => {
        console.log('Error', error);
        if (dispatch) {
          dispatch({ type: SET_ERROR, payload: getParsedErrorMessage(error) });
          dispatch({ type: ON_LOADING_FINISHED });
        }
        reject(error);
      });
  });
};
