import React, { useState, useEffect, useRef } from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, TextField, TablePagination } from "@material-ui/core";
import { RootState } from "../../reducer";
import { connect, ConnectedProps } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { getProviderList } from "../../actions";
import SBText from "../../components/base/SBText";
import ProviderTable from "./ProviderTable";
import { LABELS } from "../../util/labels";
import { listPageSearchParam } from "../../reducer/types";
import { DEFAULT_FETCH_PARAMS, PAGE_SIZES } from "./constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#F1F1F1",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
    },
    newAppBt: {
      width: 130,
      height: 50,
      borderRadius: 30,
      textTransform: "none",
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        width: 130,
        fontSize: 14,
      },
    },
    tabBasic: {
      height: 40,
      width: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    tabSelected: {
      height: 50,
      width: 120,
      backgroundColor: "white",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    cardContent: {
      display: "flex",
      minHeight: "70vh",
      flexDirection: "column",
      padding: 0,
    },
    nameCircle: {
      height: 78,
      width: 78,
      borderRadius: 40,
      backgroundColor: "#727487",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    circleText: { color: "white", fontWeight: "bold" },
    name: { color: "black", fontWeight: "bold" },
    appId: { color: "#898989", marginTop: 10 },
    headerTextParent: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 35,
    },
    cardHeader: {
      flex: 0.1,
      width: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: 22,
    },
    cardStatus: {
      flex: 0.6,
      width: "100%",
      display: "flex",
      backgroundColor: "pink",
      flexDirection: "row",
    },
    customWidth: {
      "& div": {
        // this is just an example, you can use vw, etc.
        width: "200px",
        fontFamily: "Poppins",
        borderRadius: 0,
      },
    },
    input: {
      height: 40,
      border: "none",
      backgroundColor: "white",
      marginLeft: 10,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      [theme.breakpoints.up(1920)]: {
        height: 50,
      },
    },
    tabsParent: {
      height: 60,
      display: "flex",
      marginTop: 20,
      justifyContent: "space-between",
    },
    listContainer: {},
    searchSortParents: {
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.up(1920)]: {
        height: 60,
        alignItems: "unset",
      },
    },
    tabSubParents: {
      display: "flex",
      alignItems: "flex-end",
    },
    searchbox: {
      border: "none",
      "& .MuiOutlinedInput-root": {
        position: "unset",
      },
      "& .MuiInputBase-input": {
        color: "#000000",
        fontFamily: "Poppins",
        fontWeight: 400,
        letterSpacing: "0.75px",
        textTransform: "none",
        fontSize: 14,
      },
      "& fieldset": {
        border: "unset",
      },
    },
    inherited: {
      "& .MuiFormControl-root": {
        position: "unset",
      },
      height: "fit-content",
    },
    tabDivider: { width: 10 },
  })
);
type PropsFromRedux = ConnectedProps<typeof connector>;
type SBApplicationListProps = PropsFromRedux & {};

const ProviderList: React.FC<SBApplicationListProps> = ({
  providerTableData,
  getProviderList,
  ...rest
}) => {
  const classes = useStyles();
  const searchRef = useRef();

  const [fetchParams, setFetchParams] = useState<listPageSearchParam>(
    DEFAULT_FETCH_PARAMS
  );

  useEffect(() => {
    fetchData();
  }, [fetchParams]);

  const fetchData = async () => {
    await getProviderList({
      offset: fetchParams.offset,
      limit: fetchParams.limit,
      searchText: fetchParams.searchText,
    });
  };

  const onSearchClick = (event: any) => {
    const searchInput: any = searchRef?.current;
    setFetchParams({
      ...fetchParams,
      searchText: searchInput?.value,
    });
  };

  const handleChangePage = (newPage: number) => {
    setFetchParams({
      ...fetchParams,
      offset: newPage * providerTableData.pageSize,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pageSize: number = parseInt(event.target.value, 10);
    setFetchParams({
      ...fetchParams,
      limit: pageSize,
      offset: 0,
    });
  };

  const onProviderClick = (provider: any) => {};
  // @ts-ignore
  return (
    <div className={classes.root}>
      <div className={classes.tabsParent}>
        {/* tabs for user,role, agencies */}
        {rest.children}

        <div className={classes.searchSortParents}>
          <div className={classes.inherited}>
            <TextField
              variant="outlined"
              placeholder={LABELS.SEARCH_TEXT}
              className={classes.searchbox}
              inputRef={searchRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={onSearchClick}>
                    <SearchIcon />
                  </InputAdornment>
                ),
                className: classes.input,
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.listContainer}>
        {providerTableData?.totalRecords === 0 ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <AnnouncementIcon
              style={{ fill: "#8F928E", height: 150, width: 150, opacity: 0.6 }}
            />
            <SBText
              text={LABELS.NO_PROVIDERS}
              style={{
                fontSize: 20,
                fontFamily: "Poppins",
                fontWeight: "600",
                color: "#8F928E",
              }}
            />
          </div>
        ) : (
          <>
            <ProviderTable
              data={providerTableData?.data || []}
              onItemClick={onProviderClick}
            />
            <TablePagination
              rowsPerPageOptions={PAGE_SIZES}
              component="div"
              count={providerTableData.totalRecords}
              rowsPerPage={providerTableData.pageSize}
              page={providerTableData.currentPage}
              onPageChange={(e, newPage) => {
                handleChangePage(newPage);
              }}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  providerTableData: state.providers.tableData,
});

const mapDispatch = {
  getProviderList,
};

const connector = connect(mapState, mapDispatch);
export default connector(ProviderList);
