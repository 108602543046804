import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme, Button, Snackbar, Link } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Page from '../../components/Page';
import facebook from './assets/facebook.png';
import google from './assets/google.png';
import { RootState } from '../../reducer';
import { connect, ConnectedProps } from 'react-redux';
import { validateUserByUDID, changePasswordByUDID, setForgotPasswordView, setLogout } from '../../actions';
import { useHistory, useParams } from 'react-router';
import SBButton from '../../components/base/SBButton';
import SBTextField from '../../components/base/SBTextField';
import SBText from '../../components/base/SBText';
import SBLinkButton from '../../components/base/SBLinkButton';
import AppColor from '../../theme/AppColor';
import CommenMessageDialog from '../../components/CommenMessageDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    formStyle: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    leftPanel: {
      width: '37%',
      backgroundColor: theme.palette.primary.dark,
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '1%',
      },
    },
    rightPanel: {
      width: '63%',
      backgroundColor: theme.palette.primary.contrastText,
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    card: {
      width: '100%',
      height: '100%',
      overflow: 'visible',
      display: 'flex',
      position: 'relative',
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%',
        width: '50%',
      },
    },
    content: {
      position: 'relative',
    },
    loginBox: {
      padding: theme.spacing(3),
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        width: 400,
      },
      margin: '0 auto',
    },
    media: {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      color: theme.palette.common.white,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    overlay: {
      color: 'white',
      margin: 'auto',
    },
    loginForm: {},
    avatar: {
      margin: '0 auto',
      width: 200,
      height: 58,
      textAlign: 'center',
      borderRadius: 0,
    },
    centerText: {
      color: '#FFFFFF',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2,
      width: '70%',
      padding: 20,
      textAlign: 'center',
      lineHeight: 1.5,
    },
    bottomText: {
      color: '#FFFFFF',
      fontWeight: 500,
      position: 'absolute',
      bottom: 20,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2,
      width: '100%',
      textAlign: 'center',
      lineHeight: 1.5,
    },
    bottomLinkText: {
      color: '#FFFFFF',
      fontWeight: 500,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 2,
      width: '100%',
      textAlign: 'center',
      lineHeight: 1.3,
    },
    link: {
      textDecoration: 'underline',
      padding: theme.spacing(0.5),
      color: '#FFFFFF',
    },
    divider: {
      margin: '0 auto',
      paddingTop: 10,
    },
    input: {
      marginTop: theme.spacing(3),
    },
    subParent: {
      width: '35%',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    imgParent: {
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      marginBottom: 10,
    },
    socialImage: {
      height: 16,
      marginRight: 10,
    },
    loginButton: {
      borderRadius: 30,
      fontSize: 14,
      height: 50,
      width: '100%',
      marginTop: theme.spacing(3),
    },
    header: {
      color: theme.palette.common.black,
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    errorMessage: {
      color: AppColor.appDarkGrey,
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'center',
      marginBottom: theme.spacing(2),
    },
    signupText: {
      color: '#9EA0A5',
    },
    linkButton: {
      color: '#2463FF',
      fontSize: 14,
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    forgetPasswordBtParent: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 8,
    },
    socialSignInLabel: {
      color: '#9EA0A5',
    },
    signupParents: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
    },
  })
);

const SocialButton = withStyles((theme: Theme) => ({
  root: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    borderRadius: 30,
    fontSize: 12,
    height: 50,
    width: '100%',
    marginTop: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))(Button);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ChangePasswordProps = PropsFromRedux & { setPassword?: boolean };
interface RouteParams {
  id: string;
}
const ChangePassword: React.FC<ChangePasswordProps> = ({
  loginError,
  setPassword,
  validateUserByUDID,
  changePasswordByUDID,
  setForgotPasswordView,
  setLogout,
  ...rest
}) => {
  let LABELS: any;
  if (setPassword) {
    LABELS = {
      PAGE_TITLE: 'Set Password',
      PAGE_HEADER: 'SET PASSWORD',
      PASSWORD_PLACEHOLDER: 'Enter Password',
      PASSWORD_ERROR: 'Please enter password',
    };
  } else {
    LABELS = {
      PAGE_TITLE: 'Change Password',
      PAGE_HEADER: 'CHANGE PASSWORD',
      PASSWORD_PLACEHOLDER: 'New Password',
      PASSWORD_ERROR: 'Please enter new password',
    };
  }
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const classes = useStyles();

  const [userDetails, setUserDetails] = React.useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [displayErrorView, setDisplayErrorView] = React.useState(false);
  const [isUserValidate, setUserValidated] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [userEmail, setUserEmail] = React.useState(null);
  const [isLoading, setLoading] = React.useState(true);
  const [messageDialog, showMessageDialog] = React.useState(false);

  // console.log("error Message", loginError);

  useEffect(() => {
    if (id) {
      validateUser();
    }
  }, []);

  const validateUser = async () => {
    const result: any = await validateUserByUDID(id);
    setUserValidated(true);
    if (result.error) {
      setUserEmail(null);
      setLoading(false);
      setUserValidated(true);
      setErrorMessage(result.error.message);
    } else {
      setUserEmail(result.success.message);
      setLoading(false);
      setUserValidated(true);
      setLogout();
    }
    // console.log("Result in Validate User", result);
  };

  const handleChange = (event: any) => {
    // event.persist();
    const value = event.target.value;
    const name = event.target.name;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const changePassword = async () => {
    if (!userDetails.newPassword) {
      alert(LABELS.PASSWORD_ERROR);
      return;
    }
    if (!userDetails.confirmPassword) {
      alert('Please enter confirm password');
      return;
    }

    if (userDetails.confirmPassword != userDetails.newPassword) {
      alert('New Password and Confirm password should same');
      return;
    }

    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    const found = regex.test(userDetails.newPassword);

    if (!found) {
      alert(
        'Password should be minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
      );
      return;
    }

    const result: any = await changePasswordByUDID(id, userDetails.newPassword);
    // console.log("Result >>>>>>>", result);

    if (result.success) {
      showMessageDialog(true);
    }
  };
  const handleClose = () => {
    setDisplayErrorView(false);
    // resetLoginError();

    //console.log("Close");
  };

  useEffect(() => {
    if (loginError) {
      setDisplayErrorView(true);
    }
  }, [loginError]);

  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  if (isLoading) {
    return (
      <div>
        <SBText text={'Loading ...'} className={classes.header} />
      </div>
    );
  } else {
    return (
      <Page className={classes.root} title={LABELS.PAGE_TITLE}>
        <div className={classes.leftPanel} />
        <div className={classes.rightPanel}>
          <div className={classes.subParent}>
            <div className={classes.imgParent}>
              <img
                src="/images/logo_large.png"
                style={{
                  height: 135,
                }}
              />
            </div>

            {!isUserValidate ? <SBText text={'Loading'} className={classes.header} /> : null}

            {isUserValidate && userEmail ? (
              <>
                <SBText text={LABELS.PAGE_HEADER} className={classes.header} />
                <SBTextField
                  placeholder={LABELS.PASSWORD_PLACEHOLDER}
                  type="password"
                  onChange={handleChange}
                  value={userDetails.newPassword}
                  name="newPassword"
                  onDataChange={() => console.log()}
                />
                <SBTextField
                  placeholder={'Confirm Password'}
                  type="password"
                  name="confirmPassword"
                  className={classes.input}
                  value={userDetails.confirmPassword}
                  onChange={handleChange}
                  onDataChange={() => console.log()}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      changePassword();
                      // write your functionality here
                    }
                  }}
                />
                <SBButton
                  text={'Submit'}
                  icon={false}
                  onClick={() => changePassword()}
                  className={classes.loginButton}
                />
              </>
            ) : (
              <>
                <SBText text={errorMessage} className={classes.errorMessage} />
                <SBButton
                  text={'Forgot Password'}
                  icon={false}
                  onClick={() => {
                    setForgotPasswordView(true);
                    history.replace('/login');
                  }}
                  className={classes.loginButton}
                />

                <div
                  style={{
                    width: '100#',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: 60,
                  }}
                >
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setForgotPasswordView(false);
                      history.replace('/login');
                    }}
                    style={{
                      alignSelf: 'center',
                      fontSize: 14,
                      marginTop: 5,
                      color: AppColor.appBlue,
                      textDecorationLine: 'underline',
                      display: 'flex',
                    }}
                  >
                    Back To Login
                  </Link>
                </div>
              </>
            )}
            <SocialButton onClick={() => console.log('')} style={{ visibility: 'hidden' }}>
              <img src={facebook} className={classes.socialImage} />
              LOGIN WITH FACEBOOK
            </SocialButton>
            <SocialButton onClick={() => console.log('')} style={{ visibility: 'hidden' }}>
              <img src={google} className={classes.socialImage} />
              LOGIN WITH GOOGLE
            </SocialButton>
          </div>
          <Snackbar
            open={displayErrorView}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              {loginError}
            </Alert>
          </Snackbar>
        </div>

        <CommenMessageDialog
          open={messageDialog}
          onSubmit={() => {
            showMessageDialog(false);
            history.push('/login');
          }}
          message={'Your password has been changed successfully.'}
          header={'Message'}
        />
      </Page>
    );
  }
};
const mapState = (state: RootState) => ({
  loginError: state.user.loginError,
});
const mapDispatch = {
  validateUserByUDID,
  changePasswordByUDID,
  setForgotPasswordView,
  setLogout,
};

const connector = connect(mapState, mapDispatch);
export default connector(ChangePassword);
