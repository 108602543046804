import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppColor from '../../theme/AppColor';
import SBText from '../../components/base/SBText';
import { colors, List } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { HighlightOffRounded } from '@material-ui/icons';
import SBTextField from '../../components/base/SBTextField';
import SBDropdown from '../../components/base/SBDropdown';
import ButtonBase from '@material-ui/core/ButtonBase';
import SBButton from '../../components/base/SBButton';
import clsx from 'clsx';
import CountrySelect from '../../components/CountrySelect';
import { ENTITY_TYPE, ENTITY_TYPE_LIST } from '../../util/constants';
import { LABELS } from '../../util/labels';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(1920)]: {
      maxHeight: '60%',
    },
    maxHeight: '80%',
  },
  root: {
    width: '100%',
    paddingLeft: 0,
    overflowY: 'scroll',
    // backgroundColor: theme.palette.background.paper,
  },
  ul: {
    padding: 0,
  },
  companyText: {
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  agentText: {
    fontSize: 11,
    fontWeight: 500,
    color: AppColor.appLightGrey,
    [theme.breakpoints.up(1920)]: {
      fontSize: 12,
    },
  },
  listParent: {
    minHeight: 70,
    width: '100%',
    backgroundColor: AppColor.appBlue,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
  },
  formContainer: {
    padding: 16,
    overflowY: 'auto',
    height: '100%',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: AppColor.appLightGrey,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
    '&:hover': {
      backgroundColor: colors.blueGrey[50],
    },
  },
  selectAgentTxt: {
    fontSize: 16,
    color: AppColor.appWhite,
    fontWeight: 600,
    [theme.breakpoints.up(1920)]: {
      fontSize: 18,
    },
  },
  searchInputTxt: {
    fontSize: 12,
    [theme.breakpoints.up(1920)]: {
      fontSize: 14,
    },
  },
  inputValue: {
    fontSize: '12px !important',
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px !important',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px !important',
    },
  },
  newAppBt: {
    width: 130,
    height: 50,
    borderRadius: 30,
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.up(1920)]: {
      width: 130,
      fontSize: 14,
    },
  },
  closeBtn: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #cac8c8',
    marginLeft: 15,
  },
  label: {
    textAlign: 'left',
    color: '#000000',
    marginBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px',
    },
    fontSize: '12px',
  },
}));

type EntityModelProps = {
  className?: string;
  entities: any[];
  open: any;
  onClose: () => void;
  data: any;
  onSave: (obj: any) => void;
};

const SBTextFieldClasses = makeStyles((theme) => ({
  label: {
    textAlign: 'left',
    color: '#000000',
    marginBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px',
    },
    fontSize: '12px',
  },
  muiAutocompleteInputRoot: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    paddingLeft: '0px !important',
  },
}));
const SBMultiSelectClasses = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  chip: {
    margin: 3,
    fontSize: 12,
    padding: 1,
    maxWidth: '100%',
  },
  label: {
    textAlign: 'left',
    color: '#000000',
    marginBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.up(1366)]: {
      fontSize: '13px',
    },
    [theme.breakpoints.up(1440)]: {
      fontSize: '14px',
    },
    fontSize: '12px',
  },
}));

const EntityModel: React.FC<EntityModelProps> = ({
  children,
  onClose,
  entities,
  className,
  open,
  data,
  onSave,
  ...rest
}) => {
  const classes = useStyles();
  const [item, setItem] = useState<any>({});
  const [errors, setErrors] = useState<Map<string, string>>(new Map());

  useEffect(() => {
    if (data) {
      const {
        id,
        companyName,
        type,
        country,
        companyAddress,
        pincode,
        firstName,
        lastName,
        contact1,
        email,
        parentCompanyId,
      } = data;
      setItem({
        id,
        companyName,
        type,
        country,
        companyAddress,
        pincode,
        firstName,
        lastName,
        contact1,
        email,
        parentCompanyId,
      });
    } else {
      setItem({});
    }
    setErrors(new Map());
  }, [data]);

  const onCloseModel = () => {
    setItem({});
    onClose();
    setErrors(new Map());
  };

  const getParentEntities = (): any[] => {
    let parentEntities = entities.map((entity: any) => {
      return { id: entity.id, value: entity.companyName };
    });
    if (item.id) {
      parentEntities = parentEntities.filter((entity: any) => entity.id != item.id);
    }
    return parentEntities;
  };

  const createEntity = () => {
    const { permissions, id, ...rest } = item;
    //validations
    const errors = new Map();
    if (!item.companyName || !item.companyName.trim()) {
      errors.set('companyName', 'Required');
    }

    if (!item.country || !item.country.trim()) {
      errors.set('country', 'Required');
    }

    if (!item.companyAddress || !item.companyAddress.trim()) {
      errors.set('companyAddress', 'Required');
    }

    if (!item.pincode || !item.pincode.trim()) {
      errors.set('pincode', 'Required');
    }

    if (!item.firstName || !item.firstName.trim()) {
      errors.set('firstName', 'Required');
    }

    if (!item.lastName || !item.lastName.trim()) {
      errors.set('lastName', 'Required');
    }

    if (!item.contact1 || !item.contact1.trim()) {
      errors.set('contact1', 'Required');
    }

    if (!item.email || !item.email.trim()) {
      errors.set('email', 'email');
    }

    if (errors.size > 0) {
      setErrors(errors);
      return;
    }

    onSave({
      ...data,
      ...rest,
      type: ENTITY_TYPE.AGENCY,
      name: `${item.firstName} ${item.lastName}`,
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.listParent}>
            <SBText text={item.id ? LABELS.UPDATE_AGENCY : LABELS.CREATE_AGENCY} className={classes.selectAgentTxt} />

            <HighlightOffRounded onClick={() => onClose()} style={{ marginLeft: 10, color: AppColor.appWhite }} />
          </div>
          <div className={classes.formContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SBTextField
                  placeholder={`Enter ${LABELS.AGENCY_NAME}`}
                  name="companyName"
                  onDataChange={(text) => {
                    setItem({ ...item, companyName: text });
                  }}
                  value={item?.['companyName'] || ''}
                  labelText={LABELS.AGENCY_NAME}
                  className={classes.inputValue}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('companyName')}
                  helperText={errors.get('companyName')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBDropdown
                  placeholder={`Select ${LABELS.PARENT_AGENCY}`}
                  options={getParentEntities()}
                  labelText={LABELS.PARENT_AGENCY}
                  styles={SBMultiSelectClasses}
                  onDataChange={(text: any) => {
                    setItem({ ...item, parentCompanyId: text });
                  }}
                  value={item?.parentCompanyId || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <CountrySelect
                  placeholder="Select Country"
                  labelText={'Country'}
                  styles={SBTextFieldClasses}
                  defaultValue={item?.['country']?.toLowerCase() || ''}
                  onChange={(text: any) => {
                    setItem({ ...item, country: text });
                  }}
                  error={errors.get('country')}
                  helperText={errors.get('country')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter Address"
                  name="companyAddress"
                  labelText="Address"
                  className={classes.inputValue}
                  value={item?.['companyAddress'] || ''}
                  onDataChange={(text: any) => {
                    setItem({ ...item, companyAddress: text });
                  }}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('companyAddress')}
                  helperText={errors.get('companyAddress')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Pin/Zip code"
                  name="pincode"
                  onDataChange={(text) => {
                    setItem({ ...item, pincode: text });
                  }}
                  value={item?.['pincode'] || ''}
                  labelText="Pin/Zip Code"
                  className={classes.inputValue}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('pincode')}
                  helperText={errors.get('pincode')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBText text="Contact Information" className={classes.label} color={'#736F6F'} />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter first name"
                  name="firstName"
                  labelText="First Name"
                  className={classes.inputValue}
                  value={item?.['firstName'] || ''}
                  onDataChange={(text: any) => {
                    setItem({ ...item, firstName: text });
                  }}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('firstName')}
                  helperText={errors.get('firstName')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter last name"
                  name="lastName"
                  labelText="Last Name"
                  className={classes.inputValue}
                  value={item?.['lastName'] || ''}
                  onDataChange={(text: any) => {
                    setItem({ ...item, lastName: text });
                  }}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('lastName')}
                  helperText={errors.get('lastName')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter email"
                  name="email"
                  disabled={item.id ? true : false}
                  labelText="Email"
                  className={classes.inputValue}
                  value={item?.['email'] || ''}
                  onDataChange={(text: any) => {
                    setItem({ ...item, email: text });
                  }}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('email')}
                  helperText={errors.get('email')}
                />
              </Grid>
              <Grid item xs={12}>
                <SBTextField
                  placeholder="Enter contact"
                  name="contact1"
                  questionObj={{
                    validation: 'Phone',
                  }}
                  labelText="Contact"
                  className={classes.inputValue}
                  value={item?.['contact1'] || ''}
                  onDataChange={(text: any) => {
                    setItem({ ...item, contact1: text });
                  }}
                  styles={SBTextFieldClasses}
                  error={!!errors.get('contact1')}
                  helperText={errors.get('contact1')}
                />
              </Grid>
              <Grid item xs={12}>
                <ButtonBase className={classes.newAppBt}>
                  <SBButton icon={false} text="Save" className={classes.newAppBt} onClick={createEntity} />
                </ButtonBase>
                <ButtonBase className={classes.newAppBt}>
                  <SBButton
                    icon={false}
                    text="Cancel"
                    className={clsx(classes.newAppBt, classes.closeBtn)}
                    onClick={onCloseModel}
                  />
                </ButtonBase>
              </Grid>
            </Grid>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EntityModel;
