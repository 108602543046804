import { connect, ConnectedProps } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../../reducer';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme, Divider, colors, TextField } from '@material-ui/core';
import SortBy from '../../SBApplicationList/SortBy';
import clsx from 'clsx';
import SBText from '../../../components/base/SBText';
import _ from 'lodash';
import { getApplicationIdInString } from '../../../util';
import SearchIcon from '@material-ui/icons/Search';
import { object } from 'prop-types';
import { SBTooltip } from '../../../components/base/SBTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 260,
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
      borderRadius: 3,
      overflow: 'hidden',
      height: 'calc(100% - 60px)',
    },
    sort: {
      width: 260,
      borderWidth: 0,
      borderRadius: 0,
    },
    listItem: {
      '&:hover': {
        backgroundColor: '#F7F7F7',
      },
      width: '100%',
    },
    listItemcontainer: {
      height: 78,
      width: '100%',
      padding: 16,
      '&:hover': {
        backgroundColor: '#F7F7F7',
      },
      display: 'flex',
      alignItems: 'center',
      paddingRight: '10px',
    },
    listItemSelected: {
      height: 78,
      width: '100%',
      padding: 16,
      backgroundColor: '#EFEFEF',
      display: 'flex',
      alignItems: 'center',
      paddingRight: '10px',
    },
    studentNameText: {
      color: '#8F928E',
      marginTop: 5,
      fontSize: 12,
    },
    applicationID: {
      fontSize: 14,
      fontWeight: 500,
      [theme.breakpoints.up(1920)]: {
        fontSize: 15,
      },
    },
    listParent: {
      height: 'calc(100% - 50px )',
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'column',
    },
    fileCount: {
      backgroundColor: '#f07357',
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 20,
      width: 30,
    },
    applicationFilesText: {
      fontSize: 10,
      [theme.breakpoints.up(1440)]: {
        fontSize: 12,
      },
      [theme.breakpoints.up(1920)]: {
        fontSize: 12,
      },
    },
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;
type ApplicationMailListProps = PropsFromRedux & {
  className?: string;
  onListItemClick: (obj: any, data: any) => void;
  list: any;
  onSearchText: (text: string) => void;
  onSortItemClick: (text: string) => void;
  sortValue: string;
  selectedItem: any;
  selectedApplication: string;
};
const ApplicationMailList: React.FC<ApplicationMailListProps> = ({
  onListItemClick,
  list,
  className,
  onSearchText,
  onSortItemClick,
  sortValue,
  selectedItem,
  selectedApplication,
}) => {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [searchText, setSearchText] = useState<string>('');
  console.log('Mail List', list);
  console.log('selectedApplication', selectedApplication);

  useEffect(() => {
    if (selectedApplication && list) {
      const index = list.map((app: any) => app.applicationId).indexOf(+selectedApplication);
      if (index > -1) {
        onItemClick(list[index], index);
        document?.getElementById(`app-${selectedApplication}`)?.scrollIntoView();
      }
    }

    if (selectedApplication != '0') {
      setSearchText(selectedApplication ? getApplicationIdInString(selectedApplication) : selectedApplication);
    }
  }, [list, selectedApplication]);

  useEffect(() => {
    if (selectedItem) {
      const index = _.findIndex(list, (object: any) => {
        return object.applicationId == selectedItem.applicationId;
      });

      setSelectedIndex(index);
    }
  }, [selectedItem]);

  const onItemClick = (object: any, index: number) => {
    // setSelectedIndex(index);
    onListItemClick(object, index);
  };

  const getStudentName = (object: any) => {
    const firstName = _.find(object.application.keyAnswers, (obj: any) => obj.key == 'Q_FIRSTNAME');
    const lastName = _.find(object.application.keyAnswers, (obj: any) => obj.key == 'Q_LASTNAME');
    if (firstName && lastName) {
      let name = '';
      if (firstName.value) {
        name = firstName.value;
      }
      if (lastName.value) {
        if (name) {
          name = name + ' ' + lastName.value;
        } else {
          name = lastName.value;
        }
      }
      return name;
    } else {
      const studentNameObj = _.find(object.application.keyAnswers, (obj: any) => obj.key == 'Q_NAME');
      return studentNameObj.value;
    }
  };

  

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: 'white',
          alignItems: 'center',
          paddingLeft: 10,
          paddingRight: 5,
          height: 50,
          marginBottom: 10,
        }}
      >
        <SearchIcon />

        <TextField
          id="standard-basic"
          fullWidth={true}
          InputProps={{ disableUnderline: true }}
          placeholder={'Search Application'}
          style={{ marginLeft: 10 }}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          onKeyPress={(ev: any) => {
            if (ev.key === 'Enter') {
              onSearchText(searchText);
              ev.preventDefault();
            }
          }}
        />
      </div>

      <div className={clsx(classes.root, className)}>
        <SortBy
          className={classes.sort}
          dropDownWidth={260}
          onItemClick={(text) => onSortItemClick(text)}
          value={sortValue}
        />
        <Divider />

        <div className={classes.listParent}>
          {list &&
            list.map((object: any, index: number) => (
              <>
                <div
                  key={object.applicationId}
                  id={`app-${object.applicationId}`}
                  className={selectedIndex === index ? classes.listItemSelected : classes.listItemcontainer}
                >
                  <div className={classes.listItem} onClick={() => onItemClick(object, index)}>
                    <SBText text={getApplicationIdInString(object.applicationId)} className={classes.applicationID} />
                    <SBText text={getStudentName(object)} className={classes.studentNameText} />
                  </div>
                  {object.pendingVerifications && object.pendingVerifications > 0 ? (
                    <SBTooltip title={   object.pendingVerifications > 0 ?  object.pendingVerifications+` Verifications Pending` : `No verifications pending`}>
                      <span className={classes.fileCount}>
                        <SBText
                          text={
                            object.pendingVerifications > 9
                              ? object.pendingVerifications > 99
                                ? '99+'
                                : object.pendingVerifications
                              : '0' + object.pendingVerifications
                          }
                          className={classes.applicationFilesText}
                        />
                      </span>
                    </SBTooltip>
                  ) : null}
                </div>
                <Divider />
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(ApplicationMailList);
