import { ActionType } from "./types";
import * as actionTypes from "../actions";

const initialState = {
  fileLoadPercentage: 0,
  title: "Select files",
  multiselect: false,
};

const bulkUpload = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.SET_FILE_UPLOAD_STATUS: {
      return {
        ...state,
        fileLoadPercentage: action.payload,
      };
    }
    case actionTypes.SET_FILE_PROPERTIES: {
      return {
        ...state,
        title: action.payload.title,
        multiselect: action.payload.multiselect,
      };
    }
    default: {
      return state;
    }
  }
};

export default bulkUpload;
