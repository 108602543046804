import React from "react";
import { Route } from "react-router-dom";
import { useUserPermissions } from "../../hooks/useUserPermissions";
import { LABELS } from "../../util/labels";
import { hasPermission } from "../../util/rolePermissionsUtil";

const GuardedRoute = ({ component: Component, permission, ...rest }: any) => {
  const userPermissions = useUserPermissions();
  const allowedToAccess = hasPermission(permission, userPermissions);
  return (
    <Route
      {...rest}
      render={(props) =>
        allowedToAccess ? (
          <Component {...props} />
        ) : (
          <div style={{ padding: 50, textAlign: "center" }}>
            {LABELS.UNAUTHORIZED_PAGE}
          </div>
        )
      }
    />
  );
};

export default GuardedRoute;
