import { FetchLeadsParams, FetchTlLeadsParams, LeadModalAPIStatus } from "../types";

export const PAGE_SIZES: number[] = [10, 20, 50];
export const DEFAULT_PAGE_SIZE: number = 10;
export const FIRST_PAGE: number = 0;
export const DEFAULT_FETCH_PARAMS: FetchLeadsParams = {
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
  search: "",
};
export const DEFAULT_TLLEADFETCH_PARAMS: FetchTlLeadsParams = {
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
  taName: "",
  taEmail: "",
  originOfLead: "",
  destinationOfLead: "",
  status: "",
};

export const LEADS_API_IDEAL_STATUS: LeadModalAPIStatus = {
  status: "ideal",
  errors: new Map(),
};
