import _ from "lodash";
import { getLoggedInData } from "../actions/util";

export enum USER_ROLE {
  AGENCY_OWNER = "AGENCY_OWNER",
  COUNSELLOR = "COUNSELLOR",
  LPO_ADMIN = "LPO_ADMIN",
  LPO = "LPO",
  SB_PROCESSING_LEAD = "SB_PROCESSING_LEAD",
  SB_PROCESSING_OFFICER = "SB_PROCESSING_OFFICER",
}

export const PERMISSION_SEPARATOR = "_";

/*
  Permission key structure 
  {Parent Paermission key} + {separator} + { Sub Permission key }
*/
export enum PERMISSION {
  agentManagement = "agentManagement",
  applications_agent_view_main_agency = "applications_agent_view_main_agency",
  applications_agent_view_org = "applications_agent_view_org",
  applications_agent_view_own = "applications_agent_view_own",
  applications_assignAdmissionTeam = "applications_assignAdmissionTeam",
  applications_assignToMe = "applications_assignToMe",
  applications_create = "applications_create",
  applications_defer_approve_reject = "applications_defer_approve_reject",
  applications_defer_view = "applications_defer_view",
  applications_edit = "applications_edit",
  applications_provider_view_cph = "applications_provider_view_cph",
  applications_provider_view_global = "applications_provider_view_global",
  applications_provider_view_org = "applications_provider_view_org",
  applications_provider_view_own = "applications_provider_view_own",
  applications_provider_view_sb_org = "applications_provider_view_sb_org",
  applications_provider_view_sb_own = "applications_provider_view_sb_own",
  common = "common",
  dashboard_agent_view_main_agency = "dashboard_agent_view_main_agency",
  dashboard_agent_view_org = "dashboard_agent_view_org",
  dashboard_agent_view_own = "dashboard_agent_view_own",
  dashboard_provider_view_cph = "dashboard_provider_view_cph",
  dashboard_provider_view_global = "dashboard_provider_view_global",
  dashboard_provider_view_org = "dashboard_provider_view_org",
  dashboard_provider_view_own = "dashboard_provider_view_own",
  dms_edit = "dms_edit",
  dms_view_org = "dms_view_org",
  dms_view_own = "dms_view_own",
  ta_create = "ta_create",
  ta_dashboard_view = "ta_dashboard_view",
  ta_leads_create = "ta_leads_create",
  ta_leads_view = "ta_leads_view",
  ta_view = "ta_view",
  notifications = "notifications",
  reports = "reports",
  update_application_outcomes = "update_application_outcomes",
  update_application_status = "update_application_status",
  update_application_subStatus = "update_application_subStatus",
  user_agencies_create = "user_agencies_create",
  user_agencies_edit = "user_agencies_edit",
  user_agencies_view = "user_agencies_view",
  user_create = "user_create",
  user_edit = "user_edit",
  user_providers_create = "user_providers_create",
  user_providers_edit = "user_providers_edit",
  user_providers_view = "user_providers_view",
  user_roles_create = "user_roles_create",
  user_roles_edit = "user_roles_edit",
  user_roles_view = "user_roles_view",
  user_view_global = "user_view_global",
  user_view_org = "user_view_org",
  verifications_org = "verifications_org",
  verifications_own = "verifications_own",
  view_draft_applications = "view_draft_applications",
  /* Parant Permissions for UI based on group of multiple permissions (Not part of database) */
  applications = "applications",
  applications_agent_view = "applications_agent_view",
  applications_provider_view = "applications_provider_view",
  applications_defer = "applications_defer",
  dashboard = "dashboard",
  ta = "ta",
  dms = "dms",
  user = "user",
  user_view = "user_view",
  verifications = "verifications",
  delete_draft_application= "delete_draft_application",
  delete_application= "delete_application"
  /* Parant Permissions - END */
}

export const extractPermissions = (userData: any) => {
  const userRoles = _.get(userData, "user.userRoles", []);
  const permissions: string[] = [];
  userRoles.forEach((role: any) => {
    permissions.push(role.permissionList);
  });
  return _.flatten(permissions);
};

export const extractRolesPermission = (user: any, type: string) => {
  const userRoles = _.get(user, "userRolesMapping", []);
  const permissions: PERMISSION[] = [];
  const roles: string[] = [];
  userRoles.forEach((roleObj: any) => {
    if (type == "roles" || type == "all") {
      roles.push(roleObj.role.name);
    }
    if (type == "permissions" || type == "all") {
      roleObj.role.permissions.forEach((permissionObj: any) => {
        if (permissions.indexOf(permissionObj.permission.key) == -1) {
          permissions.push(permissionObj.permission.key);
        }
      });
    }
  });
  if (type == "roles") {
    return roles;
  } else if (type == "permissions") {
    return permissions;
  } else {
    return { roles, permissions };
  }
};

export const hasPermission = (perm: any, permissions: any = null) => {
  if (!permissions) {
    permissions = extractPermissions(getLoggedInData());
  }

  if (permissions.includes(perm)) {
    return true;
  } else {
    // Look for group of permission
    const groupPermission = permissions.find(
      (key: string) => key && key.includes(`${perm}${PERMISSION_SEPARATOR}`)
    );
    return groupPermission ? true : false;
  }
};

export const hasOneOfPermissions = (
  permissionsToCheck: PERMISSION[],
  userPermissions: PERMISSION[]
) => {
  return _.intersection(userPermissions, permissionsToCheck).length > 0;
};

export const hasOneOfRoles = (rolesToCheck: number[], userRoles: number[]) => {
  return _.intersection(userRoles, rolesToCheck).length > 0;
};

export const getRoleByPermissions = (user: any) => {
  const permissions: any = extractRolesPermission(user, "permissions");

  if (
    hasOneOfPermissions(
      [PERMISSION.applications_provider_view_org],
      permissions
    )
  ) {
    return "Processing Lead";
  } else if (
    hasOneOfPermissions(
      [PERMISSION.applications_provider_view_own],
      permissions
    )
  ) {
    return "Proessing Officer";
  } else if (
    hasOneOfPermissions([PERMISSION.applications_agent_view_org], permissions)
  ) {
    return "Agency Owner";
  } else if (
    hasOneOfPermissions([PERMISSION.applications_agent_view_own], permissions)
  ) {
    return "Counsellor";
  } else {
    return "";
  }
};
