import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Grid } from "@material-ui/core";

import "./D3LineChart.css";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../reducer";
import { kMaxLength } from "buffer";

type PropsFromRedux = ConnectedProps<typeof connector>;
type D3LineChartProps = PropsFromRedux & {
  backgroundColor: string;
  country: string;
  count: number;
  dataPointArray: [];
};

const D3LineChart: React.FC<D3LineChartProps> = ({
  backgroundColor,
  country,
  count,
  dataPointArray,
}) => {
  const getHighestYPoint = (dataPointsArray: []) => {
    let max = 0;
    for (let i = 0; i < dataPointArray.length; i++) {
      if (max < dataPointArray[i][1]) max = dataPointArray[i][1];
    }
    return max;
  };

  var dataset1 = dataPointArray;
  // var dataset1 = [
  //   [1, 1],
  //   [12, 20],
  //   [24, 36],
  //   [32, 50],
  //   [40, 70],
  //   [50, 60],
  //   [55, 60],
  //   [65, 65],
  //   [73, 75],
  //   [78, 45],
  //   [83, 50],
  //   [89, 90],
  //   [100, 80],
  //   [110, 36],
  //   [120, 50],
  //   [130, 70],
  //   [140, 60],
  //   [150, 60],
  //   [160, 65],
  //   [170, 90],
  //   [180, 80],
  //   [190, 36],
  //   [200, 50],
  //   [210, 70],
  //   [220, 60],
  //   [230, 60],
  //   [1240, 365],
  // ];

  const inititaeSvg = () => {
    d3.selectAll(`.svgLineChart${country.replace(/ /g, "")} svg`).remove();

    var svg = d3
        .select(divRef.current)
        .append("svg")
        .attr("width", divRef!.current?.offsetWidth ?? 100 - 200)
        .attr("height", 70),
      //width = 100,
      width = divRef!.current?.offsetWidth ?? 100 - 200,
      height = 30;

    // Step 4
    var xScale = d3
        .scaleLinear()
        .domain([0, dataPointArray.length])
        .range([0, width]),
      yScale = d3
        .scaleLinear()
        .domain([0, getHighestYPoint(dataPointArray)])
        .range([height, 0]);

    var g = svg
      .append("g")
      .attr("transform", "translate(" + 10 + "," + 10 + ")");

    // Step 8
    var line = d3
      .line<any>()
      .x(function (d) {
        return xScale(d[0]);
      })
      .y(function (d) {
        return yScale(d[1]);
      })
      .curve(d3.curveMonotoneX);

    svg
      .append("path")
      .datum(dataset1)
      .attr("class", "line")
      .attr("transform", "translate(" + 10 + "," + 10 + ")")
      .attr("d", line)
      .style("fill", "none")
      .style("stroke", "#ffffff")
      .style("stroke-width", "2");
  };

  useEffect(() => {
    inititaeSvg();
  });

  const divRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div
        className="svgContainerLineChart"
        style={{
          background: backgroundColor,
        }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            md={4}
            xs={6}
            xl={2}
            lg={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="lineChartInfo">{country}</div>
          </Grid>
          <Grid item md={4} xs={6} xl={8} lg={8}>
            <div>
              <div
                className={`svgLineChart${country.replace(/ /g, "")}`}
                ref={divRef}
              ></div>
            </div>
          </Grid>
          <Grid
            item
            md={4}
            xs={6}
            xl={2}
            lg={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="lineChartInfo" style={{ textAlign: "right" }}>
              {count}
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapState = (state: RootState) => ({});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);
export default connector(D3LineChart);
