import React from 'react';
import { makeStyles,createStyles, withStyles } from '@material-ui/styles';
import {
    Theme, DialogActions, Dialog, TextField, Button,
    DialogContent, DialogContentText, DialogTitle, LinearProgress, Typography, Divider, Grid, IconButton
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear'
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';

import closeIcon from './assets/close.png'

const useStyles = makeStyles((theme:Theme) => createStyles({
    root: {},
    files: {
        marginTop: theme.spacing(3)
    },
    header: {
        padding: theme.spacing(2),
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center'
    },
    recipient: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    buttonParent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end'
    }
}));

type DialogProps = {
    className?: string,
    open: boolean,
    setOpen: (open: boolean) => void,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MessageDialog: React.FC<DialogProps> = ({
                                                   open,
                                                   setOpen,

                                               }) => {
    const classes = useStyles();
    const [inputText, setInputText] = React.useState('');

    const onChangeHandler = (e: any) => {
        setInputText(e.target.value)
    }


    // console.log("File uploading info", fileUploadingInfo)

    const handleSubmit = () => {
        // setOpen(false);
        // onSubmit(inputText);
    };

    const handleClose = () => {
        // setOpen(false);
    };

    let percentage = 0


    // console.log("Percentage >>>", percentage)
    // @ts-ignore
    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            TransitionComponent={Transition}
            PaperProps={{
                style: {
                    backgroundColor: 'white',
                    width:'35%',
                    boxShadow: 'none',
                },
            }}
            BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.2)'}}}
        >
            <DialogContent style={{paddingRight:0, paddingLeft:0, paddingTop:0}}>
                <>

                    <div className={classes.header}>
                        <Typography  variant={"h4"}>
                            New Message To Bank Manager
                        </Typography>
                        <IconButton aria-label="close"  color="primary" onClick={()=> setOpen(false)}>
                            <ClearIcon />
                        </IconButton>
                    </div>
                    <Divider/>

                    <div className={classes.recipient}>
                        <TextField
                            fullWidth
                            label="Recipient*"
                            name="recipient"
                            variant="outlined"
                        />

                        <TextField
                            fullWidth
                            label="Message*"
                            name="followUpCommunication"
                            variant="outlined"
                            multiline={true}
                            style={{marginTop:32}}
                            inputProps={{
                                style: {
                                    height:93,
                                },
                            }}
                        />
                    </div>

                    <Divider/>

                    <div className={classes.buttonParent}>
                        <Button variant="contained" style={{backgroundColor: '#3576CB', color:'white'}} onClick={()=> setOpen(false)}>
                            Send
                        </Button>
                    </div>

                </>
            </DialogContent>
        </Dialog>
    );
}


export default MessageDialog;
