import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AppColor from '../../../theme/AppColor';
import SBText from '../../../components/base/SBText';
import FilesDropzone from './FileDropZone';
import { colors, Button, Divider } from '@material-ui/core';
import pdfIcon from '../assets/PDF.png';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import bytesToSize from '../../../util/bytesToSize';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: 4,
  },
  listParent: {
    minHeight: 60,
    width: '100%',
    backgroundColor: AppColor.appBlue,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
   selectAgentTxt: {
    fontSize: 20,
    color: AppColor.appWhite,
    fontWeight: 500,
    [theme.breakpoints.up(1920)]: {
      fontSize: 22,
    },
  },
  filenameTxt: {
    fontSize: 11,
    color: '#5C5F74',
    fontWeight: 400,
    [theme.breakpoints.up(1920)]: {
      fontSize: 22,
    },
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
  pdfIconImage: {
    width: '16px',
    height: '16px',
  },
}));

type UploadDocumentPopupProps = {
  className?: string;
  open: any;
  onClose: () => void;
  ApplicantName: string;
  leadstatus: string;
  uploadDocumnet: (file: any) => void;
};

const UploadDocumentPopup: React.FC<UploadDocumentPopupProps> = ({
  children,
  onClose,
  className,
  open,
  ApplicantName = '',
  leadstatus,
  uploadDocumnet,
  ...rest
}) => {
  const classes = useStyles();
  const handleSubmit = () => {
    if (contractDroppedFileInfo.fileName) {
      uploadDocumnet(contractDroppedFileInfo.file);
    } 
  };
  const [contractDroppedFileInfo, setContractDroppedFileInfo] = useState({
    fileName: '',
    isError: false,
    file: [],
    size: 0,
  });
  const onCloseModel = () => {
    onClose();
  };
  const handleContractFileDrop = (file: any) => {
    if (file.length) {
      setContractDroppedFileInfo((prevState) => ({
        ...prevState,
        fileName: file[0].name,
        isError: false,
        file: file[0],
        size: file[0].size,
      }));
    } else {
      setContractDroppedFileInfo((prevState) => ({
        ...prevState,
        fileName: '',
        isError: true,
        file: [],
        size: 0,
      }));
    }
  };
  const handleDelete = () => {
    setContractDroppedFileInfo((prevState) => ({
      ...prevState,
      fileName: '',
      isError: true,
      file: [],
      size: 0,
    }));
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onCloseModel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.listParent}>
            <SBText
              text={contractDroppedFileInfo.fileName ? 'Uploaded Documents' : 'Upload Document'}
              className={classes.selectAgentTxt}
            />
            <CloseIcon
              onClick={() => onClose()}
              style={{ marginLeft: 10, color: AppColor.appWhite, cursor: 'pointer' }}
            />
          </div>
          {!contractDroppedFileInfo.fileName ? (
            <div>
              <FilesDropzone onFileDrop={handleContractFileDrop} />
              <div style={{ marginTop: '18px' }} className={classes.buttonWrapper}>
                {/* <Button
                  color="primary"
                  style={{ margin: '25px 10px', width: 200, textTransform: 'none' }}
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  {'Upload Document'}
                </Button> */}
              </div>
            </div>
          ) : (
            <div style={{ padding: '0px 20px', height: '300px' }}>
              <div style={{ display: 'flex', padding: '20px 4px', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', width: '60%', gap: 10 }}>
                  <div>
                    <img src={pdfIcon} className={classes.pdfIconImage} alt="pdficon" />
                  </div>
                  <div>
                    <SBText text={contractDroppedFileInfo.fileName} className={classes.filenameTxt} />
                  </div>
                </div>
                <div style={{ display: 'flex', width: '40%', justifyContent: 'flex-end', gap: 10 }}>
                  <div style={{ height: 20, padding: '0px 5px', border: '1px solid #c0c0c5' }}>
                    <SBText
                      text={`${bytesToSize(contractDroppedFileInfo.size)}`}
                      style={{ fontSize: 10 }}
                      className={classes.filenameTxt}
                    />
                  </div>
                  <div>
                    <DeleteIcon
                      onClick={() => handleDelete()}
                      fontSize="small"
                      style={{ color: '#d5876e', cursor: 'pointer' }}
                    />
                  </div>
                </div>
              </div>
              <Divider />
              <div style={{ marginTop: '140px' }} className={classes.buttonWrapper}>
                <Button
                  color="primary"
                  style={{ margin: '25px 10px', width: 200, textTransform: 'none' }}
                  variant="contained"
                  onClick={() => handleSubmit()}
                >
                  {'Submit'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default UploadDocumentPopup;
