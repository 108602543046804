import { StatusViewItem } from "../../../components/StatusChip/status-type";

export enum AgentStatus {
  created = 1,
  invited = 2,
  applicationReceived = 3,
  applicationRejected = 4,
  referenceCheckInProgress = 5,
  referenceCheckDisapproved = 6,
  referenceCheckCompleted = 7,
  applicationApproved = 8,
  contractSent = 9,
  contractReceived = 10,
  onboarded = 11,
  // loggedIn = 12
}

export type AgentStatusViewItem = StatusViewItem & {};

export type AgentStatusDetailsType = {
  [key in AgentStatus]: AgentStatusViewItem;
};

export const AgentStatusDetails: AgentStatusDetailsType = {
  [AgentStatus.created]: {
    label: "Created",
    color: "#3D405B",
  },
  [AgentStatus.invited]: {
    label: "Invited",
    color: "#3D405B",
  },
  [AgentStatus.applicationReceived]: {
    label: "Application Received",
    color: "#3D405B",
  },
  [AgentStatus.applicationApproved]: {
    label: "Application Approved",
    color: "#3D405B",
  },
  [AgentStatus.applicationRejected]: {
    label: "Rejected",
    color: "#3D405B",
  },
  [AgentStatus.referenceCheckInProgress]: {
    label: "Reference Check in Progress",
    color: "#3D405B",
  },
  [AgentStatus.referenceCheckCompleted]: {
    label: "Reference Check Completed",
    color: "#3D405B",
  },
  [AgentStatus.referenceCheckDisapproved]: {
    label: "Reference Check Disapproved",
    color: "#D27F65",
  },
  [AgentStatus.contractSent]: {
    label: "Contract Sent",
    color: "#3D405B",
  },
  [AgentStatus.contractReceived]: {
    label: "Contract Recieved",
    color: "#3D405B",
  },
  [AgentStatus.onboarded]: {
    label: "Onboarded",
    color: "#8BB19C",
  },
};
