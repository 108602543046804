import {
  Badge,
  Button,
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  Switch,
  TablePagination,
  Theme,
  Tooltip,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import WorkOutlined from '@material-ui/icons/WorkOutlined';
import { createStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { encryptText } from '../../util';
import { connect, ConnectedProps } from 'react-redux';
import {
  clearReviewHistory,
  deleteQuestionFile,
  downloadAllApplicationFile,
  downloadAllApplicationFileByGroupId,
  downloadQuestionFile,
  viewQuestionFile,
  downloadVersionHistoryFile,
  getALlDMSApplicationFiles,
  getDMSApplicationList,
  getReviewHistroy,
  lockUnlockDMSFileQuestions,
  resetDMSApplicationList,
  reviewDocument,
  setDMSQuestion,
  toggleMandatoryFlag,
  uploadQuestionFileFromDMS,
  addDocuments,
} from '../../actions';
import SBText from '../../components/base/SBText';
import Page from '../../components/Page';
import { RootState } from '../../reducer';
import AppColor from '../../theme/AppColor';
import ApproveRejectPopup from './ApproveRejectPopup';
import DMSApplicationList from './DMSApplicationList';
import ReviewHistory from './ReviewHistory';

import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import HistoryIcon from '@material-ui/icons/History';
import _, { filter } from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router';
import EmailSeccess from '../DMS/EmailSuccess';
import {
  AcceptanceIcon,
  DownloadIcon,
  EnglishIcon,
  IdIcon,
  IntakeIcon,
  LanguageIcon,
  MarriageIcon,
  SponcerIcon,
  UniversityIcon,
  UploadIcon,
  AddDocIcon,
} from '../../assets/scss/svgIcons';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { getFormattedDMSApplicationList } from '../../selector/applicationList';
import { decryptText, getApplicationIdInString } from '../../util';
import { hasPermission, PERMISSION } from '../../util/rolePermissionsUtil';
import lockBlue from './assets/lock_blue.png';
import unlockBlue from './assets/unlock_blue.png';
import { Visibility } from '@material-ui/icons';
import FileViewer from '../FileViewer';
import SBCircularProgress from '../../components/SBCircularProgress';
import AddIcon from '@material-ui/icons/Add';
import AddNewQuestionPopup from './AddNewQuestionPopup';
import SBRevealButton from '../../components/base/SBRevealButton';
import AgentEmailBodyDialog from './AgentEmailBodyDialog';
import { style } from 'd3';


interface FetchParams {
  limit: number;
  offset: number;
  search: string;
}
const PAGE_SIZES = [10, 20, 50, 100];

const DEFAULT_FETCH_PARAMS: FetchParams = {
  offset: 0,
  limit: PAGE_SIZES[0],
  search: '',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#E5E5E5',
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(4),
      height: 'calc(100vh - 64px)',
    },
    statusParent: {
      height: '100%',
      width: '100%',
      backgroundColor: '#EACB78',
      borderWidth: 0.8,
      borderStyle: 'solid',
      borderColor: '#C99E27',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
    },
    statusApproveParent: {
      height: '100%',
      width: '100%',
      backgroundColor: '#CBDFD4',
      borderColor: '#32A164',
      borderWidth: 0.8,
      borderStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
    },
    statusRejectParent: {
      height: '100%',
      width: '100%',
      backgroundColor: '#E3ACAA',
      borderColor: '#E5706C',
      borderWidth: 0.8,
      borderStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
    },
    approveBt: {
      backgroundColor: 'rgba(245,245,245,1)',
      borderWidth: 1,
      paddingLeft: 10,
      paddingRight: 10,
      height: 30,
      fontSize: 12,
      borderColor: '#4c4c4c',
      borderStyle: 'solid',
      color: '#4c4c4c',
      '&:hover': {
        backgroundColor: '#CBDFD4',
        borderColor: '#32A164',
        boxShadow: 'none',
      },
    },
    viewBt: {
      marginRight: 5,
      borderRadius: 20,
      height: 30,
    },
    initiateBt: {
      backgroundColor: 'rgba(245,245,245,0)',
      height: 30,
      borderWidth: 1,
      borderColor: '#4c4c4c',
      borderStyle: 'solid',
      color: '#4c4c4c',
      fontSize: 12,
      marginLeft: 5,
      '&:hover': {
        backgroundColor: '#DCE8F9',
        borderColor: '#6993CD',
        boxShadow: 'none',
      },
    },
    content: {
      padding: 0,
    },
    inner: {
      display: 'flex',
      width: '100%',
    },
    tableBody: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      // borderBottomColor: "black",
      // borderBottomWidth: 1,
      // borderBottomStyle: "solid",
      height: 55,
    },
    tableRowHeader: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      backgroundColor: '#EFEFEF',
      height: 55,
    },
    tableCell: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
    },

    dateTableCell: {
      display: 'flex',
      flex: 0.3,
      alignItems: 'center',
    },
    messageTableCell: {
      display: 'flex',
      flex: 0.5,
      alignItems: 'center',
    },
    actionTableCell: {
      display: 'flex',
      flex: 0.2,
      alignItems: 'center',
    },

    dateHeaderTableCell: {
      display: 'flex',
      flex: 0.3,
    },
    messageHeaderTableCell: {
      display: 'flex',
      flex: 0.5,
    },

    actionHeaderTableCell: {
      display: 'flex',
      flex: 0.2,
    },
    tableValueCell: {
      display: 'flex',
      flex: 1,
    },
    tableHeader: {
      height: 75,
    },
    statusPending: {
      backgroundColor: '#EACB78',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 12,
      borderWidth: 0.8,
      borderStyle: 'solid',
      borderColor: '#C99E27',
    },
    statusApp: {
      backgroundColor: '#CBDFD4',
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: 12,
      borderWidth: 0.8,
      paddingTop: 5,
      paddingBottom: 5,
      borderColor: '#32A164',
      borderStyle: 'solid',
    },
    statusRejected: {
      backgroundColor: '#E3ACAA',
      paddingLeft: 10,
      paddingRight: 10,
      fontSize: 12,
      borderWidth: 0.8,
      borderColor: '#E5706C',
      borderStyle: 'solid',
    },
    tabInactive: {
      height: 50,
      display: 'flex',
      flex: 1,
      maxWidth: '20%',
      backgroundColor: 'white',
      marginRight: 20,
      alignItems: 'center',
      justifyContent: 'center',
    },
    tabSelected: {
      height: 60,
      display: 'flex',
      flex: 1,
      maxWidth: '20%',
      backgroundColor: 'white',
      marginRight: 20,
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      zIndex: 1,
      // boxShadow: "1px -10px 10px 2px  rgba(0, 0, 0, 0.117647)",
      boxShadow: ' -2px -3px 4px 1px rgba(0, 0, 0, 0.1);',
    },

    tabsParent: {
      height: 60,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
    },
    tableParents: { border: '1px solid #B0AFAF', display: 'flex', flex: 1 },

    mainLayout: {
      display: 'flex',
      flex: 1,
      backgroundColor: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.117647) 2px 4px 6px 4px',
      padding: 16,
      overflowY: 'scroll',
    },
    verticalDivider: { width: 10, backgroundColor: '#E5E5E5' },
    tabText: {
      fontWeight: 500,
      fontSize: 15,
    },
    tableHeaderText: {
      fontWeight: 500,
      fontSize: 14,
    },
    disapproveText: {
      fontSize: 15,
      fontWeight: 700,
      color: '#D27F65',
    },
    approveText: {
      fontSize: 15,
      fontWeight: 700,
      color: '#8BB19C',
    },
    tableText: {
      fontSize: 14,
    },
    heading: {
      fontSize: theme.typography.pxToRem(14),
      fontFamily: 'Poppins',
      fontWeight: 500,
      flexShrink: 0,
      [theme.breakpoints.up(1920)]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    nested: {
      // paddingLeft: theme.spacing(4),
      backgroundColor: '#ededed',
    },
    listParent: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      maxHeight: 305,
      overflowY: 'scroll',
      padding: 0,
    },
    listItem: {
      minHeight: 60,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
      paddingLeft: 16,
      paddingRight: 0,
      paddingTop: 10,
      paddingBottom: 10,
      borderBottomWidth: 0.2,
      borderBottomColor: '#d3d3d3',
      borderBottomStyle: 'solid',
    },

    headerText: { fontSize: 13, fontWeight: 500, color: AppColor.appBlue },
    itemText: { fontSize: 12, maxWidth: '100%', color: '#63667C' },
    questionName: { fontSize: 12, maxWidth: '100%', color: '#63667C', paddingRight: 8,
    fontFamily: "Poppins",
    textTransform: "none",
    fontWeight: 400,
    letterSpacing: 0.75, },
    subItemText: { fontSize: 11 },
    headerSubParent: {
      height: 60,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flex: 1,
    },
    headerParent: {
      width: '100%',
      backgroundColor: '#efefef',
      paddingLeft: 16,
      paddingRight: 0,
    },
    rightHeaderText: {
      color: '#606378',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      marginLeft: '-15px',
      display: 'flex',
      alignItems: 'center',
      '& svg': { margin: '0 8px 0 30px' },
    },
    rightHeaderAppButton: {
      padding: '10px 10px',
      color: '#606378',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 500,
      '& svg': {
        margin: '0 0 0 20px',
      },
    },
    leftColTxt: {
      color: 'white',
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        fontSize: theme.typography.pxToRem(18),
      },
    },
    fileComplete: {
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#8BB19C',
      },
    },
    fileModarate: {
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#EBCC97',
      },
    },
    fileWarning: {
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#D27F64',
      },
    },
    fileNone: {
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#d9d7d7',
      },
    },
    groupBox: {
      // width: 130,
      // minWidth: "120px",

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      // alignItems: 'center',
      border: '1px solid #EDEDED',
      borderRadius: '8px',
      margin: '15px 10px 10px 0',
      // padding: '10px',
      color: '#898C9C',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0px 0px 6px #DDDDDD',
      },
      // height: 180,
    },
    documentListItem: {
      padding: 0,
      '&:hover $actionContainer': {
        opacity: 1,
      },
    },
    actionContainer: {
      transition: '0.5s',
      opacity: 0,
    },
    activeGroup: {
      border: '2px solid #8BB19C',
      color: '#3D405B',
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
    },
    grayButton: {
      cursor: 'pointer',
      marginLeft: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '4px 16px',
      background: '#F8F8F8',
      border: '1px solid #DDDDDD',
      boxSizing: 'border-box',
      borderRadius: '4px',
      fontSize: '12px',
      '&:hover': {
        boxShadow: '0px 0px 6px #DDDDDD',
      },
    },
    dmsPpagination: {
      boxShadow: '0px 7px 6px rgb(0 0 0 / 15%)',
      borderRight: '1px solid #CDCDCD',
      '& .MuiToolbar-root': {
        paddingLeft: 20,
      },
      '& .MuiTablePagination-selectRoot': {
        marginRight: 20,
      },
      overflowX: 'hidden',
    },
    switchBase: {
      color: '#818181',
      padding: '1px',
      '&$checked': {
        '& + $track': {
          backgroundColor: '#23bf58',
        },
      },
    },
    thumb: {
      color: 'white',
      width: '20px',
      height: '20px',
      margin: '1px',
    },
    track: {
      borderRadius: '20px',
      backgroundColor: '#818181',
      opacity: '1 !important',
      '&:after, &:before': {
        color: 'white',
        fontSize: '9px',
        position: 'absolute',
        top: '6px',
      },
      '&:after': {
        content: "'Yes'",
        left: '8px',
        fontSize: '9px',
      },
      '&:before': {
        content: "'No'",
        fontSize: '9px',
        right: '7px',
      },
    },
    checked: {
      color: '#23bf58 !important',
      transform: 'translateX(26px) !important',
    },
    main: {
      width: '50px',
      height: '24px',
      padding: '0px',
    },
    aprrejBtn: {
      border: '1px solid #DDDDDD',
      padding: '2px 10px 0px 10px',
      borderRadius: 2,
      cursor: 'pointer',
    },
    rejBtn: { backgroundColor: '#f3d3c9 !important', padding: '2px 10px 0px 10px', borderRadius: 2, cursor: 'pointer' },
    aprBtn: { backgroundColor: '#c5e1bc !important', padding: '2px 10px 0px 10px', borderRadius: 2, cursor: 'pointer' },
  })
);
function buildFileSelector() {
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('multiple', 'multiple');
  return fileSelector;
}

type PropsFromRedux = ConnectedProps<typeof connector>;
type InboxProps = PropsFromRedux & {
  classes?: string;
  className?: string;
};

const DMS: React.FC<InboxProps> = ({
  className,
  getDMSApplicationList,
  dmsApplicationList,
  getALlDMSApplicationFiles,
  dmsCurrentAppDetails,
  groupList,
  downloadQuestionFile,
  viewQuestionFile,
  downloadVersionHistoryFile,
  deleteQuestionFile,
  uploadQuestionFileFromDMS,
  setDMSQuestion,
  dmsQuestion,
  reviewHistory,
  downloadAllApplicationFileByGroupId,
  downloadAll,
  resetDMSApplicationList,
  lockUnlockDMSFileQuestions,
  toggleMandatoryFlag,
  reviewDocument,
  getReviewHistroy,
  clearReviewHistory,
  reviewdocumentstatus,
  fileUploadProgress,
  addDocuments,
  ...rest
}) => {
  let { applicationId = '0', block = '0' } = useParams<{ applicationId: string; block: string }>();
  const classes = useStyles();
  const userPermissions: PERMISSION[] = useUserPermissions();
  const [open, setOpen] = React.useState([]);
  const fileSelector = buildFileSelector();
  const [confirmDialog, showConfirmDialog] = useState<any>(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState<any>('');
  const [appList, setAppList] = React.useState([]);
  const [approveRejectPopup, setApproveRejectPopup] = React.useState(false);
  const [reviewHistoryPopup, setReviewHistoryPopup] = React.useState(false);
  const [addNewQuestionPopup, setAddNewQuestionPopup] = React.useState(false);
  const [agentEmailBodyPopup, ShowagentEmailBodyPopup] = React.useState(false);

  const handleClick = (mainIndex: number, subIndex: number) => {
    const tempOpen: any = _.cloneDeep(open);
    tempOpen[mainIndex][subIndex] = !tempOpen[mainIndex][subIndex];
    setOpen(tempOpen);
  };
  const [activeGroup, setActiveGroup] = React.useState<any>(undefined);
  const [preSelectedApplication, setPreSelectedApplication] = React.useState<string>('');
  const [action, setAction] = React.useState<string>('');
  const [label, setLabel] = React.useState<string>('');
  const [questionId, setQuestionId] = React.useState<Number>(0);
  const [groupId, setGroupId] = React.useState<Number>(0);
  const [fetchParams, setFetchParams] = useState<FetchParams>(DEFAULT_FETCH_PARAMS);
  const [currentSelectedApplicationId, setCurrentSelectedApplicationId] = useState<string>();
  useEffect(() => {
    const preSelect = applicationId && applicationId != '0' ? (decryptText(applicationId) as string) : '0';
    setCurrentSelectedApplicationId(preSelect);
    setPreSelectedApplication(preSelect);
    if (preSelect != '0') {
      setFetchParams({
        ...fetchParams,
        search: preSelect,
      });
    }
  }, [applicationId]);

  useEffect(() => {
    return () => {
      //Reset current application
      resetDMSApplicationList();
    };
  }, []);

  const hasPerm = (perm: string) => {
    return hasPermission(perm, userPermissions);
  };

  const handleFileSelect = (e: any, question: any) => {
    fileSelector.addEventListener('change', (event: any) => onFileChange(event, question));
    fileSelector.click();
  };

  const onFileChange = async (event: any, question: any) => {
    const file = event.target.files[0];
    const newFile = new File(
      [file],
      `${getApplicationIdInString(dmsCurrentAppDetails.id)}_${new Date().getTime()}#${file.name}`,
      {
        type: file.type,
      }
    );

    let questionString = '';
    if (question.label) {
      questionString = question.label;
    } else {
      questionString = question.question;
    }

    await uploadQuestionFileFromDMS(newFile, activeGroup.name, questionString);
    // await getALlDMSApplicationFiles(dmsCurrentAppDetails.id);
    loadDMSList(false);
  };

  let tableHeader = [
    { name: 'Document', width: '20%' },
    { name: 'Uploaded Date', width: '15%' },
    { name: 'Uploaded By', width: '15%' },
    { name: 'Review', width: '20%' },
  ];
  const tableHeaderPlPo = [
    { name: 'Document', width: '20%' },
    { name: 'Uploaded Date', width: '15%' },
    { name: 'Uploaded By', width: '15%' },
    { name: 'Mandatory', width: '10%' },
    { name: 'Review', width: '20%' },
  ];
  let isCsCaPresent = false;
  if (
    !(
      hasPerm(PERMISSION.applications_agent_view) ||
      hasPerm(PERMISSION.applications_agent_view_org) ||
      hasPerm(PERMISSION.applications_agent_view_own) ||
      hasPerm(PERMISSION.applications_agent_view_main_agency)
    )
  ) {
    isCsCaPresent = true;
    tableHeader = tableHeaderPlPo;
  }

  const getTableHeader = () => {
    const headerArray = [];

    for (let i = 0; i < tableHeader.length; i++) {
      if (!isCsCaPresent && tableHeader[i].name === 'Review') {
        tableHeader[i].name = 'Status';
      }
      const jstfy = ['Status', 'Mandatory', 'Review'].includes(tableHeader[i].name) ? 'center' : 'none';
      headerArray.push(
        <div style={{ display: 'flex', width: tableHeader[i].width, justifyContent: jstfy }} key={`tabh-${i}`}>
          <SBText text={tableHeader[i].name} className={classes.headerText} style={{ color: '#3D405B' }} />
        </div>
      );
    }

    return headerArray;
  };

  useEffect(() => {
    /*
      Avoid 2 API calls, when we are opening DMS from Application list
      fetchParams change triggers on initial load and when we read applicationId from URL
    */
    if (
      applicationId == '0' ||
      preSelectedApplication == '0' ||
      (preSelectedApplication != '0' && fetchParams.search)
    ) {
      loadDMSList();
    }
  }, [fetchParams]);

  useEffect(() => {
    if (dmsApplicationList?.data?.length > 0) {
      setAppList(dmsApplicationList.data);
    } else {
      setAppList([]);
    }
  }, [dmsApplicationList]);

  useEffect(() => {
    const newArray: any = [];
    if (groupList && groupList.length > 0) {
      for (let i = 0; i < groupList.length; i++) {
        const subArray = [];
        for (let j = 0; j < groupList[i].questions.length; j++) {
          subArray.push(false);
        }
        newArray.push(subArray);
      }
      setOpen(newArray);

      if (!activeGroup) {
        setActiveGroup(groupList[0]);
      }
    }
  }, [groupList]);
  useEffect(() => {
    setApproveRejectPopup(false);
  }, [reviewdocumentstatus]);

  const loadDMSList = (showProgress: boolean = true) => {
    let params: FetchParams = { ...fetchParams };
    getDMSApplicationList(params, showProgress);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setFetchParams({
      ...fetchParams,
      offset: newPage * dmsApplicationList.pageSize,
    });
  };
  const onAppoveOrReject = (reviewStatus: boolean, comment: string, qid: Number) => {
    reviewDocument(
      {
        reviewStatus: reviewStatus,
        comment: comment,
        applicationQuestionId: qid,
      },
      groupId
    );
  };

  const handleOpenPopup = (action: string, question: any, gid: any) => {
    let label = question.label;
    if (!label && question.answer.key) {
      label = decodeURI(
        question.answer.key.substring(question.answer.key.lastIndexOf('/') + 1, question.answer.key.length)
      );
    }

    if (!label) {
      label = question.question;
    }
    setAction(action);
    setApproveRejectPopup(true);
    setLabel(label);
    setQuestionId(question.id);
    setGroupId(gid);
  };
  const handleReviewHistory = (documentLabel: string, qid: Number) => {
    getReviewHistroy(qid);
    setLabel(documentLabel);
    setReviewHistoryPopup(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pageSize: number = parseInt(event.target.value, 10);
    setFetchParams({
      ...fetchParams,
      limit: pageSize,
      offset: 0,
    });
  };

  const getApplicationDetails = async (obj: any) => {
    setCurrentSelectedApplicationId(obj.id);
    await getALlDMSApplicationFiles(obj.id);
  };

  const getFileName = (fileName: string) => {
    if (fileName.includes('#')) {
      fileName = fileName.split('#')[1];
    }
    return fileName;
  };

  const downloadFile = (id: string, fileName: string) => {
    downloadQuestionFile({ id, fileName: fileName }, dmsCurrentAppDetails.id);
  };

  const viewFile = (id: string, fileName: string) => {
    viewQuestionFile({ id, fileName }, dmsCurrentAppDetails.id);
  };

  const handleMandatory = async (questionid: any, groupId: number) => {
    if (currentSelectedApplicationId) {
      await toggleMandatoryFlag(
        { applicationId: currentSelectedApplicationId, applicationQuestionId: questionid },
        groupId
      );
      // loadDMSList();
    }
  };

  const downloadAllFiles = (groupId: string) => {
    downloadAllApplicationFileByGroupId(dmsCurrentAppDetails.id, groupId);
  };

  const downloadAllApplicationFiles = () => {
    downloadAll(dmsCurrentAppDetails.id);
  };

  const sendNotificationToAgent = () => {
    ShowagentEmailBodyPopup(true);
  }

  const deleteFile = async (answerId: string) => {
    await deleteQuestionFile(dmsCurrentAppDetails.id, answerId);
    await getALlDMSApplicationFiles(dmsCurrentAppDetails.id);
  };

  const getHeader = () => {
    let applicationNo = '';
    let country = '';
    let university = '';
    let term = '';
    if (dmsCurrentAppDetails && dmsCurrentAppDetails.keyAnswers) {
      applicationNo = getApplicationIdInString(dmsCurrentAppDetails.id);

      {
        /*SB0000008 | Australia | Baxter Institute | Nov-21*/
      }
      const destCountry = _.find(dmsCurrentAppDetails.keyAnswers, (obj: any) => obj.key == 'Q_DESTCOUNTRY');
      if (destCountry && destCountry.value) {
        country = destCountry.value;
      }

      const provider = _.find(dmsCurrentAppDetails.keyAnswers, (obj: any) => obj.key == 'Q_PROVIDER');
      if (provider && provider.value) {
        university = provider.value;
      }

      const intake = _.find(dmsCurrentAppDetails.keyAnswers, (obj: any) => obj.key == 'Q_INTAKE');
      if (intake && intake.value) {
        term = intake.value;
      }
    }

    return (
      <div className={classes.rightHeaderText}>
        <Button startIcon={<IdIcon style={{ fontSize: '1.5rem' }} />}
          className={classes.rightHeaderAppButton}
          href={`/app/${encryptText(currentSelectedApplicationId)}/group/-1/question/-1`}
          target="_blank">
          {applicationNo}
        </Button>
        {/* <IdIcon /> {applicationNo} */}
        <LanguageIcon /> {country}
        <UniversityIcon /> {university}
        <IntakeIcon /> {term}
      </div>
    );
  };

  const onSearchText = (text: string) => {
    setPreSelectedApplication('0');
    setFetchParams({
      ...fetchParams,
      search: text?.trim(),
      offset: 0,
    });
  };

  // const handleLockUnlock = (question: any) => {
  //   const body = {
  //     questionId: [question.id],
  //     lockType: 'Single',
  //     lock: !question.isLocked,
  //   };
  //   lockUnlockDMSFileQuestions(body, activeGroup.name);
  // };

  // const handleLockUnlockDMSBlock = () => {
  //   const group = dmsCurrentAppDetails
  //     ? _.find(dmsCurrentAppDetails.groups, (obj: any) => obj.name == activeGroup.name)
  //     : null;
  //   const questionIds = _.map(group.questions, (obj: any) => obj.id);
  //   const body = {
  //     questionId: questionIds,
  //     lockType: 'All',
  //     lock: !group.isDMSLocked,
  //     groupId: group.id,
  //   };
  //   lockUnlockDMSFileQuestions(body, activeGroup);
  // };

  // const showConfirmInfoDialog = () => {
  //   const group = dmsCurrentAppDetails
  //     ? _.find(dmsCurrentAppDetails.groups, (obj: any) => obj.name == activeGroup)
  //     : null;
  //   showConfirmDialog(true);
  //   let message = '';
  //   if (!group.isDMSLocked) {
  //     message = `Are you sure you want lock all the file in ${activeGroup} block, It won't allow user to update the new files`;
  //   } else {
  //     message = `Are you sure you want unlock all the file in ${activeGroup} block, It will allow user to update the new files`;
  //   }
  //   setConfirmDialogMessage(message);
  // };

  const onConfirmDelete = () => {
    const group = dmsCurrentAppDetails
      ? _.find(dmsCurrentAppDetails.groups, (obj: any) => obj.name == activeGroup.name)
      : null;
    const questionIds = _.map(group.questions, (obj: any) => obj.id);
    const body = {
      questionId: questionIds,
      lockType: 'All',
      lock: !group.isDMSLocked,
      groupId: group.id,
    };
    showConfirmDialog(false);
    setConfirmDialogMessage('');
    lockUnlockDMSFileQuestions(body, activeGroup.name);
  };

  const getMandotaryAndUploadedQuestion = (group: any) => {
    let mandatoryCount = 0;
    let mandatoryAnswerd = 0;
    for (let i = 0; i < group.questions.length; i++) {
      if (group.questions[i].mandatory) {
        mandatoryCount++;
        if (group.questions[i].answer) {
          mandatoryAnswerd++;
        }
      }
    }
    return { mandatoryCount, mandatoryAnswerd };
  };

  const getGroupIcon = (name: string) => {
    const style = {
      color: 'gray',
      fontSize: 48,
      margin: '10px auto',
    };

    const style_1 = {
      color: 'gray',
      fontSize: 48,
      margin: '10px auto',
      height: 48,
      width: 45,
    };

    switch (name) {
      case 'Registration':
        return <DescriptionOutlinedIcon style={style} />;
      case 'Academic':
        return <AccountBalanceOutlinedIcon style={style} />;
      case 'Work Exp':
        return <WorkOutlined style={style} />;
      case 'Marriage':
        return <MarriageIcon style={style} />;
      case 'English':
        return <EnglishIcon style={style} />;
      case 'Sponsorship':
      case 'Sponsor':
      case 'Sponsor-1':
      case 'Sponsor-2':
        return <SponcerIcon style={style} />;
      case 'Completion':
        return <AcceptanceIcon style={style} />;
      case 'Additional Documents':
        return <AddDocIcon style={style_1} />;
      default:
        return <DescriptionOutlinedIcon style={style} />;
    }
  };


  const getQuestionText = (question:any, group:any) => {
    const data = filter(group, (obj) => obj.question === question.question)
    if (data.length === 1) {
      return question.label ? question.label : question.question; 
    } else {
      let shortGropName = ''
      if (question.verificationGroup === 'FD Verification') {
        shortGropName = 'FD'
      } else if (question.verificationGroup === 'Savings Verification') {
        shortGropName = 'SA'
      } else if (question.verificationGroup === 'Edu Loan Verification') {
        shortGropName = 'EL'
      } else if (question.verificationGroup === 'Work Exp') {
        shortGropName = 'WE'
      }
 
      if (question.label) {
        return `${question.label}(${shortGropName}-${question.tabIndex+1})`
      } else {
        return `${question.question}(${shortGropName}-${question.tabIndex+1})`
      }     
    }
  }

  const getSortedQuestions = (questionList: any) => {
  
    const sortedList = _.sortBy(questionList, [function (o) { return o.id; }]);
    const dulicate:any = {}  
    for (let i = 0; i < sortedList.length; i++) {
      if (dulicate.hasOwnProperty(sortedList[i].question)) {
        dulicate[sortedList[i].question]["count"]  =  dulicate[sortedList[i].question]["count"]+1
      } else {
        dulicate[sortedList[i].question] = {count : 0, number: 0}
      }
    }

    for (const key in dulicate){
      if (dulicate.hasOwnProperty(key)) {
        if (dulicate[key]["count"] === 0) {
          delete dulicate[key];
        }
      } 
    }
    for (let i = 0; i < sortedList.length; i++) {
      if (dulicate.hasOwnProperty(sortedList[i].question)) {
        sortedList[i].tabIndex = dulicate[sortedList[i].question].number
        dulicate[sortedList[i].question].number = dulicate[sortedList[i].question].number + 1
      } 
    }
    return sortedList
  }



  return (
    <>
      <EmailSeccess></EmailSeccess>
      <Page title={'DMS'} className={classes.root}>
        <div style={{ width: '23%' }}>
          <DMSApplicationList
            onListItemClick={(obj) => getApplicationDetails(obj)}
            selectedApplication={preSelectedApplication}
            list={appList}
            onSearchText={(text) => onSearchText(text)}
          />
          <TablePagination
            className={classes.dmsPpagination}
            rowsPerPageOptions={PAGE_SIZES}
            labelRowsPerPage="Rows"
            component="div"
            count={dmsApplicationList?.totalRecords}
            rowsPerPage={dmsApplicationList?.pageSize}
            page={dmsApplicationList?.currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
        <div className={classes.mainLayout}>
          {dmsCurrentAppDetails ? (
            <div style={{ width: '100%' }}>
              <div
                style={{
                  width: '100%',
                  height: 60,
                  backgroundColor: AppColor.appGray,
                  display: 'flex',
                  paddingLeft: 16,
                  paddingRight: 16,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                {getHeader()}
                {hasPerm(PERMISSION.dms_edit) && (
                  <>
                  <div style={{ display: 'flex', height: 35}}>
                  <SBRevealButton icon={ <NotificationsIcon fontSize='small' style={{ color: '#606378' }}/> }
                                text={ <SBText
                                          text={'Notify Agent'}
                                          style={{
                                            color: '#606378',
                                            fontSize: 14,
                                            fontWeight: 600,
                                        }}/>
                                      } 
                                onClick={sendNotificationToAgent}/>
                    <SBRevealButton icon={ <DownloadIcon fontSize='small' style={{ color: '#606378' }}/> }
                                text={ <SBText
                                          text={'Download All'}
                                          style={{
                                            color: '#606378',
                                            fontSize: 14,
                                            fontWeight: 600,
                                        }}/>
                                      } 
                                onClick={downloadAllApplicationFiles}/>
                      {/* <Button
                        onClick={(event) => downloadAllApplicationFiles()}
                        startIcon={<DownloadIcon style={{ color: '#606378' }} />}
                      >
                        <SBText
                          text={'Download All'}
                          style={{
                            color: '#606378',
                            fontSize: 14,
                            fontWeight: 600,
                          }}
                        />
                      </Button> */}
                    </div>
                  </>
                )}
              </div>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  overflowX: 'auto',
                  // height:300
                }}
              >
                {groupList.map((group: any, mainIndex: number) => {
                  const progressObj = getMandotaryAndUploadedQuestion(group);
                  return (
                    <div
                      //  style={{width:500, height:500, backgroundColor:'pink', marginRight:10}}
                      className={`${classes.groupBox} ${
                        activeGroup && activeGroup?.name === group.name ? classes.activeGroup : ''
                      }`}
                      onClick={(e) => {
                        setActiveGroup(group);
                      }}
                      key={group.id}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'left',
                        }}
                      >
                        <div
                          style={{
                            width: 110,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '75%',
                            marginTop: 12,
                          }}
                        >
                          {getGroupIcon(group.name)}
                          <Typography
                            className={classes.heading}
                            style={{
                              color: AppColor.textGray,
                              textAlign: 'center',
                              // whiteSpace: 'nowrap',
                            }}
                          >
                            {group.name}
                          </Typography>
                        </div>

                        <div style={{ height: '20%', paddingLeft: 10, paddingRight: 10 }}>
                          <Typography
                            style={{
                              fontSize: 10,
                              fontFamily: 'Poppins',
                              fontWeight: 600,
                              flexShrink: 0,
                              color: AppColor.textGray,
                            }}
                          >
                            {progressObj.mandatoryAnswerd}/{progressObj.mandatoryCount} Docs
                          </Typography>

                          <LinearProgress
                            variant="determinate"
                            value={(progressObj.mandatoryAnswerd / progressObj.mandatoryCount) * 100}
                            style={{
                              marginBottom: 10,
                            }}
                            className={
                              progressObj.mandatoryAnswerd === progressObj.mandatoryCount &&
                              progressObj.mandatoryAnswerd !== 0
                                ? classes.fileComplete
                                : (progressObj.mandatoryAnswerd / progressObj.mandatoryCount) * 100 > 50
                                ? classes.fileModarate
                                : progressObj.mandatoryAnswerd === 0 && progressObj.mandatoryCount === 0
                                ? classes.fileNone
                                : classes.fileWarning
                            }
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {groupList.map(
                (item: any, mainIndex: number) =>
                  activeGroup &&
                  item.name === activeGroup.name && (
                    <div style={{ margin: '30px 0 15px 0' }} key={item.id}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: 15,
                        }}
                      >
                        <div
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          {item.isLocked ? (
                             <Tooltip title="Block Locked">
                            <img
                              alt=""
                              src={lockBlue}
                              style={{
                                height: 16,
                                width: 16,
                                marginRight: 5,
                              }}
                            />
                            </Tooltip>
                          ) : null}

                          <Typography className={classes.heading}>{item.name}</Typography>
                        </div>
                        {hasPerm(PERMISSION.dms_edit) && (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button
                              onClick={(event) => {
                                downloadAllFiles(item.id);
                              }}
                              style={{ marginLeft: 'auto' }}
                              variant="contained"
                              startIcon={
                                <DownloadIcon
                                  style={{
                                    color: '#606378',
                                  }}
                                />
                              }
                            >
                              <SBText text="Download" style={{ color: '#606378', fontSize: 14, fontWeight: 600 }} />
                            </Button>

                            {item.allowDynamicQuestion && !item.isLocked && (
                              <Button
                                onClick={(event) => {
                                  setAddNewQuestionPopup(true);
                                }}
                                style={{ marginLeft: 10, backgroundColor: 'rgb(93,95,114)' }}
                                variant="contained"
                                startIcon={<AddIcon style={{ color: 'white' }} />}
                              >
                                <SBText
                                  text="Add Document"
                                  style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}
                                />
                              </Button>
                            )}
                          </div>
                        )}
                      </div>

                      <div style={{ width: '100%', border: '1px solid #DDDDDD' }}>
                        <div className={classes.headerParent}>
                          <div className={classes.headerSubParent}>{getTableHeader()}</div>
                        </div>
                        {item.questions.length > 0 ? (
                          <List component="nav" aria-labelledby="nested-list-subheader" className={classes.listParent}>
                            {getSortedQuestions(item.questions).map((question: any, index: number) => (
                              <>
                                <ListItem className={classes.documentListItem} key={question.id}>
                                  <div className={classes.listItem}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: tableHeader[0].width,
                                        flexWrap: 'wrap',
                                        gap: 4,
                                        flexDirection: 'row',
                                        alignItems:'center'
                                      }}
                                    >
                                      {question.isLocked || question.reviewStatus ? (
                                        <img
                                          alt=""
                                          src={question.isLocked ? lockBlue : unlockBlue}
                                          style={{
                                            height: 16,
                                            width: 16,
                                            marginRight: 5,
                                            marginTop: 3,
                                          }}
                                        />
                                      ) : null}

                                      
                                   <p className={classes.questionName}> {getQuestionText(question, item.questions)}
                                     <span>
                                      {!(
                                        hasPerm(PERMISSION.applications_provider_view_org) ||
                                        hasPerm(PERMISSION.applications_provider_view) ||
                                        hasPerm(PERMISSION.applications_provider_view_global)
                                      ) &&
                                        !question.mandatory && (
                                          <Chip
                                            style={{
                                              fontSize: 10,
                                              marginLeft: 5,
                                              height: 18,
                                              borderColor: '#e31d1d',
                                              color: '#e31d1d',
                                            }}
                                            variant="outlined"
                                            size="small"
                                            label="Optional"
                                          />
                                            )}
                                        </span>
                                        
                                        <span>
                                      {(
                                        hasPerm(PERMISSION.applications_provider_view_org) ||
                                        hasPerm(PERMISSION.applications_provider_view) ||
                                        hasPerm(PERMISSION.applications_provider_view_global)
                                      ) && question.answer &&  (
                                          <Chip
                                            style={{
                                              fontSize: 10,
                                              marginLeft: 5,
                                              height: 18,
                                              // borderColor: '#e31d1d',
                                              color: '#63667C',
                                            }}
                                            variant="outlined"
                                            size="small"
                                            label="Uploaded"
                                          />
                                            )}
                                          </span>
                                        </p>
                                    </div>

                                    <div
                                      style={{
                                        display: 'flex',
                                        width: tableHeader[1].width,
                                      }}
                                    >
                                      <SBText
                                        text={
                                          question.answer
                                            ? moment(question.answer.updatedAt).format('DD MMM YYYY HH:mm')
                                            : '-'
                                        }
                                        className={classes.itemText}
                                      />
                                    </div>

                                    <div
                                      style={{
                                        display: 'flex',
                                        width: tableHeader[2].width,
                                      }}
                                    >
                                      <SBText
                                        text={question.answer ? question.answer.createdBy.name : '-'}
                                        className={classes.itemText}
                                      />
                                    </div>
                                    {!(
                                      hasPerm(PERMISSION.applications_agent_view) ||
                                      hasPerm(PERMISSION.applications_agent_view_org) ||
                                      hasPerm(PERMISSION.applications_agent_view_own) ||
                                      hasPerm(PERMISSION.applications_agent_view_main_agency)
                                    ) && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          width: tableHeader[3].width,
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <Switch
                                          classes={{
                                            root: classes.main,
                                            switchBase: classes.switchBase,
                                            thumb: classes.thumb,
                                            track: classes.track,
                                            checked: classes.checked,
                                          }}
                                          checked={question.mandatory}
                                          onChange={() => handleMandatory(question.id, item.id)}
                                          name="checkedA"
                                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: tableHeader[isCsCaPresent ? 4 : 4 - 1].width,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {(hasPerm(PERMISSION.applications_provider_view_org) ||
                                        hasPerm(PERMISSION.applications_provider_view) ||
                                        hasPerm(PERMISSION.applications_provider_view_global)) && (
                                        <div
                                          style={{
                                            width: '100px',
                                            display: 'flex',
                                            height: 28,
                                            justifyContent: 'space-around',
                                          }}
                                        >
                                          <IconButton
                                            className={
                                              question && question.reviewStatus === null
                                                ? classes.aprrejBtn
                                                : question.reviewStatus === true
                                                ? classes.aprBtn
                                                : classes.aprrejBtn
                                            }
                                            onClick={() => handleOpenPopup('Approve', question, item.id)}
                                            disabled={!question.answer || question.reviewStatus === true}
                                          >
                                            <CheckIcon
                                              style={{
                                                color: '#606378',
                                                fontSize: 18,
                                                opacity: !question.answer ? 0.5 : 1,
                                              }}
                                            />
                                          </IconButton>
                                          <IconButton
                                            className={
                                              question.reviewStatus === null
                                                ? classes.aprrejBtn
                                                : question.reviewStatus === false
                                                ? classes.rejBtn
                                                : classes.aprrejBtn
                                            }
                                            onClick={() => handleOpenPopup('Reject', question, item.id)}
                                            disabled={!question.answer || question.reviewStatus === false}
                                          >
                                            <CloseIcon
                                              style={{
                                                color: '#606378',
                                                fontSize: 18,
                                                opacity: !question.answer ? 0.5 : 1,
                                              }}
                                            />
                                          </IconButton>
                                        </div>
                                      )}
                                      {(hasPerm(PERMISSION.applications_agent_view) ||
                                        hasPerm(PERMISSION.applications_agent_view_org)) && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            width: '100px',
                                            height: 28,
                                            padding: '3px 6px 3px 0px',
                                          }}
                                          className={
                                            question.reviewStatus === null
                                              ? classes.aprrejBtn
                                              : question.reviewStatus === true
                                              ? classes.aprBtn
                                              : classes.rejBtn
                                          }
                                        >
                                          {question.reviewStatus === true && (
                                            <CheckIcon
                                              style={{
                                                color: '#606378',
                                                fontSize: 18,
                                              }}
                                            />
                                          )}
                                          {question.reviewStatus === false && (
                                            <CloseIcon
                                              style={{
                                                color: '#606378',
                                                fontSize: 18,
                                              }}
                                            />
                                          )}
                                          <SBText
                                            text={
                                              question.reviewStatus === null && question.answer
                                                ? 'Uploaded'
                                                : question.reviewStatus === null
                                                ? 'Pending'
                                                : question.reviewStatus === true
                                                ? 'Approved'
                                                : 'Rejected'
                                            }
                                            className={classes.itemText}
                                            noWrap={false}
                                            display="inline"
                                            style={{ fontSize: 10 }}
                                          />
                                        </div>
                                      )}
                                      <IconButton
                                        onClick={() => handleReviewHistory(question.label, question.id)}
                                        disabled={!question.answer}
                                      >
                                        <ChatBubbleOutlineIcon
                                          style={{
                                            color: '#606378',
                                            fontSize: 18,
                                            opacity: !question.answer ? 0.5 : 1,
                                          }}
                                        />
                                      </IconButton>
                                    </div>

                                    {fileUploadProgress.hasOwnProperty(question.id) && (
                                      <div className="progress">
                                        <SBCircularProgress value={fileUploadProgress[question.id].progress} />
                                      </div>
                                    )}

                                    <div
                                      className={classes.actionContainer}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 'auto',
                                        marginRight: 10,
                                      }}
                                    >
                                      <Tooltip title="View">
                                        <IconButton
                                          onClick={() => viewFile(question.answer.id, question.answer.fileName)}
                                          disabled={!question.answer || fileUploadProgress.hasOwnProperty(question.id)}
                                          style={{
                                            opacity:
                                              !question.answer || fileUploadProgress.hasOwnProperty(question.id)
                                                ? 0.5
                                                : 1,
                                          }}
                                        >
                                          <Badge badgeContent="Beta" color="secondary">
                                            <Visibility />
                                          </Badge>
                                        </IconButton>
                                      </Tooltip>
                                      {hasPerm(PERMISSION.dms_edit) && (
                                        <Tooltip title="Download">
                                          <IconButton
                                            onClick={() => downloadFile(question.answer.id, question.answer.fileName)}
                                            disabled={
                                              !question.answer || fileUploadProgress.hasOwnProperty(question.id)
                                            }
                                            style={{
                                              opacity:
                                                !question.answer || fileUploadProgress.hasOwnProperty(question.id)
                                                  ? 0.5
                                                  : 1,
                                            }}
                                          >
                                            <DownloadIcon />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {hasPerm(PERMISSION.dms_edit) && (
                                        <Tooltip title="Upload">
                                          <IconButton
                                            onClick={async (event) => {
                                              setDMSQuestion(question);
                                              handleFileSelect(event, question);
                                            }}
                                            disabled={
                                              question.isLocked || fileUploadProgress.hasOwnProperty(question.id)
                                            }
                                          >
                                            <UploadIcon
                                              style={{
                                                color: '#606378',
                                                opacity:
                                                  question.isLocked || fileUploadProgress.hasOwnProperty(question.id)
                                                    ? 0.5
                                                    : 1,
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}

                                      <Tooltip title="File History">
                                        <IconButton
                                          onClick={() => handleClick(mainIndex, index)}
                                          disabled={!question.history || question.history.length === 0}
                                        >
                                          <HistoryIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </ListItem>
                                {question.history && question.history.length > 0 ? (
                                  <Collapse
                                    in={
                                      open.length > 0 && open[mainIndex] && open[mainIndex][index]
                                        ? open[mainIndex][index]
                                        : false
                                    }
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <List
                                      component="div"
                                      className={classes.listParent}
                                      aria-labelledby="nested-list-subheader"
                                    >
                                      {question.history.map((history: any, index: number) => (
                                        <ListItem style={{ padding: 0 }} key={history.id}>
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              alignItems: 'center',
                                              flex: 1,
                                              paddingRight: 0,
                                              paddingTop: 5,
                                              paddingBottom: 5,
                                              paddingLeft: 16,
                                              backgroundColor: '#fafafa',
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-around',
                                                alignItems: 'start',
                                                width: tableHeader[0].width,
                                              }}
                                            >
                                              <SBText
                                                text={history.fileName ? getFileName(history.fileName) : '-'}
                                                className={classes.subItemText}
                                                noWrap={false}
                                                display="inline"
                                              />
                                            </div>

                                            <div
                                              style={{
                                                display: 'flex',
                                                width: tableHeader[1].width,
                                              }}
                                            >
                                              <SBText
                                                text={
                                                  history.updatedAt
                                                    ? moment(history.updatedAt).format('DD MMM YYYY HH:mm')
                                                    : '-'
                                                }
                                                className={classes.subItemText}
                                              />
                                            </div>

                                            <div
                                              style={{
                                                display: 'flex',
                                                width: tableHeader[2].width,
                                              }}
                                            >
                                              <SBText
                                                text={history.createdBy ? history.createdBy.name : '-'}
                                                className={classes.subItemText}
                                              />
                                            </div>

                                            {!(
                                              hasPerm(PERMISSION.applications_agent_view) ||
                                              hasPerm(PERMISSION.applications_agent_view_org) ||
                                              hasPerm(PERMISSION.applications_agent_view_own) ||
                                              hasPerm(PERMISSION.applications_agent_view_main_agency)
                                            ) && (
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  width: tableHeader[3].width,
                                                }}
                                              ></div>
                                            )}

                                            <div
                                              style={{
                                                display: 'flex',
                                                width: tableHeader[isCsCaPresent ? 4 : 4 - 1].width,
                                              }}
                                            ></div>

                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginLeft: 'auto',
                                                marginRight: 10,
                                              }}
                                            >
                                              {hasPerm(PERMISSION.dms_edit) && (
                                                <Tooltip title="Download">
                                                  <Button
                                                    onClick={(event) =>
                                                      downloadVersionHistoryFile(
                                                        dmsCurrentAppDetails.id,
                                                        history.fileName
                                                      )
                                                    }
                                                    startIcon={<DownloadIcon />}
                                                  >
                                                    <SBText
                                                      text="Download"
                                                      style={{ color: '#606378', fontSize: 14, fontWeight: 600 }}
                                                    />
                                                  </Button>
                                                </Tooltip>
                                              )}
                                            </div>
                                          </div>
                                        </ListItem>
                                      ))}
                                    </List>
                                  </Collapse>
                                ) : null}
                              </>
                            ))}
                          </List>
                        ) : (
                          <div
                            style={{
                              height: 176,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <SBText
                              text={`There are no additional documents for this application`}
                              style={{ color: 'gray', fontSize: 14, fontWeight: 500 }}
                            />
                            <SBText
                              text={`You can add additional documents as required by using the “Add document" button`}
                              style={{ color: 'gray', fontSize: 14, fontWeight: 500 }}
                            />
                            {item.allowDynamicQuestion && (
                              <Button
                                onClick={(event) => {
                                  setAddNewQuestionPopup(true);
                                }}
                                style={{ backgroundColor: 'rgb(93,95,114)', marginTop: 16 }}
                                variant="contained"
                                startIcon={<AddIcon style={{ color: 'white' }} />}
                              >
                                <SBText
                                  text="Add Document"
                                  style={{ color: '#FFFFFF', fontSize: 14, fontWeight: 600 }}
                                />
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>
          ) : (
            <div
              style={{
                flex: 1,
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SBText text={'Select Application to view details'} style={{ color: 'grey', fontSize: 18 }} />
            </div>
          )}

          <ConfirmationDialog
            open={confirmDialog}
            onConfirm={() => onConfirmDelete()}
            onCancel={() => showConfirmDialog(false)}
            message={confirmDialogMessage}
            header={'Confirmation'}
          />
          {approveRejectPopup ? (
            <ApproveRejectPopup
              open={approveRejectPopup}
              onClose={() => setApproveRejectPopup(false)}
              Action={action}
              label={label}
              questionId={questionId}
              groupId={groupId}
              isVNTUser={hasPerm(PERMISSION.applications_provider_view_global)}
              onSearchText={onSearchText}
              onAppoveOrReject={onAppoveOrReject}
            />
          ) : null}
          {reviewHistoryPopup ? (
            <ReviewHistory
              open={reviewHistoryPopup}
              onClose={() => setReviewHistoryPopup(false)}
              Action={action}
              label={label}
              questionId={questionId}
              data={reviewHistory}
              isVNTUser={hasPerm(PERMISSION.applications_provider_view_global)}
              onSearchText={onSearchText}
              onAppoveOrReject={onAppoveOrReject}
              clearReviewHistory={clearReviewHistory}
            />
          ) : null}

          {addNewQuestionPopup ? (
            <AddNewQuestionPopup
              open={addNewQuestionPopup}
              onAdd={(obj) => {
                setAddNewQuestionPopup(false);
                addDocuments({ ...obj, groupId: activeGroup.id });
              }}
              onClose={() => setAddNewQuestionPopup(false)}
            />
          ) : null}
        </div>
      </Page>
      <FileViewer />
      {agentEmailBodyPopup ? (
        <AgentEmailBodyDialog
          open={agentEmailBodyPopup}
          applicationId = {currentSelectedApplicationId}
          onClose={() => ShowagentEmailBodyPopup(false)}
        />
      ) : null}
    </>
  );
};

const mapState = (state: RootState) => ({
  // i : state.inbox.
  // @ts-ignore
  dmsApplicationList: state.sbApplication.dmsApplicationList,
  // @ts-ignore
  dmsCurrentAppDetails: state.sbApplication.dmsCurrentAppDetails,
  // @ts-ignore
  dmsQuestion: state.sbApplication.dmsQuestion,
  reviewHistory: state.sbApplication.reviewHistory,
  reviewdocumentstatus: state.sbApplication.reviewdocumentstatus,
  groupList: getFormattedDMSApplicationList(state),
  fileUploadProgress: state.sbApplication.fileUploadProgress,
});

const mapDispatch = {
  getDMSApplicationList,
  getALlDMSApplicationFiles,
  downloadQuestionFile,
  downloadVersionHistoryFile,
  deleteQuestionFile,
  uploadQuestionFileFromDMS,
  setDMSQuestion,
  downloadAllApplicationFileByGroupId,
  downloadAllApplicationFile,
  downloadAll: downloadAllApplicationFile,
  resetDMSApplicationList,
  lockUnlockDMSFileQuestions,
  toggleMandatoryFlag,
  reviewDocument,
  getReviewHistroy,
  clearReviewHistory,
  viewQuestionFile,
  addDocuments,
};

const connector = connect(mapState, mapDispatch);
export default connector(DMS);
