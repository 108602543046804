import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';
import config from '../config';
import { RootState } from '../reducer';
import { ActionType } from '../reducer/types';
import { API_URL } from '../util/apiUrls';
import { PAGE_ROUTE } from '../util/pageRoutes';
import { SET_LOGIN_ERROR } from './appError';
import { get, postWithHeader, postWithoutHeader } from './common';
import { ON_LOADING_FINISHED, ON_LOADING_START } from './sessionActions';
import { removeLoggedInData, saveLoggedInData } from './util';
import { REDIRECT_TO } from './util/constant';

export const LOGIN_SUCCESSFULLY = 'LOGIN_SUCCESSFULLY';
export const LOGOUT = 'LOGOUT';
export const SET_PROVIDER_LIST = 'SET_PROVIDER_LIST';
export const SET_CHILD_AGENT_LIST = 'SET_CHILD_AGENT_LIST';
export const SET_FORGOT_PASSWORD_VIEW = 'SET_FORGOT_PASSWORD_VIEW';
export const SET_COMPANY_PIC = 'SET_COMPANY_PIC';
export const SET_PROVIDER_DETAILS = 'SET_PROVIDER_DETAILS';
export const SET_NETWORK_STATUS = 'SET_NETWORK_STATUS';

export const login = (body: any): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  dispatch({ type: ON_LOADING_START });
  try {
    const result = await postWithoutHeader(`${config.BASE_URL}/auth/login`, body, null);
    saveLoggedInData(result);
    dispatch(setLoginSuccessFully(result));

    if (result.user) {
      dispatch(setProviders(result.user.providers));
    }

    if (result && result.access_token) {
      const redirectTo = localStorage.getItem(REDIRECT_TO) || '/applications';
      localStorage.removeItem(REDIRECT_TO);
      window.location.href = redirectTo;
    }
    dispatch({ type: ON_LOADING_FINISHED });
  } catch (error) {
    dispatch({ type: ON_LOADING_FINISHED });
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 401) {
      dispatch({
        type: SET_LOGIN_ERROR,
        payload: 'Invalid username or password',
      });
      console.log('error in Login', axiosError.response.data);
    } else {
      dispatch({
        type: SET_LOGIN_ERROR,
        payload: 'Something Went wrong, Please try after some time ',
      });
    }
  }
};

export const getProviderDetails = (providerList: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    const result = await get(`${config.BASE_URL}/country/getProviderById/${providerList}`);
    dispatch({ type: SET_PROVIDER_DETAILS, payload: result });
    resolve(result);

    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const setProviders = (providers: any): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  // console.log("Login Success");
  dispatch({ type: SET_PROVIDER_DETAILS, payload: providers });
};

export const validateUserByUDID = (id: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    try {
      const result = await postWithoutHeader(API_URL.USER.VALIDATE, { id }, null);

      dispatch({ type: ON_LOADING_FINISHED });
      resolve({ success: result, error: null });
    } catch (error) {
      console.error(error);
      dispatch({ type: ON_LOADING_FINISHED });
      if (error.response?.data?.status === 404 || error.response?.data?.status === 400) {
        resolve({ success: null, error: error.response.data });
      } else {
        dispatch({
          type: SET_LOGIN_ERROR,
          payload: 'Something Went wrong, Please try after some time ',
        });
      }
    }
  });
};

export const changePasswordByUDID = (
  id: any,
  password: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getState) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    try {
      const result = await postWithoutHeader(API_URL.USER.CHANGE_PASSWORD, { id, password }, null);
      dispatch({ type: ON_LOADING_FINISHED });
    } catch (error) {
      console.log('Error >>>>', error.response);
      dispatch({ type: ON_LOADING_FINISHED });
      if (error.response && error.response.data && error.response.data.status === 404) {
        resolve({ success: null, error: error.response.data });
        console.log('error in Login', error.response.data);
      } else {
        dispatch({
          type: SET_LOGIN_ERROR,
          payload: 'Something Went wrong, Please try after some time ',
        });
      }
    }
  });
};

export const forgetPassword = (email: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getState
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });

    try {
      const result = await postWithoutHeader(API_URL.USER.FORGOT_PASSWORD, { email }, null);

      dispatch({ type: ON_LOADING_FINISHED });
      resolve({ success: result, error: null });
    } catch (error) {
      dispatch({ type: ON_LOADING_FINISHED });
      if (error.response && error.response.data && error.response.data.status === 404) {
        resolve({ success: null, error: error.response.data });
      } else {
        dispatch({
          type: SET_LOGIN_ERROR,
          payload: 'Something Went wrong, Please try after some time ',
        });
      }
    }
  });
};

export const getAllProviderList = (): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch,
  getstate
) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await get(API_URL.USER.FETCH_PROVIDERS(12));
    dispatch({
      type: SET_PROVIDER_LIST,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getAllSBTeamList = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getstate) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await get(API_URL.USER.FETCH_SB_TEAM(12));
    dispatch({
      type: SET_PROVIDER_LIST,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const getAllChildList = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch, getstate) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const result = await get(API_URL.USER.GET_CHILD_AGENTS(12));
    dispatch({
      type: SET_CHILD_AGENT_LIST,
      payload: result,
    });
    resolve(result);
    dispatch({ type: ON_LOADING_FINISHED });
  });
};

export const setLoginSuccessFully = (data: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  // console.log("Login Success");
  dispatch({
    type: LOGIN_SUCCESSFULLY,
    payload: data,
  });
};

export const setLogout = (): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  await removeLoggedInData();
  dispatch({
    type: LOGOUT,
    payload: true,
  });
};

export const setForgotPasswordView = (value: any): ThunkAction<void, RootState, unknown, ActionType> => async (
  dispatch
) => {
  // console.log("Login Success");
  dispatch({
    type: SET_FORGOT_PASSWORD_VIEW,
    payload: value,
  });
};

export const uploadProviderPictureProfile = (
  file: any,
  providerId: string
): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('providerId', providerId);
    try {
      const response = await postWithHeader(`${config.BASE_URL}/application/updateProviderCompanyPic`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          //console.log("loaded", loaded);
          //console.log("total", total);
          const percent = Math.floor((loaded * 100) / total);
        },
      });
      dispatch({ type: ON_LOADING_FINISHED });
      //console.log("file upload response",response);
      resolve(response);
    } catch (error) {
      dispatch({ type: ON_LOADING_FINISHED });
      //console.log("Error In Upload::", error);
      if (error && error.response) {
        reject(error.response.data);
      }
    }
  });
};

export const downloadUserProfile = (id: any): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  return new Promise(async (resolve: (x: any) => void, reject: (x: any) => void) => {
    dispatch({ type: ON_LOADING_START });
    //console.log("File Object ::::", fileAnswer);
    const result = await get(
      `${config.BASE_URL}/application/downloadProviderCompanyPic/${id}`,
      {
        responseType: 'blob',
      },
      dispatch
    );

    const arrayBuffer = await result.arrayBuffer();
    console.log(arrayBuffer.byteLength);
    const chars = new Uint8Array(arrayBuffer);
    let binary = '';
    for (var i = 0; i < chars.byteLength; i++) {
      binary += String.fromCharCode(chars[i]);
    }
    const blobdata = window.btoa(binary);
    dispatch({ type: SET_COMPANY_PIC, payload: blobdata });
    dispatch({ type: ON_LOADING_FINISHED });
    resolve(result);
  });
};

export const setNetworkStatus = (value: any): ThunkAction<void, RootState, unknown, ActionType> => async (dispatch) => {
  dispatch({
    type: SET_NETWORK_STATUS,
    payload: value,
  });
};
