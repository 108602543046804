import React, { useState } from "react";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginTop: "20px",
      padding: "0 10%",
      height: "150px",
      display: "flex",
      alignContent: "center",
      verticalAlign: "center",
    },
    finalConfirmation: {
      color: "rgba(18, 18, 18, 0.5);",
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: 400,
    },
  })
);

interface FinalProps {
  emailId: string;
}

const Final: React.FC<FinalProps> = ({ emailId }) => {
  const classes = useStyles();
  return (
    <>
      <div>
        <div className={classes.section}>
          <div className={classes.finalConfirmation}>
            Contract has been sent to {emailId.toString()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Final;
