import React, { useState } from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import { Theme, MenuItem, Select, InputBase } from "@material-ui/core";
import clsx from "clsx";
import SBText from "../SBText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 5,
      height: 45,
      backgroundColor: "#FCFBFB",
      textAlign: "left",
      overflow: "hidden",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    },
    placeHolder: { textAlign: "left", fontSize: 14, color: "#5C707C" },
    dropDown: {
      height: 45,
      overflow: "hidden",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      textAlign: "left",
      borderColor: "#3E4059",
      backgroundColor: "#FCFBFB",
      "&:focus": {
        borderColor: "#3E4059",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      textAlign: "left",
      color: "#736F6F",
    },
  })
);

type SBFilterDropdownProps = {
  className?: string;
  [x: string]: any;
  value: any;
  questionObj?: any;
  data: any;
  onChange: Function;
};
const SBFilterDropdown: React.FC<SBFilterDropdownProps> = ({
  className,
  value,
  data,
  onChange,
  ...rest
}) => {
  const SbInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "label + &": {},
      },
      input: {
        borderRadius: 5,
        backgroundColor: "#FCFBFB",
        border: "1px solid #3E4059",
        height: 30,
        textAlign: "left",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        // Use the system font instead of the default Roboto font.
        fontFamily: "Poppins",
        "&:focus": {
          borderRadius: 5,
          borderColor: "#3E4059",
          // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        letterSpacing: 0.75,
      },
    })
  )(InputBase);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // rest.onDataChange(event.target.value, questionObj);
    onChange(event.target.value);
  };

  return (
    <div className={className} style={{ width: "100%" }}>
      {rest.labelText ? (
        <SBText text={rest.labelText}  className={clsx(classes.label, rest.labeStyle)} />
      ) : null}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        // id="outlined-basic"
        variant="outlined"
        fullWidth
        displayEmpty
        value={value}
        input={<SbInput />}
        // disabled={
        //   // questionObj && questionObj.status && questionObj.status === "DISABLED"
        //   //   ? true
        //   //   : false
        // }
        // renderValue={(selected) => {
        //   if (!selected) {
        //     return (
        //       <SBText text={rest.placeholder} className={classes.placeHolder} />
        //     );
        //   }
        //
        //   return selected as string;
        // }}
        onChange={(event) => handleChange(event)}
      >
        {data.map((state: any, index: number) => (
          <MenuItem key={state.id} value={state.value}>
            {state.value}
            {/*<SBText text={state.value} />*/}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SBFilterDropdown;
