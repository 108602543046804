import React, { useState, useEffect } from "react";

import { withStyles } from "@material-ui/core/styles";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MomentUtils from "@date-io/moment";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginTop: "20px",
      padding: "0 10%",
    },
    sectionHeading: {
      color: "#5C5F74",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 700,
    },
    checkBoxLabel: {
      color: "#5C5F74",
    },
  })
);

const CustomColorCheckbox = withStyles({
  root: {
    color: "#D9D9D9",
    "&$checked": {
      color: "#1890FF",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface ContractDetailsProps {
  getDate: any;
  startDateProps: any;
  endDateProps: any;
}

const ContractDetails: React.FC<ContractDetailsProps> = ({
  getDate,
  startDateProps,
  endDateProps,
}) => {
  const classes = useStyles();

  const [selectedStartDate, setSelectedStartDate] = useState(startDateProps);
  const [selectedEndDate, setSelectedEndDate] = useState(endDateProps);
  const [isAutoRenewChecked, setIsAutoRenewChecked] = useState(false);

  const handleStartDateChange = (date: any) => {
    setSelectedStartDate(date._d);
    getDate(date._d, selectedEndDate);
  };

  const handleEndDateChange = (date: any) => {
    setSelectedEndDate(date._d);
    getDate(selectedStartDate, date._d);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAutoRenewChecked(event.target.checked);
  };

  return (
    <div>
      <div className={classes.section}>
        <div className={classes.sectionHeading}>Contract Start Date</div>
        <div>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk={true}
              variant="inline"
              format="DD/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label=""
              value={selectedStartDate}
              style={{ width: "100%" }}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.sectionHeading}>Contract End Date</div>
        <div>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk={true}
              variant="inline"
              format="DD/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label=""
              value={selectedEndDate}
              style={{ width: "100%" }}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          {/* <FormControlLabel
            classes={{ label: classes.checkBoxLabel }}
            control={
              <CustomColorCheckbox
                checked={isAutoRenewChecked}
                color="default"
                id="autoRenew"
                onChange={handleChange}
                inputProps={{ "aria-label": "checkbox with default color" }}
              />
            }
            label="Auto-Renew"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
