import { LPO_STATUS, LPOTableActionItem, LPO_ACTION_LINKS } from "../types";

export const getLPOTableActions = (status: LPO_STATUS) => {
  let actions: LPOTableActionItem[] = [];
  switch (status) {
    case LPO_STATUS.ENQUIRED:
      actions = [LPO_ACTION_LINKS.INVITE, LPO_ACTION_LINKS.REJECT];
      break;
    case LPO_STATUS.RECEIVED:
      actions = [LPO_ACTION_LINKS.APPROVE,LPO_ACTION_LINKS.REJECT ];
      break;
      case LPO_STATUS.INVITED:
        actions = [LPO_ACTION_LINKS.RESEND_INVITE];
        break;
  }
  return actions;
};
