export const LeadAction = {
  SET_LEADS_PAGINATED_DATA: "SET_LEADS_PAGINATED_DATA",
  SET_LEADS_MOBILE_DATA: "SET_LEADS_MOBILE_DATA",
  SET_LEADS_MODAL_API_STATUS: "SET_LEADS_MODAL_API_STATUS",
  SET_SELECTED_LEAD_DETAILS: "SET_SELECTED_LEAD_DETAILS",
  SET_LEAD_MODAL_DROPDOWENS_DATA: "SET_LEAD_MODAL_DROPDOWENS_DATA",
  SET_LEAD_STATUSES:"SET_LEAD_STATUSES",
  SET_ALL_LEAD_STATUSES:"SET_ALL_LEAD_STATUSES",
  SET_LEAD_STATUSE_TIMELINE:"SET_LEAD_STATUSE_TIMELINE",
  UPDATE_LEAD_STATUS:"UPDATE_LEAD_STATUS",
  SET_DESTINATION_COUNTRYS:"SET_DESTINATION_COUNTRYS"
};
