import { LEAD_STATUS, LeadTableActionItem, LEAD_ACTION_LINKS } from "../types";

export const getLeadsTableActions = (status: LEAD_STATUS) => {
  let actions: LeadTableActionItem[] = [];
  switch (status) {
    case LEAD_STATUS.QUALIFIED:
      actions = [LEAD_ACTION_LINKS.SUBMIT_TO_SB];
      break;
    case LEAD_STATUS.SUBMITTED_TO_SB:
      actions = [LEAD_ACTION_LINKS.VIEW_APPLICATION];
      break;
  }
  return actions;
};
