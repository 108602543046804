import { keys } from 'lodash';

const errorStringReplaceMap: { [k: string]: string} = {
  companyName: 'Company Name',
  companyPhone: 'Company Phone',
  companyEmail: 'Company Email',
  'firstKeyPerson.': 'Key Staff ',
  firstName: 'First Name',
  lastName: 'Last Name',
  jobTitle: 'Job Title'
};

export const convertErrorMessages = (errorMessages: string[]): string[] => {
  return errorMessages.map((msg: string) => {
    let updatedStr = msg;
    keys(errorStringReplaceMap).forEach((key) => {
      updatedStr = updatedStr.replace(key, errorStringReplaceMap[key] || '')
    });
    return updatedStr;
  });
}
