import { SvgIcon } from "@material-ui/core";
import React from "react";

export function UniversityIcon(props: any) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
      <path d="M7 10H4V17H7V10Z" fill="#5C5E74" />
      <path d="M13.5 10H10.5V17H13.5V10Z" fill="#5C5E74" />
      <path d="M22 19H2V22H22V19Z" fill="#5C5E74" />
      <path d="M20 10H17V17H20V10Z" fill="#5C5E74" />
      <path d="M12 1L2 6V8H22V6L12 1Z" fill="#5C5E74" />{" "}
    </SvgIcon>
  );
}
