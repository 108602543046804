import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, withStyles } from "@material-ui/styles";
import {
  Theme,
  DialogActions,
  Dialog,
  TextField,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SBButton from "../../../components/base/SBButton";
import AppColor from "../../../theme/AppColor";
import SBText from "../../../components/base/SBText";
import clsx from "clsx";
import SBSimpleFileSelector from "../../../components/base/SBSimpleFileSelector";
import { getApplicationIdInString } from "../../../util";
import SBFilterDropdown from "../../../components/base/SBFilterDropdown";
import moment from "moment";
import SBDate from "../../../components/base/SBDate";
import { getAnswerObj } from "../../../selector/applicationList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    cancelButton: {
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: "#FAFAFA",
      borderColor: AppColor.appBlue,
      borderWidth: 1,
      borderStyle: "solid",
      color: AppColor.appBlue,
    },
    requestButton: {
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 16,
      marginBottom: 10,
    },
    paper: { width: "450px", minHeight: 300 },
    checkButtonText: {
      marginLeft: 6,
      fontSize: 16,
      fontWeight: 600,
      color: AppColor.appBlue,
    },
    uncheckButton: { opacity: 0.5, height: 30, width: 30 },
    checkButton: { height: 30, width: 30 },
    checkedText: { color: "black" },
    arrow: {
      "&:before": {
        border: "0px solid #E6E8ED",
      },
      color: AppColor.appBlue,
    },
    tooltip: {
      backgroundColor: AppColor.appBlue,
      border: "0px solid #E6E8ED",
      color: "white",
      width: 120,
      textAlign: "center",
    },
    textAreaWidth: {
      border: "1px solid black",
      borderRadius: "5px",
      fontFamily: "Poppins",
    },
    label: {
      textAlign: "left",
      color: theme.palette.primary.light,
      display: "flex",
    },
    alertTitle: {
      fontFamily: "poppins",
      letterSpacing: 0.15,
      fontWeight: 600,
      backgroundColor: AppColor.appBlue,
      height: 50,
      paddingTop: 5,
      paddingBottom: 5,
    },
    mainTitle: {
      fontSize: 16,
      color: "white",
      fontWeight: 600,
    },
    subTitle: {
      fontSize: 12,
      color: "white",
    },
    dialogContentParent: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
  })
);

type DeferedApplicationDialogProps = {
  className?: string;
  open: boolean;
  onSubmit: (
    deferredTo: string,
    reason: string,
    remarks: string,
    date: string,
    applicationId: number
  ) => void;
  onClose: () => void;
  sbApplication?: any;
  currentApplicationDetails: any;
  dataObj?: any;
};
const DeferedApplicationDialog: React.FC<DeferedApplicationDialogProps> = ({
  open,
  sbApplication,
  currentApplicationDetails,
  // setOpen,
  onSubmit,
  onClose,
  dataObj,
}) => {
  const reasonList = [
    { id: 1, value: "Yes" },
    { id: 2, value: "No" },
  ];

  // console.log("dataObj", dataObj);

  const classes = useStyles();
  const [deferred, setDeferred] = useState("");
  const [visaDate, setVisaDate] = useState("");
  const [visaStatus, setVisaStatus] = useState("");
  const [comments, setComments] = useState("");

  const [deferredError, showDeferredError] = useState(false);
  const [visaStatusError, showVisaStatusError] = useState(false);
  const [commentsError, showCommentsError] = useState(false);
  const [dateError, showDateError] = useState(false);
  const [deferredErrorString, setDeferredErrorString] = useState(
    "Please select deferred to"
  );


  const getListOfMonths = () => {
    var currentObj = moment(dataObj.answers.intake, "MMM-YY");
    var startDate = currentObj.add(1, "months");
    var endDate = moment(startDate).add(2, "years");
    const allMonthsInPeriod = [];
    let index = 0;
    while (startDate.isBefore(endDate)) {
      const month = startDate.format("MMM-YY");
      if (
        month.startsWith("Jul") ||
        month.startsWith("Mar") ||
        month.startsWith("Nov")
      ) {
        index = index + 1;
        const obj = { id: index, value: month };
        allMonthsInPeriod.push(obj);
      }
      startDate = startDate.add(1, "month");
    }
    // console.log("allMonthsInPeriod", allMonthsInPeriod);
    return allMonthsInPeriod;
  };

  const getSubTitle = () => {
    if (currentApplicationDetails) {
      const obj: any = {};
      obj.answers = getAnswerObj(currentApplicationDetails.keyAnswers);
      obj.id = currentApplicationDetails.id;
      dataObj = obj;
    }
    let subTitle =
      dataObj && dataObj.id ? getApplicationIdInString(dataObj.id) : "";
    if (dataObj && dataObj.answers) {
      if (dataObj && dataObj.answers.name) {
        subTitle = subTitle + " | " + dataObj.answers.name;
      }

      if (dataObj && dataObj.answers.intake) {
        subTitle = subTitle + " | " + dataObj.answers.intake;
      }
    }
    return subTitle;
  };

  const handleClose = () => {
    setVisaStatus("");
    setVisaDate("");
    setComments("");
    setDeferred("");
    showCommentsError(false);
    showDeferredError(false);
    showVisaStatusError(false);
    showDateError(false);

    onClose();
  };

  const onSubmitAction = () => {
    if (!deferred) {
      showDeferredError(true);
      return;
    }

    const newDeferredDate = moment(deferred, "MMM-YY");
    const oldDeferredDate = moment(dataObj.answers.intake, "MMM-YY");
    // console.log("newDeferredDate", newDeferredDate);
    // console.log("oldDeferredDate", oldDeferredDate);
    // console.log("Difference", newDeferredDate.diff(oldDeferredDate));

    if (newDeferredDate.diff(oldDeferredDate) <= 0) {
      showDeferredError(true);
      setDeferredErrorString(
        "New deferred month should be greater than old month"
      );
      return;
    }

    if (!visaStatus) {
      showVisaStatusError(true);
      return;
    }

    if (visaStatus && visaStatus == "Yes") {
      if (!visaDate) {
        showDateError(true);
        return;
      }
    }

    //
    if (!comments) {
      // alert("Please enter your comments");
      showCommentsError(true);
      return;
    }

    onSubmit(deferred, visaStatus, comments, visaDate, dataObj.id);
    setVisaStatus("");
    setComments("");
    setDeferred("");
    showCommentsError(false);
    showDeferredError(false);
    showVisaStatusError(false);
    onClose();
  };

  const onChangeReason = (value: any) => {
    setVisaStatus(value);
    showVisaStatusError(false);
  };

  const onChangeDeferred = (value: any) => {
    setDeferred(value);
    showDeferredError(false);
  };

  const formattedDate = (date: Date) => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.alertTitle}>
          <SBText className={classes.mainTitle} text={"DEFER APPLICATION"} />
          <SBText className={classes.subTitle} text={getSubTitle()} />
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogContentParent}>
            <div style={{ marginTop: 7 }} />
            <div style={{ height: 90 }}>
              <SBFilterDropdown
                data={getListOfMonths()}
                value={deferred}
                labelText={"Defer To"}
                onChange={(value: any) => onChangeDeferred(value)}
              />
              {deferredError && deferredErrorString ? (
                <SBText
                  text={deferredErrorString}
                  style={{
                    fontSize: 12,
                    color: "red",
                  }}
                />
              ) : null}
            </div>

            <div style={{ marginTop: 7 }} />

            <SBFilterDropdown
              data={reasonList}
              value={visaStatus}
              labelText={"Visa Lodged"}
              onChange={(value: any) => onChangeReason(value)}
            />
            <SBText
              text={"Please select visa lodged status"}
              style={{
                fontSize: 12,
                color: !visaStatus && visaStatusError ? "red" : "white",
              }}
            />

            <div style={{ marginTop: 7 }} />
            <SBDate
              value={visaDate}
              questionObj={{
                status:
                  visaStatus && visaStatus == "Yes" ? "ENABLED" : "DISABLED",
              }}
              onDataChange={(date: string, obj: any) => setVisaDate(date)}
              labelText={"Lodgment Date"}
              disabled={visaStatus && visaStatus == "Yes" ? false : true}
              validationFromOther={{ minDate: new Date() }}
            />
            <SBText
              text={"Please enter visa lodgment date"}
              style={{
                fontSize: 12,
                color: !visaDate && dateError ? "red" : "white",
              }}
            />

            <SBText
              text={"Comments*"}
              className={classes.label}
              style={{ marginTop: 14 }}
            />
            <TextField
              className={classes.textAreaWidth}
              id="outlined-multiline-static"
              variant="outlined"
              onChange={(event) => setComments(event.target.value)}
              value={comments}
              name="refNum"
              multiline
              rows={4}
              fullWidth={true}
            />
            <SBText
              text={"Please enter comments"}
              style={{
                fontSize: 12,
                color: !comments && commentsError ? "red" : "white",
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <SBButton
            icon={false}
            text={"Cancel"}
            onClick={() => handleClose()}
            className={classes.requestButton}
          />
          <SBButton
            icon={false}
            text={"Confirm"}
            onClick={() => onSubmitAction()}
            className={classes.requestButton}
          />
          {/*<SBButton icon={false} text={"OK"} onClick={handleSubmit} />*/}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeferedApplicationDialog;
