import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Theme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import SBText from "../../../../components/base/SBText";
import Link from "@material-ui/core/Link";
import { tableStyles } from "../../../../styles/table";
import { useFetchAgentData } from "../../hooks/useFetchAgentData";
import AddAgentModal from "../AddAgentModal";
import { useFetchLegacyAgent } from "../../hooks/useFetchLegacyDetails";

const useStyles = makeStyles((theme: Theme) => createStyles(tableStyles(theme)));

interface Props {
  agents: any;
  totalAgents: number;
}

const LegacyAgentTable: React.FC<Props> = ({ agents, totalAgents }) => {
  const classes = useStyles();

  const { fetchLegacyData } = useFetchLegacyAgent();
  const [isAddAgentDialogVisible, setIsAddAgentDialogVisible] = React.useState(
    false
  );
  const [companyName, setCompanyName] = React.useState("");
  const [companyEmail, setCompanyEmail] = React.useState("");
  const [companyPhone, setCompanyPhone] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [userAgencyId, setUserAgencyId] = React.useState("-1");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(6);
  const [limit, setLimit] = useState(11);
  const [pageNo, setPageNo] = useState(0);
  const capitalizedCellClassName = `${classes.bodyText}  ${classes.capitalize}`;

  const handleInviteClick = (e: any, agentId: number | undefined) => {
    setIsAddAgentDialogVisible(true);
    const selectedAgentInfo = agents.filter((data: any) => data.id === agentId);

    // console.log("Selected Agent Info :::", selectedAgentInfo)
    if (selectedAgentInfo.length > 0) {
      setCompanyName(selectedAgentInfo[0].companyName || "");
      setCompanyEmail(selectedAgentInfo[0].email || "");
      setCompanyPhone(selectedAgentInfo[0].contact1 || "");
      setFirstName(selectedAgentInfo[0].name || "");
      setLastName(selectedAgentInfo[0].lastName || "");
      setUserAgencyId(""+selectedAgentInfo[0].id || "-1")
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage > pageNo) {
      setPageNo(newPage);
      setPage(newPage);
      if (offset <= totalAgents && limit <= totalAgents)
        fetchLegacyData(offset, limit);
      setOffset(offset + 5);
      setLimit(limit + 5);
    } else {
      setPage(newPage);
      setPageNo(newPage);
      let newOffset = offset - 5;
      let newLimit = limit - 5;
      if (newOffset >= 0 && newLimit >= 0) fetchLegacyData(offset, limit);
      setOffset(offset - 5);
      setLimit(limit - 5);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAgentModalClose = () => {
    setIsAddAgentDialogVisible(false);
  };

  return (
    <>
      <AddAgentModal
        open={isAddAgentDialogVisible}
        onClose={handleAgentModalClose}
        companyName={companyName}
        companyEmail={companyEmail}
        companyPhone={companyPhone}
        firstName={firstName}
        lastName={lastName}
        userAgencyId={userAgencyId}

      />
      <div className={classes.mainLayout}>
        <div className={classes.tableParents}>
          <TableContainer component={Paper}>
            <Table className={classes.table} stickyHeader>
              <TableHead className={classes.TableRow}>
                <TableRow>
                  <TableCell align="left">
                    <SBText
                      text="Company Name"
                      className={classes.HeaderPadding}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <SBText
                      text="Key Staff"
                      className={classes.HeaderPadding}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text="Email" className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text="Phone" className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text="Status" className={classes.HeaderPadding} />
                  </TableCell>
                  <TableCell align="left">
                    <SBText text="Actions" className={classes.HeaderPadding} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? agents.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : agents
                ).map((item: any) => (
                  <TableRow
                    key={item.id}
                    style={{
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th">
                      <SBText
                        text={item.companyName}
                        className={capitalizedCellClassName}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={`${item.firstName} ${item.middleName} ${item.lastName}`}
                        className={capitalizedCellClassName}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText text={item.email} className={classes.bodyText} />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={item.contact1}
                        className={capitalizedCellClassName}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <SBText
                        text={"Legacy"}
                        className={capitalizedCellClassName}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Link
                        component="button"
                        variant="body2"
                        onClick={(e: any) => handleInviteClick(e, item.id)}
                      >
                        Invite
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalAgents}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default LegacyAgentTable;
