import _ from "lodash";
import { ActionType } from "../../../../reducer/types";
import {
  DEFAULT_PAGE_SIZE,
  FIRST_PAGE,
  LEADS_API_IDEAL_STATUS,
} from "../../constants";
import {
  LeadModalAPIStatus,
  LeadModalDropdownsData,
  LeadPaginatedData,
} from "../../types";
import { LeadAction } from "../actions/action-types";

interface LeadsState {
  paginatedData: LeadPaginatedData;
  modalAPIStatus: LeadModalAPIStatus;
  modalDropdownsData: LeadModalDropdownsData;
  selectedLeadDetails: any;
  leadStatuses:any;
  selectedLeadStatusTimeline:any;
  allLeadStatuses:any;
  destinationCountrys:any;
}

const initialStateLeads: LeadsState = {
  paginatedData: {
    totalRecords: 0,
    currentPage: FIRST_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalPages: 1,
    data: [],
  },
  modalAPIStatus: LEADS_API_IDEAL_STATUS,
  modalDropdownsData: {
    applicantCountries: [],
    preferredCountries: [],
  },
  selectedLeadDetails: undefined,
  leadStatuses:[],
  allLeadStatuses:[],
  selectedLeadStatusTimeline: [],
  destinationCountrys:[]
};

const leadsReducer = (
  state = initialStateLeads,
  action: ActionType
): LeadsState => {
  switch (action.type) {
    case LeadAction.SET_LEADS_PAGINATED_DATA: {
      return {
        ...state,
        paginatedData: action.payload,
      };
    }
    case LeadAction.SET_LEADS_MOBILE_DATA: {
      const previousData = state.paginatedData.data;
      return {
        ...state,
        paginatedData: {...action.payload, data : previousData.concat(action.payload.data)}
      };
    }
    case LeadAction.SET_LEADS_MODAL_API_STATUS: {
      return {
        ...state,
        modalAPIStatus: action.payload,
      };
    }
    case LeadAction.SET_SELECTED_LEAD_DETAILS: {
      return {
        ...state,
        selectedLeadDetails: action.payload,
      };
    }
    case LeadAction.SET_LEAD_MODAL_DROPDOWENS_DATA: {
      return {
        ...state,
        modalDropdownsData: action.payload,
      };
    }
    case LeadAction.SET_LEAD_STATUSES: {
      return {
        ...state,
        leadStatuses: action.payload,
      };
    }
    case LeadAction.SET_ALL_LEAD_STATUSES: {
      return {
        ...state,
        allLeadStatuses: action.payload,
      };
    }
    case LeadAction.SET_LEAD_STATUSE_TIMELINE: {
      return {
        ...state,
        selectedLeadStatusTimeline: action.payload,
      };
    }
    case LeadAction.SET_DESTINATION_COUNTRYS: {
      return {
        ...state,
        destinationCountrys: action.payload,
      };
    }
    case LeadAction.UPDATE_LEAD_STATUS: {
      const tempObj: any = _.cloneDeep(state.paginatedData);
      const dataIndex = _.findIndex(tempObj.data, (item: any) => item.id === action.payload.leadId);
      if (dataIndex !== -1) {            
         tempObj.data[dataIndex].status = action.payload.status;
      }
      return {
        ...state,
        paginatedData: tempObj,
      };
    }
    default:
      return state;
  }
};

export default leadsReducer;
