import { ThunkAction } from "redux-thunk";
import { RootState } from "../reducer";
import { ActionType } from "../reducer/types";
import config from "../config";
import { ON_LOADING_FINISHED, ON_LOADING_START } from "./sessionActions";
import { get } from "./common";

export const SET_LPC_LIST = "SET_LPC_LIST";

export const getLPCList = (): ThunkAction<
  void,
  RootState,
  unknown,
  ActionType
> => async (dispatch) => {
  dispatch({ type: ON_LOADING_START });
  try {
    const result = await get(`${config.BASE_URL}/lpc`, dispatch);
    dispatch({ type: SET_LPC_LIST, payload: result });  
  } finally {
    dispatch({ type: ON_LOADING_FINISHED });
  }
};
