import { hasOneOfPermissions, PERMISSION } from '../../util/rolePermissionsUtil';
import { HistoryPaginatedData, ReportFilterMasterType, ReportFilterType, ReportFilterVisibilityType } from './types';
import { FetchDataParams } from './types/history-fetch-param.type';

export const PAGE_SIZES: number[] = [10, 20, 50];
export const DEFAULT_PAGINATED_DATA: HistoryPaginatedData = {
  totalRecords: 0,
  currentPage: 0,
  pageSize: PAGE_SIZES[0],
  totalPages: 1,
  data: [],
};
export const DEFAULT_FETCH_PARAMS: FetchDataParams = {
  offset: 0,
  limit: PAGE_SIZES[0],
  search: '',
};

export const SERVER_PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm A';

export const REPORT_LIST = [
  { type: 'OutcomeSummary', name: 'Outcome summary report' },
  { type: 'StatusSummary', name: 'Status summary report' },
  { type: 'StatusReport', name: 'Status report' },
  { type: 'ApplicationReport', name: 'Application report' },
  { type: 'MissingDocumentsReport', name: 'Missing Documents Report' },
  { type: 'POBySubStatusReport', name: 'Processing team report by Sub-status' },
  { type: 'CPHBySubStatusReport', name: 'CPH report by Sub-status' },
];

export const DEFAULT_REPORT_FILTER: ReportFilterType = {
  destCountry: '',
  destCity: '',
  srcCountry: '',
  srcCity: '',
  provider: '',
  intake: '',
  agency: '',
  cph: '',
  counsellor: '',
  startDate: '',
  endDate: '',
};

export const DEFAULT_FILTER_DATA: ReportFilterMasterType = {
  srcCountries: [],
  srcCities: [],
  destCountries: [],
  destCities: [],
  providers: [],
  intakes: [],
  cphs: [],
  agencies: [],
  counsellors: [],
};

export const DEFAULT_REPORT_FILTER_VISIBILITY: ReportFilterVisibilityType = {
  destCountry: true,
  destCity: false,
  srcCountry: true,
  srcCity: true,
  provider: true,
  intake: true,
  cph: true,
  agency: true,
  counsellor: true,
  startDate: true,
  endDate: true,
};

export const getFilterVisibility = (report: any, userPermissions: any): ReportFilterVisibilityType => {
  //by default all are visible e.g. VNT Users
  let visibility = { ...DEFAULT_REPORT_FILTER_VISIBILITY };

  if (hasOneOfPermissions([PERMISSION.applications_agent_view_own], userPermissions)) {
    //Counsellor
    visibility = {
      ...visibility,
      cph: false,
      agency: false,
      counsellor: false,
    };
  } else if (hasOneOfPermissions([PERMISSION.applications_agent_view_org], userPermissions)) {
    //Agency Owner
    visibility = { ...visibility, cph: false, agency: false };
  }

  //Hide CPH filter block for CPHBySubStatusReport
  if (report?.type == 'CPHBySubStatusReport') {
    visibility = {
      ...visibility,
      cph: false,
      agency: false,
      counsellor: false,
    };
  }
  return visibility;
};
