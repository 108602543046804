import React from 'react';
import { makeStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme, DialogActions, Dialog, DialogContent, DialogTitle, Tooltip, Typography, Link } from '@material-ui/core';
import AppColor from '../../theme/AppColor';
import { getApplicationIdInString } from '../../util';
import SBText from '../base/SBText';
import SBButton from '../base/SBButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },

    cancelButton: {
      paddingLeft: 16,
      paddingRight: 16,
      backgroundColor: '#FAFAFA',
      borderColor: AppColor.appBlue,
      borderWidth: 1,
      borderStyle: 'solid',
      color: AppColor.appBlue,
    },
    requestButton: {
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 16,
      marginBottom: 10,
    },
    paper: { width: '450px', minHeight: 300 },
    checkButtonText: {
      marginLeft: 6,
      fontSize: 16,
      fontWeight: 600,
      color: AppColor.appBlue,
    },
    uncheckButton: { opacity: 0.5, height: 30, width: 30 },
    checkButton: { height: 30, width: 30 },
    checkedText: { color: 'black' },
    arrow: {
      '&:before': {
        border: '0px solid #E6E8ED',
      },
      color: AppColor.appBlue,
    },
    tooltip: {
      backgroundColor: AppColor.appBlue,
      border: '0px solid #E6E8ED',
      color: 'white',
      width: 120,
    },
    textAreaWidth: {
      border: '1px solid black',
      borderRadius: '5px',
      fontFamily: 'Poppins',
    },
    label: {
      textAlign: 'left',
      color: theme.palette.primary.light,
      display: 'flex',
    },
    link: { textDecoration: 'underline' },
  })
);

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
type LockApplicationActionDialogProps = {
  className?: string;
  open: boolean;
  application: any;
  onClose: () => void;
  onDownload: (fileName: any) => void;
};
const RejectedDetailModel: React.FC<LockApplicationActionDialogProps> = ({
  open,
  application,
  // setOpen,
  onClose,
  onDownload,
}) => {
  const classes = useStyles();

  // console.log("Application Id ", application);

  const handleClose = () => {
    onClose();
    // setOpen(false);
    // onSubmit();
  };

  const getFileName = (file: any) => {
    if (file) {
      // @ts-ignore
      const fileName = file.split('#')[1];
      return fileName;
      return fileName;
    } else {
      return '';
    }
  };

  const getPreparedFileName = () => {
    if (application) {
      const fileName = `${getApplicationIdInString(application.id)}_rejected_proof`;
      return fileName;
    } else {
      return `rejected_proof`;
    }
  };

  // console.log("application In Dialog ", application);

  const getReasonText = () => {
    if (!application) {
      return 'Application Rejected';
    }

    if (!application.lockedDetails && application.reason) {
      return application.reason;
    } else {
      if (application.lockedDetails) {
        return application.lockedDetails.comments;
      } else {
        return 'Application Rejected';
      }
    }
  };

  const getSubStatus = () => {
    if (application.lockedDetails.actionTaken == 'Decline') {
      return `Your application ${getApplicationIdInString(
        application.id
      )} has been rejected, Your referred student is already exist in system`;
    } else {
      return `Your application ${getApplicationIdInString(
        application.id
      )} has been rejected and override with ${getApplicationIdInString(application.lockedDetails.applicationId)}`;
    }
  };

  const getRejectedBy = () => {
    if (application.lockedDetails.actionTakenUser) {
      return (
        application.lockedDetails.actionTakenUser.name + '(' + application.lockedDetails.actionTakenUser.email + ')'
      );
    } else {
      return '-';
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontFamily: 'poppins',
            letterSpacing: 0.15,
            fontWeight: 600,
          }}
        >
          APPLICATION REJECTION DETAILS !
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <SBText text={'Comments :'} />
            <SBText text={getReasonText()} style={{ fontSize: 12, color: AppColor.appBlue }} />

            {application && application.lockedDetails ? (
              <>
                <SBText
                  text={'Details :'}
                  style={{
                    marginTop: 10,
                  }}
                />
                <SBText
                  text={getSubStatus()}
                  style={{
                    fontSize: 12,
                    color: AppColor.appBlue,
                  }}
                />

                <SBText
                  text={'Rejected By :'}
                  style={{
                    marginTop: 10,
                  }}
                />
                <SBText
                  text={getRejectedBy()}
                  style={{
                    fontSize: 12,
                    color: AppColor.appBlue,
                  }}
                />

                {application.lockedDetails.key && application.lockedDetails.proofFileName ? (
                  <>
                    <SBText
                      text={'Rejected Details:'}
                      style={{
                        marginTop: 10,
                      }}
                    />

                    <Typography className={classes.link}>
                      <Link href="#" onClick={() => onDownload(application.lockedDetails.proofFileName)}>
                        {getFileName(application.lockedDetails.proofFileName)}
                      </Link>
                    </Typography>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <SBButton icon={false} text={'Ok'} onClick={() => handleClose()} className={classes.requestButton} />
          {/*<SBButton icon={false} text={"OK"} onClick={handleSubmit} />*/}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RejectedDetailModel;
