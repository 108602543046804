export enum NAV_MENU {
  DASHBOARD = "Dashboard",
  APPLICATIONS = "Application",
  COMMISSION = "Commission",
  CONTRACT = "Contract",
  VERIFICATION_INBOX = "Verification Inbox",
  DMS = "Files",
  REPORTS = "Reports",
  LPC = "Local Processing Councillors",
  TA = "TA",
  LPO = "LPO",
  LEADS = "Leads",
  AGENTS = "Agents",
  COMPANY_PROFILE = "Company Profile",
  USER_MANAGMENT = "User Management",
}

export enum ENTITY_TYPE {
  PROVIDER = "PROVIDER",
  AGENCY = "AGENCY",
  STARTBLOCKS = "STARTBLOCKS",
}

export const ENTITY_TYPE_NAMES: any = {
  [ENTITY_TYPE.PROVIDER]: "Provider",
  [ENTITY_TYPE.AGENCY]: "Agency",
  [ENTITY_TYPE.STARTBLOCKS]: "Startblocks",
};

export const ENTITY_TYPE_LIST = [
  { id: ENTITY_TYPE.PROVIDER, value: "Provider" },
  { id: ENTITY_TYPE.AGENCY, value: "Agency" },
  { id: ENTITY_TYPE.STARTBLOCKS, value: "Startblocks" },
];

export const ACCOUNT_ACTIVE_LIST = [
  { id: "active", value: "Active" },
  { id: "disabled", value: "Disabled" },
];

export const EMPTY_USER_LIST_FILTER = {
  entityType: null,
  provider: null,
  companyId: null,
};

export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/;
