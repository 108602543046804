import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, TextField } from '@material-ui/core';

import close from '../../../assets/images/close_model.png';
import SBText from '../../../components/base/SBText';
import SBButton from '../../../components/base/SBButton';
import SBSimpleFileSelector from '../../../components/base/SBSimpleFileSelector';

// import logo from "./assets/atmc_logo.gif";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    files: {
      marginTop: theme.spacing(3),
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      height: '50vh',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: 'white',
      // border: "2px solid #000",
      // boxShadow: theme.shadows[5],
      width: '30%',
      minWidth: 500,
      maxHeight: '90%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerParent: {
      height: 60,
      width: '100%',
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      justifyContent: 'space-between',
    },
    headerText: {
      color: theme.palette.primary.contrastText,
      fontSize: 24,
      fontWeight: 500,
    },
    body: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
    },
    subjectText: {
      fontSize: 14,
      fontWeight: 700,
    },
    bodyText: {
      fontSize: 12,
      fontWeight: 500,
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    rowText: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: 'Poppins',
      letterSpacing: 0.75,
    },
    tableView: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: '#000000',
    },
    tableRow: {
      borderBottomColor: '#000000',
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      height: 50,
      display: 'flex',
    },
    tableTextRow: {
      display: 'flex',
      flex: 0.6,
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableButtonRow: {
      display: 'flex',
      flex: 0.4,
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    tableSmallDivider: { width: 1, backgroundColor: '#000000' },
    approveBt: {
      backgroundColor: '#8BB19C',
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    disApproveBt: {
      backgroundColor: '#8BB19C',
      height: 38,
      borderRadius: 20,
      flex: 1,
    },

    greyBt: {
      backgroundColor: '#979797',
      height: 38,
      borderRadius: 20,
      flex: 1,
    },
    accept: {
      backgroundColor: '#8BB19C',
      height: 38,
      borderRadius: 20,
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
      maxWidth: 150,
      marginLeft: 16,
    },
    reject: {
      backgroundColor: '#ff4d4d',
      height: 38,
      borderRadius: 20,
      flex: 1,
      paddingLeft: 10,
      paddingRight: 10,
      maxWidth: 150,
      marginLeft: 16,
    },

    middleDivider: {
      width: theme.spacing(2),
    },
    label: {
      textAlign: 'left',
      color: theme.palette.primary.light,
    },
    commentsLabel: {
      textAlign: 'left',
      color: theme.palette.primary.light,
      marginTop: 16,
    },
    errorText: {
      color: '#ff4d4d',
      textAlign: 'left',
      marginTop: 6,
    },
    textAreaWidth: {
      width: '100%',
      border: '1px solid black',
      borderRadius: '5px',
    },
  })
);

type SVerificationResponseModelProps = {
  className?: string;
  open: boolean;
  onConfirm: (type: string, file: any, comments: string) => void;
  onCancel: () => void;
  value?: any;
};
const VerificationResponseModel: React.FC<SVerificationResponseModelProps> = ({ open, onConfirm, onCancel, value }) => {
  const classes = useStyles();
  const [comments, setComments] = React.useState('');
  const [selectedFile, setFile] = React.useState<undefined | any>(undefined);
  const [fileError, setFileError] = React.useState('');
  const [commentsError, setCommentsError] = React.useState('');

  const onAcceptReject = (eventType: string) => {
    if (!selectedFile) {
      setFileError('Please select file');
      return;
    }

    if (!comments || !comments.trim()) {
      setCommentsError('Please enter your comments');
      return;
    }

    console.log('Selected Files', selectedFile);
    onConfirm(eventType, selectedFile, comments);

    setComments('');
    setFile(undefined);
    setFileError('');
    setCommentsError('');
  };

  const handleClose = () => {
    onCancel();
    setComments('');
    setFile(undefined);
    setFileError('');
    setCommentsError('');
  };

  const handleChange = (e: any) => {
    setComments(e.target.value);
  };

  const getFileName = () => {
    if (selectedFile) {
      // @ts-ignore
      const fileName = selectedFile.name.split('#')[1];
      return fileName;
    } else {
      return '';
    }
  };

  const getPreparedFileName = () => {
    const fileName = `verification_event_by_po`;
    return fileName;
  };

  const getBody = (value: any) => {
    return (
      <div>
        <SBSimpleFileSelector
          setUploadError={() => console.log('Error')}
          onFileSelected={(fileObj: any) => setFile(fileObj)}
          onDeleteFile={() => setFile(undefined)}
          value={getFileName()}
          fileNameExtension={getPreparedFileName()}
          showDownload={false}
          accept="image/*, application/pdf"
        />

        {fileError && <SBText text={fileError} className={classes.errorText} />}
      </div>
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div>
        <div className={classes.paper}>
          <div className={classes.headerParent}>
            <SBText text={'Upload Verification Response'} className={classes.headerText} />

            <img src={close} onClick={onCancel} />
          </div>

          <div className={classes.body}>
            <SBText text={'Upload response as Image or PDF File'} className={classes.label} />
            {getBody(value)}

            <SBText text={'Comments'} className={classes.commentsLabel} />
            <TextField
              className={classes.textAreaWidth}
              id="outlined-multiline"
              multiline
              rows={4}
              variant="outlined"
              onChange={handleChange}
              value={comments}
              name="comment"
            />
            {commentsError && <SBText text={fileError} className={classes.errorText} />}
          </div>

          <div
            style={{
              textAlign: 'center',
              paddingBottom: 10,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
            }}
          >
            <SBButton
              icon={false}
              text={'Rejected'}
              onClick={() => onAcceptReject('Reject')}
              className={classes.reject}
            />

            <SBButton
              icon={false}
              text={'Accepted'}
              onClick={() => onAcceptReject('Accept')}
              className={classes.accept}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VerificationResponseModel;
