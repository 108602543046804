export type LEAD_TABLE_ACTION = "SUBMIT_TO_SB" | "VIEW_APPLICATION";

export interface LeadTableActionItem {
    label: string; 
    action: LEAD_TABLE_ACTION;
}

export type LeadTableActionLink = {
    [key in LEAD_TABLE_ACTION]: LeadTableActionItem;
  }

export const LEAD_ACTION_LINKS: LeadTableActionLink = {
  SUBMIT_TO_SB: {
    label: "Submit to SB",
    action: "SUBMIT_TO_SB",
  },
  VIEW_APPLICATION: {
    label: "View Application",
    action: "VIEW_APPLICATION",
  },
};
