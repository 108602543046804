import React from "react";
import LPOView from "./LPOView";


const LPO = () => {
  return (
    <LPOView></LPOView>
  );
};

export default LPO;
