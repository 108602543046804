import { CLOSE_FILE_VIEWER, VIEW_FILE } from '../actions';
import { ActionType } from './types';

type StateType = {
  fileUrl?: string;
  fileName?: string;
};

const initialState: StateType = {
  fileName: undefined,
  fileUrl: undefined,
};

const fileViewerReducer = (state: StateType = initialState, action: ActionType): StateType => {
  switch (action.type) {
    case VIEW_FILE:
      return {
        fileName: action.payload.fileName,
        fileUrl: action.payload.fileUrl,
      };
    case CLOSE_FILE_VIEWER:
    default:
      return {
        fileUrl: undefined,
        fileName: undefined,
      };
  }
};

export default fileViewerReducer;
