/* eslint-disable react/no-multi-comp */
// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { createStyles, makeStyles, withStyles } from '@material-ui/styles';
import { Theme, Grid, Tooltip, Button, Menu } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import InfoIcon from '@material-ui/icons/Info';
import {
  submitQuestionAnswer,
  updateSBValues,
  setLastQuestion,
  deleteQuestionFile,
  deleteFileLocally,
  downloadQuestionFile,
  getEmailTemplate,
  okToProceed,
  addMoreQuestion,
  setFileSelectionProps,
  setTabIndex,
  deleteTab,
  removeInvalidAnswerFromLastQuestion,
  getAcademicVerification,
  updateAcademicVerification,
  getAcademicVerificationWithCaptcha,
  lockUnlockQuestions,
  setLocalQuestionAnswer,
  updateLocalAnswerInCurrentApplicationAllAnswer,
  cancelVerification,
  okToProceedWithMultipleEmail,
} from '../../../actions';
import { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../reducer';
import SBTextField from '../../../components/base/SBTextField';
import SBEmailTemplates from '../../../components/SBEmailTemplates';
import SBText from '../../../components/base/SBText';
import SBDropdown from '../../../components/base/SBDropdown';
import SBDate from '../../../components/base/SBDate';
import SBFileSelector from '../../../components/base/SBFileSelector';
import SBButton from '../../../components/base/SBButton';
import SBMultiSelectDropdown from '../../../components/base/SBMultiSelectDropdown';
import _ from 'lodash';
import { QuestionType } from '../../../actions/util/QuestionType';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineRounded from '@material-ui/icons/DeleteOutlineRounded';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CommenErrorDialog from '../../../components/CommenErrorDialog';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import SBValidationErrorDialog from '../../../components/SBValidationErrorDialog';
import SBAutoVerificationTemplate from '../../../components/SBAutoVerificationTemplate';
import SBCapetchModel from '../../../components/SBCapetchModel';
import AppColor from '../../../theme/AppColor';
import lock from '../assets/lock.png';
import unlock from '../assets/unlock.png';
import { getApplicationIdInString } from '../../../util';
import { hasOneOfPermissions, PERMISSION } from '../../../util/rolePermissionsUtil';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import SBEmailWithTabsTemplates from '../../../components/SBEmailWithTabsTemplate';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    card: {
      // maxWidth: "80%",
      minWidth: '40%',
      height: 'inherit',
      // maxHeight: "90%",
      width: (props: any) => props.width,
      backgroundColor: 'white',
      borderRadius: 5,
      borderColor: '#727487',
      borderWidth: 1,
      borderStyle: 'solid',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      padding: 10,
      overflowY: 'auto',
      overflowX: 'hidden',
      [theme.breakpoints.up(1366)]: {
        padding: 15,
      },
      [theme.breakpoints.up(1440)]: {
        padding: theme.spacing(4),
      },
      maxHeight: 'calc(40vh - 50px)',
      '@media screen and ( min-height: 550px )': {
        maxHeight: 'calc(50vh - 50px)',
      },
    },
    contentWithTabs: {
      padding: 10,
      overflowY: 'auto',
      overflowX: 'hidden',
      [theme.breakpoints.up(1366)]: {
        padding: 15,
      },
      [theme.breakpoints.up(1440)]: {
        padding: theme.spacing(4),
      },
      maxHeight: 'calc(40vh - 50px - 80px)',
      '@media screen and ( min-height: 550px )': {
        maxHeight: 'calc(50vh - 50px - 80px)',
      },
    },
    inputValue: {
      fontSize: '12px !important',
      [theme.breakpoints.up(1366)]: {
        fontSize: '13px !important',
      },
      [theme.breakpoints.up(1440)]: {
        fontSize: '14px !important',
      },
    },
    cardHeader: {
      width: '100%',
      backgroundColor: theme.palette.primary.dark,
      minHeight: 45,
      paddingBottom: 5,
      paddingTop: 5,
      display: 'flex',
      paddingLeft: 10,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    input: {
      borderRadius: 2,
      height: 50,
      backgroundColor: '#FCFBFB',
      margin: theme.spacing(3),
      fontFamily: 'Roboto, sans-serif',
    },
    verificationButton: {
      borderRadius: 25,
      marginTop: 20,
      height: 50,
      paddingRight: 16,
      paddingLeft: 16,
      fontWeight: 600,
      fontSize: 13,
      [theme.breakpoints.up(1440)]: {
        fontSize: 14,
      },
    },

    verificationCancelButton: {
      marginTop: 20,
      height: 50,
      paddingRight: 16,
      paddingLeft: 16,
      minWidth: 200,
      fontWeight: 600,
      fontSize: 13,
      [theme.breakpoints.up(1440)]: {
        fontSize: 14,
      },
      marginLeft: 10,
      backgroundColor: '#c6826a',
    },
    disableVerificationButton: {
      opacity: 0.7,
      marginBottom: 10,
      marginTop: 20,
      height: 50,
      paddingRight: 16,
      paddingLeft: 16,
    },
    btParent: {
      width: '100%',
      alignSelf: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    cardHeaderText: {
      color: theme.palette.primary.contrastText,
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'left',
      [theme.breakpoints.up(1440)]: {
        fontSize: 18,
      },
    },
    moreInfoText: {
      fontSize: 13,
      [theme.breakpoints.up(1440)]: {
        fontSize: 14,
      },
    },
    popup: {
      position: 'relative',
      display: 'inlineBlock',
    },
    popuptext: {
      visibility: 'visible',
      width: '160px',
      backgroundColor: '#ECCC97',
      color: '#736F6F',
      textAlign: 'center',
      borderRadius: '9px',
      padding: '15px 8px',
      position: 'absolute',
      zIndex: 1,
      bottom: '74%',

      //left: "50%",
      //marginLeft: "-80px"
    },
    arrowdown: {
      width: 0,
      height: 0,
      top: '100%',
      marginLeft: '120px',
      position: 'absolute',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '20px solid #ECCC97',
    },
    hide: {
      visibility: 'hidden',
      animation: 'fadeIn 1s',
    },
    cardChildSubParent: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    cardChildMainParent: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      alignItems: 'flex-end',
    },
    selectedTab: {
      height: 35,
      width: 100,
      backgroundColor: '#8BB19C',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: 5,
      marginLeft: 3,
      [theme.breakpoints.up(1440)]: {
        height: 40,
      },
      marginTop: 5,
      marginBottom: 5,
      cursor: 'pointer',
    },
    selectedAddMoreTab: {
      height: 35,
      width: 130,
      backgroundColor: '#D27F65',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: 5,
      marginLeft: 3,
      [theme.breakpoints.up(1440)]: {
        height: 40,
      },
      marginTop: 5,
      marginBottom: 5,
      cursor: 'pointer',
    },

    deleteTab: {
      height: 35,
      width: 100,
      backgroundColor: '#d23232',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: 5,
      marginLeft: 3,
      [theme.breakpoints.up(1440)]: {
        height: 40,
        width: 130,
      },
      marginTop: 5,
      marginBottom: 5,
      cursor: 'pointer',
    },
    unSelectedAddMoreTab: {
      height: 35,
      width: 130,
      backgroundColor: '#D1D1D1',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: 5,
      marginLeft: 3,
      [theme.breakpoints.up(1440)]: {
        height: 40,
      },
      marginTop: 5,
      marginBottom: 5,
      cursor: 'pointer',
    },
    selectedAddMoreTabText: {
      color: 'white',
      fontWeight: 600,
      marginLeft: 10,
    },
    unSelectedAddMoreTabText: {
      color: 'grey',
      fontWeight: 500,
      marginLeft: 10,
    },
    unSelectedTab: {
      height: 35,
      width: 100,
      backgroundColor: '#D1D1D1',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      borderRadius: 5,
      marginLeft: 3,
      [theme.breakpoints.up(1440)]: {
        height: 40,
      },
      marginTop: 5,
      marginBottom: 5,
      cursor: 'pointer',
    },
    selectedTabText: {
      color: 'white',
      fontWeight: 600,
    },
    unSelectedTabText: {
      color: 'grey',
      fontWeight: 500,
    },
    tabParent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 5,
      paddingBottom: 5,
      marginTop: 16,
    },
    subTabParent: {
      display: 'flex',
      flexDirection: 'row',
      // flex: 1,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      flexWrap: 'wrap',
      width: '85%',
    },

    verificationButtonParent: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 10,
      width: '15%',
    },
    menuParent: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 16,
      paddingBottom: 16,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: AppColor.appLightGrey,
    },
    labelComp: {
      margin: 'unset',
      marginBottom: 5,
    },
    valueComp: {
      margin: 'unset',
      marginBottom: 10,
    },
    questionHeader: {
      fontSize: 16,
      marginLeft: 10,
      marginTop: 10,
      color: 'rgba(93,95,114,1)',
      [theme.breakpoints.up(1920)]: {
        fontSize: 18,
      },
    },
    questionLabel: {
      fontSize: 12,
      [theme.breakpoints.up(1920)]: {
        fontSize: 14,
      },
    },
    questionValue: {
      fontSize: 10,
      color: AppColor.appDarkGrey,
      [theme.breakpoints.up(1920)]: {
        fontSize: 12,
      },
    },
    questionHintInfo: {
      maxHeight: 300,
      [theme.breakpoints.up(1920)]: {
        maxHeight: 400,
      },
      width: 300,
      '& > ul': {
        paddingBottom: 0,
      },
    },
  })
);
const ITEM_HEIGHT = 100;
type PropsFromRedux = ConnectedProps<typeof connector>;

type DateCardProps = PropsFromRedux & {
  className?: string;
  parentDataObject: any;
  height: number;
  width: number;
  index: number;
  // initialState: any;
};

const SBTextFieldClasses = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      textAlign: 'left',
      color: theme.palette.primary.light,
      [theme.breakpoints.up(1366)]: {
        fontSize: '13px',
      },
      [theme.breakpoints.up(1440)]: {
        fontSize: '14px',
      },
      fontSize: '12px',
    },
  })
);

const MainCard: React.FC<DateCardProps> = ({
  className,
  parentDataObject,
  submitQuestionAnswer,
  sbApplication,
  // initialState,
  currentApplicationDetails,
  applicationLocalAnswer,
  setLastQuestion,
  height,
  width,
  index,
  deleteQuestionFile,
  deleteFileLocally,
  downloadQuestionFile,
  lastQuestion,
  getEmailTemplate,
  emailTemplate,
  okToProceed,
  addMoreQuestion,
  setTabIndex,
  tabIndex,
  deleteTab,
  removeInvalidAnswerFromLastQuestion,
  getAcademicVerification,
  updateAcademicVerification,
  getAcademicVerificationWithCaptcha,
  lockUnlockQuestions,
  setLocalQuestionAnswer,
  updateLocalAnswerInCurrentApplicationAllAnswer,
  cancelVerification,
  okToProceedWithMultipleEmail,
}) => {
  const classes = useStyles({
    width: '100%',
  });
  const [model, showModel] = useState<any>(false);
  const [errorOpen, setErrorOpen] = useState<any>(false);
  const [errorObj, setErrorObj] = useState<any>({ message: '', header: '' });
  const [confirmDialog, showConfirmDialog] = useState<any>(false);
  const [confirmDialogMessage, setConfirmationDialogMessage] = useState<any>('');
  const [confirmationType, setConfirmationType] = useState<any>(-1);
  const [confirmationObj, setConfirmationObj] = useState<any>(undefined);

  const [validationErrorOpen, setValidationErrorOpen] = useState<any>(false);
  const [validationError, setValidationError] = useState<any>('');
  const [validationDataObj, setValidationDataObj] = useState<any>('');

  // autoVerification Template
  const [autoVerificationModel, showAutoVerificationModel] = useState<any>(false);
  const [autoVerificationModelContent, setAutoVerificationModelContent] = useState<any>('');

  const [captchaModel, showCaptchaModel] = useState<any>(false);
  const [captchaImageContent, setCaptchaImageContent] = useState<any>('');

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const userPermissions: PERMISSION[] = useUserPermissions();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLockUnlockClick = async (event: React.MouseEvent<HTMLElement>) => {
    const currentQuestion = getCurrentQuestion();

    const body = {
      questionId: currentQuestion.id,
      lockType: 'All',
      lock: !currentQuestion.isLocked,
      applicationId: currentApplicationDetails.id,
    };
    lockUnlockQuestions(body, currentQuestion.groupId, currentQuestion.tabParentQuestionId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDropDownChange = async (selectedObj: any, questionObj?: any) => {
    const selectedValue = _.find(questionObj.options, (obj: any) => obj.id == selectedObj);
    if (questionObj.type == 'MultiSelect') {
      setLocalQuestionAnswer(questionObj.id, selectedObj, questionObj.type, currentApplicationDetails.id);
    } else if (questionObj.type == 'DropDown') {
      setLocalQuestionAnswer(questionObj.id, selectedObj, questionObj.type, currentApplicationDetails.id);
      updateLocalAnswerInCurrentApplicationAllAnswer(questionObj.id, selectedValue.value, questionObj.order);
    } else {
      let finalValue = '';
      if (questionObj.dateType == 'year') {
        finalValue = selectedObj.substring(0, 4);
      } else {
        finalValue = selectedObj;
      }
      setLocalQuestionAnswer(questionObj.id, finalValue, questionObj.type, currentApplicationDetails.id);
    }
  };

  const onFileSelected = async (file: any, questionObj: any) => {
    let questionString = '';
    if (questionObj.label) {
      questionString = questionObj.label;
    } else {
      questionString = questionObj.question;
    }
    await setLocalQuestionAnswer(questionObj.id, file, questionObj.type, currentApplicationDetails.id, questionString);
    submitQuestionAnswer(true);
  };

  const onTextChange = async (selectedObj: any, questionObj?: any) => {
    setLocalQuestionAnswer(questionObj.id, selectedObj, questionObj.type, currentApplicationDetails.id);
  };

  const onDeleteQuestionFile = async (questionObj: any) => {
    if (!questionObj.isLocked) {
      if (questionObj.id in applicationLocalAnswer) {
        // delete file locally
        deleteFileLocally(questionObj);
      } else if (questionObj.answer) {
        deleteQuestionFile(currentApplicationDetails.id, questionObj.answer.id);
      }
    }
  };

  const onDownloadFile = (questionObj: any) => {
    if (questionObj && questionObj.answer) {
      downloadQuestionFile(questionObj.answer, currentApplicationDetails.id);
    }
  };
  const up1920 = useMediaQuery((theme) => theme.breakpoints.up(1920));

  const getSingleLabelComponent = (question) => {
    return (
      <Grid container xs={12} spacing={(up1920 && 2) || 1} className={classes.labelComp}>
        <Grid item xs={12}>
          {getLabelComponent(question)}
        </Grid>
      </Grid>
    );
  };
  const getSingleValueComponent = (question) => {
    return (
      <Grid container xs={12} spacing={(up1920 && 2) || 1} className={classes.valueComp}>
        <Grid item xs={12}>
          {getComponentByType('large', question)}
        </Grid>
      </Grid>
    );
  };

  const getDoubleLabelComponent = (question1: any, question2: any) => {
    return (
      <Grid container xs={12} spacing={(up1920 && 2) || 1} className={classes.labelComp}>
        <Grid item xs={6}>
          {getLabelComponent(question1)}
        </Grid>
        <Grid item xs={6}>
          {getLabelComponent(question2)}
        </Grid>
      </Grid>
    );
  };

  const getDoubleValueComponent = (question1: any, question2: any) => {
    return (
      <Grid container spacing={(up1920 && 3) || 1} xs={12} className={classes.valueComp}>
        <Grid item xs={6}>
          {getComponentByType('large', question1)}
        </Grid>
        <Grid item xs={6}>
          {getComponentByType('large', question2)}
        </Grid>
      </Grid>
    );
  };

  const prepareAnswerUI = (dataObject: any) => {
    if (!dataObject.questionList) {
      const items: any = [];
      if (dataObject.type != 'Label') {
        items.push(getSingleLabelComponent(dataObject));
        items.push(getSingleValueComponent(dataObject));
      }
      return items;
    } else {
      const items: any = [];
      let groupQuestionList = [];
      if (dataObject.type != QuestionType.Label) {
        groupQuestionList.push(dataObject);
      }
      groupQuestionList = groupQuestionList.concat(dataObject.questionList);

      if (matches) {
        const itemHalfLength = groupQuestionList.length / 2;
        var before = parseInt(itemHalfLength.toString().split('.')[0], 10);
        var after = parseInt(itemHalfLength.toString().split('.')[1], 10); //after

        let counter = 0;
        let totalParentLength = before + (after > 0 ? 1 : 0);
        const newGroupArray = [];

        for (let j = 0; j < totalParentLength; j++) {
          // console.log("j:::", j);
          const twoArray = [];
          if (groupQuestionList[counter]) {
            twoArray.push(groupQuestionList[counter]);
            if (groupQuestionList[counter + 1]) {
              twoArray.push(groupQuestionList[counter + 1]);
            }
            counter = counter + 2;
          }
          newGroupArray.push(twoArray);
        }

        // console.log("newGroupArray", newGroupArray);
        let rowQuestions = [];
        for (let k = 0; k < newGroupArray.length; k++) {
          if (newGroupArray[k].length == 2) {
            items.push(getDoubleLabelComponent(newGroupArray[k][0], newGroupArray[k][1]));
            items.push(getDoubleValueComponent(newGroupArray[k][0], newGroupArray[k][1]));
          } else {
            rowQuestions.push(newGroupArray[k][0]);
            if (rowQuestions.length == 2) {
              items.push(getDoubleLabelComponent(rowQuestions[0], rowQuestions[1]));
              items.push(getDoubleValueComponent(rowQuestions[0], rowQuestions[1]));
              rowQuestions = [];
            }
          }
        }
        if (rowQuestions.length == 1) {
          items.push(getSingleLabelComponent(rowQuestions[0]));
          items.push(getSingleValueComponent(rowQuestions[0]));
        }
      } else {
        let rowQuestions = [];
        for (let k = 0; k < groupQuestionList.length; k++) {
          rowQuestions.push(groupQuestionList[k]);
          if (rowQuestions.length == 2) {
            items.push(getDoubleLabelComponent(rowQuestions[0], rowQuestions[1]));
            items.push(getDoubleValueComponent(rowQuestions[0], rowQuestions[1]));
            rowQuestions = [];
          }
        }
        if (rowQuestions.length == 1) {
          items.push(getSingleLabelComponent(rowQuestions[0]));
          items.push(getSingleValueComponent(rowQuestions[0]));
        }
      }
      return items;
    }
  };

  const getListFromLocalAnswer = (answer: any) => {
    const answerArray = [];
    for (let i = 0; i < answer.length; i++) {
      answerArray.push(answer[i].value);
    }
    return answerArray;
  };

  const getListFromServerAnswer = (questionObj: any) => {
    const answerArray = [];
    for (let i = 0; i < questionObj.answer.multiSelectedOptions.length; i++) {
      const object = _.find(questionObj.options, function (o) {
        return o.id == questionObj.answer.multiSelectedOptions[i].optionId;
      });
      answerArray.push(object.value);
    }
    return answerArray;
  };

  const formattedDate = (date: Date, dateType: string = null) => {
    if (!dateType || dateType == 'date') {
      return moment(date).format('YYYY-MM-DD');
    } else if (dateType == 'year') {
      return moment(date).format('YYYY');
    } else {
      return moment(date).format('MM');
    }
  };

  const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const validateInput = (validationType: string, text: string) => {
    let message = '';
    if (validationType === 'Email' && !validateEmail(text)) {
      message = `Your entered email id "${text}" is not valid, Please enter valid email id`;
    }
    return message;
  };

  const onTextBlur = (event: any, dataObject: any) => {
    if (dataObject.validation && event.target.value) {
      let message = validateInput(dataObject.validation, event.target.value);
      if (message) {
        setValidationDataObj(dataObject);
        setValidationError(message);
        setValidationErrorOpen(true);
        return;
      }
    }

    // if (lastQuestion && lastQuestion.length > 0) {
    //   submitQuestionAnswer();
    // }
  };

  const clickVerification = async (dataObject: any) => {
    if (isVerificationStatusInProgress(dataObject)) {
      // console.log("Your Verification is in Progress....");
    } else {
      if (!_.isEmpty(applicationLocalAnswer)) {
        await submitQuestionAnswer();
      }
      if (dataObject.verificationRequired == 'Auto') {
        const result: any = await getAcademicVerification(dataObject.id);

        // console.log("Result >>>>>>>>>>>>", result);

        if (result.image) {
          setCaptchaImageContent(result);
          showCaptchaModel(true);
        }

        if (result.html) {
          setAutoVerificationModelContent(result);
          showAutoVerificationModel(true);
        }
      } else {
        const allIDs = [parentDataObject.id];
        if (parentDataObject.tabs && parentDataObject.tabs.length > 0) {
          for (let i = 0; i < parentDataObject.tabs.length; i++) {
            allIDs.push(parentDataObject.tabs[i].id);
          }
        }
        await getEmailTemplate(dataObject.id, -1, allIDs);
        // prepareModal(emailTemplate, dataObject);
        showModel(true);
      }
    }
  };

  const okToProceedVerification = async () => {
    const allIDs = [parentDataObject.id];
    if (parentDataObject.tabs && parentDataObject.tabs.length > 0) {
      for (let i = 0; i < parentDataObject.tabs.length; i++) {
        allIDs.push(parentDataObject.tabs[i].id);
      }
    }
    okToProceedWithMultipleEmail(allIDs);
    showModel(false);
  };

  const getFileName = (dataObject: any) => {
    let filename =
      dataObject.status && dataObject.status === 'DISABLED'
        ? ''
        : dataObject.id in applicationLocalAnswer
        ? applicationLocalAnswer[dataObject.id].answer.name
        : dataObject.answer
        ? dataObject.answer.fileName
        : '';

    if (filename.includes('#')) {
      filename = filename.split('#')[1];
    }
    return filename;
  };

  const prepareModal = (emailTemplate: any, dataObject: any) => {
    // if(dataObject.verificationRequired){
    //  getEmailTemplate(dataObject)
    // }
    return (
      <SBEmailWithTabsTemplates
        open={model}
        onConfirm={() => okToProceedVerification(dataObject)}
        onCancel={() => showModel(false)}
        showButton={true}
        value={emailTemplate}
      />
    );
  };

  const isQuestionDisabled = (question) => {
    if (question == 'Passport Number') {
      return true;
    } else {
      return false;
    }
  };

  const sbTextFieldClasses = SBTextFieldClasses();
  const getLabelComponent = (dataObject: any) => {
    return <SBText text={dataObject.question} className={sbTextFieldClasses.label} />;
  };

  const getComponentByType = (boxType: string, dataObject: any) => {
    //console.log("Parent Id  check", dataObject);
    if (dataObject.type === 'DropDown') {
      // console.log("dataObject Dropdown", dataObject);
      return (
        <SBDropdown
          // placeholder={dataObject.information}
          onDataChange={onDropDownChange}
          options={dataObject.options}
          className={classes.inputValue}
          // labelText={dataObject.question}
          styles={SBTextFieldClasses}
          value={
            dataObject.status && dataObject.status === 'DISABLED'
              ? ''
              : dataObject.id in applicationLocalAnswer
              ? applicationLocalAnswer[dataObject.id].answer
              : dataObject.answer
              ? dataObject.answer.optionId
              : ''
          }
          questionObj={dataObject}
          disabled={
            !sbApplication[sbApplication.activeGroup].isActive || dataObject.isLocked || dataObject.isVerificationLock
          }
          country={getApplicationDestinationCountry()}
        />
      );
    }

    if (dataObject.type === QuestionType.MultiSelect) {
      return (
        <SBMultiSelectDropdown
          // placeholder={dataObject.information}
          questionObj={dataObject}
          options={dataObject.options}
          // labelText={dataObject.question}
          styles={SBTextFieldClasses}
          value={
            dataObject.status && dataObject.status === 'DISABLED'
              ? []
              : dataObject.id in applicationLocalAnswer
              ? getListFromLocalAnswer(applicationLocalAnswer[dataObject.id].answer)
              : dataObject.answer &&
                dataObject.answer.multiSelectedOptions &&
                dataObject.answer.multiSelectedOptions.length
              ? getListFromServerAnswer(dataObject)
              : []
          }
          onDataChange={onDropDownChange}
          disabled={
            !sbApplication[sbApplication.activeGroup].isActive || dataObject.isLocked || dataObject.isVerificationLock
          }
        />
      );
    }

    if (dataObject.type === QuestionType.TextField) {
      return (
        <SBTextField
          // placeholder={question.placeholder}
          name={dataObject.question}
          onDataChange={(text) => onTextChange(text, dataObject)}
          // labelText={dataObject.question}
          questionObj={dataObject}
          styles={SBTextFieldClasses}
          className={classes.inputValue}
          value={
            dataObject.status && dataObject.status === 'DISABLED'
              ? ''
              : dataObject.id in applicationLocalAnswer
              ? applicationLocalAnswer[dataObject.id].answer
              : dataObject.answer
              ? dataObject.answer.answer
              : ''
          }
          disabled={
            (dataObject.status && dataObject.status === 'DISABLED') ||
            isQuestionDisabled(dataObject.question) ||
            !sbApplication[sbApplication.activeGroup].isActive ||
            dataObject.isLocked ||
            dataObject.isVerificationLock
              ? true
              : false
          }
          onBlur={(event: any) => onTextBlur(event, dataObject)}
          inputProps={{ maxLength: 200 }}
        />
      );
    }

    if (dataObject.type === QuestionType.DatePicker) {
      return (
        <SBDate
          // labelText={dataObject.question}
          onDataChange={onDropDownChange}
          styles={SBTextFieldClasses}
          className={classes.inputValue}
          value={
            dataObject.status && dataObject.status === 'DISABLED'
              ? ''
              : dataObject.id in applicationLocalAnswer
              ? formattedDate(applicationLocalAnswer[dataObject.id].answer, dataObject.dateType)
              : dataObject.answer
              ? formattedDate(dataObject.answer.dateAnswer, dataObject.dateType)
              : ''
          }
          questionObj={dataObject}
          disabled={!sbApplication[sbApplication.activeGroup].isActive || dataObject.isVerificationLock}
        />
      );
    }
    if (dataObject.type === QuestionType.FileUpload) {
      return (
        <SBFileSelector
          onUploadFile={(file) => console.log('File Selected', file)}
          setUploadError={() => console.log('File Upload')}
          fileLoadPercentage={12}
          onFileSelected={onFileSelected}
          onDeleteFile={(obj) => onDeleteQuestionFile(obj)}
          styles={SBTextFieldClasses}
          size={boxType}
          // labelText={dataObject.question}
          parentId={dataObject.parentId}
          questionObj={dataObject}
          value={getFileName(dataObject)}
          onDownloadFile={(obj: any) => onDownloadFile(obj)}
          applicationId={getApplicationIdInString(currentApplicationDetails.id)}
          disabled={!sbApplication[sbApplication.activeGroup].isActive || dataObject.isVerificationLock}
        />
      );
    }

    if (dataObject.type === QuestionType.Label) {
      return <SBText text={dataObject.question} style={{ fontSize: 18, textAlign: 'left', fontWeight: 600 }} />;
    }
  };
  var isStateVarOpen = true;

  const [timeLeft, setTimeLeft] = useState(isStateVarOpen);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft((isStateVarOpen = false));
    }, 5000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const isVerificationStatusInProgress = (dataObject: any) => {
    if (
      dataObject.verificationStatus &&
      dataObject.verificationStatus.status &&
      dataObject.verificationStatus.status == 'InProgress'
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getVerificationText = (dataObject: any) => {
    if (dataObject.verificationStatus && dataObject.verificationStatus.status) {
      if (dataObject.verificationStatus.status == 'InProgress') {
        return 'Verification In Progress';
      }

      if (dataObject.verificationStatus.status == 'Completed') {
        return 'Send For Re-Verification';
      }

      return 'Send All For Verification';
    } else {
      return 'Send All For Verification';
    }
  };

  const getAcademicVerificationText = (dataObject: any) => {
    if (dataObject.verificationStatus && dataObject.verificationStatus.status) {
      if (dataObject.verificationStatus.status == 'InProgress') {
        return 'Verification In Progress';
      }

      if (dataObject.verificationStatus.status == 'Completed') {
        return 'Send For Re-Verification';
      }

      return 'Send For Verification';
    } else {
      return 'Send For Verification';
    }
  };

  const onAddMorePress = (dataObject: any) => {
    addMoreQuestion(dataObject.id, currentApplicationDetails.id);
  };

  const onTabPress = (question: any, index: any) => {
    setTabIndex(parentDataObject.id, index, true);
  };

  const getTabTextByVerificationGroup = (verificationGroup: string, index: number) => {
    let name;
    if (!verificationGroup) {
      name = 'Ref';
      if (index > 0) {
        name = name + '-' + index;
      } else {
        name = name + '-1';
      }
    }

    if (verificationGroup === 'Savings Verification') {
      name = 'SA';
      if (index > 0) {
        name = name + '-' + index;
      } else {
        name = name + '-1';
      }
    }

    if (verificationGroup === 'FD Verification') {
      name = 'FD';
      if (index > 0) {
        name = name + '-' + index;
      } else {
        name = name + '-1';
      }
    }

    if (verificationGroup === 'Edu Loan Verification') {
      name = 'EL';
      if (index > 0) {
        name = name + '-' + index;
      } else {
        name = name + '-1';
      }
    }

    if (verificationGroup === 'Work Exp') {
      name = 'WE';
      if (index > 0) {
        name = name + '-' + index;
      } else {
        name = name + '-1';
      }
    }

    return name;
  };

  const onValidationErrorClose = (dataObject: any) => {
    setValidationErrorOpen(false);
    // setValidationError("");
    removeInvalidAnswerFromLastQuestion(dataObject.id);
  };

  const getApplicationDestinationCountry = () => {
    let country = null;
    if (sbApplication && sbApplication.PreQuestions) {
      const questions = sbApplication.PreQuestions.questions;
      const countyObject = _.find(questions, (obj: any) => obj.key == 'Q_DESTCOUNTRY');
      if (countyObject && countyObject.answer) {
        const optionId = countyObject.answer.optionId;

        const options = _.find(countyObject.options, (obj: any) => obj.id == optionId);

        if (options) {
          country = options.value;
        }
      }
    }

    if (!country && currentApplicationDetails && currentApplicationDetails.keyAnswers) {
      const countyObject = _.find(currentApplicationDetails.keyAnswers, (obj: any) => obj.key == 'Q_DESTCOUNTRY');
      if (countyObject) {
        country = countyObject.value;
      }
    }
    return country;
  };

  const renderTabs = (dataObject: any) => {
    const currentTab = getCurrentTabIndex();
    const tabs = _.sortBy(dataObject.tabs, [
      function (o) {
        return o.tabIndex;
      },
    ]);

    return (
      <div className={classes.tabParent}>
        <div className={classes.subTabParent}>
          <div
            className={currentTab == 0 ? classes.selectedTab : classes.unSelectedTab}
            onClick={() => onTabPress(dataObject, 0)}
          >
            <SBText
              text={getTabTextByVerificationGroup(dataObject.verificationGroup, 0)}
              className={currentTab == 0 ? classes.selectedTabText : classes.unSelectedTabText}
            />
          </div>

          {tabs &&
            tabs.map((obj: any, index: number) => (
              <div
                className={index + 1 == currentTab ? classes.selectedTab : classes.unSelectedTab}
                onClick={() => onTabPress(obj, index + 1)}
              >
                <SBText
                  text={getTabTextByVerificationGroup(obj.verificationGroup, index + 2)}
                  className={index + 1 == currentTab ? classes.selectedTabText : classes.unSelectedTabText}
                />
              </div>
            ))}

          <div
            className={
              parentDataObject.status === 'DISABLED' || isVerificationStatusInProgress(dataObject)
                ? classes.unSelectedAddMoreTab
                : classes.selectedAddMoreTab
            }
            onClick={() => {
              if (parentDataObject.status !== 'DISABLED' && !isVerificationStatusInProgress(dataObject)) {
                if (parentDataObject.tabs) {
                  if (!isVerificationStatusInProgress(dataObject)) {
                    onAddMorePress(dataObject);
                  } else {
                  }
                } else {
                  const error = {
                    message: 'Maximum 3 accounts allowed',
                    header: 'Error',
                  };
                  setErrorObj(error);

                  setErrorOpen(true);
                }
              }
            }}
          >
            <AddCircleOutlineIcon
              style={{
                fill: '#FFFFFF',
              }}
            />
            <SBText text={'Add More'} className={classes.selectedAddMoreTabText} disabled />
          </div>

          {parentDataObject.tabs && parentDataObject.tabs.length > 0 && !isVerificationStatusInProgress(dataObject) ? (
            <div
              className={classes.deleteTab}
              onClick={() => {
                if (getCurrentTabIndex() > 0) {
                  setConfirmationDialogMessage('Are you sure you want to delete this account ?');
                  setConfirmationType(1);
                  showConfirmDialog(true);
                } else {
                  const error = {
                    message: 'You can not delete parent question',
                    header: 'Error',
                  };
                  setErrorObj(error);
                  setErrorOpen(true);
                }
              }}
            >
              <DeleteOutlineRounded
                style={{
                  fill: '#FFFFFF',
                }}
              />
              <SBText text={'Delete'} className={classes.selectedAddMoreTabText} />
            </div>
          ) : null}
        </div>

        <div className={classes.verificationButtonParent}>
          <SBButton
            text={getVerificationText(dataObject)}
            onClick={() => clickVerification(dataObject)}
            className={
              isVerificationStatusInProgress(dataObject)
                ? classes.disableVerificationButton
                : classes.verificationButton
            }
          />
        </div>
      </div>
    );
  };

  const onConfirm = () => {
    showConfirmDialog(false);
    if (confirmationType === 1) {
      const tabParentId = parentDataObject.id;
      const currentTabIndex = getCurrentTabIndex() - 1;
      const tabIndex = parentDataObject.tabs[currentTabIndex].tabIndex;
      deleteTab(tabParentId, tabIndex);
    } else if (confirmationType === 2) {
      cancelVerification(confirmationObj);
    }
    setConfirmationType(-1);
    setConfirmationDialogMessage('');
    setConfirmationObj(undefined);
  };

  const getCurrentTabIndex = () => {
    let tabLocateIndex = 0;
    let currentTabIndex = _.findIndex(tabIndex, (obj: any) => parentDataObject.id in obj);
    if (currentTabIndex == -1) {
      tabLocateIndex = 0;
      // currentTabIndex = 0;
    } else {
      tabLocateIndex = tabIndex[currentTabIndex][parentDataObject.id];
    }

    return tabLocateIndex;
  };

  const onApprovedRejectAutoVerification = (body: any) => {
    showAutoVerificationModel(false);
    updateAcademicVerification(body);
  };

  const onCaptchaEntered = async (dataObject: any, verificationResult: any, captcha: any) => {
    // console.log("verificationResult", verificationResult);

    // {rollNo: "3020225683", image: "iVBORw0KGgoAAAANSUhEUgAAAyAAAAJYCAYAAACadoJwAAAAAX…bAQEAADYCAgAAbAQEAADYBHdl7sWTI0XCAAAAAElFTkSuQmCC", ws: "ws://127.0.0.1:55552/devtools/browser/93c450d3-ad7a-4e2a-9137-d069ed0c5ce0", captcha: true, board: "HSEB", …}
    // board: "HSEB"
    // captcha: true
    // image: "iVBORw0KGgoAAAANSUhEUgAAAyAAAAJYCAYAAACadoJwAAAAAX"
    // passingYear: "2020"
    // rollNo: "3020225683"
    // ws: "ws://127.0.0.1:55552/devtools/browser/93c450d3-ad7a-4e2a-9137-d069ed0c5ce0"
    showCaptchaModel(false);
    const body = {
      rollNo: verificationResult.rollNo,
      ws: verificationResult.ws,
      board: verificationResult.board,
      passingYear: verificationResult.passingYear,
      captcha,
      questionId: dataObject.id,
    };
    const result: any = await getAcademicVerificationWithCaptcha(body);
    // console.log("Result >>>", result);
    if (result.html) {
      setAutoVerificationModelContent(result);
      showAutoVerificationModel(true);
    }
  };

  const getCurrentQuestion = () => {
    if (!parentDataObject.repeat) {
      return parentDataObject;
    } else {
      let tabLocateIndex = getCurrentTabIndex();
      if (tabLocateIndex === 0) {
        return parentDataObject;
      } else {
        const tabs = _.sortBy(parentDataObject.tabs, [
          function (o) {
            return o.tabIndex;
          },
        ]);
        return tabs[tabLocateIndex - 1];
      }
    }
  };

  const isVerificationRequired = (currentQuestion: any) => {
    if (sbApplication.activeGroup == 'Academic') {
      if (currentQuestion.verificationRequired) {
        const questionObj = _.find(sbApplication['Academic'].questions, (question: any) =>
          question.question.includes('from which country you have completed your 12th?')
        );
        if (questionObj) {
          const answerObj = _.find(currentApplicationDetails.allAnswers, (answer: any) => answer.id == questionObj.id);
          if (answerObj && answerObj.finalAnswer == 'India') {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  // console.log("Question", parentDataObject);

  const getQuestionInfoMenuItems = (questionObject: any) => {
    const items = [];
    items.push(<SBText text={'Information'} className={classes.questionHeader} />);

    items.push(
      <div className={classes.menuParent}>
        <SBText text={questionObject.question} className={classes.questionLabel} />
        <SBText text={questionObject.information} className={classes.questionValue} />
      </div>
    );

    if (questionObject.questionList && questionObject.questionList.length > 0) {
      for (let i = 0; i < questionObject.questionList.length; i++) {
        const ques = questionObject.questionList[i];
        items.push(
          <div className={classes.menuParent}>
            <SBText text={ques.question} className={classes.questionLabel} />
            <SBText text={ques.information} className={classes.questionValue} />
          </div>
        );
      }
    }

    return items;
  };

  const currentQuestionObject = getCurrentQuestion();

  return (
    <div
      style={{
        backgroundColor: '#F1f1f1',
        display: 'flex',
        // @ts-ignore
        // width: width,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 'inherit',
      }}
    >
      <div className={classes.card}>
        {index === 1 && !sbApplication.initialStateCompleted ? (
          <span className={timeLeft ? `${classes.popuptext}` : `${classes.popuptext} ${classes.hide}`}>
            <span className={classes.arrowdown}></span> Click here to begin
          </span>
        ) : null}
        <div className={classes.cardHeader}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {currentQuestionObject.isLocked ? (
              <img
                src={currentQuestionObject.isLocked ? lock : unlock}
                style={{ height: 22, width: 22, marginRight: 5 }}
              />
            ) : null}
            <SBText text={'Question - ' + index} className={classes.cardHeaderText} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {hasOneOfPermissions(
              [
                PERMISSION.applications_provider_view_global,
                PERMISSION.applications_provider_view_org,
                PERMISSION.applications_provider_view_own,
                PERMISSION.applications_provider_view_sb_org,
                PERMISSION.applications_provider_view_sb_own,
              ],
              userPermissions
            ) && sbApplication.activeGroup != 'PreQuestions' ? (
              <>
                {currentQuestionObject ? (
                  <Button
                    color="primary"
                    style={{
                      marginRight: 10,
                      color: 'white',
                      textTransform: 'none',
                    }}
                    onClick={handleLockUnlockClick}
                    className={classes.moreInfoText}
                  >
                    <img
                      src={currentQuestionObject.isLocked ? lock : unlock}
                      style={{ height: 22, width: 22, marginRight: 5 }}
                    />
                    {currentQuestionObject.isLocked ? 'Unlock Question' : 'Lock Questions'}
                  </Button>
                ) : null}

                <div
                  style={{
                    height: 30,
                    width: 2,
                    background: '#2c2e3d',
                    marginRight: 5,
                    marginTop: 3,
                  }}
                />
              </>
            ) : null}

            <Button
              color="primary"
              style={{ marginRight: 10, color: 'white', textTransform: 'none' }}
              onClick={handleClick}
              className={classes.moreInfoText}
            >
              <InfoIcon style={{ marginRight: 6 }} />
              More Info
            </Button>
          </div>

          {/*<Typography variant={"h4"} style={{ color: "white" }}></Typography>*/}
        </div>

        {parentDataObject.repeat ? renderTabs(parentDataObject) : null}

        <div className={parentDataObject.repeat ? classes.contentWithTabs : classes.content} id="parentScrollDiv">
          <div id="test" />
          {getCurrentQuestion().type == QuestionType.Label &&
          sbApplication &&
          !sbApplication[sbApplication.activeGroup].allowDynamicQuestion ? (
            <SBText
              text={getCurrentQuestion().question}
              style={{
                fontSize: 18,
                textAlign: 'left',
                fontWeight: 600,
                marginBottom: parentDataObject.repeat ? 16 : undefined,
              }}
            />
          ) : null}

          {getCurrentQuestion().type == QuestionType.Label &&
          (!getCurrentQuestion().questionList || getCurrentQuestion().questionList.length == 0) ? (
            <SBText
              text={`There are no additional documents for this application`}
              style={{
                fontSize: 14,
                textAlign: 'center',
                fontWeight: 500,
                marginTop: 100,
                marginBottom: 100,
                color: '#736F6F',
              }}
            />
          ) : null}

          {prepareAnswerUI(getCurrentQuestion())}
          {isVerificationRequired(getCurrentQuestion()) ? (
            <div className={classes.btParent}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {getCurrentQuestion()?.verificationStatus?.lastEventStatus !== 'Approved' ? (
                  <SBButton
                    icon={false}
                    onClick={() => clickVerification(getCurrentQuestion())}
                    text={getAcademicVerificationText(getCurrentQuestion())}
                    className={
                      isVerificationStatusInProgress(getCurrentQuestion())
                        ? classes.disableVerificationButton
                        : classes.verificationButton
                    }
                  />
                ) : null}

                {currentQuestionObject &&
                currentQuestionObject.verificationStatus?.status &&
                currentQuestionObject.verificationStatus.status == 'InProgress' ? (
                  <SBButton
                    icon={false}
                    onClick={() => {
                      setConfirmationDialogMessage('Are you sure, you want to cancel current verification? ');
                      setConfirmationType(2);
                      setConfirmationObj(getCurrentQuestion().id);
                      showConfirmDialog(true);
                    }}
                    text={'Cancel'}
                    className={classes.verificationCancelButton}
                  />
                ) : null}
              </div>

              <SBAutoVerificationTemplate
                open={autoVerificationModel}
                onConfirm={(body: any) => onApprovedRejectAutoVerification(body)}
                onReject={(body: any) => onApprovedRejectAutoVerification(body)}
                onCancel={() => showAutoVerificationModel(false)}
                showButton={true}
                value={autoVerificationModelContent}
                dataObject={getCurrentQuestion()}
              />

              <SBCapetchModel
                open={captchaModel}
                onConfirm={(dataObject: any, verificationResult: any, captcha: any) =>
                  onCaptchaEntered(dataObject, verificationResult, captcha)
                }
                onCancel={() => showCaptchaModel(false)}
                dataObject={getCurrentQuestion()}
                verificationResult={captchaImageContent}
              />
            </div>
          ) : null}
        </div>
      </div>
      <CommenErrorDialog
        open={errorOpen}
        onSubmit={() => setErrorOpen(false)}
        message={errorObj.message}
        header={errorObj.header}
      />
      <ConfirmationDialog
        open={confirmDialog}
        onConfirm={() => onConfirm()}
        onCancel={() => showConfirmDialog(false)}
        message={confirmDialogMessage}
        header={'Confirmation'}
      />
      <SBValidationErrorDialog
        open={validationErrorOpen}
        onClose={(dataObject: any) => onValidationErrorClose(dataObject)}
        message={validationError}
        dataObject={validationDataObj}
      />

      <SBEmailWithTabsTemplates
        open={model}
        onConfirm={() => okToProceedVerification(getCurrentQuestion())}
        onCancel={() => showModel(false)}
        showButton={true}
        value={emailTemplate}
      />
      <Menu
        id={`long-menu-${parentDataObject.id}`}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PopoverClasses={{ paper: classes.questionHintInfo }}
      >
        {getQuestionInfoMenuItems(parentDataObject)}
      </Menu>
    </div>
  );
};

const mapState = (state: RootState) => ({
  sbApplication: state.sbApplication.sbApplication,
  currentApplicationDetails: state.sbApplication.currentApplicationDetails,
  lastQuestion: state.sbApplication.lastQuestion,
  emailTemplate: state.sbApplication.emailtemplate,
  tabIndex: state.sbApplication.tabIndex,
  applicationLocalAnswer: state.sbApplication.applicationLocalAnswer,
});

const mapDispatch = {
  updateSBValues,
  submitQuestionAnswer,
  setLastQuestion,
  deleteQuestionFile,
  deleteFileLocally,
  downloadQuestionFile,
  getEmailTemplate,
  okToProceed,
  addMoreQuestion,
  setTabIndex,
  deleteTab,
  removeInvalidAnswerFromLastQuestion,
  getAcademicVerification,
  updateAcademicVerification,
  getAcademicVerificationWithCaptcha,
  lockUnlockQuestions,
  setLocalQuestionAnswer,
  updateLocalAnswerInCurrentApplicationAllAnswer,
  cancelVerification,
  okToProceedWithMultipleEmail,
};

const connector = connect(mapState, mapDispatch);
export default connector(MainCard);

// export default TextFieldCard;
