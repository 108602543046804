import React, { useState, useCallback } from "react";

import { useDropzone } from "react-dropzone";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import CancelIcon from "@material-ui/icons/Cancel";

import pdfIcon from "./PDF.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
    },
    modalHeader: {
      backgroundColor: "#5C5F74",
      padding: "6px 18px",
      color: "white",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: 500,
    },
    modalBody: {
      backgroundColor: "#F7F9FB",
      padding: "20px 20px",
    },
    dropArea: {
      border: "1px solid gray",
      borderRadius: "15px",
      borderStyle: "dashed",
      padding: "60px 0px",
    },
    dropAreaText: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      color: "#8D8F97",
      width: "100%",
      textAlign: "center",
      cursor: "pointer",
    },
    pdfIconImage: {
      width: "16px",
      height: "16px",
    },
    fileName: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
      color: "#727487",
      width: "80%",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    fileSize: {
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: 500,
      color: "#727487",
      padding: "3px",
      textAlign: "center",
      border: "1px solid #727487",
    },
    fileInfoContainer: {
      width: "100%",
    },
    modalFooter: {
      backgroundColor: "white",
      padding: "20px 20px",
      textAlign: "center",
    },
    button: {
      backgroundColor: "#D27F65",
      boxShadow: "none",
      color: "white",
      borderRadius: 0,
    },
    label: {
      textTransform: "none",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 500,
    },
    fileError: {
      color: "red",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
    fileSuccess: {
      color: "green",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: 400,
    },
    cancelButton: {
      position: "absolute",
      left: "77px",
      top: "265px",
    },
  })
);

type UploadFileModalProps = {
  setUploadFileModalVisibility: any;
  fileUpload: any;
};

const UploadFileModal: React.FC<UploadFileModalProps> = ({
  setUploadFileModalVisibility,
  fileUpload,
}) => {
  const classes = useStyles();

  const [droppedFileInfo, setDroppedFileInfo] = useState({
    fileName: "",
    isError: false,
    file: [],
    size: 0,
  });

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    if (acceptedFiles.length) {
      setDroppedFileInfo((prevState) => ({
        ...prevState,
        fileName: acceptedFiles[0].name,
        isError: false,
        file: acceptedFiles[0],
        size: acceptedFiles[0].size,
      }));
    } else {
      setDroppedFileInfo((prevState) => ({
        ...prevState,
        fileName: "",
        isError: true,
        file: [],
        size: 0,
      }));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
    accept: ".pdf",
  });

  const handleFileUpload = () => {
    if (!droppedFileInfo.fileName) {
      alert("Select a file first");
      return;
    }
    fileUpload(droppedFileInfo);
    //dispatch(uploadFileAction(encryptedApplicationId, droppedFileInfo.file));
  };

  const body = (
    <div className={classes.paper}>
      <div className={classes.modalHeader}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12} xl={6} lg={6}>
            Upload Documents
          </Grid>
          <Grid
            item
            md={4}
            xs={12}
            xl={6}
            lg={6}
            style={{ textAlign: "right" }}
          >
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setUploadFileModalVisibility(false);
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.modalBody}>
        {!droppedFileInfo.fileName ? (
          <div className={classes.dropArea} {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={classes.dropAreaText}>
              <span
                style={{
                  textDecoration: "underline",
                  color: "#D27F65",
                  opacity: "0.5",
                }}
              >
                Click
              </span>{" "}
              to browse or <br />
              drag and drop your files
            </div>
          </div>
        ) : (
          <div className={classes.fileInfoContainer}>
            <div>
              <Grid container spacing={1}>
                <Grid item md={4} xs={12} xl={2} lg={1}>
                  <img src={pdfIcon} className={classes.pdfIconImage} />
                </Grid>
                <Grid item md={4} xs={12} xl={8} lg={9}>
                  <div className={classes.fileName}>
                    {droppedFileInfo.fileName}
                  </div>
                </Grid>
                <Grid item md={4} xs={12} xl={2} lg={2}>
                  <div className={classes.fileSize}>
                    {Math.trunc(droppedFileInfo.size / 1024) + "KB"}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )}
      </div>
      <div className={classes.modalFooter}>
        <div style={{ marginBottom: "20px" }}>
          {droppedFileInfo.isError ? (
            <span className={classes.fileError}>
              <CancelIcon className={classes.cancelButton} />
              <span style={{ marginLeft: "15px" }}>
                You can upload only 1 pdf file
              </span>
            </span>
          ) : (
            droppedFileInfo.fileName && (
              <span className={classes.fileSuccess} style={{ display: "none" }}>
                <CheckIcon />
                <b>{droppedFileInfo.fileName}</b> is ready to upload
              </span>
            )
          )}
        </div>
        <Button
          variant="contained"
          classes={{ root: classes.button, label: classes.label }}
          onClick={handleFileUpload}
        >
          {droppedFileInfo.fileName ? "Submit" : "Upload Document"}
        </Button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        open={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        {body}
      </Modal>
    </div>
  );
};

export default UploadFileModal;
