import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../reducer';

import {
  getProcessingOfficerByAppId,
  addProcessingOfficer,
  deleteProcessingOfficer,
} from '../../../actions';

import {
  Theme,
  Button,
  OutlinedInput,
  InputAdornment,
  ListItemSecondaryAction,
  List,
  ListItem,
  ListItemText,
  IconButton
  } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        logo : {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 10,
        },
        logoheight : {
            height: 100
        },
        recepientsButton : { 
          padding : '14px 16px',
          color: '#fff !important',
          backgroundColor: theme.palette.primary.dark,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
        },
        InputField : {
          width: '100%',
          paddingRight: '0px !important'
        },
    })
  )

type PropsFromRedux = ConnectedProps<typeof connector>;
type ReceipientsBodyProps = PropsFromRedux &  {
  onAddReceipients: (receipient: string) => void;
  getProcessingOfficerByAppId: any;
  addProcessingOfficer: any;
  deleteProcessingOfficer: any;
  applicationId: any;
  processingOfficerEmails: any;
};

  const ReceipientsBody: React.FC<ReceipientsBodyProps> = ({
    onAddReceipients,
    getProcessingOfficerByAppId,
    addProcessingOfficer,
    deleteProcessingOfficer,
    applicationId,
    processingOfficerEmails
  }) => {
    const classes = useStyles();
    const [value, changeValue] = React.useState<string>("");
    const [isDisable, disableButton] = React.useState<boolean>(false);

    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    useEffect(() => {
      getProcessingOfficerByAppId(applicationId);
    }, []);

    useEffect(() => {
      onAddReceipients(processingOfficerEmails.toString());
    }, [processingOfficerEmails]);

    const handleChange=  (event: any ) => {
      disableButton(regexp.test(event.target.value));
      changeValue(event.target.value);
    }

    const addReceipient = (event: any) => {
      addProcessingOfficer(value);
      onAddReceipients(processingOfficerEmails.toString());
      changeValue('');
      disableButton(false);
    }

    const deleteReceipients = (email: any) => {
      deleteProcessingOfficer(email);
    }

    return(
      <>
        <div className={classes.logo}>
            <OutlinedInput
              type={'text'}
              className={classes.InputField}
            value={value}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end" >
                  <Button variant="text"
                    className={classes.recepientsButton}
                    onClick={addReceipient}
                    disabled={!isDisable}>
                          Add Receipients
                  </Button>
                </InputAdornment>
              }
            />
        </div>
        <List>
          {processingOfficerEmails.map((value: any) => {
            return (
              <>
                <ListItem divider>
                  <ListItemText
                    primary={value}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => deleteReceipients(value)}>
                      <CloseIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            );
          })}
        </List>
        </>
    );
}

const mapState = (state: RootState) => ({
  processingOfficerEmails: state.sbApplication.processingOfficerEmails
});

const mapDispatch = {
  getProcessingOfficerByAppId,
  addProcessingOfficer,
  deleteProcessingOfficer
};
const connector = connect(mapState, mapDispatch);
export default connector(ReceipientsBody);