import * as actionTypes from "../actions";
import { EMPTY_USER_LIST_FILTER } from "../util/constants";
import { ActionType } from "./types";

const initialState = {
  userListDetails: undefined,
  userListFilter: EMPTY_USER_LIST_FILTER,
  selectedUserDetails: undefined
};

const userMgmntReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_SUCCESS: {
      return {
        ...state,
        userListDetails: action.payload,
      };
    }
    case actionTypes.APPEND_USERS: {
      const { count, rows } = state.userListDetails as any;
      action.payload?.rows?.map((entity: any) => {
        rows.push(entity);
      });
      const newCount = action.payload?.count || count;
      return {
        ...state,
        userListDetails: { count: newCount, rows },
      };
    }
    case actionTypes.FETCH_USER_SUCCESS: {
      return {
        ...state,
        selectedUserDetails: action.payload,
      };
    }
    case actionTypes.UPDATE_USER_LIST_FILTER: {
      return {
        ...state,
        userListFilter: {...action.payload},
      };
    }
    case actionTypes.CLEAR_USER_LIST_FILTER: {
      return {
        ...state,
        userListFilter: {...EMPTY_USER_LIST_FILTER},
      };
    }
    default: {
      return state;
    }
  }
};

export default userMgmntReducer;
