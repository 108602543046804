import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  Paper,
  TableHead,
  Tooltip,
} from "@material-ui/core";
import SBText from "../../../components/base/SBText";
import { hasPermission, PERMISSION } from "../../../util/rolePermissionsUtil";
import { LABELS } from "../../../util/labels";
import { useUserPermissions } from "../../../hooks/useUserPermissions";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  padding: {},
  paddingHeader: { backgroundColor: "pink" },
  paddingButtonParent: { backgroundColor: "pink" },
  paddingButton: {
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 0,
    alignItems: "center",
    alignSelf: "center",
  },
  TableRow: {
    backgroundColor: "#efefef",
    fontWeight: 500,
  },
  TableBodyRow: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  HeaderPadding: {
    fontWeight: 500,
    fontSize: "13px",
  },
  bodyText: {
    fontSize: "13px",
  },
  tableParents: {
    display: "flex",
    flex: 1,
    height: "100%",
    overflow: "hidden",
  },

  mainLayout: {
    display: "flex",
    flex: 1,
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.117647) 2px 4px 6px 4px",
    padding: 16,
    height: "100%",
    overflow: "hidden",
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#4c4c4c",
  },
  tooltip: {
    backgroundColor: "#4c4c4c",
  },
}));

function BootstrapTooltip(props: any) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

type ProviderTableProps = {
  data: any;
  onItemClick: (item: any) => void;
};

const ProviderTable: React.FC<ProviderTableProps> = ({
  data,
  onItemClick,
  ...rest
}) => {
  const classes = useStyles();
  const userPermissions: PERMISSION[] = useUserPermissions();

  return (
    <div className={classes.mainLayout}>
      <div className={classes.tableParents}>
        <TableContainer component={Paper}>
          <Table className={classes.table} stickyHeader>
            <TableHead className={classes.TableRow}>
              <TableRow>
                <TableCell align="left">
                  <SBText
                    text={LABELS.NAME}
                    className={classes.HeaderPadding}
                  />
                </TableCell>
                {/* <TableCell align="left" className={classes.padding}>
                  <SBText
                    text={LABELS.SUB_TITLE}
                    className={classes.HeaderPadding}
                  />
                </TableCell> */}
                <TableCell align="left" className={classes.padding}>
                  <SBText
                    text={LABELS.COUNTRY}
                    className={classes.HeaderPadding}
                  />
                </TableCell>
                <TableCell align="left" className={classes.padding}>
                  <SBText
                    text={LABELS.REGION}
                    className={classes.HeaderPadding}
                  />
                </TableCell>
                <TableCell align="left" className={classes.padding}>
                  <SBText
                    text={LABELS.CITY}
                    className={classes.HeaderPadding}
                  />
                </TableCell>
                {/* <TableCell align="center" className={classes.padding}>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((provider: any) => (
                <TableRow
                  key={provider.id}
                  style={{
                    alignItems: "center",
                  }}
                >
                  <TableCell component="th">
                    <SBText text={provider.name} className={classes.bodyText} />
                  </TableCell>
                  {/* <TableCell align="left" className={classes.padding}>
                    <SBText text={provider.subTitle} className={classes.bodyText} />
                  </TableCell> */}
                  <TableCell align="left" className={classes.padding}>
                    <SBText text={provider.country} className={classes.bodyText} />
                  </TableCell>
                  <TableCell align="left" className={classes.padding}>
                    <SBText text={provider.region} className={classes.bodyText} />
                  </TableCell>
                  <TableCell align="left" className={classes.padding}>
                    <SBText text={provider.city} className={classes.bodyText} />
                  </TableCell>
                  {/* <TableCell align="left" className={classes.padding}>
                    { hasPermission(PERMISSION.user_providers_edit, userPermissions) && 
                      <BootstrapTooltip title="Details">
                        <div onClick={(event) => onItemClick(role)}>
                          <VisibilityIcon />
                        </div>
                      </BootstrapTooltip>
                    }
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ProviderTable;
