import { Grid, Theme, IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import { LABELS } from '../../../util/labels';
import SBSearch from '../../../components/base/SBSearch';
// import LeadsModal from '../LeadsModal';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      position: 'relative',
    },
    searchSortParents: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      gap: 15,
    },
    addBtn: {
      height: 40,
      borderRadius: 30,
      textTransform: 'none',
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        fontSize: 14,
      },
      padding: '5px 20px',
    },
    btnWrapper: {
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        right: '-8px',
        top: '-54px',
      },
    },
  })
);

type TlToolBarPros = {
  handleOnSearch: (searchText: string) => void;
  setFilterOpen: (open: boolean) => void;
  downloadLeads: () => void;
};
const TlToolBar: React.FC<TlToolBarPros> = ({ handleOnSearch, setFilterOpen, downloadLeads }) => {
  const classes = useStyles();

  // const [isLeadsModalOpen, setLeadsModalOpen] = useState<boolean>(false);

  // const handleAddBtnClick = () => {
  //   setLeadsModalOpen(true);
  // };

  // const handleLeadModalClose = () => {
  //   setLeadsModalOpen(false);
  // };
  return (
    <div className={classes.toolbar}>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <div className={classes.searchSortParents}>
            <div style={{ width: 275 }}>
              <SBSearch onSearch={handleOnSearch} placeHolder={LABELS.TA_NAME} />
            </div>
            <IconButton
              onClick={() => setFilterOpen(true)}
              aria-label="delete"
              style={{ borderRadius: 2, padding: 8, backgroundColor: '#dbd4d4' }}
            >
              <FilterListIcon fontSize={'medium'} style={{ color: 'black' }} />
            </IconButton>
            <IconButton
              onClick={() => downloadLeads()}
              aria-label="delete"
              style={{ borderRadius: 2, padding: 8, backgroundColor: '#dbd4d4' }}
            >
              <GetAppIcon fontSize={'medium'} style={{ color: 'black' }} />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      {/* <LeadsModal open={isLeadsModalOpen} onClose={handleLeadModalClose}></LeadsModal> */}
    </div>
  );
};

export default TlToolBar;
