import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  ActionTypes,
  get,
  ON_LOADING_FINISHED,
  ON_LOADING_START,
} from "../../../actions";
import config from "../../../config";

export const useFetchApplicationFormFile = () => {
  const dispatch = useDispatch();

  const fetchFetchApplicationFormFile = useCallback(
    async (agentId) => {
      dispatch({ type: ON_LOADING_START });
      try {
        const result = await get(
          `${config.BASE_URL}/agent-application/form/${agentId}`,
          { responseType: "blob" },
          dispatch
        );
        dispatch({
          type: ActionTypes.SET_AGENT_APPLICATION_FORM_FILE,
          payload: result,
        });
      } finally {
        dispatch({ type: ON_LOADING_FINISHED });
      }
    },
    [dispatch]
  );

  return {
    fetchFetchApplicationFormFile,
  };
};
