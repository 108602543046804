import {
  InputAdornment,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { AccessTime, Person } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { createStyles, makeStyles } from "@material-ui/styles";
import moment from "moment";
import React from "react";
import SBText from "../../../components/base/SBText";
import { getRoleByPermissions } from "../../../util/rolePermissionsUtil";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 10,
      width: "100%",
      flexGrow: 1,
      position: "relative",
      maxHeight: 400,
      minHeight:200
    },
    messageParent: {
      position: "absolute",
      top: 50,
      bottom: 0,
      left: 0,
      right: 0,
      overflowY: "auto",
      width: "100%",
      "&::-webkit-scrollbar": {
        width: "0.8em",
        backgroundColor: "#F1F1F1",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#D6D3D3",
      },
      "&::-webkit-scrollbar-button:start:decrement": {
        backgroundColor: "#D6D3D3",
        backgroundRepeat: "no-repeat",
      },
      "&::-webkit-scrollbar-button:end:increment": {
        backgroundColor: "#D6D3D3",
      },
    },
    subHeader: {
      fontSize: "1.05rem",
      color: theme.palette.primary.dark,
      marginBottom: 5,
    },
    input: {
      height: 30,
      backgroundColor: "white",
      borderRadius: 2,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      marginTop: 5,
    },
    timeline: {
      padding: 0,
    },
    timelineOppositeContent: {
      flex: 0,
      padding: 0,
    },
    iconAndTextContainer: {
      display: "flex",
      alignItems: "start",
      "& > :first-child": {
        marginRight: 8,
      },
    },
  })
);

type BlockHintsProps = {
  data: any;
  onSearchTextChange: (data: string) => void;
  value: string;
};

const ActivityLog: React.FC<BlockHintsProps> = ({
  data,
  value,
  onSearchTextChange,
  ...rest
}) => {
  // console.log("ActivityLog !!!!", data);

  const classes = useStyles();

  const formattedDate = (date: Date) => {
    return moment(date).format("DD MMM YYYY HH:mm");
  };

  const getFormattedString = (log: any) => {
    // console.log("Log permissions::", permissions);
    return `${getRoleByPermissions(log.createdUser)} ${
      log.createdUser.name
    } | ${formattedDate(log.createdAt)}`;
  };

  return (
    <div className={classes.root}>
      {/* <SBText text={"Change Log"} className={classes.subHeader} /> */}
      <TextField
        fullWidth
        id="input-with-icon-textfield"
        variant="outlined"
        placeholder={"Search Text"}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
          className: classes.input,
        }}
        onChange={(event) => {
          event.persist();
          onSearchTextChange(event.target.value.toLowerCase());
        }}
      />

      <div className={classes.messageParent}>
        <Timeline className={classes.timeline}>
          {data.map((log: any) => (
            <TimelineItem>
              <TimelineOppositeContent
                className={classes.timelineOppositeContent}
              ></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant="body2" color="textPrimary">
                  {log.activity}
                </Typography>
                <div className={classes.iconAndTextContainer}>
                  <AccessTime fontSize="small" color="secondary" />
                  <Typography variant="body2" color="textSecondary">
                    {formattedDate(log.createdAt)}
                  </Typography>
                </div>
                <div className={classes.iconAndTextContainer}>
                  <Person fontSize="small" color="secondary" />
                  <Typography variant="body2" color="textSecondary">
                    {`${getRoleByPermissions(log.createdUser)} ${
                      log.createdUser.name
                    }`}
                  </Typography>
                </div>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    </div>
  );
};

export default ActivityLog;
