import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Button, Theme, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: "#2463FF",
      fontSize: 14,
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    },
  })
);

type SBButtonProps = {
  className?: string;
  text: string;
  onClick: () => void;
  [x: string]: any;
};

const SBLinkButton: React.FC<SBButtonProps> = ({
  className,
  text,
  onClick,
  ...rest
}) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.button, className)}
      onClick={onClick}
      {...rest}
    >
      {text}
    </Button>
  );
};

export default SBLinkButton;
