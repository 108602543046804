import { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import SBButton from "../../../components/base/SBButton";
import { LABELS } from "../../../util/labels";
import SBSearch from "../../../components/base/SBSearch";
import LPOModal from "../LPOModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    searchSortParents: {
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.up(1920)]: {
        height: 60,
        alignItems: "unset",
      },
    },
    addBtn: {
      height: 40,
      borderRadius: 30,
      textTransform: "none",
      fontSize: 12,
      fontWeight: 600,
      [theme.breakpoints.up(1920)]: {
        fontSize: 14,
      },
      padding: "5px 20px",
    },
  })
);

type ToolBarPros = {
  handleOnSearch: (searchText: string) => void;
};
const ToolBar: React.FC<ToolBarPros> = ({ handleOnSearch }) => {
  const classes = useStyles();

  const [isLPOModalOpen, setLPOModalOpen] = useState<boolean>(false);

  const handleAddBtnClick = () => {
    setLPOModalOpen(true);
  };

  const handleLPOModalClose = () => {
    setLPOModalOpen(false);
  };
  return (
    <div className={classes.toolbar}>
      <div>
        {/* <SBButton
          icon={false}
          text={LABELS.NEW_LPO}
          className={classes.addBtn}
          onClick={handleAddBtnClick}
        /> */}
      </div>
      <div className={classes.searchSortParents}>
        <SBSearch
          onSearch={handleOnSearch}
          placeHolder={LABELS.SEARCH_BY_NAME}
        />
      </div>

      <LPOModal open={isLPOModalOpen} 
      onClose={handleLPOModalClose}
      onViewFile={()=> console.log("View Files")}
      ></LPOModal>
    </div>
  );
};

export default ToolBar;
