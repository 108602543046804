import { InputBase, MenuItem, Select, Theme } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import { createStyles, makeStyles, withStyles } from "@material-ui/styles";
import _ from "lodash";
import moment from "moment";
import React from "react";
import SBText from "../SBText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 5,
      height: 45,
      backgroundColor: "#FCFBFB",
      textAlign: "left",
      overflow: "hidden",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
    },
    placeHolder: { textAlign: "left", fontSize: 14, color: "#5C707C" },
    dropDown: {
      height: 45,
      overflow: "hidden",
      fontFamily: "Poppins",
      letterSpacing: 0.75,
      textAlign: "left",
      borderColor: "#3E4059",
      backgroundColor: "#FCFBFB",
      "&:focus": {
        borderColor: "#3E4059",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    label: {
      textAlign: "left",
      color: "#736F6F",
    },
    error: {
      border: "1px solid #e53935 !important",
    },
    helperError: {
      marginLeft: 14,
      marginRight: 14,
    },
  })
);

type SBDropdownProps = {
  className?: string;
  [x: string]: any;
  value: any;
  questionObj?: any;
  showNoneOption?: boolean;
  disabled?: boolean;
};
const SBDropdown: React.FC<SBDropdownProps> = ({
  className,
  value,
  questionObj,
  showNoneOption,
  disabled,
  ...rest
}) => {
  const SbInput = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "label + &": {},
      },
      input: {
        borderRadius: 5,
        backgroundColor: "#FCFBFB",
        border: "1px solid #3E4059",
        height: 30,
        textAlign: "left",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        // Use the system font instead of the default Roboto font.
        fontFamily: "Poppins",
        "&:focus": {
          borderRadius: 5,
          borderColor: "#3E4059",
          // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        letterSpacing: 0.75,
      },
    })
  )(InputBase);

  const SbInputDisabled = withStyles((theme: Theme) =>
    createStyles({
      root: {
        "label + &": {},
      },
      input: {
        borderRadius: 5,
        backgroundColor:  "#F0F0F0",
        border: "1px solid #3E4059",
        height: 30,
        textAlign: "left",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        // opacity:0.3,
        // Use the system font instead of the default Roboto font.
        fontFamily: "Poppins",
        "&:focus": {
          borderRadius: 5,
          borderColor: "#3E4059",
          // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
        },
        letterSpacing: 0.75,
      },
    })
  )(InputBase);

  const classes = { ...useStyles(), ...(rest.styles?.() || {}) };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    rest.onDataChange(event.target.value, questionObj);
  };

  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let n;
  if (rest.options && rest.options[0]) {
    n = months.includes(rest.options[0].value.split("-")[0]);
  }

  const sorter = (a: any, b: any) => {
    if (a.year !== b.year) {
      return a.year - b.year;
    } else {
      return months.indexOf(a.month) - months.indexOf(b.month);
    }
  };

  let arrayMonth = [];
  if (n) {
    for (let monthObject of rest.options) {
      //console.log(monthObject)
      let arr = monthObject.value.split("-");
      let object = {
        year: arr[1],
        month: arr[0],
        id: monthObject.id,
      };
      arrayMonth.push(object);
    }
    //console.log(arrayMonth)
    arrayMonth.sort(sorter);
  }
  let sortedArray: any = [];
  if (arrayMonth.length) {
    for (let option of arrayMonth) {
      if (rest.country && rest.country == "Australia") {
        const newDeferredDate = moment(
          `${option.month}-${option.year}`,
          "MMM-YY"
        );
        sortedArray.push({
          id: option.id,
          value: `${option.month}-${option.year}`,
        });
        // Temp removed
        // const oldDeferredDate = moment();
        // if (newDeferredDate.diff(oldDeferredDate) > 0) {
        //   sortedArray.push({
        //     id: option.id,
        //     value: `${option.month}-${option.year}`,
        //   });
        // }
      } else {
        if (sortedArray.length < 3) {
          const newDeferredDate = moment(
            `${option.month}-${option.year}`,
            "MMM-YY"
          );
          const oldDeferredDate = moment();
          if (newDeferredDate.diff(oldDeferredDate) > 0) {
            sortedArray.push({
              id: option.id,
              value: `${option.month}-${option.year}`,
            });
          }
        } else {
          break;
        }
      }
    }
  } else {
    sortedArray = rest.options ? _.sortBy(rest.options, ["value"]) : [];
  }

  return (
    <div style={{opacity: (questionObj &&
      questionObj.status &&
      questionObj.status === "DISABLED") ||
    disabled
      ?  0.3 :1}}>
      {rest.labelText ? (
        <SBText text={rest.labelText} className={classes.label} />
      ) : null}
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        // id="outlined-basic"
        {...(rest.error
          ? { classes: { select: classes.error, root: className } }
          : { root: className })}
        variant="outlined"
        fullWidth
        classes={{ root: className }}
        displayEmpty
        value={value}
        input={ (questionObj &&
          questionObj.status &&
          questionObj.status === "DISABLED") ||
        disabled
          ? <SbInputDisabled/>
          : <SbInput />  }
        disabled={
          (questionObj &&
            questionObj.status &&
            questionObj.status === "DISABLED") ||
          disabled
            ? true
            : false
        }
        {...((rest.placeholder && value !== undefined) || value !== null
          ? {
              renderValue: (selected) => {
                if (!selected) {
                  return (
                    <SBText
                      text={rest.placeholder}
                      className={classes.placeHolder}
                    />
                  );
                }
                const found = sortedArray.find((c: any) => c.id === selected);
                return (found && found.value) || "";
              },
            }
          : {})}
        onChange={(event) => handleChange(event)}
      >
        {showNoneOption && (
          <MenuItem key={"none"} value={""}>
            <em>None</em>
          </MenuItem>
        )}
        {sortedArray.map((state: any, index: number) => (
          <MenuItem key={state.id} value={state.id}>
            {state.value}
            {/*<SBText text={state.value} />*/}
          </MenuItem>
        ))}
      </Select>
      {rest.error && (
        <FormHelperText classes={{ error: classes.helperError }} error>
          {rest.helperText}
        </FormHelperText>
      )}
    </div>
  );
};

export default SBDropdown;
