import { ActionTypes } from "../actions/agent";
import { ActionType } from "../../../../reducer/types";

interface RefreeState {
  refreeApproval: [];
}

const initialState: RefreeState = {
  refreeApproval: [],
};

const RefereeApproveDisapproveReducer = (
  state = initialState,
  action: ActionType
): RefreeState => {
  switch (action.type) {
    case ActionTypes.SET_REFREE_APPROVAL_DEATILS: {
      return {
        ...state,
        refreeApproval: action.payload,
      };
    }
    default:
      return state;
  }
};

export default RefereeApproveDisapproveReducer;
