import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Theme,
  useMediaQuery,
  MenuItem,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { HighlightOffRounded } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import MuiPhoneNumber from "material-ui-phone-number";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppColor from "../../../theme/AppColor";
import { 
  Lead,
   LeadModalAPIStatus,
    LeadModalDropdownsData,
     LEAD_STATUS,
     } from "../types";
import SBText from "../../../components/base/SBText";
import SBButton from "../../../components/base/SBButton";
import { validateLead } from "../utils";
import { LeadAction, saveLeadAction } from "../store/actions";
import {
   leadModalDropdownsData, 
   selectLeadsModalAPIStatus,
  } from "../store/selectors";
import { LEADS_API_IDEAL_STATUS } from "../constants";
import { LABELS } from "../../../util/labels";
import { formDialogStyle } from "../../../styles";

const useStyles = makeStyles((theme: Theme) => 
createStyles(formDialogStyle(theme))
);

interface LeadsModalProps {
  leadData?: Lead;
  open: any;
  onClose: () => void;
}

const defaultFormData = {
  id: null,
  applicantFirstName: "",
  applicantLastName: "",
  emailId: "",
  phoneNumber: "",
  applicantCity: "",
  applicantCountry: "",
  preferredCountry: "",
  preferredCourse: "",
  convertedApplicationId: null,
  createdBy: null,
  status: LEAD_STATUS.DRAFT,
  comment: "",
  createdByUser: { id: null, name: "", email: "" },
};

const LeadsModal: React.FC<LeadsModalProps> = ({
  leadData = defaultFormData,
  open,
  onClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const apiStatus: LeadModalAPIStatus = useSelector(selectLeadsModalAPIStatus);
  const dropdownData: LeadModalDropdownsData = useSelector(
    leadModalDropdownsData
    );

  const [formData, setFormData] = useState<Lead>(leadData);
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<Map<string, string>>(new Map());
  const [isSaveAsDraftAllowed, setIsSaveAsDraftAllowed] = useState<boolean>(
    false
    );
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (apiStatus.status === "error") {
      setFormErrors(apiStatus.errors);
    } else if (apiStatus.status === "success") {
      onClose();
    }
  }, [apiStatus]);

  useEffect(() => {
    resetForm();
  }, [open]);

  useEffect(() => {
    setFormData(leadData);
    setFormDisabled(leadData.status == LEAD_STATUS.SUBMITTED_TO_SB);
  }, [leadData]);

  useEffect(() => {
    setIsSaveAsDraftAllowed(
      !(formData.applicantFirstName && formData.phoneNumber)
    );
  }, [formData]);

  const updateFormData = (field: string, value: any) => {
    setFormData((formDataVal: Lead) => {
      return { ...formDataVal, [field]: value };
    });
  };

  const handleChange = ({ target }: any) => {
    const { name, value } = target;
    updateFormData(name, value);
  };

  const handlePhoneChange = (value: any) => {
    updateFormData("phoneNumber", value);
  };

  const isValid = (asDraft: boolean) => {
    const errors = validateLead(formData, asDraft);
    setFormErrors(errors);
    return errors.size == 0;
  };

  const resetForm = () => {
    dispatch({
      type: LeadAction.SET_LEADS_MODAL_API_STATUS,
      payload: LEADS_API_IDEAL_STATUS,
    });
    setFormData(defaultFormData);
    setFormErrors(new Map());
  };

  const saveLead = useCallback(
    (isDraft: boolean) => {
      if (!isValid(isDraft)) {
        return;
      }
      dispatch(saveLeadAction(formData, isDraft));
    },
    [formData, dispatch]
  );

  return (
    <Dialog
      fullScreen={isMobileView}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      className={classes.sbDialog}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
      <SBText
          text={
            formDisabled
              ? LABELS.VIEW_LEAD
              : formData.id
              ? LABELS.UPDATE_LEAD
              : LABELS.CREATE_LEAD
          }
        />

        <IconButton aria-label="close" onClick={() => onClose()}>
          <HighlightOffRounded style={{ color: AppColor.appWhite }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form autoComplete="off" style={{ paddingTop: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                <SBText
                    className={classes.requiredFieldLabel}
                    text={LABELS.APPLICANT_FIRST_NAME}
                  />
                </div>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  disabled={formDisabled}
                  value={formData.applicantFirstName}
                  name="applicantFirstName"
                  error={formErrors.has("applicantFirstName")}
                  helperText={formErrors.get("applicantFirstName")}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                <SBText
                    className={classes.requiredFieldLabel}
                    text={LABELS.APPLICANT_LAST_NAME}
                  />
                </div>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  disabled={formDisabled}
                  value={formData.applicantLastName}
                  name="applicantLastName"
                  error={formErrors.has("applicantLastName")}
                  helperText={formErrors.get("applicantLastName")}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                <SBText
                    className={classes.requiredFieldLabel}
                    text={LABELS.PHONE_NUMBER}
                  />
                </div>
                <MuiPhoneNumber
                  defaultCountry={"in"}
                  fullWidth
                  onChange={handlePhoneChange}
                  disabled={formDisabled}
                  variant="outlined"
                  autoFormat={false}
                  countryCodeEditable={false}
                  disableAreaCodes={true}
                  value={formData.phoneNumber}
                  error={formErrors.has("phoneNumber")}
                  helperText={formErrors.get("phoneNumber")}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                <SBText
                    className={classes.requiredFieldLabel}
                    text={LABELS.EMAIL}
                  />
                </div>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  disabled={formDisabled}
                  value={formData.emailId}
                  name="emailId"
                  error={formErrors.has("emailId")}
                  helperText={formErrors.get("emailId")}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                <SBText
                    className={classes.requiredFieldLabel}
                    text={LABELS.APPLICANT_CITY}
                  />
                </div>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  disabled={formDisabled}
                  value={formData.applicantCity}
                  name="applicantCity"
                  error={formErrors.has("applicantCity")}
                  helperText={formErrors.get("applicantCity")}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                <SBText
                    className={classes.requiredFieldLabel}
                    text={LABELS.APPLICANT_COUNTRY}
                  />
                </div>
                <TextField
                  select
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  disabled={formDisabled}
                  value={formData.applicantCountry}
                  name="applicantCountry"
                  error={formErrors.has("applicantCountry")}
                  helperText={formErrors.get("applicantCountry")}
                >
                  {dropdownData?.applicantCountries?.map((country) => (
                    <MenuItem key={country.id} value={country.value}>
                      {country.value}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                <SBText
                    className={classes.requiredFieldLabel}
                    text={LABELS.PREFERRED_COUNTRY}
                  />
                </div>
                <TextField
                  select
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  disabled={formDisabled}
                  value={formData.preferredCountry}
                  name="preferredCountry"
                  error={formErrors.has("preferredCountry")}
                  helperText={formErrors.get("preferredCountry")}
                >
                  {dropdownData?.preferredCountries?.map((country) => (
                    <MenuItem key={country.id} value={country.value}>
                      {country.value}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: 0 }}>
              <div className={classes.formField}>
                <div className={classes.Label}>
                <SBText
                    className={classes.requiredFieldLabel}
                    text={LABELS.PREFERRED_COURSE}
                  />
                </div>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  disabled={formDisabled}
                  value={formData.preferredCourse}
                  name="preferredCourse"
                  error={formErrors.has("preferredCourse")}
                  helperText={formErrors.get("preferredCourse")}
                />
              </div>
            </Grid>
            <Grid item xs={12} style={{ padding: 0 }}>
              <div className={classes.formField} style={{ width: "100%" }}>
                <div className={classes.Label}>
                  <SBText text={LABELS.ADDITIONAL_COMMENT} />
                </div>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  disabled={formDisabled}
                  value={formData.comment}
                  name="comment"
                  multiline
                  rows={4}
                  fullWidth={true}
                  error={formErrors.has("comment")}
                  helperText={formErrors.get("comment")}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        {formDisabled ? (
           <SBButton
           icon={false}
           text={LABELS.CLOSE}
           color="primary"
           onClick={() => onClose()}
         />
        ) : (
          <div className={classes.multipleButtonsWrapper}>
            <SBButton
              icon={false}
              text={LABELS.SAVE_AS_DRAFT}
              disabled={isSaveAsDraftAllowed}
              color="primary"
              onClick={() => {
                saveLead(true);
              }}
            />
            <SBButton
              icon={false}
              text={LABELS.SUBMIT}
              color="primary"
              onClick={() => {
                saveLead(false);
              }}
            />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default LeadsModal;
