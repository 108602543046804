import { ENTITY_TYPE } from "../util/constants";

export interface ActionType {
  type: String;
  payload?: any;
}

export interface AdmissionAtATMC {
  id: number;
  applicationId: number;
  statusUpdatedOn: string;
  status: string;
  passportNo: string;
  passportCounty: string;
  instituteBoardFor10Th: string;
  instituteBoardFor12Th: string;
  bachelorCourseName: string;
  instituteforBacheleor: string;
  ielts_pte_listening_score?: number;
  ielts_pte_speaking_score?: number;
  ielts_pte_reading_score?: number;
  ielts_pte_writing_score?: number;
  ielts_pte_overall_score?: number;
  eap: string;
  eapWeeks: number;
}

export interface StudentType {
  id: string;
  coeStatus: string;
  coeType: string;
  coeCode: string;
  providerStudentID: number;
  firstName: string;
  courseCode: string;
  lastName?: string;
  familyName: string;
  gender: string;
  dateOfBirth: string;
  nationality: string;
  courseName: string;
  proposedStartDate: string;
  proposedEndDate: string;
  visaEffectiveDate: string;
  enrolmentComments: string;
  locationName: string;
  studentType: string;
  studentCategory: string;
  studentStatus: string;
  crossInstituteStudentIncoming: boolean;
  crossInstituteStudentOutgoing: boolean;
  crossProviderStudentIncoming: boolean;
  crossProviderStudentOutgoing: boolean;
}

export interface ApplicationItemType {
  id: number;
  createdDate: Date;
  studentName: string;
  applicationStatus: string;
  intake: string;
  statusUpdatedOn: Date;
  admissionOfficer: string;
  currentOfficers: string
}

export interface CourseType {
  id: string;
  courseCode: string;
  CRICOSCode: string;
  courseName: string;
  discipline: string;
  measure?: string;
  comment?: string;
  isActive: boolean;
  location: string;
}

export interface UnitType {
  id: string;
  unitCategory: string;
  unitCode: string;
  unitName: string;
  unitCredit: number;
  isActive: boolean;
}

export interface SemesterType {
  id: string;
  commencing_cycle: string;
  apply_to_all_courses: boolean;
  enrolment_start_date: string;
  enrolment_end_date: string;
  number_of_teaching_weeks: number;
  exam_start_date: string;
  exam_end_date: string;
  result_date: string;
  weeks?: SemesterWeekType[];
}

export interface SemesterWeekType {
  id: string;
  semesterId: string;
  startDate: string;
  endDate: string;
}

export interface StudyTemplateType {
  id: string;
  courseCode: string;
  location: string;
  courseLevel: string;
  commencingCycle: string;
  coursePathway: string;
  version: string;
  comment: string;
  month: string;
}

export interface StudyTemplateSemestersPlanType {
  id: string;
  semester: string;
  unitCode: string;
  unitType: string;
  electiveGroup: string;
  preRequisites: number;
  coRequisites: string;
  status: string;
}

export interface StudyCreditType {
  id: number;
  unitCode: string;
  unitType: string;
  electiveGroup: string;
  preRequisites: number;
  coRequisites: string;
}

export interface WorkListError {
  id: string;
  field: string;
  message: string;
}
export interface WorkListType {
  id: string;
  title: string;
  entityId: string;
  entityType: string;
  worklistType: string;
  isResolved: boolean;
  createdAt: Date;
  worklistDetails: WorkListError[];
}

export interface UnitAssignment {
  id: number;
  unitResultId: number;
  assignmentName: string;
  marks: number;
}

export interface StudentIntervention {
  id: number;
  studentId: number;
  interventionHTML: string;
}

export interface UploadPrismError {
  id: string;
  studentProviderId: number;
  errorDescription: string;
  createdBy: string;
}

export interface EmailTemplate {
  content: string,
  subject: string
}

export interface LPC {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  occupation: string;
  country: string;
  city: string;
  currentStatusId: number;
  status: LPCStatus;
  timeToConnectHour: number;
  timeToConnectMin: number,
  prevWorkDescription: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum LPCStatus {
  REJECTED = -1,
  ENQUIRED = 1,
  REFERENCE_CHECKED = 2,
  APPROVED = 3,
  ON_BOARDED = 4,
  LOGGED_IN = 5
}

export interface Provider {
  id: number,
  name: string,
  country: string,
  countryId: number,
  address: string,
  city: string,
  companyProfileFileName: string,
  companyProfileUrl: string,
  description: string,
  lat: string,
  long: string,
  parentProviderId: number,
  region: string,
  segments: string,
  status: string,
  subTitle: string
}
export interface listPageSearchParam {
  limit: number;
  offset: number;
  searchText: string;
}

export interface UserListFilter {
  entityType: ENTITY_TYPE | null;
  provider: number | null; 
  companyId: number | null;
}