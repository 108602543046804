import React from "react";
import { Route } from "react-router-dom";
import { useUserPermissions } from "../../hooks/useUserPermissions";
import { hasPermission, PERMISSION } from "../../util/rolePermissionsUtil";
import LPODashboard from "../LPO/LPODashboard";

const DashboardRoute = ({ component: Component, permission, ...rest }: any) => {
  const userPermissions = useUserPermissions();
  const lpoDashboard = hasPermission(
    PERMISSION.ta_dashboard_view,
    userPermissions
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        lpoDashboard ? <LPODashboard /> : <Component {...props} />
      }
    />
  );
};

export default DashboardRoute;
